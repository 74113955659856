import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, LoadingOutlined, MonitorOutlined, WarningTwoTone } from '@ant-design/icons';
import { Row, Spin, Radio, Switch, Tooltip } from 'antd';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';

import TrailerHistoryChart from '../charts/TrailerHistoryChart/TrailerHistoryChartComponent';
import actions from '../../actions';
import { addEndPoint, convertData, getShortenedRoute } from '../../helpers/trailer';
import * as strings from '../../helpers/defaultStrings';
import { triggerEvent } from '../../helpers/googleAnalytics';
import { mobileOnly } from '../../helpers/responsive';
import { isUsaCustomer, getTimeDate } from '../../helpers/functions';
import { selectRegion } from '../../selectors';

class TrailerHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: null,
      pos: null,
      dataHash: null,
      hours: 6,
      checked: false
    };
  }

  componentDidMount() {
    const { selectedTnTTrailer, getTrailerHistory, trailerHistory, mapRoutes } = this.props;
    const { hours } = this.state;
    if (selectedTnTTrailer) {
      if (
        trailerHistory.retrievedHistory[selectedTnTTrailer.assetId] &&
        trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].hours >= hours
      ) {
        // we load the current history data with what we want now
        this.setState({
          route: getShortenedRoute(addEndPoint(mapRoutes[selectedTnTTrailer.assetId]), hours),
          dataHash: trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].dataHash
        });
      } else {
        // we need to make the API call
        getTrailerHistory(selectedTnTTrailer.assetId, hours);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTnTTrailer, trailerHistory, getTrailerHistory, mapRoutes, historicalData, eventContext } =
      this.props;
    const { hours, dataHash } = this.state;
    if (historicalData) {
      return;
    }
    if (
      selectedTnTTrailer &&
      (!prevProps.selectedTnTTrailer ||
        prevProps.selectedTnTTrailer.assetId !== selectedTnTTrailer.assetId ||
        prevState.hours !== hours)
    ) {
      // first check if we already have the history for this trailer
      if (
        trailerHistory.retrievedHistory[selectedTnTTrailer.assetId] &&
        trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].hours >= hours
      ) {
        // we load the current history data with what we want now
        const route =
          trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].hours > hours
            ? getShortenedRoute(mapRoutes[selectedTnTTrailer.assetId], hours)
            : mapRoutes[selectedTnTTrailer.assetId];
        const dataHash =
          trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].hours > hours
            ? Date.now() + '_' + Math.random()
            : trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].dataHash;
        this.setState({ route: addEndPoint(route), dataHash });
      } else {
        if (!prevProps.selectedTnTTrailer || prevProps.selectedTnTTrailer.assetId !== selectedTnTTrailer.assetId) {
          this.setState({ route: [], dataHash: null });
        }
        // we need to make the API call
        getTrailerHistory(selectedTnTTrailer.assetId, hours);
      }
    }

    if (
      selectedTnTTrailer &&
      trailerHistory &&
      trailerHistory.retrievedHistory[selectedTnTTrailer.assetId] &&
      (!prevProps.trailerHistory ||
        !prevProps.trailerHistory.retrievedHistory[selectedTnTTrailer.assetId] ||
        dataHash !== trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].dataHash)
    ) {
      const route =
        trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].hours > hours
          ? getShortenedRoute(mapRoutes[selectedTnTTrailer.assetId], hours)
          : mapRoutes[selectedTnTTrailer.assetId];
      this.setState({
        route: addEndPoint(route),
        dataHash: trailerHistory.retrievedHistory[selectedTnTTrailer.assetId].dataHash
      });
    }

    if (
      !eventContext &&
      prevProps.selectedTnTTrailer &&
      prevProps.selectedTnTTrailer.assetId !== selectedTnTTrailer.assetId
    ) {
      this.onSwitchChange(false);
    }
  }

  onPosChange = (pos, firstPos) => {
    const { onHistoryPositionChange } = this.props;
    const { hours } = this.state;
    this.setState({ pos });
    onHistoryPositionChange(pos, hours, firstPos);
  };

  onHoursChanges = e => {
    const { pos } = this.state;
    const { onHistoryPositionChange } = this.props;
    const hours = parseInt(e.target.value, 10);
    triggerEvent('Map', 'Changed History Chart period', 'Live Map screen', hours);
    onHistoryPositionChange(pos, hours);
    this.setState({ hours });
  };

  onSwitchChange = checked => {
    const { toggleFollowTrailer } = this.props;
    toggleFollowTrailer(checked);
    this.setState({ checked });
  };

  render() {
    const { route, pos, dataHash, hours } = this.state;
    const {
      trailer,
      processing,
      error,
      resizeChart,
      alerts,
      events,
      isShowHours,
      hideIfNoData,
      historicalData,
      selectedTnTTrailer,
      eventContext,
      altTitle,
      isMetric,
      floatingPanel,
      intl: { formatMessage },
      region
    } = this.props;
    const isMobile = mobileOnly.matches;
    const isUS = isUsaCustomer(region);

    const { checked } = this.state;
    const speedValue = pos && pos.startSpeed ? Math.round(pos.startSpeed) : formatMessage(strings.short.hyphens);
    const axleLoadValue =
      pos && pos.startAxleLoad ? numeral(pos.startAxleLoad / 1000).format('0.0') : formatMessage(strings.short.hyphens);
    let data = convertData(eventContext ? historicalData : route, isMetric, isUS);
    const orderedEvents =
      events && events.length && events.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf());

    if (!isShowHours && orderedEvents) {
      const threeHours = 10800;
      const firstEventTime = orderedEvents[0].timestamp && orderedEvents[0].timestamp.unix() - threeHours;
      const lastEventTime =
        orderedEvents[orderedEvents.length - 1].timestamp &&
        orderedEvents[orderedEvents.length - 1].timestamp.unix() + threeHours;
      if (firstEventTime && lastEventTime) {
        data = data.filter(item => item.startTime > firstEventTime && item.startTime < lastEventTime);
      }
    }
    const selectedTrailer = eventContext ? trailer : selectedTnTTrailer;
    const isData = data && data.length !== 0;

    return (
      (!hideIfNoData || (hideIfNoData && isData)) && (
        <div className={isMobile || floatingPanel ? 'trailer-context-history-floating-pane' : ''}>
          <Row type='flex' style={{ padding: 8 }} justify='space-between' align='middle'>
            <Row type='flex'>
              {trailer && <h3>{trailer.defaultDisplayName}</h3>}
              {!trailer && altTitle && <h3>{altTitle}</h3>}
              {processing && <LoadingOutlined className='large-margin-left' />}
              {error && (
                <div>
                  <ExclamationCircleTwoTone twoToneColor='tomato' className='large-margin-left' />{' '}
                  {formatMessage(strings.short.error)}
                </div>
              )}
              {!processing && !isData && (
                <div>
                  <WarningTwoTone twoToneColor='orange' className='large-margin-left' />{' '}
                  {formatMessage(strings.short.noData)}
                </div>
              )}
            </Row>
            <Tooltip title={formatMessage(strings.short.followUnfollowTrailer)}>
              <Switch
                checked={checked}
                checkedChildren={<MonitorOutlined />}
                unCheckedChildren='-'
                onClick={this.onSwitchChange}
              />
            </Tooltip>
            {isShowHours && (
              <div>
                <Radio.Group size='small' value={hours.toString()} onChange={this.onHoursChanges}>
                  <Radio.Button style={{ width: 35, textAlign: 'center' }} value='6'>
                    6
                  </Radio.Button>
                  <Radio.Button style={{ width: 35, textAlign: 'center' }} value='12'>
                    12
                  </Radio.Button>
                  <Radio.Button style={{ width: 35, textAlign: 'center' }} value='24'>
                    24
                  </Radio.Button>
                  <Radio.Button style={{ width: 35, textAlign: 'center' }} value='48'>
                    48
                  </Radio.Button>
                </Radio.Group>{' '}
                {formatMessage(strings.short.hours)}
              </div>
            )}
            {pos && (
              <Row type='flex'>
                <div>
                  <strong>{formatMessage(strings.short.time)}</strong>:{' '}
                  {pos.startTime ? getTimeDate(pos.startTime * 1000) : formatMessage(strings.short.unknown)}
                </div>
                <div className='trailer-history-header-items'>
                  <strong>{formatMessage(strings.short.speed)}</strong>:
                  <div className='trailer-history-header-item-value-speed'>{speedValue} </div>
                  {' ' +
                    formatMessage(isMetric.distance ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour)}
                </div>
                <div className='trailer-history-header-items'>
                  <strong>{formatMessage(strings.short.axleLoad)}</strong>:
                  <div className='trailer-history-header-item-value-axle-load'>{axleLoadValue} </div>
                  {' ' + formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton)}
                </div>
              </Row>
            )}
          </Row>
          <Spin spinning={processing}>
            <TrailerHistoryChart
              events={events}
              alerts={alerts}
              data={data}
              dataHash={dataHash}
              selectedTrailer={selectedTrailer}
              onPosChange={this.onPosChange}
              resizeChart={resizeChart}
              isMetric={isMetric}
            />
          </Spin>
        </div>
      )
    );
  }
}

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    selectedTnTTrailer: store.appState.selectedTnTTrailer,
    trailerHistory: store.devices.history,
    processing: store.devices.processingApi,
    mapRoutes: store.devices.mapRoutes,
    error: store.devices.error,
    isMetric: store.auth.isMetric,
    region
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrailerHistory: (assetId, hours) => {
      dispatch(actions.trailerHistory.getTrailerHistory(assetId, hours));
    }
  };
}

TrailerHistory.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerHistory));
