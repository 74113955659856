import { defineMessages } from 'react-intl';

export const day = defineMessages({
  sunday: {
    id: 'day.sunday',
    defaultMessage: 'Sunday',
    description: 'day of the week'
  },
  monday: {
    id: 'day.monday',
    defaultMessage: 'Monday',
    description: 'day of the week'
  },
  tuesday: {
    id: 'day.tuesday',
    defaultMessage: 'Tuesday',
    description: 'day of the week'
  },
  wednesday: {
    id: 'day.wednesday',
    defaultMessage: 'Wednesday',
    description: 'day of the week'
  },
  thursday: {
    id: 'day.thursday',
    defaultMessage: 'Thursday',
    description: 'day of the week'
  },
  friday: {
    id: 'day.friday',
    defaultMessage: 'Friday',
    description: 'day of the week'
  },
  abbreviatedSunday: {
    id: 'day.abbrevSunday',
    defaultMessage: 'Sun',
    description: 'abbreviation of day of the week Sunday'
  },
  abbreviatedMonday: {
    id: 'day.abbreviatedMonday',
    defaultMessage: 'Mon',
    description: 'abbreviation of day of the week Monday'
  },
  abbreviatedTuesday: {
    id: 'day.abbreviatedTuesday',
    defaultMessage: 'Tue',
    description: 'abbreviation of day of the week Tuesday'
  },
  abbreviatedWednesday: {
    id: 'day.abbreviatedWednesday',
    defaultMessage: 'Wed',
    description: 'abbreviation of day of the week Wednesday'
  },
  abbreviatedThursday: {
    id: 'day.abbreviatedThursday',
    defaultMessage: 'Thu',
    description: 'abbreviation of day of the week Thursday'
  },
  abbreviatedFriday: {
    id: 'day.abbreviatedFriday',
    defaultMessage: 'Fri',
    description: 'abbreviation of day of the week Friday'
  }
});
