import actionTypes from '../actions/actionTypes';

export const initialEBPMSTrailerIDListState = {
  loaded: false,
  idList: [],
  error: null
};

export default function ebpmsTrailerIDListReducer(state = initialEBPMSTrailerIDListState, action) {
  switch (action.type) {
    case actionTypes.GET_EBPMS_TRAILERS_ID_LIST_SUCCESS:
      return {
        ...state,
        idList: action.idList,
        loaded: true
      };
    case actionTypes.GET_EBPMS_TRAILERS_ID_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
