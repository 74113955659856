import React from 'react';
import { UniqueCategories, NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { isUsaCustomer } from '../../../../../../helpers/functions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  const { ebsBrand } = rowData;

  if (ebsBrand) {
    return ebsBrand.toUpperCase();
  }
  return <NoValueSpan />;
};

const EbsBrandColumn = ({ trailers, region, formatMessage }) => {
  const isUS = isUsaCustomer(region);

  const filterFunction = checkedCategories => {
    trailers.filter('ebsBrand', t => {
      if (!t.ebsBrand) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.ebsBrand];
    });
  };

  const titleStr = strings.columns[isUS ? 'absBrand' : 'ebsBrand'];

  return {
    title: <Tooltip title={formatMessage(titleStr)}>{formatMessage(titleStr)}</Tooltip>,
    key: 'ebsBrand',
    dataIndex: 'ebsBrand',
    searchKey: formatMessage(strings.columns.ebsBrand),
    ebsBrand: formatMessage(strings.columns[isUS ? 'absBrand' : 'ebsBrand']),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <Cell rowData={rowData} />,
    categories: UniqueCategories(
      trailers.unfilteredData.map(t => t.ebsBrand),
      true
    ),
    sorter: () => trailers.sort('ebsBrand', (a, b) => (a.ebsBrand < b.ebsBrand ? -1 : 1)),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('ebsBrand'),
    width: 150
  };
};

// does this need to be absBrand too if in US?
const EbsBrandCSVColumnHeader = formatMessage => formatMessage(strings.columns.ebsBrand);
const EbsBrandCSVData = trailer => trailer.ebsBrand;
const EbsBrandCSVColumn = {
  header: EbsBrandCSVColumnHeader,
  data: EbsBrandCSVData
};

export default { table: EbsBrandColumn, csv: EbsBrandCSVColumn };
