import React from 'react';
import TrailerMapV2 from './TrailerMapV2/TrailerMapV2';
import { push } from 'connected-react-router';

import { useDispatch } from 'react-redux';

const TrailerMapV2Container = ({ trailers }) => {
  const dispatch = useDispatch();
  const filterTrailers = filterFunction => trailers.filter('map-selection', filterFunction);
  const clearFilter = () => {
    trailers.removeFilter('map-selection');
    const urlQueries = window.location.search;
    dispatch(push(`/live-map/${urlQueries}`));
  };
  const isMapSelectionFilterActive = trailers.trailerFilters['map-selection'] !== undefined;

  return (
    <TrailerMapV2
      trailers={trailers.data}
      filterTrailers={filterTrailers}
      clearFilter={clearFilter}
      isMapSelectionFilterActive={isMapSelectionFilterActive}
    />
  );
};

export default TrailerMapV2Container;
