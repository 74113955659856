import React from 'react';
import { connect } from 'react-redux';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import { Layout, Row, Col, Spin } from 'antd';
import actions from '../../actions/trailers';

import trailerImg from '../../assets/images/trailer.png';
import zfLogoWhite from '../../assets/images/ZFLogoWhite.png';
import TrailerIdCardContent from '../TrailerIdCard/TrailerIdCardContent';
import AccessRevoked from './AccessRevoked';
import LocaleString from '../Utils/LocaleString';
import './TempAccess.scss';

const { getTrailerDetails } = actions;

class TempAccess extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { match, retrieveTrailerDetails } = this.props;
    if (match.params.token) {
      retrieveTrailerDetails(match.params.token);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, retrieveTrailerDetails } = this.props;
    if (match.params.token && match.params.token !== prevProps.match.params.token) {
      retrieveTrailerDetails(match.params.token);
    }
  }

  render() {
    const { selectedTrailer, processing, error } = this.props;

    return (
      <div style={{ height: '100%' }}>
        <Layout>
          <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <img src={zfLogoWhite} alt='logo' className='header-company-logo' />
          </Layout.Header>
          <Layout.Content style={{ backgroundColor: 'white', marginTop: 64 }}>
            <Row type='flex' style={{ height: '100%' }}>
              <Col span={24}>
                <div style={{ height: '100%' }}>
                  {processing && (
                    <div style={{ textAlign: 'center', marginTop: 150 }}>
                      <Spin size='large' />
                    </div>
                  )}
                  {error && <AccessRevoked />}
                  {selectedTrailer && !processing && (
                    <div>
                      <PageHeader
                        title={
                          <div>
                            <LocaleString
                              type='phrase'
                              id='detailsForTrailerVal'
                              val={selectedTrailer.defaultDisplayName}
                            />
                          </div>
                        }
                        logo={<img alt='' style={{ height: 32, width: 32 }} src={trailerImg} />}
                      />
                      <TrailerIdCardContent
                        trailer={selectedTrailer}
                        isTempAccess={true}
                        location={this.props.location}
                        history={this.props.history}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    processing: store.trailerDetails.trailer.processing,
    error: store.trailerDetails.trailer.error,
    selectedTrailer: store.trailerDetails.trailer.item
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveTrailerDetails: tempToken => dispatch(getTrailerDetails('temp', tempToken))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TempAccess);
