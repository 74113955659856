import React from 'react';
import { getPlacesTriggerTitles, NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import moment from 'moment';
import AlertTimeline from '../../../../../charts/AlertTimeline/AlertTimelineComponent';
import './Alarm2hColumn.scss';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, region }) => {
  const { alerts } = rowData;
  const dates = [moment(moment().subtract(2, 'h'), 'YYYY/MM/DD'), moment(moment(), 'YYYY/MM/DD')];

  const placesAlerts = alerts?.filter(alert => alert.alarm === 'geofence');

  if (placesAlerts) {
    return (
      <div className={'chart-container'}>
        <AlertTimeline
          height={30}
          width={150}
          alerts={placesAlerts}
          region={region}
          dates={dates}
          dataHash={`${alerts.length}${alerts}${alerts.assetId}`}
          isHideTimeline
          placesTimeline
        />
      </div>
    );
  }

  return <NoValueSpan />;
};

const PlacesAlarm2hColumn = ({ trailers, region, formatMessage }) => {
  const filterFunction = checkedCategories =>
    trailers.filter('alerts', t => {
      if (!t.alerts) {
        return checkedCategories['unknown'];
      }

      const filtersArray = Object.keys(checkedCategories).filter(key => checkedCategories[key]);

      return t.alerts.some(a => filtersArray.includes(a.alarm));
    });

  return {
    title: (
      <Tooltip title={formatMessage(strings.short.placesAlarms2h)}>
        {formatMessage(strings.short.placesAlarms2h)}
      </Tooltip>
    ),
    key: 'placesAlarms2h',
    dataIndex: 'placesAlarms2h',
    searchKey: formatMessage(strings.short.alarm2h),
    categories: getPlacesTriggerTitles(),
    selectionSection: selectionHeaders.generalMonitoring.id,
    onFilter: filterFunction,
    render: rowData => <Cell rowData={rowData} region={region} />,
    removeFilter: () => trailers.removeFilter('placesAlarms2h'),
    width: 150
  };
};

export default { table: PlacesAlarm2hColumn, csv: null };
