import actionTypes from './actionTypes';
import { getValuePerDistance } from '../helpers/functions';
import { API_NAMES, odrBrakingDistanceDivider as distanceDivider } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

function getOdrBrakingEventsRequest() {
  return {
    type: actionTypes.GET_ODR_BRAKING_EVENTS_REQUEST
  };
}

function getOdrBrakingEventsSuccess(trailersData, final) {
  const trailers = trailersData.map(trailer => ({
    ...trailer,
    ABS_counter: getValuePerDistance(trailer.ABS_counter, trailer.mileage),
    RSS_counter_1: getValuePerDistance(trailer.RSS_counter_1, trailer.mileage),
    RSS_counter_2: getValuePerDistance(trailer.RSS_counter_2, trailer.mileage),
    Handbrake_counter: getValuePerDistance(trailer.Handbrake_counter, trailer.mileage)
  }));

  return {
    type: actionTypes.GET_ODR_BRAKING_EVENTS_SUCCESS,
    trailers,
    final
  };
}

function getOdrBrakingEventsFailure(error) {
  return {
    type: actionTypes.GET_ODR_BRAKING_EVENTS_FAILURE,
    error
  };
}

function getOdrBrakingAvgRequest() {
  return {
    type: actionTypes.GET_ODR_BRAKING_AVG_REQUEST
  };
}

function getOdrBrakingAvgSuccess(averagesData) {
  const averages = {
    ...averagesData,
    ABS_counter: averagesData.ABS_counter * distanceDivider,
    RSS_counter_1: averagesData.RSS_counter_1 * distanceDivider,
    RSS_counter_2: averagesData.RSS_counter_2 * distanceDivider,
    Handbrake_counter: averagesData.Handbrake_counter * distanceDivider
  };
  return {
    type: actionTypes.GET_ODR_BRAKING_AVG_SUCCESS,
    averages
  };
}

function getOdrBrakingAvgFailure(error) {
  return {
    type: actionTypes.GET_ODR_BRAKING_AVG_FAILURE,
    error
  };
}

function callAPI(dispatch, lastEvaluatedKey) {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null
  };

  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailersBrakeValues', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getOdrBrakingEventsSuccess(res.Items, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getOdrBrakingEventsFailure(err)));
}

function getOdrBrakingEvents() {
  return (dispatch, getState) => {
    const store = getState();
    const { items, processing } = store.trailersOdrBraking;
    if (!items?.length && !processing) {
      dispatch(getOdrBrakingEventsRequest());
      callAPI(dispatch);
    }
    getOdrBrakingAvg();
  };
}

function getOdrBrakingAvg() {
  return (dispatch, getState) => {
    const store = getState();
    const { averages, avgProcessing } = store.trailersOdrBraking;
    if (!averages && !avgProcessing) {
      dispatch(getOdrBrakingAvgRequest());
      worker
        .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'allTrailers/avgBrakeValues' })
        .then(res => {
          return dispatch(getOdrBrakingAvgSuccess(res));
        })
        .catch(err => dispatch(getOdrBrakingAvgFailure(err)));
    }
  };
}

export default {
  getOdrBrakingEvents,
  getOdrBrakingAvg
};
