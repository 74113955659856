import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.code) {
    return <p>{rowData.code}</p>;
  }
  return <NoValueSpan />;
};

const CodeColumn = ({ trailers, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.code)}>{formatMessage(strings.short.code)}</Tooltip>,
    key: 'code',
    dataIndex: 'code',
    searchKey: formatMessage(strings.short.code),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} />,
    search: searchString =>
      trailers.filter('code', t => t.code?.toString().toLowerCase().includes(searchString.toLowerCase())),
    removeSearch: () => trailers.removeFilter('code'),
    width: 150
  };
};

const CodeCSVColumnHeader = formatMessage => formatMessage(strings.short.code);
const CodeCSVData = trailer => trailer.code;
const CodeCSVColumn = {
  header: CodeCSVColumnHeader,
  data: CodeCSVData
};

export default { table: CodeColumn, csv: CodeCSVColumn };
