import React from 'react';
import { Tooltip } from 'antd';
import * as strings from '../../../../../../helpers/defaultStrings';
import { getTimeDate, isInactive } from '../../../../../../helpers/functions';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';

function convertDistance(value = 0, isMetric) {
  const computedValue = isMetric ? value : value * 0.621371;
  return computedValue.toFixed(1);
}

const Cell = ({ rowData, formatMessage, isMetric }) => {
  if (rowData.odometer == null) {
    return <NoValueSpan />;
  }
  const distance = convertDistance(rowData.odometer, isMetric.distance);
  const distanceMetric = formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile);
  const title = rowData?.ebsTime
    ? formatMessage(strings.short.ebsTimeVal, {
        value: distance,
        time: getTimeDate(rowData.ebsTime * 1000)
      })
    : formatMessage(strings.short.odometer);

  if (isInactive(rowData)) {
    return (
      <Tooltip title={title} placement='topLeft'>
        <span style={{ color: '#ccc' }}>
          {distance} <small>{distanceMetric}</small>
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      {distance} <small>{distanceMetric}</small>
    </span>
  );
};

const OdometerColumn = ({ trailers, isMetric, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.odometer)}>{formatMessage(strings.short.odometer)}</Tooltip>,
    key: 'odometer',
    dataIndex: 'odometer',
    searchKey: formatMessage(strings.short.odometer),
    selectionSection: selectionHeaders.generalMonitoring.id,
    render: rowData => <Cell rowData={rowData} isMetric={isMetric} formatMessage={formatMessage} />,
    sorter: () => trailers.sort('odometer', (a, b) => (a.odometer < b.odometer ? -1 : 1)),
    width: 150
  };
};

const OdometerCSVHeader = formatMessage => formatMessage(strings.short.odometer);
const OdometerCSVData = (trailer, _, isMetric) => {
  if (trailer?.odometer) {
    return convertDistance(trailer.odometer, isMetric.distance);
  }

  return '';
};
const OdometerCSVColumn = {
  header: OdometerCSVHeader,
  data: OdometerCSVData
};

export default { table: OdometerColumn, csv: OdometerCSVColumn };
