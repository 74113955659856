import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../../helpers/defaultStrings';

export const LightMonitoringModal = () => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className='large-margin-top'>{formatMessage(strings.lightMonitoringStatus.columnDescription)}</div>
      <div className='large-margin-top'>
        <div>
          <span className='anomaly'>&#9679;</span>{' '}
          {formatMessage(strings.lightMonitoringStatus.warningStatusDescription)}
        </div>
        <div>
          <span className='outage'>&#9679;</span> {formatMessage(strings.lightMonitoringStatus.outageStatusDescription)}
        </div>
        <div>
          <span className='good'>&#9679;</span> {formatMessage(strings.lightMonitoringStatus.goodStatusDescription)}
        </div>
        <div>
          <span className='unknown'>&#9679;</span>{' '}
          {formatMessage(strings.lightMonitoringStatus.unknownStatusDescription)}
        </div>
      </div>
      <div className='large-margin-top'>{formatMessage(strings.lightMonitoringStatus.fullInfo)}</div>
    </div>
  );
};
