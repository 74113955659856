import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { isInactive } from '../../../../../../helpers/functions';
import { Tooltip } from 'antd';
import { BrakesMicroView } from '../../../../../Utils/TireMicroView';
import './BrakeLiningColumn.scss';
import { selectionHeaders } from '../../../../../../helpers/constants';

const formatBrakePadsCsvValue = brakePads => {
  const reducer = (acc, pad) => (acc === 'Not OK' ? acc : pad.lining.suff ? 'OK' : 'Not OK');
  return brakePads.reduce(reducer, '');
};

const Cell = ({ brakePads, trailer }) => {
  let status = 0;
  if (brakePads) {
    brakePads.forEach(pad => {
      if (pad.lining) {
        if (status !== 2) {
          status = pad.lining.suff ? 1 : 2;
        }
      }
    });
  }

  const light = (status, semiTransparent) => {
    if (!status) return '-';
    let backgroundColor;

    if (status === 1) {
      backgroundColor = semiTransparent ? '#eef8f2' : '#59bc7c';
    } else {
      backgroundColor = semiTransparent ? '#f9ecee' : '#b00920';
    }
    const border = status === 1 ? '1px solid #59bc7c' : '1px solid #b00920';
    return (
      <div
        className={'brake-lining-container'}
        style={{
          width: 15,
          height: 15,
          borderRadius: 15,
          backgroundColor,
          border
        }}
      />
    );
  };

  const semiTransparent = isInactive(trailer);
  const lastConnect = semiTransparent && new Date(trailer.ebsTime * 1000);

  return (
    <div>
      {lastConnect && (
        <Tooltip
          title={
            <BrakesMicroView
              brakePads={brakePads}
              trailer={trailer}
              semiTransparent={semiTransparent}
              lastConnect={lastConnect}
            />
          }
          placement='topLeft'
        >
          <span>{light(status, semiTransparent)}</span>
        </Tooltip>
      )}
      {!lastConnect && <span>{light(status, semiTransparent)}</span>}
    </div>
  );
};

const BrakeLiningColumn = ({ formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.short.brakeLining)}>{formatMessage(strings.short.brakeLining)}</Tooltip>
    ),
    key: 'brakePads',
    dataIndex: 'brakePads',
    searchKey: formatMessage(strings.short.brakeLining),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell brakePads={rowData.brakePads} trailer={rowData} />,
    width: 150
  };
};

const BrakeLiningHeader = formatMessage => formatMessage(strings.short.brakeLining);
const BrakeLiningCSVData = trailer => trailer.brakePads && formatBrakePadsCsvValue(trailer.brakePads);
const BrakeLiningCSVColumn = {
  header: BrakeLiningHeader,
  data: BrakeLiningCSVData
};

export default { table: BrakeLiningColumn, csv: BrakeLiningCSVColumn };
