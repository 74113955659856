import React, { useCallback, useEffect, useMemo, useState, useRef, useLayoutEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addEndPoint, convertData, getShortenedRoute } from '../../../../../helpers/trailer';
import { isUsaCustomer } from '../../../../../helpers/functions';
import { trailerInfoPanel as trailerInfoPanelConstant, ebsBrand } from '../../../../../helpers/constants';
import TrailerInfoPanelHeader from '../../../../TrailerMap/TrailerInfoPanelHeader';
import actions from '../../../../../actions';

import './TrailerInforPanel.scss';
import TrailerInfoPanelModalSelector from '../../../../TrailerMap/TrailerInfoPanelModalSelector';
import TrailerIdCardContentGeneralInfo from '../../../../TrailerIdCard/TrailerIdCardContentGeneralInfo';
import TrailerHistoryChart from '../../../../charts/TrailerHistoryChart/TrailerHistoryChartComponent';
import TrailerWheelsChartComponent from '../../../../charts/TrailerWheelsChart/TrailerWheelsChartComponent';
import TrailerFCFChartComponent from '../../../../charts/TrailerFCFChart/TrailerFCFChartComponent';
import WheelLayout from '../../../../TrailerMap/WheelLayout';
import LightMonitoringLayout from '../../../../TrailerMap/LightMonitoringLayout';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  selectIsMetric,
  selectRegion,
  selectAlertItems,
  selectProcessingAlerts,
  getSelectedTnTTrailer,
  getSelectedTnTTrailerAssetId,
  selectProductFeatures,
  selectTrailerInfoComponents
} from '../../../../../selectors';
import { getOverwriteTrailerInfoPanelSettings } from './helpers/functions';
import { getAllAlerts } from '../../../../../actions/thunks/get-all-alerts';
import { useDeepCompareEffect } from '../../../../../customHooks/useDeepCompareEffect';
import {
  selectMapRoutes,
  selectRetrievedHistory,
  selectHistory,
  selectProcessingApi
} from '../../../../../selectors/devices';

const TrailerInfoPanelV2 = ({
  showBackButton = true,
  showSettingsButton = true,
  showLivemapHealthButtons = true,
  showTimeSelection = true,
  currentPos = {},
  limitStartDate,
  limitEndDate,
  limitHours = trailerInfoPanelConstant.defaultTimeRange
}) => {
  const dispatch = useCallback(useDispatch(), []);

  const selectedTnTTrailer = useSelector(getSelectedTnTTrailer);
  const selectedTnTTrailerAssetId = useSelector(getSelectedTnTTrailerAssetId);
  const selectedTrailerFullInfo = useSelector(store => store.trailerDetails.trailer.item);
  const mapRoutes = useSelector(selectMapRoutes);
  const retrievedHistory = useSelector(selectRetrievedHistory);
  const trailerHistory = useSelector(selectHistory);
  const isMetric = useSelector(selectIsMetric);
  const region = useSelector(selectRegion);
  const processing = useSelector(selectProcessingApi);
  const processingAlerts = useSelector(selectProcessingAlerts);
  const isUS = isUsaCustomer(region);

  const showAlerts = useSelector(store => store.liveMap.showAlerts);
  const allAlerts = useSelector(selectAlertItems);
  const alerts = useMemo(
    () => allAlerts.filter(alert => selectedTnTTrailerAssetId === alert.assetId),
    [allAlerts, selectedTnTTrailerAssetId]
  );
  const setLiveMapEndDate = date => dispatch(actions.liveMapActions.setLiveMapEndDate(date));
  const toggleFollowTrailer = value => dispatch(actions.liveMapActions.setLiveMapFollowTrailer(value));
  const onHistoryPositionChange = (newPos, hours) => {
    dispatch(actions.liveMapActions.setLiveMapHistoryPosition(newPos));
    dispatch(actions.liveMapActions.setLiveMapHours(hours));
  };

  const componentSettings = useSelector(selectTrailerInfoComponents);

  const productFeatures = useSelector(selectProductFeatures);

  const [hours, setHours] = useState(limitHours);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [data, setData] = useState(null);
  const [preventChartReload, setPreventChartReload] = useState(false);
  const [pickerSelectedDateTime, setPickerSelectedDateTime] = useState(null);
  const [dataHash, setDataHash] = useState(null);
  const [pos, setPos] = useState(currentPos);
  const [selectedWheels, setSelectedWheels] = useState({});
  const [startDate, setStartDate] = useState(limitStartDate);
  const [endDate, setEndDate] = useState(limitEndDate || Math.round(Date.now() / 1000));
  const [trailerInfoComponents, setTrailerInfoComponents] = useState([]);

  const [resizeChart, setResizeChart] = useState(0);

  const containterRef = useRef(null);
  useLayoutEffect(() => {
    if (containterRef?.current == null) return;
    const observer = new ResizeObserver(() => setResizeChart(Math.random()));
    observer.observe(containterRef.current);
    return () => observer.disconnect();
  }, []);

  const [overWriteShowSettings, setOverWriteShowSettings] = useState({});
  const [componentMap, setComponentMap] = useState({});

  useEffect(() => {
    const newOverWriteSettings = getOverwriteTrailerInfoPanelSettings(selectedTnTTrailer);
    setOverWriteShowSettings(newOverWriteSettings);
    setComponentMap(getComponentMap(newOverWriteSettings));
    if (!selectedTnTTrailerAssetId) {
      setData(null);
      setPos({});
      setPickerSelectedDateTime(null);
      setHours(limitHours);
    }
  }, [selectedTnTTrailerAssetId]);

  useEffect(() => {
    if (selectedTnTTrailerAssetId) {
      dispatch(actions.trailerHistory.getTrailerLastTPMS(selectedTnTTrailerAssetId, startDate, endDate));
    }
  }, [selectedTnTTrailerAssetId, startDate, endDate]);

  const getComponentMap = newOverwriteSettings => {
    const forceHide = newOverwriteSettings ?? overWriteShowSettings;
    return {
      historySpeedLoad: TrailerHistoryChart,
      // components to be created in the next task
      // eslint-disable-next-line react/display-name
      historyTPMSPressure: !forceHide.historyTPMSPressure && TrailerWheelsChartComponent,
      // eslint-disable-next-line react/display-name
      historyTPMSTemperature: !forceHide.historyTPMSTemperature && TrailerWheelsChartComponent,
      historyFCF: productFeatures.livemapFCF ? TrailerFCFChartComponent : false,
      // eslint-disable-next-line react/display-name
      trailerDetails: !forceHide.trailerDetails && TrailerIdCardContentGeneralInfo,
      // eslint-disable-next-line react/display-name
      wheelLayout: !forceHide.wheelLayout && WheelLayout,
      // eslint-disable-next-line react/display-name
      lightMonitoring: !forceHide.lightMonitoring ? LightMonitoringLayout : false
    };
  };

  const mappedRoutes = mapRoutes[selectedTnTTrailerAssetId] || [];

  const onVisibilityChange = newOverwriteSettings => {
    setOverWriteShowSettings(newOverwriteSettings);
    setComponentMap(getComponentMap(newOverwriteSettings));
  };

  const onChangeEndDateTime = selectedDateTime => {
    setPickerSelectedDateTime(selectedDateTime);
    setPos({ posX: 0 });
  };
  const onToggleWheel = useCallback(
    key => {
      const newSelectedWheels = { ...selectedWheels };
      if (newSelectedWheels[key]) {
        delete newSelectedWheels[key];
      } else {
        newSelectedWheels[key] = 1;
      }
      setSelectedWheels(newSelectedWheels);
    },
    [selectedWheels]
  );

  const onPosChange = useCallback(
    newPos => {
      // posX is only present when using the replay functionality, so we only update if:
      // - the user never used the replay (pos.posX null or !pos or !pos.posX)
      // - the user is using the replay cursor (newPos.posX not null)
      // - the user moved the cursor to the end of the chart to get real data (end = cursor is in last 5 minutes range)
      if (!pos || !pos.posX || !!newPos.posX || (pos.time && pos.time.getTime() > Date.now() - 5 * 60 * 1000)) {
        setPos(newPos);
        onHistoryPositionChange(newPos, hours);
      }
    },
    // eslint-disable-next-line
    [hours, pos]
  );

  const onBackButtonClick = () => {
    pickerSelectedDateTime && dispatch(actions.trailerHistory.getTrailerHistory(selectedTnTTrailerAssetId, 2));
    dispatch(actions.appState.updateSelectedTnTTrailer(null));
  };

  // Fetch Alarms using dates when changing the date picker
  useEffect(() => {
    if (pickerSelectedDateTime && showAlerts) {
      dispatch(getAllAlerts([moment(pickerSelectedDateTime).subtract(hours, 'hours'), moment(pickerSelectedDateTime)]));
    }
    // eslint-disable-next-line
  }, [showAlerts, pickerSelectedDateTime, hours]);

  // reset the alarms and datepicker selected date when going back to all trailer list / map view
  // eslint-disable-next-line
  useEffect(
    () => () => {
      if (showTimeSelection) {
        dispatch(getAllAlerts());
        setLiveMapEndDate(null);
      }
    },
    // eslint-disable-next-line
    [showTimeSelection]
  );

  useEffect(() => {
    if (setLiveMapEndDate) {
      setLiveMapEndDate(endDate);
      if (endDate && data && data.length > 0) {
        onPosChange({ ...data[data.length - 1], time: endDate * 1000 }, false);
      }
    }
    // eslint-disable-next-line
  }, [data, endDate]);

  useDeepCompareEffect(() => {
    if (!retrievedHistory[selectedTnTTrailerAssetId]) {
      dispatch(actions.trailerHistory.getTrailerHistory(selectedTnTTrailerAssetId, hours, endDate));
    } else {
      const trailerMapRoutes = mapRoutes[selectedTnTTrailerAssetId] || [];
      const route = getShortenedRoute(addEndPoint(trailerMapRoutes), hours, endDate);
      setData(convertData(route, isMetric, isUS));
      setDataHash(retrievedHistory[selectedTnTTrailerAssetId].dataHash);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTnTTrailer, retrievedHistory, selectedTnTTrailerAssetId, hours, isMetric, endDate]);

  useEffect(() => {
    // if picker changed to live data (picker empty), set flag as not active
    setPreventChartReload(false);
  }, [pickerSelectedDateTime, hours]);

  useEffect(() => {
    let timeNow;
    let timeAgo;

    if (limitEndDate) {
      timeNow = limitEndDate;
    } else {
      timeNow = Math.round(
        pickerSelectedDateTime ? pickerSelectedDateTime.toDate().getTime() / 1000 : Date.now() / 1000
      );
    }

    if (limitStartDate) {
      timeAgo = limitStartDate;
    } else {
      timeAgo = timeNow - hours * 60 * 60;
    }

    setStartDate(timeAgo);
    setEndDate(timeNow);
  }, [hours, mappedRoutes.length, pickerSelectedDateTime, limitStartDate, limitEndDate]);

  useEffect(() => {
    if (retrievedHistory[selectedTnTTrailerAssetId] && !processing && !preventChartReload) {
      dispatch(actions.trailerHistory.getTrailerHistory(selectedTnTTrailerAssetId, hours, endDate));
      // after load first time, check if the time travel is used, if yes set flag to true and won't reload the chart again
      setPreventChartReload(!!pickerSelectedDateTime || !!limitStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedRoutes.length, hours, startDate, endDate, limitStartDate, preventChartReload]);

  useEffect(() => {
    if (data && !pos) {
      onPosChange({ ...data[data.length - 1], time: endDate * 1000, reset: true }, true);
    }
  }, [data, pos, onPosChange, endDate]);

  useEffect(() => {
    const componentsToShow = Object.entries(componentSettings)
      .map(([, component]) => component)
      .filter(component => component.visible)
      .sort((cA, cB) => cA.order - cB.order);

    setTrailerInfoComponents(componentsToShow);
  }, [componentSettings, componentMap]);

  useEffect(() => {
    if (!overWriteShowSettings.trailerDetails) {
      if (selectedTnTTrailer.ebsBrand === ebsBrand.wabco) {
        dispatch(actions.trailers.getTrailerDetails(selectedTnTTrailerAssetId));
      } else {
        dispatch(actions.trailers.clearTrailerDetails());
      }
    }
  }, [selectedTnTTrailerAssetId, selectedTnTTrailer.ebsBrand, dispatch, overWriteShowSettings.trailerDetails]);

  return (
    <div className='trailer-info-panel' ref={containterRef}>
      <div className='trailer-info-panel__title_container'>
        <div>
          {showBackButton && (
            <button className='arrow-left-btn' onClick={onBackButtonClick}>
              <ArrowLeftOutlined />
            </button>
          )}
          <span className='ant-page-header-heading-title'>{selectedTnTTrailer.defaultDisplayName}</span>
          <span className='sub-title'>{selectedTnTTrailer.vin || selectedTnTTrailer.vinNumber || '-'}</span>
        </div>

        <TrailerInfoPanelModalSelector
          showSettingsButton={showSettingsButton}
          showLivemapHealthButtons={showLivemapHealthButtons}
          disableTrailerIdCardButton={selectedTnTTrailer.ebsBrand !== ebsBrand.wabco}
          overWriteShowSettings={overWriteShowSettings}
          onVisibilityChange={onVisibilityChange}
        />
      </div>
      <TrailerInfoPanelHeader
        data={data}
        pos={pos}
        isMetric={isMetric}
        onChangeEndDateTime={onChangeEndDateTime}
        pickerOpen={pickerOpen}
        setPickerOpen={setPickerOpen}
        hours={hours}
        pickerSelectedDateTime={pickerSelectedDateTime}
        setHours={setHours}
        toggleFollowTrailer={toggleFollowTrailer}
        showTimeSelection={showTimeSelection}
        onHistoryPositionChange={onHistoryPositionChange}
        isUS={isUS}
      />
      <Spin spinning={processing && !data}>
        {trailerInfoComponents.map(infoToShow => {
          const ComponentMapped = componentMap[infoToShow.key];

          const componentProps = {
            view: infoToShow.key,
            processingAlerts,
            processing,
            alerts,
            showAlerts,
            data,
            pos,
            selectedWheels,
            dataHash,
            hours,
            startDate,
            endDate,
            selectedTrailer: selectedTnTTrailer,
            trailer: selectedTrailerFullInfo,
            onPosChange,
            onToggleWheel,
            resizeChart,
            isMetric,
            showTitle: true,
            trailerHistory,
            hideAxleView: true,
            onChangeEndDateTime,
            setPickerOpen
          };

          return (
            ComponentMapped && (
              <div
                key={infoToShow.key + '-' + selectedTnTTrailerAssetId}
                className={`trailer-info-panel__component trailer-info-panel__component--${infoToShow.key}`}
              >
                <ComponentMapped {...componentProps} />
              </div>
            )
          );
        })}
      </Spin>
    </div>
  );
};

export default TrailerInfoPanelV2;
