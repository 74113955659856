/*// GEOFENCE INTEGRATION <- search for this term to find all validation for geofence and integration*/
// enable-places-notifications-page - when this FF is removed theres no need for default vs with geofence types;
export const notificationsAvailable = {
  device: {
    amberLight: 'amberLight',
    redLight: 'redLight',
    absWarningLamp: 'absWarningLamp',
    tireExtremeOverPressure: 'tireExtremeOverPressure',
    tireOverPressure: 'tireOverPressure',
    tireExtremeUnderPressure: 'tireExtremeUnderPressure',
    tireUnderPressure: 'tireUnderPressure',
    harshBrakeEvent: 'harshBrakeEvent',
    abs: 'abs',
    withoutISOCable: 'withoutISOCable',
    rss: 'rss',
    geofence: 'geofence',
    groteLightAnomaly: 'groteLightAnomaly',
    groteLightOutage: 'groteLightOutage',
    wheelEndOverTemperature: 'wheelEndOverTemperature',
    parkedWithLowPressure: 'parkedWithLowPressure',
    parkedWithSlowLeak: 'parkedWithSlowLeak',
    slowLeak: 'slowLeak',
    criticalLeak: 'criticalLeak',
    electricalFault: 'electricalFault',
    sensorNotFound: 'sensorNotFound',
    tireLowPressure: 'tireLowPressure'
  },
  geofence: {
    blackSpot: 'blackSpot',
    customerSite: 'customerSite',
    driverHomeAddress: 'driverHomeAddress',
    ferryTerminal: 'ferryTerminal',
    industrialZone: 'industrialZone',
    maintenancePoint: 'maintenancePoint',
    openParkingArea: 'openParkingArea',
    other: 'other',
    petrolStation: 'petrolStation',
    restArea: 'restArea',
    secureParking: 'secureParking'
  },
  report: { monthlyMileageReport: 'monthlyMileageReport' }
};

const americanDeviceNotificationTypes = [
  notificationsAvailable.device.absWarningLamp,
  notificationsAvailable.device.tireExtremeOverPressure,
  notificationsAvailable.device.tireOverPressure,
  notificationsAvailable.device.tireExtremeUnderPressure,
  notificationsAvailable.device.tireUnderPressure,
  notificationsAvailable.device.harshBrakeEvent,
  notificationsAvailable.device.abs,
  notificationsAvailable.device.withoutISOCable,
  notificationsAvailable.device.rss,
  notificationsAvailable.device.groteLightAnomaly,
  notificationsAvailable.device.groteLightOutage,
  notificationsAvailable.device.wheelEndOverTemperature,
  notificationsAvailable.device.parkedWithLowPressure,
  notificationsAvailable.device.parkedWithSlowLeak,
  notificationsAvailable.device.slowLeak,
  notificationsAvailable.device.criticalLeak,
  notificationsAvailable.device.tireLowPressure,
  notificationsAvailable.device.electricalFault,
  notificationsAvailable.device.sensorNotFound
];

export const americaNotificationTypes = {
  device: americanDeviceNotificationTypes,
  geofence: Object.values(notificationsAvailable.geofence),
  report: Object.values(notificationsAvailable.report)
};

export const americaWithGeofenceNotificationTypes = {
  device: [
    // enable-places-notifications-page remove geofence from here on removal of flag
    notificationsAvailable.device.geofence,
    ...americanDeviceNotificationTypes
  ],
  geofence: Object.values(notificationsAvailable.geofence),
  report: Object.values(notificationsAvailable.report)
};

export const defaulNotificationTypes = {
  device: [
    notificationsAvailable.device.amberLight,
    notificationsAvailable.device.redLight,
    notificationsAvailable.device.tireExtremeOverPressure,
    notificationsAvailable.device.tireOverPressure,
    notificationsAvailable.device.tireExtremeUnderPressure,
    notificationsAvailable.device.tireUnderPressure,
    notificationsAvailable.device.harshBrakeEvent,
    notificationsAvailable.device.abs,
    notificationsAvailable.device.withoutISOCable,
    notificationsAvailable.device.rss,
    notificationsAvailable.device.groteLightAnomaly,
    notificationsAvailable.device.groteLightOutage
  ],
  geofence: Object.values(notificationsAvailable.geofence),
  report: Object.values(notificationsAvailable.report)
};

export const defaultWithGeofenceNotificationTypes = {
  device: [
    notificationsAvailable.device.amberLight,
    notificationsAvailable.device.redLight,
    // enable-places-notifications-page remove geofence from here on removal of flag
    notificationsAvailable.device.geofence,
    notificationsAvailable.device.tireExtremeOverPressure,
    notificationsAvailable.device.tireOverPressure,
    notificationsAvailable.device.tireExtremeUnderPressure,
    notificationsAvailable.device.tireUnderPressure,
    notificationsAvailable.device.harshBrakeEvent,
    notificationsAvailable.device.abs,
    notificationsAvailable.device.withoutISOCable,
    notificationsAvailable.device.rss,
    notificationsAvailable.device.groteLightAnomaly,
    notificationsAvailable.device.groteLightOutage
  ],
  geofence: Object.values(notificationsAvailable.geofence),
  report: Object.values(notificationsAvailable.report)
};
