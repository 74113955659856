import actionTypes from './actionTypes';
import { addErrorMessage } from './messages';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

function getBrakingEventsRequest(deviceId) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_REQUEST,
    deviceId
  };
}

function getBrakingEventsSuccess(events, deviceId, final) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_SUCCESS,
    events,
    deviceId,
    final
  };
}

function getBrakingEventsFailure(deviceId, error) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_FAILURE,
    deviceId,
    error
  };
}

export function setNoBrakingEvents(deviceId) {
  return {
    type: actionTypes.NO_BRAKE_EVENTS,
    deviceId
  };
}

export function getAllBrakingEventsPaginated(deviceId, startTime, endTime) {
  return dispatch => {
    dispatch(getBrakingEventsRequest(deviceId));
    getBrakingEvents(deviceId, startTime, endTime, null, dispatch);
  };
}

export function getBrakingEventsOld(deviceId, startTime, endTime) {
  return dispatch => {
    // TODO if (getState().brakingEvents.deviceId === deviceId), part of the data is already loaded: find a smarter way to fetch it
    dispatch(getBrakingEventsRequest(deviceId));
    const queryStringParameters = {};
    if (startTime) queryStringParameters.startTime = +startTime;
    if (endTime) queryStringParameters.endTime = +endTime;

    worker
      .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: `brakeEvents/${deviceId}`, queryStringParameters })
      .then(res => {
        if (!res || !Array.isArray(res)) {
          throw new Error('wrong behaviour from API');
        }
        if (res.length === 0) {
          dispatch(addErrorMessage(`Sorry, trailer ${deviceId} has no brake events.`));
          throw new Error(`${deviceId} has no brake events.`);
        }
        // endTime is not implemented yet !
        const resWithEndtime = res.filter(be => +be.start_time < +endTime);
        return dispatch(getBrakingEventsSuccess(resWithEndtime, deviceId, true));
      })
      .catch(err => dispatch(getBrakingEventsFailure(deviceId, err)));
  };
}

function getBrakingEvents(deviceId, startTime, endTime, page, dispatch) {
  // if (getState().brakingEvents.deviceId === deviceId), part of the data is already loaded: find a smarter way to fetch it
  const queryStringParameters = {};
  if (startTime) {
    queryStringParameters.startTime = +startTime;
  }
  if (endTime) {
    queryStringParameters.endTime = +endTime;
    queryStringParameters.nextPage = page;
  }
  queryStringParameters.limit = 800;

  worker
    .Api({
      method: 'get',
      api: API_NAMES.EBPMS,
      endpoint: `/v2/brakeEvents/${deviceId}`,
      queryStringParameters
    })
    .then(res => {
      const {
        data,
        metadata: { nextPage }
      } = res;
      const isFinal = !nextPage;
      if (!isFinal) {
        getBrakingEvents(deviceId, startTime, endTime, nextPage, dispatch);
      }
      if (!data || !Array.isArray(data)) {
        throw new Error('wrong behaviour from API');
      }
      if (data.length === 0 && page === 1) {
        dispatch(addErrorMessage(`Sorry, trailer ${deviceId} has no brake events.`));
        throw new Error(`${deviceId} has no brake events.`);
      }
      return dispatch(getBrakingEventsSuccess(data, deviceId, isFinal));
    })
    .catch(err => dispatch(getBrakingEventsFailure(deviceId, err)));
}

// telemetry
function getTelemetryRequest(event, eventId) {
  return {
    type: actionTypes.GET_TELEMETRY_REQUEST,
    event: event,
    eventId
  };
}

function getTelemetrySuccess(items, eventId, final) {
  return {
    type: actionTypes.GET_TELEMETRY_SUCCESS,
    items,
    eventId,
    final
  };
}

function getTelemetryFailure(eventId, error) {
  return {
    type: actionTypes.GET_TELEMETRY_FAILURE,
    eventId,
    error
  };
}

function selectEvent(event) {
  return (dispatch, getState) => {
    const {
      brakingEvents: {
        selected: { event: prevEvent }
      }
    } = getState();
    if (event && (!prevEvent || prevEvent.eventId !== event.eventId)) {
      dispatch(getTelemetryRequest(event, event.eventId));
      worker
        .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: `trail/${event.eventId}` })
        .then(res => {
          if (!res || !Array.isArray(res)) {
            throw new Error('wrong behaviour from API');
          }
          if (res.length === 0) {
            throw new Error(`${event.eventId} has no telemetry data.`);
          }
          return dispatch(getTelemetrySuccess(res, event.eventId, true));
        })
        .catch(err => dispatch(getTelemetryFailure(event.eventId, err)));
    } else {
      dispatch(getTelemetryRequest(null));
    }
  };
}

export default {
  getBrakingEvents,
  selectEvent,
  setNoBrakingEvents,
  getBrakingEventsOld,
  getAllBrakingEventsPaginated
};
