import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import EventSummary from '../../../../../Events/EventSummary';
import moment from 'moment';
import { Tooltip } from 'antd';
import './RecentSummaryColumn.scss';
import { selectionHeaders } from '../../../../../../helpers/constants';

const Cell = ({ rowData, isMetric }) => {
  const today = moment.utc();
  const startDate = today.clone().subtract(1, 'year').toDate();
  const endDate = today.toDate();

  if (rowData.events) {
    if (rowData.events?.length || rowData.odoEvents?.length || rowData.eventDomain) {
      return (
        <EventSummary
          events={rowData.events}
          start={startDate}
          end={endDate}
          eventDomain={rowData.eventDomain}
          odoEvents={rowData.odoEvents}
          odoStart={Math.max(rowData.lastMileage || 0, rowData.odoEventDomain ? rowData.odoEventDomain[1] : 0) - 10000}
          odoEnd={Math.max(rowData.lastMileage || 0, rowData.odoEventDomain ? rowData.odoEventDomain[1] : 0)}
          odoEventDomain={rowData.odoEventDomain}
          width={150}
          height={8}
          showWithoutEvents
          isMetric={isMetric}
          className={'summary-chart'}
        />
      );
    }
  }
  return <NoValueSpan />;
};

const RecentSummaryColumn = ({ isMetric, formatMessage, region }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.phrase.recentSummary)}>
        {formatMessage(strings.phrase.recentSummary)}
      </Tooltip>
    ),
    key: 'recentSummary',
    dataIndex: 'events',
    searchKey: formatMessage(strings.phrase.recentSummary),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} isMetric={isMetric} region={region} />,
    width: 200
  };
};

export default { table: RecentSummaryColumn, csv: null };
