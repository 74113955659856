import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import placesActions from '../actions/places';
import { useAuth0 } from '@auth0/auth0-react';

const ScalarDataLoader = ({ children }) => {
  const user = useSelector(store => store.auth.user);
  const places = useSelector(store => store.places);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !places.loaded && !places.loading) {
      getAccessTokenSilently().then(token => {
        if (token) {
          dispatch(placesActions.getPlaces(token));
        }
      });
    }
    // eslint-disable-next-line
  }, [user, places]);

  return <>{children}</>;
};

export default ScalarDataLoader;
