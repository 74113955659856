import store from '../app/configureStore.js';
import { getIsTypeGeofence } from '../helpers/alarms/functions.js';
import { isScalar } from '../helpers/functions.js';
import { filterLastHours } from './ActionResources/transformationFunctions';
import { API_NAMES } from '../helpers/constants.js';
import actionTypes from './actionTypes';
import { worker } from '../app/web-workers/aws-web-worker';

export function getAllAlertsRequest() {
  return {
    type: actionTypes.GET_ALERTS_REQUEST
  };
}

export function receiveAlertNotifications(newAlertMessage) {
  const state = store.getState().alerts;
  const alerts2h = filterLastHours(removeBadPlaces([...state.alerts2h.items, newAlertMessage]));
  return {
    type: actionTypes.RECEIVE_NEW_ALERT_NOTIFICATION,
    alerts2h
  };
}

function updateAlertNotifications() {
  const state = store.getState().alerts;
  const alerts2h = filterLastHours(removeBadPlaces(state.alerts2h.items));
  return {
    type: actionTypes.UPDATE_ALERT2H_NOTIFICATION,
    alerts2h
  };
}

export const removeBadPlaces = (alerts, newPlaces) => {
  if (!isScalar()) return alerts;
  const places = newPlaces || store.getState().places.items;
  const placesIds = places.map(place => place.id);
  return alerts.filter(alert => {
    if (!getIsTypeGeofence({ type: alert.alarm })) return true;
    const id = alert?.geofences_details?.geofences?.[0]?.id;
    return id && placesIds.includes(id);
  });
};

function getAllAlertsSuccess(alerts, final) {
  const state = store.getState().alerts;
  const allItems = [...state.items, ...alerts];
  const itemsWithoutBadPlaces = removeBadPlaces(allItems);
  const alerts2h = filterLastHours(itemsWithoutBadPlaces);
  const filteredAlerts = itemsWithoutBadPlaces;
  return {
    type: actionTypes.GET_ALERTS_SUCCESS,
    allItems,
    alerts: filteredAlerts,
    alerts2h,
    final
  };
}

function getAllAlertsFailure(error) {
  return {
    type: actionTypes.GET_ALERTS_FAILURE,
    error
  };
}

function typeRemapping(type) {
  switch (type) {
    case 'amber':
      return 'ebsAmber';
    case 'red':
      return 'ebsRed';
    default:
      return type;
  }
}

function cleanAlarm(alarms) {
  const ids = [];
  return alarms
    .map(item => {
      const alarm = typeRemapping(item.alarm);
      return {
        ...item,
        alarm,
        speed: item.gnss.speed,
        id: alarm + item.assetId + item.time
      };
    })
    .filter(item => {
      const isUnique = !ids.includes(item.id);
      if (isUnique) {
        ids.push(item.id);
      }
      return isUnique;
    });
}

export function callAPI(dispatch, days, from, lastEvaluatedKey) {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null,
    durationInSeconds: 60 * 60 * 24 * (days + 1),
    from: from.endOf('day').unix()
  };
  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailers/alarms', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(dispatch, days, from, res.LastEvaluatedKey);
      }
      const cleanedAlarms = cleanAlarm(res.Items);
      return dispatch(getAllAlertsSuccess(cleanedAlarms, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getAllAlertsFailure(err)));
}

export default {
  receiveAlertNotifications,
  updateAlertNotifications
};
