import { STATUSES_LABELS } from '../constants';

const { INFO, SAFE, WARNING, CRITICAL } = STATUSES_LABELS;

export const statusLabelSort = (a, b) => {
  const statuses = [INFO, SAFE, WARNING, CRITICAL];
  const aPosition = statuses.indexOf(a.statusLabelId);
  const bPosition = statuses.indexOf(b.statusLabelId);
  return aPosition > bPosition ? -1 : 1;
};

export const metaDataTrailerMakeSort = (a, b) =>
  !a.metaDataTrailerMake || a.metaDataTrailerMake < b.metaDataTrailerMake ? -1 : 1;

export const metaDataTrailerModelSort = (a, b) =>
  !a.metaDataTrailerModel || a.metaDataTrailerModel < b.metaDataTrailerModel ? -1 : 1;
