import React, { Component } from 'react';
import TruckChart from './TruckChart';

function getChartState(props) {
  return {
    data: props.data
  };
}

class TruckChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.chartRef = React.createRef();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = getChartState(nextProps);
    if (nextProps.data && chart) {
      chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    const { chart } = this.state;
    if (chart && chart.svg) {
      chart.svg.selectAll('*').interrupt();
    }
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = this.chartRef.current;
    const elWidth = el.offsetWidth;

    if (!elWidth) {
      return;
    }

    const { chart } = this.state;
    if (chart) {
      chart.destroy();
    }

    const margin = {
      top: 1,
      right: 1,
      bottom: 1,
      left: 1
    };

    const elHeight = elWidth * 0.8;

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new TruckChart(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(getChartState(this.props));
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return <div ref={this.chartRef} />;
  }
}

export default TruckChartComponent;
