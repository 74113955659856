import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../../helpers/defaultStrings';
import { triggerTypes } from '../../../../../../../helpers/alarms/constants';
import { placesVariables } from '../../../../../../../helpers/alarms/functions';
import { getIsShowPlaces } from '../../../../../../../helpers/functions';

export const uniqueKeys = list => {
  const uniqueList = [];
  const keyDic = {};
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element && !keyDic[element]) {
      keyDic[element] = true;
      uniqueList.push(element);
    }
  }
  return uniqueList;
};

export const UniqueCategories = (list, showUnknownOption = false) => {
  const formatMessage = useIntl().formatMessage;
  const uniqueCategoryList = [];
  const keyDic = {};
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element && !keyDic[element]) {
      keyDic[element] = true;
      uniqueCategoryList.push({ text: element, value: element });
    }
  }
  if (showUnknownOption) {
    uniqueCategoryList.push({ text: formatMessage(strings.short.unknown), value: 'unknown' });
  }
  return uniqueCategoryList;
};

export const NoValueSpan = () => {
  const formatMessage = useIntl().formatMessage;
  return <span className='disabled'>{formatMessage(strings.short.singleHyphen)}</span>;
};

export function getTriggers(region, productFeatures) {
  let triggers = triggerTypes[region] || triggerTypes.default;

  if (!getIsShowPlaces()) {
    delete triggers.geofence;
  }

  if (productFeatures?.showWatchman) {
    triggers = {
      ...triggers,
      ...triggerTypes.watchman
    };
  }

  return triggers;
}

export function getTriggerTitles(region, productFeatures) {
  const triggers = getTriggers(region, productFeatures);
  const triggerTitles = [];

  for (const trigger in triggers) {
    triggerTitles.push({ text: triggers[trigger].text, value: trigger });
  }

  return triggerTitles;
}

export function getPlacesTriggerTitles() {
  const triggerTitles = [];

  for (const trigger in placesVariables) {
    triggerTitles.push({ text: placesVariables[trigger].title, value: trigger });
  }
  return triggerTitles;
}
