import { useEffect, useRef } from 'react';
import { isDeepEqual } from '../helpers/functions';

// https://stackoverflow.com/questions/54095994/react-useeffect-comparing-objects
function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isDeepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
