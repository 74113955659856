import React, { useState, useRef } from 'react';
import { Table, Divider, Space, Input, Button, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import HighlightedText from '../subComponents/HighlightedText';
import { requestReportTPMSSnapshot } from '../API/TPMSReportAPI';
import DownloadFile from '../subComponents/DownloadFile';
import LocaleString from '../../Utils/LocaleString';

const notificationType = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
};

const openNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message
  });
};

const ReportsTable = ({ intl: { formatMessage }, onClickDetails }) => {
  const [searchedColumn, setSearchedColumn] = useState({});
  const [isReportProcessing, setIsReportProcessing] = useState({});
  const searchInput = useRef(null);

  const onClickCSVExport = async key => {
    setIsReportProcessing({ ...isReportProcessing, [key]: true });
    try {
      const report = await requestReportTPMSSnapshot();
      const reportURL = report.signedUrl;
      if (!reportURL) throw new Error('Empty url');
      DownloadFile(reportURL);
      setIsReportProcessing({ ...isReportProcessing, [key]: false });
    } catch (error) {
      setIsReportProcessing({ ...isReportProcessing, [key]: false });
      openNotification(
        notificationType.error,
        formatMessage(strings.short.error),
        formatMessage(strings.reports.error_generating_report)
      );
    }
  };

  const reports = [
    {
      key: 'tpmsSnapshot',
      name: formatMessage(strings.reports.tpmsSnapshot),
      source: formatMessage(strings.reports.trailerTPMS),
      timeRange: formatMessage(strings.short.all),
      timeGranularity: formatMessage(strings.reports.snapshot),
      actions: [
        {
          key: 'TPMSReport',
          text: formatMessage(strings.short.details),
          onClick: onClickDetails
        },
        { key: 'TPMSSnapshotCSV', text: formatMessage(strings.reports.csv_export), onClick: onClickCSVExport }
      ]
    }
  ];

  const renderActionBtns = record => (
    <Space size='middle' split={<Divider type='vertical' />}>
      {record?.actions?.map(({ text, onClick, key, disabled }) => (
        <Button key={key} onClick={() => onClick(key)} type='link' disabled={!!disabled}>
          {text}
        </Button>
      ))}
    </Space>
  );

  const searchColumn = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            <LocaleString type='short' id='search' />
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
            <LocaleString type='short' id='reset' />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text => {
      return searchedColumn[dataIndex] ? (
        <HighlightedText highlight={searchedColumn[dataIndex]}>{text}</HighlightedText>
      ) : (
        text
      );
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: selectedKeys[0] });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: undefined });
  };

  const columns = [
    {
      title: <LocaleString type='reports' id='report_name' />,
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      ...searchColumn('name')
    },
    {
      title: <LocaleString type='reports' id='source' />,
      dataIndex: 'source',
      key: 'source',
      ...searchColumn('source')
    },
    {
      title: <LocaleString type='reports' id='time_range' />,
      dataIndex: 'timeRange',
      key: 'timeRange'
    },
    {
      title: <LocaleString type='reports' id='time_granularity' />,
      dataIndex: 'timeGranularity',
      key: 'timeGranularity'
    },
    {
      title: <LocaleString type='reports' id='action' />,
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (text, record) => renderActionBtns(record)
    }
  ];

  return <Table columns={columns} dataSource={reports} pagination={false} />;
};

export default injectIntl(ReportsTable);
