import { defineMessages } from 'react-intl';

// Report List page strings
export const reports = defineMessages({
  mileage: {
    id: 'reports.mileage',
    defaultMessage: 'Mileage',
    description: ''
  },
  tirePressureTmps: {
    id: 'reports.tirePressureTmps',
    defaultMessage: 'Tire Pressure and Temperature (TPMS)',
    description: 'TPMS - tire pressure monitoring system'
  },
  report_name: {
    id: 'reports.report_name',
    defaultMessage: 'Report Name',
    description: ''
  },
  source: {
    id: 'reports.source',
    defaultMessage: 'Source',
    description: ''
  },
  time_range: {
    id: 'reports.time_range',
    defaultMessage: 'Time range',
    description: ''
  },
  time_granularity: {
    id: 'reports.time_granularity',
    defaultMessage: 'Time granularity',
    description: ''
  },
  monthly_mileage_pt: {
    id: 'reports.monthly_mileage_pt',
    defaultMessage: 'Monthly mileage per trailer',
    description: ''
  },
  trailer_ebs: {
    id: 'reports.trailer_ebs',
    defaultMessage: 'Trailer EBS',
    description: 'EBS: Electronic breaking system'
  },
  trailer_abs: {
    id: 'reports.trailer_abs',
    defaultMessage: 'Trailer ABS',
    description: 'ABS: Anti-lock breaking system'
  },
  last_cal_month: {
    id: 'reports.last_cal_month',
    defaultMessage: 'Last cal. month',
    description: 'cal abbrviation of calendar'
  },
  monthly: {
    id: 'reports.monthly',
    defaultMessage: 'Monthly',
    description: ''
  },
  csv_export: {
    id: 'reports.csv_export',
    defaultMessage: 'CSV Export',
    description: 'CVS is type of file'
  },
  processing: {
    id: 'reports.processing',
    defaultMessage: 'Processing',
    description: 'The download will start automatically once processing is complete.'
  },
  auto_download_processing: {
    id: 'reports.auto_download_processing',
    defaultMessage: 'The download will start automatically once processing is complete.',
    description: ''
  },
  error_generating_report: {
    id: 'reports.error_generating_report',
    defaultMessage: 'There was an error generating this report. Please try again later.',
    description: ''
  },
  monthlyOn1st: {
    id: 'reports.monthlyOn1st',
    defaultMessage: 'Monthly on the 1st',
    description: ''
  },
  tpmsSnapshot: {
    id: 'reports.tpmsSnapshot',
    defaultMessage: 'TPMS snapshot',
    description: 'TPMS - tire pressure monitoring system'
  },
  trailerTPMS: {
    id: 'reports.trailerTPMS',
    defaultMessage: 'Trailer TPMS',
    description: 'TPMS - tire pressure monitoring system'
  },
  snapshot: {
    id: 'reports.snapshot',
    defaultMessage: 'Snapshot',
    description: ''
  },
  monthlyMileage: {
    id: 'reports.monthlyMileage',
    defaultMessage: 'Monthly mileage'
  },
  currentSnapshot: {
    id: 'reports.currentSnapshot',
    defaultMessage: 'Current snapshot',
    description: ''
  },
  onTheFirst: {
    id: 'reports.onTheFirst',
    defaultMessage: 'on the 1st',
    description: 'referring to the 1st day of the month'
  },
  onTheFirstMonday: {
    id: 'reports.onTheFirstMonday',
    defaultMessage: 'on the first Monday',
    description: 'referring to the 1st Monday of the month'
  },
  weeklyOnMondays: {
    id: 'reports.weeklyOnMondays',
    defaultMessage: 'Weekly on Mondays',
    description: ''
  },
  weeklyOnTuesdays: {
    id: 'reports.weeklyOnTuesdays',
    defaultMessage: 'Weekly on Tuesdays',
    description: ''
  },
  weeklyOnWednesdays: {
    id: 'reports.weeklyOnWednesdays',
    defaultMessage: 'Weekly on Wednesdays',
    description: ''
  },
  weeklyOnThursdays: {
    id: 'reports.weeklyOnThursdays',
    defaultMessage: 'Weekly on Thursdays',
    description: ''
  },
  weeklyOnFridays: {
    id: 'reports.weeklyOnFridays',
    defaultMessage: 'Weekly on Fridays',
    description: ''
  },
  weeklyOnSaturdays: {
    id: 'reports.weeklyOnSaturdays',
    defaultMessage: 'Weekly on Saturdays',
    description: ''
  },
  weeklyOnSundays: {
    id: 'reports.weeklyOnSundays',
    defaultMessage: 'Weekly on Sundays',
    description: ''
  }
});
