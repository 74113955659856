import React from 'react';
import { Button, Input, Space } from 'antd';
import LocaleString from '../../../Utils/LocaleString';
import { SearchOutlined } from '@ant-design/icons';

const searchColumn = (dataIndex, searchInput, handleSearch, handleResetSearch) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
        >
          <LocaleString type='short' id='search' />
        </Button>
        <Button onClick={() => handleResetSearch(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
          <LocaleString type='short' id='reset' />
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
  onFilter: (value, record) =>
    record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  }
});

export default searchColumn;
