import { message } from 'antd';
import actionTypes from '../actions/actionTypes';

message.config({
  top: 100,
  duration: 3
});

const messageMiddleware = () => next => action => {
  if (action.type === actionTypes.ADD_MESSAGE) {
    const { messageType, content } = action;
    message[messageType](content);
  }
  return next(action);
};

export default messageMiddleware;
