import React from 'react';
import './Dashboard.scss';
import LiveActivityBox from './DashboardComponents/LiveActivityBox';
import AlarmsBox from './DashboardComponents/AlarmsBox';

const Dashboard = ({ trailers }) => {
  return (
    <div className='tf-dashboard'>
      <LiveActivityBox trailers={trailers} />
      <AlarmsBox trailers={trailers} />
    </div>
  );
};

export default Dashboard;
