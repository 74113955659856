import React, { useEffect, useState } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as strings from '../../../helpers/defaultStrings';
import { SortBtn } from './SortBtn';
import classNames from 'classnames';
import './CategoryHeader.scss';
import { sendCustomEvent } from '../../../app/helpers/new-relic';
import { selectUser } from '../../../selectors/auth';
import { EVENT_NAMES } from '../../../analytics/constants';

export const CategoryHeader = ({
  colKey,
  children,
  categories = [],
  onFilter,
  removeFilter,
  sorter,
  sortedUp,
  sortedDown
}) => {
  const { formatMessage } = useIntl();
  const user = useSelector(selectUser);
  const [checked, setChecked] = useState({});
  const [hasChecks, setHasChecks] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const colName = colKey?.toUpperCase() ?? EVENT_NAMES.UNKNOWN;
  const pushEvent = name => sendCustomEvent(name, { identity: user.identity });

  const closeMenu = () => setIsOpen(false);
  useEffect(() => {
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, []);

  const onChange = category => {
    const newChecks = { ...checked, [category]: !checked[category] };
    setChecked(newChecks);
    let newHasChecks = false;
    for (const key in newChecks) {
      if (newChecks[key]) newHasChecks = true;
    }
    setHasChecks(newHasChecks);
    if (newHasChecks) {
      onFilter(newChecks);
    } else {
      removeFilter();
      pushEvent(`${colName}_${EVENT_NAMES.FILTER_RESET}`);
    }
  };

  const clearChecks = e => {
    e.stopPropagation();
    setChecked({});
    setHasChecks(false);
    setIsOpen(false);
    removeFilter();
    pushEvent(`${colName}_${EVENT_NAMES.FILTER_RESET}`);
  };

  const onClick = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const iconCss = classNames({
    'ico-has-checks': hasChecks,
    'ico-is-open': isOpen
  });

  const resetBtnCss = hasChecks ? 'has-checks' : '';
  const dropdownContentCss = isOpen ? 'th-dropdown-content is-open' : 'th-dropdown-content';
  const DropBtnCss = isOpen ? 'th-dropbtn is-open' : 'th-dropbtn';
  return (
    <div className='th-categories'>
      <div className='th-cat-title'>{children}</div>
      <div className='th-dropdown'>
        <div className='th-cat-btn-container'>
          {sorter && <SortBtn colKey={colKey} onClick={sorter} sortedUp={sortedUp} sortedDown={sortedDown} />}
          <button className={DropBtnCss} onClick={onClick}>
            <FilterFilled className={iconCss} />
          </button>
        </div>
        <div className={dropdownContentCss}>
          {categories.map(c => (
            <button
              key={c.value}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onChange(c.value);
              }}
            >
              <Checkbox checked={checked[c.value]}>{c.text}</Checkbox>
            </button>
          ))}
          <button key='dd-reset-btn' onClick={clearChecks} className={resetBtnCss}>
            {formatMessage(strings.short.reset)}
          </button>
        </div>
      </div>
    </div>
  );
};
