import React, { useEffect, useLayoutEffect, useState, Fragment } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Checkbox, Input } from 'antd';
import { tabOrModalView, triggerGenericEvent } from '../../helpers/googleAnalytics';
import { selectionHeaders } from '../../helpers/constants';
import arrayMove from 'array-move';
import { RegList, SortableList } from './ColumnSelectionList';
import LocaleString from '../Utils/LocaleString';
import './ColumnSelection.scss';
import { sendCustomEvent } from '../../app/helpers/new-relic';
import { useSelector } from 'react-redux';
import { selectUser } from '../../selectors';

const bySearchKey = (a, b) => {
  var searchKeyA = a.searchKey && a.searchKey.toUpperCase();
  var searchKeyB = b.searchKey && b.searchKey.toUpperCase();
  if (searchKeyA < searchKeyB) {
    return -1;
  }
  if (searchKeyA > searchKeyB) {
    return 1;
  }
  return 0;
};

function colReducer(state, action) {
  switch (action.type) {
    case 'updateColWidth': {
      return { ...state, colWidths: { ...state.colWidths, ...action.value } };
    }
    case 'updateColLocked': {
      return { ...state, colLocked: { ...state.colLocked, ...action.value } };
    }
    case 'clearState': {
      return { colWidths: {}, colLocked: {} };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const ColumnSelection = ({
  tableId,
  setIsOpen,
  isOpen,
  columns,
  selectedColumnKeys,
  saveSettingsGroup,
  columnsLocked,
  dynamicWidths
}) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [currentlyDisplayed, setCurrentlyDisplayed] = useState([]);
  const [currentlyPinned, setCurrentlyPinned] = useState([]);
  const [notSelected, setNotSelected] = useState([]);
  const [sets, setSets] = useState({});
  const [{ colWidths, colLocked }, dispatch] = React.useReducer(colReducer, { colWidths: {}, colLocked: {} });
  const [filterString, setfilterString] = useState('');
  const [analyticsColumnsWidths, setAnalyticsColumnsWidths] = useState([]);
  const user = useSelector(selectUser);

  const byFixed = (a, b) => {
    if (!colLocked[a.key] && colLocked[b.key]) {
      return 1;
    }
    if (colLocked[a.key] && !colLocked[b.key]) {
      return -1;
    }
    return 0;
  };
  useEffect(() => {
    if (selectedColumnKeys && !loaded) {
      const sortedColumns = [
        ...selectedColumnKeys.map(key => columns.find(c => c.key === key)).filter(c => c),
        ...columns.filter(c => !selectedColumnKeys.includes(c.key))
      ];

      setSelectedKeys(selectedColumnKeys);
      setDisplayColumns(sortedColumns);
      setLoaded(true);
    }
  }, [selectedColumnKeys, columns, loaded, setSelectedKeys, setDisplayColumns, setLoaded]);

  useEffect(() => {
    dispatch({ type: 'updateColLocked', value: columnsLocked });
  }, [columnsLocked, dispatch]);

  const searchFilter = c => {
    const searchKey = c.searchKey;
    if (!searchKey) {
      console.error('Can not find seachkey for:', c);
      return false;
    }
    return filterString === '' || searchKey.toUpperCase().indexOf(filterString.toUpperCase()) > -1;
  };

  useLayoutEffect(() => {
    const displayAndPinned = displayColumns.filter(c => selectedKeys.indexOf(c.key) > -1);
    const currentlyDisplayed = displayAndPinned.filter(c => !colLocked[c.key]);
    const currentlyPinned = displayAndPinned.filter(c => colLocked[c.key]);
    const notSelectedColumns = displayColumns.filter(c => selectedKeys.indexOf(c.key) <= -1);

    const newSetObj = {};
    Object.values(selectionHeaders).forEach(header => {
      newSetObj[header.id] = notSelectedColumns.filter(c => c.selectionSection === header.id);
    });

    setCurrentlyDisplayed(currentlyDisplayed);
    setCurrentlyPinned(currentlyPinned);
    setNotSelected(notSelectedColumns);
    setSets(newSetObj);
  }, [selectedKeys, displayColumns, colLocked, setCurrentlyDisplayed, setCurrentlyPinned, setNotSelected, setSets]);

  const onSelectionChange = selectedKeys => {
    setSelectedKeys(selectedKeys);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const currentlyDisplayedClone = [...currentlyDisplayed];
    const newColumns = arrayMove(currentlyDisplayedClone, oldIndex, newIndex);
    setCurrentlyDisplayed(newColumns);
    setDisplayColumns([...currentlyPinned, ...newColumns, ...notSelected]);
  };

  const onSortEndPinned = ({ oldIndex, newIndex }) => {
    const currentlyPinnedClone = [...currentlyPinned];
    const newColumns = arrayMove(currentlyPinnedClone, oldIndex, newIndex);
    setCurrentlyPinned(newColumns);
    setDisplayColumns([...newColumns, ...currentlyDisplayed, ...notSelected]);
  };

  const handleOk = () => {
    tabOrModalView('');
    const orderedColumnKeys = displayColumns
      .sort(byFixed)
      .map(c => c.key)
      .filter(key => selectedKeys.includes(key));
    if (Array.isArray(selectedColumnKeys)) {
      selectedColumnKeys.forEach(column => {
        if (orderedColumnKeys.indexOf(column) === -1) {
          triggerGenericEvent('AppState', 'Removed Column', column);
        }
      });
    }
    orderedColumnKeys.forEach(column => {
      if (selectedColumnKeys.indexOf(column) === -1) {
        triggerGenericEvent('AppState', 'Added Column', column);
      }
    });
    const settingsUpdate = {
      [`columnSelection${tableId}`]: orderedColumnKeys,
      [`columnsWidths${tableId}`]: colWidths,
      [`columnsLocked${tableId}`]: colLocked
    };
    saveSettingsGroup(settingsUpdate);
    setIsOpen(false);
    analyticsColumnsWidths.forEach(analyticsColumnWidth =>
      sendCustomEvent(`${analyticsColumnWidth.toUpperCase()}_WIDTH`, { identity: user.identity })
    );
  };

  const handleCancel = () => {
    tabOrModalView('');
    const sortedColumns = [
      ...selectedColumnKeys?.map(key => columns.find(c => c.key === key)).filter(c => c),
      ...columns?.filter(c => !selectedColumnKeys.includes(c.key))
    ];
    dispatch({ type: 'clearState' });
    setSelectedKeys(selectedColumnKeys);
    setDisplayColumns(sortedColumns);
    setIsOpen(false);
    setAnalyticsColumnsWidths([]);
  };

  const onChange = event => {
    setfilterString(event.target.value);
  };

  return (
    <Fragment>
      <Modal
        title={<LocaleString type='short' id='columnSettings' />}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={<LocaleString type='button' id='ok' />}
        className='column-selection'
        cancelText={<LocaleString type='button' id='cancel' />}
      >
        <div className='search-section'>
          <div>Search</div>
          <div className='search-wrapper'>
            <Input
              className='search-input'
              value={filterString}
              onChange={onChange}
              suffix={<CloseCircleOutlined className='search-icon' onClick={() => setfilterString('')} />}
            />
          </div>
        </div>
        {!filterString && (
          <Checkbox.Group className='item-section' onChange={onSelectionChange} value={selectedKeys}>
            <div className='set-header set-top'>
              <LocaleString type='short' id='fixedSet' />
              <div className='sub-header'>
                <LocaleString type='short' id='fixedSetSub' />
              </div>
            </div>
            <SortableList
              helperClass='sortable-helper'
              showSubtitle={true}
              hasHeaders={true}
              items={currentlyPinned}
              filter={searchFilter}
              onSortEnd={onSortEndPinned}
              lockAxis='y'
              dynamicWidths={dynamicWidths}
              colLocked={colLocked}
              dispatch={dispatch}
              analyticsColumnsWidths={analyticsColumnsWidths}
              setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
            />
            <div className='set-header'>
              <LocaleString type='short' id='scrollableSet' />

              <div className='sub-header'>
                <LocaleString type='short' id='scrollableSetSub' />
              </div>
            </div>
            <SortableList
              helperClass='sortable-helper'
              showSubtitle={true}
              hasHeaders={true}
              items={currentlyDisplayed}
              filter={searchFilter}
              onSortEnd={onSortEnd}
              lockAxis='y'
              dynamicWidths={dynamicWidths}
              colLocked={colLocked}
              dispatch={dispatch}
              analyticsColumnsWidths={analyticsColumnsWidths}
              setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
            />
            <div className='other-sets'>
              {Object.values(selectionHeaders).map((header, index) => {
                return (
                  !!sets[header.id]?.length && (
                    <div key={`${header.id}-${index}`}>
                      <div className='set-header'>{header.title}</div>
                      <RegList
                        regular={true}
                        helperClass='sortable-helper'
                        items={sets[header.id].sort(bySearchKey)}
                        filter={searchFilter}
                        onSortEnd={onSortEnd}
                        lockAxis='y'
                        dynamicWidths={dynamicWidths}
                        colLocked={colLocked}
                        dispatch={dispatch}
                        analyticsColumnsWidths={analyticsColumnsWidths}
                        setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
                      />
                    </div>
                  )
                );
              })}
            </div>
          </Checkbox.Group>
        )}
        {!!filterString && (
          <Checkbox.Group className='item-section' onChange={onSelectionChange} value={selectedKeys}>
            <div className='set-header set-top'>
              <LocaleString type='columns' id='searchResults' />
            </div>
            <SortableList
              regular={true}
              hasHeaders={true}
              showSubtitle={true}
              helperClass='sortable-helper'
              items={[...displayColumns].sort(bySearchKey)}
              filter={searchFilter}
              onSortEnd={onSortEndPinned}
              lockAxis='y'
              dynamicWidths={dynamicWidths}
              colLocked={colLocked}
              dispatch={dispatch}
              analyticsColumnsWidths={analyticsColumnsWidths}
              setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
            />
          </Checkbox.Group>
        )}
      </Modal>
    </Fragment>
  );
};

export default ColumnSelection;
