import React from 'react';
import { connect } from 'react-redux';
import { AuditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { push } from 'connected-react-router';

import LocaleString from './LocaleString';
import actions from '../../actions';
import { triggerEvent } from '../../helpers/googleAnalytics';
import OdyTextButton from '../../ReusableComponents/Buttons/OdyTextButton';

class IdCardButton extends React.Component {
  goToIdCardPage = () => {
    const { updateSelectedHealthTrailer, selectedTrailer, goToTrailerIdCard } = this.props;
    triggerEvent('Navigation', 'Go to selected device on Trailer ID Card screen', 'Live Map screen');
    updateSelectedHealthTrailer(selectedTrailer);
    goToTrailerIdCard(selectedTrailer.assetId);
  };

  render() {
    const { className, selectedTrailer, size, shape, style, light, blue, disabled, idCardUnavailable } = this.props;
    const tooltipText = idCardUnavailable
      ? { type: 'description', id: 'idCardUnavailable' }
      : { type: 'button', id: 'showOnTrailerIdCardPage' };
    return (
      <div>
        {light && (
          <Tooltip placement='bottom' title={<LocaleString type={tooltipText.type} id={tooltipText.id} />}>
            <Button
              type='link'
              style={style}
              size={size || 'small'}
              icon={<AuditOutlined className={className} />}
              disabled={disabled}
              onClick={this.goToIdCardPage}
            />
          </Tooltip>
        )}
        {blue && (
          <OdyTextButton
            type='primary'
            onClick={this.goToIdCardPage}
            icon={<AuditOutlined className={className} />}
            className='medium-margin-right'
            disabled={disabled}
          >
            {' '}
            <LocaleString type='navMenu' id='trailerIdCard' />
          </OdyTextButton>
        )}
        {!light && !blue && (
          <Button
            style={style}
            size={size || 'small'}
            shape={shape || 'round'}
            icon={<AuditOutlined className={className} />}
            key='details'
            disabled={!selectedTrailer || !selectedTrailer.assetId || disabled}
            onClick={this.goToIdCardPage}
          >
            {' '}
            <LocaleString type='button' id='showOnTrailerIdCardPage' />
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  goToTrailerIdCard: trailerId => dispatch(push(`/trailer/${trailerId}`)),
  updateSelectedHealthTrailer: trailer => dispatch(actions.appState.updateSelectedHealthTrailer(trailer))
});

export default connect(mapStateToProps, mapDispatchToProps)(IdCardButton);
