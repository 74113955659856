export const sortBy = (array, getValueToCompare) => {
  return array?.sort((a, b) => getValueToCompare(a) - getValueToCompare(b));
};

export const maxBy = (array, getValueToCompare) => {
  return sortBy(array, getValueToCompare)?.pop();
};

export const minBy = (array, getValueToCompare) => {
  return sortBy(array, getValueToCompare)?.[0];
};
