import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../../../actions';
import { filterLastHours } from '../../../../../../actions/ActionResources/transformationFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { getTriggers } from '../../../../../../helpers/alarms/functions';
import DashboardIcon from '../DashboardIcon/DashboardIcon';
import SelectHours from './SelectHours/SelectHours';
import { getAllAlerts } from '../../../../../../actions/thunks/get-all-alerts';
import { sendCustomEvent } from '../../../../../../app/helpers/new-relic';
import { selectProductFeatures, selectUser } from '../../../../../../selectors/auth';
import { EVENT_NAMES } from '../../../../../../analytics/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { selectAlertItems, selectRegion } from '../../../../../../selectors';
import { useQuery } from '../../../../../../helpers/liveMap/useQuery';

const invalidAlarmKey = {
  default: true,
  tireExtremeUnderPressure: true,
  tireUnderPressure: true,
  tireExtremeOverPressure: true,
  tireOverPressure: true
};

const AlarmsBox = ({ trailers }) => {
  const { formatMessage } = useIntl();
  const user = useSelector(selectUser);
  const region = useSelector(selectRegion);
  const alerts = useSelector(selectAlertItems);
  const productFeatures = useSelector(selectProductFeatures);

  const livemapAlarmsHours = useSelector(store => store?.settings?.items?.livemapAlarmsHours);
  let lastHours = livemapAlarmsHours ? livemapAlarmsHours : 2;
  const dispatch = useDispatch();
  const [activeAlarms, setActiveAlarms] = useState({});

  useEffect(() => {
    dispatch(getAllAlerts());

    //get shared alarm filters
    const sharedFilters = query.get('alarms')?.split('|');
    const sharedHours = query.get('hours');
    if (sharedFilters) {
      const filterObject = Object.fromEntries(sharedFilters.map(filter => [filter, true]));
      setActiveAlarms(filterObject);
      setHours(parseInt(sharedHours));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let query = useQuery();
  const windowLocation = useLocation();
  let history = useHistory();

  const triggerTypes = getTriggers(region, productFeatures);
  const alarmsForRegion = Object.keys(triggerTypes);
  const alarmsAvailable = alarmsForRegion
    .filter(alarm => !invalidAlarmKey[alarm])
    .sort((alarmA, alarmB) => triggerTypes[alarmA].order - triggerTypes[alarmB].order);

  const alarmsInLastHours = filterLastHours(alerts, lastHours).items?.filter(
    item => item.alarm && alarmsForRegion.includes(item.alarm)
  );
  const alarmTotals = {};
  alarmsForRegion.forEach(alarmKey => {
    alarmTotals[alarmKey] = alarmsInLastHours.filter(alert => alert.alarm === alarmKey).length;
  });
  alarmTotals['tpms'] =
    alarmTotals['tpms'] +
      alarmTotals['tireExtremeUnderPressure'] +
      alarmTotals['tireUnderPressure'] +
      alarmTotals['tireExtremeOverPressure'] +
      alarmTotals['tireOverPressure'] || 0;

  const toogleAlarm = alarm => {
    const nextActiveAlarms = { ...activeAlarms };
    if (!activeAlarms[alarm]) {
      sendCustomEvent(`${EVENT_NAMES.DASHBOARD_FILTER}_${alarm.toUpperCase()}`, { identity: user.identity });
      nextActiveAlarms[alarm] = true;
    } else {
      delete nextActiveAlarms[alarm];
    }
    setActiveAlarms(nextActiveAlarms);

    //add active alarm filters to url search params so view can be shared
    const alarmsURLQuery = Object.keys(nextActiveAlarms).join('|');
    const activities = query.get('activities');
    const alarmObject = Object.keys(nextActiveAlarms).length > 0 ? { alarms: alarmsURLQuery, hours: lastHours } : {};
    const searchParams = activities ? { activities: activities, ...alarmObject } : alarmObject;
    history.push({
      pathname: windowLocation.pathname,
      search: `?${new URLSearchParams(searchParams).toString()}`
    });

    // Filtering trailers by trailersIds with active alarms:
    const alarmKeysToFilter = { ...nextActiveAlarms };
    if (alarmKeysToFilter['tpms']) {
      alarmKeysToFilter['tireExtremeUnderPressure'] = true;
      alarmKeysToFilter['tireUnderPressure'] = true;
      alarmKeysToFilter['tireExtremeOverPressure'] = true;
      alarmKeysToFilter['tireOverPressure'] = true;
      delete alarmKeysToFilter['tpms'];
    }
    const shouldFilterByAlarms = Object.keys(alarmKeysToFilter).length > 0;

    const assetIdsWithActiveAlarms = alarmsInLastHours
      .filter(alert => alarmKeysToFilter[alert.alarm])
      .map(item => item.assetId)
      .reduce((dic, id) => ({ ...dic, [id]: true }), {});

    const alarmsFilterName = 'alarmsFilter';
    const alarmsFilter = trailer => assetIdsWithActiveAlarms[trailer.assetId];

    if (shouldFilterByAlarms) trailers.filter(alarmsFilterName, alarmsFilter);
    else trailers.removeFilter(alarmsFilterName);
  };

  const setHours = newHours => {
    sendCustomEvent(`${EVENT_NAMES.DASHBOARD_FILTER_HOURS}_${newHours}`, { identity: user.identity });
    dispatch(actions.settings.saveSetting('livemapAlarmsHours', newHours));
  };

  return (
    <div className='tf-dashboard-box flex-2'>
      <div className={'box-title-container'}>
        <div className={'title-container'}>
          <h2 className='ant-typography'>{alarmsInLastHours.length || 0}</h2>
          <span className='tf-box-title'>{formatMessage(strings.short.alarms)}</span>
        </div>
        <SelectHours selected={lastHours} onSelect={setHours} />
      </div>
      <ul>
        {alarmsAvailable.map(alarm => (
          <DashboardIcon
            value={alarmTotals[alarm] || 0}
            key={alarm}
            tooltipText={triggerTypes[alarm].text}
            active={activeAlarms[alarm]}
            className={`tf-icon-${alarm}`}
            onClick={() => toogleAlarm(alarm)}
          >
            {triggerTypes[alarm].getAvatarSVG()}
          </DashboardIcon>
        ))}
      </ul>
    </div>
  );
};

export default AlarmsBox;
