import React from 'react';
import { Row, Col } from 'antd';
import ValueItem from '../subComponents/ValueItem';
import TitleSubTitle from '../subComponents/TitleSubTitle';
import { useIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';

const TPMSGeneralSection = () => {
  const formatMessage = useIntl().formatMessage;
  return (
    <>
      <TitleSubTitle title={formatMessage(strings.short.general)} />
      <Row className='large-margin-bottom large-margin-top'>
        <Col span={24}>
          <ValueItem title={formatMessage(strings.short.name)} value={formatMessage(strings.reports.trailerTPMS)} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <ValueItem title={formatMessage(strings.reports.time_range)} value={formatMessage(strings.short.all)} />
        </Col>
        <Col span={12}>
          <ValueItem
            title={formatMessage(strings.reports.time_granularity)}
            value={formatMessage(strings.reports.currentSnapshot)}
          />
        </Col>
      </Row>
    </>
  );
};

export default TPMSGeneralSection;
