import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers/index';
import middlewares from '../middelwares/index';
import { ENV_CONFIG } from './helpers/env-configs';

const composeEnhancers =
  ENV_CONFIG.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const history = createBrowserHistory();
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, ...middlewares))
);

export default store;
