import React from 'react';

const IconPaused = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.2 15.9413C5.82933 15.7867 4.64 15.3547 3.51466 14.608C2.91733 14.2027 1.89866 13.216 1.49866 12.64C-0.474668 9.81866 -0.474668 6.176 1.49866 3.36C1.856 2.848 2.848 1.856 3.36 1.49866C4.208 0.906665 5.06133 0.517332 6.05866 0.261332C8.31466 -0.309335 10.6987 0.138665 12.64 1.49866C13.152 1.856 14.144 2.848 14.5013 3.36C15.5947 4.92266 16.128 6.86933 15.952 8.64533C15.7547 10.6133 14.9813 12.2667 13.6267 13.6267C12.3147 14.9333 10.8 15.6693 8.89066 15.92C8.21333 16.0053 7.824 16.0107 7.2 15.9413ZM9.49333 14.304C10.1173 14.1333 10.464 14 10.992 13.728C12.752 12.816 14.0053 11.104 14.3787 9.09333C14.496 8.44266 14.4587 7.088 14.2987 6.50666C14.1333 5.88266 14 5.536 13.728 5.008C12.8747 3.35466 11.264 2.112 9.41333 1.68C7.41866 1.22133 5.25333 1.76533 3.73866 3.12C2.69866 4.04266 2.05866 5.09333 1.696 6.45333C1.54133 7.03466 1.504 8.44266 1.62133 9.09333C1.86666 10.432 2.57066 11.76 3.49866 12.6453C4.37866 13.4827 5.6 14.128 6.72 14.3413C6.88 14.3733 7.06133 14.4107 7.12 14.4213C7.17866 14.4373 7.648 14.4373 8.16 14.4267C8.88533 14.416 9.184 14.384 9.49333 14.304Z' />
    <path d='M5.01333 3.73333H7.41333V12.2667H5.01333V3.73333Z' />
    <path d='M8.64001 3.73333H11.04V12.2667H8.64001V3.73333Z' />
  </svg>
);

export default IconPaused;
