import { defineMessages } from 'react-intl';

export const pageTitle = defineMessages({
  healthPassport: {
    id: 'pageTitle.healthPassport',
    defaultMessage: 'Health passport - Fleet',
    description: ''
  },
  home: {
    id: 'pageTitle.home',
    defaultMessage: 'Home',
    description: ''
  },
  liveMap: {
    id: 'pageTitle.liveMap',
    defaultMessage: 'Trailers - Live map',
    description: ''
  },
  liveActivity: {
    id: 'pageTitle.liveActivity',
    defaultMessage: 'Trailers - Live activity',
    description: 'Live as in real time'
  },
  settings: {
    id: 'pageTitle.settings',
    defaultMessage: 'Settings',
    description: 'Live as in real time'
  },
  notifications: {
    id: 'pageTitle.notifications',
    defaultMessage: 'Notifications',
    description: ''
  },
  placesNotifications: {
    id: 'pageTitle.placesNotifications',
    defaultMessage: 'Places alarms',
    description: ''
  },
  safety: {
    id: 'pageTitle.safety',
    defaultMessage: 'Safety - Fleet',
    description: 'Fleet is a group of trailers'
  },
  loadingEvents: {
    id: 'pageTitle.loadingEvents',
    defaultMessage: 'Trailer Utilization',
    description: ''
  },
  trailerIdCard: {
    id: 'pageTitle.trailerIdCard',
    defaultMessage: 'Trailer - ID card',
    description: ''
  },
  createNew: {
    id: 'pageTitle.createNew',
    defaultMessage: 'Create New',
    description: ''
  },
  history: {
    id: 'pageTitle.history',
    defaultMessage: 'History',
    description: ''
  },
  loadingTrailers: {
    id: 'pageTitle.loadingTrailers',
    defaultMessage: 'Loading trailers data, please wait.',
    description: 'Loading trailers data, please wait.'
  },
  brakePlusReport: {
    id: 'pageTitle.brakePlusReport',
    defaultMessage: 'BrakePlus Reports',
    description: 'BrakePlus is a name'
  },
  brakePlusMap: {
    id: 'pageTitle.brakePlusMap',
    defaultMessage: 'BrakePlus Map',
    description: 'BrakePlus is a name'
  },
  brakePlusInsights: {
    id: 'pageTitle.brakePlusInsights',
    defaultMessage: 'BrakePlus Insights',
    description: 'BrakePlus is a name'
  },
  places: {
    id: 'pageTitle.places',
    defaultMessage: 'Places',
    description: 'Title of a page listing records of places (of interest)'
  },
  reportList: {
    id: 'pageTitle.reportList',
    defaultMessage: 'Reports',
    description: ''
  }
});
