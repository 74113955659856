import moment from 'moment';
import { formatTrailerHistory, formatTrailerContextHistory } from './ActionResources/transformationFunctions';
import store from '../app/configureStore.js';
import actionTypes from './actionTypes';
import { addErrorMessage } from './messages';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { selectEntities } from '../selectors/trailers';

function getTrailerHistoryRequest(assetId, hours) {
  return {
    type: actionTypes.GET_TRAILER_HISTORY_REQUEST,
    assetId,
    hours
  };
}

function getTrailerHistorySuccess(trailerHistory, assetId, final) {
  const state = store.getState();
  const trailers = selectEntities(state);
  const isMetric = state.auth.isMetric;
  const now = Math.round(Date.now() / 1000);
  const payload = formatTrailerHistory(
    now,
    {
      devicesReducer: state.devices,
      trailers
    },
    trailerHistory,
    assetId,
    final,
    isMetric
  );
  if (payload) {
    const { history, animatedHeadRoutes, mapRoutes, devices } = payload;
    return {
      type: actionTypes.GET_TRAILER_HISTORY_SUCCESS,
      history,
      animatedHeadRoutes,
      mapRoutes,
      devices,
      processingApi: !final
    };
  }
}

function getTrailerHistoryFailure({ error, assetId }) {
  return {
    type: actionTypes.GET_TRAILER_HISTORY_FAILURE,
    assetId,
    error
  };
}

function callAPI(assetId, time, durationInSeconds, dispatch, lastEvaluatedKey) {
  const body = {
    from: time,
    durationInSeconds,
    LastEvaluatedKey: lastEvaluatedKey || null
  };
  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${assetId}/fmshistory`, body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(assetId, time, durationInSeconds, dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getTrailerHistorySuccess(res.Items, assetId, !res.LastEvaluatedKey));
    })
    .catch(error =>
      dispatch(
        getTrailerHistoryFailure({
          error,
          assetId
        })
      )
    );
}

function getTrailerHistory(assetId, hours, searchTime) {
  return dispatch => {
    const durationInSeconds = 3600 * (hours || 12);
    const time = searchTime || Date.now() / 1000;

    dispatch(getTrailerHistoryRequest(assetId, hours));
    callAPI(assetId, time, durationInSeconds, dispatch);
  };
}

function getTrailerContextHistoryRequest(assetId) {
  return {
    type: actionTypes.GET_TRAILER_CONTEXT_HISTORY_REQUEST,
    assetId
  };
}

function getTrailerContextHistorySuccess(history, assetId, final) {
  const state = store.getState().devices;
  const payload = formatTrailerContextHistory(state, history, assetId, final);
  if (payload) {
    return {
      type: actionTypes.GET_TRAILER_CONTEXT_HISTORY_SUCCESS,
      ...payload
    };
  } else {
    return {
      type: null
    };
  }
}

function getTrailerContextHistoryFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_CONTEXT_HISTORY_FAILURE,
    error
  };
}

function callTrailerContextHistoryAPI(assetId, from, durationInSeconds, dispatch, lastEvaluatedKey) {
  const body = {
    from,
    durationInSeconds,
    LastEvaluatedKey: lastEvaluatedKey || null
  };
  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${assetId}/fmshistory`, body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callTrailerContextHistoryAPI(assetId, from, durationInSeconds, dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getTrailerContextHistorySuccess(res.Items, assetId, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getTrailerContextHistoryFailure(err)));
}

function getTrailerContextHistory(assetId, start, durationInMinutes) {
  return dispatch => {
    dispatch(getTrailerContextHistoryRequest(assetId));
    if (start) {
      let from;
      let durationInSeconds;
      // WARNING duration is prior the from, the time window then correspond to [(from - duration), from]
      if (durationInMinutes) {
        // Range is specified like in case of a trip, so only limiting data to this range
        from = moment(start).add(durationInMinutes, 'minutes').valueOf() / 1000;
        durationInSeconds = 60 * durationInMinutes;
      } else {
        // Just a timestamp specified like in the case of an event, so limiting data to x hours before and after
        const contextHours = 6;
        from =
          moment(start)
            .add(contextHours / 2, 'hours')
            .valueOf() / 1000;
        durationInSeconds = 3600 * contextHours;
      }

      callTrailerContextHistoryAPI(assetId, from, durationInSeconds, dispatch);
    } else {
      if (assetId) {
        dispatch(addErrorMessage(`No timestamp available to retrieve the trailer context history for ${assetId}`));
      }
      dispatch(getTrailerContextHistoryFailure('No timestamp available to retrieve the trailer context history'));
    }
  };
}

function getTrailerContextHistoryTPMSSuccess(items, assetId) {
  let payload = { assetId: assetId };
  if (items && items[0] && items[0].tpms && items[0].tpms.data) {
    payload.tpms = items[0].tpms.data;
    payload.time = items[0].time;
  }
  return {
    type: actionTypes.GET_TRAILER_TPMS_HISTORY_SUCCESS,
    payload
  };
}

function getTrailerContextHistoryTPMSFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_TPMS_HISTORY_FAILURE,
    error
  };
}

function getTrailerContextHistoryTPMSRequest(assetId) {
  return {
    type: actionTypes.GET_TRAILER_TPMS_HISTORY_REQUEST,
    assetId
  };
}

function callTrailerContextHistoryAPIforTPMS(assetId, from, durationInSeconds, dispatch, lastEvaluatedKey) {
  const body = {
    from,
    durationInSeconds,
    LastEvaluatedKey: lastEvaluatedKey || null
  };
  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${assetId}/fmshistory`, body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callTrailerContextHistoryAPIforTPMS(assetId, from, durationInSeconds, dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getTrailerContextHistoryTPMSSuccess(res.Items, assetId));
    })
    .catch(err => dispatch(getTrailerContextHistoryTPMSFailure(err)));
}

function getTrailerLastTPMS(assetId, start, end) {
  return dispatch => {
    dispatch(getTrailerContextHistoryTPMSRequest(assetId));
    if (start && end) {
      let durationInSeconds = end - start;
      let from = start;
      // WARNING duration is prior the from, the time window then correspond to [(from - duration), from]
      callTrailerContextHistoryAPIforTPMS(assetId, from, durationInSeconds, dispatch);
    } else {
      dispatch(getTrailerContextHistoryFailure('No timestamp available to retrieve the trailer context history'));
    }
  };
}

function setTrailerContextHistoryTPMSDefault() {
  return {
    type: actionTypes.TRAILER_TPMS_HISTORY_DEFAULT
  };
}

export default {
  getTrailerHistory,
  getTrailerContextHistory,
  getTrailerLastTPMS,
  setTrailerContextHistoryTPMSDefault
};
