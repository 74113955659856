import actionTypes from './actionTypes';

function setLiveMapShowAlerts(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_SHOW_ALERTS,
    payload: value
  };
}
function setLiveMapFollowTrailer(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_FOLLOW_TRAILER,
    payload: value
  };
}
function setLiveMapHistoryPosition(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_HISTORY_POSITION,
    payload: value
  };
}
function setLiveMapHours(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_HOURS,
    payload: value
  };
}
function setLiveMapEndDate(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_END_DATE,
    payload: value
  };
}
function setLiveMapVisibleTrailers(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_VISIBLE_TRAILERS,
    payload: value
  };
}
function setLiveMapShowGeoJSONLayers(value) {
  return {
    type: actionTypes.SET_LIVE_MAP_SHOW_GEO_JSON_LAYERS,
    payload: value
  };
}
export default {
  setLiveMapShowAlerts,
  setLiveMapFollowTrailer,
  setLiveMapHistoryPosition,
  setLiveMapHours,
  setLiveMapEndDate,
  setLiveMapVisibleTrailers,
  setLiveMapShowGeoJSONLayers
};
