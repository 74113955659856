import React from 'react';

const ReportListIcon = () => (
  <span role='img' aria-label='places' className='anticon anticon-file-text'>
    <svg
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      focusable='false'
      height='1.1em'
      width='1.1em'
    >
      <path d='M9 8.5C9 8.22386 8.77614 8 8.5 8H7.5C7.22386 8 7 8.22386 7 8.5L7 18.5C7 18.7761 7.22386 19 7.5 19H8.5C8.77614 19 9 18.7761 9 18.5V8.5Z' />
      <path d='M13 11.5C13 11.2239 12.7761 11 12.5 11H11.5C11.2239 11 11 11.2239 11 11.5V18.5C11 18.7761 11.2239 19 11.5 19H12.5C12.7761 19 13 18.7761 13 18.5V11.5Z' />
      <path d='M16.5 15C16.7761 15 17 15.2239 17 15.5V18.5C17 18.7761 16.7761 19 16.5 19H15.5C15.2239 19 15 18.7761 15 18.5V15.5C15 15.2239 15.2239 15 15.5 15H16.5Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0001 1L21 7.99994V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V2C3 1.44772 3.44772 1 4 1H14.0001ZM5 21V3H14V7.5C14 7.77614 14.2239 8 14.5 8H19V21H5Z'
      />
    </svg>
  </span>
);

export default ReportListIcon;
