import numeral from 'numeral';
import * as strings from './defaultStrings';

export const unitType = {
  distance: 'distance',
  perDistance: 'perDistance',
  ton: 'ton',
  temp: 'temperature',
  weight: 'weight',
  speed: 'speed',
  cmInch: 'cmInch',
  mmInch: 'mmInch',
  meterFeet: 'meterFeet',
  pressure: 'pressure',
  percentage: 'percentage',
  acceleration: 'acceleration'
};

function convertTon(value, isMetric, isUS) {
  // if US we convert to k (kilo lbs)
  const computedValue = isMetric ? value : isUS ? value * 2.20462 : value * 0.984207;
  return numeral(computedValue).format('0.0');
}

export function convertDistance(value, isMetric) {
  const computedValue = isMetric ? value : value * 0.621371;
  return numeral(computedValue).format('0,0');
}

function convertPerDistance(value, isMetric) {
  const computedValue = isMetric ? value : value * 0.621371;
  return numeral(computedValue).format('0.000');
}

function convertSpeed(value, isMetric) {
  const computedValue = isMetric ? value : value * 0.621371;
  return numeral(computedValue).format('0.0');
}

function convertWeight(value, isMetric) {
  const computedValue = isMetric ? value : value * 2.20462;
  return numeral(computedValue).format('0,0');
}

function convertTemperature(value, isMetric) {
  const computedValue = isMetric ? value : value * (9 / 5) + 32;
  return numeral(computedValue).format('0.');
}

function convertcmInch(value, isMetric) {
  const computedValue = isMetric ? value : value * 0.393701;
  return numeral(computedValue).format('0,0');
}

function convertmmInch(value, isMetric) {
  const computedValue = isMetric ? value : value * 0.0393701;
  return numeral(computedValue).format('0,0');
}
function convertMeterFeet(value, isMetric) {
  const computedValue = isMetric ? value : value * 3.28084;
  return numeral(computedValue).format('0,0');
}

function convertPressure(value, isMetric) {
  const computedValue = isMetric ? value : value * 14.5038;
  return numeral(computedValue).format('0.0');
}

function getConvertFunction(type) {
  switch (type) {
    case unitType.ton: {
      return convertTon;
    }
    case unitType.distance: {
      return convertDistance;
    }
    case unitType.perDistance: {
      return convertPerDistance;
    }
    case unitType.speed: {
      return convertSpeed;
    }
    case unitType.cmInch: {
      return convertcmInch;
    }
    case unitType.mmInch: {
      return convertmmInch;
    }
    case unitType.weight: {
      return convertWeight;
    }
    case unitType.meterFeet: {
      return convertMeterFeet;
    }
    case unitType.pressure: {
      return convertPressure;
    }
    case unitType.percentage:
    case unitType.acceleration: {
      return x => x;
    }
    case unitType.temp:
    default: {
      return convertTemperature;
    }
  }
}

function getIsMetric(type, isMetric) {
  switch (type) {
    case unitType.meterFeet:
    case unitType.distance:
    case unitType.cmInch:
    case unitType.mmInch:
    case unitType.speed: {
      return isMetric.distance;
    }

    case unitType.weight:
    case unitType.ton: {
      return isMetric.weight;
    }

    case unitType.pressure: {
      return isMetric.pressure;
    }
    case unitType.temp: {
      return isMetric.temperature;
    }
    default: {
      return isMetric.base;
    }
  }
}

export function convert(isMetricObject, type, value, isUS) {
  if (!type || !parseFloat(value)) {
    return value;
  }
  return getConvertFunction(type)(value, getIsMetric(type, isMetricObject), isUS);
}

export function convertInt(isMetricObject, type, value, isUS) {
  const convertedValue = convert(isMetricObject, type, value, isUS);
  return numeral(convertedValue).value() || 0;
}

// getUnitString is for unit strings with values passed to them
export function getUnitString(type, isMetricObject, isUS) {
  const isMetric = getIsMetric(type, isMetricObject);
  switch (type) {
    case unitType.ton: {
      return !isMetric && isUS ? strings.short.kiloPoundUSAVal : strings.short.tonVal;
    }
    case unitType.distance: {
      return isMetric ? strings.short.kmVal : strings.short.mileVal;
    }
    case unitType.speed: {
      return isMetric ? strings.short.kmSpeedVal : strings.short.mileSpeedVal;
    }
    case unitType.cmInch: {
      return isMetric ? strings.short.centimeterVal : strings.short.inchVal;
    }
    case unitType.mmInch: {
      return isMetric ? strings.short.millimeterVal : strings.short.inchVal;
    }
    case unitType.temp: {
      return isMetric ? strings.short.celciusVal : strings.short.fahrenheitVal;
    }
    case unitType.pressure: {
      return isMetric ? strings.short.pressureBarVal : strings.short.pressurePsiVal;
    }
    default: {
      return strings.short.mileSpeedVal;
    }
  }
}

// getUnit is for just unit strings with no values passed to them
export function getUnit(type, isMetricObject, isUS, isPlural, isFull) {
  const isMetric = getIsMetric(type, isMetricObject);
  switch (type) {
    case unitType.ton: {
      return !isMetric && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton;
    }
    case unitType.speed: {
      return isMetric ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour;
    }
    case unitType.cmInch: {
      return isMetric ? strings.abbrev.centimeter : strings.abbrev.inch;
    }
    case unitType.mmInch: {
      return isMetric ? strings.abbrev.millimeter : strings.abbrev.inch;
    }
    case unitType.temp: {
      return isMetric ? strings.abbrev.celsius : strings.abbrev.fahrenheit;
    }
    case unitType.pressure: {
      return isMetric ? strings.abbrev.bar : strings.abbrev.poundPerSquareInch;
    }
    case unitType.distance:
    default: {
      if (isPlural && isFull) {
        return isMetric ? strings.short.kilometersFull : strings.short.milesFull;
      } else if (isFull) {
        return isMetric ? strings.short.kilometerFull : strings.short.mileFull;
      }
      return isMetric ? strings.abbrev.kilometer : strings.abbrev.mile;
    }
  }
}

export function getConversionString(isMetric, value, type, isUS, isConverted) {
  if (value === null || value === undefined) {
    return [strings.short.doubleDash];
  } else {
    value = isConverted ? value : getConvertFunction(type)(value, getIsMetric(type, isMetric), isUS);
  }

  return [getUnitString(type, isMetric, isUS), { value }];
}

export function getScaleFromValues(
  metricValuesList,
  isMetric,
  type,
  lastValueSign = '',
  firstValueSign = '',
  isInterval = true
) {
  const convert = getConvertFunction(type);

  let stringList = [];
  const numberOfValues = metricValuesList.length;
  for (const [index, element] of metricValuesList.entries()) {
    const value = convert(element, isMetric);
    if (index < numberOfValues - 1) {
      stringList.push(value);
    }
    // if interval, we create the 'v1 - v2' pairs
    if (index > 0 && isInterval) {
      stringList[index - 1] = `${stringList[index - 1]} - ${value}`;
    }

    // if interval and last value sign, the last value is the sign + the last value
    if (index === numberOfValues - 1 && lastValueSign !== '') {
      stringList.push(`${lastValueSign} ${value}`);
    }
    // if not interval, the last value is just the last value
    if (index === numberOfValues - 1 && lastValueSign === '' && !isInterval) {
      stringList.push(value);
    }
  }

  // if interval and first value sign, the first value is the sign + the first value
  if (firstValueSign !== '') {
    stringList = [`${firstValueSign} ${metricValuesList[0]}`].concat(stringList);
  }
  return stringList;
}
