import actionTypes from './actionTypes';
import store from '../app/configureStore.js';
import {
  getNextNotificationState,
  getNextNotificationNotification,
  getInitialNotificationState,
  getNotificationsDeleted,
  getNotificationsRead
} from './ActionResources/transformationFunctions';
import { API_NAMES, defaultEmailSubscription, defaultEBPMSEmailSubscription, reportType } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { selectProductFeatures } from '../selectors/auth';
const notificationSubscribeEndpoint = 'notification/subscribe';

export function receiveNotificationState(newNotifications) {
  const now = Math.round(Date.now() / 1000);
  const state = store.getState().notifications;
  const { buffer, lastMQTTProcessedTime, lastTimeBasedMessages, messages, processing } = getNextNotificationState(
    now,
    state,
    newNotifications
  );
  return {
    type: actionTypes.RECEIVE_NOTIFICATIONS_STATE,
    buffer,
    lastMQTTProcessedTime,
    lastTimeBasedMessages,
    messages,
    processing
  };
}

export const receiveNotification = (notificationAlert, getState) => {
  const state = getState();
  const productFeatures = selectProductFeatures(state);
  if (productFeatures.showNotification) {
    const now = Math.round(Date.now() / 1000);
    const state = store.getState().notifications;
    const payload = getNextNotificationNotification(now, state, notificationAlert);
    return {
      type: actionTypes.RECEIVE_NOTIFICATIONS_NOTIFICATION,
      ...payload
    };
  }
};

function receivedInitialNotification(notifications) {
  const now = Math.round(Date.now() / 1000);
  const state = store.getState().notifications;

  const { lastTimeBasedMessages, messages, readMessages, deletedMessages, processing } = getInitialNotificationState(
    now,
    state,
    notifications
  );
  return {
    type: actionTypes.FETCH_INITIAL_NOTIFICATIONS_STATE_SUCCESS,
    lastTimeBasedMessages,
    messages,
    readMessages,
    deletedMessages,
    processing
  };
}

function updateNotificationsRead(readNotificationIds) {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_READ,
    readNotificationIds
  };
}

function updateNotificationsRemoveRead(removeNotificationIds) {
  const now = Math.round(Date.now() / 1000);
  const state = store.getState().notifications;
  const readMessages = getNotificationsRead(now, state, removeNotificationIds);
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_REMOVE_READ,
    readMessages
  };
}

function updateNotificationsDeleted(deletedNotificationIds) {
  const state = store.getState().notifications;
  const payload = getNotificationsDeleted(state, deletedNotificationIds);
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_DELETED,
    ...payload
  };
}

function reducerNotificationsToAPIFormat(reducerNotifications) {
  const subjects = {};
  Object.keys(reducerNotifications).forEach(alarmKey => {
    let subjectAlarmKey = alarmKey;
    if (alarmKey === 'ebsRed') {
      subjectAlarmKey = 'redLight';
    } else if (alarmKey === 'ebsAmber') {
      subjectAlarmKey = 'amberLight';
    }
    if (alarmKey === reportType.TPMSReport || alarmKey === reportType.monthlyMileageReport) {
      subjects[alarmKey] = reducerNotifications[alarmKey];
    } else {
      subjects[subjectAlarmKey] = {
        inApp: !!reducerNotifications[alarmKey].website,
        browser: !!reducerNotifications[alarmKey].browser,
        email: !!reducerNotifications[alarmKey].email
      };
    }
  });
  return subjects;
}
function updateNotificationReducer(notificationTypesToListenTo) {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_TYPES_TO_LISTEN_TO,
    notificationTypesToListenTo
  };
}

function updateNotificationTypesToListenTo(notificationTypesToListenTo) {
  return dispatch => {
    dispatch(updateNotificationReducer(notificationTypesToListenTo));
    const notificationsApiFormat = reducerNotificationsToAPIFormat(notificationTypesToListenTo);
    dispatch(updateEmailNotificationsSubscriptions({ subjects: notificationsApiFormat }));
  };
}

const isAllTPMS = (subjects, platform) =>
  subjects.tireExtremeOverPressure &&
  subjects.tireExtremeOverPressure[platform] &&
  subjects.tireOverPressure &&
  subjects.tireOverPressure[platform] &&
  subjects.tireExtremeUnderPressure &&
  subjects.tireExtremeUnderPressure[platform] &&
  subjects.tireUnderPressure &&
  subjects.tireUnderPressure[platform];

function updateEmailNotificationsSubscriptions(update) {
  return (dispatch, getState) => {
    const store = getState();
    const { emailSubProcessing } = store.notifications;
    const isMetric = store.auth.isMetric;
    if (!emailSubProcessing) {
      dispatch(updateEmailNotificationsSubscriptionsRequest());
      worker
        .Api({
          method: 'post',
          api: API_NAMES.ODR_LIVE_MAP,
          endpoint: notificationSubscribeEndpoint,
          body: {
            subjects: {
              ...update.subjects,
              tpms: {
                inApp: isAllTPMS(update.subjects, 'inApp'),
                browser: isAllTPMS(update.subjects, 'browser'),
                email: isAllTPMS(update.subjects, 'email')
              }
            },
            settings: {
              isMetric
            }
          }
        })
        .then(res => {
          if (res.success) {
            dispatch(updateEmailNotificationsSubscriptionsSuccess());
          } else {
            dispatch(updateEmailNotificationsSubscriptionsFailure(res));
          }
        })
        .catch(err => dispatch(updateEmailNotificationsSubscriptionsFailure(err)));
    }
  };
}

function getNotificationsSubscriptions() {
  return dispatch => {
    dispatch(getNotificationsSubscriptionsRequest());
    return worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: notificationSubscribeEndpoint })
      .then(res => {
        if (!res || !Object.keys(res).length) {
          dispatch(updateEmailNotificationsSubscriptions(defaultEmailSubscription));
          dispatch(updateNotificationsSubscriptionsDefault());
        } else {
          if (
            !res.withoutISOCable ||
            !res.tireExtremeOverPressure ||
            !res.tireOverPressure ||
            !res.tireUnderPressure ||
            !res.tireExtremeUnderPressure
          ) {
            if (!res.withoutISOCable) {
              res.withoutISOCable = defaultEmailSubscription.subjects.withoutISOCable;
            }
            if (!res.tireExtremeOverPressure) {
              res.tireExtremeOverPressure = res.tpms;
            }
            if (!res.tireOverPressure) {
              res.tireOverPressure = res.tpms;
            }
            if (!res.tireUnderPressure) {
              res.tireUnderPressure = res.tpms;
            }
            if (!res.tireExtremeUnderPressure) {
              res.tireExtremeUnderPressure = res.tpms;
            }
            dispatch(updateEmailNotificationsSubscriptions({ subjects: res }));
          }
          const notificationTypesToListenTo = getNotificationsToListenTo(res);
          dispatch(getNotificationsSubscriptionsSuccess(notificationTypesToListenTo));
        }
      })
      .catch(err => dispatch(getNotificationsSubscriptionsFailure(err.message)));
  };
}

function getNotificationsToListenTo(res) {
  let notificationTypesToListenTo = {};
  Object.keys(res).forEach(key => {
    let fEkey = key;
    if (key === 'redLight') {
      fEkey = 'ebsRed';
    } else if (key === 'amberLight') {
      fEkey = 'ebsAmber';
    }
    if (key === reportType.TPMSReport || key === reportType.monthlyMileageReport) {
      notificationTypesToListenTo[key] = res[key];
    } else {
      notificationTypesToListenTo[fEkey] = {
        website: res[key].inApp ? 1 : 0,
        email: res[key].email ? 1 : 0
      };
    }
  });
  return notificationTypesToListenTo;
}

function getNotificationsSubscriptionsSuccess(notificationTypesToListenTo) {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS,
    notificationTypesToListenTo
  };
}

function updateNotificationsSubscriptionsDefault() {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT
  };
}

function getNotificationsSubscriptionsFailure(error) {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE,
    error
  };
}

function getNotificationsSubscriptionsRequest() {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST
  };
}

function updateEmailNotificationsSubscriptionsRequest() {
  return {
    type: actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST
  };
}

function updateEmailNotificationsSubscriptionsSuccess() {
  return {
    type: actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS
  };
}

function updateEmailNotificationsSubscriptionsFailure(error) {
  return {
    type: actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE,
    error
  };
}

function updateEBPMSEmailNotificationsSubscriptions(subjects) {
  return dispatch => {
    if (!store.notifications?.emailEBPMSSubProcessing) {
      dispatch(updateEBPMSEmailNotificationsSubscriptionsRequest());
      worker
        .Api({ method: 'post', api: API_NAMES.EBPMS, endpoint: notificationSubscribeEndpoint, body: { subjects } })
        .then(res => {
          if (res.success) {
            dispatch(updateEBPMSEmailNotificationsSubscriptionsSuccess(subjects));
          } else {
            dispatch(updateEBPMSEmailNotificationsSubscriptionsFailure(res));
          }
        })
        .catch(err => dispatch(updateEBPMSEmailNotificationsSubscriptionsFailure(err)));
    }
  };
}

function updateEBPMSEmailNotificationsSubscriptionsRequest() {
  return {
    type: actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST
  };
}

function updateEBPMSEmailNotificationsSubscriptionsSuccess(subjects) {
  return {
    type: actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS,
    subjects
  };
}

function updateEBPMSEmailNotificationsSubscriptionsFailure(error) {
  return {
    type: actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE,
    error
  };
}

function getEBPMSNotificationsSubscriptions() {
  return dispatch => {
    dispatch(getEBPMSNotificationsSubscriptionsRequest());
    return worker
      .Api({ method: 'get', api: API_NAMES.EBPMS, endpoint: notificationSubscribeEndpoint })
      .then(res => {
        if (!res || !Object.keys(res).length) {
          dispatch(updateEBPMSEmailNotificationsSubscriptions(defaultEBPMSEmailSubscription));
          dispatch(updateEBPMSNotificationsSubscriptionsDefault(defaultEBPMSEmailSubscription));
        } else {
          dispatch(getEBPMSNotificationsSubscriptionsSuccess(res));
        }
      })
      .catch(err => dispatch(getEBPMSNotificationsSubscriptionsFailure(err.message)));
  };
}

function getEBPMSNotificationsSubscriptionsRequest() {
  return {
    type: actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST
  };
}

function getEBPMSNotificationsSubscriptionsSuccess(ebpmsEmailPreferences) {
  return {
    type: actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS,
    ebpmsEmailPreferences
  };
}

function updateEBPMSNotificationsSubscriptionsDefault(defaultEBPMSEmailSubscription) {
  return {
    type: actionTypes.UPDATE_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT,
    defaultEBPMSEmailSubscription
  };
}

function getEBPMSNotificationsSubscriptionsFailure(error) {
  return {
    type: actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE,
    error
  };
}

export default {
  receivedInitialNotification,
  receiveNotification,
  receiveNotificationState,
  updateNotificationsDeleted,
  updateNotificationsRead,
  updateNotificationsRemoveRead,
  updateNotificationTypesToListenTo,
  updateEmailNotificationsSubscriptions,
  getNotificationsSubscriptions,
  getEBPMSNotificationsSubscriptions,
  updateEBPMSEmailNotificationsSubscriptions
};
