import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../../actions';
import useSize from '../../../../../customHooks/useSize';
import VirtualTable from '../../../../../ReusableComponents/VirtualTable/VirtualTable';
import { TableColumns } from '../Columns/AllColumns';
import { useIntl } from 'react-intl';
import { getGroupColumnsTable } from '../Columns/GroupColumns/getGroupColumns';
import {
  selectRegion,
  selectTrailerLiveMapColumns,
  selectFilteredEntities,
  selectColumnsWidthsliveMap,
  selectColumnsLockedliveMap,
  selectProductFeatures
} from '../../../../../selectors';

const TrailerTable = ({ trailers }) => {
  const containerRef = useRef();
  const containerSize = useSize(containerRef);
  const tableHeight = containerSize.height > 300 ? containerSize.height - 20 : 300;

  // hooks passed down into column components
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const region = useSelector(selectRegion);
  const isMetric = useSelector(store => store.auth.isMetric);
  const trailersList = useSelector(selectFilteredEntities);
  const groups = useSelector(store => store.groups.groups);
  const columnsWidthsliveMap = useSelector(selectColumnsWidthsliveMap);
  const columnsLockedliveMap = useSelector(selectColumnsLockedliveMap);
  const columnKeys = useSelector(selectTrailerLiveMapColumns);
  const productFeatures = useSelector(selectProductFeatures);

  const groupsColumns = getGroupColumnsTable(trailers, groups);
  const activeColumns = [];

  columnKeys.forEach(columnKey => {
    let column = null;
    if (columnKey in TableColumns) {
      column = TableColumns[columnKey]({
        trailers,
        region,
        isMetric,
        devices: trailersList,
        formatMessage,
        productFeatures
      });
    } else if (columnKey in groupsColumns) {
      column = groupsColumns[columnKey];
    }

    if (column) {
      column.width = columnsWidthsliveMap?.[columnKey] || column.width;
      activeColumns.push(column);
    }
  });

  const onClickRow = trailer => {
    dispatch(actions.appState.updateSelectedTnTTrailer(trailer));
  };

  const onColumnsSizeChange = columnWidths => {
    dispatch(actions.settings.saveSetting('columnsWidthsliveMap', columnWidths));
  };

  return (
    <div className='virtual-table-container' ref={containerRef}>
      <VirtualTable
        isMetric={isMetric?.distance ?? false}
        devices={trailersList}
        data={trailers.data}
        columns={activeColumns}
        tableHeight={tableHeight}
        rowHeight={60}
        sortName={trailers.sortName}
        keyExtractor={trailer => trailer.assetId}
        onClickRow={onClickRow}
        onColumnsSizeChange={onColumnsSizeChange}
        lockedColumns={columnsLockedliveMap}
      />
    </div>
  );
};

export default TrailerTable;
