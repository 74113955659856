import React from 'react';
import { PageError } from '../../ReusableComponents/PageError';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // You can also log the error to an error reporting service
  componentDidCatch(error, errorInfo) {
    const { errorLevel } = this.props;
    console.error(errorLevel, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <PageError />;
    }

    return this.props.children;
  }
}
