import React from 'react';
import { useIntl } from 'react-intl';
import { ExclamationCircleOutlined as Icon } from '@ant-design/icons';
import './PageError.scss';
import Button from '../../ReusableComponents/Buttons/OdyTextButton';
import { description, phrase, button } from '../../helpers/defaultStrings';

export const PageError = () => {
  const handleOnClick = () => window.location.reload();
  const { formatMessage } = useIntl();

  return (
    <div className='page-error'>
      <div className='page-error-container'>
        <div className='info-icon-outline'>
          <div className='info-icon-background'></div>
          <Icon className='info-icon' />
        </div>
        <div className='page-error-header'>{formatMessage(phrase.pageErrorHeader)}</div>
        <div className='page-error-body'>{formatMessage(description.pageError)}</div>
        <Button onClick={handleOnClick} className='page-error-btn' isPrimary={true}>
          {formatMessage(button.pageError)}
        </Button>
      </div>
    </div>
  );
};
