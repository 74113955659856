import React, { useEffect, useState } from 'react';

import { CalendarOutlined, ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons';

import { Button, Radio, Row, Tooltip } from 'antd';
import * as strings from '../../helpers/defaultStrings';
import { triggerEvent } from '../../helpers/googleAnalytics';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import { getTimeDate } from '../../helpers/functions';
import OdyToggleButton from '../../ReusableComponents/Buttons/OdyToggleButton';
import { PushpinOutlined } from '@ant-design/icons';
import { getSelectedTnTTrailer } from '../../selectors';

import TrailerInfoPanelHeaderCalendar from './TrailerInfoPanelHeaderCalendar';

export const TrailerInfoPanelHeader = ({
  intl: { formatMessage },
  pos,
  toggleFollowTrailer,
  onHistoryPositionChange,
  pickerSelectedDateTime,
  hours,
  setHours,
  onChangeEndDateTime,
  pickerOpen,
  setPickerOpen,
  isMetric,
  showTimeSelection = true,
  isUS
}) => {
  const trailer = useSelector(getSelectedTnTTrailer);
  const error = useSelector(store => store.devices.error);
  const processing = useSelector(store => store.devices.processingApi);
  const history = useSelector(store => store.devices.history.current);
  const [checked, setChecked] = useState(false);
  const [speedValue, setSpeedValue] = useState('');
  const [axleLoadValue, setAxleLoadValue] = useState('');

  useEffect(() => {
    let speed;
    let axleLoad;
    let date;
    // If position is selected in the charts
    if (pos && pos.time && pos.startTime) {
      speed = pos.startSpeed ? Math.round(pos.startSpeed) : false;
      axleLoad = pos.startAxleLoad ? pos.startAxleLoad / 1000 : false;
      date = pos.startTime ? pos.startTime * 1000 : pos.time;

      if (!speed && !axleLoad) {
        const startTime = pos.startTime - (pos.startTime % 60);
        const endTime = startTime + 59;

        const selectedHistory = history.data.find(
          item => item.time === pos.startTime || (item.time >= startTime && item.time <= endTime)
        );

        if (selectedHistory) {
          speed = selectedHistory.ebs.speed ? Math.round(selectedHistory.ebs.speed) : false;
          axleLoad = selectedHistory.ebs.axleload ? selectedHistory.ebs.axleload / 1000 : false;
        }
      }

      // otherwise get the last valid value from the history
    } else if (!processing && history?.data?.length) {
      const lastHistoryValue = history.data[history.data.length - 1];
      if (lastHistoryValue.ebs) {
        speed = lastHistoryValue.ebs.speed ? Math.round(lastHistoryValue.ebs.speed) : false;
        axleLoad = lastHistoryValue.ebs.axleload ? lastHistoryValue.ebs.axleload / 1000 : false;
        date = lastHistoryValue.time * 1000;
      }
    }

    // if there is no position selected and no history, get the values from trailer last valid values
    if (!processing && !date && trailer?.lastValidValues?.ebs) {
      const ebs = trailer.lastValidValues.ebs;
      speed = ebs.speed ? Math.round(ebs.speed) : false;
      axleLoad = ebs.axleload ? ebs.axleload / 1000 : false;
      date = ebs.time * 1000;
    }

    // only update if there is values
    if (date) {
      setSpeedValue(speed != null ? numeral(speed).format('0.') : formatMessage(strings.short.hyphens));
      setAxleLoadValue(axleLoad != null ? numeral(axleLoad).format('0.0') : formatMessage(strings.short.hyphens));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos, trailer, history, processing, isMetric]);

  const onSwitchChange = checked => {
    toggleFollowTrailer(checked);
    setChecked(checked);
  };

  const onHoursChanges = e => {
    const hours = parseInt(e.target.value, 10);
    triggerEvent('Map', 'Changed History Chart period', 'Live Map screen', hours);
    onHistoryPositionChange(pos, hours);
    setHours(hours);
  };

  const onDataPickerOk = time => {
    setPickerOpen(false);
    // if selected timestamp is close to current timestamp, set to load live data
    if (Date.now() < time + 60 * 1000) {
      onChangeEndDateTime(false);
    } else {
      onChangeEndDateTime(time);
    }
  };

  useEffect(() => {
    onSwitchChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // use selected position date to avoid confusion
  const isValidPos = pos && Object.keys(pos)?.length;
  const displayDate = new Date(pos?.time);
  return (
    <div className={'trailer-info-panel__header'}>
      <Row type='flex' style={{ padding: 8 }} justify='space-between' align='middle'>
        {processing && <LoadingOutlined className='large-margin-left' />}
        {error && (
          <Row type='flex'>
            <div>
              <ExclamationCircleTwoTone twoToneColor='tomato' className='large-margin-left' />{' '}
              {formatMessage(strings.short.error)}
            </div>
          </Row>
        )}
        <Tooltip title={formatMessage(strings.short.followUnfollowTrailer)}>
          <OdyToggleButton
            disabled={!history?.data.length}
            checked={checked}
            onClick={onSwitchChange}
            icon={<PushpinOutlined />}
          >
            {formatMessage(strings.short.follow)}
          </OdyToggleButton>
        </Tooltip>
        {showTimeSelection && (
          <div>
            <Radio.Group size='small' value={hours.toString()} onChange={onHoursChanges}>
              <Radio.Button className={'trailer-info-panel__header__timeframe__item'} value='6'>
                6
              </Radio.Button>
              <Radio.Button className={'trailer-info-panel__header__timeframe__item'} value='12'>
                12
              </Radio.Button>
              <Radio.Button className={'trailer-info-panel__header__timeframe__item'} value='24'>
                24
              </Radio.Button>
              <Radio.Button className={'trailer-info-panel__header__timeframe__item'} value='48'>
                48
              </Radio.Button>
            </Radio.Group>{' '}
            {formatMessage(strings.short.hours)}
          </div>
        )}
        {isValidPos && (
          <Row type='flex'>
            <div>
              <strong>{formatMessage(strings.short.time)}</strong>:{' '}
              {displayDate ? getTimeDate(displayDate) : formatMessage(strings.short.unknown)}
            </div>
            <div className='trailer-history-header-items'>
              <strong>{formatMessage(strings.short.speed)}</strong>:
              <div className='trailer-history-header-item-value-speed'>{speedValue} </div>
              {' ' + formatMessage(isMetric.distance ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour)}
            </div>
            <div className='trailer-history-header-items'>
              <strong>{formatMessage(strings.short.axleLoad)}</strong>:
              <div className='trailer-history-header-item-value-axle-load'>{axleLoadValue} </div>
              {' ' + formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton)}
            </div>
          </Row>
        )}
      </Row>
      {showTimeSelection && (
        <Row className={'trailer-info-panel__header__date-selector'}>
          <TrailerInfoPanelHeaderCalendar
            setPickerOpen={setPickerOpen}
            pickerOpen={pickerOpen}
            onDataPickerOk={onDataPickerOk}
            trailer={trailer}
          />
          <Button.Group>
            <Button onClick={() => setPickerOpen(!pickerOpen)}>
              {' '}
              <CalendarOutlined />{' '}
            </Button>
            <Button
              onClick={() => setPickerOpen(!pickerOpen)}
              className={`trailer-info-panel__header__date-selector__info ${
                pickerOpen ? 'trailer-info-panel__header__date-selector__info--selected' : ''
              }`}
            >
              {pickerSelectedDateTime ? getTimeDate(pickerSelectedDateTime) : 'Now'}
            </Button>
          </Button.Group>
        </Row>
      )}
    </div>
  );
};

export default injectIntl(TrailerInfoPanelHeader);
