import { ENV_CONFIG } from './env-configs';

const { REACT_APP_CI_COMMIT_BRANCH, REACT_APP_CI_COMMIT_SHORT_SHA } = ENV_CONFIG;

export const APP_VERSION = (() => {
  // branch has to follow the structure defined in our release strategy:
  // https://wabco.atlassian.net/wiki/spaces/TRAILERCORE/pages/2254438473/Production+Release
  // i.e.: release/2022.09.03
  const matches = REACT_APP_CI_COMMIT_BRANCH?.match(/release\/(\d+\.\d+\.\d+)/);
  if (matches?.length === 2) {
    return matches[1];
  }

  return REACT_APP_CI_COMMIT_SHORT_SHA;
})();
