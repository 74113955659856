import actionTypes from './actionTypes';
import { getAllTrailersHealth } from './trailersHealth';
import { addErrorMessage } from './messages';
import {
  getCleanedTrailer,
  formatTrailersWeeklyEvolutionDefaultDisplayNames,
  formatTripsProcessingTrips,
  formatEventsProcessingEvents,
  formatDtcProcessingDtc
} from './ActionResources/transformationFunctions';
import store from '../app/configureStore.js';
import getReportTitle from '../helpers/getReportTitle';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { filterByDeviceAssetIds } from '../helpers/functions';
import {
  isTrackAndTraceEnabled,
  selectFilteredEntitiesIds,
  selectWindowSize,
  selectMaxErrorMargin
} from '../selectors';
import { ENV_CONFIG } from '../app/helpers/env-configs';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { parseJson } from '../helpers/parse-json';

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = ENV_CONFIG;

function getAllTrailers() {
  return (dispatch, getState) => {
    const trackAndTraceEnabled = isTrackAndTraceEnabled(getState());
    if (trackAndTraceEnabled) {
      dispatch(getAllTrailersHealth());
    }
  };
}

function clearTrailerDetails() {
  return {
    type: actionTypes.CLEAR_TRAILER_DETAILS
  };
}

function getTrailerDetailsRequest(trailerId) {
  return {
    type: actionTypes.GET_TRAILER_DETAILS_REQUEST,
    trailerId
  };
}

function getTrailerDetailsSuccess(trailer) {
  return {
    type: actionTypes.GET_TRAILER_DETAILS_SUCCESS,
    trailer
  };
}

function getTrailerDetailsFailure(error, trailerId) {
  const state = store.getState().trailerDetails;
  if (state?.trailer?.item?.assetId === trailerId) {
    return {
      type: actionTypes.GET_TRAILER_DETAILS_FAILURE,
      error,
      trailerId
    };
  } else {
    return { type: null };
  }
}

function updateTrailersFromDevicesUpdate(devices) {
  const state = store.getState();
  const trailerDetails = state.trailerDetails;
  const deviceAssetIds = selectFilteredEntitiesIds(state);
  const payload = formatTrailersWeeklyEvolutionDefaultDisplayNames(trailerDetails, devices);
  if (!payload.trailers.allItems) {
    payload.trailers.allItems = payload.trailers.items;
  }

  payload.trailers.items = filterByDeviceAssetIds(payload.trailers.allItems, deviceAssetIds);
  return {
    type: actionTypes.UPDATE_TRAILERS_FROM_DEVICES,
    ...payload
  };
}

function getTrailerDetailsTripsSuccess(trips, trailerId, final) {
  const state = store.getState().trailerDetails;
  const payload = formatTripsProcessingTrips(state, trips, trailerId, final);
  if (payload) {
    return {
      type: actionTypes.GET_TRAILER_DETAILS_TRIPS_SUCCESS,
      ...payload
    };
  } else {
    return {
      type: null
    };
  }
}

function getTrailerDetailsDtcSuccess(dtc, trailerId, final) {
  const state = store.getState().trailerDetails;
  const payload = formatDtcProcessingDtc(state, dtc, trailerId, final);
  if (payload) {
    return {
      type: actionTypes.GET_TRAILER_DETAILS_DTC_SUCCESS,
      ...payload
    };
  } else {
    return {
      type: null
    };
  }
}

function getTrailerDetailsEventsSuccess(events, trailerId, final) {
  const state = store.getState().trailerDetails;
  const payload = formatEventsProcessingEvents(state, events, trailerId, final);
  if (payload) {
    return {
      type: actionTypes.GET_TRAILER_DETAILS_EVENTS_SUCCESS,
      ...payload
    };
  } else {
    return {
      type: null
    };
  }
}

function getTrailerCMIReports(trailerId) {
  return dispatch => {
    dispatch(getTrailerCMIReportsRequest());
    worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${trailerId}/cmi` })
      .then(res => {
        if (res.type === 'success') {
          dispatch(getTrailerCMIReportsSuccess(res.data));
        } else {
          dispatch(getTrailerCMIReportsFailure(res.message));
        }
      })
      .catch(err => {
        dispatch(getTrailerCMIReportsFailure(err));
      });
  };
}

function getTrailerCMIReportsRequest() {
  return {
    type: actionTypes.GET_TRAILER_CMI_REPORT_REQUEST
  };
}

function getTrailerCMIReportsSuccess(data) {
  return {
    type: actionTypes.GET_TRAILER_CMI_REPORT_SUCCESS,
    data
  };
}

function getTrailerCMIReportsFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_CMI_REPORT_FAILURE,
    error
  };
}

function retrieveTrailerRFData(trailerId) {
  return dispatch => {
    dispatch(retrieveTrailerRFDataRequest());
    worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${trailerId}/rf` })
      .then(res => {
        if (res.type === 'success') {
          dispatch(retrieveTrailerRFDataSuccess(res.data));
        } else {
          dispatch(retrieveTrailerRFDataFailure(res.message));
        }
      })
      .catch(err => {
        dispatch(retrieveTrailerRFDataFailure(err));
      });
  };
}

function retrieveTrailerRFDataRequest() {
  return {
    type: actionTypes.GET_TRAILER_RF_DATA_REQUEST
  };
}

function retrieveTrailerRFDataSuccess(data) {
  return {
    type: actionTypes.GET_TRAILER_RF_DATA_SUCCESS,
    data
  };
}

function retrieveTrailerRFDataFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_RF_DATA_FAILURE,
    error
  };
}

function getTrailerDetails(trailerId, tempToken) {
  return dispatch => {
    dispatch(getTrailerDetailsRequest(trailerId));
    const options = tempToken ? { headers: { 'X-Temp-Access-Token': tempToken } } : null;
    return worker
      .Api({
        method: 'post',
        api: API_NAMES.ODR_LIVE_MAP,
        endpoint: tempToken ? 'trailerExternal/idcard' : `trailers/${trailerId}/idcard`,
        ...options
      })
      .then(res => {
        const state = store.getState().trailerDetails;
        const cleanTrailer = getCleanedTrailer(state, res, state.trailersDefaultDisplayNames);
        dispatch(getTrailerDetailsSuccess(cleanTrailer));
        // For the subsequent call needed to retrieve all trip & events we need to refer to res.assetId
        // in order for the temporary access to work
        dispatch(getTrailerDetailsTripsSuccess([], res.assetId, true));
        dispatch(getTrailerDetailsEventsSuccess([], res.assetId, true));
        dispatch(getTrailerDetailsDtcSuccess([], res.assetId, true));
      })
      .catch(err => {
        if (err?.response?.status === 404) {
          dispatch(addErrorMessage(`Sorry, an ODR file has not been uploaded for trailer ${trailerId}`));
        } else dispatch(addErrorMessage(`Error while retrieving details for trailer ${trailerId}`));
        dispatch(getTrailerDetailsFailure(err));
      });
  };
}

export const getTrailersGroups = () => {
  return {
    type: actionTypes.GET_TRAILER_GROUPS
  };
};

export const filterTrailers = (filterType, values, dimension) => {
  return {
    type: actionTypes.FILTER_TRAILERS,
    filterType,
    values,
    dimension
  };
};

function resetTrailerSelection() {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_TRAILER
    });
  };
}

function getShareableLinkRequest() {
  return {
    type: actionTypes.GET_SHAREABLE_LINK_REQUEST
  };
}

function getShareableLinkSuccess(link) {
  return {
    type: actionTypes.GET_SHAREABLE_LINK_SUCCESS,
    link
  };
}

function getShareableLinkFailure(error) {
  return {
    type: actionTypes.GET_SHAREABLE_LINK_FAILURE,
    error
  };
}

function getShareableLink(assetId, period) {
  return dispatch => {
    dispatch(getShareableLinkRequest());
    const body = {
      assetId,
      period: period || '1d'
    };
    return worker
      .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'createExternalToken', body })
      .then(res => dispatch(getShareableLinkSuccess(res)))
      .catch(err => dispatch(getShareableLinkFailure(err)));
  };
}

function requestOdrRequest() {
  return {
    type: actionTypes.REQUEST_ODR_REQUEST
  };
}

function requestOdrSuccess(res, trailerId) {
  return {
    type: actionTypes.REQUEST_ODR_SUCCESS,
    res,
    trailerId
  };
}

function requestOdrFailure(error) {
  return {
    type: actionTypes.REQUEST_ODR_FAILURE,
    error
  };
}

function getOdrError(error) {
  if (error.response && error.response.data && error.response.data.error) {
    const errorData = parseJson(error.response.data.error);
    if (errorData && errorData.text) {
      const errorText = parseJson(errorData.text);
      if (errorText.Error) {
        return errorText.Error;
      }
    }
  }
  return error;
}

function requestOdr(trailerId) {
  return dispatch => {
    dispatch(requestOdrRequest());
    return worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${trailerId}/requestOdr` })
      .then(res => {
        dispatch(requestOdrSuccess(res, trailerId));
      })
      .catch(err => {
        dispatch(requestOdrFailure(getOdrError(err)));
      });
  };
}

function getReportRequest(filename) {
  return {
    type: actionTypes.GET_REPORT_REQUEST,
    filename
  };
}

function getReportSuccess(filename) {
  return {
    type: actionTypes.GET_REPORT_SUCCESS,
    filename
  };
}

function getReportFailure(filename) {
  return {
    type: actionTypes.GET_REPORT_FAILURE,
    filename
  };
}

function getReport(trailer, options) {
  return (dispatch, getState) => {
    const state = getState();
    const settingsWidowSize = selectWindowSize(state);
    const maxErrorMargin = selectMaxErrorMargin(state);
    const windowSize = options.windowSize ?? settingsWidowSize;
    const { deviceId, tipNum } = trailer;
    const { date = moment(), errorMargin = maxErrorMargin } = options;
    const params = {
      queryStringParameters: {
        windowSize,
        errorMargin,
        endTime: +date.endOf('day')
      },
      headers: {
        accept: 'application/pdf'
      },
      responseType: 'arraybuffer'
    };
    const filename = getReportTitle(tipNum, date, windowSize);
    dispatch(getReportRequest(filename));
    worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `report/${deviceId}`, params })
      .then(res => {
        fileDownload(res, filename);
        dispatch(getReportSuccess(filename));
      })
      .catch(err => {
        dispatch(addErrorMessage(`Sorry, could not download ${filename}.`));
        dispatch(getReportFailure(filename, err));
      });
  };
}

function getTrailerlocationRequest(assetId) {
  return {
    type: actionTypes.GET_TRAILER_LOCATION_REQUEST,
    assetId
  };
}

function getTrailerlocationSuccess(assetId, location) {
  return {
    type: actionTypes.GET_TRAILER_LOCATION_SUCCESS,
    assetId,
    location
  };
}

function getTrailerlocationFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_LOCATION_FAILURE,
    error
  };
}

function updateTrailerlocation(assetId, { longitude, latitude }) {
  return dispatch => {
    dispatch(getTrailerlocationRequest(assetId));
    const mapboxPlaceUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    return fetch(mapboxPlaceUrl)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return response.text().then(text => {
            dispatch(getTrailerlocationFailure('Response when calling MapBox : [' + response.status + ']' + text));
          });
        }
      })
      .then(({ features }) => features && features.reduce((acc, elt) => ({ ...acc, [elt.place_type]: elt.text }), {}))
      .then(result => {
        dispatch(getTrailerlocationSuccess(assetId, result));
      });
  };
}

function updateTrailerSelectedWheels(trailerAssetId, selectedWheels) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_TRAILERS_SELECTED_WHEELS,
      trailerAssetId,
      selectedWheels
    });
  };
}

export default {
  getAllTrailers,
  getTrailersGroups,
  filterTrailers,
  getTrailerDetails,
  clearTrailerDetails,
  getTrailerCMIReports,
  retrieveTrailerRFData,
  resetTrailerSelection,
  getShareableLink,
  requestOdr,
  updateTrailersFromDevicesUpdate,
  getReport,
  updateTrailerlocation,
  updateTrailerSelectedWheels
};
