import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.vinNumber) {
    return <p>{rowData.vinNumber}</p>;
  }
  return <NoValueSpan />;
};

const VinNumberColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.abbrev.vehicleIdentificationNumber)}>
        {formatMessage(strings.abbrev.vehicleIdentificationNumber)}
      </Tooltip>
    ),
    key: 'vinNumber',
    dataIndex: 'vinNumber',
    searchKey: formatMessage(strings.abbrev.vehicleIdentificationNumber),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} />,
    search: searchString =>
      trailers.filter('vinNumber', t => t.vinNumber?.toString().toLowerCase().includes(searchString.toLowerCase())),
    removeSearch: () => trailers.removeFilter('vinNumber'),
    width: 200
  };
};

const VinNumberCSVHeader = formatMessage => formatMessage(strings.abbrev.vehicleIdentificationNumber);
const VinNumberCSVData = trailer => trailer.vinNumber;
const VinNumberCSVColumn = {
  header: VinNumberCSVHeader,
  data: VinNumberCSVData
};

export default { table: VinNumberColumn, csv: VinNumberCSVColumn };
