import React from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Button, Input } from 'antd';
import { injectIntl } from 'react-intl';
import JetpackTableHeaderCell from './JetpackTableHeaderCell';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import actions from '../../actions';
import ColumnSelectionWrapper from './ColumnSelectionWrapper';
import './JetpackTable.scss';
import * as strings from '../../helpers/defaultStrings';
import { triggerGenericEvent } from '../../helpers/googleAnalytics';
import { getTableHeight } from '../../helpers/functions';
import { selectSettingsItems } from '../../selectors/settings';
import { sendCustomEvent } from '../../app/helpers/new-relic';

const resizeSubject = new BehaviorSubject().pipe(
  filter(columnWidths => !!columnWidths),
  debounceTime(750)
);

class JetpackTable extends React.Component {
  searchInput = {};
  lastFilterTime = 0;

  components = {
    header: {
      cell: JetpackTableHeaderCell
    }
  };

  constructor(props) {
    super();
    this.state = {
      searchText: null,
      filteredInfo: null,
      sortedInfo: null,
      selectedColumnKeys: null,
      columnWidths: {},
      resizeSubscription: null,
      height: window.innerHeight - (!isNaN(props.topAndBottomPadding) ? props.topAndBottomPadding : 175),
      headerHeight: 77,
      analyticsColumnWidth: ''
    };
  }

  componentDidMount() {
    const { jetpackColumns, selectedColumnKeys, columnWidths, onChange, data } = this.props;
    if (jetpackColumns) {
      this.setState({ selectedColumnKeys: selectedColumnKeys || jetpackColumns.map(c => c.key), columnWidths });
    }

    window.addEventListener('resize', this.updateHeight);
    const resizeSubscription = resizeSubject.subscribe(columnWidths => {
      this.saveColumnsWidths(columnWidths);
      const { analyticsColumnWidth } = this.state;
      const { user } = this.props;
      sendCustomEvent(analyticsColumnWidth, { identity: user.identity });
    });

    this.setState({ columnWidths, resizeSubscription });
    if (onChange) {
      onChange({}, {}, {}, data);
    }
    setTimeout(() => {
      this.updateHeight();
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { selectedColumnKeys, columnWidths, topAndBottomPadding } = this.props;
    if (selectedColumnKeys && JSON.stringify(selectedColumnKeys) !== JSON.stringify(prevProps.selectedColumnKeys)) {
      this.setState({ selectedColumnKeys });
      this.updateHeight();
    }

    if (columnWidths && JSON.stringify(columnWidths) !== JSON.stringify(prevProps.columnWidths)) {
      const { user } = this.props;
      Object.keys(columnWidths).forEach(key => {
        sendCustomEvent(`${key.toUpperCase()}_WIDTH`, { identity: user.identity });
      });
      this.setState({ columnWidths });
      this.updateHeight();
    }
    if (topAndBottomPadding !== prevProps.topAndBottomPadding) {
      this.setState({ height: window.innerHeight - (!isNaN(topAndBottomPadding) ? topAndBottomPadding : 175) });
      this.updateHeight();
    }
  }

  componentWillUnmount() {
    const { resizeSubscription } = this.state;
    if (resizeSubscription) {
      resizeSubscription.unsubscribe();
    }
    window.removeEventListener('resize', this.updateHeight);
  }

  handleSearch = (selectedKeys, confirm, field) => {
    confirm();
    triggerGenericEvent('Filter', 'Search', field);
    this.setState({ searchText: { [field]: selectedKeys[0] } });
  };

  handleReset = (clearFilters, field) => {
    clearFilters();
    this.setState({ searchText: { [field]: '' } });
  };

  handleChange = (pagination, filters, sorter, extra) => {
    const { onChange } = this.props;
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
    if (onChange) {
      onChange(pagination, filters, sorter, extra.currentDataSource);
    }
  };

  handleResize =
    index =>
    (e, { size }) => {
      let { columnWidths } = this.state;
      columnWidths[index] = Math.max(size.width, 80);
      this.setState({ columnWidths, analyticsColumnWidth: `${index.toUpperCase()}_WIDTH` });
      resizeSubject.next(columnWidths);
    };

  getColumns = (jetpackColumns, data) => {
    let { searchText, columnWidths } = this.state;
    const { columnsLocked } = this.props;
    searchText = searchText || {};
    const { formatMessage } = this.props.intl;

    return jetpackColumns.map(col => {
      let colSpec = col;
      colSpec.onHeaderCell = column => ({
        width: column.width,
        onResize: this.handleResize(col.key)
      });
      if (col.filterType === 'search') {
        colSpec = {
          ...colSpec,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='custom-filter-dropdown'>
              <Input
                ref={elem => (this.searchInput[col.key] = elem)}
                placeholder={formatMessage(strings.short.searchValue)}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, col.key)}
              />
              <Button type='primary' onClick={() => this.handleSearch(selectedKeys, confirm, col.key)}>
                {formatMessage(strings.short.search)}
              </Button>
              <Button onClick={() => this.handleReset(clearFilters, col.key)}>
                {formatMessage(strings.short.reset)}
              </Button>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
          onFilter: (value, record) => {
            const recordValue = record[col.key] || col.altText;
            return recordValue && (recordValue + '').toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                const searchInput = this.searchInput[col.key];
                if (searchInput) {
                  searchInput.focus();
                }
              });
            }
          }
        };
        if (searchText[col.key]) {
          colSpec.render = text => {
            text = (text || col.altText) + '';
            return (
              <span>
                {text.split(new RegExp(`(${searchText[col.key]})`, 'gi')).map(
                  (fragment, i) =>
                    fragment.toLowerCase() === (searchText[col.key] + '').toLowerCase() ? (
                      <span key={i} className='highlight'>
                        {fragment}
                      </span>
                    ) : (
                      fragment
                    ) // eslint-disable-line
                )}
              </span>
            );
          };
        }
      } else if (col.filterType === 'categorical') {
        colSpec = {
          ...colSpec,
          filters: [...new Set(data.map(d => (d[col.key] || formatMessage(strings.short.unknown)).toString()))]
            .map(d => ({ text: d, value: d }))
            .filter(d => d),
          onFilter: (value, record) => {
            if (Date.now() - this.lastFilterTime > 1500) {
              triggerGenericEvent('Filter', 'Filter', col.key);
              this.lastFilterTime = Date.now();
            }
            return (record?.col?.key ?? formatMessage(strings.short.unknown)).toString() === value.toString();
          }
        };
      }

      if (col.filteredRender) {
        colSpec = {
          ...colSpec,
          render: value => {
            const { filteredInfo } = this.state;
            const filteredValue = filteredInfo ? filteredInfo[col.key] || [] : [];
            return col.filteredRender(value, filteredValue);
          }
        };
      }

      if (this.props.stickyTable) {
        colSpec.width =
          columnWidths[colSpec.key] !== undefined && columnWidths[colSpec.key] !== null
            ? columnWidths[colSpec.key]
            : colSpec.width;
      }

      if (columnsLocked[colSpec.key]) {
        colSpec.fixed = columnsLocked[colSpec.key];
        colSpec.className = 'jetpack-table-column';
        colSpec.ellipsis = true;
        colSpec.width =
          columnWidths[colSpec.key] !== undefined && columnWidths[colSpec.key] !== null
            ? columnWidths[colSpec.key]
            : colSpec.width;
      }

      return colSpec;
    });
  };

  getSelectedColumns = (columns, selectedColumnKeys) => {
    const columnsKeys = columns.map(c => c.key);
    if (selectedColumnKeys) {
      const selectedValidColumnKeys = selectedColumnKeys.filter(c => columnsKeys.includes(c));
      return selectedValidColumnKeys.map(key => columns.find(c => c.key === key));
    }

    return columns;
  };

  saveColumnsWidths = columnsWidths => {
    const { saveWidths } = this.props;
    saveWidths(columnsWidths);
  };

  updateHeight = () => {
    const { height } = this.state;
    const { topAndBottomPadding } = this.props;
    const defaultTableHeaderHeight = 77;
    const tableHeaderCollection = document.getElementsByClassName('ant-table-header');
    const actualTableHeaderHeight = tableHeaderCollection[0]?.clientHeight || defaultTableHeaderHeight;
    const newTableHeight = getTableHeight(
      topAndBottomPadding,
      window.innerHeight,
      actualTableHeaderHeight,
      defaultTableHeaderHeight
    );
    if (height !== newTableHeight) {
      this.setState({ height: newTableHeight });
    }
  };

  reduceColumnsWidth = columns => {
    return (columns || []).reduce((prev, col) => {
      return (
        prev +
        (Array.isArray(col.children) && col.children.length > 0
          ? this.reduceColumnsWidth(col.children)
          : col.width > 0
          ? col.width
          : 0)
      );
    }, 0);
  };

  render() {
    const {
      id,
      data,
      jetpackColumns,
      jetpackTitle,
      stickyTable,
      columnsLocked,
      saveSettingsGroup,
      setColumnSelectionOpen,
      isColumnSelectionOpen,
      hideColumnSelectorBtn
    } = this.props;
    const { selectedColumnKeys, height } = this.state;
    const { formatMessage } = this.props.intl;

    const columns = this.getColumns(jetpackColumns, data);
    const selectedColumns = this.getSelectedColumns(columns, selectedColumnKeys);
    let scroll = {
      x: true
    };

    let style = {};
    if (stickyTable) {
      scroll = { x: this.reduceColumnsWidth(selectedColumns), y: height };
      style = {
        '--real-height': `${height - 70}px`
      };
    }

    return (
      <div className='jetpack-table-container'>
        {jetpackTitle && <h3>{jetpackTitle}</h3>}
        {this.props.columnSelector && (
          <div className='floating-selector'>
            <ColumnSelectionWrapper
              tableId={id}
              columns={columns}
              selectedColumnKeys={selectedColumnKeys}
              saveSettingsGroup={saveSettingsGroup}
              columnsLocked={columnsLocked}
              dynamicWidths={!stickyTable}
              setColumnSelectionOpen={setColumnSelectionOpen}
              isColumnSelectionOpen={isColumnSelectionOpen}
              hideColumnSelectorBtn={hideColumnSelectorBtn}
            />
          </div>
        )}
        <Table
          {...this.props}
          className={stickyTable ? 'stickyTable' : 'stdTable'}
          columns={selectedColumns}
          dataSource={data}
          onChange={this.handleChange}
          scroll={scroll}
          style={style}
          components={this.components}
          locale={{
            filterTitle: formatMessage(strings.short.filterMenu),
            filterConfirm: formatMessage(strings.short.ok),
            filterReset: formatMessage(strings.short.reset),
            emptyText: formatMessage(strings.short.noData)
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(store, props) {
  let selectedColumnKeys = null;
  const items = selectSettingsItems(store);

  // trailerEbpms, eventList, odrFileList, trailerHealth, liveMap, drawerList
  if (items[`columnSelection${props.id}`] && props.columnSelector) {
    selectedColumnKeys = items[`columnSelection${props.id}`];
  } else if (props.defaultSelectedColumnKeys) {
    selectedColumnKeys = props.defaultSelectedColumnKeys;
  }
  let columnsLocked = {};
  if (items[`columnsLocked${props.id}`] && props.columnSelector) {
    columnsLocked = items[`columnsLocked${props.id}`];
  } else if (props.defaultColumnsLocked) {
    columnsLocked = props.defaultColumnsLocked;
  }
  const incomingColumnWidths = items[`columnsWidths${props.id}`] ? items[`columnsWidths${props.id}`] : {};
  return {
    selectedColumnKeys,
    columnsLocked,
    columnWidths: incomingColumnWidths,
    user: store.auth.user
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    saveSettingsGroup: value => dispatch(actions.settings.saveSettingGroup(value)),
    saveWidths: value => dispatch(actions.settings.saveSetting(`columnsWidths${props.id}`, value))
  };
}
export { JetpackTable as JetPackTableUI };

JetpackTable.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(JetpackTable));
