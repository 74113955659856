import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import { point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import DeckGL from '@deck.gl/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrailersLayers,
  getClusterLayers,
  getSelectedTrailerLayers,
  getTrailersIconLayers,
  getTrailerAlertsLayers,
  getGetJSONLayers
} from '../../../../helpers/livemapLayers';
import {
  zoomFollowTrailer,
  zoomInBounds,
  zoomInTrailerRoute,
  zoomOutAllTrailers
} from '../../../../helpers/livemapZoom';
import actions from '../../../../actions';

import TrailerMapTooltip from '../../../TrailerMap/TrailerMapTooltip';
import TrailerMapGeoJSONTooltip from '../../../TrailerMap/TrailerMapGeoJSONTooltip';

import './TrailerMapV2.scss';
import MapToolbox from '../../../Utils/MapToolbox';
import { setMapboxLanguage } from '../../../../helpers/functions';
import BoxSelectionLayer from '../Layers/BoxSelectionLayer';
import { filterDataByRect } from '../helpers/filterDataByRect';
import ClearSelectionBtn from '../helpers/ClearSelectionBtn';
import {
  selectRegion,
  selectShowGeoJsonLayers,
  selectShowAlerts,
  selectAlertItems,
  selectFilteredEntities,
  getSelectedTnTTrailerAssetId,
  selectMapAccessToken,
  selectMapStyleURL,
  selectProductFeatures
} from '../../../../selectors';
import { zoomLiveMapToAssets } from '../../../../actions/thunks/zoom-live-map-to-asset';
import { getViewPortPolygon } from './viewport';
import { zoomOutAllPlaces } from '../../../../helpers/places';

const TrailerMapV2 = ({ trailers, clearFilter, filterTrailers, isMapSelectionFilterActive }) => {
  const dispatch = useCallback(useDispatch(), []);

  const mapViewStateTimeout = useRef(null);
  const mapRef = useRef(null);
  const mapAccessToken = useSelector(selectMapAccessToken);
  const mapStyleURL = useSelector(selectMapStyleURL);
  const initViewState = useSelector(store => store.appState.viewState);
  const places = useSelector(store => store.places);
  const trailersList = useSelector(selectFilteredEntities);
  const selectedTrailer = useSelector(store => store.appState.selectedTnTTrailer || {});
  const selectedTnTTrailerAssetId = useSelector(getSelectedTnTTrailerAssetId);
  const processing = useSelector(store => store.devices.processingApi);
  const processingAlerts = useSelector(store => store.alerts.processing);
  const mapRoutes = useSelector(store => store.devices.mapRoutes || {});
  const animatedHeadRoutes = useSelector(store => store.devices.animatedHeadRoutes || {});
  const livemapSelectedDevices = useSelector(store => store.devices.livemapSelectedDevices || []);
  const region = useSelector(selectRegion);
  const language = useSelector(store => store.language.language);
  const historyCurrentAssetId = useSelector(store => store?.devices?.history?.current?.assetId ?? '');

  const hours = useSelector(store => store.liveMap.hours);
  const followTrailer = useSelector(store => store.liveMap.followTrailer);
  const showAlerts = useSelector(selectShowAlerts);
  const historyPosition = useSelector(store => store.liveMap.historyPosition);
  const trailerInfoPanelEndDate = useSelector(store => store.liveMap.trailerInfoPanelEndDate);
  const alerts = useSelector(selectAlertItems);
  const visibleTrailerIds = useSelector(store => store.liveMap.visibleTrailerIds);
  const showGeoJSONLayers = useSelector(selectShowGeoJsonLayers);
  const productFeatures = useSelector(selectProductFeatures);

  /** Has the ref element been initialised **/
  const [refVisible, setRefVisible] = useState(false);
  const [isBoxSelectActive, setIsBoxSelectActive] = useState(false);

  /** Has the map initally flown to bounds **/
  const [mapInitialized, setMapInitialized] = useState(false);

  /** Helper to destroy the map on unmount **/
  const [GL, setGL] = useState(null);

  /** Tooltip on mouse hover trailer icon, trailer trail and alert icon **/
  const [tooltip, setTooltip] = useState({});

  /** Tooltip on mouse hover a geozone **/
  const [placeTooltip, setPlaceTooltip] = useState(false);

  /** Map state manager to move it around and zoom in zoom out **/
  const [viewState, setViewState] = useState(initViewState);

  /** Change mouse on hovering layers */
  const [isHover, setIsHover] = useState(false);

  /** Devices actives to be displayed in the map **/
  const activeDevices = trailers;

  /** List of active device asset IDs **/
  const [activeDevicesAssetIDs, setActiveDevicesAssetIDs] = useState([]);
  /** Devices that are not grouped in Clusters **/
  const [visibleTrailers, setVisibleTrailers] = useState([]);
  /** Places that are not grouped in Clusters **/
  const [visiblePlaces, setVisiblePlaces] = useState([]);

  /** Clusters **/
  const [clusterLayers, setClusterLayers] = useState([]);
  /** Trailers Trails, Trailer Labels and Trailer Trips **/
  const [trailerLayers, setTrailerLayers] = useState([]);
  /** Trailer Dashed trip line and ghost marker layer **/
  const [selectedTrailerLayers, setSelectedTrailerLayers] = useState([]);
  /** Trailers Icons **/
  const [trailerIconLayers, setTrailerIconLayers] = useState([]);
  /** Trailer Alerts **/
  const [trailerAlertLayers, setTrailerAlertLayers] = useState([]);
  /** Trailer Geojson **/
  const [geoJsonLayers, setGeoJsonLayers] = useState([]);

  const layers = [];
  if (isBoxSelectActive) {
    const boxSelectionLayer = BoxSelectionLayer(rectangle =>
      zoomLiveMapToAssets({ rectangle, trailers, filterDataByRect, setIsBoxSelectActive, filterTrailers, dispatch })
    );
    layers.push(boxSelectionLayer);
  }

  const clearSelection = () => {
    clearFilter();
    dispatch(actions.devices.setLivemapSelectedDevices(activeDevicesAssetIDs));
  };

  const updateTrailer = useCallback(
    trailer => dispatch(actions.appState.updateSelectedTnTTrailer(trailer)),
    [dispatch]
  );
  const updateTooltip = useCallback(({ x, y, object }) => setTooltip({ x, y, object }), []);

  /**
   * Trigger when change the device list or user select an specific Trailer
   */
  useEffect(() => {
    const devicesActive = (trailersList || []).filter(
      device =>
        (!selectedTrailer.assetId || device.assetId === selectedTrailer.assetId) &&
        device.gnss &&
        device.gnss.longitude &&
        device.gnss.latitude
    );
    const newDevicesIds = devicesActive.map(device => device.assetId).sort();

    if (activeDevicesAssetIDs.join('') !== newDevicesIds.join('')) {
      setActiveDevicesAssetIDs(devicesActive.map(device => device.assetId).sort());
    }
    // eslint-disable-next-line
  }, [trailersList, selectedTrailer.assetId]);

  // places
  useEffect(() => {
    setGeoJsonLayers(
      places.loaded && showGeoJSONLayers ? getGetJSONLayers(visiblePlaces, placeTooltip, setPlaceTooltip) : []
    );
  }, [showGeoJSONLayers, placeTooltip, places, visiblePlaces]);

  useEffect(() => {
    if (placeTooltip) {
      setPlaceTooltip(false);
    }
    // eslint-disable-next-line
  }, [visiblePlaces]);

  useEffect(() => {
    setTrailerLayers(
      getTrailersLayers(
        visibleTrailers,
        mapRoutes,
        animatedHeadRoutes,
        hours,
        selectedTrailer,
        mapRef,
        historyPosition,
        updateTooltip,
        trailerInfoPanelEndDate
      )
    );
    setTrailerIconLayers(
      getTrailersIconLayers(
        visibleTrailers,
        mapRoutes,
        selectedTrailer,
        historyPosition,
        updateTrailer,
        updateTooltip,
        hours,
        trailerInfoPanelEndDate
      )
    );
    // eslint-disable-next-line
  }, [
    visibleTrailers,
    mapRoutes,
    animatedHeadRoutes,
    selectedTrailer.assetId,
    historyPosition,
    hours,
    updateTooltip,
    updateTrailer
  ]);

  useEffect(() => {
    !processingAlerts &&
      setTrailerAlertLayers(
        getTrailerAlertsLayers(
          visibleTrailers,
          alerts ? alerts : null,
          selectedTrailer,
          mapRoutes,
          hours,
          updateTooltip,
          region,
          trailerInfoPanelEndDate,
          productFeatures
        )
      );
    // eslint-disable-next-line
  }, [
    visibleTrailers,
    alerts,
    mapRoutes,
    selectedTrailer.assetId,
    hours,
    updateTooltip,
    processingAlerts,
    trailerInfoPanelEndDate,
    showAlerts
  ]);

  const checkTrailersVisibleOnMap = () => {
    if (!trailersList?.length || !mapInitialized) return;

    const viewPortPolygon = getViewPortPolygon({ viewState, mapRef });
    if (!viewPortPolygon) return;

    const newVisibleTrailerIds = trailersList
      .filter(trailer => {
        if (trailer?.gnss?.longitude && trailer?.gnss?.latitude) {
          const trailerPoint = point([trailer?.gnss?.longitude, trailer?.gnss?.latitude]);
          return booleanPointInPolygon(trailerPoint, viewPortPolygon);
        }
        return false;
      })
      .map(trailer => trailer.assetId);

    if (newVisibleTrailerIds.toString() !== visibleTrailerIds.toString()) {
      dispatch(actions.liveMapActions.setLiveMapVisibleTrailers(newVisibleTrailerIds));
    }
  };

  useEffect(() => {
    if (!processing) {
      setSelectedTrailerLayers(
        getSelectedTrailerLayers(selectedTrailer, mapRoutes, hours, mapRef, trailerInfoPanelEndDate)
      );
    }
    // eslint-disable-next-line
  }, [selectedTrailer.assetId, mapRoutes, hours, processing, trailerInfoPanelEndDate]);

  useEffect(() => {
    setClusterLayers(
      getClusterLayers(
        activeDevices,
        setVisibleTrailers,
        updateTrailer,
        selectedTrailer,
        onClusterClick,
        showGeoJSONLayers ? places?.items : [],
        setVisiblePlaces,
        onClickPlacesCluster
      )
    );
    // eslint-disable-next-line
  }, [activeDevices, selectedTrailer.assetId, updateTrailer, showGeoJSONLayers]);

  useEffect(() => {
    if (!selectedTnTTrailerAssetId) {
      zoomOutAllTrailers(activeDevices, livemapSelectedDevices, flightToBounds);
    }
    // eslint-disable-next-line
  }, [activeDevicesAssetIDs, selectedTnTTrailerAssetId, livemapSelectedDevices, trailers.length]);

  useEffect(() => {
    if (selectedTrailer.assetId && !processing && selectedTrailer.assetId === historyCurrentAssetId && !followTrailer) {
      zoomInTrailerRoute(
        selectedTrailer,
        mapRoutes,
        hours,
        viewState,
        flightToBounds,
        onViewStateChange,
        trailerInfoPanelEndDate
      );
    }
    // eslint-disable-next-line
  }, [selectedTrailer.assetId, processing, trailerInfoPanelEndDate, followTrailer]);

  useEffect(() => {
    if (followTrailer && selectedTrailer.assetId) {
      zoomFollowTrailer(viewState, historyPosition, onViewStateChange);
    }
    // eslint-disable-next-line
  }, [followTrailer, selectedTrailer.assetId, historyPosition]);

  /**
   * Component Unmount
   */
  useEffect(
    () => () => {
      document.removeEventListener('contextmenu', e => e.preventDefault());
      if (GL) {
        const extension = GL.getExtension('WEBGL_lose_context');
        if (extension) extension.loseContext();
      }
    },
    [GL]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      checkTrailersVisibleOnMap();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [viewState]);

  const onViewStateChange = useCallback(
    ({ viewState }, delay = 1500) => {
      if (!viewState?.latitude || !viewState?.longitude) {
        return;
      }

      setViewState(viewState);

      /* timeout to prevent updating the redux viewState multiple times when we zoom in/out */

      if (delay) {
        clearTimeout(mapViewStateTimeout.current);
        mapViewStateTimeout.current = setTimeout(() => dispatch(actions.appState.updateMapViewState(viewState)), delay);
      } else {
        dispatch(actions.appState.updateMapViewState(viewState));
      }
    },
    [mapViewStateTimeout, dispatch]
  );

  const flightToBounds = useCallback(
    (bounds, extraBottomPadding = false, extraPadding = false) => {
      zoomInBounds(bounds, mapRef, viewState, onViewStateChange, extraPadding, extraBottomPadding);
      // eslint-disable-next-line
    },
    [viewState, mapRef, onViewStateChange]
  );

  /**
   * Flies map to bounds after ref is valid
   */
  useEffect(() => {
    if (refVisible && !mapInitialized && trailersList && trailersList.length) {
      zoomOutAllTrailers(trailersList, [], flightToBounds);
      setMapInitialized(true);
    }
  }, [refVisible, mapInitialized, trailersList, flightToBounds, setMapInitialized]);

  const onClusterClick = devices => zoomOutAllTrailers(devices, [], flightToBounds);

  const onClickPlacesCluster = places => zoomOutAllPlaces(places, flightToBounds);

  const setReactMapRef = el => {
    if (el) {
      mapRef.current = el;
      setRefVisible(!!el);
    }
  };

  return (
    <div
      className={`trailer-map__map-wrapper ${isBoxSelectActive ? 'trailer-map__map-wrapper--selection-tool' : ''}`}
      key={mapAccessToken + '-' + mapStyleURL}
    >
      <DeckGL
        viewState={viewState}
        layers={
          mapRef.current
            ? [
                ...geoJsonLayers,
                ...trailerLayers,
                ...trailerAlertLayers,
                ...clusterLayers,
                ...selectedTrailerLayers,
                ...trailerIconLayers,
                ...layers
              ]
            : []
        }
        onViewStateChange={onViewStateChange}
        onWebGLInitialized={gl => setGL(gl)}
        controller={true}
        onHover={({ object }) => setIsHover(Boolean(object))}
        getCursor={({ isDragging }) => (isDragging ? 'grabbing' : isHover ? 'pointer' : 'grab')}
        glOptions={{
          onError: console.error
        }}
      >
        <ReactMapGL
          reuseMaps
          preventStyleDiffing
          mapboxApiAccessToken={mapAccessToken}
          mapStyle={mapStyleURL}
          onLoad={evt => setMapboxLanguage(evt, language)}
          ref={setReactMapRef}
        >
          {tooltip.object && <TrailerMapTooltip data={tooltip.object} x={tooltip.x} y={tooltip.y} />}
          {showGeoJSONLayers && placeTooltip && <TrailerMapGeoJSONTooltip data={placeTooltip} />}
        </ReactMapGL>
      </DeckGL>
      <MapToolbox
        section='livemap'
        selectionTool={isBoxSelectActive}
        onShowSelectionToolChange={setIsBoxSelectActive}
        onViewStateChange={onViewStateChange}
        viewState={viewState}
      />
      <ClearSelectionBtn active={isMapSelectionFilterActive} clearFilter={clearSelection} />
    </div>
  );
};

export default TrailerMapV2;
