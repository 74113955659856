import fileDownload from 'js-file-download';
import moment from 'moment';
import actionTypes from './actionTypes';
import { addErrorMessage } from './messages';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

function getOdrFileListRequest(assetId) {
  return {
    type: actionTypes.GET_ODR_FILE_LIST_REQUEST,
    assetId
  };
}

function getOdrFileListSuccess(odrFiles, assetId) {
  return {
    type: actionTypes.GET_ODR_FILE_LIST_SUCCESS,
    odrFiles,
    assetId
  };
}

function getOdrFileListFailure(error, assetId) {
  return {
    type: actionTypes.GET_ODR_FILE_LIST_FAILURE,
    error,
    assetId
  };
}

function downloadOdrFileRequest(assetId, fileId) {
  return {
    type: actionTypes.DOWNLOAD_ODR_FILE_REQUEST,
    assetId,
    fileId
  };
}

function downloadOdrFileSuccess(assetId, fileId) {
  return {
    type: actionTypes.DOWNLOAD_ODR_FILE_SUCCESS,
    assetId,
    fileId
  };
}

function downloadOdrFileFailure(assetId, fileId, error) {
  return {
    type: actionTypes.DOWNLOAD_ODR_FILE_FAILURE,
    assetId,
    fileId,
    error
  };
}

function getOdrFileList(assetId) {
  return dispatch => {
    dispatch(getOdrFileListRequest(assetId));
    return worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `trailers/${assetId}/odrs` })
      .then(res => {
        return dispatch(getOdrFileListSuccess(res, assetId));
      })
      .catch(err => dispatch(getOdrFileListFailure(err, assetId)));
  };
}

function downloadFile(assetId, fileId, vinNumber, timestamp) {
  return dispatch => {
    dispatch(downloadOdrFileRequest(assetId, fileId));
    return worker
      .Api({
        method: 'get',
        api: API_NAMES.ODR_LIVE_MAP,
        endpoint: `trailers/${assetId}/odrs/${encodeURIComponent(fileId)}`,
        responseType: 'text'
      })
      .then(res => {
        const dateString = moment(timestamp).format('YYYY-MM-DD--HH-mm-ss');
        fileDownload(res, `${vinNumber}_${dateString}.asc`);
        dispatch(downloadOdrFileSuccess(assetId, fileId));
      })
      .catch(err => {
        dispatch(addErrorMessage(`Error while downloading ODR File for ${assetId}`));
        dispatch(downloadOdrFileFailure(assetId, fileId, err));
      });
  };
}

export default {
  getOdrFileList,
  downloadFile
};
