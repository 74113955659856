import { getIsShowPlaces } from '../../../helpers/functions';
import {
  americaWithGeofenceNotificationTypes,
  americaNotificationTypes,
  defaultWithGeofenceNotificationTypes,
  defaulNotificationTypes
} from './constants';

export const getNotificationTypes = region => {
  const isShowGeofence = getIsShowPlaces();
  if (region === 'america') {
    return isShowGeofence ? americaWithGeofenceNotificationTypes : americaNotificationTypes;
  }
  return isShowGeofence ? defaultWithGeofenceNotificationTypes : defaulNotificationTypes;
};
