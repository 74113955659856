import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../../helpers/defaultStrings';

export const GohcInfoModal = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='large-margin-top'>
      <p>
        <b>{formatMessage(strings.phrase.gioOperatingHrsCounter)}</b>
      </p>
      <p>{formatMessage(strings.description.functionMonitorInputSignal)}</p>
      <p>{formatMessage(strings.description.hrsTillNextService)}</p>
      <p>{formatMessage(strings.description.wabcoEBSOnly)}</p>
      <p>{formatMessage(strings.description.seeDocForMoreInfo)}</p>
    </div>
  );
};
