import * as d3 from 'd3';

import { dateMultiFormat } from '../../../helpers/functions';
import BaseHistoryCharts from '../baseHistoryCharts';

export default class TrailerFCFChart extends BaseHistoryCharts {
  selectedLines = {};

  create(state) {
    super.create(state);
    this.currentDragPoint = 0;
    this.lineInfo = {};
    this.series = [];
    this.onSelectWheels = state.onSelectWheels;

    this.xAxisPosLine = this.main
      .append('g')
      .attr('class', 'xAxisPos trailer-history-axis')
      .attr('transform', `translate(0,${this.props.height})`)
      .append('line')
      .attr('x1', 0)
      .attr('y1', 1)
      .attr('y2', 1)
      .style('stroke-width', 2)
      .style('stroke', '#909090')
      .style('fill', 'none');

    this.areas = this.main.append('g').attr('class', 'areas');
    this.legendWrapper = this.svg.append('g');

    this.xAxis = this.main
      .append('g')
      .attr('class', 'xAxis trailer-history-axis')
      .attr('transform', `translate(0,${this.props.height})`);

    this.yLeftAxis = this.main
      .append('g')
      .attr('class', 'yAxis trailer-history-axis trailerHistoryYAxisLeft')
      .append('text')
      .attr('class', 'yLeftAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .attr('fill', 'dodgerblue')
      .attr('font-weight', 'bold')
      .attr('font-size', 12);

    this.yRightAxis = this.main
      .append('g')
      .attr('class', 'yAxis trailer-history-axis trailerHistoryYAxisRight')
      .attr('transform', `translate(${this.props.width}, -0)`)
      .append('text')
      .attr('class', 'yRightAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .attr('fill', 'tomato')
      .attr('font-weight', 'bold')
      .attr('font-size', 12);

    this.assetId = this.props.assetId;
    this.lastTimePos = 0;
    this.drawPin(state);
    this.update(state, true);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state, assetId) {
    if (state) {
      this.drawChart(state, assetId);
      if (!this.isDragging) {
        this.drawPin(state);
        this.updatePosition(state);
      } else {
        if (state.pos.posX < this.prevPos) {
          this.pinCircle.attr('fill', 'url(#image-rewind)');
        } else if (state.pos.posX > this.prevPos) {
          this.pinCircle.attr('fill', 'url(#image-forward)');
        }
        this.prevPos = (state && state.pos && state.pos.posX) || this.props.width;
      }
    }
  }

  drawChart(state, assetId) {
    const { series, keys, min, max } = state.chartData;
    const { fcfCustomizationSettings } = state;

    this.onPosChange = state.onPosChange;
    this.historyPosition = state.pos;
    this.view = state.view;
    this.assetId = assetId;
    this.series = series;
    this.selectedLines = state.selectedWheels || {};
    this.data = (state.data || [])
      .map(d => ({ ...d, time: new Date(d.startTime * 1000) }))
      .sort((a, b) => a.time - b.time);

    const color = d3
      .scaleOrdinal()
      .domain(series.map(d => d.key))
      .range(d3.schemeCategory10)
      .unknown('#ccc');

    this.x = d3
      .scaleTime()
      .range([0, this.props.width])
      .domain([state.startDate * 1000, state.endDate * 1000]);

    this.ySpeed = d3
      .scaleLinear()
      .range([this.props.height, 0])
      .domain([min > 0 ? min - 10 : 0, max])
      .nice();

    this.xAxis.attr('transform', `translate(0,${this.props.height})`);

    this.xAxisPosLine.attr('x2', (state.pos && state.pos.posX) || this.props.width);

    const line = d3
      .line()
      .curve(d3.curveStepAfter)
      .x(d => this.x(d.time))
      .y(d => this.ySpeed(d.value));

    const xAxis = d3
      .axisBottom(this.x)
      .tickFormat(date => dateMultiFormat(date, this.props.isUS, this.props.timeFormatLocale));

    const yLeftAxis = d3.axisLeft(this.ySpeed).ticks(5);

    const yRightAxis = d3.axisRight(this.ySpeed).ticks(5);

    const lineCurve = this.areas.selectAll('.lineCurve').data(series);

    this.svg.selectAll('.line-wheel').remove();
    const lineCurveEnter = lineCurve.enter().append('path').attr('class', 'lineCurveItem');

    lineCurveEnter
      .merge(lineCurve)
      .attr('fill', 'none')
      .attr('stroke', d => color(d.key))
      .attr('stroke-width', 2)
      .attr('d', d => line(d.data))
      .attr('class', d => 'line-wheel line-wheel-' + d.key);

    this.svg.select('.xAxis').call(xAxis);
    this.svg.select('.trailerHistoryYAxisLeft').call(yLeftAxis);
    this.svg.select('.trailerHistoryYAxisRight').call(yRightAxis);

    this.drawLegends(keys, color, fcfCustomizationSettings);
    this.drawLineInfo(series, color);
    this.checkLabelsToDisplay();

    this.updatePosition(state);
  }

  updatePosition(state) {
    const { fcfCustomizationSettings } = state;

    let currentPosition = state.pos ? state.pos.posX : 0;
    if (this.x && state.pos && state.pos.time) {
      currentPosition = this.x(state.pos.time);
    }

    state.chartData.series.forEach(serie => {
      let found = state.pos ? serie.data.filter(item => item.time <= state.pos.endTime * 1000).pop() : false;

      if (!found || !found.value) {
        this.lineInfo[serie.key].style('display', 'none');
      } else {
        let x = currentPosition;
        if (!x && found && this.x) {
          x = this.x(found.time);
        }

        let y = this.ySpeed(found ? found.value : serie.data[0].value);

        this.lineInfo[serie.key].attr('transform', 'translate(' + (x - 5) + ',' + (y - 5) + ')').style('display', null);
        this.lineInfo[serie.key].select('.title').text(found ? found.value : '--');
        this.lineInfo[serie.key].select('.value').text(`: ${fcfCustomizationSettings.options[serie.key] || serie.key}`);
        this.lineInfo[serie.key]
          .select('.bg-value')
          .attr('width', this.lineInfo[serie.key].select('.value').node().getBoundingClientRect().width);
      }
    });
  }

  drawLineInfo(series, color) {
    if (series.length > 0) {
      series.forEach(({ key }) => {
        if (!this.lineInfo[key]) {
          this.lineInfo[key] = this.svg.append('g').attr('class', 'line-info line-info-' + key);
          this.lineInfo[key].append('rect').attr('class', 'arrow').attr('width', 30).attr('height', 10);
          this.lineInfo[key]
            .append('text')
            .attr('class', 'title')
            .style('fill', '#FFF')
            .style('transform', 'translate(2px, 8px)')
            .style('font-size', '9px');
          this.lineInfo[key]
            .append('rect')
            .attr('class', 'bg-value')
            .attr('width', 50)
            .attr('height', 10)
            .style('transform', 'translate(30px, 0px)')
            .style('fill', '#FFF')
            .style('opacity', 0.5);
          this.lineInfo[key]
            .append('text')
            .attr('class', 'value')
            .style('transform', 'translate(30px, 8px)')
            .style('font-size', '9px');

          this.lineInfo[key]
            .attr('dy', '.35em')
            .attr('text-anchor', 'start')
            .style('fill', color(key))
            .style('font-size', '11px')
            .style('display', null);
        }
      });
    }
  }

  toggleWheel(key) {
    if (this.selectedLines[key]) {
      delete this.selectedLines[key];
    } else {
      this.selectedLines[key] = 1;
    }

    this.checkLabelsToDisplay();
  }

  checkLabelsToDisplay() {
    this.series.forEach(({ key }) => {
      const isVisible = this.selectedLines[key] || Object.keys(this.selectedLines).length <= 0;
      this.svg.select(`.legend-${key}`).attr('opacity', isVisible || 0.4);
      this.svg.select(`.line-info-${key}`).attr('display', !isVisible && 'none');
      this.svg.select(`.line-wheel-${key}`).attr('display', !isVisible && 'none');
    });
  }

  drawLegends(keys, color, settings) {
    this.legendWrapper.selectAll('.legend').remove();

    if (this.legendBg) this.legendBg.remove();
    this.legendBg = this.legendWrapper.append('rect');

    var legend = this.legendWrapper
      .selectAll('.legend')
      .data(keys)
      .enter()
      .append('g')
      .attr('class', key => 'legend legend-' + key)
      .attr('cursor', 'pointer')
      .on('click', this.toggleWheel.bind(this))
      .attr(
        'transform',
        (d, i) =>
          'translate(' + (-this.props.width + (i - Math.floor(i / 6) * 6) * 70) + ', ' + Math.floor(i / 6) * 15 + ')'
      );

    legend
      .append('rect')
      .attr('x', this.props.width)
      .attr('y', 6)
      .attr('width', 25)
      .attr('height', 4)
      .style('fill', color);

    legend
      .append('text')
      .attr('x', () => this.props.width + 29)
      .attr('y', 8)
      .attr('dy', '.35em')
      .style('font-size', 11)
      .text(d => settings.options[d] || d);

    this.legendBg
      .style('width', this.legendWrapper.node().getBoundingClientRect().width + 20)
      .style('height', 15)
      .style('fill', '#FFF')
      .attr('transform', 'translate(0, 0)');

    this.legendWrapper.attr(
      'transform',
      `translate(${Math.abs(this.props.width - this.legendWrapper.node().getBoundingClientRect().width)},-${
        this.props.margin.top
      })`
    );
  }
}
