import * as d3 from 'd3';
import Chart from '../chart';

import './ScoreChart.scss';

export default class EventSummaryChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg
      .append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.xAxis = this.main.append('g').attr('class', 'xAxis').attr('transform', `translate(0,${this.props.height})`);

    this.yAxis = this.main.append('g').attr('class', 'yAxis stacked-area-y-axis');

    this.score = this.main.append('g').attr('class', 'score');

    this.distribution = this.main.append('g').attr('class', 'distribution');
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.domain) {
      this.drawChart(state);
    }
  }

  drawChart(state) {
    const { criticalScoreFunction, warningScoreFunction, domain, xLabel } = state;
    const x = d3.scaleLinear().range([0, this.props.width]).domain(domain);

    const y = d3.scaleLinear().range([0, this.props.height]).domain([10, 0]);

    const criticalLine = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x(d => x(d))
      .y(d => y(criticalScoreFunction(d)));

    const warningLine = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x(d => x(d))
      .y(d => y(warningScoreFunction(d)));

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    yAxis
      .tickSizeOuter(0)
      .tickSizeInner(-this.props.width)
      .tickSize(-this.props.width)
      .tickValues([2, 4, 6, 8, 10])
      .ticks(5);

    const data = Array(domain[1] - domain[0] + 1)
      .fill(1)
      .map((_, i) => i);

    this.score
      .append('path')
      .attr('class', 'pathWarning')
      .style('fill', 'none')
      .style('stroke', '#ffc107')
      .style('stroke-width', 3)
      .style('shape-rendering', 'optimizeQuality')
      .attr('d', () => warningLine(data));

    this.score
      .append('path')
      .attr('class', 'pathCritical')
      .style('fill', 'none')
      .style('stroke', 'tomato')
      .style('stroke-width', 3)
      .style('shape-rendering', 'optimizeQuality')
      .attr('d', () => criticalLine(data));

    this.svg.select('.xAxis').call(xAxis);
    this.svg.select('.yAxis').call(yAxis);

    this.xAxis
      .append('text')
      .attr('y', 0)
      .attr('x', this.props.width)
      .attr('dy', 40)
      .attr('fill', 'grey')
      .style('text-anchor', 'end')
      .text(xLabel);

    if (state.criticalValues && state.criticalValues.length > 0) {
      const dist = this.distribution.selectAll('.critical').data(state.criticalValues.filter(d => d < domain[1]));

      dist
        .enter()
        .append('circle')
        .attr('class', 'critical')
        .attr('cx', d => x(d))
        .attr('cy', this.props.height + 22)
        .attr('r', 2)
        .style('fill', 'tomato')
        .style('opacity', 0.8);
    }
    if (state.warningValues && state.warningValues.length > 0) {
      const dist = this.distribution.selectAll('.warning').data(state.warningValues.filter(d => d < domain[1]));

      dist
        .enter()
        .append('circle')
        .attr('class', 'warning')
        .attr('cx', d => x(d))
        .attr('cy', this.props.height + 26)
        .attr('r', 2)
        .style('fill', '#ffc107')
        .style('opacity', 0.8);
    }
  }
}
