import { Badge } from 'antd';
import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const getHealthScore = data => {
  if (data.healthScore != null) {
    return parseFloat(data.healthScore).toFixed(1);
  }
  return '';
};

const Cell = ({ rowData }) => {
  const healthScore = getHealthScore(rowData);

  if (healthScore !== '') {
    return <Badge dot={rowData.healthScoreType === 'mileageBased'}>{healthScore}</Badge>;
  }

  return <NoValueSpan />;
};

const HealthScoreColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.healthScore)}>{formatMessage(strings.columns.healthScore)}</Tooltip>
    ),
    key: 'healthScore',
    dataIndex: 'healthScore',
    searchKey: formatMessage(strings.columns.healthScore),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () =>
      trailers.sort('healthScore', (a, b) => (a.healthScore == null || a.healthScore < b.healthScore ? -1 : 1)),
    width: 120
  };
};

const HealthScoreCSVColumnHeader = formatMessage => formatMessage(strings.columns.healthScore);
const HealthScoreCSVData = trailer => getHealthScore(trailer);
const HealthScoreCSVColumn = {
  header: HealthScoreCSVColumnHeader,
  data: HealthScoreCSVData
};

export default { table: HealthScoreColumn, csv: HealthScoreCSVColumn };
