import React from 'react';
import { useIntl } from 'react-intl';
import { Avatar } from 'antd';
import OdyNavButton from '../../ReusableComponents/Buttons/OdyNavButton/OdyNavButton';
import OdyRouteNavButton from '../../ReusableComponents/Buttons/OdyNavButton/OdyRouteNavButton';
import './AppSwitcherOptions.scss';
import * as strings from '../../helpers/defaultStrings';
import IconAdmin from '../Icons/IconAdmin';
import IconTrailer from '../Icons/IconTrailer';
import { ENV_CONFIG } from '../../app/helpers/env-configs';

const AppSwitcherOptions = ({ closePopover, avatarSrc }) => {
  const { formatMessage } = useIntl();

  return (
    <div className='app-sw-options'>
      <OdyRouteNavButton icon={<IconTrailer />} to='/' text='TrailerFit' onClick={closePopover} />
      <OdyNavButton
        onClick={closePopover}
        icon={<IconAdmin className={'admin-icon'} />}
        link={ENV_CONFIG.REACT_APP_ODYSSEY_ADMIN_DOMAIN}
        text={formatMessage(strings.short.admin)}
      />
      <OdyNavButton
        onClick={closePopover}
        button={<Avatar src={avatarSrc} size='large' />}
        link={ENV_CONFIG.REACT_APP_ODYSSEY_MY_ACCOUNT}
        text={formatMessage(strings.short.account)}
      />
    </div>
  );
};

export default AppSwitcherOptions;
