import { defineMessages } from 'react-intl';

export const abbrev = defineMessages({
  centimeter: {
    id: 'abbrev.centimeter',
    defaultMessage: 'cm',
    description: 'abbreviation of unit of length centimeters'
  },
  millimeter: {
    id: 'abbrev.millimeter',
    defaultMessage: 'mm',
    description: 'abbreviation of unit of length millimeter'
  },
  inch: {
    id: 'abbrev.inch',
    defaultMessage: 'in',
    description: 'abbreviation of unit of length inch'
  },
  identification: {
    id: 'abbrev.identification',
    defaultMessage: 'ID',
    description: 'abbreviation of identification'
  },
  internationalMobileEquipmentIdentity: {
    id: 'abbrev.internationalMobileEquipmentIdentity',
    defaultMessage: 'IMEI',
    description: 'abbreviation of international Mobile Equipment Identity'
  },
  kiloBits: {
    id: 'abbrev.kiloBits',
    defaultMessage: 'Kb',
    description: 'abbreviation of kilo bytes'
  },
  kilometer: {
    id: 'abbrev.kilometer',
    defaultMessage: 'km',
    description: 'abbreviation of unit of kilometer'
  },
  kilometerPerHour: {
    id: 'abbrev.kilometerPerHour',
    defaultMessage: 'km/h',
    description: 'abbreviation of unit of speed kilometers per hour'
  },
  kilogram: {
    id: 'abbrev.kilogram',
    defaultMessage: 'kg',
    description: 'abbreviation of unit of weight kilogram'
  },
  pound: {
    id: 'abbrev.pound',
    defaultMessage: 'lbs',
    description: 'abbreviation of unit of weigfht pounds'
  },
  ton: {
    id: 'abbrev.ton',
    defaultMessage: 't',
    description: 'abbreviation of unit of weight ton'
  },
  kiloPoundUSA: {
    id: 'abbrev.kiloPoundUSA',
    defaultMessage: 'k',
    description: 'appreviation of the american unit of weight kilopound, 1000 pounds'
  },
  meter: {
    id: 'abbrev.meter',
    defaultMessage: 'm',
    description: 'abbreviation of unit of length meter'
  },
  feet: {
    id: 'abbrev.feet',
    defaultMessage: 'ft',
    description: 'abbreviation of unit of length feet'
  },
  second: {
    id: 'abbrev.second',
    defaultMessage: 's',
    description: 'abbreviation of unit of time second'
  },
  celsius: {
    id: 'abbrev.celsius',
    defaultMessage: '°C',
    description: 'abbreviation of unit of temperature degrees celsius'
  },
  fahrenheit: {
    id: 'abbrev.fahrenheit',
    defaultMessage: '°F',
    description: 'abbreviation of unit of temperature fahrenheit'
  },
  vehicleIdentificationNumber: {
    id: 'abbrev.vehicleIdentificationNumber',
    defaultMessage: 'VIN',
    description: 'abbreviation of Vehicle Identification Number'
  },
  volt: {
    id: 'abbrev.volt',
    defaultMessage: 'V',
    description: 'abbreviation of unit of voltage volt'
  },
  mile: {
    id: 'abbrev.mile',
    defaultMessage: 'mi',
    description: 'abbreviation of unit of length mile'
  },
  milePerHour: {
    id: 'abbrev.milePerHour',
    defaultMessage: 'mph',
    description: 'abbreviation of unit of speed miles per hour'
  },
  poundPerSquareInch: {
    id: 'abbrev.poundPerSquareInch',
    defaultMessage: 'psi',
    description: 'abbreviation of unit of pressure pounds per square inch'
  },
  bar: {
    id: 'abbrev.bar',
    defaultMessage: 'bar',
    description: 'abbreviation of metric unit of pressure bar'
  }
});
