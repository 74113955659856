import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconColumnSettings } from '../../../../../Icons';
import * as strings from '../../../../../../helpers/defaultStrings';
import { useDispatch, useSelector } from 'react-redux';
import ColumnSelection from '../../../../../Utils/ColumnSelection';
import { TableColumns } from '../../Columns/AllColumns';
import actions from '../../../../../../actions';
import OdyButton from '../../../../../../ReusableComponents/Buttons/OdyButton/OdyButton';
import { getGroupColumnsTable } from '../../Columns/GroupColumns/getGroupColumns';
import {
  selectTrailerLiveMapColumns,
  selectColumnsLockedliveMap,
  selectColumnsWidthsliveMap
} from '../../../../../../selectors/';
import { isUserTIP } from '../../../../../../helpers/functions';

function ColumnSelectionBtn({ trailers }) {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const selectedColumnKeys = useSelector(selectTrailerLiveMapColumns);
  const columnsLockedliveMap = useSelector(selectColumnsLockedliveMap);
  const columnsWidthsliveMap = useSelector(selectColumnsWidthsliveMap);
  const groups = useSelector(store => store.groups.groups);
  const dispatch = useDispatch();

  // need to pass a mock trailers obj to TableColumns to access default column settings
  const mockColumnProps = {
    trailers: {
      unfilteredData: [],
      sort: () => {},
      filter: () => {},
      removeFilter: () => {}
    },
    devices: [],
    isMetric: true,
    region: 'default',
    formatMessage
  };

  const user = useSelector(store => store.auth.user);
  const isTIP = isUserTIP(user);

  const columns = [];
  for (const key in TableColumns) {
    const column = TableColumns[key](mockColumnProps, isTIP);
    if (column) {
      if (columnsWidthsliveMap && key in columnsWidthsliveMap) {
        column.width = columnsWidthsliveMap[key];
      }
      columns.push(column);
    }
  }

  const groupsColumns = getGroupColumnsTable(trailers, groups);

  for (const key in groupsColumns) {
    const column = groupsColumns[key];
    if (columnsWidthsliveMap && key in columnsWidthsliveMap) {
      column.width = columnsWidthsliveMap[key];
    }
    columns.push(column);
  }

  const tableId = 'liveMap';
  const columnsLocked = columnsLockedliveMap;
  const saveSettingsGroup = data => {
    dispatch(actions.settings.saveSettingGroup(data));
  };

  const onClickBtn = () => {
    setIsOpen(true);
  };

  return (
    <>
      <OdyButton icon={<IconColumnSettings />} onClick={onClickBtn}>
        {formatMessage(strings.short.columns)}
      </OdyButton>
      {isOpen && (
        <ColumnSelection
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          tableId={tableId}
          columns={columns}
          selectedColumnKeys={selectedColumnKeys}
          columnsLocked={columnsLocked}
          saveSettingsGroup={saveSettingsGroup}
        />
      )}
    </>
  );
}

export default React.memo(ColumnSelectionBtn);
