import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import moment from 'moment';
import { Tooltip } from 'antd';
import drivingActivityImg from '../../../../../../assets/images/driving.png';
import parkedActivityImg from '../../../../../../assets/images/parking.png';
import pausedActivityImg from '../../../../../../assets/images/pause.png';
import { triggerGenericEvent } from '../../../../../../helpers/googleAnalytics';
import { selectionHeaders } from '../../../../../../helpers/constants';

const getActivityIcon = status => {
  if (status === 'driving') {
    return <img src={drivingActivityImg} alt='driving' style={{ width: 20 }} />;
  }
  if (status === 'parked') {
    return <img src={parkedActivityImg} alt='parked' style={{ width: 20 }} />;
  }
  if (status === 'paused') {
    return <img src={pausedActivityImg} alt='paused' style={{ width: 20 }} />;
  }
  return null;
};

const Cell = ({ rowData, formatMessage }) => {
  const { activityStatus } = rowData;
  if (activityStatus) {
    return (
      <Tooltip placement='left' title={activityStatus ? formatMessage(strings.short[activityStatus.status]) : null}>
        {activityStatus && getActivityIcon(activityStatus.status)}{' '}
        {activityStatus && activityStatus.since
          ? moment.duration(moment.utc().diff(activityStatus.since, 'seconds'), 'seconds').humanize()
          : formatMessage(strings.short.unknown)}
      </Tooltip>
    );
  }
  return <NoValueSpan />;
};

const ActivityColumn = ({ trailers, formatMessage }) => {
  let lastFilterTime = 0;

  const filterFunction = checkedCategories =>
    trailers.filter('activityStatus', t => {
      if (Date.now() - lastFilterTime > 1500) {
        triggerGenericEvent('Filter', 'Filter', 'activity');
        lastFilterTime = Date.now();
      }

      if (!t.activityStatus?.status) {
        return checkedCategories['unknown'];
      }

      return checkedCategories[t.activityStatus.status];
    });

  const categories = [
    { text: formatMessage(strings.short.driving), value: 'driving' },
    { text: formatMessage(strings.short.paused), value: 'paused' },
    { text: formatMessage(strings.short.parked), value: 'parked' }
  ];

  return {
    title: <Tooltip title={formatMessage(strings.short.activity)}>{formatMessage(strings.short.activity)}</Tooltip>,
    key: 'activity',
    dataIndex: 'activityStatus',
    searchKey: formatMessage(strings.short.activity),
    selectionSection: selectionHeaders.generalMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    categories,
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('activityStatus'),
    width: 150
  };
};

export default { table: ActivityColumn, csv: null };
