import React, { useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import DeckGL from '@deck.gl/react';
import ReactMapGL from 'react-map-gl';
import { setMapboxLanguage } from '../../helpers/functions';
import {
  getSelectedTrailerLayers,
  getTrailerAlertsLayers,
  getTrailersIconLayers,
  getTrailersLayers
} from '../../helpers/livemapLayers';
import { useSelector } from 'react-redux';
import { zoomInBounds, zoomInTrailerRoute } from '../../helpers/livemapZoom';

import './AlertMapContext.scss';
import { getAlertsGeofenceLayers } from '../../helpers/placesLayers';
import { getIsTypeGeofence } from '../../helpers/alarms/functions';
import { selectRegion, selectMapAccessToken, selectMapStyleURL, selectProductFeatures } from '../../selectors';

const AlertMapContext = ({ trailer, trailerHistory, pos, followTrailer, alerts }) => {
  const region = useSelector(selectRegion);
  const language = useSelector(store => store.language.language);
  const mapAccessToken = useSelector(selectMapAccessToken);
  const mapStyleURL = useSelector(selectMapStyleURL);
  const productFeatures = useSelector(selectProductFeatures);

  const mapRef = useRef(null);

  /** Trailer Dashed trip line **/
  const [selectedTrailerLayers, setSelectedTrailerLayers] = useState([]);
  /** Trailers Trip blue line **/
  const [selectedTrailerTripLayers, setSelectedTrailerTripLayers] = useState([]);
  /** Trailers Icons **/
  const [trailerIconLayers, setTrailerIconLayers] = useState([]);
  /** Trailer Alerts **/
  const [trailerAlertLayers, setTrailerAlertLayers] = useState([]);
  const [alertGeofenceLayers, setAlertGeofenceLayers] = useState([]);
  /** Map View State **/
  const [viewState, setViewState] = useState({ longitude: 3.736, latitude: 47.136, zoom: 3, pitch: 0, bearing: 0 });

  const [mapRoutes, setMapRoutes] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onViewStateChange = useCallback(({ viewState }) => {
    setViewState(viewState);
  }, []);

  useEffect(() => {
    if (trailer) {
      setMapRoutes({
        [trailer.assetId]: trailerHistory?.length > 0 ? trailerHistory : []
      });
      setEndDate(trailerHistory?.length > 0 && trailerHistory[trailerHistory.length - 1].endTime);
    }
  }, [trailer, trailerHistory]);

  useEffect(() => {
    if (trailer && trailer.assetId && mapRoutes) {
      setSelectedTrailerLayers(getSelectedTrailerLayers(trailer, mapRoutes, 6, mapRef, endDate, false, true));
    }
    // eslint-disable-next-line
  }, [trailer, mapRoutes]);

  useEffect(() => {
    if (trailer && trailer.assetId && mapRoutes) {
      setSelectedTrailerTripLayers(
        getTrailersLayers([trailer], mapRoutes, null, 6, trailer, mapRef, pos, () => {}, endDate)
      );
      setTrailerIconLayers(
        getTrailersIconLayers(
          [trailer],
          mapRoutes,
          trailer,
          pos,
          () => {},
          () => {},
          6,
          endDate
        )
      );
      if (alerts && alerts.length > 0) {
        setTrailerAlertLayers(
          getTrailerAlertsLayers([trailer], alerts, trailer, mapRoutes, 6, () => {}, region, endDate, productFeatures)
        );
      }
    }
    // eslint-disable-next-line
  }, [trailer, mapRoutes, pos]);

  useEffect(() => {
    if (alerts.length > 0 && alerts.some(alert => getIsTypeGeofence(alert))) {
      setAlertGeofenceLayers(getAlertsGeofenceLayers(alerts));
    }
  }, [alerts]);

  useEffect(() => {
    if (trailer && trailer.assetId && !followTrailer && mapRoutes !== null && mapRoutes[trailer.assetId]?.length > 0) {
      zoomInTrailerRoute(trailer, mapRoutes, 6, viewState, flightToBounds, onViewStateChange, endDate);
    }
    // eslint-disable-next-line
  }, [trailer && trailer.assetId, mapRoutes, followTrailer]);

  const flightToBounds = useCallback(
    (bounds, extraBottomPadding = false, extraPadding = false) => {
      zoomInBounds(bounds, mapRef, viewState, onViewStateChange, extraPadding, extraBottomPadding);
      // eslint-disable-next-line
    },
    [viewState, mapRef, onViewStateChange]
  );

  return (
    <div className={'alert-map-context'} key={mapAccessToken + '-' + mapStyleURL}>
      <DeckGL
        viewState={viewState}
        layers={[
          ...selectedTrailerTripLayers,
          ...selectedTrailerLayers,
          ...trailerIconLayers,
          ...trailerAlertLayers,
          ...alertGeofenceLayers
        ]}
        onViewStateChange={onViewStateChange}
        controller={true}
      >
        <ReactMapGL
          reuseMaps
          preventStyleDiffing
          mapboxApiAccessToken={mapAccessToken}
          mapStyle={mapStyleURL}
          onLoad={evt => setMapboxLanguage(evt, language)}
          ref={el => el && (mapRef.current = el)}
        ></ReactMapGL>
      </DeckGL>
    </div>
  );
};

export default injectIntl(AlertMapContext);
