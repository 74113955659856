import { maxBy, minBy, sortBy } from '../../helpers/arrays';
import moment from 'moment';

/**
 * RECENCY SCORE
 */

const recencyCriticalScoreFunction = daysSinceLastEvent => {
  if (daysSinceLastEvent === 0 || daysSinceLastEvent > 0) {
    return 10 * (1 / Math.sqrt(daysSinceLastEvent + 1));
  }
  return 0;
};

const recencyWarningScoreFunction = daysSinceLastEvent => {
  if (daysSinceLastEvent === 0 || daysSinceLastEvent > 0) {
    return 3 * (1 / Math.sqrt(daysSinceLastEvent + 1));
  }
  return 0;
};

const getDaysSinceLastEvent = (events, lastKnownDay, priority) => {
  const lastEvent = maxBy(
    events.filter(e => e.priority === priority),
    e => e.date
  );
  const lastEventDate = lastEvent ? moment.utc(lastEvent.date.toISOString().substring(0, 10)) : null;
  return lastEvent ? (lastKnownDay || moment.utc()).diff(lastEventDate, 'days') : null;
};

const computeRecencyScore = (events, lastKnownDay) => {
  const daysSinceLastCriticalEvent = getDaysSinceLastEvent(events, lastKnownDay, 2);
  const daysSinceLastWarningEvent = getDaysSinceLastEvent(events, lastKnownDay, 1);
  return Math.max(
    recencyCriticalScoreFunction(daysSinceLastCriticalEvent),
    recencyWarningScoreFunction(daysSinceLastWarningEvent)
  );
};

const getMileageBucketsSinceLastEvent = (events, lastKnownMilage, priority) => {
  const lastEvent = maxBy(
    events.filter(e => e.priority === priority),
    e => e.mileage
  );
  return lastEvent ? lastKnownMilage - lastEvent.mileage : null;
};

const computeRecencyScoreMileage = (events, lastKnownMilage) => {
  const mileageBucketsSinceLastCriticalEvent = getMileageBucketsSinceLastEvent(events, lastKnownMilage, 2);
  const mileageBucketsSinceLastWarningEvent = getMileageBucketsSinceLastEvent(events, lastKnownMilage, 1);
  return Math.max(
    recencyCriticalScoreFunction(mileageBucketsSinceLastCriticalEvent),
    recencyWarningScoreFunction(mileageBucketsSinceLastWarningEvent)
  );
};

/**
 * DURATION SCORE
 */

const durationCriticalScoreFunction = criticalAvgSequenceDuration => {
  return Math.max(0, Math.min(10, 2 ** (criticalAvgSequenceDuration - 1) - 1));
};

const durationWarningScoreFunction = warningAvgSequenceDuration => {
  return Math.max(0, Math.min(10, 2 ** (warningAvgSequenceDuration - 2) - 1));
};

const getNumberOfActivityDays = (events, start, stop) => {
  if (!events || events.length === 0) return 0;
  const firstEvents = start || moment.utc(minBy(events, e => e.date).date);
  const lastEvents = stop || moment.utc(maxBy(events, e => e.date).date);
  return lastEvents.diff(firstEvents, 'days') + 1;
};

const getNumberOfEvents = (events, priority) => {
  return events.filter(e => e.priority === priority).length;
};

const getFractionOfTimeInState = (events, priority) => {
  const dayInCount = getNumberOfEvents(events, priority);
  const activityDayCount = getNumberOfActivityDays(events);
  return dayInCount / activityDayCount;
};

const getSequenceCount = (events, priority) => {
  let sequenceCount = 0;
  let currentEvent = null;
  const sortEvents = sortBy(events, e => e.date);
  sortEvents.forEach(e => {
    if (
      currentEvent &&
      (currentEvent.priority !== e.priority || moment.utc(e.date).diff(moment.utc(currentEvent.date), 'days') > 1)
    ) {
      // New sequence
      if (currentEvent.priority === priority) {
        sequenceCount += 1;
      }
    }
    if (!currentEvent) {
      sequenceCount = 1;
    }
    currentEvent = e;
  });

  return sequenceCount;
};

const getAverageSequenceDuration = (events, priority) => {
  const sequenceCount = getSequenceCount(events, priority);
  const sequenceTotalDuration = getNumberOfEvents(events, priority);
  return sequenceCount ? sequenceTotalDuration / sequenceCount : null;
};

const computeDurationScore = events => {
  const criticalAvgSequenceDuration = getAverageSequenceDuration(events, 2);
  const warningAvgSequenceDuration = getAverageSequenceDuration(events, 2);

  return Math.min(
    10,
    durationCriticalScoreFunction(criticalAvgSequenceDuration) +
      durationWarningScoreFunction(warningAvgSequenceDuration)
  );
};

const getSequenceCountMileage = (events, priority) => {
  let sequenceCount = 0;
  let currentEvent = null;
  const sortEvents = sortBy(events, e => e.date);
  sortEvents.forEach(e => {
    if (currentEvent && (currentEvent.priority !== e.priority || e.mileage - currentEvent.mileage <= 100)) {
      // New sequence
      if (currentEvent.priority === priority) {
        sequenceCount += 1;
      }
    }
    if (!currentEvent) {
      sequenceCount = 1;
    }
    currentEvent = e;
  });

  return sequenceCount;
};

const getAverageSequenceDurationMileage = (events, priority) => {
  const sequenceCount = getSequenceCountMileage(events, priority);
  const sequenceTotalDuration = getNumberOfEvents(events, priority);
  return sequenceCount ? sequenceTotalDuration / sequenceCount : null;
};

const computeDurationScoreMileage = events => {
  const criticalAvgSequenceDuration = getAverageSequenceDurationMileage(events, 2);
  const warningAvgSequenceDuration = getAverageSequenceDurationMileage(events, 2);

  return Math.min(
    10,
    durationCriticalScoreFunction(criticalAvgSequenceDuration) +
      durationWarningScoreFunction(warningAvgSequenceDuration)
  );
};

/**
 * FREQUENCY SCORE
 */

const frequencyCriticalScoreFunction = averageNumberOfDays => {
  if (averageNumberOfDays) {
    return 10 * (1 / averageNumberOfDays ** (1 / 3));
  }
  return 10;
};

const frequencyWarningScoreFunction = averageNumberOfDays => {
  if (averageNumberOfDays) {
    return 3 * (1 / averageNumberOfDays ** (1 / 3));
  }
  return 3;
};

const getAverageDaysBetweenEvents = (events, start, stop, priority) => {
  const sequenceCount = getSequenceCount(events, priority);
  const activityDayCount = getNumberOfActivityDays(events, start, stop);
  return sequenceCount ? activityDayCount / sequenceCount : +Infinity;
};

const computeFrequencyScore = (events, start, stop) => {
  const daysBetweenCriticalEvents = getAverageDaysBetweenEvents(events, start, stop, 2);
  const daysBetweenWarningEvents = getAverageDaysBetweenEvents(events, start, stop, 1);

  return Math.min(
    10,
    frequencyCriticalScoreFunction(daysBetweenCriticalEvents) + frequencyWarningScoreFunction(daysBetweenWarningEvents)
  );
};

const getNumberOfActivityMileageBuckets = (events, start, stop) => {
  if (!events || events.length === 0) return 0;
  const firstEvents = start || minBy(events, e => e.mileage).mileage;
  const lastEvents = stop || maxBy(events, e => e.mileage).mileage;
  return (lastEvents - firstEvents) / 100;
};

const getAverageMileageBucketsBetweenEvents = (events, start, stop, priority) => {
  const sequenceCount = getSequenceCountMileage(events, priority);
  const activityBucketCount = getNumberOfActivityMileageBuckets(events, start, stop);
  return sequenceCount ? activityBucketCount / sequenceCount : +Infinity;
};

const computeFrequencyScoreMileage = (events, start, stop) => {
  const mileageBucketsBetweenCriticalEvents = getAverageMileageBucketsBetweenEvents(events, start, stop, 2);
  const mileageBucketsBetweenWarningEvents = getAverageMileageBucketsBetweenEvents(events, start, stop, 1);

  return Math.min(
    10,
    frequencyCriticalScoreFunction(mileageBucketsBetweenCriticalEvents) +
      frequencyWarningScoreFunction(mileageBucketsBetweenWarningEvents)
  );
};

/*
 *  HEALTH SCORE
 */

const computeHealthScore = (events, eventDomain, odoEvents, odoEventDomain) => {
  if (!events || events.length === 0 || !eventDomain || !odoEventDomain) {
    return {
      healthScoreType: 'timeBased',
      healthScore: 0,
      recencyScore: 0,
      durationScore: 0,
      frequencyScore: 0
    };
  }
  const start = moment.utc(eventDomain[0]);
  const end = moment.utc(eventDomain[1]);
  let recencyScore;
  let durationScore;
  let frequencyScore;
  let healthScoreType;
  if (end.diff(start, 'days') > 31) {
    healthScoreType = 'timeBased';
    recencyScore = computeRecencyScore(events, end);
    durationScore = computeDurationScore(events);
    frequencyScore = computeFrequencyScore(events, start, end);
  } else {
    healthScoreType = 'mileageBased';
    recencyScore = computeRecencyScoreMileage(odoEvents, odoEventDomain[1]);
    durationScore = computeDurationScoreMileage(odoEvents);
    frequencyScore = computeFrequencyScoreMileage(odoEvents, odoEventDomain[0], odoEventDomain[1]);
  }

  const healthScore = (1 / 3) * recencyScore + (1 / 3) * durationScore + (1 / 3) * frequencyScore;
  return {
    healthScoreType,
    healthScore,
    recencyScore,
    durationScore,
    frequencyScore
  };
};

export default {
  computeHealthScore,

  // Use for charts
  recencyCriticalScoreFunction,
  recencyWarningScoreFunction,
  durationCriticalScoreFunction,
  durationWarningScoreFunction,
  frequencyCriticalScoreFunction,
  frequencyWarningScoreFunction,

  // Use for analysis
  getNumberOfActivityDays,
  getFractionOfTimeInState,
  getDaysSinceLastEvent,
  getSequenceCount,
  getAverageSequenceDuration,
  getAverageDaysBetweenEvents
};
