import React from 'react';
import './HelpContainer.scss';
import { ENV_CONFIG } from '../../app/helpers/env-configs';

const { REACT_APP_HELP_URL } = ENV_CONFIG;

const HelpContainer = () => (
  <div className='iframe-container'>
    <iframe src={REACT_APP_HELP_URL} width='100%' height='100%' title='Help'></iframe>
  </div>
);

HelpContainer.propTypes = {};

export default HelpContainer;
