import store from '../../../app/configureStore.js';
import moment from 'moment';
import { loadingEventsOptions } from '../../../helpers/constants';

export const transformMonthlyAndAssets = (items, bins = 10) => {
  const initData = {
    loadedKm: 0,
    unloadedKm: 0,
    tripsLoaded: 0,
    tripsUnloaded: 0,
    distance0t: 0,
    distance10t: 0,
    distance20t: 0,
    distance30t: 0,
    distance40t: 0
  };

  const loadsGroupedByAsset = {};

  let processedData = [];

  const trailers = store.getState().trailerDetails.trailersDefaultDisplayNames;
  items
    .sort((a, b) => a.time - b.time)
    .forEach(item => {
      let date = new Date(item.time * 1000);
      let day = date.getDay();
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2);
      let week = moment(date).format('w');
      let weekLabel =
        moment(date).startOf('week').format('DD') +
        '-' +
        moment(date).endOf('week').format('DD') +
        '/' +
        moment(date).endOf('week').format('MM');
      let label = moment(date).format('MMMM YYYY');
      let yearMonth = moment(date).format('YYYYMM');
      let dayMonth = moment(date).format('DD/MM');

      let newData;
      if (typeof loadsGroupedByAsset[item.assetId] === 'undefined') {
        newData = {
          assetId: item.assetId,
          trailerName: trailers[item.assetId] || item.assetId,
          ...initData,
          monthly: {}
        };
      } else {
        newData = loadsGroupedByAsset[item.assetId];
        let previousEventOdometer = newData.previousEvent.odometer;
        let currentOdometer = item.odometer;
        let roadedKm = currentOdometer - previousEventOdometer;

        let previousDate = new Date(newData.previousEvent.time * 1000);
        let currentDate = new Date(item.time * 1000);
        let diff = Math.abs(currentDate.getTime() - previousDate.getTime());
        let minutes = Math.round(diff / 60000);

        let duration = moment.duration(moment(currentDate).diff(moment(previousDate)));
        let dateDiff = `${duration.hours()}h${duration.minutes()}m`;

        if (duration.days() > 0) dateDiff = `${duration.days()}d ${dateDiff}`;

        let previousAxleLoad = newData.previousEvent.axleload;
        let previousAxleLoadTons = Math.floor(previousAxleLoad / (1000 * bins)) * bins;
        if (previousAxleLoadTons >= loadingEventsOptions.maxTons) {
          previousAxleLoadTons = loadingEventsOptions.maxTons - bins;
        }

        if (roadedKm > 2 && roadedKm < 5000) {
          if (item.eventType === loadingEventsOptions.UNLOADING) {
            newData.tripsLoaded++;
            newData.loadedKm += roadedKm;
          } else if (item.eventType === loadingEventsOptions.LOADING) {
            newData.tripsUnloaded++;
            newData.unloadedKm += roadedKm;
          }

          let loadDiff = Math.round(item.axleLoadDiff / 1000) * 1000;
          processedData.push({
            time: item.time,
            startTime: newData.previousEvent.time,
            year,
            month,
            day,
            dayMonth,
            yearMonth,
            week,
            weekLabel,
            axleLoadTons: previousAxleLoadTons,
            axleLoad: item.axleload,
            axleLoadDiff: item.axleLoadDiff,
            odometerEnd: newData.odometer,
            odometerStart: newData.previousEvent.odometer,
            loadDiff,
            dateDiff,
            duration: minutes,
            yearMonthLabel: label,
            assetId: item.assetId,
            eventType: item.eventType,
            loadedKm: item.eventType === loadingEventsOptions.LOADING ? roadedKm : 0,
            unloadedKm: item.eventType === loadingEventsOptions.UNLOADING ? roadedKm : 0,
            roadedKm,
            tripsLoaded: item.eventType === loadingEventsOptions.LOADING ? 1 : 0,
            tripsUnloaded: item.eventType === loadingEventsOptions.UNLOADING ? 1 : 0,
            from: {
              coordinates: [newData.previousEvent.lon, newData.previousEvent.lat]
            },
            to: {
              coordinates: [item.lon, item.lat]
            }
          });
        }
      }
      newData.previousEvent = item;

      loadsGroupedByAsset[item.assetId] = newData;
    });

  return {
    assets: Object.values(loadsGroupedByAsset),
    processedData: processedData.sort((a, b) => a.yearMonth - b.yearMonth)
  };
};

export const transformGroupByDays = items => {
  let groupedItems = {};
  items
    .sort((a, b) => a.time - b.time)
    .forEach(item => {
      let newData;
      if (typeof groupedItems[item.assetId] === 'undefined') {
        newData = {
          days: {}
        };
      } else {
        newData = groupedItems[item.assetId];
        let previousEventOdometer = newData.previousEvent.odometer;
        let currentOdometer = item.odometer;
        let roadedKm = currentOdometer - previousEventOdometer;

        let previousDate = new Date(newData.previousEvent.time * 1000);
        let currentDate = new Date(item.time * 1000);
        let diff = Math.abs(currentDate.getTime() - previousDate.getTime());
        let minutes = Math.round(diff / 60000);

        let duration = moment.duration(moment(currentDate).diff(moment(previousDate)));
        let dateDiff = `${duration.hours()}h${duration.minutes()}m`;

        if (duration.days() > 0) dateDiff = `${duration.days()}d ${dateDiff}`;

        newData.days[previousDate.getDate()] = newData.days[previousDate.getDate()] || [];
        newData.days[previousDate.getDate()].push({
          roadedKm,
          date: previousDate.getDate(),
          month: previousDate.getMonth(),
          hour: previousDate.getHours(),
          dateDiff,
          duration: minutes,
          ...newData.previousEvent
        });
      }
      newData.previousEvent = item;
      groupedItems[item.assetId] = newData;
    });

  return groupedItems;
};
