export const getOverwriteTrailerInfoPanelSettings = selectedTnTTrailer => {
  const isTPMSActive = selectedTnTTrailer?.metadata?.tpms_active;
  return {
    historyTPMSPressure: !isTPMSActive,
    historyTPMSTemperature: !(isTPMSActive && selectedTnTTrailer?.metadata?.tpms_tempSupport),
    wheelLayout: !(isTPMSActive && !selectedTnTTrailer?.metadata?.tpms_autodetect),
    trailerDetails: false,
    lightMonitoring: !selectedTnTTrailer?.lightMonitoring?.type
  };
};
