import React from 'react';
import { Checkbox, Button } from 'antd';
import * as strings from '../../../../helpers/defaultStrings';
import searchColumn from '../shared/searchColumn';
import { getDaNotifData } from '../../../../helpers/alarms/functions';
import { useIntl } from 'react-intl';
import { GroteGuardianDisplayString } from '../../../../helpers/constants';
import { isUsaCustomer } from '../../../../helpers/functions';
import { alarmCategories } from '../../../../helpers/alarms/constants';

export const getNotifRowData = (notificationRows, region, formatMessage, productFeatures) => {
  const notificationData = getDaNotifData(formatMessage, region, productFeatures);
  let notificationList = [];

  notificationRows.forEach(notifKey => {
    if (notificationData[notifKey]) notificationList.push(notificationData[notifKey]);
  });
  return notificationList;
};

export const getNotifColumns = (
  onMore,
  onToggleWeb,
  onToggleEmail,
  formatMessage,
  searchInput,
  handleSearch,
  handleResetSearch,
  isPlacesNotifications,
  region
) => {
  let filters = isPlacesNotifications
    ? [
        {
          text: formatMessage(strings.geofence.blackSpot),
          value: 'blackSpot'
        },
        {
          text: formatMessage(strings.geofence.officeAndIndustrial),
          value: 'officeAndIndustrial'
        },
        {
          text: formatMessage(strings.geofence.transport),
          value: 'transport'
        },
        {
          text: formatMessage(strings.geofence.services),
          value: 'services'
        },
        {
          text: formatMessage(strings.geofence.parking),
          value: 'parking'
        },
        {
          text: formatMessage(strings.geofence.other),
          value: 'other'
        },
        {
          text: formatMessage(strings.geofence.petrolStation),
          value: 'petrolStation'
        }
      ]
    : [
        {
          text: formatMessage(strings.short.tpms),
          value: formatMessage(strings.short.tpms)
        },
        {
          text: formatMessage(strings.short.health),
          value: formatMessage(strings.short.health)
        },
        {
          text: formatMessage(strings.short.drivingBehaviour),
          value: formatMessage(strings.short.drivingBehaviour)
        },
        { text: GroteGuardianDisplayString, value: GroteGuardianDisplayString }
      ];

  filters =
    !isPlacesNotifications && isUsaCustomer(region)
      ? [
          ...filters,
          {
            text: alarmCategories.watchman,
            value: alarmCategories.watchman
          },
          {
            text: formatMessage(strings.short.wheel),
            value: formatMessage(strings.short.wheel)
          }
        ]
      : filters;

  return [
    {
      title: isPlacesNotifications ? formatMessage(strings.short.places) : formatMessage(strings.short.notification),
      searchKey: formatMessage(strings.short.notification),
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <NotificationEntry
          record={record}
          isPlacesNotifications={isPlacesNotifications}
          onMore={() => onMore(record.emailKey)}
        />
      ),
      ...searchColumn('type', searchInput, handleSearch, handleResetSearch),
      onFilter: (searchValue, record) => {
        const lowerCaseSearch = searchValue.toLowerCase();
        if (isPlacesNotifications) {
          return (
            record.title?.props?.id?.toLowerCase().includes(lowerCaseSearch) ||
            record.desc?.props?.id?.toLowerCase().includes(lowerCaseSearch)
          );
        } else {
          return (
            record.title?.toLowerCase().includes(lowerCaseSearch) ||
            record.desc?.toLowerCase().includes(lowerCaseSearch)
          );
        }
      }
    },
    {
      title: formatMessage(strings.short.functionalCategory),
      dataIndex: 'category',
      key: 'category',
      filters: filters,
      onFilter: (value, record) => {
        if (isPlacesNotifications && record?.category?.props?.id) {
          return record.category.props.id.indexOf(value) === 0;
        } else if (!isPlacesNotifications && record?.category) {
          return record.category.indexOf(value) === 0;
        } else {
          return -1;
        }
      },
      width: 220
    },
    {
      title: (
        <div className='we-settings-th'>
          <span>{formatMessage(strings.short.web)}</span>
          <span>{formatMessage(strings.short.email)}</span>
        </div>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (_, record) => (
        <div className='we-settings-th'>
          <Checkbox checked={record.isWebActive} onChange={() => onToggleWeb(record.key, !record.isWebActive)} />
          <Checkbox checked={record.isEmailActive} onChange={() => onToggleEmail(record.key, !record.isEmailActive)} />
        </div>
      )
    }
  ];
};

const NotificationEntry = ({ record, onMore, isPlacesNotifications }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <div className='alarms-table-cell-container'>
      <div className='ntif-icon'>
        <div className={record.iconClass}>{isPlacesNotifications ? <img src={record.iconUrl} /> : record.icon()}</div>
      </div>
      <div className='cell-setting-desc'>
        <div className='cell-setting-title'>{record.title}</div>
        <div className='cell-setting-text'>
          <span>{record.desc}</span>
          <Button type='link' onClick={onMore}>
            {formatMessage(strings.short.more)}
          </Button>
        </div>
      </div>
    </div>
  );
};
