import React, { useEffect, useRef, useState } from 'react';
import { Button, Tooltip } from 'antd';

import { IconDrawRectangle, IconHand, IconWrenge, IconZoomIn, IconZoomOut, IconAlarm } from '../Icons';

import './MapToolbox.scss';
import * as strings from '../../helpers/defaultStrings';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { CheckSquareFilled, BorderOutlined } from '@ant-design/icons';
import { getIsShowPlaces } from '../../helpers/functions';
import liveMapActions from '../../actions/liveMapActions';
import { selectShowGeoJsonLayers, selectShowAlerts, getSelectedTnTTrailer, selectAllPlaces } from '../../selectors';
import { selectProductFeatures } from '../../selectors/auth';
import { ENV_CONFIG } from '../../app/helpers/env-configs';

const { REACT_APP_ENV } = ENV_CONFIG;

/**
 *
 * @param tools = livemap | health
 * @param onShowSelectionToolChange
 * @param selectionTool
 * @param showAlerts
 * @param onShowAlertChange
 * @param onViewStateChange
 * @param formatMessage
 * @returns {JSX.Element}
 * @constructor
 */
const MapToolbox = ({
  section = 'livemap',
  viewState: initialViewState,
  onShowSelectionToolChange,
  selectionTool,
  showPartners,
  onViewStateChange,
  onShowPartnersChange,
  intl: { formatMessage }
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const geozones = useSelector(selectAllPlaces);
  const productFeatures = useSelector(selectProductFeatures);
  const selectedTnTTrailer = useSelector(getSelectedTnTTrailer);
  const [showDisplayInformation, setShowDisplayInformation] = useState(false);
  const showGeoJSONLayers = useSelector(selectShowGeoJsonLayers);
  const showAlerts = useSelector(selectShowAlerts);

  const onZoom = (zoomLevel = 2) => {
    const viewState = {
      ...initialViewState,
      transitionDuration: 200,
      zoom: Math.max(Math.min(initialViewState.zoom + zoomLevel, 20), 0)
    };
    onViewStateChange({ viewState }, 0);
  };

  const closeDisplayInformation = event => {
    if (containerRef && !containerRef.current.contains(event.target)) {
      setShowDisplayInformation(false);
    }
  };

  useEffect(() => {
    const localShowGeoJSONLayers = localStorage.getItem('showGeoJSONLayers');
    if (localShowGeoJSONLayers) {
      dispatch(liveMapActions.setLiveMapShowGeoJSONLayers(localShowGeoJSONLayers === 'true'));
    }
    const localShowAlerts = localStorage.getItem('showAlerts');
    if (localShowAlerts) {
      dispatch(liveMapActions.setLiveMapShowAlerts(localShowAlerts === 'true'));
    }

    document.addEventListener('mousedown', closeDisplayInformation);
    return () => document.removeEventListener('mousedown', closeDisplayInformation);
  }, []);

  const setShowGeoJSONLayers = showLayers => {
    localStorage.setItem('showGeoJSONLayers', showLayers);
    dispatch(liveMapActions.setLiveMapShowGeoJSONLayers(showLayers));
  };
  const onShowAlertChange = show => {
    localStorage.setItem('showAlerts', show);
    dispatch(liveMapActions.setLiveMapShowAlerts(show));
  };
  const resetAlertsGeoLayers = () => {
    setShowGeoJSONLayers(false);
    onShowAlertChange(false);
  };
  return (
    <div className={'toolbox'} ref={containerRef}>
      <div className={'toolbox__button-group'}>
        {section === 'livemap' && (
          <>
            {!getIsShowPlaces() && (
              <div className='show-alerts-tooltip'>
                <Tooltip placement='right' title={formatMessage(strings.short.showHideAlarms)}>
                  <Button
                    className={`toolbox__button-group__button ${showAlerts && 'active'}`}
                    onClick={() => onShowAlertChange(!showAlerts)}
                  >
                    <IconAlarm />
                  </Button>
                </Tooltip>
              </div>
            )}
            {/*// GEOFENCE INTEGRATION <- search for this term to find all validation for geofence and integration*/}
            {(getIsShowPlaces() || REACT_APP_ENV.toString().trim().toLowerCase() === 'dev1') && (
              <Tooltip placement='right' title={formatMessage(strings.short.showHideAlarmsPlaces)}>
                <Button
                  className={`toolbox__button-group__button ${showDisplayInformation && 'active'}`}
                  onClick={() => setShowDisplayInformation(!showDisplayInformation)}
                >
                  {showDisplayInformation || showAlerts || showGeoJSONLayers ? <IconAlarm /> : <IconAlarm />}
                </Button>
              </Tooltip>
            )}
          </>
        )}
        {showDisplayInformation && (
          <div className={'toolbox__display-information'}>
            <div className={'toolbox__display-information__header'}>{formatMessage(strings.short.showInformation)}</div>
            {section === 'livemap' && (
              <>
                <Tooltip placement='right' title={formatMessage(strings.short.showHideAlarms)}>
                  <Button
                    className={`toolbox__display-information__button ${showAlerts && 'active'}`}
                    onClick={() => onShowAlertChange(!showAlerts)}
                  >
                    {showAlerts ? <CheckSquareFilled /> : <BorderOutlined />} Alarms
                  </Button>
                </Tooltip>
                <Tooltip placement='right' title={formatMessage(strings.short.showHideGeofences)}>
                  <Button
                    className={`toolbox__display-information__button ${showGeoJSONLayers && 'active'}`}
                    onClick={() => setShowGeoJSONLayers(!showGeoJSONLayers)}
                  >
                    {showGeoJSONLayers ? <CheckSquareFilled /> : <BorderOutlined />}{' '}
                    {formatMessage(strings.short.places)}{' '}
                    {geozones.loading && formatMessage(strings.short.loadingIndictorWithParenthesis)}
                  </Button>
                </Tooltip>
                <Button
                  className={`toolbox__reset-information__button ${(showGeoJSONLayers || showAlerts) && 'active'}`}
                  onClick={() => resetAlertsGeoLayers()}
                >
                  {formatMessage(strings.short.reset)}{' '}
                  {geozones.loading && formatMessage(strings.short.loadingIndictorWithParenthesis)}
                </Button>
              </>
            )}
          </div>
        )}

        {section === 'health' && (
          <Tooltip placement='right' title={formatMessage(strings.short.showWabcoLogo)}>
            <Button
              className={`toolbox__button-group__button ${showPartners && 'active'}`}
              onClick={onShowPartnersChange}
            >
              <IconWrenge />
            </Button>
          </Tooltip>
        )}
      </div>
      {section === 'livemap' &&
        productFeatures.showLiveMapSelectionTool &&
        Object.keys(selectedTnTTrailer).length === 0 && (
          <div className={'toolbox__button-group'}>
            <Tooltip placement='right' title={formatMessage(strings.short.navigateMap)}>
              <Button
                className={`toolbox__button-group__button ${!selectionTool && 'active'}`}
                onClick={() => onShowSelectionToolChange(false)}
              >
                <IconHand />
              </Button>
            </Tooltip>

            <Tooltip placement='right' title={formatMessage(strings.short.bulkSelectTrailers)}>
              <Button
                className={`toolbox__button-group__button ${selectionTool && 'active'}`}
                onClick={() => onShowSelectionToolChange(!selectionTool)}
              >
                <IconDrawRectangle />
              </Button>
            </Tooltip>
          </div>
        )}
      <div className={'toolbox__button-group'}>
        <Tooltip placement='right' title={formatMessage(strings.short.zoomIn)}>
          <Button className={'toolbox__button-group__button border-bottom'} onClick={() => onZoom()}>
            <IconZoomIn />
          </Button>
        </Tooltip>
        <Tooltip placement='right' title={formatMessage(strings.short.zoomOut)}>
          <Button className={'toolbox__button-group__button'} onClick={() => onZoom(-2)}>
            <IconZoomOut />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default injectIntl(MapToolbox);
