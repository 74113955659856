import React, { useState } from 'react';
import { Popover } from 'antd';
import AppSwitcherOptions from './AppSwitcherOptions';
import AppSwitcherIcon from '../Icons/AppSwitcherIcon';
import './AppSwitcher.scss';

const AppSwitcher = ({ avatarSrc }) => {
  const [open, setOpen] = useState(false);

  const toggleMenuOpen = () => setOpen(!open);

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      content={<AppSwitcherOptions avatarSrc={avatarSrc} closePopover={() => setOpen(false)} />}
      arrowPointAtCenter
      visible={open}
      onVisibleChange={toggleMenuOpen}
      overlayClassName='app-switcher'
    >
      <div className='header-menu-right__icon-wrap' onClick={toggleMenuOpen}>
        <AppSwitcherIcon />
      </div>
    </Popover>
  );
};

export default AppSwitcher;
