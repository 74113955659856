import React, { useState, useRef } from 'react';
import { Table, Modal } from 'antd';
import * as strings from '../../../helpers/defaultStrings';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import actions from '../../../actions';
import { getNotifColumns, getNotifRowData } from './DeviceAlarmHelpers/DeviceAlarmsColumns';
import EmailPreview from './DeviceAlarmHelpers/EmailPreview';
import { triggerGenericEvent } from '../../../helpers/googleAnalytics';
import useDebounce from '../../../customHooks/useDebounce';

import { selectRegion, selectNotificationTypesToListenTo, selectProductFeatures } from '../../../selectors';

const DeviceAlarms = ({ notificationRows, isPlacesNotifications }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalRecordKey, setModalRecordKey] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState({});
  const [userChanges, setUserChanges] = useState(0);
  useDebounce(() => triggerGenericEvent('User', 'Notifications Updated', 'configure'), 5000, [userChanges]);
  const searchInput = useRef(null);
  const formatMessage = useIntl().formatMessage;
  const notificationTypesToListenTo = useSelector(selectNotificationTypesToListenTo);
  const productFeatures = useSelector(selectProductFeatures);

  const region = useSelector(selectRegion);

  const onMore = recordKey => {
    setIsModalVisible(true);
    setModalRecordKey(recordKey);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setModalRecordKey(null);
  };

  let tableData = getNotifRowData(notificationRows, region, formatMessage, productFeatures);
  const [webNotifStatus, emailNotifStatus] = deriveStateFromReducer(
    notificationTypesToListenTo,
    tableData.map(d => d.key)
  );

  tableData = tableData.map(row => {
    const rowWithStatus = { ...row };
    rowWithStatus.isWebActive = webNotifStatus[row.key];
    rowWithStatus.isEmailActive = emailNotifStatus[row.key];
    return rowWithStatus;
  });

  const onToggleWeb = (notifKey, newValue) => {
    const update = { ...notificationTypesToListenTo };
    update[notifKey] = update[notifKey] || {};
    update[notifKey].website = newValue ? 1 : 0;
    dispatch(actions.notifications.updateNotificationTypesToListenTo(update));
    setUserChanges(userChanges + 1);
  };
  const onToggleEmail = (notifKey, newValue) => {
    const update = { ...notificationTypesToListenTo };
    update[notifKey] = update[notifKey] || {};
    update[notifKey].email = newValue ? 1 : 0;
    dispatch(actions.notifications.updateNotificationTypesToListenTo(update));
    setUserChanges(userChanges + 1);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: selectedKeys[0] });
  };

  const handleResetSearch = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: undefined });
  };

  const tableColumns = getNotifColumns(
    onMore,
    onToggleWeb,
    onToggleEmail,
    formatMessage,
    searchInput,
    handleSearch,
    handleResetSearch,
    isPlacesNotifications,
    region
  );

  return (
    <>
      <Modal
        title={formatMessage(strings.short.emailPreview)}
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        width={670}
      >
        <EmailPreview emailKey={modalRecordKey} />
      </Modal>
      <Table className='notification-configure-table' columns={tableColumns} dataSource={tableData} />
    </>
  );
};

function deriveStateFromReducer(notificationDataFromReducer, tableDataKeys = []) {
  const webStatus = {};
  const emailStatus = {};
  tableDataKeys.forEach(key => {
    const element = notificationDataFromReducer[key];
    webStatus[key] = element?.website === 1;
    emailStatus[key] = element?.email === 1;
  });
  return [webStatus, emailStatus];
}

export default DeviceAlarms;
