import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import LocaleString from '../Utils/LocaleString';
import AxleView from './AxleView';
import TruckChart from '../charts/truckChart/TruckChartComponent';
import { mobileOnly } from '../../helpers/responsive';
import { useSelector } from 'react-redux';
import TrailerIdCardContentGeneralInfoDescription from './TrailerIdCardContentGeneralInfoDescription';
import { injectIntl } from 'react-intl';

import './TrailerIdCardContentGeneralInfo.scss';

const TrailerIdCardContentGeneralInfo = ({
  trailer,
  selectedTrailer,
  trailerCurrent,
  hideAxleView,
  intl: { formatMessage }
}) => {
  const isMobile = mobileOnly.matches;
  const isMetric = useSelector(store => store.auth.isMetric);
  const processing = useSelector(store => store.trailerDetails.trailer.processing);

  if (processing) {
    return <LoadingOutlined className='large-margin-left' />;
  }

  return (
    (trailer || selectedTrailer) && (
      <Row type='flex' id='componentToPrint' className={'trailer-card-id__content__general-info'}>
        <Col span={isMobile ? 24 : 10}>
          <h2>
            <LocaleString type='short' id='generalInformation' />
          </h2>
          <div style={{ paddingLeft: 8, marginBottom: 8 }}>
            <TrailerIdCardContentGeneralInfoDescription trailer={trailer || selectedTrailer} />
          </div>
          {!hideAxleView && trailer && (
            <AxleView
              trailer={trailer}
              trailerCurrent={trailerCurrent}
              isMetric={isMetric}
              formatMessage={formatMessage}
            />
          )}
        </Col>
        {trailer && (
          <Col span={isMobile ? 24 : 12} offset={!isMobile ? 1 : 0}>
            <div className={'trailer-card-id__content__general-info__vehicle-type-title'}>
              <LocaleString type='short' id='vehicleType' />
            </div>
            <div className={'trailer-card-id__content__general-info__vehicle-type-info'}>
              <LocaleString type='type' id={trailer.vehicleType} />
            </div>
            {trailer.axles && trailer.axles.details && <TruckChart data={trailer} />}
          </Col>
        )}
      </Row>
    )
  );
};

export default injectIntl(TrailerIdCardContentGeneralInfo);
