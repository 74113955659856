import React from 'react';
import * as strings from '../defaultStrings';
import { isUsaCustomer } from '../functions';
import { alarmCategories, HX_ALARM_TYPES, triggerKeys, triggerTypes } from './constants';
import IconABS from '../../Components/Icons/ABS';
import IconWarningLight from '../../Components/Icons/warningLight';
import IconTPMS from '../../Components/Icons/TPMS';
import IconRSS from '../../Components/Icons/RSS';
import IconWithoutISOCable from '../../Components/Icons/withoutISOCable';
import IconHarshBraking from '../../Components/Icons/harshBraking';
import IconPlaces from '../../Components/Icons/Places';
import IconLightAnomaly from '../../Components/Icons/LightAnomaly';
import IconWheelEnd from '../../Components/Icons/WheelEnd';
import IconElectricalFault from '../../Components/Icons/ElectricalFault';
import IconSensorNotFound from '../../Components/Icons/SensorNotFound';
import { GroteGuardianDisplayString, ORDINAL_NUMBERS_STRING_KEYS, publicUrl } from '../constants';
import { getIsShowPlaces } from '../functions';
import LocaleString from '../../Components/Utils/LocaleString';
import { getConversionString, unitType } from '../unitConverter';

export const getTriggerKeys = region => triggerKeys[region] || triggerKeys.default;

export function getTriggers(region, productFeatures) {
  let triggers = triggerTypes[region] || triggerTypes.default;

  if (!getIsShowPlaces()) {
    delete triggers.geofence;
  }

  if (productFeatures?.showWatchman) {
    triggers = {
      ...triggers,
      ...triggerTypes.watchman
    };
  }

  return triggers;
}

export function getTriggerTitles(region, productFeatures) {
  const triggers = getTriggers(region, productFeatures);
  const triggerTitles = [];

  for (let trigger in triggers) {
    triggerTitles.push({ text: triggers[trigger].text, value: trigger });
  }

  return triggerTitles;
}

export function getAlarmIconSVG(trigger, classes = '') {
  const alert = trigger || triggerTypes.default.default;
  return <div className={classes + ' ' + alert.class}>{alert.getAvatarSVG()}</div>;
}

const largeAlarmIconClass = 'large alarm-icon';
export const getNotificationTypeName = (type, formatMessage) => {
  switch (type) {
    case 'poweron':
      return formatMessage(strings.alert.powerOnTitle);
    case 'ebsAmber':
      return formatMessage(strings.alert.ebsAmberTitle);
    case 'absWarningLamp':
      return formatMessage(strings.alert.absWarningTitle);
    case 'ebsRed':
      return formatMessage(strings.alert.ebsRedTitle);
    case 'harshBrakeEvent':
      return formatMessage(strings.alert.harshBrakeTitle);
    case 'startstop':
      return formatMessage(strings.alert.startStopTitle);
    case 'tpms':
      return formatMessage(strings.alert.tpmsTitle);
    case 'tireExtremeUnderPressure':
      return formatMessage(strings.alert.tpmsEUPTitle);
    case 'tireUnderPressure':
      return formatMessage(strings.alert.tpmsUPTitle);
    case 'tireExtremeOverPressure':
      return formatMessage(strings.alert.tpmsEOPTitle);
    case 'tireOverPressure':
      return formatMessage(strings.alert.tpmsOPTitle);
    case 'rss':
      return formatMessage(strings.alert.rssTitle);
    case 'abs':
      return formatMessage(strings.alert.absTitle);
    case 'withoutISOCable':
      return formatMessage(strings.alert.drivingWithoutISOTitle);
    case 'geofence':
      return formatMessage(strings.alert.geofence);
    case 'groteLightAnomaly':
      return formatMessage(strings.alert.groteLightAnomaly);
    case 'groteLightOutage':
      return formatMessage(strings.alert.groteLightOutage);
    case 'blackSpot':
      return formatMessage(strings.geofence.blackSpot);
    case 'customerSite':
      return formatMessage(strings.geofence.customerSite);
    case 'driverHomeAddress':
      return formatMessage(strings.geofence.driverHomeAddress);
    case 'ferryTerminal':
      return formatMessage(strings.geofence.ferryTerminal);
    case 'industrialZone':
      return formatMessage(strings.geofence.industrialZone);
    case 'maintenancePoint':
      return formatMessage(strings.geofence.maintenancePoint);
    case 'openParkingArea':
      return formatMessage(strings.geofence.openParkingArea);
    case 'other':
      return formatMessage(strings.geofence.other);
    case 'petrolStation':
      return formatMessage(strings.geofence.petrolStation);
    case 'restArea':
      return formatMessage(strings.geofence.restArea);
    case 'secureParking':
      return formatMessage(strings.geofence.secureParking);
    default:
      return type && strings.alert[type] ? formatMessage(strings.alert[type]) : formatMessage(strings.short.unknown);
  }
};

const getAllDaNotifData = formatMessage => {
  return {
    absWarningLamp: {
      key: 'absWarningLamp',
      emailKey: 'absWarningLamp',
      icon: IconWarningLight,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.absWarningTitle),
      desc: formatMessage(strings.alert.absWarningLightDesc),
      category: formatMessage(strings.short.health),
      isWebActive: false,
      isEmailActive: false
    },
    amberLight: {
      key: 'ebsAmber',
      emailKey: 'amber',
      icon: IconWarningLight,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.ebsAmberTitle),
      desc: formatMessage(strings.alert.ebsAmberLightDesc),
      category: formatMessage(strings.short.health),
      isWebActive: false,
      isEmailActive: false
    },
    redLight: {
      key: 'ebsRed',
      emailKey: 'red',
      icon: IconWarningLight,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.ebsRedTitle),
      desc: formatMessage(strings.alert.ebsRedLightDesc),
      category: formatMessage(strings.short.health),
      isWebActive: false,
      isEmailActive: false
    },
    tireExtremeUnderPressure: {
      key: 'tireExtremeUnderPressure',
      emailKey: 'tireExtremeUnderPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.extremeLowTP),
      desc: formatMessage(strings.alert.extremeLowPressureDesc),
      category: formatMessage(strings.short.tpms),
      isWebActive: false,
      isEmailActive: false
    },
    tireUnderPressure: {
      key: 'tireUnderPressure',
      emailKey: 'tireUnderPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.lowTP),
      desc: formatMessage(strings.alert.lowPressureDesc),
      category: formatMessage(strings.short.tpms),
      isWebActive: false,
      isEmailActive: false
    },
    tireExtremeOverPressure: {
      key: 'tireExtremeOverPressure',
      emailKey: 'tireExtremeOverPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.extremeHighTP),
      desc: formatMessage(strings.alert.extremeHighPressureDesc),
      category: formatMessage(strings.short.tpms),
      isWebActive: false,
      isEmailActive: false
    },
    tireOverPressure: {
      key: 'tireOverPressure',
      emailKey: 'tireOverPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.highTP),
      desc: formatMessage(strings.alert.highPressureDesc),
      category: formatMessage(strings.short.tpms),
      isWebActive: false,
      isEmailActive: false
    },
    rss: {
      key: 'rss',
      emailKey: 'rss',
      icon: IconRSS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.rssTriggers),
      desc: formatMessage(strings.alert.rssDesc),
      category: formatMessage(strings.short.drivingBehaviour),
      isWebActive: false,
      isEmailActive: false
    },
    abs: {
      key: 'abs',
      emailKey: 'abs',
      icon: IconABS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.absTriggers),
      desc: formatMessage(strings.alert.absDesc),
      category: formatMessage(strings.short.drivingBehaviour),
      isWebActive: false,
      isEmailActive: false
    },
    withoutISOCable: {
      key: 'withoutISOCable',
      emailKey: 'withoutISOCable',
      icon: IconWithoutISOCable,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.drivingWithoutISOTitle),
      desc: formatMessage(strings.alert.drivingWithoutISODesc),
      category: formatMessage(strings.short.drivingBehaviour),
      isWebActive: false,
      isEmailActive: false
    },
    harshBrakeEvent: {
      key: 'harshBrakeEvent',
      emailKey: 'harshBrakeEvent',
      icon: IconHarshBraking,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.harshBrakeTitle),
      desc: formatMessage(strings.alert.harshBrakeDesc),
      category: formatMessage(strings.short.drivingBehaviour),
      isWebActive: false,
      isEmailActive: false
    },
    geofence: {
      key: 'geofence',
      emailKey: 'geofence',
      icon: IconPlaces,
      iconClass: 'large alarm-icon flex-center blue',
      title: formatMessage(strings.alert.geofence),
      desc: formatMessage(strings.alert.geofenceDesc),
      category: formatMessage(strings.short.places),
      isWebActive: true,
      isEmailActive: true
    },
    groteLightAnomaly: {
      key: 'groteLightAnomaly',
      emailKey: 'groteLightAnomaly',
      icon: IconLightAnomaly,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.groteLightAnomaly),
      desc: formatMessage(strings.alert.groteLightAnomalyDesc),
      category: GroteGuardianDisplayString,
      isWebActive: false,
      isEmailActive: false
    },
    groteLightOutage: {
      key: 'groteLightOutage',
      emailKey: 'groteLightOutage',
      icon: IconLightAnomaly,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.groteLightOutage),
      desc: formatMessage(strings.alert.groteLightOutageDesc),
      category: GroteGuardianDisplayString,
      isWebActive: false,
      isEmailActive: false
    },
    wheelEndOverTemperature: {
      key: 'wheelEndOverTemperature',
      emailKey: 'wheelEndOverTemperature',
      icon: IconWheelEnd,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.wheelEndOverTemperature),
      desc: formatMessage(strings.alert.highWheelTemperatureDesc),
      category: formatMessage(strings.short.wheel),
      isWebActive: false,
      isEmailActive: false
    },
    electricalFault: {
      key: 'electricalFault',
      emailKey: 'electricalFault',
      icon: IconElectricalFault,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.electricalFault),
      desc: formatMessage(strings.alert.electricalFaultDesc),
      category: formatMessage(strings.short.wheel),
      isWebActive: false,
      isEmailActive: false
    },
    sensorNotFound: {
      key: 'sensorNotFound',
      emailKey: 'sensorNotFound',
      icon: IconSensorNotFound,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.sensorNotFound),
      desc: formatMessage(strings.alert.sensorNotFoundDesc),
      category: formatMessage(strings.short.wheel),
      isWebActive: false,
      isEmailActive: false
    },
    tireLowPressure: {
      key: 'tireLowPressure',
      emailKey: 'tireLowPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.tireLowPressure),
      desc: formatMessage(strings.alert.tireLowPressureDesc),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    parkedWithLowPressure: {
      key: 'parkedWithLowPressure',
      emailKey: 'parkedWithLowPressure',
      icon: IconTPMS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.parkedWithLowPressure),
      desc: formatMessage(strings.alert.parkedWithLowPressureDesc),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    parkedWithSlowLeak: {
      key: 'parkedWithSlowLeak',
      emailKey: 'parkedWithSlowLeak',
      icon: IconTPMS,
      iconClass: 'large alarm-icon yellow',
      title: formatMessage(strings.alert.parkedWithSlowLeak),
      desc: formatMessage(strings.alert.parkedWithSlowLeakDesc),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    slowLeak: {
      key: 'slowLeak',
      emailKey: 'slowLeak',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.slowLeak),
      desc: formatMessage(strings.alert.slowLeakDesc),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    criticalLeak: {
      key: 'criticalLeak',
      emailKey: 'criticalLeak',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.criticalLeak),
      desc: formatMessage(strings.alert.moderateLeakDesc),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    lowWheelTemperature: {
      key: 'lowWheelTemperature',
      emailKey: 'lowWheelTemperature',
      icon: IconTPMS,
      iconClass: 'large alarm-icon red',
      title: formatMessage(strings.alert.lowWheelTemperature),
      desc: formatMessage(strings.alert.lowWheelTemperature),
      category: alarmCategories.watchman,
      isWebActive: false,
      isEmailActive: false
    },
    ...placesVariables
  };
};

export const getDaNotifData = (formatMessage, region, productFeatures) => {
  const allDANotifData = getAllDaNotifData(formatMessage);
  const commonNotifications = {
    geofence: allDANotifData.geofence,
    tireExtremeUnderPressure: allDANotifData.tireExtremeUnderPressure,
    tireUnderPressure: allDANotifData.tireUnderPressure,
    tireExtremeOverPressure: allDANotifData.tireExtremeOverPressure,
    tireOverPressure: allDANotifData.tireOverPressure,
    rss: allDANotifData.rss,
    abs: allDANotifData.abs,
    withoutISOCable: allDANotifData.withoutISOCable,
    harshBrakeEvent: allDANotifData.harshBrakeEvent,
    groteLightAnomaly: allDANotifData.groteLightAnomaly,
    groteLightOutage: allDANotifData.groteLightOutage,
    blackSpot: allDANotifData.blackSpot,
    customerSite: allDANotifData.customerSite,
    driverHomeAddress: allDANotifData.driverHomeAddress,
    ferryTerminal: allDANotifData.ferryTerminal,
    industrialZone: allDANotifData.industrialZone,
    maintenancePoint: allDANotifData.maintenancePoint,
    openParkingArea: allDANotifData.openParkingArea,
    other: allDANotifData.other,
    petrolStation: allDANotifData.petrolStation,
    restArea: allDANotifData.restArea,
    secureParking: allDANotifData.secureParking
  };
  if (isUsaCustomer(region)) {
    return {
      absWarningLamp: allDANotifData.absWarningLamp,
      ...(productFeatures.showWatchman && {
        wheelEndOverTemperature: allDANotifData.wheelEndOverTemperature,
        parkedWithLowPressure: allDANotifData.parkedWithLowPressure,
        parkedWithSlowLeak: allDANotifData.parkedWithSlowLeak,
        slowLeak: allDANotifData.slowLeak,
        criticalLeak: allDANotifData.criticalLeak,
        tireLowPressure: allDANotifData.tireLowPressure,
        electricalFault: allDANotifData.electricalFault,
        sensorNotFound: allDANotifData.sensorNotFound
      }),
      ...commonNotifications
    };
  }
  return {
    amberLight: allDANotifData.amberLight,
    redLight: allDANotifData.redLight,
    ...commonNotifications
  };
};

export const getIsTypeGeofence = alert => alert?.type === 'geofence';

export const placesVariables = {
  industrialZone: {
    colour: [158, 157, 36],
    colourHex: '#9E9D24',
    markerUrl: '/alarmIcons/placesMarkers/industrialZone.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/industrialZone.svg',
    key: 'geofence_industrialZone',
    emailKey: 'geofence_industrialZone',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='industrialZone' />,
    desc: <LocaleString type='geofence' id='industrialZone' />,
    category: <LocaleString type='geofence' id='officeAndIndustrial' />,
    isWebActive: false,
    isEmailActive: false
  },
  customerSite: {
    colour: [158, 157, 36],
    colourHex: '#9E9D24',
    markerUrl: '/alarmIcons/placesMarkers/customerSite.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/customerSite.svg',
    key: 'geofence_customerSite',
    emailKey: 'geofence_customerSite',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='customerSite' />,
    desc: <LocaleString type='geofence' id='customerSite' />,
    category: <LocaleString type='geofence' id='officeAndIndustrial' />,
    isWebActive: false,
    isEmailActive: false
  },
  driverHomeAddress: {
    colour: [158, 157, 36],
    colourHex: '#9E9D24',
    markerUrl: '/alarmIcons/placesMarkers/driverHomeAddress.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/driverHomeAddress.svg',
    key: 'geofence_driverHomeAddress',
    emailKey: 'geofence_driverHomeAddress',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='driverHomeAddress' />,
    desc: <LocaleString type='geofence' id='driverHomeAddress' />,
    category: <LocaleString type='geofence' id='officeAndIndustrial' />,
    isWebActive: false,
    isEmailActive: false
  },
  petrolStation: {
    colour: [69, 39, 160],
    colourHex: '#4527A0',
    markerUrl: '/alarmIcons/placesMarkers/petrolStation.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/petrolStation.svg',
    key: 'geofence_petrolStation',
    emailKey: 'geofence_petrolStation',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='petrolStation' />,
    desc: <LocaleString type='geofence' id='petrolStation' />,
    category: <LocaleString type='geofence' id='petrolStation' />,
    isWebActive: false,
    isEmailActive: false
  },
  secureParking: {
    colour: [21, 101, 192],
    colourHex: '#1565C0',
    markerUrl: '/alarmIcons/placesMarkers/secureParking.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/secureParking.svg',
    key: 'geofence_secureParking',
    emailKey: 'geofence_secureParking',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='secureParking' />,
    desc: <LocaleString type='geofence' id='secureParking' />,
    category: <LocaleString type='geofence' id='parking' />,
    isWebActive: false,
    isEmailActive: false
  },
  restArea: {
    colour: [21, 101, 192],
    colourHex: '#1565C0',
    markerUrl: '/alarmIcons/placesMarkers/restArea.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/restArea.svg',
    key: 'geofence_restArea',
    emailKey: 'geofence_restArea',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='restArea' />,
    desc: <LocaleString type='geofence' id='restArea' />,
    category: <LocaleString type='geofence' id='parking' />,
    isWebActive: false,
    isEmailActive: false
  },
  openParkingArea: {
    colour: [21, 101, 192],
    colourHex: '#1565C0',
    markerUrl: '/alarmIcons/placesMarkers/openParkingArea.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/openParkingArea.svg',
    key: 'geofence_openParkingArea',
    emailKey: 'geofence_openParkingArea',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='openParkingArea' />,
    desc: <LocaleString type='geofence' id='openParkingArea' />,
    category: <LocaleString type='geofence' id='parking' />,
    isWebActive: false,
    isEmailActive: false
  },
  maintenancePoint: {
    colour: [106, 27, 154],
    colourHex: '#6A1B9A',
    markerUrl: '/alarmIcons/placesMarkers/maintenancePoint.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/maintenancePoint.svg',
    key: 'geofence_maintenancePoint',
    emailKey: 'geofence_maintenancePoint',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='maintenancePoint' />,
    desc: <LocaleString type='geofence' id='maintenancePoint' />,
    category: <LocaleString type='geofence' id='services' />,
    isWebActive: false,
    isEmailActive: false
  },
  blackSpot: {
    colour: [221, 12, 41],
    colourHex: '#DD0C29',
    markerUrl: '/alarmIcons/placesMarkers/blackSpot.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/blackSpot.svg',
    key: 'geofence_blackSpot',
    emailKey: 'geofence_blackSpot',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='blackSpot' />,
    desc: <LocaleString type='geofence' id='blackSpot' />,
    category: <LocaleString type='geofence' id='blackSpot' />,
    isWebActive: false,
    isEmailActive: false
  },
  ferryTerminal: {
    colour: [40, 53, 147],
    colourHex: '#283593',
    markerUrl: '/alarmIcons/placesMarkers/ferryTerminal.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/ferryTerminal.svg',
    key: 'geofence_ferryTerminal',
    emailKey: 'geofence_ferryTerminal',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='ferryTerminal' />,
    desc: <LocaleString type='geofence' id='ferryTerminal' />,
    category: <LocaleString type='geofence' id='transport' />,
    isWebActive: false,
    isEmailActive: false
  },
  other: {
    colour: [98, 117, 127],
    colourHex: '#62757F',
    markerUrl: '/alarmIcons/placesMarkers/other.png',
    iconUrl: publicUrl + 'alarmIcons/placesIcons/other.svg',
    key: 'geofence_other',
    emailKey: 'geofence_other',
    iconClass: largeAlarmIconClass,
    title: <LocaleString type='geofence' id='other' />,
    desc: <LocaleString type='geofence' id='other' />,
    category: <LocaleString type='geofence' id='other' />,
    isWebActive: false,
    isEmailActive: false
  }
};

export const getHxAlertInfoString = (alert, formatMessage, isMetric, isUS) => {
  const { alarm: type } = alert;
  const watchman = alert.watchman?.find(wheel => wheel?.alarms?.length);
  const position = watchman?.location?.position;
  const row = watchman?.location?.row;
  if (!position || !row) {
    return '';
  }
  let stringKey = strings.alert.hxAlertInfo;
  const stringVariables = getHxLocationStrings(position, row, formatMessage);

  if (HX_ALARM_TYPES.pressure.includes(type)) {
    if (Object.values(watchman.pressure).length > 1) {
      stringVariables.pressureValuePortA = formatMessage(
        ...getConversionString(isMetric, watchman.pressure.port_a, unitType.pressure, isUS)
      );
      stringVariables.pressureValuePortB = formatMessage(
        ...getConversionString(isMetric, watchman.pressure.port_b, unitType.pressure, isUS)
      );
      stringKey = strings.alert.hxAlertInfoWithPressure;
    } else {
      stringVariables.pressureValue = formatMessage(
        ...getConversionString(isMetric, watchman.pressure.port_a, unitType.pressure, isUS)
      );
      stringKey = strings.alert.hxAlertInfoWithSinglePressure;
    }
  } else if (HX_ALARM_TYPES.temperature.includes(type)) {
    stringVariables.temperatureValue = formatMessage(
      ...getConversionString(isMetric, watchman.temperature, unitType.temp, isUS)
    );
    stringKey = strings.alert.hxAlertInfoWithTemp;
  }
  return <p>{formatMessage(stringKey, stringVariables)}</p>;
};

export const getHxLocationStrings = (position, row, formatMessage) => {
  const wheelLocation = `A${row}${position === 1 ? 'L' : 'R'}`;
  const axleOrdinal = getOrdinalNumberString(row, formatMessage);
  const side = formatMessage(position === 1 ? strings.short.left : strings.short.right);
  return { axleOrdinal, side, wheelLocation };
};

export const getOrdinalNumberString = (number, formatMessage) => {
  const stringKey = ORDINAL_NUMBERS_STRING_KEYS[number];
  return stringKey ? formatMessage(stringKey) : number;
};
