import actionTypes from './actionTypes';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { Logger } from '../app/helpers/logger';

function getGroupsRequest() {
  return {
    type: actionTypes.GET_GROUPS_REQUEST
  };
}

function getGroupsSuccess(groups) {
  return {
    type: actionTypes.GET_GROUPS_SUCCESS,
    groups
  };
}

function getGroupsFailure(error) {
  return {
    type: actionTypes.GET_GROUPS_FAILURE,
    error
  };
}

function getGroups() {
  return dispatch => {
    dispatch(getGroupsRequest());

    return worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'groups' })
      .then(res => {
        const parsedRes = parseGroups(res.groups);
        return dispatch(getGroupsSuccess(parsedRes));
      })
      .catch(err => {
        Logger.error(err);
        return dispatch(getGroupsFailure(err));
      });
  };
}

function parseGroups(groups) {
  const subgroups = groups.filter(item => !!item.parentId);
  return groups
    .filter(item => !item.parentId)
    .map(group => {
      group.subgroups = [];
      subgroups.forEach(subgroup => {
        if (group.id === subgroup.parentId) {
          group.subgroups.push(subgroup);
        }
      });
      return group;
    });
}

export default {
  getGroups
};
