import { createSlice } from '@reduxjs/toolkit';
import { generateAsyncThunkReducers } from '../app/generators';
import { LOADING_STATUSES, LAST_VALID_EBPMS_EVENT } from '../helpers/constants';

const initialState = {
  lastValidEBPMSEvents: [],
  loading: LOADING_STATUSES.IDLE
};

const fulfilledStateReducer = (state, action) => {
  state.lastValidEBPMSEvents.push(...action.payload);
};

export const sliceOptions = {
  name: 'lastValidEBPMSEventsState',
  initialState,
  extraReducers: {
    ...generateAsyncThunkReducers({ thunkType: LAST_VALID_EBPMS_EVENT, fulfilledStateReducer })
  }
};

export const lastValidEBPMSEventSlice = createSlice(sliceOptions);

export default {
  lastValidEBPMSEventsState: lastValidEBPMSEventSlice.reducer
};
