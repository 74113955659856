import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import { ArrowLeftOutlined, CompassOutlined, InboxOutlined } from '@ant-design/icons';
import { Row, Col, Spin, Upload, Button } from 'antd';
import actions from '../../actions';

import TrailerList from '../Utils/TrailerList';
import TrailerShare from './TrailerShare';
import TrailerIdCardContent from './TrailerIdCardContent';
import LocaleString from '../Utils/LocaleString';
import { triggerEvent } from '../../helpers/googleAnalytics';
import './TrailerIdCard.scss';
import HeartIcon from '../Utils/CustomIcons';

class TrailerIdCard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      match,
      retrieveTrailerDetails,
      retrieveTrailerCMIReports,
      retrieveTrailerRFData,
      getOdrFileList,
      getTrailerHistograms,
      getOdrBrakingEvents,
      selectedTrailer,
      processing,
      goToSelectedTrailer
    } = this.props;

    getTrailerHistograms();
    getOdrBrakingEvents();
    if (match.params.trailerId && !processing) {
      retrieveTrailerDetails(match.params.trailerId);
      retrieveTrailerCMIReports(match.params.trailerId);
      retrieveTrailerRFData(match.params.trailerId);
      getOdrFileList(match.params.trailerId);
    } else if (!processing && selectedTrailer && selectedTrailer.assetId) {
      goToSelectedTrailer(selectedTrailer.assetId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      retrieveTrailerDetails,
      retrieveTrailerCMIReports,
      retrieveTrailerRFData,
      trailers,
      getOdrFileList,
      selectedTrailer,
      selectedHealthTrailer,
      updateSelectedHealthTrailer
    } = this.props;
    // check to see if we are coming from an ID to non ID (by using the main menu icons)
    if (!match.params.trailerId && prevProps.match.params.trailerId) {
      this.goBack();
    }

    if (match.params.trailerId && match.params.trailerId !== prevProps.match.params.trailerId) {
      retrieveTrailerDetails(match.params.trailerId);
      retrieveTrailerCMIReports(match.params.trailerId);
      retrieveTrailerRFData(match.params.trailerId);
      getOdrFileList(match.params.trailerId);
    }

    if (selectedTrailer && match.params.trailerId && !selectedHealthTrailer && trailers) {
      const newSelectedTrailer = trailers.filter(item => {
        return item.assetId === selectedTrailer.assetId;
      })[0];
      if (newSelectedTrailer) {
        updateSelectedHealthTrailer(newSelectedTrailer);
      }
    }
  }

  componentWillUnmount() {
    const { resetTrailerSelection } = this.props;
    resetTrailerSelection();
  }

  handleFile = info => {
    const { uploadFile } = this.props;
    const reader = new FileReader();
    reader.onloadend = () => {
      uploadFile(reader.result);
    };
    reader.readAsDataURL(info.file);
    triggerEvent('ODR', 'Upload File', 'Trailer Screen');
  };

  goBack = () => {
    const { match, resetTrailerSelection, goTo } = this.props;
    const url = match.url.split('/')[1];
    resetTrailerSelection();
    goTo(url);
  };

  goToHealthPage = () => {
    const { updateSelectedHealthTrailer, selectedHealthTrailer, goToHealth } = this.props;
    triggerEvent('Navigation', 'Go to selected device on Health screen', 'Trailer ID Card screen');
    updateSelectedHealthTrailer(selectedHealthTrailer);
    goToHealth();
  };

  goToLiveMapPage = () => {
    const { updateSelectedTnTTrailer, selectedHealthTrailer, goToLiveMap } = this.props;
    triggerEvent('Navigation', 'Go to selected device on Live Map screen', 'Trailer ID Card screen');
    updateSelectedTnTTrailer(selectedHealthTrailer);
    goToLiveMap();
  };

  render() {
    const {
      selectedTrailer,
      selectedTrailerCurrent,
      processing,
      match,
      history,
      location,
      selectedHealthTrailer,
      selectedTrailerInstall,
      selectedTrailerRf
    } = this.props;
    const url = match.url.split('/')[1];
    let rfDetails;
    if (selectedTrailerRf && selectedTrailerRf[0]) {
      rfDetails = selectedTrailerRf[0];
    }
    const action = (
      <Row className='trailer-card-id__header__actions'>
        {selectedTrailer && !selectedTrailer.uploaded && selectedHealthTrailer && (
          <Button type='link' onClick={this.goToLiveMapPage} icon={<CompassOutlined />} className='medium-margin-right'>
            {' '}
            <LocaleString type='button' id='liveMap' />
          </Button>
        )}
        {selectedTrailer && !selectedTrailer.uploaded && selectedHealthTrailer && url !== 'health' && (
          <Button
            type='link'
            onClick={this.goToHealthPage}
            className='medium-margin-right trailer-card-id__header__actions__health'
          >
            <HeartIcon /> <LocaleString type='button' id='health' />
          </Button>
        )}
        {selectedTrailer && !selectedTrailer.uploaded && selectedTrailer.vinNumber && (
          <TrailerShare
            trailerVin={selectedTrailer && selectedTrailer.vinNumber}
            assetId={selectedTrailer && selectedTrailer.assetId}
          />
        )}
        <TrailerList location={location} />
      </Row>
    );

    return (
      <Row type='flex' style={{ height: '100%', overflowY: 'scroll' }} className='trailer-card-id'>
        <Col span={24} style={{ backgroundColor: 'white', height: '100%' }}>
          <div>
            {processing && (
              <div style={{ textAlign: 'center', marginTop: 150 }}>
                <Spin size='large' />
              </div>
            )}
            {!selectedTrailer && !processing && (
              <div style={{ textAlign: 'center', marginTop: 200 }}>
                <div className='trailer-card-id__pick-trailer'>
                  <h4>
                    <LocaleString type='phrase' id='noTrailerSelected' />
                  </h4>
                  <TrailerList location={location} />
                </div>
                <div className='trailer-card-id__or'>
                  <LocaleString type='short' id='or' />
                </div>
                <div className='trailer-card-id__odr' style={{ width: 400, margin: 'auto', maxWidth: '90%' }}>
                  <Upload.Dragger name='file' multiple={false} customRequest={this.handleFile}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      <LocaleString type='phrase' id='odrUploadText' />
                    </p>
                    <p className='ant-upload-hint'>
                      <LocaleString type='phrase' id='odrUploadHint' />
                    </p>
                  </Upload.Dragger>
                </div>
              </div>
            )}
            {selectedTrailer && !processing && (
              <div>
                <PageHeader
                  title={
                    <div>
                      <ArrowLeftOutlined onClick={this.goBack} className='large-margin-right' />
                      <LocaleString type='phrase' id='detailsForTrailerVal' val={selectedTrailer.defaultDisplayName} />
                    </div>
                  }
                  action={action}
                />
                <TrailerIdCardContent
                  trailer={selectedTrailer}
                  trailerCurrent={selectedTrailerCurrent}
                  match={match}
                  history={history}
                  location={location}
                  installationDetails={selectedTrailerInstall}
                  rfDetails={rfDetails}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(store) {
  return {
    processing:
      store.trailerDetails.trailer.processing ||
      store.trailerDetails.installationDetails.processing ||
      store.trailerDetails.rfDetails.processing,
    error: store.trailerDetails.trailer.error,
    selectedTrailer: store.trailerDetails.trailer.item,
    selectedTrailerCurrent: store.trailerDetails.trailer.listTrailer,
    selectedTrailerInstall: store.trailerDetails.installationDetails.item,
    selectedTrailerRf: store.trailerDetails.rfDetails.item,
    selectedHealthTrailer: store.appState.selectedHealthTrailer,
    trailers: store.trailerDetails.trailers.items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveTrailerDetails: trailerId => dispatch(actions.trailers.getTrailerDetails(trailerId)),
    retrieveTrailerCMIReports: trailerId => dispatch(actions.trailers.getTrailerCMIReports(trailerId)),
    retrieveTrailerRFData: trailerId => dispatch(actions.trailers.retrieveTrailerRFData(trailerId)),
    uploadFile: file => dispatch(actions.file.uploadFile(file)),
    resetTrailerSelection: () => dispatch(actions.trailers.resetTrailerSelection()),
    goTo: url => dispatch(push(`/${url}`)),
    updateSelectedHealthTrailer: trailer => dispatch(actions.appState.updateSelectedHealthTrailer(trailer)),
    updateSelectedTnTTrailer: trailer => dispatch(actions.appState.updateSelectedTnTTrailer(trailer)),
    goToLiveMap: () => dispatch(push('/live-map')),
    goToSelectedTrailer: assetId => dispatch(push('/trailer/' + assetId)),
    goToHealth: () => dispatch(push('/health')),
    getOdrFileList: trailerId => dispatch(actions.odrFiles.getOdrFileList(trailerId)),
    getTrailerHistograms: () => dispatch(actions.trailerHistograms.getTrailerHistograms()),
    getOdrBrakingEvents: () => dispatch(actions.trailersOdrBraking.getOdrBrakingEvents())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrailerIdCard);
