import moment from 'moment';
import { API_NAMES } from '../../../helpers/constants';
import { worker } from '../../../app/web-workers/aws-web-worker';

const MAX_REQ_COUNT = 100;
const REQ_INTERVAL_MS = 10000;

const requestReport = (startTime, endTime) => {
  const body = { startTime: moment(startTime).format(), endTime: moment(endTime).format() };
  return worker.Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'analytics/report', body });
};

const getReportStatus = reportId => {
  return worker.Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: `analytics/report/${reportId}` });
};

export const getReport = (startTime, endTime) => {
  return new Promise((resolve, reject) => {
    requestReport(new Date(startTime.toISOString()), new Date(endTime.toISOString()))
      .then(res => {
        const reportID = res.reportId;
        let requestCount = 0;
        if (reportID) {
          const interval = setInterval(async () => {
            try {
              if (requestCount >= MAX_REQ_COUNT) {
                reject('MAX_REQ_COUNT_REACHED');
                return;
              }
              requestCount++;
              const reportRes = await getReportStatus(reportID);
              if (reportRes.complete && reportRes.signedUrl) {
                clearInterval(interval);
                resolve(reportRes.signedUrl);
              }
            } catch (error) {
              clearInterval(interval);
              reject(error);
            }
          }, REQ_INTERVAL_MS);
        }
      })
      .catch(err => reject(err));
  });
};

export default { getReport, requestReport, getReportStatus };
