import { lineString, point, polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { FlyToInterpolator } from 'react-map-gl';
import * as d3 from 'd3';
import { WebMercatorViewport } from '@deck.gl/core';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Logger } from '../app/helpers/logger';

const turf = { lineString, bbox, point };

const getZoomOfRecenteredPosition = pos => {
  if (pos.speed != null && pos.speed <= 50) {
    if (pos.speed < 10) {
      return 15.5;
    }
    return 13;
  }
  return 10;
};

export const getDeviceBounds = devices => {
  let points = [
    ...devices
      .map(d => (d.gnss.longitude && d.gnss.latitude ? [d.gnss.longitude, d.gnss.latitude] : null))
      .filter(pos => pos)
  ];
  // is not possible to get the bounds if there is only one device (1 point)
  // so, we can duplicate the point to zoom in the area
  points = points.length === 1 ? [...points, ...points] : points;

  let line = null;
  try {
    line = points.length >= 2 ? turf.lineString(points) : null;
  } catch (e) {
    // Use try catch here in case all the points are the same which could cause an exception when determingin the line
    console.warn(`Problem generating line from device points: ${JSON.stringify(e)}`);
    line = null;
  }
  return {
    bounds: line !== null ? turf.bbox(line) : null,
    points
  };
};

export const getDevicesInPolygon = (devices, coordinates) => {
  const poly = polygon(coordinates);

  const devicesInPolygon = (devices || []).filter(device =>
    booleanPointInPolygon(point([device.gnss.longitude, device.gnss.latitude]), poly)
  );
  return devicesInPolygon.map(device => device.assetId);
};

export const getDevicesInCircle = (devices, center, radius, properties) => {
  const poly = circle(center, radius?.size ? radius.size / 1000 : 1, { properties });

  const devicesInPolygon = (devices || []).filter(device =>
    booleanPointInPolygon(point([device.gnss.longitude, device.gnss.latitude]), poly)
  );
  return devicesInPolygon.map(device => device.assetId);
};

export const zoomOutAllTrailers = (activeDevices, livemapSelectedDevices, flightToBounds) => {
  let { bounds } = getDeviceBounds(
    activeDevices.filter(device => !livemapSelectedDevices.length || livemapSelectedDevices.includes(device.assetId))
  );
  flightToBounds(bounds);
};

export const zoomInTrailerRoute = (
  selectedTrailer,
  mapRoutes,
  hours,
  viewState,
  flightToBounds,
  onViewStateChange,
  trailerInfoPanelEndDate
) => {
  const hoursFromUpdate = hours;

  if (mapRoutes[selectedTrailer.assetId] && mapRoutes[selectedTrailer.assetId].length > 0) {
    if (mapRoutes[selectedTrailer.assetId].length === 1) {
      const newViewState = {
        ...viewState,
        longitude: mapRoutes[selectedTrailer.assetId][0].from[0],
        latitude: mapRoutes[selectedTrailer.assetId][0].from[1],
        zoom: 13,
        transitionDuration: 2500,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubic
      };
      onViewStateChange({ viewState: newViewState });
    } else {
      const oldestTime = hoursFromUpdate
        ? Math.round((trailerInfoPanelEndDate || Date.now()) / 1000) - hoursFromUpdate * 60 * 60
        : 0;
      const points = [
        ...mapRoutes[selectedTrailer.assetId].filter(d => d.startTime >= oldestTime).map(d => d.from),
        ...mapRoutes[selectedTrailer.assetId].filter(d => d.startTime >= oldestTime).map(d => d.to)
      ];
      if (points.length > 1) {
        const line = turf.lineString(points);
        const bounds = turf.bbox(line);

        setTimeout(() => flightToBounds(bounds), 500);
      }
    }
  } else if (selectedTrailer.gnss) {
    const newViewState = {
      ...viewState,
      longitude: selectedTrailer.gnss.longitude,
      latitude: selectedTrailer.gnss.latitude,
      zoom: 13,
      transitionDuration: 2500,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: d3.easeCubic
    };
    onViewStateChange({ viewState: newViewState });
  }
};

export const zoomFollowTrailer = (viewState, historyPosition, onViewStateChange) => {
  const longitude = historyPosition?.startGnss?.longitude;
  const latitude = historyPosition?.startGnss?.latitude;

  if (!longitude || !latitude) {
    return;
  }

  const newViewState = {
    ...viewState,
    longitude,
    latitude,
    zoom: getZoomOfRecenteredPosition(historyPosition),
    transitionDuration: 300,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: d3.easeCubic
  };
  onViewStateChange({ viewState: newViewState });
};

export const zoomToAreaCoordinates = (coordinates, mapRef, viewState, onViewStateChange, extraPadding = false) => {
  try {
    const bounds = turf.bbox(polygon(coordinates));
    return zoomInBounds(bounds, mapRef, viewState, onViewStateChange, extraPadding);
  } catch (e) {
    Logger.error(e);
  }
};

export const zoomInBounds = (
  bounds,
  mapRef,
  viewState,
  onViewStateChange,
  extraPadding = false,
  extraBottomPadding = false
) => {
  try {
    const currentMap = mapRef?.current?.getMap();
    const width = currentMap?.transform?.width;
    const height = currentMap?.transform?.height;

    if (!width || !height || !bounds || !bounds.length) {
      return;
    }
    const viewStateWithWidthHeight = {
      ...viewState,
      width,
      height
    };
    const { longitude, latitude, zoom } = new WebMercatorViewport(viewStateWithWidthHeight).fitBounds(
      [
        [bounds[0], bounds[1]],
        [bounds[2], bounds[3]]
      ],
      {
        padding: {
          top: extraPadding ? 150 : 50,
          bottom: extraBottomPadding ? Math.min(250, mapRef.current._height) : 50,
          left: extraPadding ? 150 : 50,
          right: extraPadding ? 150 : 50
        }
      }
    );

    // if we have a bounds that was generated by a vehicle that has all its lat/lngs on same spot,
    // or within a very high precision close to each other, the zoom we generate can be set to 'Infinity' by the above call.
    const safeZoom = isFinite(zoom) ? zoom : 13;
    const newViewState = {
      ...viewState,
      longitude,
      latitude,
      zoom: safeZoom,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: d3.easeCubic
    };

    onViewStateChange({ viewState: newViewState });
  } catch (e) {
    Logger.error(e);
  }
};
