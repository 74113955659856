import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { Tooltip } from 'antd';
import { getTimeDate } from '../../../../../../helpers/functions';
import moment from 'moment';
import { selectionHeaders } from '../../../../../../helpers/constants';

const getLastPositionTime = lastPosition => getTimeDate(lastPosition * 1000);

const Cell = ({ rowData, formatMessage }) => {
  const { lastPosition } = rowData;

  if (!lastPosition) {
    return formatMessage(strings.short.unknown);
  }

  // catch the >=22h <=36h period and instead of moment showing 'a day ago' we enforce showing hours
  const timeAgo = Math.round(Date.now() / 1000) - lastPosition;
  const hoursAgo = Math.round(timeAgo / (60 * 60));

  return (
    <Tooltip title={getLastPositionTime(lastPosition)} placement='topLeft'>
      {hoursAgo >= 22 && hoursAgo <= 36
        ? formatMessage(strings.short.hoursAgoVal, { value: hoursAgo })
        : moment
            .utc(lastPosition * 1000)
            .local()
            .fromNow()}
    </Tooltip>
  );
};

const LastPositionColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.short.lastPosition)}>{formatMessage(strings.short.lastPosition)}</Tooltip>
    ),
    key: 'lastPosition',
    dataIndex: 'lastPosition',
    selectionSection: selectionHeaders.generalMonitoring.id,
    searchKey: formatMessage(strings.short.lastPosition),
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    sorter: () => trailers.sort('lastPosition', (a, b) => (a.lastPosition > b.lastPosition ? -1 : 1)),
    width: 160
  };
};

const LastPositionCSVHeader = formatMessage => formatMessage(strings.short.lastPosition);
const LastPositionCSVData = trailer => {
  return trailer.lastPosition ? getLastPositionTime(trailer.lastPosition) : '';
};
const LastPositionCSVColumn = {
  header: LastPositionCSVHeader,
  data: LastPositionCSVData
};

export default { table: LastPositionColumn, csv: LastPositionCSVColumn };
