import React from 'react';
import { Row, Button } from 'antd';
import LocaleString from '../Utils/LocaleString';

export default class Events extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  togglePrioritySelection = priority => {
    let priorities;
    if (this.props.priorities.includes(priority)) {
      const newPriorities = new Set(this.props.priorities);
      newPriorities.delete(priority);
      priorities = [...newPriorities];
    } else {
      priorities = [...this.props.priorities, priority];
    }
    this.props.onChange(priorities);
  };

  priorityColor = priority => {
    switch (priority) {
      case 1:
        return '#ffc107';
      case 2:
        return 'tomato';
      default:
        return '#00abe7';
    }
  };

  render() {
    const { priorities } = this.props;
    const legend = [
      { priorityLabel: <LocaleString type='short' id='critical' />, priority: 2 },
      { priorityLabel: <LocaleString type='short' id='important' />, priority: 1 },
      { priorityLabel: <LocaleString type='short' id='info' />, priority: undefined }
    ];
    return (
      <Row type='flex' justify='space-between'>
        <Button.Group className='event-priority-level-legend'>
          {legend.map(p => (
            <Button
              key={p.priority || 0}
              onClick={() => this.togglePrioritySelection(p.priority)}
              style={{ alignItems: 'center' }}
            >
              <span
                style={{
                  height: 15,
                  width: 15,
                  marginRight: 4,
                  backgroundColor: priorities.includes(p.priority) ? this.priorityColor(p.priority) : 'lightgrey',
                  borderRadius: '50%',
                  display: 'inline-block'
                }}
              />
              <span> {p.priorityLabel} </span>
            </Button>
          ))}
        </Button.Group>
      </Row>
    );
  }
}
