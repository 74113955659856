import * as d3 from 'd3';
import numeral from 'numeral';
import Chart from '../chart';
import moment from 'moment';
import { dateMultiFormat, getTimeDate, getDate } from '../../../helpers/functions';
import * as strings from '../../../helpers/defaultStrings';
import { getModuleMessage, getDescriptionMessage } from '../../../helpers/odrerrorDefaultStrings';

import './EventTimelineChart.scss';

export default class EventTimeLineChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();

    this.main = this.svg
      .append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.zoomHighlight = this.main
      .append('g')
      .attr('id', 'zoom-highlight')
      .append('rect')
      .style('fill', '#F9F9F9')
      .attr('height', this.props.height)
      .attr('y', 45)
      .attr('rx', 5)
      .attr('ry', 5);

    this.labels = this.main.append('g').attr('class', 'labels');

    this.xAxis = this.main.append('g').attr('class', 'x-axis');

    this.yAxis = this.main.append('g').attr('class', 'y-axis-event-timeline clickable');

    this.yRecordedAxis = this.main.append('g').attr('class', 'y-axis-recorded-event-timeline');

    this.zoomBackground = this.main
      .append('rect')
      .attr('class', 'zoom')
      .attr('width', this.props.width)
      .attr('height', this.props.height)
      .style('opacity', 0);

    this.main
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.props.width + 20)
      .attr('height', 50000)
      .attr('x', -10)
      .attr('y', 0);

    this.context = this.main.append('g').attr('class', 'context').attr('clip-path', 'url(#clip)');

    if (d3.select('.generic-tooltip.event-timeline-tooltip').empty()) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'generic-tooltip event-timeline-tooltip')
        .style('opacity', 0)
        .style('left', 0)
        .style('top', 0)
        .style('pointer-events', 'none');
    } else {
      this.tooltip = d3.select('.generic-tooltip.event-timeline-tooltip').style('opacity', 0);
    }

    this.flatDTC = false;
    this.flatRecorded = false;
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.trips) {
      this.dtc = state.dtc;
      this.trips = state.trips;
      this.axisType = state.mileageTimeline ? 'mileage' : 'time';
      this.onTripHover = state.onTripHover;
      this.metric = state.metric;
      this.colorDomain = state.colorDomain;
      this.recordedEvents = state.recordedEvents;
      this.selectEvent = state.selectEvent;
      this.selectTrip = state.selectTrip;
      this.trailer = state.trailer;
      this.onErrorTypeClicked = state.onErrorTypeClicked;
      this.drawTimeline();
    }
  }

  updateMetric(metric, colorDomain) {
    const color = d3.scaleSequential(d3.interpolateRdBu).domain([colorDomain[1], colorDomain[0]]);
    this.tripView
      .selectAll('.trip')
      .transition()
      .style('fill', d => color(d[metric]));
  }

  drawTimeline() {
    const self = this;
    this.context.selectAll('*').remove();
    this.tripView = this.context.append('g').attr('class', 'trips');
    this.recordedEventsView = this.context.append('g').attr('class', 'recordedEvents');
    this.dtcView = this.context.append('g').attr('class', 'dtc');
    this.rule = this.context
      .append('g')
      .attr('class', 'rule')
      .append('line')
      .style('stroke', '#f0f0f0')
      .style('stroke-width', 1)
      .style('pointer-events', 'none');
    //.style('opacity', 0);

    if (this.axisType === 'time') {
      this.x = d3.scaleTime().range([0, this.props.width]);
    } else {
      this.x = d3.scaleLinear().range([0, this.props.width]);
    }

    const color = d3.scaleSequential(d3.interpolateRdBu).domain([this.colorDomain[1], this.colorDomain[0]]);

    const distinctErrorCount = this.dtc && this.dtc.length > 0 ? new Set(this.dtc.map(d => d.errorCode)).size : 1;
    const distinctRecordedEventsCount = new Set(this.recordedEvents.map(d => d.eventNameId)).size;

    const tripHeight = 36;
    const recordedEventsHeight = tripHeight * distinctRecordedEventsCount;
    const eventHeight = distinctErrorCount * tripHeight;
    const totalHeight = recordedEventsHeight + tripHeight + eventHeight;

    const formatMessage = this.props.formatMessage;

    this.drawLabels(tripHeight, recordedEventsHeight);

    this.zoom = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, 0],
        [this.props.width, totalHeight]
      ])
      .extent([
        [0, 0],
        [this.props.width, totalHeight]
      ])
      .on('zoom', () => this.zoomed())
      .on('end', () => this.zoomEnd());

    this.zoomBackground
      .attr('height', totalHeight)
      //.on('mouseenter', () => self.rule.style('opacity', 1))
      // eslint-disable-next-line space-before-function-paren
      // eslint-disable-next-line func-names
      .on('mousemove', function () {
        const [x] = d3.mouse(this);
        self.rule.attr('x1', x).attr('x2', x);
      })
      //.on('mouseleave', () => self.rule.style('opacity', 0))
      .call(this.zoom);

    this.y = d3.scaleBand().range([0, eventHeight]).padding(0.1);

    this.yRecorded = d3.scaleBand().range([0, recordedEventsHeight]).padding(0.1);

    this.xAxisScale = d3.axisTop(this.x);
    const { isUS, timeFormatLocale } = this.props;

    if (this.axisType === 'time') {
      this.xAxisScale.tickFormat(date => dateMultiFormat(date, isUS, timeFormatLocale));
    }

    this.yAxisScale = d3
      .axisRight(this.y)
      .tickPadding(20)
      .tickSize(-this.props.width)
      .tickFormat(d => d + ': ' + getModuleMessage(d, formatMessage, self.props.isiABS));

    this.yRecordedAxisScale = d3.axisRight(this.yRecorded).tickPadding(20).tickSize(-this.props.width);

    const xDomain = d3.extent((this.dtc || []).concat(this.trips).concat(this.recordedEvents), d =>
      this.axisType === 'time' ? d.timestamp && moment(d.timestamp).local().toDate() : d.mileage
    );
    this.x.domain(xDomain);

    this.y.domain((this.dtc || []).map(d => d.errorCode).reverse());
    this.yRecorded.domain(
      this.recordedEvents
        .map(d => (this.props.strings[d.eventNameId] ? this.props.strings[d.eventNameId] : d.eventNameId))
        .reverse()
    );

    this.xAxis.attr('transform', `translate(0,-10)`).call(this.xAxisScale);

    this.yAxis
      .attr('transform', `translate(${this.props.width}, ${recordedEventsHeight + tripHeight * 2})`)
      .call(this.yAxisScale);

    this.yRecordedAxis
      .attr('transform', `translate(${this.props.width}, ${tripHeight * 1.5})`)
      .call(this.yRecordedAxisScale);

    if (!this.dtc || this.dtc.length === 0) this.toggleExpansionDTC(this);

    function mouseMove() {
      const circle = d3.select(this);
      const circleXPos = circle.attr('cx');
      const circleYPos = circle.attr('cy');
      const circleClass = circle.attr('class');
      const w = document.documentElement.clientWidth;
      const h = document.documentElement.clientHeight;

      self.tooltip
        .style('right', d3.event.pageX > w / 2 ? `calc(100vw - ${d3.event.pageX - 10}px)` : 'auto')
        .style('left', d3.event.pageX <= w / 2 ? `${d3.event.pageX + 10}px` : 'auto')
        .style(
          'bottom',
          (circleClass === 'dtc' || circleYPos > 100) && d3.event.pageY > h / 2
            ? `calc(100vh - ${d3.event.pageY - 10}px)`
            : 'auto'
        )
        .style(
          'top',
          !(circleClass === 'dtc' || circleYPos > 100) || d3.event.pageY <= h / 2
            ? `calc(${d3.event.pageY + 10}px)`
            : 'auto'
        );

      self.rule.attr('x1', circleXPos).attr('x2', circleXPos);
    }

    this.recordedEventsView
      .attr('transform', `translate(0,${tripHeight + this.yRecorded.bandwidth() / 2 + 1})`)
      .selectAll('.recordedEvent')
      .data(this.recordedEvents)
      .enter()
      .append('circle')
      .attr('class', 'recordedEvent')
      .style('fill', 'grey')
      .style('stroke', 'white')
      .style('stroke-width', 2)
      .attr('cx', d => {
        if (this.axisType === 'time') {
          return this.x(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
        }
        return (d.mileage || d.mileage === 0) && !isNaN(d.mileage) ? this.x(d.mileage) : 0;
      })
      .attr(
        'cy',
        d =>
          this.yRecorded(this.props.strings[d.eventNameId] ? this.props.strings[d.eventNameId] : d.eventNameId) +
          this.yRecorded.bandwidth() / 2
      )
      //.attr('cy', tripHeight * 0.75)
      .attr('r', 7)
      .attr('opacity', 0.8)
      .on('mouseenter', function mouseEnter(d) {
        const elem = d3.select(this);
        elem.style('stroke', '#3a3a48').style('stroke-width', 2.5).style('cursor', 'pointer');
        self.tooltip.style('opacity', 1);
        self.tooltip.html(`
          <h3><strong>${
            self.props.strings[d.eventNameId] ? self.props.strings[d.eventNameId] : d.eventNameId
          }</strong>    ${self.props.strings.id}: ${d.eventId} </h3>
          ${
            d.GPSPosition_latitude && d.GPSPosition_longitude
              ? ''
              : `<strong className="critical">${self.props.strings.noGps}</strong>`
          }
          <div><strong>${
            self.props.strings.mileage
          }</strong>: ${d.mileage} ${self.props.isMetric.distance ? self.props.strings.km : self.props.strings.mi}</div>
          <div><strong>${
            self.props.strings.time
          }</strong>: ${d.timestamp ? getTimeDate(d.timestamp) : self.props.strings.unknown}</div>
          ${
            !self.props.isiABS || d.AxleLoadSum
              ? `<div><strong>${self.props.strings.axleLoad}</strong>: ${d.AxleLoadSum || '0.0'}${
                  !self.props.isMetric.weight && self.props.isUS
                    ? self.props.strings.kiloPoundUSA
                    : self.props.strings.ton
                }</div>`
              : ''
          }
          <div><strong>${
            self.props.strings.speed
          }</strong>: ${d.VehicleSpeed} ${self.props.isMetric.distance ? self.props.strings.kph : self.props.strings.mph}</div>
          <div class='read-more-text'>${formatMessage(strings.phrase.clickTheEventToReadMore)}</div>
          `);
      })
      .on('mousemove', mouseMove)
      .on('mouseleave', function mouseLeave() {
        d3.select(this).style('stroke', 'white').style('stroke-width', 2).style('cursor', 'default');
        self.tooltip.style('opacity', 0);
      })
      .on('click', self.selectEvent);

    this.dtcView
      .attr('transform', `translate(0,${recordedEventsHeight + tripHeight * 2})`)
      .selectAll('.dtc')
      .data(this.dtc || [])
      .enter()
      .append('circle')
      .attr('class', 'dtc')
      .style('fill', d => this.priorityColor(d.priority))
      .style('stroke', 'white')
      .style('stroke-width', 2)
      .attr('cx', d => {
        if (this.axisType === 'time') {
          return this.x(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
        }
        return this.x(d.mileage);
      })
      .attr('cy', d => this.y(d.errorCode) + this.y.bandwidth() / 2)
      .attr('r', 7)
      .attr('opacity', 0.8)
      .on('mouseenter', function mouseEnter(d) {
        const elem = d3.select(this);
        elem.style('stroke', '#3a3a48').style('stroke-width', 2.5).style('cursor', 'pointer');
        self.tooltip.style('opacity', 1);
        self.tooltip.html(`
          ${`<h3><strong>${getModuleMessage(d.errorCode, formatMessage, self.props.isiABS)}</strong></h3>`}
          ${
            d.GPSPosition_latitude && d.GPSPosition_longitude
              ? ''
              : `<strong className="critical">${self.props.strings.noGps}</strong>`
          }
          <div><strong>${
            self.props.strings.mileage
          }</strong>:${d.mileage} ${self.props.isMetric.distance ? self.props.strings.km : self.props.strings.mi}</div>
          <div><strong>${
            self.props.strings.time
          }</strong>: ${d.timestamp ? getTimeDate(d.timestamp) : self.props.strings.unknown}</div>
          ${
            !self.props.isiABS || d.AxleLoadSum
              ? `<div><strong>${self.props.strings.axleLoad}</strong>: ${numeral(d.AxleLoadSum).format('0,0.0')}${
                  !self.props.isMetric.weight && self.props.isUS
                    ? self.props.strings.kiloPoundUSA
                    : self.props.strings.ton
                }</div>`
              : ''
          }
          <div><strong>${
            self.props.strings.speed
          }</strong>: ${d.VehicleSpeed} ${self.props.isMetric.distance ? self.props.strings.kph : self.props.strings.mph}</div>
          <br/>
          <div><strong>${self.props.strings.errorCode}</strong>: ${d.errorCode}</div>
          <div><strong>${
            self.props.strings.module
          }</strong>: ${getModuleMessage(d.errorCode, formatMessage, self.props.isiABS)}</div>
          <div><strong>${
            self.props.strings.issue
          }</strong>: ${getDescriptionMessage(d.errorCode, formatMessage, self.props.isiABS)}</div>
          <div class='read-more-text'>${formatMessage(strings.phrase.clickTheEventToReadMore)}</div>
        `);
      })
      .on('mousemove', mouseMove)
      .on('mouseleave', function mouseLeave() {
        d3.select(this).style('stroke', 'white').style('stroke-width', 2).style('cursor', 'default');
        self.tooltip.style('opacity', 0);
      })
      .on('click', self.selectEvent);

    this.yAxis
      .selectAll('text')
      .on('click', d => d !== 'none' && self.onErrorTypeClicked(d))
      .on('mouseenter', function mouseEnter(d) {
        if (d !== 'none') {
          self.tooltip.style('opacity', 1);
          self.tooltip.html(`
            <h3>${self.props.strings.errorCode}: <strong>${d}</strong></h3>
            <div><strong>${self.props.strings.module}</strong>: ${getModuleMessage(
            d,
            formatMessage,
            self.props.isiABS
          )}</div>
            <div><strong>${self.props.strings.issue}</strong>: ${getDescriptionMessage(
            d,
            formatMessage,
            self.props.isiABS
          )}</div>
            <div style='float: right; padding-top: 5px;'>${formatMessage(strings.phrase.clickToReadMore)}</div>
        `);
        } else {
          const elem = d3.select(this);
          elem.style('cursor', 'default');
        }
      })
      .on('mousemove', mouseMove)
      .on('mouseleave', function mouseLeave() {
        self.tooltip.style('opacity', 0);
      });

    this.tripView
      .selectAll('.trip')
      .data(this.trips)
      .enter()
      .append('rect')
      .attr('class', 'trip')
      .style('fill', d => color(d[this.metric]))
      .attr('x', d => {
        if (this.axisType === 'time') {
          return this.x(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
        }
        return (d.mileage || d.mileage === 0) && !isNaN(d.mileage) ? this.x(d.mileage) : 0;
      })
      .attr('y', 0)
      .attr('width', (d, i) => {
        if (this.axisType === 'time') {
          return Math.max(1, this.x(d.duration * 60000) - this.x(0));
        }
        if (i !== this.trips.length - 1) {
          return d.mileage && !isNaN(d.mileage) && d.nextTripMileage && !isNaN(d.nextTripMileage)
            ? Math.max(1, this.x(d.nextTripMileage) - this.x(d.mileage) - 1)
            : 0;
        }
        return (d.mileage || d.mileage === 0) && !isNaN(d.mileage)
          ? Math.max(1, this.x(this.x.domain()[1]) - this.x(d.mileage) - 1)
          : 0;
      })
      .attr('height', tripHeight - 5)
      .attr('opacity', 0.8)
      .style('cursor', d => (d.timestamp && self.trailer.assetId ? 'pointer' : ''))
      .on('mouseenter', function mouseEnter(d) {
        const elem = d3.select(this);
        elem.style('stroke', '#3a3a48').style('stroke-width', 2);
        self.tooltip.style('opacity', 1);
        self.tooltip.html(`
          <h3>${self.props.strings.trip}</h3>
          <div style="width: 300px;">
            <div style="width: 50%; float: left;">
              <div><strong>${
                self.props.strings.date
              }</strong>: ${d.timestamp ? getDate(d.timestamp) : '<span className="critical"}>' + self.props.strings.unknown + '</span>'}</div>
              <div><strong>${
                self.props.strings.mileage
              }</strong>: ${d.mileage} ${self.props.isMetric.distance ? self.props.strings.km : self.props.strings.mi}</div>
              <div><strong>${
                self.props.strings.maxSpeed
              }</strong>: ${d.maxSpeed} ${self.props.isMetric.distance ? self.props.strings.kph : self.props.strings.mph}</div>
              <div><strong>${self.props.strings.duration}</strong>: ${numeral(d.duration * 60).format('00:00:00')}</div>
              <div><strong>${
                self.props.strings.distance
              }</strong>: ${d.distance} ${self.props.isMetric.distance ? self.props.strings.km : self.props.strings.mi}</div>
            </div>
            <div style="width: 50%; float: right;">
              ${
                !self.props.isiABS || d.maxAxleLoad
                  ? `<div><strong>${self.props.strings.maxAxleLoad}</strong>: ${numeral(d.maxAxleLoad).format(
                      '0,0.[00]'
                    )}${
                      !self.props.isMetric.weight && self.props.isUS
                        ? self.props.strings.kiloPoundUSA
                        : self.props.strings.ton
                    }</div>`
                  : ''
              }
              ${
                !self.props.isiABS || d.minAxleLoad
                  ? `<div><strong>${self.props.strings.minAxleLoad}</strong>: ${numeral(d.minAxleLoad).format(
                      '0,0.[00]'
                    )}${
                      !self.props.isMetric.weight && self.props.isUS
                        ? self.props.strings.kiloPoundUSA
                        : self.props.strings.ton
                    }</div>`
                  : ''
              }
              ${
                !self.props.isiABS || d.avgAxleLoad
                  ? `<div><strong>${self.props.strings.avgAxleLoad}</strong>: ${numeral(d.avgAxleLoad).format(
                      '0,0.[00]'
                    )}${
                      !self.props.isMetric.weight && self.props.isUS
                        ? self.props.strings.kiloPoundUSA
                        : self.props.strings.ton
                    }</div>`
                  : ''
              }
              <div><strong>${self.props.strings.brakeCount}</strong>: ${numeral(d.brakeCount).format('0,0.[00]')}</div>
              <div><strong>${self.props.strings.ABSevents}</strong>: ${numeral(d.absCount).format('0,0.[00]')}</div>
              <div><strong>${
                self.props.strings.latAcceleration
              }</strong>: ${numeral(d.latAcceleration / 100).format('0,0.[00]')}g</div>
            </div>
          </div>
        `);
        self.onTripHover(d);
      })
      .on('mousemove', mouseMove)
      .on('mouseleave', function mouseLeave() {
        d3.select(this).style('stroke-width', 0);
        self.tooltip.style('opacity', 0);
        self.onTripHover(null);
      })
      .on('click', function (d) {
        if (d.timestamp && self.trailer.assetId) self.selectTrip(d);
      });

    this.rule
      .attr('x1', 0)
      .attr('y1', tripHeight + 4)
      .attr('x2', 0)
      .attr('y2', this.props.height);

    if (this.axisType === 'time') {
      // Initial zoom limited at most to the last year
      const years = 1;
      const subDomain = [Math.max(xDomain[0], moment.utc(xDomain[1]).subtract(years, 'year').toDate()), xDomain[1]];
      this.zoom.transform(
        this.zoomBackground,
        d3.zoomIdentity
          .scale(this.props.width / (this.x(subDomain[1]) - this.x(subDomain[0])))
          .translate(-this.x(subDomain[0]), 0)
      );
    } else {
      // Initial zoom at the last 5000 miles
      const lastMileageAmount = 5000;
      const subDomain = [Math.max(0, xDomain[1] - lastMileageAmount), xDomain[1]];
      this.zoom.transform(
        this.zoomBackground,
        d3.zoomIdentity
          .scale(this.props.width / (this.x(subDomain[1]) - this.x(subDomain[0])))
          .translate(-this.x(subDomain[0]), 0)
      );
    }

    this.resizeSVG(this);
  }

  zoomed = () => {
    this.zoomHighlight.style('opacity', 1);

    const t = d3.event.transform;
    if (d3.event.sourceEvent) {
      if (
        Math.abs(this.xAxisScale.scale()(this.x.domain()[0])) < 0.0001 &&
        d3.event.sourceEvent.offsetX < this.props.width / 6
      ) {
        // just at the left border
        this.zoomHighlight.attr('x', 0).attr('width', this.props.width / 6);
        t.x = 0;
      } else if (
        Math.abs(this.xAxisScale.scale()(this.x.domain()[1]) - this.props.width) < 0.0001 &&
        d3.event.sourceEvent.offsetX > (5 * this.props.width) / 6
      ) {
        // just at the rigth border
        this.zoomHighlight.attr('x', (5 * this.props.width) / 6).attr('width', this.props.width / 6);
        t.x = -this.props.width * (t.k - 1);
      } else {
        this.zoomHighlight.attr('x', this.props.width / 6).attr('width', (4 * this.props.width) / 6);
      }
    }
    const newScale = t.rescaleX(this.x);
    this.recordedEventsView.selectAll('.recordedEvent').attr('cx', d => {
      if (this.axisType === 'time') {
        return newScale(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
      }
      return d.mileage ? newScale(d.mileage) : 0;
    });
    this.dtcView.selectAll('.dtc').attr('cx', d => {
      if (this.axisType === 'time') {
        return newScale(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
      }
      return d.mileage ? newScale(d.mileage) : 0;
    });
    this.tripView
      .selectAll('.trip')
      .attr('x', d => {
        if (this.axisType === 'time') {
          return newScale(d.timestamp ? moment(d.timestamp).local().toDate() : 0);
        }
        return d.mileage ? newScale(d.mileage) : 0;
      })
      .attr('width', (d, i) => {
        if (this.axisType === 'time') {
          return Math.max(1, newScale(d.duration * 60000) - newScale(0));
        }
        if (i !== this.trips.length - 1) {
          return d.mileage && d.nextTripMileage
            ? Math.max(1, newScale(d.nextTripMileage) - newScale(d.mileage) - 1)
            : 0;
        }
        return d.mileage ? Math.max(1, newScale(newScale.domain()[1]) - newScale(d.mileage) - 1) : 0;
      });
    //.attr('x', d => newScale(d.mileage))
    //.attr('width', (d, i) => (i !== this.trips.length - 1 ? Math.max(1, newScale(d.nextTripMileage) - newScale(d.mileage) - 1) : Math.max(1, newScale(this.x.domain()[1]) - newScale(d.mileage) - 1)))
    this.xAxis.call(this.xAxisScale.scale(newScale));
  };

  zoomEnd = () => {
    this.zoomHighlight.style('opacity', 0);
  };

  priorityColor = priority => {
    switch (priority) {
      case 1:
        return '#ffc107';
      case 2:
        return 'tomato';
      default:
        return 'dodgerblue';
    }
  };

  drawLabels = (tripHeight, recordedEventsHeight) => {
    const self = this;
    this.labels.selectAll('*').remove();
    this.labels
      .append('text')
      .attr('x', this.props.width + 20)
      .attr('y', -(tripHeight * 0.5))
      .attr('text-anchor', 'start')
      .attr('font-size', 10)
      .attr('fill', 'grey')
      .text(this.axisType === 'time' ? self.props.strings.time : self.props.strings.mileage);

    this.labels
      .append('text')
      .attr('x', this.props.width + 20)
      .attr('y', tripHeight * 0.5)
      .attr('text-anchor', 'start')
      .attr('font-size', 10)
      .attr('fill', 'grey')
      .text(this.props.strings.trips);

    this.labels
      .append('text')
      .attr('class', 'recorded-event-label')
      .attr('x', this.props.width + 8)
      .attr('y', tripHeight * 1.3)
      .attr('text-anchor', 'start')
      .attr('font-size', 10)
      .attr('fill', 'grey')
      .attr('cursor', 'pointer')
      .text(`${this.flatRecorded ? '\u25BA' : '\u25BC'} ${this.props.strings.recordedEvents}`)
      .on('click', () =>
        self.toggleExpansionRecorded(this, recordedEventsHeight - 2 + tripHeight * 2, tripHeight * 2.85)
      );

    this.labels
      .append('text')
      .attr('class', 'dtc-label')
      .attr('id', 'dtc-label')
      .attr('x', this.props.width + 8)
      .attr('y', recordedEventsHeight - 2 + tripHeight * 2)
      .attr('text-anchor', 'start')
      .attr('font-size', 10)
      .attr('fill', 'grey')
      .attr('cursor', 'pointer')
      .text(`${!this.dtc ? '' : this.flatDTC ? '\u25BA' : '\u25BC'} ${this.props.strings.diagnosticModulesEvents}`)
      .on('click', () => self.toggleExpansionDTC(this));
  };

  toggleExpansionRecorded = (self, yRecordOpen, yRecordClosed) => {
    if (!self.flatRecorded) {
      self.flatRecorded = true;
      const flatScale = self.yRecorded.copy().domain(['none']).range([0, 36]);
      const flatYAxis = d3
        .axisRight(flatScale)
        .tickPadding(20)
        .tickSize(-self.props.width)
        .tickFormat(self.props.strings.allRecordedEvents);
      this.yRecordedAxis.transition().call(flatYAxis);

      this.recordedEventsView
        .selectAll('.recordedEvent')
        .transition()
        .duration(500)
        .attr('cy', flatScale('none') + flatScale.bandwidth() / 2);
      self.dtcView
        .transition()
        .duration(500)
        .attr('transform', `translate(0,${36 * 3})`);
      self.labels
        .select('.dtcLabel')
        .transition()
        .duration(500)
        .attr('y', 36 * 3);
      self.yAxis
        .transition()
        .duration(500)
        .attr('transform', `translate(${this.props.width},${36 * 3})`);

      d3.select('#dtc-label').node().setAttribute('y', yRecordClosed);
    } else {
      self.flatRecorded = false;
      self.yRecordedAxis.transition().call(self.yRecordedAxisScale);
      this.recordedEventsView
        .selectAll('.recordedEvent')
        .transition()
        .duration(500)
        .attr(
          'cy',
          d =>
            this.yRecorded(this.props.strings[d.eventNameId] ? this.props.strings[d.eventNameId] : d.eventNameId) +
            this.yRecorded.bandwidth() / 2
        );
      self.dtcView
        .transition()
        .duration(500)
        .attr('transform', `translate(0,${36 * (self.yRecorded.domain().length + 2)})`);
      self.labels
        .select('.dtcLabel')
        .transition()
        .duration(500)
        .attr('y', 36 * (self.yRecorded.domain().length + 2));
      self.yAxis
        .transition()
        .duration(500)
        .attr('transform', `translate(${this.props.width},${36 * (self.yRecorded.domain().length + 2)})`);

      d3.select('#dtc-label').node().setAttribute('y', yRecordOpen);
    }
    self.labels
      .select('.recordedEventLabel')
      .text(`${self.flatRecorded ? '\u25BA' : '\u25BC'} ${self.props.strings.recordedEvents}`);
    self.resizeSVG(self);
  };

  toggleExpansionDTC = self => {
    if (!self.flatDTC || !self.dtc || self.dtc.length === 0) {
      self.flatDTC = true;
      const flatScale = self.y.copy().domain(['none']).range([0, 36]);
      const flatYAxis = d3
        .axisRight(flatScale)
        .tickPadding(20)
        .tickSize(-self.props.width)
        .tickFormat(self.props.strings.allRecordedEvents);
      this.yAxis.transition().call(flatYAxis);

      this.dtcView
        .selectAll('.dtc')
        .transition()
        .duration(500)
        .attr('cy', flatScale('none') + flatScale.bandwidth() / 2);
    } else {
      self.flatDTC = false;
      self.yAxis.transition().call(self.yAxisScale);
      this.dtcView
        .selectAll('.dtc')
        .transition()
        .duration(500)
        .attr('cy', d => this.y(d.errorCode) + this.y.bandwidth() / 2);
    }
    self.labels
      .select('.dtcLabel')
      .text(`${!self.dtc ? '' : self.flatDTC ? '\u25BA' : '\u25BC'} ${this.props.strings.diagnosticModulesEvents}`);
    self.resizeSVG(self);
  };

  resizeSVG = self => {
    const tripHeight = 36;
    const recordedEventsHeight = (self.flatRecorded ? 1 : self.yRecorded.domain().length) * tripHeight;
    const eventHeight = (self.flatDTC ? 2 : self.y.domain().length + 1) * tripHeight;
    const totalHeight = recordedEventsHeight + tripHeight + eventHeight;

    d3.select(self.svg.node().parentNode)
      .transition()
      .duration(500)
      .attr('height', totalHeight + (self.props.margin.top + self.props.margin.bottom));

    self.zoomHighlight.attr('height', totalHeight);
    self.rule.attr('y2', totalHeight);
  };
}
