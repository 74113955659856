import { defineMessages } from 'react-intl';

export const geofence = defineMessages({
  industrialZone: {
    id: 'geofence.industrialZone',
    defaultMessage: 'Industrial zone',
    description: ''
  },
  customerSite: {
    id: 'geofence.customerSite',
    defaultMessage: 'Customer site',
    description: ''
  },
  driverHomeAddress: {
    id: 'geofence.driverHomeAddress',
    defaultMessage: 'Driver home address',
    description: ''
  },
  petrolStation: {
    id: 'geofence.petrolStation',
    defaultMessage: 'Petrol station',
    description: ''
  },
  secureParking: {
    id: 'geofence.secureParking',
    defaultMessage: 'Secure parking',
    description: ''
  },
  restArea: {
    id: 'geofence.restArea',
    defaultMessage: 'Rest area',
    description: ''
  },
  openParkingArea: {
    id: 'geofence.openParkingArea',
    defaultMessage: 'Open parking area',
    description: ''
  },
  maintenancePoint: {
    id: 'geofence.maintenancePoint',
    defaultMessage: 'Maintenance / Service point',
    description: ''
  },
  blackSpot: {
    id: 'geofence.blackSpot',
    defaultMessage: 'Black spot / Dangerous area',
    description: ''
  },
  ferryTerminal: {
    id: 'geofence.ferryTerminal',
    defaultMessage: 'Ferry terminal',
    description: ''
  },
  other: {
    id: 'geofence.other',
    defaultMessage: 'Other',
    description: ''
  },
  enterDescription: {
    id: 'geofence.enterDescription',
    defaultMessage: 'Place event occurred. Trailer entering {VAL_NAME}.',
    description: 'Do not translate {VAL_NAME} it is a variable'
  },
  leavingDescription: {
    id: 'geofence.leavingDescription',
    defaultMessage: 'Place event occurred. Trailer leaving {VAL_NAME}.',
    description: 'Do not translate {VAL_NAME} it is a variable'
  },
  officeAndIndustrial: {
    id: 'geofence.officeAndIndustrial',
    defaultMessage: 'Office and industrial',
    description: ''
  },
  parking: {
    id: 'geofence.parking',
    defaultMessage: 'Parking',
    description: ''
  },
  services: {
    id: 'geofence.services',
    defaultMessage: 'Services',
    description: ''
  },
  transport: {
    id: 'geofence.transport',
    defaultMessage: 'Transport',
    description: ''
  }
});
