import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import TrailerHistorySparkLine from './TrailerHistorySparkLine';
import VizSensor from 'react-visibility-sensor';

class TrailerHistorySparkLineComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.chartRef = React.createRef();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
      isVisible: true
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentDidUpdate() {
    const { chart, isVisible } = this.state;
    if (chart && chart.areas && !document.hidden && isVisible) {
      chart.update(this.props);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = this.chartRef.current;
    const { chart } = this.state;
    const { formatMessage } = this.props.intl;
    if (chart) {
      chart.destroy();
    }

    const margin = {
      top: 2,
      right: 2,
      bottom: 2,
      left: 2
    };
    const { height, width } = this.props;
    const elWidth = width || el.offsetWidth;
    const elHeight = height || 50;

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      strings: {
        axleLoad: formatMessage(strings.short.axleLoad),
        speed: formatMessage(strings.short.speed)
      }
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new TrailerHistorySparkLine(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(this.props);
        this.state.chart.preventTransitions();
      }
    );
  }
  updateIfVisible(isVisible) {
    const { chart } = this.state;
    if (isVisible) {
      chart.update(this.props);
    }
    this.setState({ isVisible });
  }

  render() {
    return (
      <VizSensor
        partialVisibility={true}
        onChange={isVisible => {
          this.updateIfVisible(isVisible);
        }}
      >
        <div ref={this.chartRef} />
      </VizSensor>
    );
  }
}

TrailerHistorySparkLineComponent.propTypes = {};

export default injectIntl(TrailerHistorySparkLineComponent);
