import React from 'react';
import { GiftOutlined, ReloadOutlined, SettingOutlined, ToTopOutlined, WifiOutlined } from '@ant-design/icons';
import { Row, Col, List, Avatar } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
//import ObjectInspector from 'react-object-inspector'
import { getTimeDate } from '../../helpers/functions';
import { convert, getConversionString, unitType } from '../../helpers/unitConverter';
import tools from '../../actions/ActionResources/helperTools';
import axleImg from '../../assets/images/axle.png';
import LocaleString from '../Utils/LocaleString';
import Tire from '../Utils/Tire';

const { Description } = DescriptionList;

const getSensorLabel = sensor => {
  if (sensor === 1) {
    return 'C-D';
  }
  if (sensor === 2) {
    return 'E-F';
  }
  return <LocaleString type='short' id='none' />;
};

const AxleView = ({ trailer, trailerCurrent, isMetric, formatMessage }) => {
  let tires;
  let twinTyres;
  if (trailerCurrent) {
    tires = tools.getTPMS(trailerCurrent, trailer);
  } else {
    tires = trailer.tires;
  }
  if (trailer.tyreChoice === 'twinTyres') {
    twinTyres = true;
  } else if (tires) {
    twinTyres = tires.some(t => t.location && t.location.endsWith('2'));
  }

  return (
    <Row type='flex'>
      {trailer.axles && trailer.axles.details && (
        <Col span={24}>
          <h2 className='axle-details-header'>
            <LocaleString type='short' id='axleDetails' />
          </h2>
          {trailer.ebsTime && (
            <LocaleString type='short' id='ebsTireTimeVal' val={getTimeDate(trailer.ebsTime * 1000)} />
          )}
          <List
            itemLayout='horizontal'
            dataSource={trailer.axles.details}
            renderItem={(axle, i) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={axleImg} />}
                  title={
                    <div>
                      <LocaleString type='short' id='axleNum' val={i + 1} />
                    </div>
                  }
                  description={
                    <div>
                      <DescriptionList size='small' col='2'>
                        <Description
                          term={
                            <span>
                              <GiftOutlined /> <LocaleString type='short' id='load' />
                            </span>
                          }
                        >
                          {convert(isMetric, unitType.weight, axle.Axle_load_unladen)} &ndash;{' '}
                          {convert(isMetric, unitType.weight, axle.Axle_load_laden)}{' '}
                          {isMetric.weight ? (
                            <LocaleString type='abbrev' id='kilogram' />
                          ) : (
                            <LocaleString type='abbrev' id='pound' />
                          )}
                        </Description>
                        <Description
                          term={
                            <span>
                              <ToTopOutlined /> <LocaleString type='short' id='lift' />
                            </span>
                          }
                        >
                          {axle.Lift_axle ? (
                            <LocaleString type='short' id='yes' />
                          ) : (
                            <LocaleString type='short' id='no' />
                          )}
                        </Description>
                        <Description
                          term={
                            <span>
                              <WifiOutlined /> <LocaleString type='short' id='wheelSpeedSensor' />
                            </span>
                          }
                        >
                          {getSensorLabel(axle.Sensor)}
                        </Description>
                        {axle.numberOfTeeth && (
                          <Description
                            term={
                              <span>
                                <SettingOutlined /> <LocaleString type='short' id='teeth' />
                              </span>
                            }
                          >
                            {axle.numberOfTeeth}
                          </Description>
                        )}
                        {axle.tyreCircumference && (
                          <Description
                            term={
                              <span>
                                <ReloadOutlined /> <LocaleString type='short' id='circumference' />
                              </span>
                            }
                          >
                            {formatMessage(...getConversionString(isMetric, axle.tyreCircumference, unitType.mmInch))}
                          </Description>
                        )}
                      </DescriptionList>
                      {tires && tires.length > 0 && (
                        <div>
                          <h4 style={{ color: '#000000a6' }}>Tires</h4>
                          <Row>
                            <Col span={12}>
                              <Row type='flex'>
                                {twinTyres && (
                                  <Tire
                                    data={tires ? tires.find(t => t.location === `${i + 1}L2`) : null}
                                    isMetric={isMetric}
                                  />
                                )}
                                <Tire
                                  data={tires ? tires.find(t => t.location === `${i + 1}L1`) : null}
                                  isMetric={isMetric}
                                />
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row type='flex'>
                                <Tire
                                  data={tires ? tires.find(t => t.location === `${i + 1}R1`) : null}
                                  isMetric={isMetric}
                                />
                                {twinTyres && (
                                  <Tire
                                    data={tires ? tires.find(t => t.location === `${i + 1}R2`) : null}
                                    isMetric={isMetric}
                                  />
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      )}
    </Row>
  );
};

export default AxleView;
