import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { selectionHeaders } from '../../../../../../helpers/constants';

const Cell = ({ rowData }) => {
  if (rowData.visibility) {
    return <EyeTwoTone />;
  }
  return <EyeInvisibleOutlined />;
};

const VisibilityColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    trailers.filter('visibility', t => checkedCategories[t.visibility]);
  };

  return {
    title: <EyeTwoTone />,
    searchKey: formatMessage(strings.short.notVisible) + formatMessage(strings.short.visible),
    key: 'visibility',
    dataIndex: 'visibility',
    selectionSection: selectionHeaders.map.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () => trailers.sort('visibility', (a, b) => (a.visibility < b.visibility ? -1 : 1)),
    showSorterTooltip: false,
    categories: [
      {
        text: formatMessage(strings.short.visible),
        value: true
      },
      {
        text: formatMessage(strings.short.notVisible),
        value: false
      }
    ],
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('visibility'),
    width: 180
  };
};

export default { table: VisibilityColumn, csv: null };
