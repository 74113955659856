import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Row, Col, Select } from 'antd';
import TitleSubTitle from '../subComponents/TitleSubTitle';
import { useIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import { reportVariables } from '../../../helpers/constants';
import TimeDropdown from '../subComponents/TimeDropdown';
const { Option } = Select;

const MileageSubscriptionSection = ({ subscription, onChangeSubscription }) => {
  const userEmail = useSelector(store => store.auth?.user?.emails?.[0]);
  const isSubscribed = subscription.email;
  const formatMessage = useIntl().formatMessage;

  const onTimeChange = value => {
    onChangeSubscription([{ key: 'emissionTime', value }]);
  };

  const onDateChange = value => {
    onChangeSubscription([{ key: 'emissionDate', value }]);
  };

  return (
    <>
      <Row className='large-margin-bottom'>
        <Col>
          <TitleSubTitle
            title={formatMessage(strings.short.subscription)}
            subtitle={formatMessage(strings.phrase.editsApplyToCurrentUser, { value: userEmail })}
          />
        </Col>
      </Row>
      <Row className='large-margin-bottom large-margin-top'>
        <Checkbox
          checked={isSubscribed}
          onChange={() => onChangeSubscription([{ key: 'email', value: !subscription.email }])}
        >
          {formatMessage(strings.phrase.subscribeToEmail)}
        </Checkbox>
      </Row>
      <Row align='middle' gutter={8} className='large-margin-top'>
        <Col>{formatMessage(strings.reports.monthly)}</Col>
        <Col span={10}>
          <Select
            disabled={!isSubscribed}
            onSelect={onDateChange}
            value={subscription.emissionDate}
            className='full-width'
          >
            {reportVariables.monthlyMileageReport.subscriptionFrequencyDropDown.map(({ value, stringKey }) => (
              <Option key={`frequency-dropdown-${value}`} value={value}>
                {formatMessage(stringKey)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>{formatMessage(strings.short.at)}</Col>
        <Col>
          <TimeDropdown disabled={!isSubscribed} value={subscription.emissionTime} onChange={onTimeChange} />
        </Col>
      </Row>
    </>
  );
};

export default MileageSubscriptionSection;
