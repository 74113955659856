import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const ImeiColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.abbrev.internationalMobileEquipmentIdentity)}>
        {formatMessage(strings.abbrev.internationalMobileEquipmentIdentity)}
      </Tooltip>
    ),
    key: 'imei',
    dataIndex: 'imei',
    searchKey: formatMessage(strings.abbrev.internationalMobileEquipmentIdentity),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <p>{rowData.imei}</p>,
    search: searchString =>
      trailers.filter('imeiSearch', t => t.imei.toString().toLowerCase().includes(searchString.toLowerCase())),
    removeSearch: () => trailers.removeFilter('imeiSearch'),
    width: 150
  };
};

const ImeiCSVColumnHeader = formatMessage => formatMessage(strings.abbrev.internationalMobileEquipmentIdentity);
const ImeiCSVData = trailer => trailer.imei;
const ImeiCSVColumn = {
  header: ImeiCSVColumnHeader,
  data: ImeiCSVData
};

export default { table: ImeiColumn, csv: ImeiCSVColumn };
