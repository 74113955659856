import React from 'react';
import { Select } from 'antd';
import LocaleString from '../../../../../../Utils/LocaleString';

const SelectHours = ({ selected, onSelect }) => {
  return (
    <div className={'hours-select'}>
      <Select placeholder='Select a timeframe' value={selected} onChange={onSelect}>
        <Select.Option value={2}>
          <LocaleString type='short' id={'last2h'} />
        </Select.Option>
        <Select.Option value={6}>
          <LocaleString type='short' id={'last6h'} />
        </Select.Option>
        <Select.Option value={12}>
          <LocaleString type='short' id={'last12h'} />
        </Select.Option>
        <Select.Option value={24}>
          <LocaleString type='short' id={'last24h'} />
        </Select.Option>
        <Select.Option value={48}>
          <LocaleString type='short' id={'last48h'} />
        </Select.Option>
      </Select>
    </div>
  );
};

export default SelectHours;
