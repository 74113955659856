export const downloadFile = (csvData, filename) => {
  let a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  let blob = new Blob([csvData], { type: 'text/csv' });
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
};
