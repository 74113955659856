import React, { useState } from 'react';
import { Drawer, Divider, Row, Button, Col } from 'antd';
import { useIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import MileageSubscriptionSection from './MileageSubscriptionSection';
import MileageGeneralSection from './MileageGeneralSection';
import { reportType } from '../../../helpers/constants';

const MileageDetailsDrawer = ({ isOpen, setOpenDrawer, onClickOk, getSubscription }) => {
  const [changedSubscriptions, setChangedSubscriptions] = useState({});
  const formatMessage = useIntl().formatMessage;

  const onChangeSubscription = subscriptions => {
    let newSubscription = Object.assign({}, changedSubscriptions);
    subscriptions.forEach(({ key, value }) => {
      newSubscription[key] = value;
    });
    setChangedSubscriptions(newSubscription);
  };

  const onCancel = () => {
    setChangedSubscriptions({});
    setOpenDrawer('');
  };

  return (
    <Drawer
      title={formatMessage(strings.reports.monthlyMileage)}
      placement='right'
      onClose={onCancel}
      visible={isOpen}
      width='30%'
      footer={
        <Row justify={'end'} gutter={8}>
          <Col>
            <Button onClick={onCancel}>{formatMessage(strings.button.cancel)}</Button>
          </Col>
          <Col>
            <Button
              type='primary'
              onClick={() => onClickOk({ key: reportType.monthlyMileageReport, changedSubscriptions })}
            >
              {formatMessage(strings.short.ok)}
            </Button>
          </Col>
        </Row>
      }
    >
      <MileageGeneralSection />
      <Divider />
      <MileageSubscriptionSection
        subscription={getSubscription(reportType.monthlyMileageReport, changedSubscriptions)}
        onChangeSubscription={onChangeSubscription}
      />
    </Drawer>
  );
};

export default MileageDetailsDrawer;
