import { Logger } from './logger';

/**
 *
 * PageAction events are sent every 30 seconds, with a maximum of 120 events
 * per 30-second harvest cycle, per browser.
 *
 * After the 120-event limit is reached, additional events are not captured for that harvest cycle.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action/#description
 */
export const sendCustomEvent = (name, attributes, { customEvent = window.newrelic?.addPageAction } = {}) => {
  if (typeof customEvent !== 'function') {
    Logger.warning('new relic not found');
    return;
  }

  Logger.debug('sendCustomEvent', name, attributes);
  customEvent(name, attributes);
};
