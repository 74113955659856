import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Row } from 'antd';
import './ReportList.scss';
import { MileageReportsTable } from './ReportsTable/MileageReportsTable';
import TPMSReportsTable from './ReportsTable/TPMSReportsTable';
import MileageDetailsDrawer from './Drawer/MileageDetailsDrawer';
import TPMSDetailsDrawer from './Drawer/TPMSDetailsDrawer';
import LocaleString from '../Utils/LocaleString';
import { reportType, reportVariables } from '../../helpers/constants';
import { isNullOrUndefined } from '../../helpers/functions';
import notificationActions from '../../actions/notifications';
import { selectProductFeatures } from '../../selectors';

const ReportList = () => {
  const [openDrawer, setOpenDrawer] = useState('');
  const notifications = useSelector(store => store?.notifications?.notificationTypesToListenTo);
  const showTPMSReport = useSelector(selectProductFeatures).showTPMSReport;
  const dispatch = useDispatch();

  const getCurrentSubscription = (reportType, changedSubscriptions) => {
    let subscription = {};
    const defaultSubscription = reportVariables[reportType].defaultSubscription;
    const stateSubscription = notifications?.[reportType] || {};
    Object.keys(defaultSubscription).forEach(key => {
      const changedValue = changedSubscriptions[key];
      const stateValue = stateSubscription[key];

      if (!isNullOrUndefined(changedValue)) subscription[key] = changedValue;
      else if (!isNullOrUndefined(stateValue)) subscription[key] = stateValue;
      else subscription[key] = defaultSubscription[key];
    });
    subscription.email = !!subscription.email;
    return subscription;
  };

  const onClickOk = ({ key, changedSubscriptions }) => {
    const newSubscriptions = Object.assign(notifications);
    newSubscriptions[key] = getCurrentSubscription(key, changedSubscriptions);
    dispatch(notificationActions.updateNotificationTypesToListenTo(newSubscriptions));
    setOpenDrawer('');
  };

  return (
    <div className='report-list-content'>
      <MileageDetailsDrawer
        isOpen={openDrawer === reportType.monthlyMileageReport}
        setOpenDrawer={setOpenDrawer}
        onClickOk={onClickOk}
        getSubscription={getCurrentSubscription}
      />
      <TPMSDetailsDrawer
        isOpen={openDrawer === reportType.TPMSReport}
        setOpenDrawer={setOpenDrawer}
        getSubscription={getCurrentSubscription}
        onClickOk={onClickOk}
      />
      <Row>
        <h1>
          <LocaleString type='reports' id='mileage' />
        </h1>
      </Row>
      <MileageReportsTable onClickDetails={key => setOpenDrawer(key)} />
      {showTPMSReport && (
        <>
          <Row>
            <h1 className='rprt-space-title'>
              <LocaleString type='reports' id='tirePressureTmps' />
            </h1>
          </Row>
          <TPMSReportsTable
            onClickDetails={key => {
              setOpenDrawer(key);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ReportList;
