import actionTypes from '../actions/actionTypes';

const initialState = {
  templateHtml: {}
};

export default function emailTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        templateHtml: {
          ...state.templateHtml,
          [action.templateType]: { processing: true, item: null, error: null }
        }
      };
    case actionTypes.GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateHtml: {
          ...state.templateHtml,
          [action.templateType]: { processing: false, item: action.template, error: null }
        }
      };
    case actionTypes.GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        templateHtml: {
          ...state.templateHtml,
          [action.templateType]: { processing: false, item: null, error: action.error }
        }
      };
    default:
      return state;
  }
}
