import dtcPriority from '../../assets/data/dtc_priority.json';
import iABSDtcPriority from '../../assets/data/iabs_dtc_priority.json';
import { tebseEventIdLabel, tebsfEventIdLabel } from '../../Components/Events/EventIdLabel';
import LocaleString from '../../Components/Utils/LocaleString';
import { mapErrorCodes } from '../../helpers/functions';
import React from 'react';
import { ENV_CONFIG } from '../../app/helpers/env-configs';

const { REACT_APP_MAP_MATCHING_MAX_DATA_RENDER, REACT_APP_MAP_MATCHING_ENABLE, REACT_APP_ANIMATED_HEAD_MAX_INTERVAL } =
  ENV_CONFIG;

export const vehicleTypeMapping = {
  0: 'semi',
  1: 'drawbar',
  2: 'centralAxle',
  3: 'dolly'
};

export const tebseAbsSystemMapping = {
  0: '2S/2M',
  1: '4S/2M',
  2: '4S/3M',
  3: '4S/2M+1M'
};

export const tebsfAbsSystemMapping = {
  0: '2S/2M',
  1: '4S/2M (SR)',
  2: '4S/3M',
  3: '2S/1M (MSH)',
  4: '4S/2M (AR)',
  5: '2S/1M (MAR)'
};

export const ebsTypeMapping = {
  0: 'Basic',
  1: 'Standard',
  2: 'Premium',
  3: 'TCS-Plus',
  4: 'MultiVoltage'
};

export const tebsfSuspensionTypeIdMapping = {
  0: 'mechanicalSuspensionWithHeightSensor',
  1: 'mechanicalSuspension',
  2: 'airSuspensionLevelingValve',
  3: 'airSuspensionEcas1',
  4: 'airSuspensionEtasc1',
  5: 'airSuspensionEcas2',
  6: 'airSuspensionEtasc2',
  7: 'hydraulicSuspension',
  8: 'airSuspensionEcas2FrontRear'
};

export const maxMatchedDataPointsToRender = parseInt(REACT_APP_MAP_MATCHING_MAX_DATA_RENDER, 10);

export const disableMapMatching =
  !REACT_APP_MAP_MATCHING_ENABLE || REACT_APP_MAP_MATCHING_ENABLE.toString().trim() !== 'true';

export const animatedHeadMaxInterval = parseInt(REACT_APP_ANIMATED_HEAD_MAX_INTERVAL, 10);

export const dtcPriorityMap = mapErrorCodes(dtcPriority);

export const iABSDtcPriorityMap = mapErrorCodes(iABSDtcPriority);

export const tebseRecordedEventLabelMap = tebseEventIdLabel({}).reduce((map, event) => {
  map[`${event.eventId}`] = event.eventNameId;
  return map;
}, {});

export const tebsfRecordedEventLabelMap = tebsfEventIdLabel({}).reduce((map, event) => {
  map[`${event.eventId}`] = event.eventNameId;
  return map;
}, {});

export const priorityMapping = {
  '32kb': {
    name: (
      <span>
        <LocaleString type='short' id='unknown' caseStyle='lc' /> <small>(32kb)</small>
      </span>
    ),
    id: 'unknown',
    color: 'grey'
  },
  0: { name: <LocaleString type='short' id='safe' caseStyle='lc' />, id: 'safe', color: '#87d068' },
  1: { name: <LocaleString type='short' id='info' caseStyle='lc' />, id: 'info', color: 'dodgerblue' },
  2: { name: <LocaleString type='short' id='warning' caseStyle='lc' />, id: 'warning', color: '#ffc107' },
  3: { name: <LocaleString type='short' id='critical' caseStyle='lc' />, id: 'critical', color: 'tomato' }
};

export const loginRetry = {
  attempts: 3,
  waitTime: 10000
};
