import React, { useEffect, useState, useRef } from 'react';
import { Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import HistogramChart from '../charts/HistogramChart/HistogramChart';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { getIsTraileriABS, getIsTrailerTEBSF } from '../../helpers/functions';
import {
  controlPressureScale,
  supplyPressureScale,
  downhillGradientScale,
  tebseDecelerationScale,
  speedScale,
  tebseLatAccelerationScale,
  histogramDataType,
  maxBrakePressureScale,
  brakeDemandPressureApplicationsScale,
  tebsfLatAccelerationScale,
  tebsfDecelerationScale,
  surfaceMeanScale,
  surfaceMaxScale,
  ecuTemperatureScale,
  brakeDemandPressureTimeScale
} from '../../helpers/constants';
import { isAllMetric, isUsaCustomer } from '../../helpers/functions';
import { convertInt, unitType } from '../../helpers/unitConverter';
import { selectRegion, selectFilteredEntitiesIds, selectDisplayTrailers } from '../../selectors';

const useBbox = () => {
  const ref = useRef();
  const [bbox, setBbox] = useState({});

  const set = () => setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [bbox, ref];
};

const secondsToHours = value => value / 3600;
const minutesToHours = value => value / 60;
const hoursToHrMin = value => {
  const hours = Math.floor(value).toString();
  let n = new Date(0, 0);
  n.setMinutes(value * 60);
  const min = n.toTimeString().slice(2, 5);
  return hours + min;
};

const arrayExistsAndNotEmpty = arr => arr?.length > 0 && arr.some(value => value !== 0);

const getRealtiveHistogramData = data =>
  data.map(histData => {
    const newHistData = {};
    Object.keys(histData).forEach(key => {
      const dataPoints = histData[key];
      if (dataPoints instanceof Array) {
        const total = dataPoints.reduce((result, current) => result + current, 0);
        newHistData[key] = dataPoints.map(value => (total ? Math.round((value / total) * 100) : value));
      } else {
        newHistData[key] = histData[key];
      }
    });
    return newHistData;
  });

const getUnitType = dataKey => {
  switch (dataKey) {
    case histogramDataType.axleLoad:
    case histogramDataType.axleLoadH:
    case histogramDataType.axleLoadL:
    case histogramDataType.lateralAcceleration:
      return unitType.speed;
    default:
      break;
  }
};

const HistogramList = ({ trailer, isTempAccess, intl: { formatMessage } }) => {
  const [bbox, ref] = useBbox();
  const windowHeight = window.innerHeight;
  const height = windowHeight - ((bbox.top || 0) + 18);
  const [isRelative, setIsRelative] = useState(false);
  const rawHistogramGroupData = useSelector(store => store.trailerHistograms.items);
  const processing = useSelector(store => store.trailerHistograms.processing);
  const devices = useSelector(store => store.trailerDetails.trailers.items);
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const isUS = isUsaCustomer(region);
  const displayTrailers = useSelector(selectDisplayTrailers);
  const deviceAssetIds = useSelector(selectFilteredEntitiesIds);
  const isiABS = getIsTraileriABS(trailer);
  const isTEBSF = getIsTrailerTEBSF(trailer);
  const isDrawbar = trailer?.vehicleType === 'drawbar';

  let loadXAxisValue =
    parseFloat(trailer?.originalData?.data?.VehicleDATA?.max_total_axle_load || 27000) /
    (isMetric.weight || isUS ? 1000 : 1016);
  loadXAxisValue =
    loadXAxisValue.toFixed(1) +
    formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton);

  const hideInactive = !displayTrailers?.includes('inactiveTrailers');
  let histogramGroupData = rawHistogramGroupData;
  if (hideInactive) {
    // Filter non-active trailers from histogramGroupData, using hasmap for linear time
    const activeDevicesHashMap = deviceAssetIds.reduce((map, id) => {
      map[id] = true;
      return map;
    }, {});
    histogramGroupData = rawHistogramGroupData.filter(e => activeDevicesHashMap[e.assetId]);
  }

  const trailerId = trailer?.assetId || 'default_asset';
  let histogramData;
  if (trailer?.uploaded || isTempAccess) {
    histogramData = [{ ...trailer?.histograms, assetId: trailerId }];
  } else {
    histogramData = histogramGroupData;
  }
  if (isRelative) {
    histogramData = getRealtiveHistogramData(histogramData);
  }
  if (!isAllMetric(isMetric)) {
    histogramData = histogramData.map(data => {
      if (!data.isConverted) {
        Object.keys(data).forEach(dataKey => {
          const dataUnitType = getUnitType(dataKey);
          if (dataUnitType) {
            data[dataKey] = data[dataKey].map(value => convertInt(isMetric, dataUnitType, value, isUS));
          }
        });
        data.isConverted = true;
      }
      return data;
    });
  }

  const getDeviceName = assetId => {
    if (assetId === trailerId) {
      return trailer.trailer || trailer.defaultDisplayName;
    }
    const device = devices && devices.find(device => device.assetId === assetId);

    if (device && device.trailer) {
      return device.trailer || device.defaultDisplayName;
    } else {
      return formatMessage(strings.short.unknown);
    }
  };

  if (processing) {
    return <LoadingOutlined className='large-margin-left' />;
  }

  // Histograms:
  // not all are available if they are iAbs (isiABS), they are different for TEBS-F
  const histograms = {};

  const distanceUnitMetric = isRelative ? '%' : isMetric.distance ? ' km' : ' mi';
  const distanceUnitLabel = isRelative
    ? formatMessage(strings.charts[`histYlabelDistancePerc`])
    : formatMessage(strings.charts[`histYlabelDistance`]);

  const pressureUnitLabel = name =>
    formatMessage(
      isMetric.pressure
        ? strings.charts[`histXlabel${name}PressureBar`]
        : strings.charts[`histXlabel${name}PressurePsi`]
    );
  const brakeApplicationsLabel = formatMessage(strings.charts.histYlabelBrakeApplications);
  const getScale = scale => (isMetric.pressure ? scale.metric : scale.imperial);
  const loadLabel = formatMessage(strings.charts[`histXlabelAxleLoadValue`], { value: loadXAxisValue });
  const hoursLabel = isRelative
    ? formatMessage(strings.charts.histYlabelHoursPerc)
    : formatMessage(strings.charts.histYlabelHours);
  const speedLabel = formatMessage(
    isMetric.distance ? strings.charts.histXlabelSpeedkmph : strings.charts.histXlabelSpeedMph
  );
  const accelerationLabel = formatMessage(strings.charts.histXlabelLatAccelerationInG);
  const decelerationLabel = formatMessage(strings.charts.histXlabelDecelerationInG);
  const gradLabel = formatMessage(strings.charts.histXlabelDownhillGradPerc);
  const numberLabel = isRelative
    ? formatMessage(strings.charts.histYlabelNumberPerc)
    : formatMessage(strings.charts.histYlabelNumber);
  const meanLabel = formatMessage(strings.charts.histXlabelMeanValue);
  const maxLabel = formatMessage(strings.charts.histXlabelMaxValue);
  const tempLabel = isMetric.temperature
    ? formatMessage(strings.charts.histXlabelECUTemperatureInCelsius)
    : formatMessage(strings.charts.histXlabelECUTemperatureInFahrenheit);

  function getHistogramComponent(
    name,
    xLabel,
    yLabel,
    yMetric,
    customScale = null,
    canBeRelative = true,
    mustTransformData = false
  ) {
    const dataTransformer = name === 'TimeSpeed' ? minutesToHours : secondsToHours;
    return (
      <div className='histogram-chart-div' key={`histogram${name}`}>
        <HistogramChart
          dataTransformer={mustTransformData && !isRelative ? dataTransformer : undefined}
          pointTransformer={mustTransformData && !isRelative ? hoursToHrMin : undefined}
          yPointMetric={mustTransformData && !isRelative ? formatMessage(strings.charts.histYPointHoursMin) : undefined}
          histogramData={histogramData}
          activeAsset={trailerId}
          customScale={customScale}
          title={
            isRelative && canBeRelative
              ? formatMessage(strings.charts[`histTitle${name}Perc`])
              : formatMessage(strings.charts[`histTitle${name}`])
          }
          xLabel={xLabel}
          yLabel={yLabel}
          yAxisMetric={yMetric}
          chartkey={histogramDataType[name]}
          noDataWarning={formatMessage(strings.charts.histNoDataWarning, {
            value: getDeviceName(trailerId)
          })}
          getDeviceName={getDeviceName}
          isRelative={isRelative}
        />
      </div>
    );
  }

  if (isTEBSF) {
    histograms.AxleLoad = getHistogramComponent('AxleLoad', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.AxleLoadH = getHistogramComponent('AxleLoadH', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.AxleLoadL = getHistogramComponent('AxleLoadL', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.MaxBrakePressureApplications = getHistogramComponent(
      'MaxBrakePressureApplications',
      pressureUnitLabel('MaxBrake'),
      brakeApplicationsLabel,
      '',
      getScale(maxBrakePressureScale),
      false
    );
    histograms.BrakeDemandPressureTime = getHistogramComponent(
      'BrakeDemandPressureTime',
      pressureUnitLabel('BrakeDemand'),
      hoursLabel,
      isRelative ? '%' : null,
      getScale(brakeDemandPressureTimeScale),
      false
    );
    histograms.BrakeDemandPressureApplications = getHistogramComponent(
      'BrakeDemandPressureApplications',
      pressureUnitLabel('BrakeDemand'),
      brakeApplicationsLabel,
      isRelative ? '%' : null,
      getScale(brakeDemandPressureApplicationsScale),
      false
    );
    histograms.TimeSupplyPressure = getHistogramComponent(
      'TimeSupplyPressure',
      pressureUnitLabel('Supply'),
      hoursLabel,
      isRelative ? '%' : null,
      getScale(supplyPressureScale),
      false,
      true
    );
    histograms.TimeSpeed = getHistogramComponent(
      'TimeSpeed',
      speedLabel,
      hoursLabel,
      isRelative ? '%' : null,
      getScale(speedScale),
      false,
      true
    );
    histograms.DistanceLatAcceleration = getHistogramComponent(
      'DistanceLatAcceleration',
      accelerationLabel,
      distanceUnitLabel,
      distanceUnitMetric,
      tebsfLatAccelerationScale
    );
    histograms.BrakeAppDownhillGrad = getHistogramComponent(
      'BrakeAppDownhillGrad',
      gradLabel,
      numberLabel,
      isRelative ? '%' : null,
      downhillGradientScale,
      false
    );
    histograms.TebsfBrakeAppDeceleration = getHistogramComponent(
      'TebsfBrakeAppDeceleration',
      decelerationLabel,
      numberLabel,
      isRelative ? '%' : null,
      tebsfDecelerationScale,
      false
    );
    histograms.RoadSurfaceMean = getHistogramComponent(
      'RoadSurfaceMean',
      meanLabel,
      distanceUnitLabel,
      distanceUnitMetric,
      surfaceMeanScale,
      false
    );
    histograms.RoadSurfaceMax = getHistogramComponent(
      'RoadSurfaceMax',
      maxLabel,
      distanceUnitLabel,
      distanceUnitMetric,
      surfaceMaxScale,
      false
    );
    histograms.ECUTemperature = getHistogramComponent(
      'ECUTemperature',
      tempLabel,
      distanceUnitLabel,
      distanceUnitMetric,
      getScale(ecuTemperatureScale),
      false
    );

    if (isDrawbar) {
      histograms.DrawbarTrailerLoad = getHistogramComponent(
        'DrawbarTrailerLoad',
        loadLabel,
        distanceUnitLabel,
        distanceUnitMetric
      );
    }

    // Histograms not shown :
    // histograms.AxleLoad24n = getHistogramComponent('AxleLoad24n', loadLabel, distanceUnitLabel, distanceUnitMetric);
    // histograms.AxleLoadH24n = getHistogramComponent('AxleLoadH24n', loadLabel, distanceUnitLabel, distanceUnitMetric);
    // histograms.AxleLoadL24n = getHistogramComponent('AxleLoadL24n', loadLabel, distanceUnitLabel, distanceUnitMetric);
    // histograms.FrameStressMean = getHistogramComponent(
    //   'FrameStressMean',
    //   meanLabel,
    //   distanceUnitLabel,
    //   distanceUnitMetric,
    //   frameStressMeanScale,
    //   false
    // );
    // histograms.FrameStressMax = getHistogramComponent(
    //   'FrameStressMax',
    //   maxLabel,
    //   distanceUnitLabel,
    //   distanceUnitMetric,
    //   frameStressMaxScale,
    //   false
    // );
  } else {
    histograms.AxleLoad = getHistogramComponent('AxleLoad', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.AxleLoadCD = getHistogramComponent('AxleLoadCD', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.AxleLoadEF = getHistogramComponent('AxleLoadEF', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.AxleLoadSum = getHistogramComponent('AxleLoadSum', loadLabel, distanceUnitLabel, distanceUnitMetric);
    histograms.TimeSpeed = getHistogramComponent(
      'TimeSpeed',
      speedLabel,
      hoursLabel,
      isRelative ? '%' : null,
      getScale(speedScale),
      false,
      true
    );
    histograms.BrakeAppControlPressure = getHistogramComponent(
      'BrakeAppControlPressure',
      pressureUnitLabel('Control'),
      numberLabel,
      isRelative ? '%' : null,
      getScale(controlPressureScale),
      false
    );
    histograms.BrakeTimeControlPressure = getHistogramComponent(
      'BrakeTimeControlPressure',
      pressureUnitLabel('Control'),
      hoursLabel,
      isRelative ? '%' : null,
      getScale(controlPressureScale),
      false,
      true
    );
    histograms.TimeSupplyPressure = getHistogramComponent(
      'TimeSupplyPressure',
      pressureUnitLabel('Supply'),
      hoursLabel,
      isRelative ? '%' : null,
      getScale(supplyPressureScale),
      false,
      true
    );
    histograms.BrakeAppDownhillGrad = getHistogramComponent(
      'BrakeAppDownhillGrad',
      gradLabel,
      numberLabel,
      isRelative ? '%' : null,
      downhillGradientScale,
      false
    );
    histograms.TebseBrakeAppDeceleration = getHistogramComponent(
      'TebseBrakeAppDeceleration',
      decelerationLabel,
      numberLabel,
      isRelative ? '%' : null,
      tebseDecelerationScale,
      false
    );
    histograms.DistanceLatAcceleration = getHistogramComponent(
      'DistanceLatAcceleration',
      accelerationLabel,
      distanceUnitLabel,
      distanceUnitMetric,
      tebseLatAccelerationScale
    );
  }

  let trailerHistogramList;
  if (isiABS) {
    // iABS trailers Histogram List
    const selectedTrailerHistData = histogramData.find(h => h.assetId === trailerId);
    trailerHistogramList = [];
    // only rendering histograms that containt data
    if (arrayExistsAndNotEmpty(selectedTrailerHistData[histogramDataType.TimeSpeed])) {
      trailerHistogramList.push(histograms.TimeSpeed);
    }
    if (arrayExistsAndNotEmpty(selectedTrailerHistData[histogramDataType.AxleLoad])) {
      trailerHistogramList.push(histograms.AxleLoad);
    }
    if (arrayExistsAndNotEmpty(selectedTrailerHistData[histogramDataType.AxleLoadL])) {
      trailerHistogramList.push(histograms.AxleLoadEF);
    }
    if (arrayExistsAndNotEmpty(selectedTrailerHistData[histogramDataType.AxleLoadSum])) {
      trailerHistogramList.push(histograms.AxleLoadSum);
    }
  } else if (isTEBSF) {
    trailerHistogramList = [
      histograms.AxleLoad,
      histograms.AxleLoadH,
      histograms.AxleLoadL,
      histograms.MaxBrakePressureApplications,
      histograms.BrakeDemandPressureApplications,
      histograms.BrakeDemandPressureTime,
      histograms.TimeSupplyPressure,
      histograms.TimeSpeed,
      histograms.DistanceLatAcceleration,
      histograms.BrakeAppDownhillGrad,
      histograms.TebsfBrakeAppDeceleration,
      histograms.RoadSurfaceMean,
      histograms.RoadSurfaceMax,
      histograms.ECUTemperature
    ];
    if (isDrawbar) {
      trailerHistogramList.push(histograms.DrawbarTrailerLoad);
    }
  } else {
    // EBS trailers Histogram List
    trailerHistogramList = [
      histograms.AxleLoad,
      histograms.AxleLoadCD,
      histograms.AxleLoadEF,
      histograms.BrakeAppControlPressure,
      histograms.BrakeTimeControlPressure,
      histograms.TimeSupplyPressure,
      histograms.TimeSpeed,
      histograms.BrakeAppDownhillGrad,
      histograms.TebseBrakeAppDeceleration,
      histograms.DistanceLatAcceleration
    ];
  }

  return (
    <div>
      <div className='histogram-list-header'>
        <div>
          <h2>{formatMessage(strings.short.histograms)}</h2>
          <span className='histogram-chart-subtitle'>{formatMessage(strings.short.histogramsSubtitle)}</span>
        </div>
        <div className='histogram-switch-element'>
          <span>
            {formatMessage(strings.charts.histSwitchTitle)}
            <Switch className='histogram-switch' checked={isRelative} onChange={setIsRelative} />
          </span>
        </div>
      </div>
      <div className='histogram-chart-grid' ref={ref} height={height} style={{ maxHeight: height }}>
        {!!(trailer && histogramData?.length) && (
          <>
            {trailerHistogramList}

            {/* Following empty divs are to fix spacing from using flexwrap and space-between */}
            <div className='histogram-chart-div'></div>
            <div className='histogram-chart-div'></div>
            <div className='histogram-chart-div'></div>
          </>
        )}
      </div>
      {!(trailerId && histogramData && histogramData.length) && (
        <div className='histogram-warning'>{formatMessage(strings.charts.histChartDataNotloaded)}</div>
      )}
    </div>
  );
};

export default injectIntl(HistogramList);
