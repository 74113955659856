import React from 'react';
import { Button, Typography } from 'antd';
import './OdyNavButton.scss';
import { openNewTab } from '../../../helpers/functions';

const OdyNavButton = ({ link, icon, text, onClick, button }) => {
  const onClickButton = () => {
    openNewTab(link);
    if (onClick) onClick();
  };

  return (
    <div className={'button-container'} onClick={onClickButton}>
      {icon ? <Button shape={'circle'} icon={icon} type={'primary'} size={'large'} /> : button ? button : ''}
      <Typography className='ody-nav-btn-text'>{text}</Typography>
    </div>
  );
};

export default OdyNavButton;
