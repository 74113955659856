import actionTypes from '../actions/actionTypes';

export const initialState = {
  unfilteredDevices: null,
  devices: null,
  deviceAssetIds: [],
  states: null,
  buffer: [],
  history: {
    current: {
      assetId: 0,
      data: [],
      tpms: [],
      dataHash: null,
      final: false,
      hours: 0
    },
    retrievedHistory: {} // assetId => {dataHash, hours}
  },
  contextHistory: {
    assetId: null,
    processing: false,
    error: null,
    data: null
  },
  contextTPMS: {
    default: true,
    assetId: null,
    tpms: null,
    time: null,
    error: null,
    processing: false
  },
  initialRawStates: null,
  processing: false,
  processingApi: false,
  processingMapMatching: false,
  error: null,
  mapRoutes: {},
  animatedHeadRoutes: {},
  lastMQTTProcessedTime: 0,
  mapMatchedData: {}, // keep track of map matching checks of each device,
  livemapSelectedDevices: []
};

export default function devicesReducer(state = initialState, action) {
  const existingAssetId = state?.history?.current?.assetId;
  switch (action.type) {
    case actionTypes.FETCH_MAP_MATCHING_ROUTE_FAILURE:
      return {
        ...state,
        error: action.error,
        processingMapMatching: false
      };
    case actionTypes.FETCH_INITIAL_DEVICES_STATES_REQUEST:
      return {
        ...state,
        initialRawStates: [],
        processingApi: true
      };
    case actionTypes.FETCH_INITIAL_DEVICES_STATES_FAILURE:
      return {
        ...state,
        initialRawStates: null,
        processingApi: false,
        error: action.error
      };
    case actionTypes.GET_TRAILER_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          current: {
            ...state.history.current,
            assetId: action.assetId,
            hours: action.hours,
            data: [],
            tpms: []
          }
        },
        error: null,
        processingApi: true
      };
    case actionTypes.GET_TRAILER_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.history,
        animatedHeadRoutes: action.animatedHeadRoutes,
        mapRoutes: action.mapRoutes,
        devices: action.devices,
        processingApi: action.processingApi
      };
    case actionTypes.GET_TRAILER_HISTORY_FAILURE:
      if (existingAssetId && existingAssetId !== action.assetId) {
        return state;
      } else {
        return {
          ...state,
          history: {
            ...state.history,
            current: {
              ...state.history.current,
              data: []
            }
          },
          processingApi: false,
          error: action.error
        };
      }
    case actionTypes.GET_TRAILER_CONTEXT_HISTORY_REQUEST:
      return {
        ...state,
        contextHistory: {
          ...state.contextHistory,
          assetId: action.assetId,
          data: null,
          processing: true,
          error: null
        }
      };
    case actionTypes.GET_TRAILER_CONTEXT_HISTORY_SUCCESS:
      return {
        ...state,
        contextHistory: action.contextHistory
      };
    case actionTypes.GET_TRAILER_CONTEXT_HISTORY_FAILURE:
      if (state.contextHistory.assetId !== action.assetId) {
        return state;
      } else {
        return {
          ...state,
          contextHistory: {
            ...state.contextHistory,
            data: null,
            processing: false,
            error: action.error
          }
        };
      }
    case actionTypes.GET_TRAILER_TPMS_HISTORY_REQUEST:
      return {
        ...state,
        contextTPMS: {
          default: false,
          assetId: action.assetId,
          tpms: null,
          time: null,
          error: null,
          processing: true
        }
      };
    case actionTypes.GET_TRAILER_TPMS_HISTORY_SUCCESS:
      return {
        ...state,
        contextTPMS: {
          default: false,
          assetId: action.payload.assetId,
          tpms: action.payload.tpms,
          time: action.payload.time,
          error: null,
          processing: false
        }
      };
    case actionTypes.GET_TRAILER_TPMS_HISTORY_FAILURE:
      return {
        ...state,
        contextTPMS: {
          default: false,
          assetId: null,
          tpms: null,
          time: null,
          error: action.error,
          processing: false
        }
      };
    case actionTypes.TRAILER_TPMS_HISTORY_DEFAULT:
      return {
        ...state,
        contextTPMS: {
          ...state.contextTPMS,
          default: true
        }
      };
    case actionTypes.FETCH_INITIAL_DEVICES_STATES_SUCCESS:
      return {
        ...state,
        initialRawStates: action.initialRawStates,
        processingApi:
          action.processingApi !== undefined && action.processingApi !== null
            ? action.processingApi
            : state.processingApi,
        states: action.states || state.states,
        mapRoutes: action.mapRoutes || state.mapRoutes,
        devices: action.devices || state.devices
      };
    case actionTypes.RECEIVE_NEW_DEVICE_STATE:
      return {
        ...state,
        buffer: action.buffer || state.buffer,
        devices: action.devices || state.devices,
        lastMQTTProcessedTime: action.lastMQTTProcessedTime || state.lastMQTTProcessedTime,
        states: action.states || state.states,
        mapRoutes: action.mapRoutes || state.mapRoutes,
        animatedHeadRoutes: action.animatedHeadRoutes || state.animatedHeadRoutes,
        history: action.history || state.history
      };

    case actionTypes.FETCH_MAP_MATCHING_ROUTE_REQUEST:
      return {
        ...state,
        processingMapMatching: true
      };
    case actionTypes.FETCH_MAP_MATCHING_ROUTE_SUCCESS:
      return {
        ...state,
        mapRoutes: action.mapRoute,
        mapMatchedData: action.mapMatchedData,
        processingMapMatching: action.processingMapMatching
      };
    case actionTypes.SELECT_DEVICES_LIVEMAP: {
      return {
        ...state,
        livemapSelectedDevices: action.devices
      };
    }
    case actionTypes.FILTER_DEVICES:
      return {
        ...state,
        devices: action.devices,
        deviceAssetIds: action.deviceAssetIds
      };
    default:
      return state;
  }
}
