import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as strings from '../../../helpers/defaultStrings';
import TrailerHistoryChart from './TrailerHistoryChart';
import LocaleString from '../../Utils/LocaleString';
import { isUsaCustomer, getTimeFormatLocale } from '../../../helpers/functions';
import { convertInt, unitType } from '../../../helpers/unitConverter';
import NoDataOrLoading from '../NoDataOrLoading';
import { selectProductFeatures, selectRegion } from '../../../selectors';

class TrailerHistoryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.chartRef = React.createRef();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    // this.createChart();

    if (this.props.forceCreateChart) {
      this.getChartState(this.props);
    }
    // Re-render from scratch on each resize.
    window.addEventListener('resize', () => this.getChartState(this.props));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const { dataHash, resizeChart, alerts, pos, showAlerts, trailerHistory, processingAlerts, startDate, endDate } =
      nextProps;

    if (chart && resizeChart !== this.props.resizeChart) {
      chart.destroy();
      this.setState(
        {
          chart: null
        },
        () => this.getChartState(nextProps)
      );
    } else if (
      (dataHash && dataHash !== this.props.dataHash) ||
      startDate !== this.props.startDate ||
      endDate !== this.props.endDate ||
      showAlerts !== this.props.showAlerts ||
      (JSON.stringify(alerts) !== JSON.stringify(this.props.alerts) && !processingAlerts) ||
      JSON.stringify(trailerHistory?.current?.isoCable) !==
        JSON.stringify(this.props.trailerHistory?.current?.isoCable) ||
      JSON.stringify(pos) !== JSON.stringify(this.props.pos)
    ) {
      this.getChartState(nextProps);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.getChartState);
  }

  getChartState(props) {
    const { data, selectedTrailer } = props;
    const newData = data ? { ...props, data: this.convertData(data) } : props;

    if (newData.data && newData.data.length) {
      if (this.state.chart) {
        this.state.chart.update(newData, selectedTrailer ? selectedTrailer.assetId : null);
      } else {
        this.createChart(newData);
      }
    } else if (this.state.chart) {
      this.state.chart.destroy();
      this.setState({
        chart: null
      });
    }
  }

  convertData(data) {
    const { isMetric, region } = this.props;
    const isUS = isUsaCustomer(region);
    return data.map(item => {
      if (item.isConverted) {
        return item;
      }
      return {
        ...item,
        startAxleLoad: convertInt(isMetric, unitType.ton, item.startAxleLoad, isUS),
        startSpeed: convertInt(isMetric, unitType.speed, item.startSpeed),
        isConverted: true
      };
    });
  }

  createChart(newData) {
    const el = this.chartRef.current;
    const { chart } = this.state;
    const { selectedTrailer, region, isMetric, productFeatures } = this.props;
    const { formatMessage } = this.props.intl;

    if (chart) {
      chart.destroy();
    }
    if (!el) {
      return;
    }

    const margin = {
      top: 20,
      right: 40,
      bottom: 20,
      left: 40
    };
    const { height, width } = this.props;
    const elWidth = width || (el && el.offsetWidth);
    const elHeight = height || 150;
    const isUS = isUsaCustomer(region);
    const axleLoadString = isUS ? strings.short.aggregateLoad : strings.short.axleLoad;

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      strings: {
        axleLoad: formatMessage(axleLoadString),
        speed: formatMessage(strings.short.speed),
        isoTooltip: formatMessage(strings.alert.movingWithoutISO)
      },
      assetId: selectedTrailer ? selectedTrailer.assetId : null,
      region,
      isMetric,
      isUS,
      timeFormatLocale: getTimeFormatLocale(formatMessage, isUS),
      productFeatures
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new TrailerHistoryChart(el, props)
      },
      function callback() {
        this.state.chart.create(newData);
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return (
      <>
        {this.props.showTitle && (
          <h2>
            <LocaleString type='sections' id={this.props.view} />
          </h2>
        )}
        <div ref={this.chartRef}>
          {(!this.props.data || this.props.data.length <= 0) && (
            <NoDataOrLoading
              processing={this.props.processing}
              setPickerOpen={this.props.setPickerOpen}
              onChangeEndDateTime={this.props.onChangeEndDateTime}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              showButtons={true}
            />
          )}
        </div>
      </>
    );
  }
}

TrailerHistoryChartComponent.propTypes = {};

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    region,
    productFeatures: selectProductFeatures(store)
  };
}

export default injectIntl(connect(mapStateToProps)(TrailerHistoryChartComponent));
