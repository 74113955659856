import * as d3 from 'd3';
import Chart from '../chart';

import './ColorLegend.scss';

export default class ColorLegend extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g').attr('class', 'main');
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.color) {
      this.color = d3.interpolateRdBu; //state.color;
      this.maxValue = state.maxValue;
      this.minValue = state.minValue;
      this.reverse = state.reverse;
      this.drawLegend();
    }
  }

  updateValue(value) {
    this.main
      .selectAll('.value')
      .transition()
      .attr('transform', `translate(${value || value === 0 ? this.x(value) : -100}, ${-5}) rotate(180)`);
  }

  drawLegend() {
    this.main.selectAll('*').remove();

    const domain = [this.minValue, this.maxValue];
    const color = d3
      .scaleSequential(d3.interpolateRdBu)
      .domain(this.reverse ? [this.maxValue, this.minValue] : [this.minValue, this.maxValue]);

    this.x = d3.scaleLinear().domain(domain).range([0, this.props.width]);

    const xAxis = d3.axisBottom(this.x).tickSize(13);
    //.tickFormat(d => d === 0.5 ? formatPercent(d) : formatNumber(100 * d); });

    this.main.call(xAxis);

    const ticks = this.x.ticks();
    this.main
      .selectAll('rect')
      .data(ticks.map((tick, i) => [tick, i < ticks.length - 1 ? ticks[i + 1] : this.maxValue]))
      .enter()
      .insert('rect', '.tick')
      .attr('height', 8)
      .attr('x', d => this.x(d[0]))
      .attr('width', d => this.x(d[1]) - this.x(d[0]))
      .attr('fill', d => color(d[0]));

    this.main
      .append('path')
      .attr('class', 'value')
      .attr('d', d3.symbol().type(d3.symbolTriangle))
      .attr('fill', 'black')
      .attr('transform', `translate(-100, ${-5}) rotate(180)`);
  }
}
