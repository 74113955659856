const tebseEventNames = [
  'nd',
  'abs',
  'rssLevel1',
  'rssLevel2',
  'warningLamp',
  'error',
  'tyrePressureInsufficient',
  'liningWear',
  'gio1',
  'gio2',
  'lowTyrePressure',
  'wheelEndOverTemp',
  'gioFcf1',
  'gioFcf2',
  'gioFcf3',
  'gioFcf4',
  'gioFcf5',
  'gioFcf6',
  'gioFcf7',
  'gioFcf8',
  'relativeBrakeDemand',
  'ropSystemDisabled',
  'ycSystemDisabled',
  'trailerDocked',
  'tailGuardDeactivated',
  'tpmCalibrationRequest',
  'liftAxlePlausibility',
  'immobiliserLockTrailer',
  'immobiliserUnlockTrailer',
  'immobiliserWrongPin',
  'immobiliserUnauthorisedUse',
  'tiltAlert',
  'antiSnaking',
  'standByOperation',
  'operation24N',
  'supplyPressureWarning',
  'optiLoad',
  'optiTurn',
  'tbsBrakeIntervention',
  'tbsDeactivationByUser',
  'epbDeactivatedByUser',
  'driveDetectionMemoryBit',
  'ecasBadRoadDetection',
  'trailerOverload',
  'ecasOutsideRequestedLevel',
  'ecasPlausibilityErrorFrontAxle',
  'ecasPlausibilityErrorRearAxle',
  'plausibilityResPressureControl',
  'scbEvent',
  'nd'
];

export const tebseEventIdLabel = () =>
  tebseEventNames.map((eventNameId, index) => {
    return { eventId: index, eventNameId };
  });

const tebsfEventNames = [
  'abs',
  'rssLevel1',
  'rssLevel2',
  'emergencyBrakeAlert',
  'absControlCritical',
  'tyrePressureInsufficient',
  'liningWear',
  'issPlusDigital',
  'issPlusAnalog',
  'meritorTireInflationSystem',
  'wheelEndOverTemp',
  'gioFcf1',
  'gioFcf2',
  'gioFcf3',
  'gioFcf4',
  'gioFcf5',
  'gioFcf6',
  'gioFcf7',
  'gioFcf8',
  'nd',
  'ropSystemDisabled',
  'ycSystemDisabled',
  'tailGuardDeactivated',
  'nd',
  'tpmCalibrationRequest',
  'liftAxlePlausibility',
  'immobiliserLockTrailer',
  'immobiliserUnlockTrailer',
  'immobiliserWrongPin',
  'immobiliserUnauthorisedUse',
  'tiltAlert',
  'antiSnaking',
  'standByOperation',
  'collision',
  'diagnosticErrorMessage',
  'optiLoad',
  'optiTurn',
  'tbsBrakeIntervention',
  'tbsDeactivationByUser',
  'epbDeactivatedByUser',
  'driveDetectionMemoryBit',
  'ecasBadRoadDetection',
  'trailerOverload',
  'ecasOutsideRequestedLevel',
  'ecasPlausibilityErrorFrontAxle',
  'ecasPlausibilityErrorRearAxle',
  'liftAxlePlausibilityValveDetection',
  'optiTireTimeout',
  'optiTireLowBattery',
  'fingerPrintHistory',
  'scbTelematicsControlRequest',
  'ecasEmergencyMode'
];
export const tebsfEventIdLabel = () =>
  tebsfEventNames.map((eventNameId, index) => {
    return { eventId: index + 1, eventNameId };
  });
