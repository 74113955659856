import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../scss/App.scss';
import LocaleContainer from './LocaleContainer';
import { validateAuth0Token } from '../actions/thunks/auth/validate-auth0-token';
import { Modal, Spin } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0AppLogin = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.auth.user);
  const authError = useSelector(store => store.auth.error);
  const { isLoading, error, isAuthenticated, logout, loginWithRedirect, getIdTokenClaims } = useAuth0();

  const onLogout = () => logout({ returnTo: window.location.origin });

  if (!isLoading && !user) {
    if (authError || error) {
      onLogout();
    } else if (isAuthenticated) {
      getIdTokenClaims().then(token => {
        if (token) {
          dispatch(
            validateAuth0Token({
              token: token.__raw
            })
          );
        } else {
          onLogout();
        }
      });
    } else {
      loginWithRedirect();
    }
  }

  if (user) {
    return <LocaleContainer />;
  }

  return (
    <Modal
      visible={true}
      closable={false}
      title={null}
      width={100}
      footer={null}
      wrapClassName='loading-modal'
      centered={true}
    >
      <Spin size='large' />
    </Modal>
  );
};
export default Auth0AppLogin;
