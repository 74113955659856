import React from 'react';
const HighlightText = ({ children, highlight }) => {
  if (typeof highlight !== 'string' && !(highlight instanceof String)) {
    return children;
  }
  if (typeof children !== 'string' && !(children instanceof String)) {
    return children;
  }
  // only filter if both children and highlight are strings
  return children.split(new RegExp(`(${highlight})`, 'gi')).map((textSegment, i) =>
    textSegment.toLowerCase() === highlight.toLowerCase() ? (
      <span key={i} className='highlight'>
        {textSegment}
      </span>
    ) : (
      textSegment
    )
  );
};

export default HighlightText;
