import { createAsyncThunk } from '@reduxjs/toolkit';
import { worker } from '../../../app/web-workers/aws-web-worker';
import { API_NAMES, SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH } from '../../../helpers/constants';

// +info https://redux-toolkit.js.org/api/createAsyncThunk#type
export const settingsThunk = async () => {
  const data = await worker.Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'notification/settings' });

  // return values will update the reducer state accordingly on action creator '*/fulfilled '
  return data ?? {};
};

export const fetchSettings = createAsyncThunk(SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH, settingsThunk);
