const DownloadFile = url => {
  const filePath = url;
  const a = document.createElement('A');
  a.href = filePath;
  a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default DownloadFile;
