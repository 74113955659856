import { push } from 'connected-react-router';
import { setLivemapSelectedDevices } from '../../actions/devices';

// previously called `onBoxSelect`
export const zoomLiveMapToAssets = ({
  rectangle,
  trailers,
  filterDataByRect,
  setIsBoxSelectActive,
  filterTrailers,
  dispatch
}) => {
  setIsBoxSelectActive(false);
  const trailersInsideRec = filterDataByRect(trailers, rectangle);

  if (trailersInsideRec.length > 0) {
    const trailerIdsMap = {};
    trailersInsideRec.forEach(t => (trailerIdsMap[t.assetId] = true));
    filterTrailers(t => trailerIdsMap[t.assetId]);
    const urlQueries = window.location.search;
    const devicesInPolygonAssetIds = trailersInsideRec.map(trailer => trailer.assetId);
    const selectedTrailerIDsURL = devicesInPolygonAssetIds.join('|');
    dispatch(setLivemapSelectedDevices(devicesInPolygonAssetIds));
    dispatch(push(`/live-map/${selectedTrailerIDsURL}${urlQueries}`));
  }
};
