import React, { memo } from 'react';
import classNames from 'classnames';
import './OdyToggleButton.scss';

export const OdyToggleButton = ({ icon, onClick, className, children, disabled = false, checked = false }) => {
  const classes = classNames('ody-toggle-btn', {
    [className]: className,
    checked,
    disabled,
    enabled: !disabled
  });

  return (
    <button disabled={disabled} onClick={() => onClick(!checked)} className={classes}>
      {icon && <span className='ody-btn-icon'>{icon}</span>}
      {children}
    </button>
  );
};

export default memo(OdyToggleButton);
