import React, { useEffect, useState } from 'react';
import * as timeHelper from '../../helpers/time';
import { getConversionString, unitType } from '../../helpers/unitConverter';
import { useSelector } from 'react-redux';
import LocaleString from '../Utils/LocaleString';
import { injectIntl } from 'react-intl';
import './TrailerMapTooltip.scss';
import { isUsaCustomer } from '../../helpers/functions';
import { getTriggers } from '../../helpers/alarms/functions';
import moment from 'moment';
import { selectEntities, selectProductFeatures, selectRegion } from '../../selectors';

const TrailerMapTooltip = ({ data, x, y, intl: { formatMessage } }) => {
  const devices = useSelector(selectEntities);
  const trailers = useSelector(store => store.trailerDetails.trailers.items || []);
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const productFeatures = useSelector(selectProductFeatures);

  const [category, setCategory] = useState(false);
  const [timeActivity, setTimeActivity] = useState('');
  const triggerTypes = getTriggers(region, productFeatures);

  const getDisplayName = object => {
    if (devices) {
      const device = devices.filter(item => item.assetId === object.assetId)[0];
      if (device) {
        return device.defaultDisplayName;
      }
    }
    return object.assetId;
  };

  const displayName = getDisplayName(data);
  const time = data.time ? data.time : Math.round((data.startTime + data.endTime) / 2);
  const speed = Math.round(data.speed ? data.speed : (data.startSpeed + data.endSpeed) / 2);
  const axleLoad = data.axleLoad ? data.axleLoad / 1000 : false;
  const style = { top: y, left: x };
  const alert = data.alarm && triggerTypes[data.alarm] ? triggerTypes[data.alarm].text : null;

  useEffect(() => {
    if (data.category) {
      setCategory(data.category);
    } else {
      const trailer = trailers.find(trailer => trailer.assetId === data.assetId);
      setCategory(trailer && trailer.category);
    }

    if (data.activityStatus) {
      setTimeActivity(moment.utc(data.activityStatus.since).local().fromNow(true) + ' ' + data.activityStatus.status);
    } else {
      setTimeActivity(timeHelper.getTimeAgo(time));
    }
  }, [data, trailers, time]);

  return (
    <div className='tooltip trailer-map-tooltip' style={style}>
      <div className='row'>
        <h3 className='white-text trailer-title'>{displayName}</h3>
        {category && (
          <div>
            <LocaleString type='trailerType' id={category} />
          </div>
        )}
      </div>
      {alert && (
        <div className='row'>
          <h5 className='white-text title'>
            <LocaleString type='short' id={'alert'} />
          </h5>
          <div>{alert}</div>
        </div>
      )}
      <div className='row'>
        <h5 className='white-text title'>
          <LocaleString type='short' id={data.activityStatus && data.activityStatus.since ? 'activity' : 'time'} />
        </h5>
        <div>{timeActivity}</div>
      </div>
      {!!speed && (
        <div className='row'>
          <h5 className='white-text title'>
            <LocaleString type='short' id={'speed'} />
          </h5>
          <div> {formatMessage(...getConversionString(isMetric, speed, unitType.speed))}</div>
        </div>
      )}
      {!!axleLoad && (
        <div className='row'>
          <h5 className='white-text title'>
            <LocaleString type='short' id={'load'} />
          </h5>
          <div> {formatMessage(...getConversionString(isMetric, axleLoad, unitType.ton, isUsaCustomer(region)))}</div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(TrailerMapTooltip);
