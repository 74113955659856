import React from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from '../ColumnComponents/columnHelper/columnHelperFunctions';

const getValidGroups = (trailers, groups) => {
  if (!trailers || !trailers.length || !groups) {
    return [];
  }

  const deviceGroups = {};
  for (let i = 0; i < trailers.length; i++) {
    const trailer = trailers[i];
    if (trailer.groups?.length) {
      for (let j = 0; j < trailer.groups.length; j++) {
        const group = trailer.groups[j];
        deviceGroups[group] = true;
      }
    }
  }
  const validGroups = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    const groupN = { ...group };
    groupN.subgroups = [];
    groupN.subgroupsDict = {};
    for (const subgroup of group.subgroups) {
      if (subgroup.id in deviceGroups) {
        groupN.subgroups.push(subgroup);
        groupN.subgroupsDict[subgroup.id] = subgroup.name;
      }
    }
    if (groupN.id in deviceGroups || groupN.subgroups.length) {
      validGroups.push(groupN);
    }
  }
  return validGroups;
};

const GroupCell = ({ trailer, group }) => {
  if (trailer?.groups?.length) {
    for (const groupId of trailer.groups) {
      if (groupId in group.subgroupsDict) {
        return group.subgroupsDict[groupId];
      }
    }
  }
  return <NoValueSpan />;
};

const GroupTitle = ({ name }) => {
  const formatMessage = useIntl().formatMessage;
  const title = formatMessage(strings.short.groupNameVar, { VAR_GROUP_NAME: name });
  return <Tooltip title={title}>{title}</Tooltip>;
};

const GroupColumn = (trailers, group) => {
  const filterFunction = checkedCategories => {
    trailers.filter(group.id, t => {
      if (!t.groups) return false;
      for (let i = 0; i < t.groups.length; i++) {
        const groupID = t.groups[i];
        if (checkedCategories[groupID]) return true;
      }
      return false;
    });
  };

  return {
    title: <GroupTitle name={group.name} />,
    searchKey: group.name,
    key: group.id,
    dataIndex: group.name,
    selectionSection: 'groups',
    render: trailer => <GroupCell trailer={trailer} group={group} />,
    categories: group.subgroups.map(subgroup => {
      return { text: subgroup.name, value: subgroup.id };
    }),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter(group.id),
    width: 180
  };
};

const GroupCSVHeader = group => formatMessage =>
  formatMessage(strings.short.groupNameVar, { VAR_GROUP_NAME: group.name });
const GroupCSVData = group => trailer => {
  if (trailer?.groups?.length) {
    for (const groupId of trailer.groups) {
      if (groupId in group.subgroupsDict) {
        return group.subgroupsDict[groupId];
      }
    }
  }
};
const GroupColumnCSV = group => {
  return {
    header: GroupCSVHeader(group),
    data: GroupCSVData(group)
  };
};

const generateGroupCols = (validGroups, trailers) => {
  const groupCols = {};
  for (let i = 0; i < validGroups.length; i++) {
    const group = validGroups[i];
    groupCols[group.id] = GroupColumn(trailers, group);
  }
  return groupCols;
};

export const getGroupColumnsTable = (trailers, groups) => {
  const validGroups = getValidGroups(trailers.unfilteredData, groups);
  const groupCols = generateGroupCols(validGroups, trailers);
  return groupCols;
};

const generateGroupColsCSV = validGroups => {
  const groupCols = {};
  for (let i = 0; i < validGroups.length; i++) {
    const group = validGroups[i];
    groupCols[group.id] = GroupColumnCSV(group);
  }
  return groupCols;
};

export const getGroupColumnsCSV = (trailers, groups) => {
  const validGroups = getValidGroups(trailers, groups);
  const groupCols = generateGroupColsCSV(validGroups);
  return groupCols;
};
