import { buildAuthDataFromResponse } from './helper';
import { authRetryFailure, authRetryLogin } from '../../auth';
import { login } from './login';
import { API_NAMES } from '../../../helpers/constants';
import { worker } from '../../../app/web-workers/aws-web-worker';

const VALIDATE_RETRY_TIMEOUT = 10000;
const MAX_VALIDATE_TOKEN_ATTEMPTS = 3;

export const validateTransicsToken = ({ token, env, retryCount = 0 }) => {
  return async dispatch => {
    try {
      const data = await worker.Api({
        method: 'get',
        api: API_NAMES.ODR_LIVE_MAP,
        endpoint: `validateEnvToken/${env}/${token}`
      });
      const authData = buildAuthDataFromResponse(data);
      dispatch(login(authData));
    } catch (error) {
      if (retryCount < MAX_VALIDATE_TOKEN_ATTEMPTS) {
        dispatch(authRetryLogin());
        setTimeout(() => {
          dispatch(validateTransicsToken({ token, env, retryCount: retryCount + 1 }));
        }, VALIDATE_RETRY_TIMEOUT);
      } else {
        dispatch(authRetryFailure(error));
      }
    }
  };
};
