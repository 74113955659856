import * as d3 from 'd3';
import ReactDOMServer from 'react-dom/server';
import React from 'react';

import { placesVariables } from '../../../helpers/alarms/functions';

import './TrailerHistoryChart.scss';
import { dateMultiFormat } from '../../../helpers/functions';

import BaseHistoryCharts from '../baseHistoryCharts';

export default class TrailerHistoryChart extends BaseHistoryCharts {
  create(state) {
    super.create(state);

    this.events = this.main.append('g').attr('class', 'events');

    this.alerts = this.main.append('g').attr('class', 'alerts');

    this.iso = this.main.append('g').attr('class', 'iso');

    this.areas = this.main.append('g').attr('class', 'areas');

    this.xAxis = this.main
      .append('g')
      .attr('class', 'xAxis trailer-history-axis')
      .attr('transform', `translate(0,${this.props.height})`);

    this.xAxisPosLine = this.main
      .append('g')
      .attr('class', 'xAxisPos trailer-history-axis')
      .attr('transform', `translate(0,${this.props.height})`)
      .append('line')
      .attr('x1', 0)
      .attr('y1', 1)
      .attr('y2', 1)
      .style('stroke-width', 2)
      .style('stroke', '#909090')
      .style('fill', 'none');

    this.yLeftAxis = this.main
      .append('g')
      .attr('class', 'yAxis trailer-history-axis trailerHistoryYAxisLeft')
      .append('text')
      .attr('class', 'yLeftAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .attr('fill', '#0070B4')
      .attr('font-weight', 'bold')
      .attr('font-size', 12)
      .text(this.props.strings.speed);

    this.yRightAxis = this.main
      .append('g')
      .attr('class', 'yAxis trailer-history-axis trailerHistoryYAxisRight')
      .attr('transform', `translate(${this.props.width}, 0)`)
      .append('text')
      .attr('class', 'yRightAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '-0.9em')
      .attr('text-anchor', 'end')
      .attr('fill', '#DD0C29')
      .attr('font-weight', 'bold')
      .attr('font-size', 12)
      .text(this.props.strings.axleLoad);

    this.assetId = this.props.assetId;
    this.lastTimePos = 0;
    this.drawPin(state);
    this.update(state, true);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state, assetId) {
    if (state) {
      this.onPosChange = state.onPosChange;
      this.alertData = state.alerts || [];
      this.eventData = state.events || [];
      this.historyPosition = state.pos || {};
      this.showAlerts = !!state.showAlerts;
      this.endDate = state.endDate;
      this.isoData = state?.trailerHistory?.current?.isoCable ?? [];
      this.drawChart(state, assetId);
      if (!this.isDragging) {
        this.drawPin(state);
      } else {
        if (state.pos.posX < this.prevPos) {
          this.pinCircle.attr('fill', 'url(#image-rewind)');
        } else if (state.pos.posX > this.prevPos) {
          this.pinCircle.attr('fill', 'url(#image-forward)');
        }
        this.prevPos = (state && state.pos && state.pos.posX) || this.props.width;
      }
    }
  }

  drawChart(state, assetId) {
    const { isMetric } = this.props;
    this.data = (state.data || [])
      .map(d => ({ ...d, time: new Date(d.startTime * 1000) }))
      .sort((a, b) => a.time - b.time);

    let domainData = this.data ? d3.extent(this.data, d => d.time) : null;
    if (state.startDate && state.endDate) {
      domainData = [state.startDate * 1000, state.endDate * 1000];
    } else if (domainData && state.endDate) {
      domainData[1] = state.endDate * 1000;
    }
    this.data = this.data.filter(d => d.time >= domainData[0] && d.time <= domainData[1]);
    this.alertData = this.alertData.filter(d => d.time >= domainData[0] / 1000 && d.time <= domainData[1] / 1000);

    this.x = d3.scaleTime().range([0, this.props.width]).domain(domainData);

    this.xAxisPosLine.attr('x2', (state.pos && state.pos.posX) || this.props.width);

    const ySpeed = d3
      .scaleLinear()
      .range([this.props.height, 0])
      .domain([0, isMetric.distance ? 120 : 80])
      .nice();

    const yLoad = d3
      .scaleLinear()
      .range([this.props.height, 0])
      .domain([0, d3.max(this.data, d => d.startAxleLoad)])
      .nice();

    const area = (accessor, yScale) =>
      d3
        .area()
        .curve(d3.curveStepAfter)
        .x(d => this.x(d.time))
        .y0(yScale(0))
        .y1(d => yScale(d[accessor]));

    const line = (accessor, yScale) =>
      d3
        .line()
        .curve(d3.curveStepAfter)
        .x(d => this.x(d.time))
        .y(d => yScale(d[accessor]));

    const xAxis = d3
      .axisBottom(this.x)
      .tickFormat(date => dateMultiFormat(date, this.props.isUS, this.props.timeFormatLocale));
    const yLeftAxis = d3.axisLeft(ySpeed);

    yLeftAxis.ticks(5);

    const yRightAxis = d3.axisRight(yLoad);

    yRightAxis.ticks(5).tickFormat(v => d3.format('.2~r')(v / 1000));

    const dataSeries = [
      {
        color: '#0070B4',
        accessor: 'startSpeed',
        scale: ySpeed,
        data: this.data.filter(d => d.startSpeed !== null && d.startSpeed !== undefined)
      },
      {
        color: '#DD0C29',
        accessor: 'startAxleLoad',
        scale: yLoad,
        data: this.data.filter(d => d.startAxleLoad)
      }
    ];

    const areaCurve = this.areas.selectAll('.areaCurve').data(dataSeries.slice(0, 1));

    const areaCurveEnter = areaCurve.enter().append('path').attr('class', 'areaCurve');

    areaCurveEnter
      .merge(areaCurve)
      .attr('fill', d => d.color)
      .attr('opacity', 0.2)
      .attr('d', d => area(d.accessor, d.scale)(d.data));

    this.svg.selectAll('.line-data').remove();

    const lineCurve = this.areas.selectAll('.lineCurve').data(dataSeries);

    const lineCurveEnter = lineCurve.enter().append('path').attr('class', 'lineCurve');

    lineCurveEnter
      .merge(lineCurve)
      .attr('fill', 'none')
      .attr('stroke', d => d.color)
      .attr('stroke-width', 2)
      .attr('d', d => line(d.accessor, d.scale)(d.data))
      .attr('class', 'line-data');

    this.svg.select('.xAxis').call(xAxis);
    this.svg.select('.trailerHistoryYAxisLeft').call(yLeftAxis);
    this.svg.select('.trailerHistoryYAxisRight').call(yRightAxis);

    this.assetId = assetId;
    this.drawIsoRectangles();
    this.drawAlerts();
    this.drawEvents();
  }

  drawIsoRectangles() {
    this.iso.selectAll('.iso').remove();

    if (!this.showAlerts) {
      return;
    }

    const iso = this.iso
      .selectAll('.iso')
      .data(
        this.isoData.map(d => ({ start: new Date(d.start * 1000), end: d.end ? new Date(d.end * 1000) : new Date() }))
      );

    const isoEnter = iso.enter().append('g').attr('class', 'iso');

    isoEnter
      .append('rect')
      .attr('x', d => this.x(d.start))
      .attr('y', 5)
      .attr('width', d => this.x(d.end || this.endDate) - this.x(d.start))
      .attr('height', this.props.height - 7)
      .attr('fill', '#fad625')
      .attr('fill-opacity', '0.3')
      .on('mouseover', function () {
        d3.select(this).attr('fill-opacity', '0.5');
      })
      .on('mouseout', function () {
        d3.select(this).attr('fill-opacity', '0.3');
      });

    isoEnter
      .append('line')
      .attr('stroke', 'lightgrey')
      .attr('stoke-width', 0.5)
      .attr('x', d => this.x(d.end || this.endDate))
      .attr('y', -10)
      .attr('x2', 0)
      .attr('y2', this.props.height)
      .attr('transform', d => `translate( ${this.x(d.end) || 0}, 0)`);

    isoEnter
      .on('mouseover', d => {
        if (this.tooltip) {
          this.tooltip.style('display', 'none');
          this.tooltip.remove();
        }

        const startX = Math.max(this.x(d.start), 0);
        const isLeft = startX < this.props.width / 2;
        const rectX = isLeft ? startX + 30 : startX - 160;
        const textX = isLeft ? startX + 110 : startX - 80;

        this.tooltip = this.svg.append('g').attr('class', 'generic-tooltip').style('display', 'none');

        this.tooltip
          .append('rect')
          .attr('rx', 2)
          .attr('ry', 2)
          .attr('width', 160)
          .attr('height', 20)
          .attr('x', rectX)
          .attr('y', -20)
          .style('background', 'black')
          .style('background-color', 'black')
          .style('opacity', 0.75);

        this.tooltip
          .append('text')
          .attr('y', -20)
          .attr('x', textX)
          .attr('dy', '1.2em')
          .style('text-anchor', 'middle')
          .attr('font-size', '12px')
          .attr('fill', 'white');

        this.tooltip.style('display', 'block');
        this.tooltip.select('text').html(this.props.strings.isoTooltip);
      })
      .on('mouseout', () => {
        this.tooltip.style('display', 'none');
        this.tooltip.remove();
      });
  }

  drawAlerts() {
    // forcing rerender of the alert as the update with the viewBow does not work
    this.alerts.selectAll('.alert').remove();

    const alerts = this.alerts
      .selectAll('.alert')
      .data(this.alertData.map(d => ({ ...d, time: new Date(d.time * 1000) })));

    const alertEnter = alerts.enter().append('g').attr('class', 'alert');

    alertEnter
      .append('foreignObject')
      .attr('class', 'padding2')
      .attr('viewBox', d => `${(this.triggerTypes[d.alarm] || this.triggerTypes.default).x} 0 128 128`)
      .attr('id', (d, i) => `${d.assetId}_${i}`)
      .attr('x', -10)
      .attr('y', -17)
      .attr('width', 25)
      .attr('height', 25)
      .html(d => {
        return ReactDOMServer.renderToStaticMarkup(
          <div className={this.triggerTypes[d.alarm] ? this.triggerTypes[d.alarm].class : ''}>
            {d.geofences_details?.geofences[0]?.type ? (
              <img src={placesVariables[d.geofences_details?.geofences[0]?.type].iconUrl} className='places-icon' />
            ) : this.triggerTypes[d.alarm] ? (
              this.triggerTypes[d.alarm].getAvatarSVG()
            ) : (
              ''
            )}
          </div>
        );
      });

    alertEnter
      .append('use')
      .attr('xlink:href', (d, i) => (d.alarm ? `#${d.assetId}_${i}` : ''))
      .attr('width', 20)
      .attr('height', 20)
      .attr('x', -10)
      .attr('y', -19);

    alertEnter
      .append('line')
      .attr('stroke', 'lightgrey')
      .attr('stoke-width', 0.5)
      .attr('x', 0)
      .attr('y', -10)
      .attr('x2', 0)
      .attr('y2', this.props.height);

    const alertMerge = alertEnter.merge(alerts);

    alertMerge.attr('transform', d => `translate( ${this.x(d.time) ? this.x(d.time) : 0}, 0)`);
  }

  drawEvents() {
    this.events.selectAll('.event').remove();

    const events = this.events
      .selectAll('.event')
      .data(this.eventData.map(d => ({ ...d, time: new Date(d.timestamp) })));

    const eventEnter = events.enter().append('g').attr('class', 'event');

    eventEnter
      .append('circle')
      .attr('fill', d => d.color || 'grey')
      .attr('stroke', 'white')
      .attr('stroke-width', 2)
      .attr('r', 10)
      .attr('cx', 0)
      .attr('cy', -9);

    eventEnter
      .append('line')
      .attr('stroke', 'lightgrey')
      .attr('stoke-width', 0.5)
      .attr('x', 0)
      .attr('y', -10)
      .attr('x2', 0)
      .attr('y2', this.props.height);

    const eventMerge = eventEnter.merge(events);

    eventMerge.attr('transform', d => `translate( ${this.x(d.time) ? this.x(d.time) : 0}, 0)`);
  }
}
