/* eslint-disable react/display-name */
import React from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Tooltip, Badge } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import EventSummary from '../Events/EventSummary';
import ScoreDoc from '../Score/ScoreDoc';
import { triggerGenericEvent } from '../../helpers/googleAnalytics';
import { BrakesMicroView } from '../Utils/TireMicroView';
import TireMicroView from '../Utils/TireMicroView';
import * as strings from '../../helpers/defaultStrings';
import TrailerHistorySparkLine from '../charts/TrailerHistorySparkLine/TrailerHistorSparkLineComponent';
import battery0Img from '../../assets/images/batteryLevels/batteryLevel0.png';
import battery25Img from '../../assets/images/batteryLevels/batteryLevel25.png';
import battery25ChargingImg from '../../assets/images/batteryLevels/batteryLevel25Charging.png';
import battery50Img from '../../assets/images/batteryLevels/batteryLevel50.png';
import battery50ChargingImg from '../../assets/images/batteryLevels/batteryLevel50Charging.png';
import battery100Img from '../../assets/images/batteryLevels/batteryLevel100.png';
import battery100ChargingImg from '../../assets/images/batteryLevels/batteryLevel100Charging.png';
import drivingActivityImg from '../../assets/images/driving.png';
import parkedActivityImg from '../../assets/images/parking.png';
import pausedActivityImg from '../../assets/images/pause.png';
import AlertTimeline from '../charts/AlertTimeline/AlertTimelineComponent';
import {
  getCheckedGroups,
  isUsaCustomer,
  isContainsTrailerPulseBattery,
  getTimeDate,
  getIsRSSPlus
} from '../../helpers/functions';
import { getTriggerTitles } from '../../helpers/alarms/functions';
import { defaultIsMetric, ebsBrand, selectionHeaders, COLUMNS, TAG_MAP } from '../../helpers/constants';
import { getConversionString, convert, unitType } from '../../helpers/unitConverter';
import WheelLayoutInfoToolTip from '../TrailerMap/WheelLayoutInfoToolTip';
import './TrailerColumns.scss';
import OdyTag from '../../ReusableComponents/Tag/OdyTag';
import ColumnInfoModal from '../Utils/ColumnInfoModal';
import {
  statusLabelSort,
  metaDataTrailerMakeSort,
  metaDataTrailerModelSort
} from '../../helpers/sorters/column-sorters';

const getActivityIcon = status => {
  if (status === 'driving') {
    return <img src={drivingActivityImg} alt='driving' style={{ width: 20 }} />;
  }
  if (status === 'parked') {
    return <img src={parkedActivityImg} alt='parked' style={{ width: 20 }} />;
  }
  if (status === 'paused') {
    return <img src={pausedActivityImg} alt='paused' style={{ width: 20 }} />;
  }
  return null;
};

let lastFilterTime = 0;

const noValueSpan = formatMessage => <span className='disabled'>{formatMessage(strings.short.singleHyphen)}</span>;

const getBatteryImage = battery => {
  const { level } = battery;
  const isCharging = battery.status === 'Charging';
  switch (level) {
    case 'low':
      return isCharging ? battery25ChargingImg : battery25Img;
    case 'medium':
      return isCharging ? battery50ChargingImg : battery50Img;
    case 'high':
      return isCharging ? battery100ChargingImg : battery100Img;
    default:
      return battery0Img;
  }
};

export const getColumnObject = (keys, props = {}) => {
  const { devices, groups, formatMessage, region, tableId, setGroupColumns } = props;
  const isUS = isUsaCustomer(region);

  let columnKeys = [...keys];
  let addBreakPadColumn = false;
  if (devices && !isUS) {
    for (let device of devices) {
      if (device.brakePads) {
        addBreakPadColumn = true;
        break;
      }
    }
  }
  if (addBreakPadColumn) {
    columnKeys.push('brakePads');
  }
  if (isContainsTrailerPulseBattery(devices)) {
    columnKeys.push('power');
  }

  let staticColumns = columns({ ...props, isUS: isUS }).filter(column => {
    return columnKeys.includes(column.key);
  });

  let checkedGroups = getCheckedGroups(devices, groups);

  const groupColumns = checkedGroups
    ? checkedGroups.map(group => {
        const title = formatMessage(strings.short.groupNameVar, { VAR_GROUP_NAME: group.name });
        return {
          title: <Tooltip title={title}>{title}</Tooltip>,
          searchKey: title,
          selectionSection: selectionHeaders.groups.id,
          shortTitle: title,
          dataIndex: group.name,
          key: group.id,
          ellipsis: true,
          render: (unusedVariable, trailer) => {
            const groupIds = trailer.groups;
            if (groupIds?.length) {
              const subgroup = group.subgroups.filter(subgroup => groupIds.includes(subgroup.id))[0];
              if (subgroup) {
                return subgroup.name;
              }
            }
            return noValueSpan(formatMessage);
          },
          filters: group.subgroups.map(subgroup => {
            return { text: subgroup.name, value: subgroup.id };
          }),
          onFilter: (value, record) => record.groups && record.groups.includes(value),
          width: 150
        };
      })
    : [];
  if (setGroupColumns) {
    setGroupColumns(groupColumns);
  }
  const returnColumns = staticColumns.concat(groupColumns);
  if (tableId === 'drawerList') {
    const wrappedRenderColumns = returnColumns.map(column => {
      const wrappedRender = (text, record, index) => {
        if (record?.ebsBrand === ebsBrand.wabco) {
          return column.render ? column.render(text, record, index) : text;
        } else {
          return (
            <Tooltip placement='bottom' title={formatMessage(strings.description.idCardUnavailable)}>
              <div className='disabled-row'>{column.render ? column.render(text, record, index) : text}</div>
            </Tooltip>
          );
        }
      };
      return { ...column, render: wrappedRender };
    });
    return wrappedRenderColumns;
  }
  return returnColumns;
};

const GOHCBar = ({ gohc, formatMessage }) => {
  if (gohc && gohc.status) {
    const perc = gohc.percOfTimeSpent;
    let width = perc;
    if (perc >= 100) {
      width = 100;
    } else if (perc <= 10) {
      width = 10;
    }

    const tooltip = (
      <div>
        <div>
          {formatMessage(strings.short.customerName)}: {gohc.service_name_cust}
        </div>
        <div>
          {formatMessage(strings.short.hoursSinceService)}: {gohc.timeInService}
        </div>
        <div>
          {formatMessage(strings.short.hoursUntilService)}: {Math.max(0, gohc.serviceTime - gohc.timeInService)}
        </div>
      </div>
    );
    return (
      <Tooltip title={tooltip} placement='top'>
        <div className='bar-container'>
          <div className='bar-outline'>
            <div style={{ width: `${width}%` }} className={`bar-contents ${gohc.status}`}></div>
          </div>
          <div className='bar-title'>
            {!!(gohc.status === 'empty') && formatMessage(strings.short.recentlyServiced)}
            {!!(gohc.status === 'safe' || gohc.status === 'warning') &&
              formatMessage(strings.short.hrsLeftTillService, {
                value: gohc.serviceTime - gohc.timeInService
              })}
            {!!(gohc.status === 'danger') &&
              formatMessage(strings.short.hrsOverdueService, {
                value: gohc.timeInService - gohc.serviceTime
              })}
          </div>
        </div>
      </Tooltip>
    );
  }
  return noValueSpan(formatMessage);
};

const isInactive = ({ activityStatus, lastEBSValid }) =>
  !lastEBSValid || (activityStatus !== null && activityStatus.status === 'parked');

const hasPosition = trailer => trailer.gnss && trailer.gnss.longitude && trailer.gnss.latitude;

const columns = ({
  startDate,
  endDate,
  tableId,
  formatMessage,
  devices,
  region,
  productFeatures,
  isMetric = defaultIsMetric,
  isUS
}) => {
  const scoreDocProps = {
    isDeprecatedVariant: true,
    devices,
    isMetric: isMetric?.distance
  };

  return [
    {
      title: formatMessage(strings.short.country),
      searchKey: formatMessage(strings.short.country),
      shortTitle: formatMessage(strings.short.country),
      dataIndex: 'location',
      key: 'country',
      ellipsis: true,
      render: (location, trailer) => {
        return (
          <div>
            {' '}
            {location
              ? location.country || noValueSpan(formatMessage)
              : hasPosition(trailer)
              ? formatMessage(strings.short.loadingIndictor)
              : noValueSpan(formatMessage)}
          </div>
        );
      },
      width: 120
    },
    {
      title: formatMessage(strings.short.region),
      searchKey: formatMessage(strings.short.region),
      shortTitle: formatMessage(strings.short.region),
      dataIndex: 'location',
      key: 'region',
      ellipsis: true,
      render: (location, trailer) => {
        const value = location
          ? location.region || noValueSpan(formatMessage)
          : hasPosition(trailer)
          ? formatMessage(strings.short.loadingIndictor)
          : noValueSpan(formatMessage);
        return <Tooltip title={value}>{value}</Tooltip>;
      },
      width: 180
    },
    {
      title: formatMessage(strings.short.city),
      searchKey: formatMessage(strings.short.city),
      shortTitle: formatMessage(strings.short.city),
      dataIndex: 'location',
      key: 'city',
      ellipsis: true,
      render: (location, trailer) => {
        const value = location
          ? location.place || location.locality || noValueSpan(formatMessage)
          : hasPosition(trailer)
          ? formatMessage(strings.short.loadingIndictor)
          : noValueSpan(formatMessage);
        return <Tooltip title={value}>{value}</Tooltip>;
      },
      width: 120
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.abbrev.vehicleIdentificationNumber)}>
          {formatMessage(strings.abbrev.vehicleIdentificationNumber)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.abbrev.vehicleIdentificationNumber),
      dataIndex: 'vinNumber',
      key: 'vinNumber',
      selectionSection: selectionHeaders.generalInfo.id,
      filterType: 'search',
      render: vinNumber => vinNumber || noValueSpan(formatMessage),
      ellipsis: true,
      width: 170
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.abbrev.internationalMobileEquipmentIdentity)}>
          {formatMessage(strings.abbrev.internationalMobileEquipmentIdentity)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.abbrev.internationalMobileEquipmentIdentity),
      dataIndex: 'imei',
      key: 'imei',
      selectionSection: selectionHeaders.trailerSystems.id,
      filterType: 'search',
      sorter: (a, b) => {
        if (a.imei < b.imei) return -1;
        if (a.imei > b.imei) return 1;
        return 0;
      },
      showSorterTooltip: false,
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns.healthStatus)}>
          {formatMessage(strings.columns.healthStatus)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.healthStatus),
      dataIndex: 'statusLabel',
      key: 'statusLabel',
      selectionSection: selectionHeaders.healthMonitoring.id,
      render: (text, record) => {
        const { statusLabel } = record;
        return record.statusLabelId ? (
          <OdyTag type={TAG_MAP[record.statusLabelId]}>
            {statusLabel && statusLabel.props && formatMessage(strings[statusLabel.props.type][statusLabel.props.id])}
          </OdyTag>
        ) : (
          noValueSpan(formatMessage)
        );
      },
      sorter: statusLabelSort,
      showSorterTooltip: false,
      filters: [
        { text: formatMessage(strings.short.critical), value: 'critical' },
        { text: formatMessage(strings.short.warning), value: 'warning' },
        { text: formatMessage(strings.short.info), value: 'info' },
        { text: formatMessage(strings.short.safe), value: 'safe' },
        { text: formatMessage(strings.short.unknown), value: 'unknown' }
      ],
      onFilter: (value, record) => {
        if (Date.now() - lastFilterTime > 1500) {
          triggerGenericEvent('Filter', 'Filter', 'status');
          lastFilterTime = Date.now();
        }
        return (value === 'unknown' && !record.statusLabelId) || record.statusLabelId === value.toString();
      },
      width: 150
    },
    {
      title: <Tooltip title={formatMessage(strings.columns.since)}>{formatMessage(strings.columns.since)}</Tooltip>,
      searchKey: formatMessage(strings.columns.since),
      dataIndex: 'since',
      selectionSection: selectionHeaders.trailerSystems.id,
      key: 'since',
      sorter: (a, b) => {
        if (!a?.sinceDays) {
          return -1;
        }
        if (a.sinceDays === b.sinceDays) {
          return a.sinceKm > b.sinceKm ? -1 : 1;
        }
        return a.sinceDays > b.sinceDays ? -1 : 1;
      },
      showSorterTooltip: false,
      render: (since, record) => {
        if (record.sinceDays === null && record.sinceKm === null) {
          return noValueSpan(formatMessage);
        }
        return (
          <div>
            {record.sinceDays === 0 && formatMessage(strings.short.today)}
            {record.sinceDays === 1 && formatMessage(strings.short.yesterday)}
            {record.sinceDays > 1 &&
              formatMessage(strings.short.numDaysVal, { value: record.sinceDays != null ? record.sinceDays : '-' })}
            {record.sinceDays !== null && record.sinceDays >= 0 && record.sinceKm !== null && <span>{' / '}</span>}
            {record.sinceKm && (
              <span>
                {record.sinceKm < 1 ? 0 : convert(isMetric, unitType.distance, record.sinceKm)}{' '}
                {formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile)}
              </span>
            )}
          </div>
        );
      },
      width: 150
    },
    {
      title: (
        <>
          {formatMessage(strings.columns.healthScore)}
          <ScoreDoc {...scoreDocProps} columnKey={COLUMNS.HEALTH_SCORE} />
        </>
      ),
      searchKey: formatMessage(strings.columns.healthScore),
      shortTitle: (
        <Tooltip title={formatMessage(strings.columns.healthScore)}>
          {formatMessage(strings.columns.healthScore)}
        </Tooltip>
      ),
      dataIndex: COLUMNS.HEALTH_SCORE,
      selectionSection: selectionHeaders.healthMonitoring.id,
      key: COLUMNS.HEALTH_SCORE,
      sorter: (a, b) =>
        a.healthScore === null || a.healthScore === undefined || a.healthScore < b.healthScore ? -1 : 1,
      showSorterTooltip: false,
      render: (healthScore, record) => {
        return healthScore != null ? (
          <Badge dot={record.healthScoreType === 'mileageBased'}>{numeral(healthScore).format('0.0')}</Badge>
        ) : (
          noValueSpan(formatMessage)
        );
      },
      defaultSortOrder: tableId === 'trailerHealth' || tableId === 'drawerList' ? 'descend' : null,
      width: 120
    },
    {
      title: (
        <div>
          {formatMessage(strings.short.gohc)}
          <ColumnInfoModal
            title={formatMessage(strings.short.gohc)}
            body={
              <div className='large-margin-top'>
                <p>
                  <b>{formatMessage(strings.phrase.gioOperatingHrsCounter)}</b>
                </p>
                <p>{formatMessage(strings.description.functionMonitorInputSignal)}</p>
                <p>{formatMessage(strings.description.hrsTillNextService)}</p>
                <p>{formatMessage(strings.description.wabcoEBSOnly)}</p>
                <p>{formatMessage(strings.description.seeDocForMoreInfo)}</p>
              </div>
            }
          />
        </div>
      ),
      shortTitle: <Tooltip title={formatMessage(strings.short.gohc)}>{formatMessage(strings.short.gohc)}</Tooltip>,
      searchKey: formatMessage(strings.short.gohc),
      dataIndex: 'GOHC',
      selectionSection: selectionHeaders.healthMonitoring.id,
      key: 'gohc',
      width: 170,
      sorter: (a, b) => {
        const a_percOfTimeSpent = a.GOHC && a.GOHC.percOfTimeSpent;
        const b_percOfTimeSpent = b.GOHC && b.GOHC.percOfTimeSpent;
        return a_percOfTimeSpent === null || a_percOfTimeSpent === undefined || a_percOfTimeSpent < b_percOfTimeSpent
          ? -1
          : 1;
      },
      showSorterTooltip: false,
      filters: [
        { text: formatMessage(strings.short.serviceEmpty), value: 'empty' },
        { text: formatMessage(strings.short.serviceSafe), value: 'safe' },
        { text: formatMessage(strings.short.serviceWarning), value: 'warning' },
        { text: formatMessage(strings.short.serviceDanger), value: 'danger' },
        { text: formatMessage(strings.short.unknown), value: 'unknown' }
      ],
      onFilter: (value, record) => {
        if (Date.now() - lastFilterTime > 1500) {
          triggerGenericEvent('Filter', 'Filter', 'gohc');
          lastFilterTime = Date.now();
        }
        if (record.GOHC && record.GOHC.status) {
          return record.GOHC.status === value.toString();
        } else {
          return value === 'unknown';
        }
      },
      render: gohc => <GOHCBar gohc={gohc} formatMessage={formatMessage} />
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns.batteryLevel)}>
          {formatMessage(strings.columns.batteryLevel)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.batteryLevel),
      shortTitle: formatMessage(strings.columns.batteryLevel),
      key: 'power',
      dataIndex: 'power',
      selectionSection: selectionHeaders.healthMonitoring.id,
      ellipsis: true,
      render: power => {
        const powerData = power && power.battery && power.battery.level;
        if (powerData) {
          return (
            <div>
              <img alt={power.battery.level} src={getBatteryImage(power.battery)} className='list-item-icon' />
              {formatMessage(strings.short[power.battery.level])}
            </div>
          );
        }
        return noValueSpan(formatMessage);
      },
      filters: [
        { text: formatMessage(strings.short.high), value: 'high' },
        { text: formatMessage(strings.short.medium), value: 'medium' },
        { text: formatMessage(strings.short.low), value: 'low' },
        { text: formatMessage(strings.short.dead), value: 'dead' },
        { text: formatMessage(strings.short.unknown), value: 'unknown' }
      ],
      onFilter: (value, record) => {
        if (Date.now() - lastFilterTime > 1500) {
          triggerGenericEvent('Filter', 'Filter', 'power');
          lastFilterTime = Date.now();
        }
        if (record.power && record.power.battery && record.power.battery.level) {
          return record.power.battery.level === value.toString();
        }
        return value === 'unknown';
      },
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.brakeLining)}>{formatMessage(strings.short.brakeLining)}</Tooltip>
      ),
      searchKey: formatMessage(strings.short.brakeLining),
      dataIndex: 'brakePads',
      selectionSection: selectionHeaders.healthMonitoring.id,
      key: 'brakePads',
      ellipsis: true,
      render: (brakePads, trailer) => {
        let status = 0;
        if (brakePads) {
          brakePads.forEach(pad => {
            if (pad.lining) {
              if (status !== 2) {
                status = pad.lining.suff ? 1 : 2;
              }
            }
          });
        }

        const light = (status, semiTransparent) => {
          if (!status) return '-';
          let backgroundColor;

          if (status === 1) {
            backgroundColor = semiTransparent ? '#eef8f2' : '#59bc7c';
          } else {
            backgroundColor = semiTransparent ? '#f9ecee' : '#b00920';
          }
          const border = status === 1 ? '1px solid #59bc7c' : '1px solid #b00920';
          return (
            <div
              style={{
                width: 15,
                height: 15,
                borderRadius: 15,
                backgroundColor,
                border
              }}
            />
          );
        };
        const semiTransparent = isInactive(trailer);
        const lastConnect = semiTransparent && new Date(trailer.ebsTime * 1000);
        return (
          <div>
            {lastConnect && (
              <Tooltip
                title={
                  <BrakesMicroView
                    brakePads={brakePads}
                    trailer={trailer}
                    semiTransparent={semiTransparent}
                    lastConnect={lastConnect}
                  />
                }
                placement='topLeft'
              >
                <span>{light(status, semiTransparent)}</span>
              </Tooltip>
            )}
            {!lastConnect && <span>{light(status, semiTransparent)}</span>}
          </div>
        );
      },
      width: 150
    },
    {
      title: (
        <>
          {formatMessage(strings.columns.recencyScore)}
          <ScoreDoc {...scoreDocProps} columnKey={COLUMNS.RECENCY_SCORE} />
        </>
      ),
      searchKey: formatMessage(strings.columns.recencyScore),
      shortTitle: formatMessage(strings.columns.recencyScore),
      dataIndex: COLUMNS.RECENCY_SCORE,
      key: COLUMNS.RECENCY_SCORE,
      selectionSection: selectionHeaders.healthMonitoring.id,
      sorter: (a, b) =>
        a.recencyScore === null || a.recencyScore === undefined || a.recencyScore < b.recencyScore ? -1 : 1,
      render: recencyScore =>
        recencyScore != null ? `${numeral(recencyScore).format('0.0')}` : noValueSpan(formatMessage),
      showSorterTooltip: false,
      width: 120
    },
    {
      title: (
        <>
          {formatMessage(strings.columns.durationScore)}
          <ScoreDoc {...scoreDocProps} columnKey={COLUMNS.DURATION_SCORE} />
        </>
      ),
      searchKey: formatMessage(strings.columns.durationScore),
      shortTitle: formatMessage(strings.columns.durationScore),
      dataIndex: COLUMNS.DURATION_SCORE,
      key: COLUMNS.DURATION_SCORE,
      selectionSection: selectionHeaders.healthMonitoring.id,
      sorter: (a, b) =>
        a.durationScore === null || a.durationScore === undefined || a.durationScore < b.durationScore ? -1 : 1,
      render: durationScore =>
        durationScore != null ? `${numeral(durationScore).format('0.0')}` : noValueSpan(formatMessage),
      showSorterTooltip: false,
      width: 120
    },
    {
      title: (
        <>
          {formatMessage(strings.columns.frequencyScore)}
          <ScoreDoc {...scoreDocProps} columnKey={COLUMNS.FREQUENCY_SCORE} />
        </>
      ),
      searchKey: formatMessage(strings.columns.frequencyScore),
      shortTitle: formatMessage(strings.columns.frequencyScore),
      dataIndex: COLUMNS.FREQUENCY_SCORE,
      key: COLUMNS.FREQUENCY_SCORE,
      selectionSection: selectionHeaders.healthMonitoring.id,
      sorter: (a, b) =>
        a.frequencyScore === null || a.frequencyScore === undefined || a.frequencyScore < b.frequencyScore ? -1 : 1,
      render: frequencyScore =>
        frequencyScore != null ? `${numeral(frequencyScore).format('0.0')}` : noValueSpan(formatMessage),
      showSorterTooltip: false,
      width: 120
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.odrParsingStatus)}>
          {formatMessage(strings.short.odrParsingStatus)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.short.odrParsingStatus),
      shortTitle: formatMessage(strings.short.odrParsingStatus),
      dataIndex: 'odrStatus',
      key: 'odrStatus',
      selectionSection: selectionHeaders.trailerSystems.id,
      sorter: (a, b) =>
        a.odrStatus.id === null || a.odrStatus.id === undefined || a.odrStatus.id < b.odrStatus.id ? -1 : 1,
      render: odrStatus => {
        return odrStatus && strings[odrStatus.type][odrStatus.id]
          ? formatMessage(strings[odrStatus.type][odrStatus.id], { value: odrStatus.val })
          : noValueSpan(formatMessage);
      },
      showSorterTooltip: false,
      width: 200
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns.lightMonitoring)}>
          {formatMessage(strings.columns.lightMonitoring)}
          <ColumnInfoModal
            title={formatMessage(strings.columns.lightMonitoring)}
            body={
              <div>
                <div className='large-margin-top'>{formatMessage(strings.lightMonitoringStatus.columnDescription)}</div>
                <div className='large-margin-top'>
                  <div>
                    <span className='anomaly'>&#9679;</span>{' '}
                    {formatMessage(strings.lightMonitoringStatus.warningStatusDescription)}
                  </div>
                  <div>
                    <span className='outage'>&#9679;</span>{' '}
                    {formatMessage(strings.lightMonitoringStatus.outageStatusDescription)}
                  </div>
                  <div>
                    <span className='good'>&#9679;</span>{' '}
                    {formatMessage(strings.lightMonitoringStatus.goodStatusDescription)}
                  </div>
                  <div>
                    <span className='unknown'>&#9679;</span>{' '}
                    {formatMessage(strings.lightMonitoringStatus.unknownStatusDescription)}
                  </div>
                </div>
                <div className='large-margin-top'>{formatMessage(strings.lightMonitoringStatus.fullInfo)}</div>
              </div>
            }
          />
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.lightMonitoring),
      shortTitle: formatMessage(strings.columns.lightMonitoring),
      dataIndex: 'lightMonitoring',
      key: 'lightMonitoring',
      selectionSection: selectionHeaders.healthMonitoring.id,
      sorter: (a, b) => (a.lightMonitoring?.severity < b.lightMonitoring?.severity ? -1 : 1),
      render: lightMonitoring => {
        const { type, value, tooltip } = lightMonitoring;

        if (type) {
          return (
            <OdyTag type={type}>
              <Tooltip title={formatMessage(strings.lightMonitoringStatus[tooltip])}>
                {formatMessage(strings.lightMonitoringStatus[value])}
              </Tooltip>
            </OdyTag>
          );
        }

        return noValueSpan(formatMessage);
      },
      showSorterTooltip: false,
      width: 180
    },
    {
      title: (
        <div>
          {formatMessage(strings.phrase.recentSummary)}
          <ColumnInfoModal
            title={formatMessage(strings.phrase.recentSummary)}
            body={
              <div>
                {formatMessage(
                  isMetric.distance ? strings.description.recentSummary : strings.description.recentSummaryMile
                )}
                <div style={{ paddingLeft: 16 }} className='large-margin-top'>
                  <div>
                    <span style={{ color: 'lightgrey' }}>&#9679;</span> {formatMessage(strings.short.unknownStatus)}
                  </div>
                  <div>
                    <span className='safe'>&#9679;</span> {formatMessage(strings.short.noIssue)}
                  </div>
                  <div>
                    <span className='info'>&#9679;</span> {formatMessage(strings.short.info)}
                  </div>
                  <div>
                    <span className='warning'>&#9679;</span> {formatMessage(strings.short.warning)}
                  </div>
                  <div>
                    <span className='critical'>&#9679;</span> {formatMessage(strings.short.critical)}
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ),
      searchKey: formatMessage(strings.phrase.recentSummary),
      shortTitle: formatMessage(strings.phrase.recentSummary),
      dataIndex: 'events',
      key: 'recentSummary',
      selectionSection: selectionHeaders.healthMonitoring.id,
      ellipsis: true,
      render: (events, record) => {
        if (record.eepromSize === 32 && !getIsRSSPlus(record)) {
          return (
            <div className='medium-margin-left'>
              {formatMessage(isUsaCustomer(region) ? strings.phrase.noAbsSupport : strings.phrase.noEbsSupport)}
            </div>
          );
        } else if (record.events?.length || record.odoEvents?.length || record.eventDomain) {
          return (
            <EventSummary
              events={record.events}
              start={startDate}
              end={endDate}
              eventDomain={record.eventDomain}
              odoEvents={record.odoEvents}
              odoStart={Math.max(record.lastMileage || 0, record.odoEventDomain ? record.odoEventDomain[1] : 0) - 10000}
              odoEnd={Math.max(record.lastMileage || 0, record.odoEventDomain ? record.odoEventDomain[1] : 0)}
              odoEventDomain={record.odoEventDomain}
              width={150}
              height={8}
              showWithoutEvents
              isMetric={isMetric}
            />
          );
        }
        return noValueSpan(formatMessage);
      },
      width: 200
    },
    {
      title: <Tooltip title={formatMessage(strings.short.brand)}>{formatMessage(strings.short.brand)}</Tooltip>,
      searchKey: formatMessage(strings.short.brand),
      key: 'brand',
      dataIndex: 'brand',
      sorter: (a, b) => (a.brand === null || a.brand === undefined || a.brand < b.brand ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'search',
      width: 200
    },
    {
      title: <Tooltip title={formatMessage(strings.short.axles)}>{formatMessage(strings.short.axles)}</Tooltip>,
      searchKey: formatMessage(strings.short.axles),
      key: 'axleCount',
      dataIndex: 'axleCount',
      sorter: (a, b) => (a.axleCount === null || a.axleCount === undefined || a.axleCount < b.axleCount ? -1 : 1),
      filterType: 'categorical',
      showSorterTooltip: false,
      width: 150
    },
    {
      title: <Tooltip title={formatMessage(strings.short.type)}>{formatMessage(strings.short.type)}</Tooltip>,
      searchKey: formatMessage(strings.short.type),
      key: 'vehicleType',
      dataIndex: 'vehicleType',
      sorter: (a, b) =>
        a.vehicleType === null || a.vehicleType === undefined || a.vehicleType < b.vehicleType ? -1 : 1,
      filterType: 'categorical',
      showSorterTooltip: false,
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.productionDate)}>
          {formatMessage(strings.short.productionDate)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.short.productionDate),
      dataIndex: 'productionDate',
      key: 'productionDate',
      render: value => <div>{value ? value.local().format('l') : formatMessage(strings.short.unknown)}</div>,
      sorter: (a, b) =>
        a.productionDate === null || a.productionDate === undefined || a.productionDate.diff(b.productionDate) < 0
          ? -1
          : 1,
      showSorterTooltip: false,
      width: 150
    },
    {
      title: <Tooltip title={formatMessage(strings.short.name)}>{formatMessage(strings.short.name)}</Tooltip>,
      searchKey: formatMessage(strings.short.name),
      shortTitle: formatMessage(strings.short.name),
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      filterType: 'search',
      width: 250
    },
    {
      title: <Tooltip title={formatMessage(strings.short.trailer)}>{formatMessage(strings.short.trailer)}</Tooltip>,
      searchKey: formatMessage(strings.short.trailer),
      shortTitle: formatMessage(strings.short.trailer),
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'trailer',
      key: 'trailer',
      ellipsis: true,
      filterType: 'search',
      altText: formatMessage(strings.short.noTrailer),
      width: 200,
      render: value => (value ? value : formatMessage(strings.short.noTrailer))
    },
    {
      title: <Tooltip title={formatMessage(strings.short.code)}>{formatMessage(strings.short.code)}</Tooltip>,
      searchKey: formatMessage(strings.short.code),
      shortTitle: formatMessage(strings.short.code),
      dataIndex: 'code',
      selectionSection: selectionHeaders.generalInfo.id,
      filterType: 'search',
      ellipsis: true,
      key: 'code',
      width: 150
    },
    // live map
    //
    {
      title: <Tooltip title={formatMessage(strings.short.speed)}>{formatMessage(strings.short.speed)}</Tooltip>,
      searchKey: formatMessage(strings.short.speed),
      key: 'speed',
      dataIndex: 'speed',
      selectionSection: selectionHeaders.generalMonitoring.id,
      sorter: (a, b) => {
        if (!a || !a.speed) {
          return -1;
        }

        if (!b || !b.speed) {
          return 1;
        }

        return a.speed - b.speed;
      },
      showSorterTooltip: false,
      render: (speed, trailer) => {
        const val = {
          value: formatMessage(...getConversionString(isMetric, speed, unitType.speed)),
          time: getTimeDate(trailer.ebsTime * 1000)
        };
        return (
          <span>
            {trailer && speed !== null && speed !== undefined && isInactive(trailer) && (
              <Tooltip title={formatMessage(strings.short.ebsTimeVal, val)} placement='topLeft'>
                <span style={{ color: '#ccc' }}>
                  {convert(isMetric, unitType.speed, speed)}{' '}
                  <small>
                    {formatMessage(isMetric.distance ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour)}
                  </small>
                </span>
              </Tooltip>
            )}
            {trailer && speed !== null && speed !== undefined && !isInactive(trailer) && (
              <span>
                {convert(isMetric, unitType.speed, speed)}{' '}
                <small>
                  {formatMessage(isMetric.distance ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour)}
                </small>
              </span>
            )}
            {(!trailer || speed === null || speed === undefined) && <small>-</small>}
          </span>
        );
      },
      width: 100
    },
    {
      title: <Tooltip title={formatMessage(strings.short.odometer)}>{formatMessage(strings.short.odometer)}</Tooltip>,
      searchKey: formatMessage(strings.short.odometer),
      key: 'odometer',
      dataIndex: 'odometer',
      selectionSection: selectionHeaders.generalMonitoring.id,
      sorter: (a, b) => {
        if (!a || !a.odometer) {
          return -1;
        }

        if (!b || !b.odometer) {
          return 1;
        }

        return a.odometer - b.odometer;
      },
      showSorterTooltip: false,
      render: (odometer, trailer) => {
        const title =
          trailer && trailer.ebsTime
            ? formatMessage(strings.short.ebsTimeVal, {
                value: formatMessage(...getConversionString(isMetric, odometer, unitType.distance)),
                time: getTimeDate(trailer.ebsTime * 1000)
              })
            : formatMessage(strings.short.odometer);
        return (
          <span>
            {trailer && odometer !== null && odometer !== undefined && isInactive(trailer) && (
              <Tooltip title={title} placement='topLeft'>
                <span style={{ color: '#ccc' }}>
                  {convert(isMetric, unitType.distance, odometer)}{' '}
                  <small>{formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile)}</small>
                </span>
              </Tooltip>
            )}
            {trailer && odometer !== null && odometer !== undefined && !isInactive(trailer) && (
              <span>
                {convert(isMetric, unitType.distance, odometer)}{' '}
                <small>{formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile)}</small>
              </span>
            )}
            {(!trailer || odometer === null || odometer === undefined) && <small>-</small>}
          </span>
        );
      },
      width: 100
    },
    {
      title: <Tooltip title={formatMessage(strings.short.load)}>{formatMessage(strings.short.load)}</Tooltip>,
      searchKey: formatMessage(strings.short.load),
      key: 'axleLoad',
      selectionSection: selectionHeaders.generalMonitoring.id,
      dataIndex: 'axleLoad',
      sorter: (a, b) => {
        if (!a || !a.axleLoad) {
          return -1;
        }

        if (!b || !b.axleLoad) {
          return 1;
        }

        return a.axleLoad - b.axleLoad;
      },
      showSorterTooltip: false,
      render: (axleLoad, trailer) => {
        const isUS = isUsaCustomer(region);
        const val = {
          value:
            convert(isMetric, unitType.ton, axleLoad / 1000, isUS) +
            ' ' +
            formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton),
          time: getTimeDate(trailer.ebsTime * 1000)
        };

        return (
          <span>
            {trailer && axleLoad !== null && axleLoad !== undefined && isInactive(trailer) && (
              <Tooltip title={formatMessage(strings.short.ebsTimeVal, val)} placement='topLeft'>
                <span style={{ color: '#ccc' }}>
                  {convert(isMetric, unitType.ton, axleLoad / 1000, isUS)}{' '}
                  <small>
                    {formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton)}
                  </small>
                </span>
              </Tooltip>
            )}
            {trailer && axleLoad !== null && axleLoad !== undefined && !isInactive(trailer) && (
              <span>
                {convert(isMetric, unitType.ton, axleLoad / 1000, isUS)}{' '}
                <small>
                  {formatMessage(!isMetric.weight && isUS ? strings.abbrev.kiloPoundUSA : strings.abbrev.ton)}
                </small>
              </span>
            )}
            {(!trailer || axleLoad === null || axleLoad === undefined) && <small>-</small>}
          </span>
        );
      },
      width: 100
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.lastPosition)}>{formatMessage(strings.short.lastPosition)}</Tooltip>
      ),
      searchKey: formatMessage(strings.short.lastPosition),
      key: 'time',
      dataIndex: 'lastPosition',
      selectionSection: selectionHeaders.generalMonitoring.id,
      sorter: (a, b) => (a.lastPosition < b.lastPosition ? -1 : 1),
      showSorterTooltip: false,
      render: lastPosition => {
        if (!lastPosition) {
          return formatMessage(strings.short.unknown);
        }

        // catch the >=22h <=36h period and instead of moment showing 'a day ago' we enforce showing hours
        const timeAgo = Math.round(Date.now() / 1000) - lastPosition;
        const hoursAgo = Math.round(timeAgo / (60 * 60));

        return (
          <Tooltip title={getTimeDate(lastPosition * 1000)} placement='topLeft'>
            {hoursAgo >= 22 && hoursAgo <= 36
              ? formatMessage(strings.short.hoursAgoVal, { value: hoursAgo })
              : moment
                  .utc(lastPosition * 1000)
                  .local()
                  .fromNow()}
          </Tooltip>
        );
      },
      width: 160
    },
    {
      title: <EyeTwoTone />,
      searchKey: formatMessage(strings.short.notVisible) + formatMessage(strings.short.visible),
      dataIndex: 'visibility',
      selectionSection: selectionHeaders.map.id,
      sorter: (a, b) => {
        if (a.visibility < b.visibility) {
          return -1;
        }
        if (a.visibility > b.visibility) {
          return 1;
        }
        return 0;
      },
      showSorterTooltip: false,
      render: text => (text === formatMessage(strings.short.visible) ? <EyeTwoTone /> : <EyeInvisibleOutlined />),
      key: 'visibility',
      filters: [
        {
          text: formatMessage(strings.short.visible),
          value: 'Visible'
        },
        {
          text: formatMessage(strings.short.notVisible),
          value: 'Not visible'
        }
      ],
      onFilter: (value, record) => {
        if (Date.now() - lastFilterTime > 1500) {
          triggerGenericEvent('Filter', 'Filter', 'visibility');
          lastFilterTime = Date.now();
        }
        return record.visibility === value;
      },
      width: 80
    },
    {
      title: <Tooltip title={formatMessage(strings.columns.last2h)}>{formatMessage(strings.columns.last2h)}</Tooltip>,
      searchKey: formatMessage(strings.columns.last2h),
      shortTitle: formatMessage(strings.columns.last2h),
      dataIndex: 'trail',
      key: 'trail',
      selectionSection: selectionHeaders.generalMonitoring.id,
      ellipsis: true,
      render: value => {
        return (
          <TrailerHistorySparkLine
            width={100}
            height={30}
            data={
              !value
                ? null
                : value.map(v => ({
                    time: new Date(v.startTime),
                    speed: new Date(v.speed)
                  }))
            }
          />
        );
      },
      width: 120
    },
    {
      title: (
        <>
          {formatMessage(strings.short.tpms)}
          <ColumnInfoModal title={formatMessage(strings.short.tpms)} body={<WheelLayoutInfoToolTip />} />
        </>
      ),
      searchKey: formatMessage(strings.short.tpms),
      shortTitle: formatMessage(strings.short.tpms),
      dataIndex: 'tires',
      selectionSection: selectionHeaders.healthMonitoring.id,
      key: 'tires',
      ellipsis: true,
      render: (tires, trailer) => {
        return (
          <span>
            {tires && (
              <span>
                <TireMicroView
                  formatMessage={formatMessage}
                  trailer={trailer}
                  semiTransparent={false}
                  isMetric={isMetric}
                />
              </span>
            )}
            {!tires && <small>-</small>}
          </span>
        );
      },
      width: 150
    },
    {
      title: <Tooltip title={formatMessage(strings.short.activity)}>{formatMessage(strings.short.activity)}</Tooltip>,
      searchKey: formatMessage(strings.short.activity),
      shortTitle: formatMessage(strings.short.activity),
      dataIndex: 'activityStatus',
      selectionSection: selectionHeaders.generalMonitoring.id,
      key: 'activity',
      width: 150,
      ellipsis: true,
      render: activityStatus => {
        return (
          <div>
            <Tooltip
              placement='left'
              title={activityStatus ? formatMessage(strings.short[activityStatus.status]) : null}
            >
              {activityStatus && getActivityIcon(activityStatus.status)}{' '}
              {activityStatus && activityStatus.since
                ? moment.duration(moment.utc().diff(activityStatus.since, 'seconds'), 'seconds').humanize()
                : formatMessage(strings.short.unknown)}
            </Tooltip>
          </div>
        );
      },
      filters: [
        {
          text: formatMessage(strings.short.driving),
          value: 'driving'
        },
        {
          text: formatMessage(strings.short.paused),
          value: 'paused'
        },
        {
          text: formatMessage(strings.short.parked),
          value: 'parked'
        }
      ],
      onFilter: (value, record) => {
        if (Date.now() - lastFilterTime > 1500) {
          triggerGenericEvent('Filter', 'Filter', 'visibility');
          lastFilterTime = Date.now();
        }
        return record.activityStatus && record.activityStatus.status === value;
      }
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns[isUS ? 'absBrand' : 'ebsBrand'])}>
          {formatMessage(strings.columns[isUS ? 'absBrand' : 'ebsBrand'])}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns[isUS ? 'absBrand' : 'ebsBrand']),
      key: 'ebsBrand',
      selectionSection: selectionHeaders.trailerSystems.id,
      dataIndex: 'ebsBrand',
      render: ebsBrand => (ebsBrand != null ? ebsBrand?.toUpperCase() : <span style={{ color: '#cacaca' }}>-</span>),
      sorter: (a, b) => (a.ebsBrand === null || a.ebsBrand === undefined || a.ebsBrand < b.ebsBrand ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short[isUS ? 'absVersion' : 'ebsVersion'])}>
          {formatMessage(strings.short[isUS ? 'absVersion' : 'ebsVersion'])}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.short[isUS ? 'absVersion' : 'ebsVersion']),
      key: 'ebsType',
      selectionSection: selectionHeaders.trailerSystems.id,
      dataIndex: 'ebsType',
      render: ebsType => (ebsType != null ? ebsType : <span style={{ color: '#cacaca' }}>-</span>),
      sorter: (a, b) => (a.ebsType === null || a.ebsType === undefined || a.ebsType < b.ebsType ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },
    {
      title: <Tooltip title={formatMessage(strings.short.alarm2h)}>{formatMessage(strings.short.alarm2h)}</Tooltip>,
      searchKey: formatMessage(strings.short.alarm2h),
      shortTitle: formatMessage(strings.short.alarm2h),
      dataIndex: 'alerts',
      selectionSection: selectionHeaders.generalMonitoring.id,
      key: 'alarm2h',
      ellipsis: true,
      filteredRender: (value, filters = []) => {
        const alerts =
          filters.length > 0
            ? value.filter(alert => {
                return filters.includes(alert.alarm);
              })
            : value;
        const dates = [moment(moment().subtract(2, 'h'), 'YYYY/MM/DD'), moment(moment(), 'YYYY/MM/DD')];
        if (!alerts) {
          return '';
        }
        return (
          <AlertTimeline
            height={30}
            width={150}
            alerts={alerts}
            region={region}
            dates={dates}
            dataHash={`${alerts.length}${value}${alerts.assetId}`}
            isHideTimeline
          />
        );
      },
      filters: getTriggerTitles(region, productFeatures),
      onFilter: (value, record) => {
        return record.alerts && record.alerts.some(alert => alert.alarm === value);
      },
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.tpmsSource)}>{formatMessage(strings.short.tpmsSource)}</Tooltip>
      ),
      searchKey: formatMessage(strings.short.tpmsSource),
      key: 'metaDataTpmsSource',
      selectionSection: selectionHeaders.trailerSystems.id,
      dataIndex: 'metaDataTpmsSource',
      render: metaDataTpmsSource => metaDataTpmsSource || noValueSpan(formatMessage),
      sorter: (a, b) => (!a.metaDataTpmsSource || a.metaDataTpmsSource < b.metaDataTpmsSource ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },

    {
      title: (
        <Tooltip title={formatMessage(strings.columns.trailerMake)}>
          {formatMessage(strings.columns.trailerMake)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.trailerMake),
      key: 'metaDataTrailerMake',
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'metaDataTrailerMake',
      render: metaDataTrailerMake => metaDataTrailerMake || noValueSpan(formatMessage),
      sorter: metaDataTrailerMakeSort,
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns.trailerModel)}>
          {formatMessage(strings.columns.trailerModel)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.trailerModel),
      key: 'metaDataTrailerModel',
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'metaDataTrailerModel',
      render: metaDataTrailerModel => metaDataTrailerModel || noValueSpan(formatMessage),
      sorter: metaDataTrailerModelSort,
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.trailerType)}>{formatMessage(strings.short.trailerType)}</Tooltip>
      ),
      searchKey: formatMessage(strings.short.trailerType),
      key: 'trailerType',
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'trailerType',
      render: trailerType => trailerType || noValueSpan(formatMessage),
      sorter: (a, b) => (!a.trailerType || a.trailerType < b.trailerType ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.trailerProductionDate)}>
          {formatMessage(strings.short.trailerProductionDate)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.short.trailerProductionDate),
      key: 'metaDataTrailerYear',
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'metaDataTrailerYear',
      render: metaDataTrailerYear => metaDataTrailerYear || noValueSpan(formatMessage),
      sorter: (a, b) => (!a.metaDataTrailerYear || a.metaDataTrailerYear < b.metaDataTrailerYear ? -1 : 1),
      showSorterTooltip: false,
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.columns.externalCode)}>
          {formatMessage(strings.columns.externalCode)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.columns.externalCode),
      key: 'externalCode',
      dataIndex: 'externalCode',
      selectionSection: selectionHeaders.generalInfo.id,
      render: externalCode => externalCode || noValueSpan(formatMessage),
      filterType: 'search',
      width: 150
    },
    {
      title: (
        <Tooltip title={formatMessage(strings.short.trailerCategory)}>
          {formatMessage(strings.short.trailerCategory)}
        </Tooltip>
      ),
      searchKey: formatMessage(strings.short.trailerCategory),
      key: 'categoryTitle',
      selectionSection: selectionHeaders.generalInfo.id,
      dataIndex: 'categoryTitle',
      render: categoryTitle => categoryTitle || noValueSpan(formatMessage),
      sorter: (a, b) => (!a.categoryTitle || a.categoryTitle < b.categoryTitle ? -1 : 1),
      showSorterTooltip: false,
      filterType: 'categorical',
      width: 200
    }
  ];
};

export default columns;
