import { isBrakePlus, isScalar } from '../../../helpers/functions';
import { ENV_CONFIG } from '../../../app/helpers/env-configs';
import { parseJson } from '../../../helpers/parse-json';

const {
  REACT_APP_AUTH_REDIRECT_URL_QA,
  REACT_APP_AUTH_REDIRECT_URL_FT,
  REACT_APP_AUTH_REDIRECT_URL_PA,
  REACT_APP_AUTH_REDIRECT_URL_PB,
  REACT_APP_AUTH_REDIRECT_URL_FCS
} = ENV_CONFIG;

export const redirectToPortal = () => {
  if (isScalar()) {
    return;
  }
  const env = window.localStorage.getItem('transics-env');
  if (!env && isBrakePlus()) {
    if (window.location.toString().includes('.tipeurope.com')) {
      window.location = 'https://connectedservices.fleetradar.tipeurope.com';
    } else {
      window.location = REACT_APP_AUTH_REDIRECT_URL_FCS;
    }
    return;
  }
  if (env) {
    if (env === 'qa') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_QA;
    } else if (env === 'pa') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PA;
    } else if (env === 'pb') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PB;
    } else if (env === 'ft') {
      window.location = REACT_APP_AUTH_REDIRECT_URL_FT;
    } else if (env === 'fcs') {
      if (window.location.toString().includes('.tipeurope.com')) {
        window.location = 'https://connectedservices.fleetradar.tipeurope.com';
      } else {
        window.location = REACT_APP_AUTH_REDIRECT_URL_FCS;
      }
    } else {
      window.location = REACT_APP_AUTH_REDIRECT_URL_PB;
    }
  } else {
    window.location = REACT_APP_AUTH_REDIRECT_URL_PB;
  }
};

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function decodeJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return parseJson(jsonPayload);
}

export const parseJwt = token => {
  if (!token) {
    return null;
  }

  const decodedJwt = decodeJwt(token);
  const parsedToken = {};

  Object.keys(decodedJwt).forEach(key => {
    const parsedKey = key.split('/').pop();
    parsedToken[parsedKey] = decodedJwt[key];
  });
  return parsedToken;
};

export const buildAuthDataFromResponse = response => {
  const {
    userProfileToken,
    openIdToken: { Token: token, IdentityId }
  } = response;

  const userProfile = decodeJwt(userProfileToken);

  const expiresAt = userProfile.exp
    ? userProfile.exp * 1000
    : 10 * 60 * 60 * 1000 + (userProfile.iat ? userProfile.iat * 1000 : Date.now());

  const user = {
    ...userProfile,
    expires_at: expiresAt,
    identity_id: IdentityId
  };

  return {
    user,
    token,
    IdentityId,
    transicsUserProfileToken: userProfileToken,
    expiresAt
  };
};
