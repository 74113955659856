import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as strings from '../../../../../../helpers/defaultStrings';
import DashboardIcon from '../DashboardIcon/DashboardIcon';
import { IconPaused, IconParked, IconDriving } from '../../../../../Icons';
import { useHistory, useLocation } from 'react-router-dom';
import { sendCustomEvent } from '../../../../../../app/helpers/new-relic';
import { selectUser } from '../../../../../../selectors/auth';
import { EVENT_NAMES } from '../../../../../../analytics/constants';
import { useQuery } from '../../../../../../helpers/liveMap/useQuery';

const getStatusCounts = trailersData => {
  let drivingCount = 0,
    parkedCount = 0,
    pausedCount = 0;
  trailersData.forEach(trailer => {
    if (trailer.activityStatus?.status === 'driving') drivingCount++;
    else if (trailer.activityStatus?.status === 'parked') parkedCount++;
    else if (trailer.activityStatus?.status === 'paused') pausedCount++;
  });
  return { drivingCount, parkedCount, pausedCount };
};

const LiveActivityBox = ({ trailers }) => {
  const { formatMessage } = useIntl();
  const user = useSelector(selectUser);
  const [activeFilters, setActiveFilters] = useState({});

  const visibleTrailersCount = trailers.data.length;
  const allTrailersCount = trailers.unfilteredData.length;
  const countTitle =
    visibleTrailersCount === allTrailersCount ? visibleTrailersCount : `${visibleTrailersCount}/${allTrailersCount}`;
  const { drivingCount, parkedCount, pausedCount } = getStatusCounts(trailers.data);

  let query = useQuery();
  let history = useHistory();

  const windowLocation = useLocation();
  useEffect(() => {
    const sharedFilters = query.get('activities')?.split('|');
    if (sharedFilters) {
      const filterObject = Object.fromEntries(sharedFilters.map(filter => [filter, true]));
      setActiveFilters(filterObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toogleDrivingFilter = name => {
    const nextActiveFilters = { ...activeFilters };
    if (!activeFilters[name]) {
      sendCustomEvent(`${EVENT_NAMES.DASHBOARD_FILTER}_${name.toUpperCase()}`, { identity: user.identity });
      nextActiveFilters[name] = true;
    } else {
      delete nextActiveFilters[name];
    }
    setActiveFilters(nextActiveFilters);

    //add active activity filters to url search params so view can be shared
    const activitiesURLQuery = Object.keys(nextActiveFilters).join('|');
    const alarms = query.get('alarms');
    const alarmsObject = alarms ? { alarms: alarms, hours: query.get('hours') } : {};
    const activitiesObject = activitiesURLQuery ? { activities: activitiesURLQuery } : {};
    const searchParams = { ...activitiesObject, ...alarmsObject };
    history.push({
      pathname: windowLocation.pathname,
      search: `?${new URLSearchParams(searchParams).toString()}`
    });

    const activityFilterName = 'activityFilter';
    const shouldFilterByStatus = Object.keys(nextActiveFilters).length > 0;
    const statusFilter = trailer => nextActiveFilters[trailer.activityStatus?.status] === true;

    if (shouldFilterByStatus) trailers.filter(activityFilterName, statusFilter);
    else trailers.removeFilter(activityFilterName);
  };

  return (
    <div className='tf-dashboard-box'>
      <div className={'box-title-container'}>
        <div className={'title-container'}>
          <h2 className='ant-typography'>{countTitle}</h2>
          <span className='tf-box-title'>{formatMessage(strings.pageTitle.liveActivity)}</span>
        </div>
      </div>
      <ul>
        <DashboardIcon
          value={drivingCount}
          tooltipText={formatMessage(strings.short.driving)}
          active={activeFilters['driving']}
          onClick={() => toogleDrivingFilter('driving')}
        >
          <IconDriving />
        </DashboardIcon>
        <DashboardIcon
          value={parkedCount}
          tooltipText={formatMessage(strings.short.parked)}
          active={activeFilters['parked']}
          onClick={() => toogleDrivingFilter('parked')}
        >
          <IconParked />
        </DashboardIcon>
        <DashboardIcon
          value={pausedCount}
          tooltipText={formatMessage(strings.short.paused)}
          active={activeFilters['paused']}
          onClick={() => toogleDrivingFilter('paused')}
        >
          <IconPaused />
        </DashboardIcon>
      </ul>
    </div>
  );
};

export default LiveActivityBox;
