import * as d3 from 'd3';
import Chart from '../chart';
import React from 'react';

import './AlertTimeline.scss';
import ReactDOMServer from 'react-dom/server';
import { dateMultiFormat } from '../../../helpers/functions';
import { getTriggers, placesVariables } from '../../../helpers/alarms/functions';
import classNames from 'classnames';

export default class AlertTimeline extends Chart {
  create(state) {
    const { region, productFeatures, isHideTimeline } = this.props;
    this.triggerTypes = getTriggers(region, productFeatures);

    this.svg = super.createRoot();
    this.main = this.svg.append('g').attr('class', 'main');

    if (!isHideTimeline) {
      this.main.attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);
      this.xAxis = this.main.append('g').attr('class', 'xAxis').attr('transform', `translate(0,-10)`);
    }

    this.main
      .append('line')
      .style('stroke-width', 5)
      .style('stroke-linecap', 'round')
      .attr('x1', 0)
      .attr('y1', this.props.height / 2)
      .attr('x2', this.props.width)
      .attr('y2', this.props.height / 2);

    this.alerts = this.main.append('g').attr('class', 'alerts');

    this.update(state, true);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state) {
      this.drawChart(state);
    }
  }

  drawChart(state) {
    const { isHideTimeline, placesTimeline } = this.props;
    // x axis
    this.x = d3.scaleTime().range([0, this.props.width]).domain(state.dates);
    if (!isHideTimeline) {
      const xAxis = d3
        .axisTop(this.x)
        .tickFormat(date => dateMultiFormat(date, this.props.isUS, this.props.timeFormatLocale));
      this.svg.select('.xAxis').call(xAxis);
    }

    // alerts
    const alertData = state.alerts || [];
    this.alerts.selectAll('.alert').remove();

    const alerts = this.alerts.selectAll('.alert').data(alertData);

    const alertEnter = alerts.enter().append('g').attr('class', 'alert');

    alertEnter
      .append('foreignObject')
      .attr('class', 'padding2')
      .attr('id', (d, i) => `${d.assetId}_${i}`)
      .html(d => {
        const alarmClassNames = classNames(this.triggerTypes[d.alarm]?.class, {
          'generic-places-alarm': !placesTimeline && d.alarm === 'geofence'
        });
        return ReactDOMServer.renderToStaticMarkup(
          placesTimeline || d.alarm === 'geofence' ? (
            <div className={alarmClassNames}>
              <img
                src={
                  placesVariables[d.geofences_details?.geofences[0]?.type]?.iconUrl
                    ? placesVariables[d.geofences_details?.geofences[0]?.type]?.iconUrl
                    : placesVariables['other'].iconUrl
                }
                style={{ width: 22, height: 22 }}
              />
            </div>
          ) : (
            <div className={alarmClassNames}>
              {this.triggerTypes[d.alarm] ? this.triggerTypes[d.alarm].getAvatarSVG() : ''}
            </div>
          )
        );
      })
      .attr('width', 30)
      .attr('height', 30)
      .attr('x', -10)
      .attr('y', this.props.height / 2 - 14);

    alertEnter
      .append('use')
      .attr('xlink:href', (d, i) => (d.alarm ? `#${d.assetId}_${i}` : ''))
      .attr('width', 20)
      .attr('height', 20)
      .attr('x', -10)
      .attr('y', this.props.height / 2 - 10)
      .style('cursor', 'pointer');
    if (state.onClick) {
      alertEnter.on('click', function (d) {
        state.onClick(d);
      });
    }

    const alertMerge = alertEnter.merge(alerts);

    alertMerge.attr('transform', d => `translate( ${this.x(d.datetime) ? this.x(d.datetime) : 0}, 0)`);
  }
}
