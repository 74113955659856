import { worker } from '../../../app/web-workers/aws-web-worker';
import { MESSAGE_TYPES } from '../../../workers/constants';
import { loginFailure } from '../../auth';
import { onLoginSuccess } from './on-login-success';

export const login = ({ transicsUserProfileToken, token, expiresAt, IdentityId, user }) => {
  return async dispatch => {
    try {
      await worker.wrapMessageInPromise({
        types: {
          request: MESSAGE_TYPES.LOGIN_REQUEST,
          success: MESSAGE_TYPES.LOGIN_SUCCESS,
          error: MESSAGE_TYPES.LOGIN_ERROR
        },
        payload: {
          rejectMe: true,
          transicsUserProfileToken,
          authData: {
            token,
            expires_at: expiresAt,
            identity_id: IdentityId
          },
          user
        }
      });
      dispatch(onLoginSuccess(user));
      window.localStorage.setItem('transics-token', transicsUserProfileToken);
    } catch (error) {
      dispatch(loginFailure(error));
    }
  };
};
