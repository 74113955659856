import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../helpers/defaultStrings';
import { DownloadOutlined } from '@ant-design/icons';
import { IconDashboard } from '../../../../Icons';
import downloadCsv from '../DownloadCsv/DownloadCsv';
import './DashboardTitleMenu.scss';
import { useSelector } from 'react-redux';
import { isUsaCustomer } from '../../../../../helpers/functions';
import OdyButton from '../../../../../ReusableComponents/Buttons/OdyButton/OdyButton';
import { Tooltip, Typography } from 'antd';
import ColumnSelectionBtn from './ColumnSelectionBtn/ColumnSelectionBtn';
import { selectRegion } from '../../../../../selectors';
import { selectTrailerLiveMapColumns } from '../../../../../selectors/';

const { Title } = Typography;

const DashboardTitleMenu = ({ trailers, showDashboard, toogleDashboard }) => {
  const formatMessage = useIntl().formatMessage;
  const dashboardTitle = formatMessage(strings.short.trailerlist);
  const groups = useSelector(store => store.groups.groups);
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const selectedColumns = useSelector(selectTrailerLiveMapColumns);
  const isUS = isUsaCustomer(region);

  return (
    <div className='dashboard-title-menu'>
      <div>
        <Title level={4} className='tf_collapsable_menu_title'>
          {' '}
          <Tooltip placement='bottomLeft' title={dashboardTitle}>
            {dashboardTitle}
          </Tooltip>
        </Title>
      </div>
      <div className='control-button-wrap'>
        <OdyButton
          icon={<DownloadOutlined />}
          onClick={() => downloadCsv(trailers.data, formatMessage, isMetric, isUS, selectedColumns, groups)}
        >
          {formatMessage(strings.short.exportCSV)}
        </OdyButton>
        <ColumnSelectionBtn trailers={trailers} />
        <OdyButton icon={<IconDashboard />} onClick={() => toogleDashboard()}>
          {formatMessage(showDashboard ? strings.short.hideDashboard : strings.short.showDashboard)}
        </OdyButton>
      </div>
    </div>
  );
};

export default DashboardTitleMenu;
