import React from 'react';
import LocaleString from '../../Components/Utils/LocaleString';
import IconABS from '../../Components/Icons/ABS';
import IconWarningLight from '../../Components/Icons/warningLight';
import IconTPMS from '../../Components/Icons/TPMS';
import IconRSS from '../../Components/Icons/RSS';
import IconWithoutISOCable from '../../Components/Icons/withoutISOCable';
import IconHarshBraking from '../../Components/Icons/harshBraking';
import IconDefault from '../../Components/Icons/Default';
import IconPlaces from '../../Components/Icons/Places';
import LightAnomaly from '../../Components/Icons/LightAnomaly';
import IconWheelEnd from '../../Components/Icons/WheelEnd';
import IconElectricalFault from '../../Components/Icons/ElectricalFault';
import IconSensorNotFound from '../../Components/Icons/SensorNotFound';
import { ENV_CONFIG } from '../../app/helpers/env-configs';

const publicUrl = new URL(ENV_CONFIG.PUBLIC_URL, window.location.protocol + '//' + window.location.host + '/');

const alarmKeys = {
  abs: 'abs',
  absWarningLamp: 'absWarningLamp',
  default: 'default',
  ebsAmber: 'ebsAmber',
  ebsRed: 'ebsRed',
  geofence: 'geofence',
  harshBrakeEvent: 'harshBrakeEvent',
  rss: 'rss',
  tireExtremeOverPressure: 'tireExtremeOverPressure',
  tireExtremeUnderPressure: 'tireExtremeUnderPressure',
  tireOverPressure: 'tireOverPressure',
  tireUnderPressure: 'tireUnderPressure',
  tpms: 'tpms',
  groteLightAnomaly: 'groteLightAnomaly',
  groteLightOutage: 'groteLightOutage',
  withoutISOCable: 'withoutISOCable',
  wheelEndOverTemperature: 'wheelEndOverTemperature',
  parkedWithLowPressure: 'parkedWithLowPressure',
  parkedWithSlowLeak: 'parkedWithSlowLeak',
  slowLeak: 'slowLeak',
  criticalLeak: 'criticalLeak',
  electricalFault: 'electricalFault',
  sensorNotFound: 'sensorNotFound',
  tireLowPressure: 'tireLowPressure'
};

export const triggerKeys = {
  america: [
    alarmKeys.absWarningLamp,
    alarmKeys.harshBrakeEvent,
    alarmKeys.tpms,
    alarmKeys.tireExtremeUnderPressure,
    alarmKeys.tireUnderPressure,
    alarmKeys.tireExtremeOverPressure,
    alarmKeys.tireOverPressure,
    alarmKeys.rss,
    alarmKeys.abs,
    alarmKeys.geofence,
    alarmKeys.withoutISOCable,
    alarmKeys.default,
    alarmKeys.groteLightAnomaly,
    alarmKeys.groteLightOutage,
    alarmKeys.wheelEndOverTemperature,
    alarmKeys.electricalFault,
    alarmKeys.sensorNotFound,
    alarmKeys.parkedWithLowPressure,
    alarmKeys.parkedWithSlowLeak,
    alarmKeys.slowLeak,
    alarmKeys.criticalLeak,
    alarmKeys.tireLowPressure
  ],
  default: [
    alarmKeys.ebsAmber,
    alarmKeys.ebsRed,
    alarmKeys.harshBrakeEvent,
    alarmKeys.tpms,
    alarmKeys.tireExtremeUnderPressure,
    alarmKeys.tireUnderPressure,
    alarmKeys.tireExtremeOverPressure,
    alarmKeys.tireOverPressure,
    alarmKeys.rss,
    alarmKeys.abs,
    alarmKeys.geofence,
    alarmKeys.withoutISOCable,
    alarmKeys.default,
    alarmKeys.groteLightAnomaly,
    alarmKeys.groteLightOutage
  ]
};

export const notificationKeys = {
  america: [
    alarmKeys.absWarningLamp,
    alarmKeys.harshBrakeEvent,
    alarmKeys.tpms,
    alarmKeys.tireExtremeUnderPressure,
    alarmKeys.tireUnderPressure,
    alarmKeys.tireExtremeOverPressure,
    alarmKeys.tireOverPressure,
    alarmKeys.rss,
    alarmKeys.abs,
    alarmKeys.geofence,
    alarmKeys.withoutISOCable,
    alarmKeys.wheelEndOverTemperature,
    alarmKeys.electricalFault,
    alarmKeys.sensorNotFound,
    alarmKeys.parkedWithLowPressure,
    alarmKeys.parkedWithSlowLeak,
    alarmKeys.slowLeak,
    alarmKeys.criticalLeak,
    alarmKeys.tireLowPressure,
    alarmKeys.default
  ],
  default: [
    alarmKeys.ebsAmber,
    alarmKeys.ebsRed,
    alarmKeys.harshBrakeEvent,
    alarmKeys.tpms,
    alarmKeys.tireExtremeUnderPressure,
    alarmKeys.tireUnderPressure,
    alarmKeys.tireExtremeOverPressure,
    alarmKeys.tireOverPressure,
    alarmKeys.rss,
    alarmKeys.abs,
    alarmKeys.geofence,
    alarmKeys.withoutISOCable,
    alarmKeys.default
  ]
};

export const triggerTitles = {
  ebsAmber: <LocaleString type='alert' id='ebsAmberTitle' />,
  ebsRed: <LocaleString type='alert' id='ebsRedTitle' />,
  absWarningLamp: <LocaleString type='alert' id='absWarningTitle' />,
  harshBrakeEvent: <LocaleString type='alert' id='harshBrakeTitle' />,
  tpms: <LocaleString type='alert' id='tpmsTitle' />,
  tireExtremeUnderPressure: <LocaleString type='alert' id='tpmsEUPTitle' />,
  tireUnderPressure: <LocaleString type='alert' id='tpmsUPTitle' />,
  tireExtremeOverPressure: <LocaleString type='alert' id='tpmsEOPTitle' />,
  tireOverPressure: <LocaleString type='alert' id='tpmsOPTitle' />,
  rss: <LocaleString type='alert' id='rssTitle' />,
  abs: <LocaleString type='alert' id='absTitle' />,
  geofence: <LocaleString type='alert' id='geofence' />,
  withoutISOCable: <LocaleString type='alert' id='drivingWithoutISOTitle' />,
  groteLightAnomaly: <LocaleString type='alert' id='groteLightAnomaly' />,
  groteLightOutage: <LocaleString type='alert' id='groteLightOutage' />,
  wheelEndOverTemperature: <LocaleString type='alert' id='wheelEndOverTemperature' />,
  electricalFault: <LocaleString type='alert' id='electricalFault' />,
  sensorNotFound: <LocaleString type='alert' id='sensorNotFound' />,
  parkedWithLowPressure: <LocaleString type='alert' id='parkedWithLowPressure' />,
  parkedWithSlowLeak: <LocaleString type='alert' id='parkedWithSlowLeak' />,
  slowLeak: <LocaleString type='alert' id='slowLeak' />,
  criticalLeak: <LocaleString type='alert' id='criticalLeak' />,
  tireLowPressure: <LocaleString type='alert' id='tireLowPressure' />,
  default: <LocaleString type='alert' id='unknown' />
};

const notificationAlarms = {
  ebsAmber: {
    status: 'processing',
    getAvatarSVG: () => <IconWarningLight />,
    text: triggerTitles.ebsAmber,
    translationKey: 'ebsAmberTitle',
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/ebsAmber.png',
    order: 4
  },
  ebsRed: {
    status: 'processing',
    getAvatarSVG: () => <IconWarningLight />,
    text: triggerTitles.ebsRed,
    translationKey: 'ebsRedTitle',
    pngUrl: publicUrl + 'alarmIcons/ebsRed.png',
    class: 'alarm-icon red',
    order: 5
  },
  absWarningLamp: {
    status: 'processing',
    getAvatarSVG: () => <IconWarningLight />,
    text: triggerTitles.absWarningLamp,
    translationKey: 'absWarningTitle',
    pngUrl: publicUrl + 'alarmIcons/ebsAmber.png',
    class: 'alarm-icon yellow',
    order: 4
  },
  harshBrakeEvent: {
    status: 'processing',
    text: triggerTitles.harshBrakeEvent,
    translationKey: 'harshBrakeTitle',
    getAvatarSVG: () => <IconHarshBraking />,
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/harshBraking.png',
    order: 3
  },
  tpms: {
    status: 'urgent',
    text: triggerTitles.tpms,
    translationKey: 'tpmsTitle',
    getAvatarSVG: () => <IconTPMS />,
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/TPMS.png',
    order: 2
  },
  tireExtremeUnderPressure: {
    status: 'urgent',
    text: triggerTitles.tireExtremeUnderPressure,
    translationKey: 'tpmsEUPTitle',
    getAvatarSVG: () => <IconTPMS />,
    class: 'alarm-icon red',
    pngUrl: publicUrl + 'alarmIcons/TPMSRed.png',
    order: 8
  },
  tireUnderPressure: {
    status: 'urgent',
    text: triggerTitles.tireUnderPressure,
    translationKey: 'tpmsUPTitle',
    getAvatarSVG: () => <IconTPMS />,
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/TPMS.png',
    order: 9
  },
  tireExtremeOverPressure: {
    status: 'urgent',
    text: triggerTitles.tireExtremeOverPressure,
    translationKey: 'tpmsEOPTitle',
    getAvatarSVG: () => <IconTPMS />,
    class: 'alarm-icon red',
    pngUrl: publicUrl + 'alarmIcons/TPMSRed.png',
    order: 10
  },
  tireOverPressure: {
    status: 'urgent',
    text: triggerTitles.tireOverPressure,
    translationKey: 'tpmsOPTitle',
    getAvatarSVG: () => <IconTPMS />,
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/TPMS.png',
    order: 11
  },
  rss: {
    status: 'processing',
    getAvatarSVG: () => <IconRSS />,
    text: triggerTitles.rss,
    translationKey: 'rssTitle',
    class: 'alarm-icon yellow',
    pngUrl: publicUrl + 'alarmIcons/RSS.png',
    order: 6
  },
  abs: {
    status: 'processing',
    text: triggerTitles.abs,
    translationKey: 'absTitle',
    getAvatarSVG: () => <IconABS />,
    pngUrl: publicUrl + 'alarmIcons/ABS.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  geofence: {
    status: 'processing',
    getAvatarSVG: () => <IconPlaces />,
    text: triggerTitles.geofence,
    class: 'alarm-icon flex-center blue',
    translationKey: 'geofence',
    pngUrl: publicUrl + 'alarmIcons/places.png',
    order: 12
  },
  withoutISOCable: {
    status: 'processing',
    getAvatarSVG: () => <IconWithoutISOCable />,
    class: 'alarm-icon yellow',
    text: triggerTitles.withoutISOCable,
    translationKey: 'drivingWithoutISOTitle',
    pngUrl: publicUrl + 'alarmIcons/withoutISOCable.png',
    order: 7
  },
  groteLightAnomaly: {
    status: 'processing',
    text: triggerTitles.groteLightAnomaly,
    translationKey: 'groteLightAnomaly',
    getAvatarSVG: () => <LightAnomaly />,
    pngUrl: publicUrl + 'alarmIcons/groteWarning.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  groteLightOutage: {
    status: 'processing',
    text: triggerTitles.groteLightOutage,
    translationKey: 'groteLightOutage',
    getAvatarSVG: () => <LightAnomaly />,
    pngUrl: publicUrl + 'alarmIcons/groteOutage.png',
    class: 'alarm-icon red',
    order: 1
  },
  wheelEndOverTemperature: {
    status: 'processing',
    text: triggerTitles.wheelEndOverTemperature,
    translationKey: 'wheelEndOverTemperature',
    getAvatarSVG: () => <IconWheelEnd />,
    pngUrl: publicUrl + 'alarmIcons/wheelEndRed.png',
    class: 'alarm-icon red',
    order: 1
  },
  electricalFault: {
    status: 'processing',
    text: triggerTitles.electricalFault,
    translationKey: 'electricalFault',
    getAvatarSVG: () => <IconElectricalFault />,
    pngUrl: publicUrl + 'alarmIcons/electricalFault.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  sensorNotFound: {
    status: 'processing',
    text: triggerTitles.sensorNotFound,
    translationKey: 'sensorNotFound',
    getAvatarSVG: () => <IconSensorNotFound />,
    pngUrl: publicUrl + 'alarmIcons/sensorNotFound.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  tireLowPressure: {
    status: 'processing',
    text: triggerTitles.tireLowPressure,
    translationKey: 'tireLowPressure',
    getAvatarSVG: () => <IconTPMS />,
    pngUrl: publicUrl + 'alarmIcons/TPMSRed.png',
    class: 'alarm-icon red',
    order: 1
  },
  parkedWithLowPressure: {
    status: 'processing',
    text: triggerTitles.parkedWithLowPressure,
    translationKey: 'parkedWithLowPressure',
    getAvatarSVG: () => <IconTPMS />,
    pngUrl: publicUrl + 'alarmIcons/TPMS.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  parkedWithSlowLeak: {
    status: 'processing',
    text: triggerTitles.parkedWithSlowLeak,
    translationKey: 'parkedWithSlowLeak',
    getAvatarSVG: () => <IconTPMS />,
    pngUrl: publicUrl + 'alarmIcons/TPMS.png',
    class: 'alarm-icon yellow',
    order: 1
  },
  slowLeak: {
    status: 'processing',
    text: triggerTitles.slowLeak,
    translationKey: 'slowLeak',
    getAvatarSVG: () => <IconTPMS />,
    pngUrl: publicUrl + 'alarmIcons/TPMSRed.png',
    class: 'alarm-icon red',
    order: 1
  },
  criticalLeak: {
    status: 'processing',
    text: triggerTitles.criticalLeak,
    translationKey: 'criticalLeak',
    getAvatarSVG: () => <IconTPMS />,
    pngUrl: publicUrl + 'alarmIcons/TPMSRed.png',
    class: 'alarm-icon red',
    order: 1
  },
  default: {
    getAvatarSVG: () => <IconDefault />,
    text: triggerTitles.default,
    pngUrl: publicUrl + 'alarmIcons/default.png',
    translationKey: 'unknown',
    class: 'alarm-icon yellow'
  }
};

export const triggerTypes = {
  watchman: {
    wheelEndOverTemperature: notificationAlarms.wheelEndOverTemperature,
    parkedWithLowPressure: notificationAlarms.parkedWithLowPressure,
    parkedWithSlowLeak: notificationAlarms.parkedWithSlowLeak,
    slowLeak: notificationAlarms.slowLeak,
    criticalLeak: notificationAlarms.criticalLeak,
    electricalFault: notificationAlarms.electricalFault,
    sensorNotFound: notificationAlarms.sensorNotFound,
    tireLowPressure: notificationAlarms.tireLowPressure
  },
  america: {
    absWarningLamp: notificationAlarms.absWarningLamp,
    harshBrakeEvent: notificationAlarms.harshBrakeEvent,
    tpms: notificationAlarms.tpms,
    tireExtremeUnderPressure: notificationAlarms.tireExtremeUnderPressure,
    tireUnderPressure: notificationAlarms.tireUnderPressure,
    tireExtremeOverPressure: notificationAlarms.tireExtremeOverPressure,
    tireOverPressure: notificationAlarms.tireOverPressure,
    rss: notificationAlarms.rss,
    abs: notificationAlarms.abs,
    geofence: notificationAlarms.geofence,
    withoutISOCable: notificationAlarms.withoutISOCable,
    default: notificationAlarms.default,
    groteLightAnomaly: notificationAlarms.groteLightAnomaly,
    groteLightOutage: notificationAlarms.groteLightOutage
  },
  default: {
    ebsRed: notificationAlarms.ebsRed,
    ebsAmber: notificationAlarms.ebsAmber,
    harshBrakeEvent: notificationAlarms.harshBrakeEvent,
    tpms: notificationAlarms.tpms,
    tireExtremeUnderPressure: notificationAlarms.tireExtremeUnderPressure,
    tireUnderPressure: notificationAlarms.tireUnderPressure,
    tireExtremeOverPressure: notificationAlarms.tireExtremeOverPressure,
    tireOverPressure: notificationAlarms.tireOverPressure,
    rss: notificationAlarms.rss,
    abs: notificationAlarms.abs,
    geofence: notificationAlarms.geofence,
    withoutISOCable: notificationAlarms.withoutISOCable,
    default: notificationAlarms.default,
    groteLightAnomaly: notificationAlarms.groteLightAnomaly,
    groteLightOutage: notificationAlarms.groteLightOutage
  }
};

export const alarmCategories = {
  watchman: 'Hendrickson Watchman'
};

export const HX_ALARM_TYPES = {
  pressure: [
    alarmKeys.parkedWithLowPressure,
    alarmKeys.parkedWithSlowLeak,
    alarmKeys.slowLeak,
    alarmKeys.criticalLeak,
    alarmKeys.tireLowPressure
  ],
  temperature: [alarmKeys.wheelEndOverTemperature]
};
