import React from 'react';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Drawer, Button, Spin } from 'antd';
import actions from '../../actions';
import TrailerTable from './TrailerTable';
import './TrailerList.scss';
import LocaleString from './LocaleString';
import { triggerEvent } from '../../helpers/googleAnalytics';
import { mobileOnly } from '../../helpers/responsive';
import { getTrailerColumns, ebsBrand } from '../../helpers/constants';
import { IconColumnSettings } from '../Icons';
import OdyButton from '../../ReusableComponents/Buttons/OdyButton/OdyButton';
import { selectRegion } from '../../selectors';

class TrailerList extends React.Component {
  searchInput = {};

  constructor() {
    super();
    this.state = {
      visible: false,
      columnsSelection: [],
      isColumnSelectionOpen: false
    };
  }

  componentDidMount() {
    const { getAllTrailers, region } = this.props;
    getAllTrailers();
    this.setState({ columnsSelection: getTrailerColumns(region) });
  }

  showDrawer = () => {
    triggerEvent('AppState', 'Opened Trailer Drawer', 'Trailer ID Card screen');
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    triggerEvent('AppState', 'Closed Trailer Drawer', 'Trailer ID Card screen');
    this.setState({
      visible: false
    });
  };

  onTrailerSelect = trailer => {
    const { selectTrailer, setMessage, updateSelectedHealthTrailer } = this.props;

    if (trailer.ebsBrand === ebsBrand.wabco) {
      if (trailer.last_odr) {
        updateSelectedHealthTrailer(trailer);
        if (trailer && trailer.id) {
          const trailerIdTab = this.getActiveTrailerTab();
          selectTrailer(trailer.id, trailerIdTab);
          this.setState({ visible: false });
        } else {
          setMessage(<LocaleString type='alert' id='unknownVehicleId' />);
        }
      } else {
        setMessage(`No data available for trailer ${trailer.assetId}.`);
      }
    }
  };

  setColumnSelectionOpen = value => {
    this.setState({ isColumnSelectionOpen: value });
  };

  getActiveTrailerTab() {
    const urlQuery = this.props.location?.search;
    const query = new URLSearchParams(urlQuery);
    const queryTab = query.get('tab');
    return queryTab;
  }

  render() {
    const { visible, isColumnSelectionOpen } = this.state;
    const { trailers, processing } = this.props;
    const pageSize = 100;
    const isMobile = mobileOnly.matches;

    return (
      <div>
        <Button type='primary' onClick={this.showDrawer}>
          <LocaleString type='button' id='pickATrailer' />
        </Button>
        <Drawer
          title={<LocaleString type='button' id='pickATrailer' />}
          placement='right'
          closable={true}
          onClose={this.onClose}
          visible={visible}
          mask={!isMobile}
          width={isMobile ? '90%' : '60%'}
          className='trailer-list-drawer'
        >
          <div>
            <div className='title-col-container'>
              <h2>
                <LocaleString type='short' id='trailerList' />
              </h2>
              <OdyButton icon={<IconColumnSettings />} onClick={this.setColumnSelectionOpen}>
                <LocaleString type='short' id='columns' />
              </OdyButton>
            </div>

            {processing && (
              <div style={{ textAlign: 'center', marginTop: 150 }}>
                <Spin size='large' />
              </div>
            )}
            {trailers && (
              <TrailerTable
                id='drawerList'
                topAndBottomPadding={trailers.length > pageSize ? 240 : 200}
                defaultSelectedColumnKeys={[
                  'trailer',
                  'ebsBrand',
                  'vinNumber',
                  'statusLabel',
                  'healthScore',
                  'brand',
                  'axleCount',
                  'vehicleType',
                  'productionDate'
                ]}
                pageSize={pageSize}
                defaultColumnsLocked={{ trailer: true }}
                onRow={record => ({
                  onClick: () => this.onTrailerSelect(record)
                })}
                hideColumnSelectorBtn
                isColumnSelectionOpen={isColumnSelectionOpen}
                setColumnSelectionOpen={this.setColumnSelectionOpen}
              />
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(store) {
  const region = selectRegion(store);
  return {
    processing: store.trailersHealth.processing || store.trailerDetails.trailers.processing,
    error: store.trailerDetails.trailers.error,
    trailers: store.trailerDetails.trailers.items,
    selectedTrailer: store.appState.selectedHealthTrailer,
    region,
    isMetric: store.auth.isMetric,
    groups: store.groups.groups
  };
}
function mapDispatchToProps(dispatch) {
  return {
    selectTrailer: (assetId, trailerIdTab) => {
      if (trailerIdTab) {
        dispatch(push(`/trailer/${assetId}?tab=${trailerIdTab}`));
      } else {
        dispatch(push(`/trailer/${assetId}`));
      }
    },
    getAllTrailers: () => dispatch(actions.trailers.getAllTrailers()),
    setMessage: msg => dispatch(actions.messages.addWarningMessage(msg)),
    updateSelectedHealthTrailer: trailer => dispatch(actions.appState.updateSelectedHealthTrailer(trailer))
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerList));
