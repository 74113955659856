import { formatTrailersEventHistoryWeeklyEvolution } from './ActionResources/transformationFunctions';
import actionTypes from './actionTypes';
import store from '../app/configureStore.js';
import { filterByDeviceAssetIds } from '../helpers/functions';
import { API_NAMES } from '../helpers/constants';
import { selectFilteredEntitiesIds } from '../selectors';
import { worker } from '../app/web-workers/aws-web-worker';
import { getPageSizeTrailersHealth } from '../app/feature-flags/page-size-trailers-health';
import { getTrailersHealthBatchSize } from '../app/feature-flags/trailers-health-batch-size';
import { Logger } from '../app/helpers/logger';
import { loadAllTrailersHealthData } from './helper';

function getAllTrailersHealthRequest() {
  return {
    type: actionTypes.GET_TRAILERS_HEALTH_REQUEST
  };
}

function getAllTrailersHealthSuccess(trailers, final) {
  const state = store.getState();
  const trailerDetails = state.trailerDetails;
  const deviceAssetIds = selectFilteredEntitiesIds(state);
  const payload = formatTrailersEventHistoryWeeklyEvolution(trailerDetails, trailers, final);
  trailers.allItems = trailers.items;
  trailers.items = filterByDeviceAssetIds(trailers.allItems, deviceAssetIds);
  return {
    type: actionTypes.GET_TRAILERS_HEALTH_SUCCESS,
    payload,
    trailers,
    final
  };
}

function getAllTrailersHealthFailure(error) {
  return {
    type: actionTypes.GET_TRAILERS_HEALTH_FAILURE,
    error
  };
}

function callAPI(dispatch, lastEvaluatedKey) {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null
  };

  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailersHealth', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getAllTrailersHealthSuccess(res.Items, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getAllTrailersHealthFailure(err)));
}

export const loadTrailersHealthData = () => {
  return async (dispatch, getState) => {
    try {
      const size = getTrailersHealthBatchSize(getState());
      const trailersData = await loadAllTrailersHealthData(size);
      dispatch(getAllTrailersHealthSuccess(trailersData, true));
    } catch (err) {
      Logger.error(err);
      dispatch(getAllTrailersHealthFailure(err));
    }
  };
};

export function getAllTrailersHealth() {
  return async (dispatch, getState) => {
    const store = getState();
    const shouldUseV3 = getPageSizeTrailersHealth(store);
    const { items: trailers, processing } = store.trailersHealth;
    if ((!trailers || trailers.length === 0) && !processing) {
      dispatch(getAllTrailersHealthRequest());
      if (shouldUseV3) {
        dispatch(loadTrailersHealthData());
      } else {
        callAPI(dispatch);
      }
    }
  };
}

export default {
  getAllTrailersHealth
};
