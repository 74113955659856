import { createAsyncThunk } from '@reduxjs/toolkit';
import { worker } from '../../app/web-workers/aws-web-worker';
import { API_NAMES } from '../../helpers/constants';
import { FEATURE_FLAGS_THUNK_TYPE } from '../../helpers/constants';

// +info https://redux-toolkit.js.org/api/createAsyncThunk#type
export const featureFlagsThunk = async () => {
  const data = await worker.Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'featureFlagsByUser' });

  // return values will update the reducer state accordingly on action creator '*/fulfilled '
  return data ?? {};
};

export const fetchFeatureFlags = createAsyncThunk(FEATURE_FLAGS_THUNK_TYPE, featureFlagsThunk);
