import actionTypes from '../actions/actionTypes';

export const initialState = {
  showAlerts: false,
  followTrailer: false,
  historyPosition: null,
  hours: 6,
  trailerInfoPanelEndDate: null,
  visibleTrailerIds: [],
  showGeoJSONLayers: false
};

export default function liveMapReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LIVE_MAP_SHOW_ALERTS:
      return {
        ...state,
        showAlerts: action.payload
      };
    case actionTypes.SET_LIVE_MAP_FOLLOW_TRAILER:
      return {
        ...state,
        followTrailer: action.payload
      };
    case actionTypes.SET_LIVE_MAP_HISTORY_POSITION:
      return {
        ...state,
        historyPosition: action.payload
      };
    case actionTypes.SET_LIVE_MAP_HOURS:
      return {
        ...state,
        hours: action.payload
      };
    case actionTypes.SET_LIVE_MAP_END_DATE:
      return {
        ...state,
        trailerInfoPanelEndDate: action.payload
      };
    case actionTypes.SET_LIVE_MAP_VISIBLE_TRAILERS:
      return {
        ...state,
        visibleTrailerIds: action.payload
      };
    case actionTypes.SET_LIVE_MAP_SHOW_GEO_JSON_LAYERS:
      return {
        ...state,
        showGeoJSONLayers: action.payload
      };
    default:
      return state;
  }
}
