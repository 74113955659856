import { groteCableColourToLampMap } from './constants';
import * as strings from '../helpers/defaultStrings';

export const LampPosition = {
  RIGHT: 'right',
  LEFT: 'left',
  TOP_RIGHT: 'topRight',
  TOP_LEFT: 'topLeft',
  TOP_MIDDLE: 'topMiddle'
};

export const Light = {
  TAIL_LAMPS: 'tailLamps',
  STOP_LAMP: 'stopLamp',
  CLEARANCE_LAMPS: 'clearanceLamps',
  LEFT_TURN: 'leftTurn',
  RIGHT_TURN: 'rightTurn'
};

export const MouseEvent = {
  ENTER: 'enter',
  LEAVE: 'leave'
};

export const LightCableColour = {
  RED: 'red',
  GREEN: 'green',
  YELLOW: 'yellow',
  BLACK: 'black',
  BROWN: 'brown'
};

export const LightMonitoringStatusColour = {
  RED: 'red',
  GREEN: 'green',
  YELLOW: 'yellow',
  GREY: 'grey'
};

export const groteCodeToStatusColour = {
  0: LightMonitoringStatusColour.GREY,
  1: LightMonitoringStatusColour.YELLOW,
  2: LightMonitoringStatusColour.RED,
  3: LightMonitoringStatusColour.GREEN,
  4: LightMonitoringStatusColour.YELLOW,
  5: LightMonitoringStatusColour.RED,
  6: LightMonitoringStatusColour.GREEN
};

// colour of cable in grote value corresponds to lamp on trailer
export const cableColourToLampType = {
  [LightCableColour.GREEN]: Light.RIGHT_TURN,
  [LightCableColour.RED]: Light.STOP_LAMP,
  [LightCableColour.YELLOW]: Light.LEFT_TURN,
  [LightCableColour.BLACK]: Light.CLEARANCE_LAMPS,
  [LightCableColour.BROWN]: Light.TAIL_LAMPS
};

export const GroteStatus = {
  UNKNOWN: 'unknown',
  GOOD: 'good',
  ANOMALY: 'anomaly',
  OUTAGE: 'outage',
  MULTIPLE_ANOMALIES: 'multipleAnomalies'
};

export const groteLightState = {
  0: 'unknown',
  1: 'highCurrent', // warning
  2: 'lowCurrent', // outage
  3: 'good', // good
  4: 'intermittent', // warning
  5: 'disconnected', // outage
  6: 'reconnected' // good
};

// Used for sorting in Light Monitoring column by severity of errors
export const groteStatusSeverityMap = {
  0: GroteStatus.UNKNOWN,
  1: GroteStatus.GOOD,
  2: GroteStatus.ANOMALY,
  3: GroteStatus.OUTAGE,
  4: GroteStatus.MULTIPLE_ANOMALIES
};

export const groteSeverityColourMap = {
  0: LightMonitoringStatusColour.GREY,
  1: LightMonitoringStatusColour.GREEN,
  2: LightMonitoringStatusColour.YELLOW,
  3: LightMonitoringStatusColour.RED,
  4: LightMonitoringStatusColour.RED
};

export const groteWarningType = {
  groteLightOutage: 'groteLightOutage',
  groteLightAnomaly: 'groteLightAnomaly'
};

const colorToPositions = {
  [LightCableColour.RED]: [LampPosition.LEFT, LampPosition.RIGHT],
  [LightCableColour.GREEN]: [LampPosition.RIGHT],
  [LightCableColour.YELLOW]: [LampPosition.LEFT],
  [LightCableColour.BLACK]: [LampPosition.TOP_LEFT, LampPosition.TOP_RIGHT],
  [LightCableColour.BROWN]: [LampPosition.TOP_MIDDLE]
};

/**
 * Maps grote visualisation position to cable (lamp) colour
 * @param position
 * @returns {[]}
 */
const positionsToColour = position => {
  let colours = [];

  Object.keys(colorToPositions).forEach(colour => {
    if (colorToPositions[colour].includes(position)) {
      colours.push(colour);
    }
  });

  return colours;
};

const maxCode = (a, b) => {
  if (a === 2 || b === 2 || a === 5 || b === 5) {
    return 2;
  }
  if (a === 1 || b === 1 || a === 4 || b === 4) {
    return 1;
  }
  if (a === 3 || b === 3 || a === 6 || b === 6) {
    return 3;
  }
  return 0;
};

const getWarningsAndErrorCodes = (values, errorCodes) => values.filter(lamp => errorCodes.includes(lamp.code));

const formatTagProps = (type, value, tooltip, severity) => ({
  type,
  value,
  tooltip,
  severity
});

const reformatGrote = grote => {
  const lampColours = [
    LightCableColour.GREEN,
    LightCableColour.RED,
    LightCableColour.YELLOW,
    LightCableColour.BLACK,
    LightCableColour.BROWN
  ];

  const values = Object.keys(grote)
    .filter(attr => attr.toLowerCase() !== 'time')
    .reduce((acc, cur) => {
      acc.push(grote[cur]);
      return acc;
    }, []);

  const lamps = values.reduce((acc, cur) => {
    acc.push(cur.colour);
    return acc;
  }, []);

  const unknownLampStatuses = lampColours.filter(colour => !lamps.includes(colour));

  // adds unknown code to lamp values that aren't in the obj
  for (let i in unknownLampStatuses) {
    values.push({
      colour: unknownLampStatuses[i],
      code: 0
    });
  }
  return values;
};

/**
 * formats props for <OdyTag /> colour, text and sort values based on severity of status
 * @param grote
 * @returns {{severity: number, tooltip: string, outages, groteValues: *[], time: *, type: string, value: string, anomalies, good, unknown}|{severity: number, tooltip: string, groteValues: [], time: string, type: string, value: string}}
 */
export const formatGroteStatusTag = grote => {
  let tag = {
    type: '',
    value: '',
    tooltip: '',
    severity: 0,
    groteValues: [],
    time: ''
  };

  if (!grote || Object.keys(grote).length === 0) {
    return tag;
  }

  const formattedGroteValues = reformatGrote(grote);

  const filteredAnomalies = getWarningsAndErrorCodes(formattedGroteValues, [1, 4]);
  const filteredOutages = getWarningsAndErrorCodes(formattedGroteValues, [2, 5]);
  const filteredGoodLamps = getWarningsAndErrorCodes(formattedGroteValues, [3, 6]);
  const filteredUnknown = getWarningsAndErrorCodes(formattedGroteValues, [0]);

  if (filteredGoodLamps.length === 5) {
    tag = formatTagProps('success', GroteStatus.GOOD, GroteStatus.GOOD, 1);
  } else if (filteredOutages.length > 0 && filteredAnomalies.length > 0) {
    tag = formatTagProps('error', GroteStatus.MULTIPLE_ANOMALIES, GroteStatus.ANOMALY, 4);
  } else if (filteredAnomalies.length > 0) {
    if (filteredAnomalies.length === 1) {
      tag = formatTagProps('warning', groteCableColourToLampMap[filteredAnomalies[0].colour], GroteStatus.ANOMALY, 2);
    } else {
      tag = formatTagProps('error', GroteStatus.MULTIPLE_ANOMALIES, GroteStatus.ANOMALY, 4);
    }
  } else if (filteredOutages.length > 0) {
    if (filteredOutages.length === 1) {
      tag = formatTagProps('error', groteCableColourToLampMap[filteredOutages[0].colour], GroteStatus.OUTAGE, 3);
    } else {
      tag = formatTagProps('error', GroteStatus.MULTIPLE_ANOMALIES, GroteStatus.OUTAGE, 4);
    }
  } else {
    tag = formatTagProps('neutral', GroteStatus.UNKNOWN, GroteStatus.UNKNOWN, 0);
  }

  return {
    ...tag,
    groteValues: formattedGroteValues,
    anomalies: filteredAnomalies,
    outages: filteredOutages,
    good: filteredGoodLamps,
    unknown: filteredUnknown,
    time: grote?.time
  };
};

export const getLampStatusFromGrote = (colour, grote) => {
  let code = 0;

  grote.forEach(lamp => {
    if (lamp.colour === colour) {
      code = lamp.code;
    }
  });

  return code;
};

const getStatusTextForLamp = (key, grote) => {
  const lampColours = positionsToColour(key);

  return lampColours.map(lamp => ({
    colour: lamp,
    code: groteLightState[getLampStatusFromGrote(lamp, grote)]
  }));
};

export const formatGroteToVisualisationProps = grote => {
  const statuses = {};
  for (const { colour, code } of grote) {
    const positions = colorToPositions[colour];
    for (const pos of positions) {
      statuses[pos] = maxCode(statuses[pos], code);
    }
  }

  Object.keys(statuses).forEach(key => {
    statuses[key] = {
      colour: groteCodeToStatusColour[statuses[key]],
      tooltipValues: getStatusTextForLamp(key, grote)
    };
  });

  return statuses;
};

export const formatLampStatusString = (lampCableColour, code, formatMessage) =>
  `${formatMessage(strings.lightMonitoringStatus[cableColourToLampType[lampCableColour]])} (${formatMessage(
    strings.lightMonitoringStatus[code]
  )})`;
