import React from 'react';
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import LocaleString from '../../../Utils/LocaleString';

const ClearSelectionBtn = ({ active, clearFilter }) => {
  if (active) {
    return (
      <div className='tm-clear-filters-btn'>
        <Button type='primary' shape='round' size='large' onClick={() => clearFilter()} icon={<CloseCircleOutlined />}>
          {' '}
          <LocaleString type='short' id='clearSelection' />
        </Button>
      </div>
    );
  }
  return null;
};

export default ClearSelectionBtn;
