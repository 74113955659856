import React from 'react';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { selectIsDataLoading } from '../../../../selectors';
import { InfoCard } from '../../../../ReusableComponents/Cards/InfoCard';
import { pageTitle } from '../../../../helpers/defaultStrings';

import './TrailersLoading.scss';

export const TrailersLoading = () => {
  const { formatMessage } = useIntl();
  const isTrailersDataLoading = useSelector(selectIsDataLoading);

  if (!isTrailersDataLoading) {
    return null;
  }

  return (
    <InfoCard>
      <div className='main-content'>
        <Spin className='spinner' spinning={isTrailersDataLoading}></Spin>
        <span>{formatMessage(pageTitle.loadingTrailers)}</span>
      </div>
    </InfoCard>
  );
};
