import { cleanBrakeEvents, cleanTelemetry } from './datacleaners';
import actionTypes from '../actions/actionTypes';

export const initialBrakingEventsState = {
  deviceId: null,
  items: null,
  error: null,
  processing: false,
  selected: {
    event: null,
    eventId: null,
    telemetry: null,
    processing: false,
    error: null
  }
};

function brakingEventsReducer(state = initialBrakingEventsState, action) {
  switch (action.type) {
    // get brake events
    case actionTypes.GET_BRAKE_EVENTS_REQUEST:
      return {
        ...state,
        deviceId: action.deviceId,
        items: null,
        error: null,
        processing: true,
        selected: {
          event: null,
          eventId: null,
          telemetry: null,
          processing: false,
          error: null
        }
      };
    case actionTypes.GET_BRAKE_EVENTS_SUCCESS:
      if (action.events && action.deviceId === state.deviceId) {
        return {
          ...state,
          deviceId: action.deviceId,
          items: (state.items || []).concat(cleanBrakeEvents(action.events)),
          processing: !action.final
        };
      } else {
        return state;
      }
    case actionTypes.GET_BRAKE_EVENTS_FAILURE:
      return {
        ...state,
        items: null,
        processing: false,
        error: action.error
      };
    case actionTypes.NO_BRAKE_EVENTS: {
      return {
        ...state,
        items: null,
        processing: false,
        error: null,
        deviceId: action.deviceId
      };
    }
    // get telemetry data
    case actionTypes.GET_TELEMETRY_REQUEST:
      return {
        ...state,
        selected: {
          ...state.selected,
          event: action.event,
          eventId: action.eventId,
          telemetry: null,
          processing: true,
          error: null
        }
      };
    case actionTypes.GET_TELEMETRY_SUCCESS:
      if (action.items && state.selected.event && state.selected.eventId === action.eventId) {
        return {
          ...state,
          selected: {
            ...state.selected,
            telemetry: (state.selected.telemetry || []).concat(cleanTelemetry(action.items)),
            processing: !action.final
          }
        };
      } else {
        return state;
      }
    case actionTypes.GET_TELEMETRY_FAILURE:
      return {
        ...state,
        selected: {
          ...state.selected,
          telemetry: null,
          processing: false,
          error: action.error
        }
      };
    default:
      return state;
  }
}

export default brakingEventsReducer;
