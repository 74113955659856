import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as strings from '../../helpers/defaultStrings';
import { getCleanedDescriptionNumber } from '../../helpers/odrerrorDefaultStrings';
import './ErrorDrawerContext.scss';
import { getFixedInternationalLanguageCode } from '../../helpers/functions';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import iabsErrorCodes from './iabsErrorCodes.json';
import tebseErrorCodes from './tebseErrorCodes.json';

const { REACT_APP_HELP_BASE_URL } = ENV_CONFIG;

const availableDocumentation = {
  iABSErrorCodes: new Set(iabsErrorCodes),
  tebseErrorCodes: new Set(tebseErrorCodes)
};

const ErrorDrawerContext = ({
  isMask,
  isTrailerIABS,
  visible,
  errorCode,
  intl: { formatMessage },
  onCloseCallback
}) => {
  const { language } = useSelector(store => store.language);
  const { user } = useSelector(store => store.auth);
  const [iFrameUrl, setIFrameUrl] = useState({});
  const parsedErrorCode = getCleanedDescriptionNumber(errorCode.toString()).split('.').join('');

  useEffect(() => {
    const userLanguage = language ?? user?.language ?? 'en';
    const displayLanguage = getFixedInternationalLanguageCode(userLanguage, 'en');
    const baseUrl = `${REACT_APP_HELP_BASE_URL}/DtcErrors`;
    const errorFolder = isTrailerIABS ? 'en_TEBS_E_FSP_html.iabs' : `${displayLanguage}_TEBS_E_FSP_html`;
    const errorDocUrl = `${baseUrl}/${errorFolder}/${parsedErrorCode}.htm`;
    setIFrameUrl(errorDocUrl);
  }, [language, user, isTrailerIABS, parsedErrorCode]);

  const availableErrorsSet = isTrailerIABS
    ? availableDocumentation.iABSErrorCodes
    : availableDocumentation.tebseErrorCodes;
  const isIFrameAvailable = errorCode && availableErrorsSet.has(getCleanedDescriptionNumber(errorCode.toString()));
  return (
    <>
      <Drawer
        title={formatMessage(strings.short.faultCodeVal, { VAR_FAULT_CODE: errorCode })}
        width={isMask ? '30%' : '100%'}
        closable={true}
        mask={isMask}
        onClose={onCloseCallback}
        visible={visible}
        className={`error-drawer-context ${isMask ? 'width-100' : 'width-30'}`}
      >
        {!isIFrameAvailable && (
          <div className='error-drawer-context-no-data grey-italic-text'>
            {formatMessage(strings.phrase.noDescriptionAvailable)}
          </div>
        )}
        {isIFrameAvailable && <iframe className='error-drawer-context-iframe' src={iFrameUrl} title={errorCode} />}
      </Drawer>
    </>
  );
};

export default injectIntl(ErrorDrawerContext);
