import React, { useState } from 'react';
import { Drawer, Divider, Row, Button, Col } from 'antd';
import { useIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import TPMSSubscriptionSection from './TPMSSubscriptionSection';
import TPMSGeneralSection from './TPMSGeneralSection';
import { reportType } from '../../../helpers/constants';

const DetailsDrawer = ({ isOpen, setOpenDrawer, onClickOk, getSubscription }) => {
  const [changedSubscriptions, setChangedSubscriptions] = useState({});
  const formatMessage = useIntl().formatMessage;

  const onChangeSubscription = subscriptions => {
    let newSubscription = Object.assign({}, changedSubscriptions);
    subscriptions.forEach(({ key, value }) => {
      newSubscription[key] = value;
    });
    setChangedSubscriptions(newSubscription);
  };

  const onCancel = () => {
    setChangedSubscriptions({});
    setOpenDrawer('');
  };

  return (
    <Drawer
      title={formatMessage(strings.reports.trailerTPMS)}
      placement='right'
      onClose={onCancel}
      visible={isOpen}
      width='30%'
      footer={
        <Row justify={'end'} gutter={8}>
          <Col>
            <Button onClick={onCancel}>{formatMessage(strings.button.cancel)}</Button>
          </Col>
          <Col>
            <Button type='primary' onClick={() => onClickOk({ key: reportType.TPMSReport, changedSubscriptions })}>
              {formatMessage(strings.short.ok)}
            </Button>
          </Col>
        </Row>
      }
    >
      <TPMSGeneralSection />
      <Divider />
      <TPMSSubscriptionSection
        subscription={getSubscription(reportType.TPMSReport, changedSubscriptions)}
        onChangeSubscription={onChangeSubscription}
      />
    </Drawer>
  );
};

export default DetailsDrawer;
