import React from 'react';
import numeral from 'numeral';
import { Tooltip } from 'antd';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';

const getRecency = data => {
  if (data.recencyScore) {
    return numeral(data.recencyScore).format('0.0');
  }
  return '';
};

const Cell = ({ rowData }) => {
  const recencyScore = getRecency(rowData);
  if (recencyScore !== '') {
    return <p>{recencyScore}</p>;
  }
  return <NoValueSpan />;
};

const RecencyScoreColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.recencyScore)}>
        {formatMessage(strings.columns.recencyScore)}{' '}
      </Tooltip>
    ),
    key: 'recencyScore',
    dataIndex: 'recencyScore',
    searchKey: formatMessage(strings.columns.recencyScore),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () =>
      trailers.sort('recencyScore', (a, b) =>
        a.recencyScore === null || a.recencyScore === undefined || a.recencyScore < b.recencyScore ? -1 : 1
      ),
    width: 180
  };
};

const RecencyScoreColumnHeader = formatMessage => formatMessage(strings.columns.recencyScore);
const RecencyCSVData = trailer => getRecency(trailer);
const RecencyScoreCSVColumn = {
  header: RecencyScoreColumnHeader,
  data: RecencyCSVData
};

export default { table: RecencyScoreColumn, csv: RecencyScoreCSVColumn };
