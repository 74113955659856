import React, { useState, useRef } from 'react';
import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import actions from '../../../actions';
import { getNotifColumns, getNotifRowData } from './ReportEmailsHelpers/ReportEmailsColumns';
import { triggerGenericEvent } from '../../../helpers/googleAnalytics';
import useDebounce from '../../../customHooks/useDebounce';

const ReporEmails = ({ notificationRows }) => {
  const dispatch = useDispatch();
  const [searchedColumn, setSearchedColumn] = useState({});
  const searchInput = useRef(null);
  const formatMessage = useIntl().formatMessage;
  const notificationTypesToListenTo = useSelector(store => store.notifications?.notificationTypesToListenTo);
  const [userChanges, setUserChanges] = useState(0);
  useDebounce(() => triggerGenericEvent('User', 'Notifications Updated', 'configure'), 5000, [userChanges]);

  let tableData = getNotifRowData(notificationRows, formatMessage);
  const emailNotifStatus = deriveStateFromReducer(
    notificationTypesToListenTo,
    tableData.map(d => d.key)
  );

  tableData = tableData.map(row => {
    const rowWithStatus = { ...row };
    rowWithStatus.isEmailActive = emailNotifStatus[row.key];
    return rowWithStatus;
  });

  const onToggleEmail = (notifKey, newValue) => {
    const update = { ...notificationTypesToListenTo };
    update[notifKey] = update[notifKey] || {};
    update[notifKey].email = newValue ? 1 : 0;
    dispatch(actions.notifications.updateNotificationTypesToListenTo(update));
    setUserChanges(userChanges + 1);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: selectedKeys[0] });
  };

  const handleResetSearch = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchedColumn({ ...searchedColumn, [dataIndex]: undefined });
  };

  const tableColumns = getNotifColumns(onToggleEmail, formatMessage, searchInput, handleSearch, handleResetSearch);

  return <Table className='notification-configure-table' columns={tableColumns} dataSource={tableData} />;
};

function deriveStateFromReducer(notificationDataFromReducer, tableDataKeys = []) {
  const emailStatus = {};
  tableDataKeys.forEach(key => {
    const element = notificationDataFromReducer[key];
    emailStatus[key] = element?.email === 1;
  });
  return emailStatus;
}

export default ReporEmails;
