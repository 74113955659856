import { selectFilteredEntitiesIds, selectPlacesItems, selectProductFeatures } from '../../../selectors';
import { getIsTypeGeofence } from '../../../helpers/alarms/functions';
import { receiveNotification } from '../../notifications';
import { isScalar } from '../../../helpers/functions';

export const showIfPlaceAlarm = ({ data, places }) => {
  // temp fix filter out place alarms that occur in places the user doesn't have access to

  const eventData = data?.value?.event;
  if (!isScalar() || !getIsTypeGeofence({ type: data?.value?.reason })) {
    return true;
  }
  const placesIds = places.map(place => place.id);
  const id = eventData?.geofences_details?.geofences?.[0]?.id;
  return id && placesIds.includes(id);
};

export const showIfFeaturesEnabled = (trigger, productFeatures) => {
  if (!productFeatures.showWatchman && trigger === 'Watchman') {
    return false;
  }

  return true;
};

export const isShowNotification = ({ data: messagePayload, deviceAssetIds, places, productFeatures }) => {
  const data = { value: messagePayload };
  const assetId = data?.value?.event?.assetId;
  const trigger = data?.value?.event?.header?.event?.trigger;

  return (
    assetId &&
    deviceAssetIds.includes(assetId) &&
    showIfPlaceAlarm({
      data,
      places
    }) &&
    showIfFeaturesEnabled(trigger, productFeatures)
  );
};

export const handleCustomerTopic = data => (dispatch, getState) => {
  const state = getState();
  const productFeatures = selectProductFeatures(state);
  if (
    isShowNotification({
      data,
      productFeatures,
      deviceAssetIds: selectFilteredEntitiesIds(state),
      places: selectPlacesItems(state)
    })
  ) {
    dispatch(receiveNotification(data, getState));
  }
};
