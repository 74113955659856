import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import './TrailerMapGeoJSONTooltip.scss';
import { getDevicesInCircle, getDevicesInPolygon } from '../../helpers/livemapZoom';
import { selectEntities } from '../../selectors';

const TrailerMapGeoJSONTooltip = ({ data: { place, position }, showTrailers = true }) => {
  const { formatMessage } = useIntl();
  const devices = useSelector(selectEntities);
  const trailerNames = useSelector(store => store?.trailerDetails?.trailersDefaultDisplayNames || {});
  const { x, y } = position;

  const geometry = position.object?.geometry ?? position.object?.geofenceDefinition?.geometry;

  const coordinates = geometry?.coordinates;
  const type = geometry?.type;
  let inboundsDevices = [];

  if (!coordinates?.length || !type) {
    return;
  }

  if (showTrailers && coordinates.length > 0 && type) {
    if (type === 'Circle' || type === 'Point') {
      const properties = position.object?.properties ?? position?.object?.geofenceDefinition?.properties;
      inboundsDevices = getDevicesInCircle(devices, coordinates, properties?.radius, properties);
    } else {
      inboundsDevices = getDevicesInPolygon(devices, coordinates);
    }
  }

  let inboundsTrailersNames = inboundsDevices.map(assetId => trailerNames[assetId] || assetId);

  if (inboundsDevices.length > 5) {
    inboundsTrailersNames = inboundsDevices.slice(0, 5);
    inboundsTrailersNames.push('...');
  }

  const titleStringKey = (place.type && strings.geofence[place.type]) || strings.short.unknown;
  return (
    <div className='geo-json-tooltip' style={{ top: y, left: x }}>
      <div className='trailer-title'>{formatMessage(titleStringKey)}</div>

      <div className='geo-title place-name'>{formatMessage(strings.short.name)}:</div>
      <div>{place.name}</div>

      <div className='trailer-count'>
        <div className='geo-title'>{formatMessage(strings.short.trailers)}:</div>
        <div className='tooltip-text'>{inboundsDevices.length}</div>
      </div>
      {inboundsDevices.length > 0 && (
        <div className='geo-trailers-list'>
          {inboundsTrailersNames.map((trailer, index) => (
            <div className={'trailer-name-item'} key={`geo-trailer-name-${index}`}>
              {trailer}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TrailerMapGeoJSONTooltip;
