import { useEffect } from 'react';
import useTimeout from './useTimeout';

// runs given callback debounced by delay on dependencies change
// use to trigger debounced side effects on state change / user action
export default function useDebounce(callback, delay, dependencies = []) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}
