import * as defaultStrings from './defaultStrings';
import messages from '../assets/i18n/dtcerrorMessages/odrerrorDefaultMessages.json';
import iABSmessages from '../assets/i18n/dtcerrorMessages/iABSodrerrorDefaultMessages.json';

export const getCleanedDescriptionNumber = errorCode => errorCode.replace(/^0+/, '');
const getModuleNumber = errorCode => getCleanedDescriptionNumber(errorCode).split('.')[0];

const dtcErrorMessagesMap = messages.reduce(
  (acc, elt) => ({
    ...acc,
    [elt.id]: elt
  }),
  {}
);
const iABSErrorMessagesMap = iABSmessages.reduce(
  (acc, elt) => ({
    ...acc,
    [elt.id]: elt
  }),
  {}
);

export const getModuleMessage = (errorCode, formatMessage, isiABS) => {
  const moduleNumber = getModuleNumber(errorCode.toString());
  const messageKey = `odrerror.module.${moduleNumber}`;

  let message;
  if (isiABS) {
    message = iABSErrorMessagesMap[messageKey];
    return message?.defaultMessage || formatMessage(defaultStrings.short.unknown);
  } else {
    message = dtcErrorMessagesMap[messageKey];
    return message ? formatMessage(message) : formatMessage(defaultStrings.short.unknown);
  }
};

export const getDescriptionMessage = (errorCode, formatMessage, isiABS) => {
  const moduleNumber = getCleanedDescriptionNumber(errorCode.toString());
  const messageKey = `odrerror.description.${moduleNumber}`;
  let message;
  if (isiABS) {
    message = iABSErrorMessagesMap[messageKey];
    return message?.defaultMessage || formatMessage(defaultStrings.short.unknown);
  } else {
    message = dtcErrorMessagesMap[messageKey];
    return message ? formatMessage(message) : formatMessage(defaultStrings.short.unknown);
  }
};
