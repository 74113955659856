import React, { useEffect, useState } from 'react';
import { Row, Tooltip } from 'antd';
import * as strings from '../../helpers/defaultStrings';

import './TireMicroView.scss';
import { getTimeDate } from '../../helpers/functions';
import { mostRecentValuesTPMS } from '../../helpers/trailer';
import { useSelector } from 'react-redux';

const MicroTire = ({ data, twinTire, formatMessage }) => {
  if (!data) {
    return '';
  }
  return (
    <Tooltip
      title={formatMessage(strings.short.ebsSingleTireTimeVal, {
        value: data.time ? getTimeDate(data.time) : '-'
      })}
      placement='topLeft'
    >
      <div
        className={`tire-micro-view__tire tire-micro-view__tire--${twinTire ? 'twin' : 'single'} ${
          data.hasAlert ? 'error' : ''
        } ${data.isOldData ? 'old-data' : ''}`}
      />
    </Tooltip>
  );
};

const TireMicroView = ({ trailer, semiTransparent, formatMessage }) => {
  const [tiresData, setTiresData] = useState({});
  const isMetric = useSelector(store => store.auth.isMetric);

  useEffect(() => {
    setTiresData(mostRecentValuesTPMS(trailer, {}, false, isMetric));
  }, [trailer, trailer.time, isMetric]);

  if (!trailer || !trailer.axles || !trailer.tires || trailer.tires.length === 0) return '-';

  const axles = new Array(trailer.axles).fill(1);
  let twinTyres = false;
  if (trailer.tyreChoice) {
    twinTyres = trailer.tyreChoice === 'twinTyres';
  } else if (trailer.tires) {
    twinTyres = trailer.tires.some(t => t.location && t.location.endsWith('2'));
  }

  return (
    <Row type='flex'>
      {axles.map((_, i) => (
        <div key={i} style={{ marginRight: 4 }}>
          <div>
            <div>
              <div>
                {twinTyres && (
                  <MicroTire
                    twinTire={twinTyres}
                    formatMessage={formatMessage}
                    data={tiresData[`A${i + 1}R2`]}
                    semiTransparent={semiTransparent}
                  />
                )}
                <MicroTire
                  twinTire={twinTyres}
                  formatMessage={formatMessage}
                  data={tiresData[`A${i + 1}R1`]}
                  semiTransparent={semiTransparent}
                />
              </div>
            </div>
            <div style={{ height: 4 }}></div>
            <div>
              <div>
                <MicroTire
                  twinTire={twinTyres}
                  formatMessage={formatMessage}
                  data={tiresData[`A${i + 1}L1`]}
                  semiTransparent={semiTransparent}
                />
                {twinTyres && (
                  <MicroTire
                    twinTire={twinTyres}
                    formatMessage={formatMessage}
                    data={tiresData[`A${i + 1}L2`]}
                    semiTransparent={semiTransparent}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Row>
  );
};

export const BrakesMicroView = ({ brakePads, lastConnect }) => {
  if (!brakePads) return '-';
  return (
    <div>
      {lastConnect && (
        <div>
          <span>{getTimeDate(lastConnect)}</span>
        </div>
      )}
    </div>
  );
};

export default TireMicroView;
