import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';

import battery0Img from '../../../../../../assets/images/batteryLevels/batteryLevel0.png';
import battery25Img from '../../../../../../assets/images/batteryLevels/batteryLevel25.png';
import battery25ChargingImg from '../../../../../../assets/images/batteryLevels/batteryLevel25Charging.png';
import battery50Img from '../../../../../../assets/images/batteryLevels/batteryLevel50.png';
import battery50ChargingImg from '../../../../../../assets/images/batteryLevels/batteryLevel50Charging.png';
import battery100Img from '../../../../../../assets/images/batteryLevels/batteryLevel100.png';
import battery100ChargingImg from '../../../../../../assets/images/batteryLevels/batteryLevel100Charging.png';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const powerIsValid = power => {
  if (!power) return false;
  if (!power?.battery?.level) return false;
  return true;
};

const getBatteryImage = battery => {
  const { level } = battery;
  const isCharging = battery.status === 'Charging';
  switch (level) {
    case 'low':
      return isCharging ? battery25ChargingImg : battery25Img;
    case 'medium':
      return isCharging ? battery50ChargingImg : battery50Img;
    case 'high':
      return isCharging ? battery100ChargingImg : battery100Img;
    default:
      return battery0Img;
  }
};

const Cell = ({ rowData, formatMessage }) => {
  if (powerIsValid(rowData.power)) {
    return (
      <div>
        <img
          alt={rowData.power.battery.level}
          src={getBatteryImage(rowData.power.battery)}
          className='list-item-icon'
        />
        {formatMessage(strings.short[rowData.power.battery.level])}
      </div>
    );
  }
  return <NoValueSpan />;
};

const BatteryLevelColumn = ({ trailers, formatMessage }) => {
  const categories = [
    { text: formatMessage(strings.short.high), value: 'high' },
    { text: formatMessage(strings.short.medium), value: 'medium' },
    { text: formatMessage(strings.short.low), value: 'low' },
    { text: formatMessage(strings.short.dead), value: 'dead' },
    { text: formatMessage(strings.short.unknown), value: 'unknown' }
  ];

  const filterFunction = checkedCategories => {
    trailers.filter('metaDataTrailerMake', t => {
      if (powerIsValid(t.power)) {
        return checkedCategories[t.power.battery.level];
      }
      return checkedCategories['unknown'];
    });
  };

  return {
    title: (
      <Tooltip title={formatMessage(strings.short.batteryLevel)}>{formatMessage(strings.short.batteryLevel)}</Tooltip>
    ),
    key: 'power',
    dataIndex: 'power',
    searchKey: formatMessage(strings.columns.batteryLevel),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    categories: categories,
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('metaDataTrailerMake'),
    width: 150
  };
};

const BatteryLevelCSVColumnHeader = formatMessage => formatMessage(strings.columns.batteryLevel);
const BatteryLevelCSVData = trailer => {
  if (trailer.power?.battery.level === 'dead') return 'empty';
  return trailer.power?.battery.level;
};
const BatteryCSVColumn = {
  header: BatteryLevelCSVColumnHeader,
  data: BatteryLevelCSVData
};

export default { table: BatteryLevelColumn, csv: BatteryCSVColumn };
