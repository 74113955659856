import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, strings, formatMessage }) => {
  if (rowData.odrStatus && strings[rowData.odrStatus.type][rowData.odrStatus.id]) {
    return (
      <p>{formatMessage(strings[rowData.odrStatus.type][rowData.odrStatus.id], { value: rowData.odrStatus.val })}</p>
    );
  }
  return <NoValueSpan />;
};

const OdrStatusColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.short.odrParsingStatus)}>
        {formatMessage(strings.short.odrParsingStatus)}
      </Tooltip>
    ),
    key: 'odrStatus',
    dataIndex: 'odrStatus',
    searchKey: formatMessage(strings.short.odrParsingStatus),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <Cell rowData={rowData} strings={strings} formatMessage={formatMessage} />,
    sorter: () =>
      trailers.sort('odrStatus', (a, b) =>
        a.odrStatus.id === null || a.odrStatus.id === undefined || a.odrStatus.id < b.odrStatus.id ? -1 : 1
      ),
    width: 200
  };
};

const OdrStatusColumnHeader = formatMessage => formatMessage(strings.short.odrParsingStatus);
const OdrStatusCSVData = (trailer, formatMessage) =>
  formatMessage(strings[trailer.odrStatus.type][trailer.odrStatus.id], { value: trailer.odrStatus.val });
const OdrStatusCSVColumn = {
  header: OdrStatusColumnHeader,
  data: OdrStatusCSVData
};

export default { table: OdrStatusColumn, csv: OdrStatusCSVColumn };
