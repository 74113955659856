import React from 'react';
import { UniqueCategories } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.metaDataTpmsSource) {
    return <p>{rowData.metaDataTpmsSource}</p>;
  }
  return <NoValueSpan />;
};

const TpmsSourceColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    trailers.filter('metaDataTpmsSource', t => {
      if (!t.metaDataTpmsSource) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.metaDataTpmsSource];
    });
  };

  return {
    title: <Tooltip title={formatMessage(strings.short.tpmsSource)}>{formatMessage(strings.short.tpmsSource)}</Tooltip>,
    key: 'metaDataTpmsSource',
    dataIndex: 'metaDataTpmsSource',
    searchKey: formatMessage(strings.short.tpmsSource),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <Cell rowData={rowData} />,
    categories: UniqueCategories(
      trailers.unfilteredData.map(t => t.metaDataTpmsSource),
      true
    ),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('metaDataTpmsSource'),
    sorter: () =>
      trailers.sort('metaDataTpmsSource', (a, b) =>
        !a.metaDataTpmsSource || a.metaDataTpmsSource < b.metaDataTpmsSource ? -1 : 1
      ),
    width: 150
  };
};

const TpmsSourceColumnHeader = formatMessage => formatMessage(strings.short.tpmsSource);
const TpmsSourceCSVData = trailer => trailer.metaDataTpmsSource;
const TpmsSourceCSVColumn = {
  header: TpmsSourceColumnHeader,
  data: TpmsSourceCSVData
};

export default { table: TpmsSourceColumn, csv: TpmsSourceCSVColumn };
