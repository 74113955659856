import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const NameColumn = ({ trailers, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.name)}>{formatMessage(strings.short.name)}</Tooltip>,
    key: 'name',
    dataIndex: 'name',
    searchKey: formatMessage(strings.short.name),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <p>{rowData.name}</p>,
    search: searchString =>
      trailers.filter('nameSearch', t => t.name.toString().toLowerCase().includes(searchString.toLowerCase())),
    removeSearch: () => trailers.removeFilter('nameSearch'),
    width: 250
  };
};

const NameColumnHeader = formatMessage => formatMessage(strings.short.name);
const NameCSVData = trailer => trailer.name;
const NameCSVColumn = {
  header: NameColumnHeader,
  data: NameCSVData
};

export default { table: NameColumn, csv: NameCSVColumn };
