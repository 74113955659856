import React, { useEffect, useState } from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getUnitString, unitType } from '../../helpers/unitConverter';
import { wheelsColors } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { isNullOrUndefined, getTimeDate } from '../../helpers/functions';

/**
 *
 * @param tiresData
 * @param axle = 1|2|3|4...
 * @param position = R|L
 * @param number = 1|2|3|4
 * @param selectedWheels
 * @param onClick
 * @param formatMessage
 * @returns {*}
 * @constructor
 */
const WheelLayoutItem = ({ tiresData, axle, position, number, selectedWheels, onClick, intl: { formatMessage } }) => {
  const isMetric = useSelector(store => store.auth.isMetric);

  const [temperature, setTemperature] = useState(false);
  const [pressure, setPressure] = useState(false);
  const [emptyData, setEmptyData] = useState(true);
  const [time, setTime] = useState(false);
  const [alert, setAlert] = useState(false);
  const [threshold, setThreshold] = useState(false);

  const wheel = 'A' + axle + position + number;
  const colour = wheelsColors[wheel] || '#000';
  const positionText = position === 'L' ? 'left' : 'right';

  const getValueOrDash = value => (isNullOrUndefined(value) ? '-' : value);

  useEffect(() => {
    if (tiresData[wheel]) {
      setTemperature(
        (tiresData[wheel].temperature || tiresData[wheel].temperature === 0) &&
          formatMessage(getUnitString(unitType.temp, isMetric), { value: tiresData[wheel].temperature })
      );
      setPressure(
        (tiresData[wheel].pressure || tiresData[wheel].pressure === 0) &&
          formatMessage(getUnitString(unitType.pressure, isMetric), { value: tiresData[wheel].pressure })
      );
      setThreshold(tiresData[wheel].pressureThreshold);
      setEmptyData(tiresData[wheel].isOldData);
      setTime(tiresData[wheel].time);
      setAlert(tiresData[wheel].hasAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wheel, tiresData]);

  const translateThreshold = threshold => {
    const translationKey = strings.short[`pressureThreshold${threshold.toUpperCase()}`];

    return translationKey ? formatMessage(translationKey) : formatMessage(strings.short.pressureThresholdUNK);
  };

  const getTooltipText = () => (
    <span>
      {formatMessage(strings.short.ebsTireTimeVal, {
        value: time ? getTimeDate(time) : '-'
      })}
      {alert && threshold && threshold.toLowerCase() !== 'ukn' && threshold.toLowerCase() !== 'navl' && (
        <>
          <br />
          {translateThreshold(threshold)}
        </>
      )}
    </span>
  );

  return (
    <Tooltip title={getTooltipText()} placement='topLeft'>
      <div
        className={`wheel-layout__axle__tire wheel-layout__axle__tire--${positionText} wheel-layout__axle__tire--${positionText}-${number} ${
          alert ? 'error' : ''
        } ${alert ? 'error' : ''} ${selectedWheels[wheel] ? 'selected' : ''} ${emptyData ? 'old-data' : ''}`}
        onClick={() => onClick(wheel)}
      >
        <span className={'wheel-layout__axle__tire__info'}>
          {pressure || temperature ? (
            <>
              <span>{getValueOrDash(pressure)}</span>
              <span>{getValueOrDash(temperature)}</span>
            </>
          ) : (
            '--'
          )}
          {alert && (
            <span className={'wheel-layout__axle__tire__alert'}>
              <WarningTwoTone twoToneColor='orange' className='warning-icon' />
            </span>
          )}
        </span>
        <span className={'wheel-layout__axle__tire__name'} style={{ background: colour }}>
          {wheel}
        </span>
        {/*${!wheelData ? 'old-data' : ''}*/}
      </div>
    </Tooltip>
  );
};

export default injectIntl(WheelLayoutItem);
