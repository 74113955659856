import moment from 'moment';
import {
  vehicleTypeMapping,
  tebseAbsSystemMapping,
  tebsfAbsSystemMapping,
  dtcPriorityMap,
  iABSDtcPriorityMap,
  tebseRecordedEventLabelMap,
  tebsfRecordedEventLabelMap,
  priorityMapping,
  tebsfSuspensionTypeIdMapping
} from './constants';
import { getIsTrailerTEBSF, getIsTraileriABS } from '../../helpers/functions';
import LocaleString from '../../Components/Utils/LocaleString';
import React from 'react';
import positions from '../../assets/data/positions.json';
import tools, { getODREepromDumpSize } from './helperTools';
import numeral from 'numeral';
import { formatGroteStatusTag } from '../../helpers/grote';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { yearMonthDayHourMin } from '../../helpers/constants/date';

const { REACT_APP_ENV } = ENV_CONFIG;

function getSuspensionType(intEnum, isTEBSF) {
  let id = '';
  if (isTEBSF) {
    id = tebsfSuspensionTypeIdMapping[intEnum];
  } else if (intEnum) {
    id = 'mechanicalSuspension';
  } else {
    id = 'airSuspension';
  }

  return <LocaleString type='short' id={id} />;
}

function trailers(trailers) {
  return trailers.map((trailer, i) => {
    const axleCount = getAxleCount(trailer);
    return {
      ...trailer,
      axleCount,
      axles: axleCount,
      brand: trailer.Brand || trailer.brand,
      productionDate: trailer.Production_date
        ? moment.utc(trailer.Production_date, 'YYYY [W]w')
        : moment.utc(trailer.productionDate, 'YYYY [W]w'),
      vinNumber: trailer.VIN || trailer.vin,
      vehicleType: vehicleTypeMapping[trailer.Type] || vehicleTypeMapping[trailer.type],
      tires: tools.getLatestValidTPMS(trailer),
      gnss: trailer.gnss
        ? trailer.gnss
        : REACT_APP_ENV === 'integration' && trailer.imei === trailer.assetId
        ? { longitude: positions[i].long, latitude: positions[i].lat }
        : null,
      lightMonitoring: formatGroteStatusTag(trailer.lastValidValues?.grote)
    };
  });
}

export function getAxleCount(trailer) {
  let axleCount = trailer && (trailer.metaDataAxles || trailer.Axles || trailer.axles);
  if (!axleCount) {
    const tpmsData = trailer?.lastValidValues?.tpms?.data ?? [];
    if (!tpmsData.some(data => !data.location)) {
      axleCount = tpmsData.reduce((acc, elt) => (elt.location[0] > acc ? elt.location[0] : acc), null);
    }
  }
  return numeral(axleCount).value();
}

function dtcEvent(state, event, id, isiABS) {
  const eventData = event.data ? event.data : event;
  const [module, issueType] = eventData.ErrorID ? eventData.ErrorID.split('.') : [null, null];
  const processedErrorCode = `${+module}${+issueType === 0 ? '' : `.${+issueType}`}`;
  const priority = isiABS ? iABSDtcPriorityMap[processedErrorCode] : dtcPriorityMap[processedErrorCode];
  const timestamp = moment.utc(eventData?.TimeDateStart?.Text, yearMonthDayHourMin);
  return Object.assign({}, eventData, {
    priority,
    id,
    module,
    issueType,
    originalTimestamp: eventData?.TimeDateStart?.Text,
    timestamp: timestamp && timestamp.year() > 1980 && timestamp.year() < 2030 ? timestamp : null,
    mileage: eventData.MileageStart,
    errorCode: eventData.ErrorID,
    color: priority != null ? priorityMapping[priority + 1].color : priorityMapping[1].color,
    situation: {
      pneumaticPressure: eventData.DPressure_pneumatic,
      brakePressure: eventData.BrakePressureH1,
      brakePressureH2: eventData.BrakePressureH2,
      mileage: eventData.MileageStart,
      checksum: eventData.Checksum,
      supplyPressure: eventData.SupplyPressure,
      diagnosticEventCounter: eventData.DiagnosticEventCounter,
      supplyVoltage:
        eventData.SupplyVoltage && eventData.SupplyVoltage > 50
          ? (eventData.SupplyVoltage / 2.56) * 0.2
          : eventData.SupplyVoltage,
      pressureCan: eventData.DPressure_CAN,
      speed: eventData.VehicleSpeed
    },
    eventType: 'dtc'
  });
}

function recordedEvent(event, id, isTEBSF) {
  const eventData = event.data || event;
  const timestamp = moment.utc(eventData?.TimeDateStart?.Text, yearMonthDayHourMin);
  const axleLoadSum = isTEBSF
    ? eventData.specific_data?.total_h_load + eventData?.specific_data.total_l_load
    : eventData.AxleLoadSum;
  return {
    ...eventData,
    AxleLoadSum: axleLoadSum,
    id,
    eventId: eventData.EventIdentification,
    eventNameId: isTEBSF
      ? tebsfRecordedEventLabelMap[eventData.EventIdentification]
      : tebseRecordedEventLabelMap[eventData.EventIdentification],
    originalTimestamp: eventData?.TimeDateStart?.Text,
    timestamp: timestamp && timestamp.year() > 1980 && timestamp.year() < 2030 ? timestamp : null,
    mileage: eventData.MileageStart,
    situation: {
      counter: eventData.EventCounter,
      axleLoad: axleLoadSum,
      latitude: eventData.GPSPosition_latitude,
      longitude: eventData.GPSPosition_longitude,
      demand: eventData.DemandValueBeforeBrakeForceDistribution,
      overflowCount: eventData.EventCounterOverflow,
      speed: eventData.VehicleSpeed
    },
    eventType: 'recorded'
  };
}

function trailerTrip(trip, id) {
  const tripData = trip.data ? trip.data : trip;
  const timestamp = moment.utc(tripData?.TimeDate?.Text, yearMonthDayHourMin);

  return Object.assign({}, trip, {
    id,
    timestamp: timestamp && timestamp.year() > 1980 && timestamp.year() < 2030 ? timestamp : null,
    maxSpeed: tripData.Max_vehicle_speed,
    mileage: tripData.mileage,
    maxAxleLoad: tripData.Max_axleload,
    minAxleLoad: tripData.Min_axleload,
    avgAxleLoad: tripData.Axleload_sum,
    distance: tripData.Distance,
    brakeCount: tripData.Brake_counter,
    latAcceleration: tripData.Lateral_acceleration,
    absCount: tripData.ABS_counter,
    duration: tripData.Duration
  });
}

function withDuration(trips) {
  const sortedTrips = trips
    .slice() // copy
    .sort((a, b) => a.mileage - b.mileage);
  return sortedTrips.map((trip, i) =>
    Object.assign({}, trip, {
      nextTripTimestamp:
        i < sortedTrips.length - 1 && trip.timestamp && sortedTrips[i + 1].timestamp
          ? sortedTrips[i + 1].timestamp
          : null,
      nextTripMileage: i < sortedTrips.length - 1 ? sortedTrips[i + 1].mileage : null,
      durationToNextTrip:
        i < sortedTrips.length - 1 && trip.timestamp && sortedTrips[i + 1].timestamp
          ? sortedTrips[i + 1].timestamp.diff(trip.timestamp)
          : 0,
      distanceToNextTrip: i < sortedTrips.length - 1 ? sortedTrips[i + 1].mileage - trip.mileage : 0
    })
  );
}

function tripsWithDuration(trips) {
  return withDuration(trips);
}

function addBkvDetails(axles, bkv, isTEBSF) {
  for (let i = 0; i < axles.length; i++) {
    const axle = axles[i];
    let bkvId = '';
    if (isTEBSF) {
      bkvId = `axle_${i + 1}`;
    } else {
      if (axle.Sensor === 1) {
        bkvId = `axle_c-d`;
      }
      if (axle.Sensor === 2) {
        bkvId = `axle_e-f`;
      }
    }

    axles[i]['numberOfTeeth'] = bkvId ? bkv?.Number_of_teeth[bkvId] : null;
    axles[i]['tyreCircumference'] = bkvId ? bkv?.Tyre_circumference[bkvId] : null;
  }
  return axles;
}

export function trailer(state, trailer, trailersDefaultDisplayNames) {
  const isTEBSF = getIsTrailerTEBSF(trailer);

  const bkv = trailer?.data?.VehicleDATA?.BKV;

  const trailerFromState = state?.trailers?.items?.find(item => item.assetId === trailer.assetId);
  let isiABS = getIsTraileriABS(trailerFromState);
  const uploadDumpSize = trailer?.data?.ODR_eeprom_dump_size || trailer?.ODR_eeprom_dump_size;
  if (!isiABS && uploadDumpSize) {
    isiABS = getODREepromDumpSize(uploadDumpSize) === 8;
  }

  let dtcData = trailer?.data?.DiagEventRecorder?.Event;
  if (!dtcData?.length) {
    dtcData = trailer?.data?.Failure_Memory?.Failure || [];
  }

  const dtc = dtcData.map((event, i) => dtcEvent(state, event, i, isiABS));

  const recordedEvents = (trailer?.data?.EventRecorder?.Event ?? []).map((event, i) =>
    recordedEvent(event, i, isTEBSF)
  );

  const trips = (trailer?.data?.trip_memory?.Trip ?? []).map((trip, i) => trailerTrip(trip, i));

  const tripsWithDuration = withDuration(trips);

  const eepromSize = trailer?.data?.ODR_eeprom_dump_size ?? trailer.ODR_eeprom_dump_size;
  return {
    vinNumber: trailer?.data?.VehicleDATA?.VIN ?? trailer?.data?.VehicleDATA?.vin,
    brand: trailer?.data?.VehicleDATA?.manufacturer,
    vehicleType: vehicleTypeMapping[trailer?.data?.VehicleDATA?.vehicle_type_1],
    model: trailer?.data?.VehicleDATA?.vehicle_type,
    productionDate: moment.utc(trailer?.data?.VehicleDATA?.trailer_production_date, 'YYYY [W]w'),
    absSystem: isTEBSF
      ? tebsfAbsSystemMapping[trailer?.data.VehicleDATA?.abs_system]
      : tebseAbsSystemMapping[trailer?.data.VehicleDATA?.abs_system],
    suspensionType: getSuspensionType(trailer?.data?.VehicleDATA?.Suspension_type, isTEBSF),
    ebsType: tools.getEbsType(trailer?.data?.VehicleDATA?.DeviceNumberSpecific?.ECU_type),
    ebsBrakeCalculationNumber: trailer?.data?.VehicleDATA?.BrakeCalculationNumber,
    ebsPartNumber: trailer?.data?.VehicleDATA?.Wabco_Production_Data?.ProductNumber,
    steeringAxle: trailer?.data?.VehicleDATA?.RSS_adhesive_steering_axle ? 'steeringAxle' : 'noSteeringAxle',
    independentWheelSuspension: trailer?.data?.VehicleDATA?.RSS_independent_wheel_suspension
      ? 'independentSuspension'
      : 'rigidAxle',
    tyreChoice: trailer?.data?.VehicleDATA?.RSS_tire_choice ? 'twinTyres' : 'singleTyres',
    modulatorDirection: trailer?.data?.Modular_direction === 2 ? 'oppositeToDirectionOfTravel' : 'directionOfTravel',
    axles: {
      count: trailer?.data?.VehicleDATA?.AxleCount,
      countLift: trailer?.data?.VehicleDATA?.L_AxleCount,
      details: trailer?.data?.VehicleDATA?.Axles
        ? addBkvDetails(trailer?.data?.VehicleDATA?.Axles, bkv, isTEBSF)
        : null,
      bkv: trailer?.data?.VehicleDATA?.BKV
    },
    tires: trailer?.lastFms?.tpms?.data,
    tpms: trailer.tpms,
    brakePads: trailer?.lastFms?.ebs?.brakePads,
    lastOdo: trailer?.lastFms?.ebs?.odometer,
    dtc,
    recordedEvents,
    vehicleId: trailer.vehicleId,
    trips: tripsWithDuration,
    imei: trailer.imei,
    assetId: trailer.assetId,
    originalData: trailer,
    eepromSize: getODREepromDumpSize(eepromSize),
    eepromSizeDisplay: !eepromSize ? (
      <LocaleString type='short' id='unknown' />
    ) : (
      <span>
        {eepromSize / 1024} <LocaleString type='abbrev' id='kiloBits' />
      </span>
    ),
    defaultDisplayName:
      trailersDefaultDisplayNames[trailer.assetId] ||
      trailer.licensePlate ||
      trailer.trailer ||
      trailer.name ||
      'ID: ' + trailer.assetId,
    adap25:
      trailer.data && trailer.data.ADAP25 && trailer.data.ADAP25.GOHC && trailer.data.ADAP25.GOHC.operation_hours > 0
        ? trailer.data.ADAP25
        : null,
    histograms: trailer.data.Histograms,
    brakeValues: trailer.data.BrakeValues
  };
}

const clean = {
  trailers,
  trailer,
  dtcEvent,
  recordedEvent,
  trailerTrip,
  tripsWithDuration
};

export default clean;
