import React from 'react';
import { Tooltip, Progress, Typography } from 'antd';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import './GohcColumn.scss';
import { triggerGenericEvent } from '../../../../../../helpers/googleAnalytics';
import { selectionHeaders } from '../../../../../../helpers/constants';

const Cell = ({ gohc, formatMessage }) => {
  if (gohc && gohc.status) {
    const perc = gohc.percOfTimeSpent;
    let width = perc;
    if (perc >= 100) {
      width = 100;
    } else if (perc <= 10) {
      width = 10;
    }

    let barColor;

    switch (gohc.status) {
      case 'empty':
        barColor = '#30AB5BFF';
        break;
      case 'safe':
        barColor = '#30AB5BFF';
        break;
      case 'warning':
        barColor = '#E8BF29FF';
        break;
      case 'error':
        barColor = '#DD0C29CC';
        break;
      default:
        barColor = '#30AB5BFF';
    }

    const tooltip = (
      <div>
        <div>
          {formatMessage(strings.short.customerName)}: {gohc.service_name_cust}
        </div>
        <div>
          {formatMessage(strings.short.hoursSinceService)}: {gohc.timeInService}
        </div>
        <div>
          {formatMessage(strings.short.hoursUntilService)}: {Math.max(0, gohc.serviceTime - gohc.timeInService)}
        </div>
      </div>
    );

    return (
      <Tooltip title={tooltip} placement='top'>
        <div>
          <div className={'bar'}>
            <Progress percent={width} showInfo={false} strokeColor={barColor} />
          </div>
          <div className={'bar-text'}>
            <Typography>
              {!!(gohc.status === 'empty') && formatMessage(strings.short.recentlyServiced)}
              {!!(gohc.status === 'safe' || gohc.status === 'warning') &&
                formatMessage(strings.short.hrsLeftTillService, {
                  value: gohc.serviceTime - gohc.timeInService
                })}
              {!!(gohc.status === 'danger') &&
                formatMessage(strings.short.hrsOverdueService, {
                  value: gohc.timeInService - gohc.serviceTime
                })}
            </Typography>
          </div>
        </div>
      </Tooltip>
    );
  }
  return <NoValueSpan />;
};

const GohcColumn = ({ trailers, formatMessage }) => {
  const categories = [
    { text: formatMessage(strings.short.serviceEmpty), value: 'empty' },
    { text: formatMessage(strings.short.serviceSafe), value: 'safe' },
    { text: formatMessage(strings.short.serviceWarning), value: 'warning' },
    { text: formatMessage(strings.short.serviceDanger), value: 'danger' },
    { text: formatMessage(strings.short.unknown), value: 'unknown' }
  ];

  let lastFilterTime = 0;

  const filterFunction = checkedCategories =>
    trailers.filter('GOHC', t => {
      if (Date.now() - lastFilterTime > 1500) {
        triggerGenericEvent('Filter', 'Filter', 'gohc');
        lastFilterTime = Date.now();
      }

      if (!t.GOHC) {
        return checkedCategories['unknown'];
      }

      return checkedCategories[t.GOHC.status];
    });

  return {
    title: <Tooltip title={formatMessage(strings.short.gohc)}>{formatMessage(strings.short.gohc)}</Tooltip>,
    key: 'GOHC',
    dataIndex: 'GOHC',
    searchKey: formatMessage(strings.short.gohc),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell gohc={rowData.GOHC} formatMessage={formatMessage} />,
    onFilter: filterFunction,
    sorter: () =>
      trailers.sort('GOHC', (a, b) => {
        const a_percOfTimeSpent = a.GOHC && a.GOHC.percOfTimeSpent;
        const b_percOfTimeSpent = b.GOHC && b.GOHC.percOfTimeSpent;
        return a_percOfTimeSpent === null || a_percOfTimeSpent === undefined || a_percOfTimeSpent < b_percOfTimeSpent
          ? -1
          : 1;
      }),
    removeFilter: () => trailers.removeFilter('GOHC'),
    categories,
    width: 170
  };
};

export default { table: GohcColumn, csv: null };
