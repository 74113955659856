import React from 'react';
import { NoValueSpan, UniqueCategories } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { metaDataTrailerMakeSort } from '../../../../../../helpers/sorters/column-sorters';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.metaDataTrailerMake) {
    return <p>{rowData.metaDataTrailerMake}</p>;
  }
  return <NoValueSpan />;
};

const MakeColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    trailers.filter('metaDataTrailerMake', t => {
      if (!t.metaDataTrailerMake) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.metaDataTrailerMake];
    });
  };

  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.trailerMake)}>{formatMessage(strings.columns.trailerMake)}</Tooltip>
    ),
    key: 'metaDataTrailerMake',
    dataIndex: 'metaDataTrailerMake',
    searchKey: formatMessage(strings.columns.trailerMake),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () => trailers.sort('metaDataTrailerMake', metaDataTrailerMakeSort),
    categories: UniqueCategories(
      trailers.unfilteredData.map(t => t.metaDataTrailerMake),
      true
    ),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('metaDataTrailerMake'),
    width: 150
  };
};

const MakeCSVColumnHeader = formatMessage => formatMessage(strings.columns.trailerMake);
const MakeCSVData = trailer => trailer.metaDataTrailerMake;
const MakeCSVColumn = {
  header: MakeCSVColumnHeader,
  data: MakeCSVData
};

export default { table: MakeColumn, csv: MakeCSVColumn };
