import moment from 'moment';
import {
  getBrakePads,
  getEbsTime,
  getSpeed,
  getAxleLoad,
  getOdometer,
  getODREepromDumpSize,
  getLatestValidTPMS,
  getActivityStatus,
  getEBSValid
} from '../actions/ActionResources/helperTools';

const normalizeTrailer = trailer => {
  if (!trailer.deviceIMEI || !trailer.assetId) {
    return null;
  }
  trailer.id = trailer.assetId;
  trailer.imei = trailer.deviceIMEI;
  if (!trailer.gnss) {
    trailer.gnss = {};
  }

  trailer.time = trailer.lastReport;
  trailer.lastPosition = null;

  if (trailer.lastValidValues) {
    trailer.power = trailer.lastValidValues.power ?? null;
    if (trailer.lastValidValues.gnss) {
      trailer.gnss = trailer.lastValidValues.gnss;
      trailer.lastPosition = trailer.lastValidValues.gnss.time;
    }
  }

  trailer.ebsTime = getEbsTime(trailer);
  trailer.useGnssSpeed =
    !trailer.ebsTime || (trailer.ebsTime && trailer.lastPosition && trailer.ebsTime < trailer.lastPosition);
  trailer.speed = getSpeed(trailer, trailer.useGnssSpeed);
  trailer.axleLoad = getAxleLoad(trailer);
  trailer.odometer = getOdometer(trailer);

  if (!trailer.header) {
    trailer.header = {
      imei: trailer.imei,
      event: {
        event: '',
        trigger: ''
      },
      time: trailer.time,
      sequence: 0
    };
  }

  trailer.code = trailer.name;
  trailer.trailer = trailer.licensePlate;
  trailer.name = trailer.format;
  trailer.lastReport = moment.unix(trailer.lastReport);
  trailer.defaultDisplayName = trailer.trailer
    ? trailer.trailer
    : trailer.name
    ? trailer.name
    : 'ID: ' + trailer.assetId;
  trailer.eepromSize = getODREepromDumpSize(trailer.ODR_eeprom_dump_size);
  trailer.tires = getLatestValidTPMS(trailer);
  trailer.brakePads = getBrakePads(trailer);
  trailer.activityStatus = getActivityStatus(
    trailer.sinceState,
    trailer.lastPosition ? moment.utc(moment.unix(trailer.lastPosition)) : null
  );
  trailer.lastEBSValid = getEBSValid(trailer);
  trailer.ebsBrand = trailer?.metadata?.ebs_brand ?? trailer?.lastValidValues?.ebs_trailer?.systemBrand;
  trailer.ebsType = trailer?.metadata?.ebs_model ?? trailer?.lastValidValues?.ebs_trailer?.systemName;
  trailer.metaDataTpmsSource = trailer?.metadata?.tpms_source;
  trailer.metaDataVin = trailer?.metadata?.odr_vin;
  trailer.metaDataTrailerMake = trailer?.metadata?.odr_trailer_make;
  trailer.metaDataTrailerModel = trailer?.metadata?.odr_trailer_model;
  trailer.metaDataTrailerType = trailer?.metadata?.odr_trailer_type;
  trailer.metaDataTrailerYear = trailer?.metadata?.odr_trailer_year;
  trailer.metaDataAxles = trailer?.metadata?.tpms_axlecount;

  return trailer;
};

export const sortTrailers = trailers => {
  return trailers.sort((a, b) => {
    if (!b.lastPosition) {
      return -1;
    }

    if (!a.lastPosition) {
      return 1;
    }
    return b.lastPosition - a.lastPosition;
  });
};

export const dataNormalization = trailers => {
  return trailers.map(t => normalizeTrailer(t));
};

export const filterTrailers = trailers => trailers.filter(trailer => trailer);

export const applyFn = (list, ...functions) => {
  return functions.reduce((res, currentFunction) => {
    return currentFunction(res);
  }, list);
};
