import { createSelector } from '@reduxjs/toolkit';
import {
  DEFAULT_APP_SETTINGS,
  HEALTH_TABLE_COLUMNS,
  DEFAULT_SELECTED_EBPMS_COLUMNS,
  DEFAULT_SELECTED_EBPMS_COLUMNS_TIP,
  DEFAULT_SELECTED_LIVE_MAP_COLUMNS
} from '../helpers/constants';
import { ENV_CONFIG } from '../app/helpers/env-configs';
import { isUserTIP } from '../helpers/functions';

const getSettings = state => state.settings;

export const selectSettingsItems = createSelector(getSettings, state => ({
  ...DEFAULT_APP_SETTINGS,
  ...state?.items
}));

export const selectMaxValidDays = createSelector(
  selectSettingsItems,
  state => state?.maxValidDays ?? DEFAULT_APP_SETTINGS.maxValidDays
);

export const selectTrailerHealthColumns = createSelector(
  selectSettingsItems,
  state => state?.columnSelectiontrailerHealth ?? HEALTH_TABLE_COLUMNS
);

export const selectTrailerLiveMapColumns = createSelector(
  selectSettingsItems,
  state => state?.columnSelectionliveMap ?? DEFAULT_SELECTED_LIVE_MAP_COLUMNS
);

export const selectTrailerEBPMSColumns = createSelector(
  selectSettingsItems,
  state => state.auth.user,
  (settings, user) => {
    if (isUserTIP(user)) {
      return settings?.columnSelectiontrailerEbpms ?? DEFAULT_SELECTED_EBPMS_COLUMNS_TIP;
    }
    return settings?.columnSelectiontrailerEbpms ?? DEFAULT_SELECTED_EBPMS_COLUMNS;
  }
);

export const selectColumnsLockedtrailerEbpms = createSelector(
  selectSettingsItems,
  state => state?.columnsLockedtrailerEbpms
);

export const selectDisplayTrailers = createSelector(
  selectSettingsItems,
  state => state?.displayTrailers ?? DEFAULT_APP_SETTINGS.displayTrailers
);

export const selectDisplayTrailersEBPMS = createSelector(
  selectSettingsItems,
  state => state?.displayTrailersEBPMS ?? DEFAULT_APP_SETTINGS.displayTrailersEBPMS
);

export const selectLivemapAlarmsHours = createSelector(
  selectSettingsItems,
  state => state?.livemapAlarmsHours ?? DEFAULT_APP_SETTINGS.livemapAlarmsHours
);

export const selectWindowSize = createSelector(
  selectSettingsItems,
  state => state?.windowSize ?? DEFAULT_APP_SETTINGS.windowSize
);

export const selectColumnsWidthstrailerEbpms = createSelector(
  selectSettingsItems,
  state => state?.columnsWidthstrailerEbpms ?? DEFAULT_APP_SETTINGS.columnsWidthstrailerEbpms
);

export const selectMaxErrorMargin = createSelector(
  selectSettingsItems,
  state => state?.maxErrorMargin ?? DEFAULT_APP_SETTINGS.maxErrorMargin
);

export const selectTrailerInfoComponents = createSelector(
  selectSettingsItems,
  state => state?.trailerInfoComponents || {}
);
export const selectColumnsLockedliveMap = createSelector(selectSettingsItems, state => state?.columnsLockedliveMap);
export const selectColumnsWidthsliveMap = createSelector(selectSettingsItems, state => state?.columnsWidthsliveMap);

export const selectFcfCustomization = createSelector(selectSettingsItems, state => state?.fcfCustomization);
export const selectTntRightPanelWidth = createSelector(
  selectSettingsItems,
  state => state?.tntRightPanelWidth ?? DEFAULT_APP_SETTINGS.tntRightPanelWidth
);

export const selectTntRightPanelOpen = createSelector(
  selectSettingsItems,
  state => state?.tntRightPanelOpen ?? DEFAULT_APP_SETTINGS.tntRightPanelOpen
);

export const selectMapAccessToken = createSelector(
  selectSettingsItems,
  state => state?.mapAccessToken ?? ENV_CONFIG.REACT_APP_MAPBOX_ACCESS_TOKEN
);

export const selectMapStyleURL = createSelector(
  selectSettingsItems,
  state => state?.mapStyleURL ?? ENV_CONFIG.REACT_APP_MAXBOX_STYLE
);

export const selectEbpmsLeftPanelWidth = createSelector(
  selectSettingsItems,
  state => state?.ebpmsLeftPanelWidth ?? DEFAULT_APP_SETTINGS.ebpmsLeftPanelWidth
);

export const selectPerformanceKeys = createSelector(
  [selectWindowSize, selectMaxErrorMargin],
  (windowSize, maxErrorMargin) => {
    let performanceKey = 'margin01';
    if (maxErrorMargin === Number.MAX_VALUE) {
      performanceKey = 'nomargin';
    } else if (maxErrorMargin === 0.03) {
      performanceKey = 'margin003';
    }

    return {
      performanceKey: `${performanceKey}${windowSize}`,
      fallbackPerformanceKey: `nomargin${windowSize}`
    };
  }
);

export const selectIsTrailerFitSettingsLoading = createSelector(getSettings, state => state?.processing);
export const selectIsTrailerFitSettingsLoaded = createSelector(getSettings, state => state?.loaded);
