import actionTypes from './actionTypes';

export const logoutRequest = () => ({ type: actionTypes.USER_LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: actionTypes.USER_LOGOUT_SUCCESS });
export const logoutError = error => ({ type: actionTypes.USER_LOGOUT_FAILURE, error });

export const loginRequest = () => ({ type: actionTypes.USER_LOGIN_REQUEST });
export const loginSuccess = user => ({ type: actionTypes.USER_LOGIN_SUCCESS, user });
export const loginFailure = error => ({ type: actionTypes.USER_LOGIN_FAILURE, error });

export const authRetryFailure = error => ({
  type: actionTypes.USER_RETRY_FAILURE,
  error
});

export const authRetryLogin = error => ({
  type: actionTypes.USER_RETRY_LOGIN,
  error
});

export const setIsMetric = isMetric => ({
  type: actionTypes.USER_CHANGE_IS_METRIC,
  isMetric
});
