import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoticeIcon from 'ant-design-pro/lib/NoticeIcon';
import { BellOutlined, QuestionCircleOutlined, ReadOutlined, WarningOutlined } from '@ant-design/icons';
import { notification, Avatar, Modal, message } from 'antd';
import actions from '../../actions';
import { getTimeDate } from '../../helpers/functions';
import {
  getAlarmIconSVG,
  getIsTypeGeofence,
  getNotificationTypeName,
  getTriggers,
  placesVariables
} from '../../helpers/alarms/functions';
import { push } from 'connected-react-router';
import * as strings from '../../helpers/defaultStrings';
import { selectFilteredEntities } from '../../selectors/trailers';
import { isTrackAndTraceEnabled, selectProductFeatures, selectRegion } from '../../selectors/auth';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { getisFFPlacesNotificationsPageEnabled } from '../../app/feature-flags';
import { parseJson } from '../../helpers/parse-json';

const { REACT_APP_NOTIFICATIONS_ALERT_GROUP_TYPE } = ENV_CONFIG;

export const sampleAvatars = [
  'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
  'https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png',
  'https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png',
  'https://gw.alipayobjects.com/zos/rmsportal/GvqBnKhFgObvnSGkDsje.png'
];

export const defaultAvatar = getAlarmIconSVG(null, 'large');

const getAvatar = (type, triggerTypes, message, iconClass = '') => {
  const isGeofence = getIsTypeGeofence({ type });
  if (isGeofence) {
    const geofenceType = message?.geofences_details?.geofences?.[0]?.type;
    return <PlacesIcon type={geofenceType} />;
  } else {
    return getAlarmIconSVG(triggerTypes[type], iconClass) || defaultAvatar;
  }
};

const PlacesIcon = ({ type }) => {
  const iconUrl = placesVariables[type]?.iconUrl;
  return (
    <div className={`${message.alarmIconClass} `}>
      <img src={iconUrl} style={{ width: 26, height: 26 }} />
    </div>
  );
};

class NotificationPanel extends Component {
  constructor(props) {
    super(props);
    const { region, productFeatures } = this.props;
    this.state = {
      alertedMessages: [],
      unread: 0,
      selectedTab: '',
      retrievedDevices: false,
      deviceBranding: null,
      clickIconToNotificationCentre: true,
      confirmDeleteModalVisible: false
    };

    this.triggerTypes = getTriggers(region, productFeatures);
  }

  componentDidMount() {
    const { user, getNotificationsSubscriptions, isNotificationSubProcessing } = this.props;
    const { retrievedDevices } = this.state;

    if (user && user.id && !retrievedDevices && !isNotificationSubProcessing) {
      getNotificationsSubscriptions();
      this.initData();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      devices,
      getDevicesInitialStates,
      notifications,
      initialRawStates,
      receivedInitialNotification,
      readMessages,
      user,
      processingApi,
      updateTrailersFromDevicesUpdate,
      trackAndTraceEnabled
    } = this.props;
    const { retrievedDevices } = this.state;

    if (user && user.id && !retrievedDevices) {
      this.initData();
    }
    if (!user) {
      return;
    }

    if (
      trackAndTraceEnabled &&
      prevProps.devices === null &&
      devices &&
      JSON.stringify(prevProps.devices) !== JSON.stringify(devices)
    ) {
      getDevicesInitialStates(Array.isArray(devices) ? devices.length : 0);
      updateTrailersFromDevicesUpdate(devices);
      this.updateSelectedTrailerStates();
    } else if (prevProps.devices && devices && JSON.stringify(prevProps.devices) !== JSON.stringify(devices)) {
      // we need the trailers store to be updated
      updateTrailersFromDevicesUpdate(devices);
      this.updateSelectedTrailerStates();
    }

    if (
      initialRawStates &&
      initialRawStates.length > 0 &&
      !processingApi &&
      JSON.stringify(prevProps.initialRawStates) !== JSON.stringify(initialRawStates)
    ) {
      receivedInitialNotification(initialRawStates);
    }

    if (
      (notifications && JSON.stringify(prevProps.notifications) !== JSON.stringify(notifications)) ||
      (readMessages && JSON.stringify(prevProps.readMessages) !== JSON.stringify(readMessages))
    ) {
      this.updateNotifications(notifications);
    }
  }

  initData() {
    this.cleanLocalStorage();

    let alertedMessages = parseJson(localStorage.getItem('alertedMessages'));
    alertedMessages = Array.isArray(alertedMessages) ? alertedMessages : [];

    this.setState({
      retrievedDevices: true,
      alertedMessages
    });
  }

  getTPMSString = type => {
    const { formatMessage } = this.props;
    switch (type) {
      case 'tpms':
        return formatMessage(strings.alert.tpmsTitle);
      case 'tireExtremeOverPressure':
        return formatMessage(strings.alert.tpmsEOPTitle);
      case 'tireOverPressure':
        return formatMessage(strings.alert.tpmsOPTitle);
      case 'tireExtremeUnderPressure':
        return formatMessage(strings.alert.tpmsEUPTitle);
      case 'tireUnderPressure':
        return formatMessage(strings.alert.tpmsUPTitle);
      default:
        return formatMessage(strings.alert.tpmsTitle);
    }
  };

  updateNotifications(newNotifications) {
    const { alertedMessages, deviceBranding, selectedTab } = this.state;
    const { readMessages, devices, notifications, formatMessage } = this.props;
    // allow for this to have been triggered by either an updated read/unread message or a deleted message
    newNotifications = newNotifications ? newNotifications : notifications;
    if (!newNotifications) {
      return;
    }

    let title, description, extraInfoValues;
    let unread = 0;
    let alertedMessagesUpdated = alertedMessages;
    let deviceBrandingUpdated = Object.assign({}, deviceBranding);
    let cardTimerMS = 500;
    let numMessages = 0;
    let updateSelectedTab = '';
    let messagesToAlert = {};
    const latestDevices = devices ? Object.assign({}, ...devices.map(device => ({ [device['assetId']]: device }))) : {};
    Object.keys(newNotifications).forEach(type => {
      updateSelectedTab = selectedTab === '' ? (updateSelectedTab === '' ? type : updateSelectedTab) : selectedTab;
      // ToDo: Use the switch when we have proper types decided upon for easy to traslate descriptive content
      // Also need to wait until the MQTT response is same schema as API response

      switch (type) {
        case 'poweron':
          title = '';
          description = formatMessage(strings.alert.powerOnCardText);
          break;
        case 'startstop':
          extraInfoValues = {
            standstill: formatMessage(strings.alert.stoppedMovingCardText),
            moving: formatMessage(strings.alert.startedMovingCardText)
          };
          title = '';
          description = '';
          break;
        case 'ebsAmber':
          title = '';
          description = formatMessage(strings.alert.ebsAmberCardText);
          break;
        case 'absWarningLamp':
          title = '';
          description = formatMessage(strings.alert.absWarningCardText);
          break;
        case 'ebsRed':
          title = '';
          description = formatMessage(strings.alert.ebsRedCardText);
          break;
        case 'harshBrakeEvent':
          title = '';
          description = formatMessage(strings.alert.harshBrakeCardText);
          break;
        case 'tpms':
          title = '';
          description = formatMessage(strings.alert.tpmsCardText);
          break;
        case 'tireExtremeUnderPressure':
          title = '';
          description = formatMessage(strings.alert.tpmsEUPCardText);
          break;
        case 'tireUnderPressure':
          title = '';
          description = formatMessage(strings.alert.tpmsUPCardText);
          break;
        case 'tireExtremeOverPressure':
          title = '';
          description = formatMessage(strings.alert.tpmsEOPCardText);
          break;
        case 'tireOverPressure':
          title = '';
          description = formatMessage(strings.alert.tpmsOPCardText);
          break;
        case 'rss':
          title = '';
          description = formatMessage(strings.alert.rssCardText);
          break;
        case 'abs':
          title = '';
          description = formatMessage(strings.alert.absCardText);
          break;
        case 'withoutISOCable':
          title = '';
          description = formatMessage(strings.alert.isoCardText);
          break;
        case 'geofence':
          title = formatMessage(strings.alert.placeText);
          break;
        default:
          title = '';
          description = '';
          break;
      }

      newNotifications[type] = newNotifications[type].map(message => {
        numMessages++;
        unread += readMessages && readMessages.indexOf(message.key) >= 0 ? 0 : 1;
        if (!deviceBrandingUpdated[message.assetId]) {
          deviceBrandingUpdated[message.assetId] = {
            color: '#' + (((1 << 24) * Math.random()) | 0).toString(16),
            avatar: sampleAvatars[Math.floor(Math.random() * sampleAvatars.length)]
          };
        }
        message.read = readMessages && readMessages.indexOf(message.key) >= 0 ? 1 : 0;
        message.datetime = getTimeDate(message.time * 1000, true, true);
        message.color = deviceBrandingUpdated[message.assetId].color;
        message.avatar = getAvatar(type, this.triggerTypes, message);
        if (getIsTypeGeofence({ type })) {
          message.title = `${
            latestDevices && latestDevices[message.assetId]
              ? latestDevices[message.assetId].defaultDisplayName
              : message.assetId
          } ${formatMessage(
            message?.geofences_details?.duration === 0 ? strings.alert.enterPlaceText : strings.alert.leavePlaceText,
            { VAL_NAME: message?.geofences_details?.geofences?.[0]?.name }
          )}`;
        } else {
          message.description =
            message.extraInfo && extraInfoValues && extraInfoValues[message.extraInfo]
              ? extraInfoValues[message.extraInfo]
              : description;
          message.title =
            title +
            ' ' +
            (latestDevices && latestDevices[message.assetId]
              ? latestDevices[message.assetId].defaultDisplayName
              : message.assetId);
        }
        message.clickClose = true;
        message.extra = (
          <Avatar
            size='small'
            icon={<ReadOutlined />}
            title={message.read ? formatMessage(strings.button.markAsUnread) : formatMessage(strings.button.markAsRead)}
            onClick={event => {
              event.stopPropagation();
              this.markRead(message.key, message.read);
            }}
          />
        );

        if (alertedMessages.indexOf(message.key) === -1) {
          if (!messagesToAlert[type]) {
            messagesToAlert[type] = [];
          }
          messagesToAlert[type].push(message);
        }
        return message;
      });

      newNotifications[type].sort((a, b) => (a.key < b.key ? 1 : -1));
    });
    alertedMessagesUpdated = [].concat.apply(
      alertedMessagesUpdated,
      [].concat(
        ...Object.keys(messagesToAlert).map(type => {
          const messages = messagesToAlert[type];
          let sentBatch = false;
          if (messages.length > REACT_APP_NOTIFICATIONS_ALERT_GROUP_TYPE) {
            setTimeout(() => {
              return this.openNotificationCard(
                getNotificationTypeName(type, formatMessage),
                formatMessage(strings.alert.numNotificationsVal, {
                  value: messages.length
                }),
                'batch_' + type + '_' + Date.now(),
                this.triggerTypes[type] ? (
                  getAlarmIconSVG(this.triggerTypes[type], 'large flex')
                ) : (
                  <QuestionCircleOutlined />
                )
              );
            }, cardTimerMS);
            cardTimerMS += 1;
            sentBatch = true;
          }
          return messages.map(message => {
            if (!sentBatch) {
              setTimeout(
                () =>
                  //This sets the realtime modal notification when alert comes in
                  this.openNotificationCard(
                    type === 'geofence' ? message.title : getNotificationTypeName(type, formatMessage),
                    message.datetime +
                      ': ' +
                      (latestDevices && latestDevices[message.assetId]
                        ? latestDevices[message.assetId].defaultDisplayName
                        : message.assetId),
                    message.key,
                    this.triggerTypes[type] ? (
                      getAvatar(type, this.triggerTypes, message, 'large flex')
                    ) : (
                      <QuestionCircleOutlined />
                    ),
                    message
                  ),
                cardTimerMS
              );
              cardTimerMS += 100;
            }
            return message.key;
          });
        })
      )
    );

    localStorage.setItem('alertedMessages', JSON.stringify(alertedMessagesUpdated));

    // Until we have an API for this we can use localStorage for testing
    this.setState({
      alertedMessages: alertedMessagesUpdated,
      deviceBranding: deviceBrandingUpdated,
      clickIconToNotificationCentre: numMessages === 0,
      selectedTab: updateSelectedTab,
      unread
    });
  }

  goToNotificationCentre = () => {
    const { selectNotificationCentre } = this.props;
    selectNotificationCentre('notifications');
  };

  openNotificationCard = (title, description, messageKey, icon, message, duration = 5) => {
    notification.open({
      message: title,
      description,
      icon,
      duration,
      onClick: () => this.onNotificationCardClick(message, messageKey)
    });
  };

  onNotificationClick = item => {
    const { selectNotification, isFFPlacesNotificationsPageEnabled } = this.props;

    if (item.reason === 'geofence' && isFFPlacesNotificationsPageEnabled) {
      selectNotification('places-alarms', item.key);
    } else {
      selectNotification('notifications', item.key);
    }
  };

  onNotificationCardClick = (message, messageKey) => {
    const { selectNotification, isFFPlacesNotificationsPageEnabled } = this.props;
    if (message.reason === 'geofence' && isFFPlacesNotificationsPageEnabled) {
      selectNotification('places-alarms', messageKey);
    } else {
      selectNotification('notifications', messageKey);
    }
  };

  updateSelectedTrailerStates() {
    const {
      devices,
      selectedTnTTrailer,
      selectedHealthTrailer,
      updateSelectedHealthTrailer,
      updateSelectedTnTTrailer
    } = this.props;
    const latestDevices = devices ? Object.assign({}, ...devices.map(device => ({ [device['assetId']]: device }))) : {};

    if (
      selectedTnTTrailer &&
      latestDevices[selectedTnTTrailer.assetId] &&
      latestDevices[selectedTnTTrailer.assetId].time > selectedTnTTrailer.time
    ) {
      updateSelectedTnTTrailer(latestDevices[selectedTnTTrailer.assetId]);
    }

    if (
      selectedHealthTrailer &&
      latestDevices[selectedHealthTrailer.assetId] &&
      latestDevices[selectedHealthTrailer.assetId].time > selectedHealthTrailer.time
    ) {
      updateSelectedHealthTrailer({
        ...selectedHealthTrailer,
        ...latestDevices[selectedHealthTrailer.assetId]
      });
    }
  }

  cleanLocalStorage() {
    const { updateNotificationsRemoveRead } = this.props;
    // clean all data older than a day
    const yesterday = Math.floor(Date.now() / 1000) - 24 * 60 * 60;

    let alertedMessages = parseJson(localStorage.getItem('alertedMessages'));
    alertedMessages = Array.isArray(alertedMessages) ? alertedMessages : [];
    let i = alertedMessages.length;
    while (i--) {
      if (typeof alertedMessages[i] !== 'string') {
        continue;
      }
      const timestamp = parseInt(alertedMessages[i].substr(0, 10), 10);
      if (timestamp < yesterday) {
        alertedMessages.splice(i, 1);
      }
    }
    localStorage.setItem('alertedMessages', JSON.stringify(alertedMessages));

    let readMessages = parseJson(localStorage.getItem('readMessages'));
    readMessages = Array.isArray(readMessages) ? readMessages : [];
    i = readMessages.length;
    let removeReadMessages = [];
    while (i--) {
      if (typeof readMessages[i] !== 'string') {
        continue;
      }
      const timestamp = parseInt(readMessages[i].substr(0, 10), 10);
      if (timestamp < yesterday) {
        removeReadMessages.push(readMessages[i]);
      }
    }

    if (removeReadMessages.length > 0) {
      updateNotificationsRemoveRead(removeReadMessages);
    }

    let deletedMessages = parseJson(localStorage.getItem('deletedMessages'));
    deletedMessages = Array.isArray(deletedMessages) ? deletedMessages : [];
    i = deletedMessages.length;
    while (i--) {
      if (typeof deletedMessages[i] !== 'string') {
        continue;
      }
      const timestamp = parseInt(deletedMessages[i].substr(0, 10), 10);
      if (timestamp < yesterday) {
        deletedMessages.splice(i, 1);
      }
    }
    localStorage.setItem('deletedMessages', JSON.stringify(deletedMessages));
  }

  onTabChange = tabTitle => {
    // remove the .$ from start of the tabTitle
    this.setState({
      selectedTab: tabTitle.substr(2)
    });
  };

  markRead = (messageKey, read) => {
    const { updateNotificationsRead, updateNotificationsRemoveRead } = this.props;

    if (!read) {
      updateNotificationsRead([messageKey]);
    } else {
      updateNotificationsRemoveRead([messageKey]);
    }
  };

  clickIcon = event => {
    const { clickIconToNotificationCentre } = this.state;
    const { notifications } = this.props;

    let numNotifications = 0;

    for (let type in this.triggerTypes) {
      numNotifications += (notifications && notifications[type] ? notifications[type] : []).length;
    }

    if (clickIconToNotificationCentre && numNotifications === 0) {
      event.stopPropagation();
      this.goToNotificationCentre();
    }
  };

  createTabs = () => {
    const { selectedTab } = this.state;
    const { notificationTypesToListenTo, notifications, formatMessage } = this.props;

    // tabs will be alphabetical, but grouped by those that have notifications before those that dont
    const tabs = [];
    const tabOrder = [];
    let lastType = '';
    let setTabOnNextType = false;

    for (let type in this.triggerTypes) {
      let tpmsNotifications;
      if (type === 'tpms') {
        tpmsNotifications = this.getTPMSNotificaiton(notifications);
      }
      if (
        notificationTypesToListenTo &&
        notificationTypesToListenTo[type] &&
        notificationTypesToListenTo[type]['website'] &&
        [
          'default',
          'tireExtremeUnderPressure',
          'tireUnderPressure',
          'tireExtremeOverPressure',
          'tireOverPressure'
        ].indexOf(type) < 0
      ) {
        lastType = type;
        tabOrder.push({
          type,
          length: (type === 'tpms' && tpmsNotifications
            ? tpmsNotifications
            : notifications && notifications[type]
            ? notifications[type]
            : []
          ).length
        });
        if (setTabOnNextType) {
          this.setState({
            selectedTab: lastType
          });
          setTabOnNextType = false;
        }
      } else if (selectedTab === type) {
        if (lastType !== '') {
          this.setState({
            selectedTab: lastType
          });
        } else {
          setTabOnNextType = true;
        }
      }
    }

    tabOrder.sort((a, b) => b.length - a.length);

    for (let tab of tabOrder) {
      if (tab.type !== 'tpms') {
        const list = (notifications && notifications[tab.type] ? notifications[tab.type] : [])
          // We have to force the format here to make sure we don't end up with an object rendering error in React yielding a blank screen
          .map(d => {
            return {
              ...d,
              datetime: d.datetime && d.datetime._isAMomentObject ? getTimeDate(d.time * 1000, true, true) : d.datetime,
              avatar: getAvatar(tab.type, this.triggerTypes[tab.type], d, 'large flex')
            };
          })
          .sort((a, b) => b.time - a.time);

        tabs.push(
          <NoticeIcon.Tab
            list={list}
            title={getNotificationTypeName(tab.type, formatMessage)}
            name={tab.type}
            emptyText={formatMessage(strings.short.noNotifications)}
            emptyImage='https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg'
            key={tab.type}
            loadedAll={false}
            scrollToLoad={false}
            showClear={true}
          />
        );
      } else {
        const tpmsNotifications = this.getTPMSNotificaiton(notifications);
        const list = (tpmsNotifications ? tpmsNotifications : [])
          // We have to force the format here to make sure we don't end up with an object rendering error in React yielding a blank screen
          .map(d => ({
            ...d,
            tabType: d.reason,
            description: this.getTPMSString(d.reason),
            datetime: d.datetime && d.datetime._isAMomentObject ? getTimeDate(d.time * 1000, true, true) : d.datetime,
            avatar: getAlarmIconSVG(this.triggerTypes[d.reason], 'large flex')
          }));

        tabs.push(
          <NoticeIcon.Tab
            list={list}
            name={tab.type}
            title={getNotificationTypeName(tab.type, formatMessage)}
            emptyText={formatMessage(strings.short.noNotifications)}
            emptyImage='https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg'
            key={tab.type}
            loadedAll={false}
            scrollToLoad={false}
            showClear={true}
          />
        );
      }
    }

    return tabs;
  };

  getTPMSNotificaiton = notifications => {
    if (notifications) {
      let tpmsNotifications = notifications['tpms'] || [];
      if (this.checkTypeWebNotification('tireExtremeUnderPressure') && notifications['tireExtremeUnderPressure']) {
        tpmsNotifications = tpmsNotifications.concat(notifications['tireExtremeUnderPressure']);
      }
      if (this.checkTypeWebNotification('tireUnderPressure') && notifications['tireUnderPressure']) {
        tpmsNotifications = tpmsNotifications.concat(notifications['tireUnderPressure']);
      }
      if (this.checkTypeWebNotification('tireExtremeOverPressure') && notifications['tireExtremeOverPressure']) {
        tpmsNotifications = tpmsNotifications.concat(notifications['tireExtremeOverPressure']);
      }
      if (this.checkTypeWebNotification('tireOverPressure') && notifications['tireOverPressure']) {
        tpmsNotifications = tpmsNotifications.concat(notifications['tireOverPressure']);
      }
      return tpmsNotifications;
    }
  };

  checkTypeWebNotification = type => {
    const { notificationTypesToListenTo } = this.props;
    if (
      notificationTypesToListenTo &&
      notificationTypesToListenTo[type] &&
      notificationTypesToListenTo[type]['website']
    ) {
      return true;
    }
  };

  onClear = () => {
    const { unread, selectedTab } = this.state;
    const { updateNotificationsRemoveRead, updateNotificationsDeleted, notifications, formatMessage } = this.props;

    let deleteMessageIds = [];
    const unreadRemoved = notifications[selectedTab].reduce(function (total, message) {
      deleteMessageIds.push(message.key);
      return total + (message.read ? 0 : 1);
    }, 0);

    const unreadUpdated = unread - unreadRemoved;

    if (deleteMessageIds.length > 0) {
      updateNotificationsDeleted(deleteMessageIds);
      updateNotificationsRemoveRead(deleteMessageIds);
    }

    this.setState({
      unread: unreadUpdated
    });

    this.hideDeleteModal();
    message.success(formatMessage(strings.alert.notificationsDeleted));
  };

  confirmDelete = () => {
    this.setState({
      confirmDeleteModalVisible: true
    });
  };

  hideDeleteModal = () => {
    this.setState({
      confirmDeleteModalVisible: false
    });
  };

  render() {
    const { unread, confirmDeleteModalVisible, selectedTab } = this.state;
    const { notificationTypesToListenTo, formatMessage, productFeatures } = this.props;

    return (
      <div className='header-menu-right__icon-wrap'>
        {productFeatures.showNotification && (
          <NoticeIcon
            className='notice-icon'
            bell={<BellOutlined onClick={this.clickIcon} />}
            count={unread}
            onItemClick={this.onNotificationClick}
            onClear={this.confirmDelete}
            onTabChange={this.onTabChange}
            loadedAll={false}
            locale={{
              emptyText: formatMessage(strings.short.noNotifications),
              clear: formatMessage(strings.button.delete),
              loadedAll: formatMessage(strings.short.loaded),
              loadMore: formatMessage(strings.button.notificationCentre)
            }}
            onLoadMore={this.goToNotificationCentre}
            clearClose={true}
          >
            {notificationTypesToListenTo && this.createTabs()}
          </NoticeIcon>
        )}
        <Modal
          visible={confirmDeleteModalVisible}
          okText={formatMessage(strings.button.confirmDelete)}
          okType='danger'
          cancelText={formatMessage(strings.button.cancel)}
          onCancel={this.hideDeleteModal}
          onOk={this.onClear}
          title={
            <h3 style={{ marginBottom: 0 }}>
              <WarningOutlined style={{ marginRight: 12, color: 'red' }} />
              {formatMessage(strings.phrase.deleteNotificationsVal, {
                value: getNotificationTypeName(selectedTab, formatMessage)
              })}
            </h3>
          }
        >
          <p>
            {formatMessage(strings.description.deleteNotificationsVal, {
              value: getNotificationTypeName(selectedTab, formatMessage)
            })}
          </p>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const region = selectRegion(state);

  return {
    trackAndTraceEnabled: isTrackAndTraceEnabled(state),
    processing: state.devices.processing,
    processingApi: state.devices.processingApi,
    error: state.devices.error,
    devices: selectFilteredEntities(state),
    initialRawStates: state.devices.initialRawStates,
    notifications: state.notifications.messages,
    readMessages: state.notifications.readMessages,
    notificationTypesToListenTo: state.notifications.notificationTypesToListenTo,
    selectedTnTTrailer: state.appState.selectedTnTTrailer,
    selectedHealthTrailer: state.appState.selectedHealthTrailer,
    user: state.auth.user,
    region,
    notificationTypesToListenToDefaultValues: state.notifications.notificationTypesToListenToDefaultValues,
    productFeatures: selectProductFeatures(state),
    isNotificationSubProcessing: state.notifications.isNotificationSubProcessing,
    places: state.places.items,
    isFFPlacesNotificationsPageEnabled: getisFFPlacesNotificationsPageEnabled(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDevicesInitialStates: numDevices => dispatch(actions.devices.fetchInitialDeviceState(numDevices)),
    updateTrailersFromDevicesUpdate: devices => dispatch(actions.trailers.updateTrailersFromDevicesUpdate(devices)),
    updateNotificationsDeleted: notificationIds =>
      dispatch(actions.notifications.updateNotificationsDeleted(notificationIds)),
    updateNotificationsRead: notificationIds =>
      dispatch(actions.notifications.updateNotificationsRead(notificationIds)),
    updateNotificationsRemoveRead: notificationIds =>
      dispatch(actions.notifications.updateNotificationsRemoveRead(notificationIds)),
    receivedInitialNotification: initialRawStates =>
      dispatch(actions.notifications.receivedInitialNotification(initialRawStates)),
    updateSelectedHealthTrailer: trailer => dispatch(actions.appState.updateSelectedHealthTrailer(trailer)),
    updateSelectedTnTTrailer: trailer => dispatch(actions.appState.updateSelectedTnTTrailer(trailer)),
    selectNotification: (mainPath, messageKey) => dispatch(push(`/${mainPath}?notification_key=${messageKey}`)),
    selectNotificationCentre: mainPath => dispatch(push(`/${mainPath}`)),
    updateAlertNotifications: () => dispatch(actions.alerts.updateAlertNotifications),
    getNotificationsSubscriptions: () => dispatch(actions.notifications.getNotificationsSubscriptions())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPanel);
