import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LocaleString from '../../Utils/LocaleString';
import TrailerFCFChart from './TrailerFCFChart';
import * as strings from '../../../helpers/defaultStrings';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import './TrailerFCFChart.scss';
import TrailerFCFFormSettings from './TrailerFCFFormSettings';
import actions from '../../../actions';
import NoDataOrLoading from '../NoDataOrLoading';
import { isUsaCustomer, getDateTime, getTimeFormatLocale, isNullOrUndefined } from '../../../helpers/functions';
import { selectRegion, selectFcfCustomization, selectProductFeatures } from '../../../selectors';

class TrailerFCFChartComponent extends Component {
  chartEl = null;

  state = {
    chart: null,
    isOpen: false,
    chartData: {
      keys: [],
      series: [],
      min: 0,
      max: 0,
      hasData: false
    }
  };

  componentDidMount() {
    // Re-render from scratch on each resize.
    window.addEventListener('resize', () => this.createChart.bind(this)());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataHash, resizeChart, startDate, endDate, pos } = nextProps;

    if (resizeChart !== this.props.resizeChart) {
      this.createChart(this.state.chartData);
    } else if (
      (dataHash && dataHash !== this.props.dataHash) ||
      this.props.startDate !== startDate ||
      this.props.endDate !== endDate ||
      JSON.stringify(pos) !== JSON.stringify(this.props.pos)
    ) {
      this.getChartData(nextProps);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart(newChartData = false) {
    const { chart } = this.state;
    const {
      selectedTrailer,
      height,
      width,
      region,
      intl: { formatMessage },
      productFeatures
    } = this.props;
    const { data: dataHistory } = this.props.trailerHistory.current;
    if (chart) {
      chart.destroy();
    }

    const chartData = newChartData || this.state.chartData;

    if (!chartData.hasData) {
      return;
    }

    const margin = {
      top: (dataHistory.length > 0 ? 35 : 10) + Math.floor(chartData.keys.length / 6) * 18,
      right: 40,
      bottom: 20,
      left: 40
    };
    const elWidth = width || (this.chartEl && this.chartEl.offsetWidth);
    const elHeight = (height || 150) + Math.floor(chartData.keys.length / 6) * 18;
    const isUS = isUsaCustomer(region);

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      assetId: selectedTrailer ? selectedTrailer.assetId : null,
      chartData,
      isUS,
      timeFormatLocale: getTimeFormatLocale(formatMessage, isUS),
      productFeatures
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new TrailerFCFChart(this.chartEl, props)
      },
      function callback() {
        this.state.chart.create({
          ...this.props,
          chartData
        });
        this.state.chart.preventTransitions();
      }
    );
  }

  getChartData(props) {
    const { startDate, endDate, processing } = props;
    const { data: dataHistory } = props.trailerHistory.current;

    let chartData = this.state.chartData;

    if (!processing) {
      const fcfData = {};
      let max = -Infinity;
      let min = +Infinity;

      (dataHistory || [])
        .filter(item => item.ebs && item.ebs.fcf && item.ebs.fcf.length > 0)
        .forEach(dataItem => {
          dataItem.ebs.fcf.forEach(fcfItem => {
            if (
              !isNullOrUndefined(fcfItem?.last_valid?.value) &&
              fcfItem?.last_valid?.timestamp >= startDate &&
              fcfItem?.last_valid?.timestamp <= endDate
            ) {
              if (!fcfData[fcfItem.id]) fcfData[fcfItem.id] = [];

              fcfData[fcfItem.id].push({
                label: fcfItem.id,
                time: fcfItem.last_valid.timestamp * 1000,
                value: fcfItem.last_valid.value
              });

              min = Math.min(min, fcfItem.last_valid.value);
              max = Math.max(max, fcfItem.last_valid.value);
            }
          });
        });

      const keys = Object.keys(fcfData);
      const series = keys.map(key => ({ key, data: fcfData[key] }));

      chartData = {
        keys,
        series,
        min,
        max,
        hasData: series.length > 0
      };

      this.setState({ chartData });
    }

    if (chartData.hasData) {
      this.state.chart
        ? this.state.chart.update({ ...props, chartData }, props.selectedTrailer ? props.selectedTrailer.assetId : null)
        : this.createChart(chartData);
    } else if (!processing && this.state.chart) {
      this.state.chart.destroy();
      this.setState({
        chart: null
      });
    }

    return chartData;
  }

  getCSVData() {
    const { startDate, endDate, fcfCustomizationSettings } = this.props;
    const { data: dataHistory } = this.props.trailerHistory.current;

    /** @todo split headers and data into different arrays https://www.npmjs.com/package/react-csv  */
    const csvData = [
      [
        'id',
        'label',
        'last_valid_timestamp',
        'last_valid_value',
        'min_timestamp',
        'min_value',
        'max_timestamp',
        'max_value'
      ]
    ];
    (dataHistory || [])
      .filter(item => item.ebs && item.ebs.fcf && item.ebs.fcf.length > 0)
      .forEach(dataItem => {
        dataItem.ebs.fcf.forEach(fcfItem => {
          if (
            !isNullOrUndefined(fcfItem?.last_valid.value) &&
            fcfItem?.last_valid?.timestamp >= startDate &&
            fcfItem?.last_valid?.timestamp <= endDate
          ) {
            csvData.push([
              fcfItem.id,
              fcfCustomizationSettings.options[fcfItem.id] || fcfItem.id,
              getDateTime(fcfItem.last_valid.timestamp * 1000, true),
              fcfItem.last_valid.value,
              getDateTime(fcfItem.min.timestamp * 1000, true),
              fcfItem.min.value,
              getDateTime(fcfItem.max.timestamp * 1000, true),
              fcfItem.max.value
            ]);
          }
        });
      });

    return csvData;
  }

  handleOk(values) {
    const { setFCFSettings } = this.props;
    setFCFSettings(values);
    this.setState({ isOpen: false });
  }

  handleCancel() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      intl: { formatMessage },
      fcfCustomizationSettings
    } = this.props;
    const { isOpen, chartData } = this.state;

    return (
      <div className='livemap-fcf-wrapper'>
        {this.props.showTitle && (
          <div className='livemap-fcf-wrapper__header'>
            <h2>{fcfCustomizationSettings.title || <LocaleString type='sections' id={this.props.view} />}</h2>
            {chartData.keys.length > 0 && (
              <Button type='link' onClick={() => this.setState({ isOpen: true })}>
                <LocaleString type='short' id='editLabels' />
              </Button>
            )}
          </div>
        )}
        {chartData.keys.length > 0 && (
          <CSVLink
            data={this.getCSVData()}
            filename='wabco-livemap-fcf.csv'
            icon='download'
            className='ant-btn ant-btn-sm livemap-fcf-export-button'
          >
            <DownloadOutlined /> {formatMessage(strings.short.exportCSV)}
          </CSVLink>
        )}
        <div ref={n => (this.chartEl = n)}>
          {!this.state.chartData.hasData && (
            <NoDataOrLoading
              processing={this.props.processing}
              setPickerOpen={this.props.setPickerOpen}
              onChangeEndDateTime={this.props.onChangeEndDateTime}
            />
          )}
        </div>

        {chartData.keys.length > 0 && isOpen && (
          <TrailerFCFFormSettings
            isOpen={isOpen}
            chartData={chartData}
            handleOk={this.handleOk.bind(this)}
            handleCancel={this.handleCancel.bind(this)}
          />
        )}
      </div>
    );
  }
}

TrailerFCFChartComponent.propTypes = {};

function mapStateToProps(state) {
  const region = selectRegion(state);

  return {
    region,
    fcfCustomizationSettings: selectFcfCustomization(state),
    isMetric: state.auth.isMetric,
    productFeatures: selectProductFeatures(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFCFSettings: value => dispatch(actions.settings.saveSetting('fcfCustomization', value))
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerFCFChartComponent));
