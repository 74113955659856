import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { List, Row, Button, Col, Select, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import 'react-reflex/styles.css';
import '../../Notifications/Notifications.scss';
import * as strings from '../../../helpers/defaultStrings';
import AlertTimeline from '../../charts/AlertTimeline/AlertTimelineComponent';
import { convert, unitType } from '../../../helpers/unitConverter';
import { getGeofenceDurationString, getMediumDateShortTime } from '../../../helpers/functions';
import { getIsTypeGeofence, placesVariables } from '../../../helpers/alarms/functions';
import { notifKeyToAlertID, shouldScroll, disabledDate } from './helpers/functions';
import { useLocation } from 'react-router';
import { getPlacesMapBoxStaticImage } from './helpers/getPlacesMapBoxStaticImage';
import { selectRegion, selectIsMetric, selectProcessingAlerts } from '../../../selectors';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PlacesAlarmsHistory = ({
  paginationConfig,
  dropDownTrailers,
  selectedTypes,
  filterValue,
  gotoAlert,
  isMobile,
  formatMessage,
  onFilterChange,
  toggleSelectedType,
  onDatePickerChange,
  selectEvent,
  resetFilters,
  getExportCSVData,
  getHeaders,
  dates,
  date,
  exportCSV,
  filteredAlerts,
  goToSubSettings,
  lastDates
}) => {
  const processingAlerts = useSelector(selectProcessingAlerts);
  const region = useSelector(selectRegion);
  const isMetric = useSelector(selectIsMetric);
  const [hasDoneInitialScroll, setHasDoneInitialScroll] = useState(false);
  const [idScrolledTo, setIdScrolledTo] = useState(false);
  const query = new URLSearchParams(useLocation()?.search);

  const notificationKey = query.get('notification_key');

  useLayoutEffect(() => {
    if (notificationKey) {
      const id = notifKeyToAlertID(notificationKey);
      const element = document.getElementById(id);
      if (element && shouldScroll(hasDoneInitialScroll, idScrolledTo, id)) {
        gotoAlert({ id });
        setHasDoneInitialScroll(true);
        setIdScrolledTo(id);
      }
    }
  }, [notificationKey, filteredAlerts, processingAlerts]);

  return (
    <div id='placesAlarmsHistory'>
      <Row type='flex' justify='end' className='large-margin-bottom'>
        <Button type='link' onClick={goToSubSettings}>
          {formatMessage(strings.button.subscriptionSettings)}
        </Button>
        <Button icon={<ReloadOutlined />} onClick={resetFilters} className='medium-margin-right'>
          {formatMessage(strings.short.reload)}
        </Button>
        <CSVLink
          data={getExportCSVData(isMetric)}
          headers={getHeaders(isMetric)}
          filename={`alarms_export_${date}.csv`}
        >
          {}{' '}
          <Button icon={<DownloadOutlined />} onClick={exportCSV}>
            {formatMessage(strings.short.exportCSV)}
          </Button>
        </CSVLink>
      </Row>
      <Row type='flex' justify='space-between' align='bottom' className='large-margin-bottom alert-timeline__row'>
        <Col span={isMobile ? null : 16} className='alert-timeline__col alert-timeline__col--chart'>
          <AlertTimeline
            className='full-width'
            height={100}
            alerts={filteredAlerts}
            region={region}
            dates={dates[1] ? dates : lastDates}
            onClick={alert => gotoAlert(alert)}
            dataHash={`${filteredAlerts.length}${dates}${filterValue}${selectedTypes}`}
            placesTimeline={true}
          />
        </Col>
        <Col span={isMobile ? null : 8} className='alert-timeline__col alert-timeline__col--filters'>
          <div className='notification-type-selector'>
            <h3 className='title'>{formatMessage(strings.short.alertCategories)}</h3>
            <Row type='flex' gutter={4}>
              {Object.keys(placesVariables).map(place => {
                const isSelected = selectedTypes.includes(place);
                return (
                  <Col
                    key={place}
                    onClick={() => toggleSelectedType(place)}
                    className={'item'}
                    style={{
                      backgroundColor: isSelected ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <img src={placesVariables[place].iconUrl} style={{ width: 32, height: 32 }} />
                    <div className='type-name'>{formatMessage(strings.geofence[place])}</div>
                    {isSelected && <CheckOutlined className='right-aligned-icon' />}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
      <Row type='flex' justify='center' gutter={16} className='large-margin-top' id='notification-search-element'>
        <Col span={isMobile ? 10 : null}>
          <Select
            showSearch
            allowClear
            filterOption
            showArrow={false}
            placeholder={formatMessage(strings.phrase.filterByTrailer)}
            onChange={onFilterChange}
            className='notification-trailer-selector'
            value={filterValue}
          >
            {dropDownTrailers.map((trailer, index) => (
              <Option key={`${trailer}-${index}`} value={trailer}>
                {trailer}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={isMobile ? 14 : null}>
          <RangePicker
            disabledDate={current => disabledDate(current, dates)}
            value={dates}
            format='YYYY-MM-DD'
            onCalendarChange={onDatePickerChange}
            disabled={processingAlerts}
          />
        </Col>
      </Row>
      <List
        itemLayout='vertical'
        size='large'
        loading={processingAlerts}
        //dataSource={notifications}
        pagination={paginationConfig}
        dataSource={filteredAlerts}
        rowKey='id'
        className='notification-centre__notification-list'
        renderItem={item => {
          const isGeofence = getIsTypeGeofence(item);
          const iconUrl = placesVariables[item.geofences_details?.geofences[0]?.type]?.iconUrl;
          const listItemReadClass = item.read === 1 ? 'list-item-read' : '';
          const mapBoxImageUrl = getPlacesMapBoxStaticImage(item);
          return (
            <List.Item
              className='padding20'
              id={item.id}
              onClick={() => selectEvent(item)}
              extra={
                (!item.longitude && !item.latitude) || !mapBoxImageUrl ? (
                  <span className='no-gps-text'>{formatMessage(strings.phrase.noGpsData)}</span>
                ) : (
                  <img
                    onClick={() => selectEvent(item)}
                    width={272}
                    style={{
                      opacity: item.read === 1 ? '0.15' : '1',
                      cursor: 'pointer'
                    }}
                    alt='logo'
                    src={mapBoxImageUrl}
                  />
                )
              }
            >
              <List.Item.Meta
                avatar={
                  <div className={`${item.alarmIconClass} ${item.read === 1 ? 'list-avatar-read' : ''}`}>
                    <img src={iconUrl} style={{ width: 26, height: 26 }} />
                  </div>
                }
                title={<div className={listItemReadClass}>{item.title}</div>}
                description={<span className={listItemReadClass}>{item.description}</span>}
              />
              <span className={listItemReadClass}>
                <div>
                  <span>
                    {isGeofence
                      ? formatMessage(strings.alert.placeVal, {
                          VAL_NAME: item.placeData?.name
                        })
                      : formatMessage(strings.alert.dateVal, {
                          value: getMediumDateShortTime(item.datetime)
                        })}
                  </span>
                  <br />
                  <span>
                    {isGeofence
                      ? formatMessage(item.isEnteringAlert ? strings.alert.enterVal : strings.alert.exitVal, {
                          VAL_DATE: item.time ? getMediumDateShortTime(item.time * 1000) : ''
                        })
                      : item.speed == null
                      ? formatMessage(strings.short.noSpeedVal)
                      : formatMessage(isMetric.distance ? strings.alert.speedVal : strings.alert.speedMileVal, {
                          value: convert(isMetric, unitType.speed, item.speed)
                        })}
                  </span>
                  <br />
                  <span>
                    {isGeofence
                      ? item.isEnteringAlert
                        ? ''
                        : formatMessage(strings.alert.durationVal, {
                            VAL_TIME: formatMessage(...getGeofenceDurationString(item))
                          })
                      : item.alt == null
                      ? formatMessage(strings.short.noAltitudeVal)
                      : formatMessage(isMetric.distance ? strings.alert.altitudeVal : strings.alert.altitudeFeetVal, {
                          value: convert(isMetric, unitType.meterFeet, item.alt)
                        })}
                  </span>
                  {item.wheelInfo}
                </div>
              </span>
            </List.Item>
          );
        }}
        locale={{ emptyText: formatMessage(strings.short.noData) }}
      />
    </div>
  );
};

export default injectIntl(PlacesAlarmsHistory);
