import PubSub from 'pubsub-js';

import { MEMORY_STORAGE_MESSAGES, MESSAGE_TYPES, WORKER_NAME } from '../../workers/constants';
import { Logger } from '../helpers/logger';

export const subscribe = (topic, callback) => {
  const unsubscribe = () => {
    PubSub.unsubscribe(token);
  };
  const token = PubSub.subscribe(topic, callback);

  return unsubscribe;
};

export const handleMemoryStorageSync = ({ type, payload }) => {
  const prefix = `${WORKER_NAME}:`;
  if (type === MESSAGE_TYPES.MEMORY_STORAGE_SET_ITEM) {
    localStorage.setItem(`${prefix}${payload.key}`, JSON.stringify(payload.value));
  } else if (type === MESSAGE_TYPES.MEMORY_STORAGE_REMOVE_ITEM) {
    localStorage.removeItem(`${prefix}${payload.key}`);
  } else if (type === MESSAGE_TYPES.MEMORY_STORAGE_CLEAR) {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    });
  }
};

export const onMessageHandler = e => {
  const {
    type,
    metadata: { topic }
  } = e.data;

  Logger.debug('host:onmessage', { type, data: e.data });
  if (MEMORY_STORAGE_MESSAGES.includes(type)) {
    handleMemoryStorageSync(e.data);
  } else {
    PubSub.publish(topic, e.data);
  }
};

export const sendMessageHandler =
  worker =>
  ({ type, payload, metadata }) => {
    if (!type) {
      throw new Error('missing message type');
    }
    worker.postMessage({ type, payload, metadata });
  };
