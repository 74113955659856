import { DISPLAY_TRAILERS } from '../../helpers/constants';
import { selectEntities } from '../../selectors/trailers';
import { selectDisplayTrailers } from '../../selectors/settings';
import { trailersSlice } from '../../slices/trailers';

export const filterTrailers = () => {
  return (dispatch, getState) => {
    const state = getState();
    const unfilteredTrailers = selectEntities(state);
    if (!unfilteredTrailers.length) {
      // nothing to do without trailers.
      return;
    }

    const displayTrailers = selectDisplayTrailers(state);
    const isShowInactive = displayTrailers.includes(DISPLAY_TRAILERS.INACTIVE_TRAILERS);
    const filteredTrailers = (
      isShowInactive
        ? unfilteredTrailers
        : unfilteredTrailers.filter(trailer => {
            return trailer.active !== false;
          })
    ).sort((a, b) => b.lastPosition - a.lastPosition);

    const filteredTrailerEntitiesIds = filteredTrailers.map(t => t.assetId);
    dispatch(trailersSlice.actions.updateFilteredEntitiesIds(filteredTrailerEntitiesIds));
  };
};
