import {
  formatInitialDeviceStates,
  formatNewDeviceState,
  formatMapMatching
} from './ActionResources/transformationFunctions';
import actionTypes from './actionTypes';
import store from '../app/configureStore.js';
import {
  shouldFilterOutTrailer,
  filterByDeviceAssetIds,
  filterOutInactiveTrailersIfNeeded
} from '../helpers/functions';
import { selectEntities, selectUnfilteredDevices, selectDisplayTrailers } from '../selectors';
import { ENV_CONFIG } from '../app/helpers/env-configs';
import { trailersSlice } from '../slices/trailers';
import { API_NAMES, DISPLAY_TRAILERS } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAP_MATCHING_MAX_DATA_REQUEST } = ENV_CONFIG;

// <points> gets replaced by the devices points with lat and lng separated by comma and each point separated by semi colon
const MAP_MATCHING_URL =
  'https://api.mapbox.com/matching/v5/mapbox/driving/<points>?timestamps=<timestamps>&access_token=' +
  REACT_APP_MAPBOX_ACCESS_TOKEN +
  '&tidy=true&geometries=polyline6&overview=full&annotations=speed,distance,duration';

function fetchInitialDevicesStatesFailure(error) {
  return {
    type: actionTypes.FETCH_INITIAL_DEVICES_STATES_FAILURE,
    error
  };
}

export function fetchInitialDevicesStatesSuccess(devicesStates, final) {
  const state = store.getState().devices;
  const { initialRawStates, processingApi, states, mapRoutes, devices } = formatInitialDeviceStates(
    state,
    devicesStates,
    final
  );
  return {
    type: actionTypes.FETCH_INITIAL_DEVICES_STATES_SUCCESS,
    initialRawStates,
    processingApi,
    states,
    mapRoutes,
    devices
  };
}

function fetchInitialDevicesStatesRequest() {
  return {
    type: actionTypes.FETCH_INITIAL_DEVICES_STATES_REQUEST
  };
}

function filterTrailers() {
  const state = store.getState();
  const unfilteredDevices = selectUnfilteredDevices(state);
  const displayTrailers = selectDisplayTrailers(state);
  const {
    trailerDetails: { trailers },
    ebpmsTrailers: { allItems },
    alerts: { allItems: alerts }
  } = state;

  if (unfilteredDevices == null) {
    return { type: actionTypes.NO_TRAILERS_TO_FILTER_DEVICES };
  }
  const isShowInactive = displayTrailers.includes(DISPLAY_TRAILERS.INACTIVE_TRAILERS);

  const devices = unfilteredDevices.filter(
    device =>
      !shouldFilterOutTrailer({
        device,
        isShowInactive
      })
  );
  const deviceAssetIds = devices.map(device => device.assetId);
  const ebpmsItems = filterOutInactiveTrailersIfNeeded({
    trailers: allItems,
    displayTrailers
  });
  trailers.items = filterByDeviceAssetIds(trailers.allItems, deviceAssetIds);
  const alertItems = filterByDeviceAssetIds(alerts, deviceAssetIds);

  return {
    type: actionTypes.FILTER_DEVICES,
    devices,
    deviceAssetIds,
    trailers,
    ebpmsItems,
    alertItems
  };
}

function callAPI(dispatch, numDevices, lastEvaluatedKey) {
  // depending on the number of devices we have we request different amount of historical data.
  // 120 mins for <=100 devices, 12 mins for 1000, 6 mins for 2000 devices, 5 mins is minimum
  const numSecs = Math.round(Math.max((3600 * 2) / Math.max(numDevices / 100, 1), 300));

  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null,
    time: Math.round(new Date().getTime() / 1000) - numSecs
  };
  return worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailers/fmshistory', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(dispatch, numDevices, res.LastEvaluatedKey);
      }
      return dispatch(fetchInitialDevicesStatesSuccess(res.Items, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(fetchInitialDevicesStatesFailure(err)));
}

export function fetchInitialDeviceState(numDevices) {
  return (dispatch, getState) => {
    const store = getState();
    const { initialRawStates, processingApi } = store.devices;
    if ((!initialRawStates || initialRawStates.length === 0) && !processingApi) {
      dispatch(fetchInitialDevicesStatesRequest());
      return callAPI(dispatch, numDevices);
    }
  };
}

export function receiveNewDeviceState(newDeviceStates, test) {
  return (dispatch, getState) => {
    let state;
    let now;
    let trailers;
    if (!test) {
      now = Math.round(Date.now() / 1000);
      state = getState().devices;
      trailers = selectEntities(getState());
    } else {
      now = test.processingTime;
      state = test.state;
      trailers = test.state.devices;
    }
    const { buffer, devices, lastMQTTProcessedTime, states, mapRoutes, animatedHeadRoutes, history } =
      formatNewDeviceState(now, state, newDeviceStates, trailers);
    dispatch(trailersSlice.actions.updateTrailers(devices));

    return {
      type: actionTypes.RECEIVE_NEW_DEVICE_STATE,
      buffer,
      devices,
      lastMQTTProcessedTime,
      states,
      mapRoutes,
      animatedHeadRoutes,
      history
    };
  };
}

function requestMapMatching(assetId, timestamp, firstEventTime, points, segments, timestamps, callBack) {
  return dispatch => {
    dispatch(mapMatchingRequest());

    return fetch(
      MAP_MATCHING_URL.replace('<points>', points.slice(-REACT_APP_MAP_MATCHING_MAX_DATA_REQUEST).join(';')).replace(
        '<timestamps>',
        timestamps.slice(-REACT_APP_MAP_MATCHING_MAX_DATA_REQUEST).join(';')
      )
    )
      .then(result => result.json())
      .then(matchedPoints => {
        dispatch(
          requestMapMatchingSuccess(
            assetId,
            timestamp,
            firstEventTime,
            matchedPoints,
            points,
            segments,
            timestamps,
            callBack
          )
        );
      })
      .catch(err => dispatch(requestMapMatchingFailure(err.message)).then(callBack));
  };
}

function mapMatchingRequest() {
  return {
    type: actionTypes.FETCH_MAP_MATCHING_ROUTE_REQUEST
  };
}

function requestMapMatchingSuccess(assetId, timestamp, firstEventTime, values, points, segments, timestamps, callBack) {
  const state = store.getState().devices;
  const payload = formatMapMatching(
    state,
    assetId,
    timestamp,
    firstEventTime,
    values,
    points,
    segments,
    timestamps,
    callBack
  );

  return {
    type: actionTypes.FETCH_MAP_MATCHING_ROUTE_SUCCESS,
    ...payload
  };
}

function requestMapMatchingFailure(error, callBack) {
  return {
    type: actionTypes.FETCH_MAP_MATCHING_ROUTE_FAILURE,
    error,
    callBack
  };
}

export function setLivemapSelectedDevices(devices) {
  return {
    type: actionTypes.SELECT_DEVICES_LIVEMAP,
    devices
  };
}

export default {
  fetchInitialDeviceState,
  receiveNewDeviceState,
  requestMapMatching,
  setLivemapSelectedDevices,
  filterTrailers
};
