import React from 'react';
import { useSelector } from 'react-redux';
import { PageHeader, Tabs } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import 'react-reflex/styles.css';
import './Notifications.scss';
import * as strings from '../../helpers/defaultStrings';
import paths from '../../app/paths';
import DeviceAlarms from './SubComponents/DeviceAlarms';
import ReporEmails from './SubComponents/ReporEmails';
import EmailCard from './SubComponents/EmailCard';
import { getNotificationTypes } from './SubComponents/notificationTypesAvailable';
import { selectRegion } from '../../selectors';
import { isIntegration, getIsShowPlaces } from '../../helpers/functions';
import { getisFFPlacesNotificationsPageEnabled } from '../../app/feature-flags';

const { TabPane } = Tabs;
const PAGE_TABS = {
  deviceAlarms: 'device-alarms',
  reportEmails: 'report-emails'
};

const SubscriptionSettings = ({ history, location }) => {
  const formatMessage = useIntl().formatMessage;
  const region = useSelector(selectRegion);
  const showPlacesNotifications = useSelector(getisFFPlacesNotificationsPageEnabled);
  const onChangeTab = tabKey => {
    history.replace(`${paths.subscriptionSettings}?tab=${tabKey}`);
  };

  const getActiveTab = defaultTab => {
    const urlQuery = location?.search;
    const query = new URLSearchParams(urlQuery);
    const queryTab = query.get('tab');
    const activeTab = queryTab ? queryTab : defaultTab;
    return activeTab;
  };

  const activeTab = getActiveTab(PAGE_TABS.deviceAlarms);

  const notificationsAvailable = getNotificationTypes(region);
  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={formatMessage(strings.button.subscriptionSettings)}
        subTitle={<div>{formatMessage(strings.description.subscriptionSettings)}</div>}
      />

      <div>
        <EmailCard />
        <Tabs onChange={onChangeTab} type='card' className='sub-settings-tabs' activeKey={activeTab}>
          <TabPane tab={formatMessage(strings.settings.deviceAlarms)} key={PAGE_TABS.deviceAlarms}>
            <DeviceAlarms notificationRows={notificationsAvailable.device} />
          </TabPane>
          {showPlacesNotifications && getIsShowPlaces() && (
            <TabPane tab={formatMessage(strings.settings.placesAlarms)} key={PAGE_TABS.placesAlarms}>
              <DeviceAlarms notificationRows={notificationsAvailable.geofence} isPlacesNotifications />
            </TabPane>
          )}
          {isIntegration && ( // Temporarily hiding tab on production. TODO: Remove check once Backend ready
            <TabPane tab={formatMessage(strings.settings.reportEmails)} key={PAGE_TABS.reportEmails}>
              <ReporEmails notificationRows={notificationsAvailable.report} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default injectIntl(SubscriptionSettings);
