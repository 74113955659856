import * as React from 'react';
import FlagIconFactory from 'react-flag-icon-css';

// Please only use `FlagIconFactory` one time in your application, there is no
// need to use it multiple times (it would slow down your app). You may place the
// line below in a `FlagIcon.js` file in your 'components' directory, then
// write `export default FlagIcon` as shown below and import it elsewhere in your app.
const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const languages = [
  { lang: 'cs', name: 'Čeština', flagcode: 'cz' },
  { lang: 'cn', name: '中文', flagcode: 'cn' },
  { lang: 'da', name: 'Dansk', flagcode: 'dk' },
  { lang: 'bg', name: 'български', flagcode: 'bg' },
  { lang: 'de', name: 'Deutsch', flagcode: 'de' },
  { lang: 'en', name: 'English', flagcode: 'gb' },
  { lang: 'en-us', name: 'English US', flagcode: 'us' },
  { lang: 'es', name: 'Español', flagcode: 'es' },
  { lang: 'fr', name: 'Français', flagcode: 'fr' },
  { lang: 'it', name: 'Italiano', flagcode: 'it' },
  { lang: 'jp', name: '日本語', flagcode: 'jp' },
  { lang: 'nl', name: 'Nederlands', flagcode: 'nl' },
  { lang: 'no', name: 'Norsk', flagcode: 'no' },
  { lang: 'pl', name: 'Polski', flagcode: 'pl' },
  { lang: 'pt', name: 'Português', flagcode: 'pt' },
  { lang: 'br', name: 'Português (Brasil)', flagcode: 'br' },
  { lang: 'ru', name: 'Pусский', flagcode: 'ru' },
  { lang: 'ro', name: 'Român', flagcode: 'ro' },
  { lang: 'sk', name: 'Slovenský', flagcode: 'sk' },
  { lang: 'sl', name: 'Slovenščina', flagcode: 'si' },
  { lang: 'sv', name: 'Svenska', flagcode: 'se' },
  { lang: 'tr', name: 'Türkçe', flagcode: 'tr' }
];
export { FlagIcon, languages };
