import actionTypes from '../actions/actionTypes';

export const initialState = {
  items: [],
  loaded: false,
  loading: false,
  creating: false,
  deleting: false,
  error: false
};

export default function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PLACES_REQUEST: {
      state.loading = true;
      state.loaded = false;
      state.items = [];
      return state;
    }
    case actionTypes.GET_PLACES_SUCCESS: {
      state.loading = false;
      state.loaded = true;
      state.items = action.data.map((zone, index) => ({ ...zone, index }));
      return state;
    }
    case actionTypes.GET_PLACES_FAILURE: {
      state.loading = false;
      state.loaded = true;
      state.error = action.error;
      return state;
    }
    case actionTypes.CREATE_PLACE_REQUEST: {
      state.creating = true;
      return state;
    }
    case actionTypes.CREATE_PLACE_EDIT_SUCCESS: {
      state.creating = false;
      state.items = state.items.map(item => (item.placeId === action.data.placeId ? action.data : item));
      return state;
    }
    case actionTypes.CREATE_PLACE_SUCCESS: {
      state.creating = false;
      state.items = [action.data, ...state.items];
      return state;
    }
    case actionTypes.DELETE_PLACE_REQUEST: {
      state.deleting = true;
      return state;
    }
    case actionTypes.DELETE_PLACE_SUCCESS: {
      state.deleting = false;
      state.items = state.items.filter(item => item.placeId !== action.data.placeId);
      return state;
    }
    default:
      return state;
  }
}
