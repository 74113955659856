import React, { useRef, useState } from 'react';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { SortBtn } from './SortBtn';
import './SearchHeader.scss';
import { sendCustomEvent } from '../../../app/helpers/new-relic';
import { selectUser } from '../../../selectors/auth';
import { EVENT_NAMES } from '../../../analytics/constants';

let SEARCH_DEBOUNCE_TIMER_ID = null;

export const SearchHeader = ({ colKey, colWidth, children, removeSearch, search, sorter, sortedUp, sortedDown }) => {
  const user = useSelector(selectUser);
  const pushEvent = name => sendCustomEvent(name, { identity: user.identity });
  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchInput = useRef(null);
  const colName = colKey?.toUpperCase() ?? EVENT_NAMES.UNKNOWN;

  const pushSearchEvent = (clearSearch = false) => {
    const debounceTime = 2000; // in milliseconds

    if (clearSearch) {
      pushEvent(`${colName}_${EVENT_NAMES.CLICKED_X}`);
      return;
    }

    if (SEARCH_DEBOUNCE_TIMER_ID) {
      clearTimeout(SEARCH_DEBOUNCE_TIMER_ID);
    }

    // debounce:
    SEARCH_DEBOUNCE_TIMER_ID = setTimeout(() => {
      pushEvent(`${colName}_${EVENT_NAMES.QUERY_TYPED}`);
      SEARCH_DEBOUNCE_TIMER_ID = null;
    }, debounceTime);
  };

  const activateSearch = () => {
    setSearchMode(true);
    setTimeout(() => searchInput.current.select(), 100);
  };

  const clearSearch = () => {
    setSearchMode(false);
    setSearchValue('');
    removeSearch();
    pushSearchEvent(true);
  };

  const onSearch = value => {
    setSearchValue(value);
    search(value);
    pushSearchEvent();
  };

  const isOpen = searchMode ? 'open' : '';
  const inputCssClasses = `th-search-container ${isOpen}`;
  const containerCssClasses = `th-search ${isOpen}`;
  const innerIconCssClasses = `th-search-icon-in ${isOpen}`;

  return (
    <div className={containerCssClasses} style={{ width: colWidth }}>
      {!isOpen && (
        <div className='th-search-btn-container'>
          {sorter && <SortBtn colKey={colKey} onClick={sorter} sortedUp={sortedUp} sortedDown={sortedDown} />}
          <SearchOutlined onClick={activateSearch} className='th-search-icon' />
        </div>
      )}
      <div className='th-title'>{children}</div>
      <div className={inputCssClasses}>
        <input
          className={'th-search-input'}
          ref={searchInput}
          onChange={e => onSearch(e.target.value)}
          value={searchValue}
        />
        <label>
          <SearchOutlined className={innerIconCssClasses} />
          <CloseCircleFilled onClick={clearSearch} className='th-clear-icon' />
        </label>
      </div>
    </div>
  );
};
