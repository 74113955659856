import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, formatMessage }) => {
  if (rowData.trailer) {
    return <p>{rowData.trailer}</p>;
  }
  return <p>{formatMessage(strings.short.noTrailer)}</p>;
};

const TrailerColumn = ({ trailers, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.trailer)}>{formatMessage(strings.short.trailer)}</Tooltip>,
    key: 'trailer',
    dataIndex: 'trailer',
    searchKey: formatMessage(strings.short.trailer),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    search: searchString =>
      trailers.filter('trailerSearch', t => t.trailer.toString().toLowerCase().includes(searchString.toLowerCase())),
    removeSearch: () => trailers.removeFilter('trailerSearch'),
    width: 200
  };
};

const TrailerCSVHeader = formatMessage => formatMessage(strings.short.trailer);
const TrailerCSVData = trailer => trailer.trailer;
const TrailerCSVColumn = {
  header: TrailerCSVHeader,
  data: TrailerCSVData
};

export default { table: TrailerColumn, csv: TrailerCSVColumn };
