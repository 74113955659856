import { worker } from '../app/web-workers/aws-web-worker';
import actionTypes from './actionTypes';
import { API_NAMES } from '../helpers/constants';

function getWorkshopServicePartnersStart() {
  return {
    type: actionTypes.GET_WSP_REQUEST
  };
}

function getWorkshopServicePartnersFailure(error) {
  return {
    type: actionTypes.GET_WSP_FAILURE,
    error
  };
}

function getWorkshopServicePartnersSuccess(wspList) {
  return {
    type: actionTypes.GET_WSP_SUCCESS,
    items: wspList
  };
}

function callAPI(dispatch) {
  worker
    .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'workshops' })

    .then(res => {
      return dispatch(getWorkshopServicePartnersSuccess(res));
    })
    .catch(error => dispatch(getWorkshopServicePartnersFailure(error)));
}

function getWorkshopServicePartners() {
  return (dispatch, getState) => {
    const workshopServicePartnersState = getState().workshopServicePartners;
    const { loading, loaded } = workshopServicePartnersState;
    if (!(loading || loaded)) {
      dispatch(getWorkshopServicePartnersStart());
      callAPI(dispatch);
    }
  };
}

export default {
  getWorkshopServicePartners
};
