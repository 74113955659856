import React, { memo } from 'react';
import './OdyTag.scss';

const cssClassMap = {
  info: 'tag-info',
  success: 'tag-success',
  warning: 'tag-warning',
  error: 'tag-error',
  neutral: 'tag-neutral'
};

const OdyTag = ({ onClick, children, type }) => {
  const cssClass = type in cssClassMap ? cssClassMap[type] : '';
  return (
    <span onClick={onClick} className={`ody-tag ${cssClass}`}>
      {children}
    </span>
  );
};

export default memo(OdyTag);
