import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import numeral from 'numeral';
import { getConversionString, unitType } from '../../helpers/unitConverter';
import { isUsaCustomer } from '../../helpers/functions';
import * as strings from '../../helpers/defaultStrings';
import { selectRegion } from '../../selectors';

const LoadingEventDescription = ({ loadingEvent, intl: { formatMessage } }) => {
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const isUS = isUsaCustomer(region);

  return (
    <div className='trailer-history-description'>
      <h3>
        <strong>{formatMessage(strings.short.trip)}</strong>
      </h3>
      <Row>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.date)}</strong>:{' '}
            {loadingEvent.timestamp
              ? loadingEvent.timestamp.format('DD/MM/YYYY')
              : formatMessage(strings.short.unknown)}
          </div>
          <div>
            <strong>{formatMessage(strings.short.duration)}</strong>:{' '}
            {numeral(loadingEvent.duration * 60).format('00:00:00')}
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.mileageAtStart)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, loadingEvent.mileage, unitType.distance))}
          </div>
          <div>
            <strong>{formatMessage(strings.short.distance)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, loadingEvent.distance, unitType.distance))}
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.avgAxleLoad)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, loadingEvent.avgAxleLoad, unitType.ton, isUS))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

LoadingEventDescription.propTypes = {};

export default injectIntl(LoadingEventDescription);
