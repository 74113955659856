import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';
import * as strings from '../../../helpers/defaultStrings';

const Text = Typography.Text;

const EmailCard = () => {
  const emails = useSelector(store => store?.auth?.user?.emails);
  const formatMessage = useIntl().formatMessage;
  if (emails && emails.length > 0) {
    return (
      <div className='sub-settings-email-card'>
        <Text type='secondary'>{formatMessage(strings.short.email)}:</Text>
        <p>{emails[0]}</p>
      </div>
    );
  }
};

export default EmailCard;
