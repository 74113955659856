import actionTypes from '../actions/actionTypes';
import { removeBadPlaces } from '../actions/alerts';

const initialState = {
  allItems: [],
  items: [],
  alerts2h: { items: [], lastUpdated: {} },
  processing: false,
  error: null,
  // random string used to quickly verify that the data has been updated
  dataHash: null
};

export default function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALERTS_REQUEST:
      return {
        ...state,
        allItems: [],
        items: [],
        alerts2h: { items: [], lastUpdated: {} },
        processing: true,
        error: null,
        dataHash: null
      };
    case actionTypes.GET_ALERTS_SUCCESS:
      return {
        ...state,
        items: action.alerts,
        allItems: action.allItems,
        alerts2h: action.alerts2h,
        processing: !action.final,
        dataHash: action.final ? `${Date.now()}_${Math.random()}` : state.dataHash
      };
    case actionTypes.GET_ALERTS_FAILURE: {
      return {
        ...state,
        allItems: [],
        items: [],
        alerts2h: { items: [], lastUpdated: {} },
        processing: false,
        error: action.error,
        dataHash: null
      };
    }
    case actionTypes.RECEIVE_NEW_ALERT_NOTIFICATION:
    case actionTypes.UPDATE_ALERT2H_NOTIFICATION:
      return {
        ...state,
        alerts2h: action.alerts2h
      };
    case actionTypes.FILTER_DEVICES:
      return {
        ...state,
        items: action.alertItems
      };
    case actionTypes.GET_PLACES_SUCCESS:
      if (state.allItems.length) {
        return {
          ...state,
          items: removeBadPlaces(state.allItems, action.data)
        };
      }
      return state;
    default:
      return state;
  }
}
