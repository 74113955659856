import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Avatar } from 'antd';
import * as strings from '../../helpers/defaultStrings';
import './UserMenu.scss';
import ApplicationSettings from '../Settings/ApplicationSettings';
import paths from '../../app/paths';
import OdyButton from '../../ReusableComponents/Buttons/OdyButton/OdyButton';
import OdyTextButton from '../../ReusableComponents/Buttons/OdyTextButton';
import './UserDropdown.scss';
import { isScalar, isUserTIP, openNewTab } from '../../helpers/functions';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { selectProductFeatures } from '../../selectors';

const SubscriptionSettingsOption = ({ formatMessage, toggleMenuOpen }) => {
  const { showNotification } = useSelector(selectProductFeatures);
  if (showNotification) {
    return (
      <Link to={paths.subscriptionSettings} onClick={() => toggleMenuOpen()}>
        <OdyTextButton className='full-width'>
          <span>{formatMessage(strings.button.subscriptionSettings)}</span>
        </OdyTextButton>
      </Link>
    );
  }
  return null;
};

const UserDropdown = ({ user, logOut, formatMessage, toggleMenuOpen, avatarSrc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const org = useSelector(store => store.auth.auth0User?.orgdn);
  const showModal = () => {
    toggleMenuOpen();
    setIsOpen(true);
  };
  const name = user?.name?.givenName || user.name;
  const company = org ? org : isUserTIP(user) ? 'TIP' : 'ZF';

  return (
    <div className='user-dropdown'>
      <div className='user-dropdown-content'>
        <div className='user-dropdown-avatar'>
          <Avatar src={avatarSrc} size='large' />
        </div>
        <div className='typography-bold1-semibold'>{name}</div>
        <div className='typography-bold1-light'>{user?.emails?.[0]}</div>
        <div className='user-dropdown-company typography-small-light'>{company}</div>
        {isScalar() && (
          <OdyButton role='link' onClick={() => openNewTab(ENV_CONFIG.REACT_APP_ODYSSEY_MY_ACCOUNT)}>
            {formatMessage(strings.button.manageYourAccount)}
          </OdyButton>
        )}
        <OdyTextButton type='primary' onClick={showModal}>
          {formatMessage(strings.button.trailerSettings)}
        </OdyTextButton>
        <SubscriptionSettingsOption formatMessage={formatMessage} toggleMenuOpen={toggleMenuOpen} />
        {isOpen && (
          <ApplicationSettings open={isOpen} onOk={() => setIsOpen(false)} onCancel={() => setIsOpen(false)} />
        )}
      </div>
      <Divider />
      <div className='user-dropdown-content'>
        <div className='user-dropdown-sign-out'>
          <OdyButton role='link' onClick={logOut}>
            {formatMessage(strings.button.signOut)}
          </OdyButton>
        </div>
      </div>
    </div>
  );
};

export default UserDropdown;
