import React from 'react';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { getTimeDate, isUsaCustomer, isInactive, isNullOrUndefined } from '../../../../../../helpers/functions';
import { convert, getConversionString, getUnit, unitType } from '../../../../../../helpers/unitConverter';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ trailer, isMetric, isUS, formatMessage }) => {
  const { axleLoad } = trailer;

  const convertedAxleLoad = convert(isMetric, unitType.ton, axleLoad / 1000, isUS);
  const unit = formatMessage(getUnit(unitType.ton, isMetric, isUS));

  if (axleLoad) {
    const val = {
      value: formatMessage(...getConversionString(isMetric, axleLoad, unitType.ton, isUS)),
      time: getTimeDate(trailer.ebsTime * 1000)
    };

    return (
      <span>
        {trailer && axleLoad !== null && axleLoad !== undefined && isInactive(trailer) && (
          <Tooltip title={formatMessage(strings.short.ebsTimeVal, val)} placement='topLeft'>
            <span style={{ color: '#ccc' }}>
              {convertedAxleLoad} <small>{unit}</small>
            </span>
          </Tooltip>
        )}
        {trailer && axleLoad !== null && axleLoad !== undefined && !isInactive(trailer) && (
          <span>
            {convertedAxleLoad} <small>{unit}</small>
          </span>
        )}
        {(!trailer || axleLoad === null || axleLoad === undefined) && <NoValueSpan />}
      </span>
    );
  }
  return <NoValueSpan />;
};

const AxleLoadColumn = ({ trailers, region, isMetric, formatMessage }) => {
  const isUS = isUsaCustomer(region);

  return {
    title: <Tooltip title={formatMessage(strings.short.load)}>{formatMessage(strings.short.load)}</Tooltip>,
    key: 'axleLoad',
    dataIndex: 'axleLoad',
    searchKey: formatMessage(strings.short.load),
    selectionSection: selectionHeaders.generalMonitoring.id,
    render: rowData => <Cell trailer={rowData} isMetric={isMetric} isUS={isUS} formatMessage={formatMessage} />,
    sorter: () =>
      trailers.sort('axleLoad', (a, b) => {
        if (!a || !a.axleLoad) {
          return -1;
        }

        if (!b || !b.axleLoad) {
          return 1;
        }

        return a.axleLoad - b.axleLoad;
      }),
    width: 120
  };
};

const AxleLoadCSVColumnHeader = formatMessage => formatMessage(strings.short.load);
const AxleLoadCSVData = (trailer, _formatMessage, isMetric, isUS) =>
  !isNullOrUndefined(trailer?.axleLoad) ? convert(isMetric, unitType.ton, trailer.axleLoad / 1000, isUS) : '';

const AxleLoadCSVColumn = {
  header: AxleLoadCSVColumnHeader,
  data: AxleLoadCSVData
};

export default { table: AxleLoadColumn, csv: AxleLoadCSVColumn };
