import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, formatMessage }) => {
  if (rowData.categoryTitle && strings.trailerType[rowData.categoryTitle]) {
    return <p>{formatMessage(strings.trailerType[rowData.categoryTitle])}</p>;
  }
  return <NoValueSpan />;
};

const CategoryTitleColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    return trailers.filter('categoryTitle', t => {
      if (!t.categoryTitle) {
        return checkedCategories['unknown'];
      }

      return checkedCategories[t.categoryTitle];
    });
  };

  const categories = Object.keys(strings.trailerType).map(category => ({
    text: formatMessage(strings.trailerType[category]),
    value: category
  }));

  return {
    title: (
      <Tooltip title={formatMessage(strings.short.trailerCategory)}>
        {formatMessage(strings.short.trailerCategory)}
      </Tooltip>
    ),
    key: 'categoryTitle',
    dataIndex: 'categoryTitle',
    searchKey: formatMessage(strings.short.trailerCategory),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    sorter: () => trailers.sort('categoryTitle', (a, b) => a.categoryTitle?.localeCompare(b.categoryTitle) || 0),
    categories,
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('categoryTitle'),
    width: 150
  };
};

const CategoryTitleCSVColumnHeader = formatMessage => formatMessage(strings.short.trailerCategory);
const CategoryTitleCSVData = (trailer, formatMessage) => {
  if (trailer.categoryTitle && strings.trailerType[trailer.categoryTitle]) {
    return formatMessage(strings.trailerType[trailer.categoryTitle]);
  }
  return '';
};
const CategoryTitleCSVColumn = {
  header: CategoryTitleCSVColumnHeader,
  data: CategoryTitleCSVData
};

export default { table: CategoryTitleColumn, csv: CategoryTitleCSVColumn };
