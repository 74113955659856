import React from 'react';
import { Modal, Spin, Button } from 'antd';
import LocaleString from '../../Components/Utils/LocaleString';

const FirstLoginModal = ({ retrying, backToPortal }) => (
  <Modal
    centered
    closable={false}
    visible={true}
    title={
      retrying ? undefined : (
        <span>
          <LocaleString type='short' id={'pleaseTryAgainLater'} />
        </span>
      )
    }
    footer={[
      <div className='centred' key={1}>
        {retrying && <Spin />}
        {!retrying && (
          <Button onClick={backToPortal}>
            <LocaleString type='short' id={'backToPortal'} />
          </Button>
        )}
      </div>
    ]}
  >
    <p>
      <LocaleString type='phrase' id={retrying ? 'firstTimeConnecting' : 'syncTakingLonger'} />
    </p>
  </Modal>
);

export default FirstLoginModal;
