// @flow
import React from 'react';
import moment from 'moment';
import LocaleString from '../Components/Utils/LocaleString';

export function getTimeAgo(lastPosition) {
  const timeAgo = Math.round(Date.now() / 1000) - lastPosition;
  const hoursAgo = Math.round(timeAgo / (60 * 60));
  if (hoursAgo >= 22 && hoursAgo <= 36) {
    return <LocaleString type='short' id='hoursAgoVal' val={hoursAgo} />;
  } else {
    return moment
      .utc(lastPosition * 1000)
      .local()
      .fromNow();
  }
}
