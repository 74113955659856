import { defineMessages } from 'react-intl';

export const alertColours = defineMessages({
  red: {
    id: 'alertColours.red',
    defaultMessage: 'Red',
    description: ''
  },
  green: {
    id: 'alertColours.green',
    defaultMessage: 'Green',
    description: ''
  },
  yellow: {
    id: 'alertColours.yellow',
    defaultMessage: 'Yellow',
    description: ''
  },
  grey: {
    id: 'alertColours.grey',
    defaultMessage: 'Grey',
    description: ''
  }
});
