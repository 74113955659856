// @flow

export function parseLat(lat) {
  const commaIndex = lat.indexOf(',');
  const isNorth = lat[commaIndex + 1] === 'N';
  const parsedDegrees = Math.floor(Number(lat.slice(0, commaIndex)) / 100);
  const parsedMinutes = Number(lat.slice(0, commaIndex)) - 100 * parsedDegrees;
  if (isNorth) {
    return parsedDegrees + parsedMinutes / 60;
  }
  return -(parsedDegrees + parsedMinutes / 60);
}

export function parseLong(long) {
  const commaIndex = long.indexOf(',');
  const isEast = long[commaIndex + 1] === 'E';
  const parsedDegrees = Math.floor(Number(long.slice(0, commaIndex)) / 100);
  const parsedMinutes = Number(long.slice(0, commaIndex)) - 100 * parsedDegrees;
  if (isEast) {
    return parsedDegrees + parsedMinutes / 60;
  }
  return -(parsedDegrees + parsedMinutes / 60);
}

export function toRadians(deg) {
  return (deg * Math.PI) / 180;
}

export function toDegrees(rad) {
  return (rad * 180) / Math.PI;
}

/**
 * Returns an array of [longitude, latitude] between two points
 *
 * @param   {array} fromPoint - [longitude,latitude] of origin point.
 * @param   {array} toPoint - [longitude/latitude] of destination point.
 * @param   {number} numberOfPoints - Number of points to generate between origin and destination points
 * @returns {array} The points between the origin and destination points
 *
 */

export function getPointsBetween(fromPoint, toPoint, numberOfPoints) {
  let points = [];
  while (points.length < numberOfPoints) {
    points.push(
      intermediatePoint(
        points.length === 0 ? fromPoint : points[points.length - 1],
        toPoint,
        (points.length + 1) / numberOfPoints
      )
    );
  }

  return points;
}

export function intermediatePoint(fromPoint, toPoint, fraction) {
  const a1 = toRadians(fromPoint[1]),
    b1 = toRadians(fromPoint[0]);
  const a2 = toRadians(toPoint[1]),
    b2 = toRadians(toPoint[0]);

  const sina1 = Math.sin(a1),
    cosa1 = Math.cos(a1),
    sinb1 = Math.sin(b1),
    cosb1 = Math.cos(b1);
  const sina2 = Math.sin(a2),
    cosa2 = Math.cos(a2),
    sinb2 = Math.sin(b2),
    cosb2 = Math.cos(b2);

  // distance between points
  const ca = a2 - a1;
  const cb = b2 - b1;
  const a = Math.sin(ca / 2) * Math.sin(ca / 2) + Math.cos(a1) * Math.cos(a2) * Math.sin(cb / 2) * Math.sin(cb / 2);
  const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const A = Math.sin((1 - fraction) * d) / Math.sin(d);
  const B = Math.sin(fraction * d) / Math.sin(d);

  const x = A * cosa1 * cosb1 + B * cosa2 * cosb2;
  const y = A * cosa1 * sinb1 + B * cosa2 * sinb2;
  const z = A * sina1 + B * sina2;

  const a3 = Math.atan2(z, Math.sqrt(x * x + y * y));
  const b3 = Math.atan2(y, x);

  return [((toDegrees(b3) + 540) % 360) - 180, toDegrees(a3)]; // normalise lon to −180..+180°
}

/**
 * Returns the direction from origin point to destination point.
 *
 * @param   {array} fromPoint - [longitude,latitude] of origin point.
 * @param   {array} toPoint - [longitude/latitude] of destination point.
 * @returns {number} Initial bearing in degrees from north.
 *
 */
export function getDirectionBetween(fromPoint, toPoint) {
  const a1 = toRadians(fromPoint[1]),
    a2 = toRadians(toPoint[1]);
  const bd = toRadians(toPoint[0] - fromPoint[0]);
  const y = Math.sin(bd) * Math.cos(a2);
  const x = Math.cos(a1) * Math.sin(a2) - Math.sin(a1) * Math.cos(a2) * Math.cos(bd);
  const c = Math.atan2(y, x);

  return 180 - ((toDegrees(c) + 360) % 360);
}
