import React from 'react';
import Icon from '@ant-design/icons';

const heartBeatSvg = () => (
  <svg viewBox='0 0 289 258' version='1.1' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group' transform='translate(1.000000, 0.000000)'>
        <g id='heart' fill='currentColor' fillRule='nonzero'>
          <path
            d='M281,53.1313548 C276.531987,42.7992682 270.089549,33.4364301 262.033333,25.5669677 C253.971066,17.6740307 244.465434,11.4016278 234.033333,7.09083871 C223.215889,2.60307206 211.613604,0.305977517 199.9,0.332903226 C183.466667,0.332903226 167.433333,4.82709677 153.5,13.316129 C150.166667,15.3468387 147,17.5772903 144,20.0074839 C141,17.5772903 137.833333,15.3468387 134.5,13.316129 C120.566667,4.82709677 104.533333,0.332903226 88.1,0.332903226 C76.2666667,0.332903226 64.8,2.59664516 53.9666667,7.09083871 C43.5,11.4185806 34.0666667,17.643871 25.9666667,25.5669677 C17.8998891,33.4275533 11.4559299,42.7926035 7,53.1313548 C2.36666667,63.884129 0,75.3027097 0,87.0541935 C0,98.139871 2.26666667,109.691613 6.76666667,121.443097 C10.5333333,131.263742 15.9333333,141.450581 22.8333333,151.73729 C33.7666667,168.016258 48.8,184.994323 67.4666667,202.205419 C98.4,230.735226 129.033333,250.443097 130.333333,251.242065 L138.233333,256.302194 C141.733333,258.532645 146.233333,258.532645 149.733333,256.302194 L157.633333,251.242065 C158.933333,250.409806 189.533333,230.735226 220.5,202.205419 C239.166667,184.994323 254.2,168.016258 265.133333,151.73729 C272.033333,141.450581 277.466667,131.263742 281.2,121.443097 C285.7,109.691613 287.966667,98.139871 287.966667,87.0541935 C288,75.3027097 285.633333,63.884129 281,53.1313548 Z M144,229.969548 C144,229.969548 25.3333333,154.034323 25.3333333,87.0541935 C25.3333333,53.1313548 53.4333333,25.6335484 88.1,25.6335484 C112.466667,25.6335484 133.6,39.216 144,59.0570323 C154.4,39.216 175.533333,25.6335484 199.9,25.6335484 C234.566667,25.6335484 262.666667,53.1313548 262.666667,87.0541935 C262.666667,154.034323 144,229.969548 144,229.969548 Z'
            id='Shape'
          ></path>
        </g>
        <path
          d='M6.5,138 L276.70156,137.533993'
          id='Line'
          stroke='#FFFFFF'
          strokeWidth='14'
          strokeLinecap='square'
        ></path>
        <polyline
          id='Path-2'
          stroke='currentColor'
          strokeWidth='16'
          strokeLinecap='round'
          strokeLinejoin='round'
          points='19.6717073 122.586402 59.6464255 123.192411 81.5794841 64 121.50411 181.172804 142.690114 122.586402 268.438482 122.586402'
        ></polyline>
      </g>
    </g>
  </svg>
);

const HeartIcon = props => <Icon component={heartBeatSvg} {...props} />;

export default HeartIcon;
