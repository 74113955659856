import React from 'react';
import { connect } from 'react-redux';
import { HeartOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { push } from 'connected-react-router';

import LocaleString from './LocaleString';
import actions from '../../actions';
import { triggerEvent } from '../../helpers/googleAnalytics';
import OdyTextButton from '../../ReusableComponents/Buttons/OdyTextButton';

class HealthButton extends React.Component {
  goToHealthPage = () => {
    const { updateSelectedHealthTrailer, selectedTrailer, goToHealth } = this.props;
    triggerEvent('Navigation', 'Go to selected device on Health screen', 'Live Map screen');
    updateSelectedHealthTrailer(selectedTrailer);
    goToHealth();
  };

  render() {
    const { size, shape, style, light, blue } = this.props;
    return (
      <div>
        {light && (
          <Tooltip placement='bottom' title={<LocaleString type='button' id='showOnHealthPage' />}>
            <Button
              type='link'
              style={style}
              size={size ? size : 'small'}
              shape={shape ? shape : 'round'}
              icon={<HeartOutlined />}
              onClick={this.goToHealthPage}
            />
          </Tooltip>
        )}

        {blue && (
          <OdyTextButton
            type='primary'
            onClick={this.goToHealthPage}
            icon={<HeartOutlined />}
            className='medium-margin-right'
          >
            {' '}
            <LocaleString type='button' id='health' />
          </OdyTextButton>
        )}
        {!light && !blue && (
          <Button
            style={style}
            size={size ? size : 'small'}
            shape={shape ? shape : 'round'}
            icon={<HeartOutlined />}
            onClick={this.goToHealthPage}
          >
            {' '}
            <LocaleString type='button' id='showOnHealthPage' />
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  goToHealth: () => dispatch(push('/health')),
  updateSelectedHealthTrailer: (trailer, callBack) =>
    dispatch(actions.appState.updateSelectedHealthTrailer(trailer, callBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthButton);
