import React from 'react';
import { Row } from 'antd';

import AccessRevokedImg from '../../assets/images/access-revoked.jpg';
import LocaleString from '../Utils/LocaleString';

const AccessRevoked = () => {
  return (
    <div style={{ marginTop: 100 }}>
      <Row type='flex' justify='center'>
        <div style={{ textAlign: 'center' }}>
          <h1>
            <LocaleString type='phrase' id='accessRevoked' />
          </h1>
          <h3>
            <LocaleString type='description' id='accessRevoked' />
          </h3>
        </div>
      </Row>
      <Row type='flex' justify='center' align='top'>
        <img style={{ width: 300, marginTop: 0 }} src={AccessRevokedImg} alt='AccessRevoked' />
      </Row>
    </div>
  );
};

export default AccessRevoked;
