export const EVENT_NAMES = {
  INFORMATION_MODAL_OPENED: 'INFORMATION_MODAL_OPENED',
  INFORMATION_MODAL_CLOSED_OUTSIDE: 'INFORMATION_MODAL_CLOSED_OUTSIDE',
  INFORMATION_MODAL_CLOSED_OK: 'INFORMATION_MODAL_CLOSED_OK',
  INFORMATION_MODAL_CLOSED_X: 'INFORMATION_MODAL_CLOSED_X',
  UNKNOWN: 'UNKNOWN',
  FILTER_RESET: 'FILTER_RESET',
  FILTER_SELECTED: 'FILTER_SELECTED',
  CLICKED_X: 'CLICKED_X',
  QUERY_TYPED: 'QUERY_TYPED',
  SORT_DESC: 'SORT_DESC',
  SORT_ASC: 'SORT_ASC',
  DASHBOARD_FILTER: 'DASHBOARD_FILTER',
  DASHBOARD_FILTER_HOURS: 'DASHBOARD_FILTER_HOURS'
};
