import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import './Home.scss';
import FirstLoginModal from './FirstLoginModal';
import splash from '../../assets/images/splash.jpg';
import zfLogoWhite from '../../assets/images/ZFLogoWhite.png';
import TIP from '../../assets/images/TIP-Insight-BrakePlus-logo.jpg';
import { redirectToPortal } from '../../actions/thunks/auth/helper';
import { isScalar } from '../../helpers/functions';
import { APP_VERSION } from '../../app/helpers/app-version';
import { selectProductFeatures } from '../../selectors';

const homeStyle = isTipImage => ({
  backgroundImage: isTipImage ? 'none' : `url(${splash})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

const Home = () => {
  const productFeatures = useSelector(selectProductFeatures);
  const retrying = useSelector(store => store.auth.retrying);
  const firstLogin = useSelector(store => store.auth.firstLogin);

  const isTipImage = productFeatures.logoImage === 'tip';

  const backToPortal = () => {
    const env = window.localStorage.getItem('transics-env');
    redirectToPortal(env);
  };

  return (
    <div style={homeStyle(isTipImage)}>
      <div className={isTipImage ? 'tip-background' : 'default-background'}>
        <img src={isTipImage ? TIP : zfLogoWhite} alt='wabco' className='home__logo' />
        <Tag className='version-style'>{APP_VERSION}</Tag>
      </div>
      {!isScalar() && firstLogin && <FirstLoginModal retrying={retrying} backToPortal={backToPortal} />}
    </div>
  );
};

export default Home;
