import { convertInt, unitType } from './unitConverter';
import moment from 'moment';
import { isNullOrUndefined } from './functions';

export const addEndPoint = route =>
  Array.isArray(route) && route.length > 0
    ? [
        ...route,
        {
          ...route[route.length - 1],
          startSpeed: route[route.length - 1].endSpeed,
          startAxleLoad: route[route.length - 1].endAxleLoad,
          startTime: route[route.length - 1].endTime
        }
      ]
    : null;

export const getShortenedRoute = (route, hours, searchTime) => {
  if (!route) {
    return null;
  }
  const startNow = searchTime || Date.now() / 1000;
  const timeAgo = searchTime - hours * 60 * 60;
  return route.reduce((agg, elem) => {
    if (elem.startTime >= timeAgo && elem.endTime <= startNow) {
      agg.push(elem);
    }

    return agg;
  }, []);
};

export const convertData = (data, isMetric) => {
  if (!data) {
    return data;
  }
  return data.map(item => {
    if (!item.isConverted) {
      item.isConverted = true;
      item.startAxleLoad = convertInt(isMetric, unitType.ton, item.startAxleLoad);
      item.startSpeed = convertInt(isMetric, unitType.speed, item.startSpeed);
      item.endSpeed = convertInt(isMetric, unitType.speed, item.endSpeed);
      item.endAxleLoad = convertInt(isMetric, unitType.ton, item.endAxleLoad);
    }
    return item;
  });
};

/**
 * Returns:
 * {
 *   label: <label A + wheelLocation = A1R1>,
 *   location: <wheelLocation - 1R1>,
 *   powerGood: <true, false>,
 *   temperature: <number or undefined>,
 *   pressure: <number or undefined>,
 *   hasAlert: <true or false>
 * }
 * @param wheelData
 * @param isMetric
 */
export const getWheelTPMSValue = (wheelData, isMetric, fallbackTime) => {
  let label = 'A' + wheelData.location;
  let temperature = wheelData.temp;
  let pressure = false;
  let hasAlert = false;
  let pressureThreshold;
  if (wheelData.pressure) {
    pressure = wheelData.pressure.val ? wheelData.pressure.val / 10 : wheelData.pressure.value;
    pressureThreshold = wheelData.pressure.thd;
    hasAlert = wheelData.pressure.suff === false;
  }

  const newData = {
    label,
    location: wheelData.location,
    powerGood: !(wheelData.powerGood === false),
    hasAlert: false,
    pressureThreshold: pressureThreshold,
    time: wheelData.time ?? fallbackTime
  };
  if (temperature || temperature === 0) {
    newData.temperature = convertInt(isMetric, unitType.temp, temperature);
  }
  if (pressure || pressure === 0) {
    newData.pressure = convertInt(isMetric, unitType.pressure, pressure);
    newData.hasAlert = hasAlert;
  } else if (hasAlert) {
    newData.hasAlert = hasAlert;
    newData.pressureThreshold = pressureThreshold;
    newData.pressure = newData.pressure || '--';
  }
  return newData;
};

/**
 * Given the trailerObject and the fmshistory array, it returns the most recent values for the TPMS data in the following format:
 * {
 *   'A1R1': {
 *    temperature: 35,
 *    pressure: 9.2,
 *    hasAlert: false, <--- tmps.pressure.suff === false
 *    isOldData: false, <--- in case the latest data returns empty, it iterates in the fmshistory and get the most recent previous values
 *    time: 1597764949543 <--- time when the data was generated
 *   },
 *   'A1L1': {
 *      ...
 *   },
 *   'A2R1': {
 *      ...
 *   },
 *   ...
 * }
 * In case you need to get from an specific time, just pass the specific item (specificHistoryItem) from the history array
 * and it will return the valid values from that event and the most recent value if there isn't in the object
 *
 * @param trailer
 * @param history
 * @param selectedTimelineHistoryItem
 * @param isMetric
 * @returns {{}}
 */
export const mostRecentValuesTPMS = (trailer, history = {}, selectedTimelineHistoryItem, isMetric) => {
  const tiresData = getTrailersAxleWheelDefaultData(trailer);

  let searchHistoryItem;
  // if the user selected an item in t
  //    he timeline
  if (selectedTimelineHistoryItem) {
    const findSelectedTime = selectedTimelineHistoryItem.time / 1000;
    searchHistoryItem = {
      ...(history.tpms || []).find(
        ({ endTime, startTime }) => endTime > findSelectedTime && startTime <= findSelectedTime
      )
    };

    if (!searchHistoryItem.time) {
      searchHistoryItem = {
        time: selectedTimelineHistoryItem.time
          ? selectedTimelineHistoryItem.time / 1000
          : selectedTimelineHistoryItem.startTime,
        wheels: {}
      };
      if (selectedTimelineHistoryItem.tpms && selectedTimelineHistoryItem.tpms.data) {
        Object.keys(tiresData).forEach(wheel => {
          const wheelData = selectedTimelineHistoryItem.tpms.data.find(item => `A${item.location}` === wheel);

          if (wheelData) {
            searchHistoryItem.wheels[wheel] = getWheelTPMSValue(wheelData, isMetric);
          }
        });
      }
    }
    // otherwise it uses the latest data in the history
  } else if (!selectedTimelineHistoryItem && history.tpms && history.tpms.length > 0) {
    searchHistoryItem = { ...history.tpms[history.tpms.length - 1] };

    // and the last resource, if neither history nor a selected item in the timeline are available,
    // we can retrieve the information from the trailer, which usually is the latest valid values
  } else {
    searchHistoryItem = {
      time: trailer.time,
      wheels: {}
    };
    if (trailer.tires) {
      Object.keys(tiresData).forEach(wheel => {
        const wheelData = trailer.tires.find(item => `A${item.location}` === wheel);

        if (wheelData) {
          searchHistoryItem.wheels[wheel] = getWheelTPMSValue(wheelData, isMetric);
        }
      });
    }
  }

  Object.keys(tiresData).forEach(wheel => {
    let requestedDate = searchHistoryItem ? parseInt(searchHistoryItem.time * 1000) : null;

    // get currentValues
    let {
      temperatureVal,
      pressureVal,
      hasAlert,
      isEmptyData: isOldData,
      pressureThreshold,
      time
    } = getValidValuesFromHistoryItem(searchHistoryItem, wheel);

    // if there is no data in the current Requested Date and there is a history, try to get the previous valid data
    if (isOldData && history.tpms && history.tpms.length > 0) {
      // get the history order from newest date to oldest starting at requested date
      const historyFromRequestedDate = (history.tpms || [])
        .filter(item => item.endTime < requestedDate / 1000)
        .sort((a, b) => b.time - a.time);
      for (let item of historyFromRequestedDate) {
        if (item && item.wheels) {
          let data = getValidValuesFromHistoryItem(item, wheel, time);

          if (!data.isEmptyData) {
            // update end current values
            temperatureVal = data.temperatureVal;
            pressureVal = data.pressureVal;
            hasAlert = data.hasAlert;
            pressureThreshold = data.pressureThreshold;

            requestedDate = item.time * 1000;
            break;
          }
        }
      }
    }

    // if requested the latest valid values and the data is older than 2 minutes,
    // we should consider always as old Data
    if (!selectedTimelineHistoryItem && !isOldData) {
      // it's considered old data if:
      // - has no Data available
      // - device is powered off
      // - data is older than 30 min (Jozef agreed)
      isOldData =
        (trailer?.sinceState?.power?.state ?? 'PowerOff') === 'PowerOff' ||
        requestedDate < moment().subtract(30, 'minutes').toDate().getTime();
    }

    if (temperatureVal || pressureVal) {
      tiresData[wheel].temperature = temperatureVal;
      tiresData[wheel].pressure = pressureVal;
      tiresData[wheel].pressureThreshold = pressureThreshold;
      tiresData[wheel].isOldData = isOldData;
      tiresData[wheel].time = time ? time * 1000 : requestedDate;
      tiresData[wheel].hasAlert = hasAlert;
    } else if (trailer.lastValidValues) {
      const lastValidTpms = trailer.lastValidValues[`ebs_tpms_${wheel.slice(1)}`];
      const tpms = trailer.tpms || {};

      if (lastValidTpms) {
        const lastValidValuePressure = tpms.pressure || lastValidTpms.pressure || {};

        tiresData[wheel].temperature =
          tpms.temp || tpms.temp === 0
            ? convertInt(isMetric, unitType.temp, tpms.temp)
            : lastValidTpms.temp || lastValidTpms.temp === 0
            ? convertInt(isMetric, unitType.temp, lastValidTpms.temp)
            : false;
        tiresData[wheel].pressure =
          lastValidValuePressure.value || lastValidValuePressure.value === 0
            ? convertInt(isMetric, unitType.pressure, lastValidValuePressure.value)
            : false;
        tiresData[wheel].time = tpms.time * 1000 || lastValidTpms.time * 1000 || null;
        tiresData[wheel].hasAlert = lastValidValuePressure.suff === false;
        tiresData[wheel].pressureThreshold = lastValidValuePressure.thd;
      }

      // it's considered old data if:
      // - has no Data available
      // - device is powered off
      // - data is older than 30 min (Jozef agreed)
      tiresData[wheel].isOldData =
        (selectedTimelineHistoryItem && !tiresData[wheel].temperature && !tiresData[wheel].pressure) ||
        (trailer?.sinceState?.power?.state ?? 'PowerOff') === 'PowerOff' ||
        tiresData[wheel].time < moment().subtract(30, 'minutes').toDate().getTime();
    }
    if (tiresData[wheel].time > Date.now()) {
      tiresData[wheel].time = tiresData[wheel].time / 1000;
    }
  });

  return tiresData;
};

const getTrailersAxleWheelDefaultData = trailer => {
  const axleCount = parseInt(trailer.axles) || 1;
  const twinTyres =
    trailer.tyreChoice === 'twinTyres' ||
    (trailer.tires && trailer.tires.some(t => t.location && t.location.endsWith('2')));

  const defaultData = {
    temperature: null,
    pressure: null,
    pressureThreshold: null,
    hasAlert: false,
    isOldData: true,
    time: null
  };
  const tiresData = {};
  for (let axle = 1; axle <= axleCount; axle++) {
    tiresData[`A${axle}R1`] = { location: `${axle}R1`, label: `A${axle}R1`, ...defaultData };
    tiresData[`A${axle}L1`] = { location: `${axle}L1`, label: `A${axle}L1`, ...defaultData };
    if (twinTyres) {
      tiresData[`A${axle}R2`] = { location: `${axle}R2`, label: `A${axle}R2`, ...defaultData };
      tiresData[`A${axle}L2`] = { location: `${axle}L2`, label: `A${axle}L2`, ...defaultData };
    }
  }
  return tiresData;
};

const getValidValuesFromHistoryItem = (historyItemData, wheel) => {
  const wheelData = historyItemData?.wheels?.[wheel] ?? {};
  return {
    temperatureVal: !isNullOrUndefined(wheelData.temperature) ? wheelData.temperature : false,
    pressureVal: !isNullOrUndefined(wheelData.pressure) ? wheelData.pressure : false,
    pressureThreshold: wheelData.pressureThreshold,
    hasAlert: wheelData.hasAlert || false,
    isEmptyData: !wheelData || (!wheelData.temperature && !wheelData.pressure),
    time: wheelData.time
  };
};
