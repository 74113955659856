import { createSlice } from '@reduxjs/toolkit';
import { generateAsyncThunkReducers } from '../app/generators';
import {
  SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH,
  SETTINGS_TRAILERFIT_THUNK_TYPE_SAVE,
  SETTINGS_EBPMS_THUNK_TYPE_SAVE,
  LOADING_STATUSES
} from '../helpers/constants';

const defaultRequestState = {
  loading: LOADING_STATUSES.IDLE,
  dataLoaded: false
};
export const initialState = {
  [SETTINGS_EBPMS_THUNK_TYPE_SAVE]: { data: {}, ...defaultRequestState },
  [SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH]: { data: {}, ...defaultRequestState },
  [SETTINGS_TRAILERFIT_THUNK_TYPE_SAVE]: { data: {}, ...defaultRequestState }
};

const fulfilledStateReducer = (state, action) => {
  state.data = action.payload;
};

export const sliceOptions = {
  name: 'settings.v2',
  initialState,
  extraReducers: {
    ...generateAsyncThunkReducers({
      withNamespace: true,
      thunkType: SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH,
      fulfilledStateReducer
    }),
    ...generateAsyncThunkReducers({
      withNamespace: true,
      thunkType: SETTINGS_TRAILERFIT_THUNK_TYPE_SAVE,
      fulfilledStateReducer
    }),
    ...generateAsyncThunkReducers({
      withNamespace: true,
      thunkType: SETTINGS_EBPMS_THUNK_TYPE_SAVE,
      fulfilledStateReducer
    })
  }
};

export const settingsSlice = createSlice(sliceOptions);

export default {
  settingsV2: settingsSlice.reducer
};
