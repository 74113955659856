import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';

export const zoomOutAllPlaces = (activePlaces, flightToBounds) => {
  let { bounds } = getPlacesBounds(activePlaces);
  flightToBounds(bounds);
};

export const getPlaceCoordinates = place => {
  return isPlaceTypePoint(place)
    ? getPlacePointCoordinates(place)
    : place?.geofenceDefinition?.geometry?.coordinates?.[0];
};

export const getPlacePointCoordinates = place => {
  // 1 degree = 110574m
  const coordinates = place?.geofenceDefinition?.geometry?.coordinates;
  const radius = place.geofenceDefinition.properties.radius.size || 1000;
  const radiusInDegrees = radius / 110574;
  return [
    [coordinates[0], coordinates[1] + radiusInDegrees],
    [coordinates[0], coordinates[1] - radiusInDegrees],
    [coordinates[0] + radiusInDegrees, coordinates[1]],
    [coordinates[0] - radiusInDegrees, coordinates[1]]
  ];
};

export const isPlaceTypePoint = place => place?.geofenceDefinition?.geometry?.type === 'Point';

export const getPlacesBounds = places => {
  let points = [];
  let coordinates = [];
  places.forEach(place => {
    coordinates = getPlaceCoordinates(place);
    if (coordinates) {
      points.push(...coordinates);
    }
  });

  let line = null;
  try {
    line = points.length >= 2 ? lineString(points) : null;
  } catch (e) {
    // Use try catch here in case all the points are the same which could cause an exception when determingin the line
    console.warn(`Problem generating line from device points: ${JSON.stringify(e)}`);
    line = null;
  }
  return {
    bounds: line !== null ? bbox(line) : null,
    points
  };
};
