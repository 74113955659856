import devices from './devices';
import trailers from './trailers';
import notifications from './notifications';
import trailersHealth from './trailersHealth';
import trailerHistory from './trailerHistory';
import * as auth from './auth';
import file from './file';
import settings from './settings';
import language from './language';
import messages from './messages';
import appState from './appState';
import emailTemplates from './emailTemplates';
import alerts from './alerts';
import odrFiles from './odrFiles';
import trailersLoadingEvents from './trailersLoadingEvents';
import brakingEvents from './brakingEvents';
import ebpmsTrailers from './ebpmsTrailers';
import groups from './groups';
import trailerHistograms from './trailerHistograms';
import places from './places';
import trailersOdrBraking from './trailersOdrBraking';
import workshopServicePartners from './workshopServicePartners';
import liveMapActions from './liveMapActions';
import { fetchLastValidEbpmsMessage } from './thunks/fetch-lastValidEbpmsMessage';

export default {
  devices,
  trailers,
  trailersHealth,
  notifications,
  trailerHistory,
  auth,
  file,
  settings,
  language,
  messages,
  appState,
  emailTemplates,
  alerts,
  odrFiles,
  trailersLoadingEvents,
  brakingEvents,
  ebpmsTrailers,
  groups,
  places,
  trailerHistograms,
  trailersOdrBraking,
  workshopServicePartners,
  liveMapActions,
  fetchLastValidEbpmsMessage
};
