import { createSelector } from '@reduxjs/toolkit';
import { LOADING_STATUSES } from '../helpers/constants';

const selectRoot = state => state?.trailers;

// this should be internal only, given the outside world should only care
// about filtered entities
const getEntities = createSelector(selectRoot, state => state?.entities);
export const selectEntities = createSelector(getEntities, state => Object.values(state));

export const selectFilteredEntitiesIds = createSelector(selectRoot, state => state?.filteredEntitiesIds);

export const selectFilteredEntities = createSelector(
  [getEntities, selectFilteredEntitiesIds],
  (entities, filteredEntitiesIds) => {
    return filteredEntitiesIds.reduce((filteredEntities, entityId) => {
      return [...filteredEntities, entities[entityId]];
    }, []);
  }
);
export const selectLoadingAcrossRequests = createSelector(selectRoot, state => state?.loadingAcrossRequests);
export const selectIsDataLoading = createSelector(selectRoot, state => {
  const { loading, loadingAcrossRequests } = state;

  return loading === LOADING_STATUSES.PENDING || loadingAcrossRequests;
});
