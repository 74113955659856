import { getRegion } from '../helpers/functions';
import { defaultIsMetric } from '../helpers/constants';

import actionTypes from '../actions/actionTypes';

export const initialUserState = {
  processing: false,
  error: null,
  user: null,
  region: null,
  isMetric: defaultIsMetric,
  firstLogin: false,
  retrying: false,
  auth0User: null
};

export default function userReducer(state = initialUserState, action) {
  switch (action.type) {
    case actionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        processing: false,
        region: null
      };
    case actionTypes.USER_LOGOUT_REQUEST:
      return {
        ...state,
        processing: true
      };
    case actionTypes.USER_LOGOUT_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        processing: false,
        region: action.user ? getRegion(action.user.customerCountry) : null,
        firstLogin: false,
        retrying: false
      };
    case actionTypes.ADD_USER_DATA:
      return {
        ...state,
        auth0User: {
          ...state.auth0User,
          ...action.payload
        }
      };
    case actionTypes.AUTH0_USER_LOGIN_SUCCESS:
      return {
        ...state,
        auth0User: action.user
      };
    case actionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        processing: true
      };
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error
      };
    case actionTypes.AUTH0_USER_LOGIN_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error
      };
    case actionTypes.USER_CHANGE_IS_METRIC:
      return {
        ...state,
        isMetric: action.isMetric || defaultIsMetric
      };
    case actionTypes.USER_RETRY_LOGIN:
      return {
        ...state,
        firstLogin: true,
        retrying: true
      };
    case actionTypes.USER_RETRY_FAILURE:
      return {
        ...state,
        firstLogin: true,
        retrying: false,
        processing: false,
        error: action.error
      };
    default:
      return state;
  }
}
