import actionTypes from '../actions/actionTypes';

const initialState = {
  items: [],
  processing: false,
  error: null,
  averages: null,
  avgProcessing: false,
  avgError: null
};

export default function trailersBrakingEventsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ODR_BRAKING_EVENTS_REQUEST: {
      return {
        ...state,
        items: [],
        processing: true,
        error: null
      };
    }
    case actionTypes.GET_ODR_BRAKING_EVENTS_SUCCESS: {
      return {
        ...state,
        items: [...state.items, ...action.trailers],
        processing: !action.final,
        error: null
      };
    }
    case actionTypes.GET_ODR_BRAKING_EVENTS_FAILURE: {
      return {
        ...state,
        items: [],
        processing: false,
        error: action.error
      };
    }
    case actionTypes.GET_ODR_BRAKING_AVG_REQUEST: {
      return {
        ...state,
        averages: null,
        avgProcessing: true,
        avgError: null
      };
    }
    case actionTypes.GET_ODR_BRAKING_AVG_SUCCESS: {
      return {
        ...state,
        averages: action.averages,
        avgProcessing: false,
        avgError: null
      };
    }
    case actionTypes.GET_ODR_BRAKING_AVG_FAILURE: {
      return {
        ...state,
        averages: null,
        avgProcessing: false,
        avgError: action.error
      };
    }
    default:
      return state;
  }
}
