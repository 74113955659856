import { defineMessages } from 'react-intl';

export const lightMonitoringStatus = defineMessages({
  good: {
    id: 'lightMonitoringStatus.good',
    defaultMessage: 'Good',
    description: ''
  },
  multipleAnomalies: {
    id: 'lightMonitoringStatus.multipleAnomalies',
    defaultMessage: 'Multiple Anomalies',
    description: ''
  },
  unknown: {
    id: 'lightMonitoringStatus.unknown',
    defaultMessage: 'Unknown',
    description: ''
  },
  highCurrent: {
    id: 'lightMonitoringStatus.highCurrent',
    defaultMessage: 'High Current',
    description: ''
  },
  lowCurrent: {
    id: 'lightMonitoringStatus.lowCurrent',
    defaultMessage: 'Low Current',
    description: ''
  },
  intermittent: {
    id: 'lightMonitoringStatus.intermittent',
    defaultMessage: 'Intermittent',
    description: ''
  },
  disconnected: {
    id: 'lightMonitoringStatus.disconnected',
    defaultMessage: 'Disconnected',
    description: ''
  },
  reconnected: {
    id: 'lightMonitoringStatus.reconnected',
    defaultMessage: 'Reconnected',
    description: ''
  },
  stopLamp: {
    id: 'lightMonitoringStatus.stopLamp',
    defaultMessage: 'Stop Lamp',
    description: ''
  },
  rightTurn: {
    id: 'lightMonitoringStatus.rightTurn',
    defaultMessage: 'Right Turn',
    description: ''
  },
  leftTurn: {
    id: 'lightMonitoringStatus.leftTurn',
    defaultMessage: 'Left Turn',
    description: ''
  },
  clearanceLamps: {
    id: 'lightMonitoringStatus.clearanceLamps',
    defaultMessage: 'Clearance Lamps',
    description: ''
  },
  tailLamps: {
    id: 'lightMonitoringStatus.tailLamps',
    defaultMessage: 'Tail Lamps',
    description: ''
  },
  columnDescription: {
    id: 'lightMonitoringStatus.columnDescription',
    defaultMessage:
      'The light alarm status is represented by the colors amber, red and green and the light location is written on the pill',
    description: ''
  },
  warningStatusDescription: {
    id: 'lightMonitoringStatus.warningStatusDescription',
    defaultMessage: 'Amber color indicates light anomaly status',
    description: ''
  },
  outageStatusDescription: {
    id: 'lightMonitoringStatus.outageStatusDescription',
    defaultMessage: 'Red color indicates light outage status',
    description: ''
  },
  goodStatusDescription: {
    id: 'lightMonitoringStatus.goodStatusDescription',
    defaultMessage: 'Green color indicates light good status',
    description: ''
  },
  unknownStatusDescription: {
    id: 'lightMonitoringStatus.unknownStatusDescription',
    defaultMessage: 'Grey color indicates unknown light status',
    description: ''
  },
  fullInfo: {
    id: 'lightMonitoringStatus.fullInfo',
    defaultMessage: 'Select the trailer name to check the full information about lights monitoring.',
    description: ''
  },
  anomaly: {
    id: 'lightMonitoringStatus.anomaly',
    defaultMessage: 'Anomaly',
    description: ''
  },
  anomalies: {
    id: 'lightMonitoringStatus.anomalies',
    defaultMessage: 'Anomalies',
    description: ''
  },
  outage: {
    id: 'lightMonitoringStatus.outage',
    defaultMessage: 'Outage',
    description: ''
  },
  outages: {
    id: 'lightMonitoringStatus.outages',
    defaultMessage: 'Outages',
    description: ''
  },
  lightType: {
    id: 'lightMonitoringStatus.lightType',
    defaultMessage: 'Light Type',
    description: ''
  },
  alertColour: {
    id: 'lightMonitoringStatus.alertColour',
    defaultMessage: 'Alert Colour',
    description: ''
  },
  noInfo: {
    id: 'lightMonitoringStatus.noInfo',
    defaultMessage: 'No light monitoring info available.',
    description: ''
  },
  lastUpdated: {
    id: 'lightMonitoringStatus.lastUpdated',
    defaultMessage: 'Last Updated',
    description: ''
  }
});
