import React from 'react';
import * as strings from '../../helpers/defaultStrings';
import { FlagIcon } from './SettingsLanguages';
import { settingsConstants, unitSystems, DEFAULT_APP_SETTINGS } from '../../helpers/constants';
import './Settings.scss';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Menu, Dropdown, Button, Radio, InputNumber } from 'antd';

const GenericDropdown = ({ menuItems, buttonTitle }) => {
  return (
    <Dropdown overlay={<Menu className='setting-dropdown-menu'>{menuItems}</Menu>}>
      <Button>
        {buttonTitle} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export const DropdownOption = ({ options, formatMessage, optionalSettings, settings, updateSetting }) => {
  const menuItems = (Object.keys(options.data) || []).map(key => {
    const item = options.data[key];
    return (
      <Menu.Item key={item} onClick={() => updateSetting(options.type, parseFloat(key))}>
        {formatMessage(strings.settings[item])}
      </Menu.Item>
    );
  });
  let value = settings[options.type];
  if (value === undefined || value === null) {
    value = DEFAULT_APP_SETTINGS[options.type];
  }

  Object.keys(optionalSettings).forEach(key => {
    if (key === options.type) {
      value = optionalSettings[key];
    }
  });

  return (
    <GenericDropdown
      buttonTitle={formatMessage(strings.settings[settingsConstants[options.type][value]])}
      menuItems={menuItems}
    />
  );
};

export const LanguageOption = ({ options, lang, transicsLanguage, language, updateLang }) => {
  const menuItems = [];
  options.data.forEach(item => {
    menuItems.push(
      <Menu.Item key={item.lang} onClick={() => updateLang(item)}>
        <FlagIcon code={item.flagcode} /> {' ' + item.name}
      </Menu.Item>
    );
  });
  return <GenericDropdown menuItems={menuItems} buttonTitle={lang?.name || language?.name || transicsLanguage?.name} />;
};

export const UnitSystemOption = ({ options, formatMessage, isMetricSystem, updateAllSetting }) => {
  const radioButtons = Object.keys(options.data).map(key => {
    const item = options.data[key];
    return (
      <Radio.Button value={item} key={item}>
        {formatMessage(strings.settings[item])}
      </Radio.Button>
    );
  });
  return (
    <Radio.Group
      onChange={item => updateAllSetting(item?.target?.value === unitSystems.metric)}
      value={isMetricSystem.base ? unitSystems.metric : unitSystems.imperial}
      buttonStyle='solid'
    >
      {radioButtons}
    </Radio.Group>
  );
};

export const MaxValidDaysOption = ({ settings, options, updateSetting }) => {
  let value = settings.items[options.type];
  if (value === undefined || value === null) {
    value = DEFAULT_APP_SETTINGS[options.type];
  }
  return (
    <InputNumber min={0} max={365} defaultValue={value} onChange={change => updateSetting(options.type, change)} />
  );
};

export const TrailersOption = ({ updateSetting, checkboxGroupvalue, checkboxValue, checkboxTitle, type }) => {
  return (
    <Checkbox.Group
      className='display-trailer-checkbox-group'
      options={[
        {
          label: checkboxTitle,
          value: checkboxValue
        }
      ]}
      value={checkboxGroupvalue}
      onChange={value => updateSetting(type, value)}
    />
  );
};

export const Warning = ({ warning, formatMessage }) => {
  if (!warning) return <div />;
  const { defaultWarningString, defaultOptionString, emailNotificationString, extraInformation } = warning;
  return (
    <div className='description'>
      <div key={`warning-${Math.random()}`} className='description-item'>
        <ExclamationCircleOutlined className='exclamation-circle-icon' />
        <div>
          {defaultWarningString && (
            <div>
              {formatMessage(defaultWarningString)}:{' '}
              {typeof defaultOptionString === 'string' ? defaultOptionString : formatMessage(defaultOptionString)}
            </div>
          )}
          {emailNotificationString && <div>{formatMessage(emailNotificationString)}</div>}
          {extraInformation && <div>{formatMessage(extraInformation)}</div>}
        </div>
      </div>
    </div>
  );
};
