import React from 'react';
import { Checkbox } from 'antd';
import * as strings from '../../../../helpers/defaultStrings';
import getNotifData from './getREnotifData';
import searchColumn from '../shared/searchColumn';

export const getNotifRowData = (notificationRows, formatMessage) => {
  const notificationData = getNotifData(formatMessage);
  const notificationList = notificationRows.map(notifKey => {
    return notificationData[notifKey];
  });
  return notificationList;
};

export const getNotifColumns = (onToggleEmail, formatMessage, searchInput, handleSearch, handleResetSearch) => {
  return [
    {
      title: formatMessage(strings.short.notification),
      searchKey: formatMessage(strings.short.notification),
      dataIndex: 'description',
      key: 'description',
      ...searchColumn('description', searchInput, handleSearch, handleResetSearch)
    },
    {
      title: formatMessage(strings.short.frequency),
      dataIndex: 'frequency',
      key: 'frequency',
      width: 220
    },
    {
      title: <span>{formatMessage(strings.short.email)}</span>,
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Checkbox
          checked={record.isEmailActive}
          onChange={() => onToggleEmail(record.key, !record.isEmailActive)}
        ></Checkbox>
      )
    }
  ];
};
