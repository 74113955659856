import React from 'react';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { vehicleTypeMapping } from '../../../../../../actions/ActionResources/constants';
import { isNullOrUndefined } from '../../../../../../helpers/functions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, formatMessage }) => {
  const trailerType = vehicleTypeMapping[rowData?.trailerType];
  if (trailerType) {
    return formatMessage(strings.type[trailerType]);
  }

  return <NoValueSpan />;
};

const TrailerTypeColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    trailers.filter('trailerType', t => {
      if (isNullOrUndefined(t?.trailerType)) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.metadata.odr_trailer_type];
    });
  };

  return {
    title: (
      <Tooltip title={formatMessage(strings.short.trailerType)}>{formatMessage(strings.short.trailerType)}</Tooltip>
    ),
    key: 'trailerType',
    dataIndex: 'trailerType',
    searchKey: formatMessage(strings.short.trailerType),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    categories: Object.keys(vehicleTypeMapping).map(key => ({
      value: key,
      text: formatMessage(strings.type[vehicleTypeMapping[key]])
    })),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('trailerType'),
    sorter: () =>
      trailers.sort('trailerType', (a, b) => {
        const typeA = a?.trailerType;
        const typeB = b?.trailerType;
        if (isNullOrUndefined(typeA)) return 1;
        if (isNullOrUndefined(typeB)) return -1;
        if (typeA === typeB) return 0;
        if (typeA > typeB) return -1;
        return 1;
      }),
    width: 150
  };
};

const TrailerTypeCSVColumnHeader = formatMessage => formatMessage(strings.short.trailerType);
const TrailerTypeCSVData = (trailer, formatMessage) => {
  const trailerType = vehicleTypeMapping[trailer?.trailerType];
  if (trailerType) {
    return formatMessage(strings.type[trailerType]);
  }

  return '';
};
const TrailerTypeCSVColumn = {
  header: TrailerTypeCSVColumnHeader,
  data: TrailerTypeCSVData
};

export default { table: TrailerTypeColumn, csv: TrailerTypeCSVColumn };
