import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import LocaleString from '../Utils/LocaleString';
import { convertInt, getUnitString, unitType } from '../../helpers/unitConverter';
import { livemapDatepickerBubbles, API_NAMES } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isUsaCustomer } from '../../helpers/functions';
import { selectRegion, getSelectedTnTTrailerAssetId } from '../../selectors';
import { worker } from '../../app/web-workers/aws-web-worker';

const TrailerInfoPanelHeaderCalendar = ({ setPickerOpen, pickerOpen, onDataPickerOk, intl: { formatMessage } }) => {
  const [currentMonthDateTime, setCurrentMonthDateTime] = useState(moment().format('YYYY-MM-DD'));
  const [trailerAnalytics, setTrailerAnalytics] = useState({});
  const [loadedMonths, setLoadedMonths] = useState({});
  const [loading, setLoading] = useState(true);
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const selectedTnTTrailerAssetId = useSelector(getSelectedTnTTrailerAssetId);

  const isUS = isUsaCustomer(region);

  // if the datepicker is open and you change the trailer by clicking in the map, we must reset the values
  useEffect(() => {
    setLoadedMonths({});
    setTrailerAnalytics({});
    setCurrentMonthDateTime(moment().format('YYYY-MM-DD'));
  }, [selectedTnTTrailerAssetId]);

  useEffect(() => {
    const dateTime = moment(currentMonthDateTime);

    if (pickerOpen && !loadedMonths[dateTime.format('YYYYMM')]) {
      loadedMonths[dateTime.format('YYYYMM')] = 1;
      setLoadedMonths(loadedMonths);
      setLoading(true);

      let endDate = dateTime.utc().endOf('month').endOf('day').toISOString();

      // if current month, endDate = yesterday
      if (dateTime.format('MMYYYY') === moment().format('MMYYYY')) {
        endDate = moment().utc().subtract(1, 'day').endOf('day').toISOString();
      }

      const body = {
        from: dateTime.utc().startOf('month').startOf('day').toISOString(),
        to: endDate,
        lod: 'day'
      };

      worker
        .Api({
          method: 'post',
          api: API_NAMES.ODR_LIVE_MAP,
          endpoint: `analytics/asset/${selectedTnTTrailerAssetId}`,
          body
        })
        .then(trailerAnalyticsData => {
          if (trailerAnalyticsData.data) {
            const reduced = trailerAnalyticsData.data.reduce((acc, currentVal) => {
              acc[currentVal.time] = currentVal.dist || 0;
              return acc;
            }, {});
            setTrailerAnalytics({ ...reduced, ...trailerAnalytics });
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [pickerOpen, selectedTnTTrailerAssetId, currentMonthDateTime]);

  // pre-defined rules base on the task TP-51538
  const { minDistance, maxDistance, percentOpacity, percentSize } = livemapDatepickerBubbles;

  return (
    <DatePicker
      className={'trailer-info-panel__header__datepicker'}
      showTime={isUS ? { user12hours: true } : true}
      format={isUS ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD HH:mm:ss'}
      placeholder='Select Time'
      open={pickerOpen}
      onOk={onDataPickerOk}
      renderExtraFooter={() => (
        <>
          {loading && (
            <div className={'trailer-info-panel__header__datepicker__legend`__loading'}>
              <LoadingOutlined className='large-margin-left' /> <LocaleString type='short' id={'loadingIndictor'} />
            </div>
          )}
          {!loading && (
            <>
              <span>
                <span className={'trailer-info-panel__header__datepicker__legend__bubble max'}></span> &gt;
                {formatMessage(getUnitString(unitType.distance, isMetric), {
                  value: convertInt(isMetric, unitType.distance, 600)
                })}
              </span>
              <span>
                <span className={'trailer-info-panel__header__datepicker__legend__bubble mid'}></span>
                {formatMessage(getUnitString(unitType.distance, isMetric), {
                  value: convertInt(isMetric, unitType.distance, 300)
                })}
              </span>
              <span>
                <span className={'trailer-info-panel__header__datepicker__legend__bubble min'}></span>
                {formatMessage(getUnitString(unitType.distance, isMetric), {
                  value: convertInt(isMetric, unitType.distance, 10)
                })}
              </span>
            </>
          )}
        </>
      )}
      dateRender={current => {
        /**
         * there is no event to get the visible month in the open Datepicker,
         * So, in order to do that, when we render the days elements, I am using the middle date (middle of the month)
         * to get the current visible month and use it to fetch the bubbles for the visible month
         */
        if (current.date() === 15) {
          setCurrentMonthDateTime(current.format('YYYY-MM-DD'));
        }

        const date = current.format('YYYYMMDD');

        const bubbleStyle = {};

        let distance = parseInt(trailerAnalytics[date] || 0);
        if (distance) {
          // anything > 600 should be grouped on 600
          distance = (distance > maxDistance ? maxDistance : distance) - minDistance; // it starts on 10, so lets make it relative from 0 to 590 instead of 10 to 600

          // generate bubble relative to the distance
          // opacity from 24% to 48% - range from (0 to 24) + 24
          // size from 8px to 24px - range from (0 to 16) + 8
          const bubbleOpacity = percentOpacity * distance + 24;
          const bubbleSize = percentSize * distance + 8;

          bubbleStyle.width = bubbleSize;
          bubbleStyle.height = bubbleSize;
          bubbleStyle.opacity = bubbleOpacity / 100;
          bubbleStyle.marginRight = (bubbleStyle.width / 2) * -1;
          bubbleStyle.marginTop = (bubbleStyle.height / 2) * -1;
        }
        return (
          <div
            className='trailer-info-panel__header__datepicker__date ant-picker-cell-inner'
            style={{ position: 'relative' }}
            aria-selected='false'
            aria-disabled='false'
          >
            {current.date()}
            {distance > 0 && (
              <span className={'trailer-info-panel__header__datepicker__date__bubble'} style={bubbleStyle}></span>
            )}
          </div>
        );
      }}
      onCancel={() => setPickerOpen(!pickerOpen)}
      disabledDate={current => current && (current > moment() || current < moment().subtract(1, 'year'))}
    />
  );
};

export default injectIntl(TrailerInfoPanelHeaderCalendar);
