import { defineMessages } from 'react-intl';

export const eventLabel = defineMessages({
  diagnosticModulesEvents: {
    id: 'eventLabel.diagnosticModulesEvents',
    defaultMessage: 'Diagnostic (Modules - Events)',
    description: ''
  },
  abs: {
    id: 'eventLabel.abs',
    defaultMessage: 'ABS',
    description: 'ABS - Anti-lock Braking System'
  },
  absControlCritical: {
    id: 'eventLabel.absControlCritical',
    defaultMessage: 'ABS control critical',
    description: 'ABS - Anti-lock Braking System'
  },
  antiSnaking: {
    id: 'eventLabel.antiSnaking',
    defaultMessage: 'Anti-snaking',
    description: ''
  },
  collision: {
    id: 'eventLabel.collision',
    defaultMessage: 'Collision',
    description: ''
  },
  diagnosticErrorMessage: {
    id: 'eventLabel.diagnosticErrorMessage',
    defaultMessage: 'Diagnostic/error message',
    description: ''
  },
  driveDetectionMemoryBit: {
    id: 'eventLabel.driveDetectionMemoryBit',
    defaultMessage: 'Driving detection memory bit',
    description: ''
  },
  ecasBadRoadDetection: {
    id: 'eventLabel.ecasBadRoadDetection',
    defaultMessage: 'ECAS - Bad road detection',
    description: 'ECAS is a name dont translate'
  },
  ecasEmergencyMode: {
    id: 'eventLabel.ecasEmergencyMode',
    defaultMessage: 'ECAS - Emergency mode',
    description: 'Name do not translate'
  },
  ecasOutsideRequestedLevel: {
    id: 'eventLabel.ecasOutsideRequestedLevel',
    defaultMessage: 'ECAS - Outside requested level',
    description: 'It s a name do not translate'
  },
  ecasPlausibilityErrorFrontAxle: {
    id: 'eventLabel.ecasPlausibilityErrorFrontAxle',
    defaultMessage: 'ECAS plausibility error front axle',
    description: 'Name dont translate'
  },
  ecasPlausibilityErrorRearAxle: {
    id: 'eventLabel.ecasPlausibilityErrorRearAxle',
    defaultMessage: 'ECAS plausibility error rear axle',
    description: 'ECAS is a name do not translate'
  },
  emergencyBrakeAlert: {
    id: 'eventLabel.emergencyBrakeAlert',
    defaultMessage: 'Emergency brake alert',
    description: ''
  },
  epbDeactivatedByUser: {
    id: 'eventLabel.epbDeactivatedByUser',
    defaultMessage: 'EPB - Deactivated by user',
    description: 'EPB is a name do not translate'
  },
  error: {
    id: 'eventLabel.error',
    defaultMessage: 'Error',
    description: ''
  },
  fingerPrintHistory: {
    id: 'eventLabel.fingerPrintHistory',
    defaultMessage: 'Fingerprint history',
    description: ''
  },
  gio1: {
    id: 'eventLabel.gio1',
    defaultMessage: 'GIO 1 (Jedermann analog)',
    description: 'GIO is a name do not translate'
  },
  gio2: {
    id: 'eventLabel.gio2',
    defaultMessage: 'GIO 2 (Jedermann digital)',
    description: 'GIO is a name do not translate'
  },
  gioFcf1: {
    id: 'eventLabel.gioFcf1',
    defaultMessage: 'GIO FCF 1',
    description: 'GIO is a name do not translate'
  },
  gioFcf2: {
    id: 'eventLabel.gioFcf2',
    defaultMessage: 'GIO FCF 2',
    description: 'GIO is a name do not translate'
  },
  gioFcf3: {
    id: 'eventLabel.gioFcf3',
    defaultMessage: 'GIO FCF 3',
    description: 'GIO is a name do not translate'
  },
  gioFcf4: {
    id: 'eventLabel.gioFcf4',
    defaultMessage: 'GIO FCF 4',
    description: 'GIO is a name do not translate'
  },
  gioFcf5: {
    id: 'eventLabel.gioFcf5',
    defaultMessage: 'GIO FCF 5',
    description: 'GIO is a name do not translate'
  },
  gioFcf6: {
    id: 'eventLabel.gioFcf6',
    defaultMessage: 'GIO FCF 6',
    description: 'GIO is a name do not translate'
  },
  gioFcf7: {
    id: 'eventLabel.gioFcf7',
    defaultMessage: 'GIO FCF 7',
    description: 'GIO is a name do not translate'
  },
  gioFcf8: {
    id: 'eventLabel.gioFcf8',
    defaultMessage: 'GIO FCF 8',
    description: 'GIO is a name do not translate'
  },
  immobiliserLockTrailer: {
    id: 'eventLabel.immobiliserLockTrailer',
    defaultMessage: 'Immobilizer lock trailer',
    description: ''
  },
  immobiliserUnlockTrailer: {
    id: 'eventLabel.immobiliserUnlockTrailer',
    defaultMessage: 'Immobilizer unlock trailer',
    description: ''
  },
  immobiliserUnauthorisedUse: {
    id: 'eventLabel.immobiliserUnauthorisedUse',
    defaultMessage: 'Immobilizer unauthorised use',
    description: ''
  },
  immobiliserWrongPin: {
    id: 'eventLabel.immobiliserWrongPin',
    defaultMessage: 'Immobiliser wrong PIN',
    description: ''
  },
  issPlusAnalog: {
    id: 'eventLabel.issPlusAnalog',
    defaultMessage: 'ISS Plus analog',
    description: ''
  },
  issPlusDigital: {
    id: 'eventLabel.issPlusDigital',
    defaultMessage: 'ISS Plus digital',
    description: ''
  },
  liftAxlePlausibility: {
    id: 'eventLabel.liftAxlePlausibility',
    defaultMessage: 'Lift axle plausibility',
    description: ''
  },
  liftAxlePlausibilityValveDetection: {
    id: 'eventLabel.liftAxlePlausibilityValveDetection',
    defaultMessage: 'Lift axle plausibility - Valve detection',
    description: ''
  },
  liningWear: {
    id: 'eventLabel.liningWear',
    defaultMessage: 'Lining wear',
    description: ''
  },
  lowTyrePressure: {
    id: 'eventLabel.lowTyrePressure',
    defaultMessage: 'Low tire pressure',
    description: ''
  },
  meritorTireInflationSystem: {
    id: 'eventLabel.meritorTireInflationSystem',
    defaultMessage: 'Meritor tire inflation system',
    description: 'Do not translate meritor'
  },
  nd: {
    id: 'eventLabel.nd',
    defaultMessage: 'nd',
    description: ''
  },
  operation24N: {
    id: 'eventLabel.operation24N',
    defaultMessage: '24N operation',
    description: ''
  },
  optiLoad: {
    id: 'eventLabel.optiLoad',
    defaultMessage: 'OptiLoad',
    description: ''
  },
  optiTireLowBattery: {
    id: 'eventLabel.optiTireLowBattery',
    defaultMessage: 'Opti tire low battery',
    description: ''
  },
  optiTireTimeout: {
    id: 'eventLabel.optiTireTimeout',
    defaultMessage: 'Opti tire timeout',
    description: ''
  },
  optiTurn: {
    id: 'eventLabel.optiTurn',
    defaultMessage: 'OptiTurn',
    description: ''
  },
  plausibilityResPressureControl: {
    id: 'eventLabel.plausibilityResPressureControl',
    defaultMessage: 'Plausibility residual pressure control',
    description: ''
  },
  recordedEvents: {
    id: 'eventLabel.recordedEvents',
    defaultMessage: 'Recorded events',
    description: ''
  },
  relativeBrakeDemand: {
    id: 'eventLabel.relativeBrakeDemand',
    defaultMessage: 'Relative brake demand',
    description: ''
  },
  ropSystemDisabled: {
    id: 'eventLabel.ropSystemDisabled',
    defaultMessage: 'ROP system disabled',
    description: 'ROP is a name do not translate'
  },
  rssLevel1: {
    id: 'eventLabel.rssLevel1',
    defaultMessage: 'RSS level 1',
    description: 'RSS - Roll Stability Support'
  },
  rssLevel2: {
    id: 'eventLabel.rssLevel2',
    defaultMessage: 'RSS level 2',
    description: 'RSS - Roll Stability Support'
  },
  scbEvent: {
    id: 'eventLabel.scbEvent',
    defaultMessage: 'SCB event',
    description: 'SCB is a name do not translate'
  },
  standByOperation: {
    id: 'eventLabel.standByOperation',
    defaultMessage: 'Standby operation',
    description: ''
  },
  scbTelematicsControlRequest: {
    id: 'eventLabel.scbTelematicsControlRequest',
    defaultMessage: 'SCB telematics control request',
    description: 'SCB is a name do not translate'
  },
  supplyPressureWarning: {
    id: 'eventLabel.supplyPressureWarning',
    defaultMessage: 'Supply pressure warning',
    description: ''
  },
  tailGuardDeactivated: {
    id: 'eventLabel.tailGuardDeactivated',
    defaultMessage: 'TailGuard deactivated (ELEX)',
    description: 'ELEX is a name do not tranlate'
  },
  tbsBrakeIntervention: {
    id: 'eventLabel.tbsBrakeIntervention',
    defaultMessage: 'TSB - Brake intervention',
    description: 'TBS is a name do not translate'
  },
  tbsDeactivationByUser: {
    id: 'eventLabel.tbsDeactivationByUser',
    defaultMessage: 'TSB - Deactivation by user',
    description: 'TBS is a name do not translate'
  },
  tiltAlert: {
    id: 'eventLabel.tiltAlert',
    defaultMessage: 'Tilt alert',
    description: ''
  },
  tpmCalibrationRequest: {
    id: 'eventLabel.tpmCalibrationRequest',
    defaultMessage: 'TPM calibration request',
    description: 'TPM is a name do not translate'
  },
  trailerDocked: {
    id: 'eventLabel.trailerDocked',
    defaultMessage: 'Trailer docked (ELEX)',
    description: 'ELEX is a name do not translate'
  },
  trailerOverload: {
    id: 'eventLabel.trailerOverload',
    defaultMessage: 'Trailer overload',
    description: ''
  },
  tyrePressureInsufficient: {
    id: 'eventLabel.tyrePressureInsufficient',
    defaultMessage: 'Insufficient tire pressure',
    description: ''
  },
  warningLamp: {
    id: 'eventLabel.warningLamp',
    defaultMessage: 'Warning lamp',
    description: 'lamp is a light'
  },
  wheelEndOverTemp: {
    id: 'eventLabel.wheelEndOverTemp',
    defaultMessage: 'Wheel end over temperature',
    description: ''
  },
  ycSystemDisabled: {
    id: 'eventLabel.ycSystemDisabled',
    defaultMessage: 'YC system disabled',
    description: 'YC is a name do not translate'
  }
});
