import actionTypes from './actionTypes';
import { addErrorMessage } from './messages';
import store from '../app/configureStore.js';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { transformMonthlyAndAssets } from './ActionResources/transformationFunctions';
import { Logger } from '../app/helpers/logger';

const updateDisplayBy = displayBy => ({
  type: actionTypes.CHANGE_DISPLAY_BY_LOADING_EVENTS,
  payload: displayBy
});

function changeFilterAsset(assets) {
  return {
    type: actionTypes.CHANGE_FILTER_ASSET_LOADING_EVENTS,
    payload: assets
  };
}
function changeFilterDates(dates) {
  return {
    type: actionTypes.CHANGE_FILTER_DATES_LOADING_EVENTS,
    payload: dates
  };
}
function changeFilterMonths(dates) {
  return {
    type: actionTypes.CHANGE_FILTER_MONTH_LOADING_EVENTS,
    payload: dates
  };
}
function fetchTrailerLoadingEventsSuccess(events, finished = false) {
  const state = store.getState().trailersLoadingEvents;
  const payload = {
    events: state.loadingEvents.concat(events),
    finished
  };
  if (payload) {
    return {
      type: actionTypes.FETCH_TRAILERS_LOADING_EVENTS,
      payload: payload
    };
  } else {
    return {
      type: null
    };
  }
}

const fetchTrailerLoadingEvents =
  (from = new Date().getTime() - 3600 * 2, to = new Date().getTime(), lastEvaluatedKey = null) =>
  dispatch => {
    const state = store.getState().trailersLoadingEvents;
    if (state.loading) {
      return;
    }
    dispatch({
      type: actionTypes.START_TRAILERS_LOADING_EVENTS
    });
    fetchTrailerLoadingEventsApi(from, to, lastEvaluatedKey, dispatch);
  };

const fetchTrailerLoadingEventsApi = (from, to, lastEvaluatedKey, dispatch) => {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null,
    from: Math.round(from / 1000),
    to: Math.round(to / 1000)
  };
  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailers/loadingEvents', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        fetchTrailerLoadingEventsApi(from, to, res.LastEvaluatedKey, dispatch);
      }
      return dispatch(fetchTrailerLoadingEventsSuccess(res.Items, !res.LastEvaluatedKey));
    })
    .catch(err => {
      Logger.error(err);
      dispatch({
        type: actionTypes.ERROR_TRAILERS_LOADING_EVENTS
      });
      dispatch(addErrorMessage(`Error while retrieving loading events details`));
    });
};

const getGroupedEvents = () => {
  return () => {
    const state = store.getState().trailersLoadingEvents;

    return transformMonthlyAndAssets(state.loadingEvents);
  };
};

export default {
  updateDisplayBy,
  changeFilterDates,
  changeFilterMonths,
  changeFilterAsset,
  fetchTrailerLoadingEvents,
  getGroupedEvents
};
