import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import './Components/Ebpms/EbpmsMap.scss';
import './Components/charts/DayBarChart/DayBarChart.css';
import './Components/charts/ScatterPlotChart/ScatterPlotChart.css';
import App from './app/App';
import { unregister } from './app/registerServiceWorker';
import { isScalar } from './helpers/functions';
import Auth0App from './app/Auth0App';
import { initialize } from './app/web-workers/aws-web-worker';

initialize();
ReactDOM.render(isScalar() ? <Auth0App /> : <App />, document.getElementById('root'));
unregister();
