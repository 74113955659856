import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { DrawRectangleMode } from 'nebula.gl';

const BoxSelectionLayer = onSelect => {
  return new EditableGeoJsonLayer({
    id: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    },
    selectedFeatureIndexes: [],
    mode: DrawRectangleMode,
    onEdit: props => {
      if (props.editType === 'addFeature') {
        onSelect(props.updatedData.features[0].geometry.coordinates[0]);
      }
    },
    modeConfig: {
      dragToDraw: true
    }
  });
};

export default BoxSelectionLayer;
