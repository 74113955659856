import imageReorder from '../../assets/images/icon-drag-reorder.png';
import imageForward from '../../assets/images/icon-drag-forward.png';
import imageRewind from '../../assets/images/icon-drag-rewind.png';
import imagePlay from '../../assets/images/icon-drag-play.png';
import Chart from './chart';
import { getTriggers } from '../../helpers/alarms/functions';
import * as d3 from 'd3';
import { triggerEvent } from '../../helpers/googleAnalytics';
import moment from 'moment';

/**
 * Abstract class for a D3 chart.
 */
export default class BaseHistoryCharts extends Chart {
  create() {
    const { region, productFeatures } = this.props;
    this.triggerTypes = getTriggers(region, productFeatures);
    this.svg = super.createRoot();
    this.isDragging = false;
    this.replayTimeout = null;
    this.isReplaying = false;
    this.tooltip = false;
    this.main = this.svg.append('g').attr('class', 'main');

    this.appendReplayImages();

    this.positionLine = this.svg
      .append('line')
      .attr('class', 'position-line')
      .style('stroke-dasharray', '2, 2')
      .style('stroke', '#666')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', this.props.height);

    this.pin = this.svg.append('g').attr('class', 'pin');
    this.pinCircle = this.pin.append('circle').attr('fill', 'url(#image-reorder)');
  }

  appendReplayImages() {
    this.svg
      .append('pattern')
      .attr('id', 'image-reorder')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .attr('xlink:href', imageReorder);
    this.svg
      .append('pattern')
      .attr('id', 'image-forward')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .attr('xlink:href', imageForward);
    this.svg
      .append('pattern')
      .attr('id', 'image-rewind')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .attr('xlink:href', imageRewind);
    this.svg
      .append('pattern')
      .attr('id', 'image-play')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', 16)
      .attr('width', 16)
      .attr('xlink:href', imagePlay);
  }

  replay(data) {
    // commented for now as it will be discussed next sprint if we are going to use the animated replay
    // if (data.length > 0) {
    //
    //   this.isReplaying = true;
    //   const eventData = data[0]
    //   this.onPosChange({ ...eventData, posX: this.x(eventData.time) });
    //
    //   this.replayTimeout = setTimeout(() => {
    //     this.replay(data.slice(10));
    //   }, 1)
    // } else {
    //   this.isReplaying = false;
    //   this.onPosChange({ ...data[data.length - 1], posX: this.props.width });
    // }
    this.onPosChange({ ...data[data.length - 1], posX: this.props.width });
  }

  dragStarted() {
    this.isDragging = true;
    clearTimeout(this.replayTimeout);
  }

  dragged() {
    const posX = Math.max(0, Math.min(d3.event.x, this.props.width));

    this.positionLine.attr('x1', posX).attr('x2', posX);
    this.pinCircle.attr('cx', posX);
    const time = this.x.invert(posX);
    this.lastTimePos = time;
    const currentIndex = this.data.findIndex(d => d.time > time);
    if (this.onPosChange && currentIndex > 0) {
      this.onPosChange({ ...this.data[currentIndex - 1], time, posX }, undefined);
    } else {
      if (d3.event.x > this.props.width) {
        // return the last position
        this.onPosChange({ ...this.data[this.data.length - 1], time, lastPos: true, posX }, undefined);
      } else {
        this.onPosChange({ time, posX }, undefined);
      }
    }
  }

  dragEnded() {
    this.isDragging = false;
    triggerEvent('Map', 'Dragged History Chart pointer', 'Live Map screen');

    if (!this.isDragging && this.historyPosition.posX < this.props.width) {
      this.pinCircle.attr('fill', 'url(#image-play)');
    }
  }

  drawPin(state) {
    let pinPosition = state && state.pos ? state.pos.posX : 0;
    if (!pinPosition && this.x && state.pos && state.pos.time) {
      pinPosition = this.x(state.pos.time);
    }

    this.positionLine.attr('x1', pinPosition).attr('x2', pinPosition);

    if (this.isReplaying) {
      this.pinCircle.attr('fill', 'url(#image-forward)');
    } else if (!this.isDragging) {
      if (this.historyPosition && this.historyPosition.posX < this.props.width) {
        this.pinCircle.attr('fill', 'url(#image-play)');
      } else {
        this.pinCircle.attr('fill', 'url(#image-reorder)');
      }
    }

    this.pinCircle
      .attr('r', 8)
      .attr('cx', pinPosition)
      .attr('cy', this.props.height)
      .call(
        d3
          .drag()
          .on('start', () => this.dragStarted(this))
          .on('drag', () => this.dragged(this, this))
          .on('end', () => this.dragEnded(this))
      )
      .on('mouseover', function () {
        d3.select(this).style('cursor', 'pointer');
      })
      .on('click', () => {
        this.replay(
          this.data.filter(
            d => moment(d.time).toDate().getTime() >= moment(this.historyPosition.time).toDate().getTime()
          )
        );
      });
  }
}
