import circleToPolygon from './circleToPolygon';
import { placesVariables } from '../../../../helpers/alarms/functions';

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
const MAPBOX_API_KEY = REACT_APP_MAPBOX_ACCESS_TOKEN;

const baseMarkerUrls = 'https%3A%2F%2Ftx-trailerfit.integration.wabco.aloalto.com%2FalarmIcons%2FplacesMarkers%2F';

export const buildGeoJson = (latitude, longitude, geofences) => {
  const obj = {
    type: 'FeatureCollection',
    features: []
  };
  if (Array.isArray(geofences)) {
    obj.features = obj.features.concat(
      geofences.map(geofence => {
        let feature = {};
        let coordinates = geofence?.geofenceDefinition?.geometry?.coordinates
          ?.flat()
          .map(coord => [parseFloat(parseFloat(coord[0]).toFixed(4)), parseFloat(parseFloat(coord[1]).toFixed(4))]);

        const radius = geofence?.geofenceDefinition?.properties?.radius?.size;
        if (radius > 0) {
          coordinates = circleToPolygon(geofence?.geofenceDefinition?.geometry?.coordinates, radius, {
            numberOfEdges: 128
          }).coordinates[0];
          coordinates = coordinates.map(coord => [
            parseFloat(parseFloat(coord[0]).toFixed(4)),
            parseFloat(parseFloat(coord[1]).toFixed(4))
          ]);
        }
        feature = {
          type: 'Feature',
          properties: {
            stroke: placesVariables[geofence.type]?.colourHex || '#000',
            fill: placesVariables[geofence.type]?.colourHex || '#000',
            'fill-opacity': 0.2,
            'stroke-width': 3
          },
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        };
        return feature;
      })
    );
  }
  obj.features.push({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(parseFloat(longitude).toFixed(4)), parseFloat(parseFloat(latitude).toFixed(4))]
    }
  });

  return JSON.stringify(obj);
};
export const addImageToStaticMap = geofences => {
  const placesLatLong = geofences?.filter(geofence => geofence.address?.latitude && geofence.address?.longitude);

  if (Array.isArray(placesLatLong) && placesLatLong.length > 0) {
    const place = placesLatLong[0];
    return `,url-${baseMarkerUrls}${place.type}.png(${place.address.longitude},${place.address.latitude})`;
  }
  return '';
};
export const getPlacesMapBoxStaticImage = alarm => {
  const latitude = alarm?.gnss?.latitude;
  const longitude = alarm?.gnss?.longitude;
  const geofences = alarm?.geofences_details?.geofences;
  const mapboxBaseUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson`;
  const rawUrl = `${mapboxBaseUrl}(${buildGeoJson(
    latitude,
    longitude,
    geofences
  )}),url_placeholder/${longitude},${latitude},12/500x300?access_token=${MAPBOX_API_KEY}`;
  let url = encodeURI(rawUrl);
  url = url.replace(',url_placeholder', addImageToStaticMap(geofences));
  url = url.replace(/#/g, '%23');
  const validImage = fetch(url).then(res => {
    return res.ok;
  });
  if (validImage) {
    return url;
  } else {
    return false;
  }
};
