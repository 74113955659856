import { tabOrModalView } from '../../../../helpers/googleAnalytics';
import paths from '../../../../app/paths';
import { convertInt } from '../../../../helpers/unitConverter';
import * as strings from '../../../../helpers/defaultStrings';
import { PAGE_TABS, PAGE_SIZE, defaultDates } from './constants';
import { defaultAvatar } from '../../../Notifications/NotificationPanel';
import { getPlaceCategoryString, getTimeDate } from '../../../../helpers/functions';

import moment from 'moment';

export const refreshList = (getAlerts, dates) => {
  getAlerts(dates);
};

export const onChangeTab = (tabKey, history) => {
  history.push(`${paths.notifications}/${tabKey}`);

  switch (tabKey) {
    case PAGE_TABS.subscriptionSettings:
      tabOrModalView('configure');
      break;
    case PAGE_TABS.default:
    default:
      tabOrModalView('history');
      break;
  }
};

export const getSelectedEvent = event => ({
  ...event,
  GPSPosition_latitude: event.latitude,
  GPSPosition_longitude: event.longitude,
  timestamp: event.datetime,
  VehicleSpeed: event.speed,
  notification: true,
  eventNameId: event.trailer,
  display: event.description,
  description: null
});

export const getDropDownTrailers = alerts => {
  const dropDownTrailers = [];
  alerts?.forEach(alert => {
    if (!dropDownTrailers.includes(alert.trailer)) {
      dropDownTrailers.push(alert.trailer);
    }
  });
  return dropDownTrailers;
};

export const getHeaders = isMetric => {
  return [
    { label: 'Trailer', key: 'trailer' },
    { label: 'Alarm Type', key: 'type' },
    { label: 'Description', key: 'description' },
    { label: 'Date', key: 'datetimeFormatted' },
    {
      label: isMetric && isMetric.distance ? 'Speed (km/h)' : 'Speed (mph)',
      key: isMetric && isMetric.distance ? 'speed' : 'speedMph'
    },
    {
      label: isMetric && isMetric.distance ? 'Altitude (m)' : 'Altitude (ft)',
      key: isMetric && isMetric.distance ? 'alt' : 'altFt'
    },
    { label: 'Longitude', key: 'longitude' },
    { label: 'Latitude', key: 'latitude' }
  ];
};

export const showTotal = (total, range, formatMessage) => {
  const pageNum = Math.ceil(total / PAGE_SIZE);
  if (total === 1) {
    return formatMessage(strings.short.paginationSingleItem);
  } else if (pageNum > 1) {
    return formatMessage(strings.short.paginationPlural, {
      VAR_TOTAL: total,
      VAR_RANGE_1: range[0],
      VAR_RANGE_2: range[1],
      VAR_PAGE: pageNum
    });
  } else {
    return formatMessage(strings.short.paginationSingular, {
      VAR_TOTAL: total,
      VAR_RANGE_1: range[0],
      VAR_RANGE_2: range[1]
    });
  }
};

export const getExportCSVData = (isMetric, filteredAlerts, unitType) => {
  return filteredAlerts.map(alert => {
    alert.speedMph = convertInt(isMetric, unitType.speed, alert.speed);
    alert.altFt = convertInt(isMetric, unitType.meterFeet, alert.alt);
    return alert;
  });
};

export const updateAlerts = (alerts, formatMessage, devices, places, triggerTypes) => {
  //cut out any non geofence related alerts from NotificationCentre function.
  //If refactoring that page later to reuse these functions then take that one.
  if (!alerts || !alerts.length) {
    return [];
  }
  const latestDevices =
    devices && devices.length > 0 ? Object.assign(...devices.map(device => ({ [device['assetId']]: device }))) : {};

  const updatedAlerts = alerts.map(alert => {
    let title, description;
    const type = alert.alarm;

    let placeData = {};
    const placeId = alert?.geofences_details?.geofences?.[0]?.id;

    if (type === 'geofence') {
      title = '';
      if (placeId) {
        placeData = places.find(place => place.id === placeId) || {};
      }
      alert.isEnteringAlert = alert?.geofences_details?.duration === 0;
      description = formatMessage(
        alert.isEnteringAlert ? strings.geofence.enterDescription : strings.geofence.leavingDescription,
        {
          VAL_NAME: formatMessage(getPlaceCategoryString(placeData))
        }
      );
    }
    return {
      ...alert,
      type,
      alarm: type,
      time: alert.time,
      placeData,
      datetime: alert.time * 1000,
      datetimeFormatted: getTimeDate(alert.time * 1000),
      avatarSVG: triggerTypes[type] ? triggerTypes[type].getAvatarSVG() : defaultAvatar,
      alarmIconClass: 'large alarm-icon',
      description: description,
      title: `${title} ${
        latestDevices && latestDevices[alert.assetId] ? latestDevices[alert.assetId].defaultDisplayName : alert.assetId
      }`,
      trailer:
        latestDevices && latestDevices[alert.assetId] ? latestDevices[alert.assetId].defaultDisplayName : alert.assetId,
      assetId: alert.assetId,
      code: alert._device ? alert._device.name : null,
      speed: alert.gnss.valid ? alert.gnss.speed : null,
      longitude: alert.gnss.valid ? alert.gnss.longitude : null,
      latitude: alert.gnss.valid ? alert.gnss.latitude : null,
      alt: alert.gnss.valid ? alert.gnss.alt : null,
      satellites: alert.gnss.valid ? alert.gnss.satellites : null,
      id: alert.id
    };
  });
  return updatedAlerts.sort((a, b) => (a.datetime - b.datetime < 0 ? 1 : -1));
};
export const updateFilteredAlerts = (setFilteredAlerts, alerts, selectedTypes) => {
  const filteredPlacesAlerts = alerts?.filter(alert => {
    return (
      (selectedTypes.length === 0 && alert.alarm === 'geofence') ||
      selectedTypes.includes(alert.geofences_details?.geofences[0]?.type)
    );
  });
  setFilteredAlerts(filteredPlacesAlerts);
};

export const onDatePickerChange = (selectedDates, setDates, setLastDates, dates, onPageChange) => {
  const currentStartDate = dates[0];
  const currentEndDate = dates[1];

  onPageChange(1);
  if (selectedDates?.length > 0) {
    let startDate = selectedDates[0]?.startOf('day');
    const isEndDateToday = selectedDates[1]?.isSame(moment(), 'd');
    const endDate = isEndDateToday ? moment() : selectedDates[1]?.endOf('day');

    if (currentStartDate !== startDate) {
      if (currentEndDate) {
        setDates([startDate, null]);
        setLastDates(dates);
      }
      setDates([startDate, null]);
      return;
    } else if (endDate > moment(startDate).add(31, 'd')) {
      startDate = moment(endDate).subtract(31, 'd').startOf('day');
    }
    setDates([startDate, endDate]);
  } else {
    setDates(defaultDates);
  }
};

export const selectEvent = (event, updateTnTTrailer, setSelectedEvent, devices) => {
  const _selectedEvent = event ? getSelectedEvent(event) : null;
  const trailer = event ? devices.find(item => item.assetId === event.assetId) : null;
  updateTnTTrailer(trailer);
  setSelectedEvent(_selectedEvent);
};

export const onFilterChange = (newFilterValue, onPageChange, setFilterValue, filterValue) => {
  if (newFilterValue !== filterValue) {
    onPageChange(1);
    setFilterValue(newFilterValue);
  }
};

export const toggleSelectedType = (type, onPageChange, setSelectedTypes, selectedTypes) => {
  onPageChange(1);
  if (selectedTypes.includes(type)) {
    setSelectedTypes(
      selectedTypes.filter(k => k !== type) // remove
    );
  } else {
    setSelectedTypes(
      [...selectedTypes, type] // add
    );
  }
};

export const gotoAlert = (alert, filteredAlerts, setCurrentPage, setScrollTo) => {
  const i = filteredAlerts.findIndex(a => a.id === alert.id);
  const page = Math.ceil((i + 1) / PAGE_SIZE);
  setCurrentPage(page);
  setScrollTo(alert.id);
};

export const resetFilters = setDates => {
  setDates(defaultDates);
};

export const paginationConfig = (showTotal, onPageChange, currentPage, filteredAlerts, formatMessage) => {
  return filteredAlerts.length
    ? {
        position: 'both',
        pageSize: PAGE_SIZE,
        current: currentPage,
        showSizeChanger: false,
        onChange: onPageChange,
        className: 'notification-centre__pagination',
        showTotal: (total, range) => showTotal(total, range, formatMessage)
      }
    : false;
};

export const notifKeyToAlertID = key => {
  const parts = key.split('_');
  return parts[1] + parts[2] + parts[0];
};
export const shouldScroll = (hasDoneInitialScroll, idScrolledTo, id) => {
  if (!hasDoneInitialScroll) {
    return true;
  }
  if (idScrolledTo !== id) {
    return true;
  }
  return false;
};

export const disabledDate = (current, dates) => {
  const startValue = dates[0];
  const endValue = dates[1];

  if (endValue) {
    return current > moment().endOf('day');
  }

  return (
    current > moment().endOf('day') ||
    current > moment(startValue).add(31, 'd') ||
    current < moment(startValue).startOf('day')
  );
};
