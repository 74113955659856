import actionTypes from '../actions/actionTypes';

export const initialState = {
  processing: false,
  error: null,
  viewState: {
    longitude: 3.736,
    latitude: 47.136,
    zoom: 3,
    pitch: 0,
    bearing: 0
  },
  firstShow: true,
  selectedTnTTrailer: null,
  selectedHealthTrailer: null,
  tnTTrailerTablePage: 1,
  trailerDrawerOpen: false,
  filterByAlarm: [],
  filterByDeviceState: []
};

export default function appStateReducer(state = initialState, action) {
  const { callBack } = action;
  if (callBack) {
    callBack();
  }

  switch (action.type) {
    case actionTypes.UPDATE_MAP_VIEW_STATE:
      return {
        ...state,
        viewState: action.viewState,
        firstShow: action.firstShow
      };
    case actionTypes.UPDATE_TRAILER_DRAWER_STATE:
      return {
        ...state,
        trailerDrawerOpen: action.trailerDrawerOpen
      };
    case actionTypes.UPDATE_SELECTED_TNT_TRAILER:
      return {
        ...state,
        selectedTnTTrailer: action.selectedTnTTrailer
      };
    case actionTypes.UPDATE_SELECTED_HEALTH_TRAILER:
      return {
        ...state,
        selectedHealthTrailer: action.selectedHealthTrailer
      };
    case actionTypes.UPDATE_TNT_TRAILER_TABLE_PAGE:
      return {
        ...state,
        tnTTrailerTablePage: action.tnTTrailerTablePage
      };
    default:
      return state;
  }
}
