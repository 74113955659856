/* eslint-disable max-len */
import { CompositeLayer } from '@deck.gl/core';
import { TextLayer } from '@deck.gl/layers';
import { scaleQuantile } from 'd3-scale';
import { characterSet } from './characterSet';
import Supercluster from 'supercluster';

const DEFAULT_COLOR_SCHEME = [
  [29, 145, 192],
  [65, 182, 196],
  [127, 205, 187],
  [199, 233, 180],
  [237, 248, 177]
];

const defaultProps = {
  getLabel: x => x.label,
  getWeight: x => x.weight || 1,
  getPosition: x => x.position,
  colorScheme: DEFAULT_COLOR_SCHEME,
  minFontSize: 14,
  maxFontSize: 32,
  weightThreshold: 1
};

export default class TagmapLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    super.updateState({ props, oldProps, changeFlags });
    if (props.colorScheme !== oldProps.colorScheme) {
      // set color scheme
      const colorScale = scaleQuantile().domain([props.minFontSize, props.maxFontSize]).range(props.colorScheme);
      this.setState({ colorScale });
    }

    const rebuildIndex = changeFlags.dataChanged;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 15, radius: 40 });
      index.load(
        props.data.map(d => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      const { index } = this.state;
      const clusters = index.getClusters([-180, -85, 180, 85], z);
      const newData = clusters.map(({ id, properties }) => {
        if (id) {
          return index.getLeaves(id, 1)[0].properties;
        }
        return properties;
      });
      this.setState({ data: newData });
    }
  }

  renderLayers() {
    const { data } = this.state;

    return [
      new TextLayer({
        id: 'tagmap-layer-halo',
        data,
        getText: d => d.label,
        getPosition: d => d.position,
        getColor: () => [255, 255, 255, 255], //colorScale(d.height),
        //getSize: d => d.height
        getSize: 18,
        getTextAnchor: 'start',
        getPixelOffset: [25, 0],
        getAlignmentBaseline: 'center',
        characterSet,
        fontSettings: {
          sdf: true,
          radius: 5
        },
        fontWeight: 'bold',
        fontFamily: 'Gill Sans Extrabold, sans-serif'
      }),
      new TextLayer({
        id: 'tagmap-layer-main',
        data,
        getText: d => d.label,
        getPosition: d => d.position,
        getColor: () => [0, 0, 0, 255], //colorScale(d.height),
        //getSize: d => d.height
        getSize: 18,
        getTextAnchor: 'start',
        getPixelOffset: [25, 0],
        getAlignmentBaseline: 'center',
        fontWeight: 'bold',
        fontFamily: 'Gill Sans Extrabold, sans-serif',
        characterSet
      })
    ];
  }
}

TagmapLayer.layerName = 'TagmapLayer';
TagmapLayer.defaultProps = defaultProps;
