import React, { memo } from 'react';
import './OdyTextButton.scss';

const OdyTextButton = ({ icon, onClick, children, className, disabled, isPrimary = false }) => {
  let cssClass = `ody-text-btn ${className}`;
  let onClickAction = onClick;

  if (disabled) {
    cssClass += ' disabled';
    onClickAction = undefined;
  }
  return (
    <button onClick={onClickAction} className={`${cssClass} ${isPrimary && 'primary-btn'}`}>
      {icon && <span className='ody-btn-icon'>{icon}</span>}
      {children}
    </button>
  );
};

export default memo(OdyTextButton);
