import React, { useState, memo, useEffect } from 'react';
import { Resizable } from 'react-resizable';
import { SearchHeader } from './headers/SearchHeader';
import { CategoryHeader } from './headers/CategoryHeader';
import { InfoHeader } from './headers/InfoHeader';
import './VirtualTable.scss';
import useDebounce from '../../customHooks/useDebounce';
import { INFO_HEADER_COLUMNS } from '../../helpers/constants';
import { SortBtn } from './headers/SortBtn';
import { sendCustomEvent } from '../../app/helpers/new-relic';
import { useSelector } from 'react-redux';
import { selectUser } from '../../selectors';

const DEFAULT_CELL_WIDTH = 170;
const RenderRow = (row, columns, columnWidths, lockedColumns = {}) => {
  let fixedColPosition = 0;
  return columns.reduce((acc, col) => {
    const tdWidth = columnWidths[col.key];
    const cellContent = (
      <td
        key={col.key}
        style={{ width: tdWidth, left: fixedColPosition }}
        className={lockedColumns[col.key] ? 'fixed-column' : ''}
      >
        {col.render ? col.render(row) : row[col.dataIndex]}
      </td>
    );
    if (lockedColumns[col.key]) {
      fixedColPosition = fixedColPosition + tdWidth;
    }
    return [...acc, cellContent];
  }, []);
};

const RenderSortableHeader = ({ column, sortName }) => {
  const sortProps = {
    colKey: column.key,
    sorter: column.sorter,
    sortedUp: column.key === sortName,
    sortedDown: column.key + 'reversed' === sortName
  };

  return (
    <>
      <span className='th-vtable-title'>{column.title}</span>
      <SortBtn {...sortProps} />
    </>
  );
};

const RenderSearchHeader = ({ colWidth, column, sortName }) => {
  return (
    <SearchHeader
      colWidth={colWidth}
      search={column.search}
      removeSearch={column.removeSearch}
      sorter={column.sorter}
      sortedUp={column.key === sortName}
      sortedDown={column.key + 'reversed' === sortName}
      colKey={column.key}
    >
      {column.title}
    </SearchHeader>
  );
};

const RenderCategoryHeader = ({ column, sortName }) => {
  return (
    <CategoryHeader
      colKey={column.key}
      categories={column.categories}
      onFilter={column.onFilter}
      removeFilter={column.removeFilter}
      sorter={column.sorter}
      sortedUp={column.key === sortName}
      sortedDown={column.key + 'reversed' === sortName}
    >
      {column.title}
    </CategoryHeader>
  );
};

const WrapInfoHeader = ({ sibling, hasSibling, isMetric, trailers, columnKey }) => {
  const props = { key: columnKey, columnKey, isMetric, trailers, hasSibling };
  return (
    <>
      {sibling}
      <InfoHeader {...props} />
    </>
  );
};

const RenderHeader = ({ column, onResize, colWidth, sortName, isMetric, devices }) => {
  const headerStyle = { width: colWidth, left: column.leftPosition };
  const columnClasses = column.locked ? 'fixed-column' : '';
  const hasInfo = INFO_HEADER_COLUMNS.includes(column.key);
  const thProps = {
    key: column.key,
    style: headerStyle,
    className: columnClasses,
    ...(column.sorter ? { onClick: column.sorter } : null)
  };

  // exclusive column headers: search, categories or sorter
  // optional column headers: info
  const exclusiveHeadersMap = {
    search: {
      test: () => column.search,
      render: () => <RenderSearchHeader key={'search'} colWidth={colWidth} column={column} sortName={sortName} />
    },
    categories: {
      test: () => column.categories?.length > 0,
      render: () => <RenderCategoryHeader key={'categories'} column={column} sortName={sortName} />
    },
    sorter: {
      test: () => column.sorter,
      render: () => <RenderSortableHeader key={'sorter'} column={column} sortName={sortName} />
    }
  };

  const { title } = column;
  const header = Object.values(exclusiveHeadersMap)
    .find(api => api.test())
    ?.render();
  const infoHeaderProps = {
    isMetric,
    devices,
    columnKey: column.key,
    sibling: header ? header : title,
    hasSibling: Boolean(header)
  };

  const thContent = hasInfo ? <WrapInfoHeader {...infoHeaderProps} /> : header ?? title;
  const headerContent = <th {...thProps}>{thContent}</th>;

  return (
    <Resizable
      key={column.key}
      width={colWidth}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      {headerContent}
    </Resizable>
  );
};

export const VirtualTable = ({
  isMetric,
  devices,
  data,
  columns,
  tableHeight,
  rowHeight,
  sortName,
  onClickRow,
  keyExtractor,
  onColumnsSizeChange,
  lockedColumns = {}
}) => {
  const baseColumnWidths = columns.reduce((a, c) => {
    return { ...a, [c.key]: c.width ? c.width : DEFAULT_CELL_WIDTH };
  }, {});
  const user = useSelector(selectUser);
  const [scrollTop, setScrollTop] = useState(0);
  const [initialTableXPosition, setInitialTableXPosition] = useState(0);
  const [columnWidths, setColumnWidths] = useState(baseColumnWidths);
  const [analyticsColumnWidth, setAnalyticsColumnWidth] = useState(null);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const innerHeight = data.length * rowHeight;
  const overDrawn = 3;
  // update external column size settings on column resize:
  useDebounce(
    () => {
      if (onColumnsSizeChange) onColumnsSizeChange(columnWidths);
      if (analyticsColumnWidth) {
        sendCustomEvent(analyticsColumnWidth, { identity: user.identity });
      }
    },
    450,
    [columnWidths]
  );
  // update internal column size on external column size change:
  useEffect(() => {
    let isOffSync = false;
    const newColumnWidths = {};
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      newColumnWidths[column.key] = column.width || DEFAULT_CELL_WIDTH;
      if (newColumnWidths[column.key] !== columnWidths[column.key]) {
        isOffSync = true;
      }
    }
    if (isOffSync) {
      setColumnWidths(newColumnWidths);
    }
    setInitialTableXPosition(document.getElementsByClassName('virtual-table-head')[0].getBoundingClientRect().x);
    if (lockedColumns) {
      calcHeaderPositions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  if (data == null) return null;
  if (columns == null) return null;

  const onResize =
    key =>
    (e, { size }) => {
      setAnalyticsColumnWidth(`${key.toUpperCase()}_WIDTH`);
      setColumnWidths({ ...columnWidths, [key]: Math.max(size.width, 100) });
    };

  // indexes to render:
  const startIndex = Math.max(Math.floor(scrollTop / rowHeight) - overDrawn, 0);
  const endIndex = Math.min(
    data.length - 1, // don't render past the end of the list
    Math.floor((scrollTop + tableHeight) / rowHeight) + overDrawn
  );

  const onScroll = e => {
    if (
      e.currentTarget.firstChild.getBoundingClientRect().x !== initialTableXPosition &&
      !e.currentTarget.classList.contains('scrolled-x-table')
    ) {
      e.currentTarget.classList.add('scrolled-x-table');
    } else if (e.currentTarget.firstChild.getBoundingClientRect().x === initialTableXPosition) {
      e.currentTarget.classList.remove('scrolled-x-table');
    }
    if (Math.abs(scrollTop - e.currentTarget.scrollTop) > rowHeight * 3) {
      setScrollTop(e.currentTarget.scrollTop);
    }
  };
  let itemsToRender = [];
  for (let i = startIndex; i <= endIndex; i++) {
    const item = data[i];
    const rowStyle = {
      position: 'absolute',
      left: 0,
      top: i * rowHeight,
      height: rowHeight,
      lineHeight: `${rowHeight}px`
    };
    if (onClickRow) {
      itemsToRender.push(
        <tr key={keyExtractor(item)} style={rowStyle} onClick={() => onClickRow(item)} className='cursor-pointer'>
          {RenderRow(item, columns, columnWidths, lockedColumns)}
        </tr>
      );
    } else {
      itemsToRender.push(
        <tr key={keyExtractor(item)} style={rowStyle}>
          {RenderRow(item, columns, columnWidths, lockedColumns)}
        </tr>
      );
    }
  }
  const calcHeaderPositions = () => {
    let leftPosition = 0;
    const headers = columns.map(x => {
      const lockedHeader = {
        ...x,
        locked: lockedColumns[x.key],
        leftPosition: lockedColumns[x.key] ? leftPosition : 0
      };
      if (lockedColumns[x.key]) {
        leftPosition = leftPosition + columnWidths[x.key];
      }
      return lockedHeader;
    });
    setColumnHeaders(headers);
  };

  return (
    <div className='virtual-table' onScroll={onScroll}>
      <div className='virtual-table-head'>
        <table>
          <thead>
            <tr>
              {columnHeaders.map(column => {
                const { key } = column;
                const props = {
                  column,
                  onResize: onResize(key),
                  colWidth: columnWidths[key] || baseColumnWidths[key],
                  sortName,
                  isMetric,
                  devices
                };

                return <RenderHeader key={column.key} {...props} />;
              })}
            </tr>
          </thead>
        </table>
      </div>
      <table>
        <tbody style={{ height: innerHeight }}>{itemsToRender}</tbody>
      </table>
    </div>
  );
};

export default memo(VirtualTable);
