import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Modal } from 'antd';
import * as strings from '../../helpers/defaultStrings';

import './ColumnInfoModal.scss';
import OdyTextButton from '../../ReusableComponents/Buttons/OdyTextButton';
import { sendCustomEvent } from '../../app/helpers/new-relic';
import { useSelector } from 'react-redux';
import { selectUser } from '../../selectors';
import { EVENT_NAMES } from '../../analytics/constants';

const ColumnInfoModal = ({ title, body, hasSibling }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const btnIconClasses = classNames('btn-icon', 'medium-margin-left', {
    'medium-margin-right': hasSibling
  });
  const user = useSelector(selectUser);
  const { formatMessage } = useIntl();

  const sendCustomEventWrapper = name => sendCustomEvent(name, { identity: user.identity });

  const onVisibilityChange = (e, visibility) => {
    setModalVisible(visibility);
    e.preventDefault();
    e.stopPropagation();
  };

  const onOpenModal = e => {
    onVisibilityChange(e, true);
    sendCustomEventWrapper(EVENT_NAMES.INFORMATION_MODAL_OPENED);
  };

  const onCancel = e => {
    onVisibilityChange(e, false);
    if (e.currentTarget?.type === 'button') {
      sendCustomEventWrapper(EVENT_NAMES.INFORMATION_MODAL_CLOSED_X);
    } else {
      sendCustomEventWrapper(EVENT_NAMES.INFORMATION_MODAL_CLOSED_OUTSIDE);
    }
  };

  const onOk = e => {
    onVisibilityChange(e, false);
    sendCustomEventWrapper(EVENT_NAMES.INFORMATION_MODAL_CLOSED_OK);
  };

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={onCancel}
        title={
          <Fragment>
            <span className='medium-margin-right'>
              <InfoCircleOutlined className='title-icon' />
            </span>
            {title}
          </Fragment>
        }
        width={520}
        bodyStyle={{ maxHeight: '60vh', overflowY: 'auto', paddingTop: 0 }}
        maskClosable={true}
        closable={true}
        footer={
          <OdyTextButton onClick={onOk} isPrimary={true}>
            {formatMessage(strings.short.ok)}
          </OdyTextButton>
        }
      >
        {body}
      </Modal>
      <button className={btnIconClasses} onClick={onOpenModal}>
        <InfoCircleOutlined className='column-icon' />
      </button>
    </>
  );
};

export default ColumnInfoModal;
