import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getScales from './d3';
import { Tooltip } from 'antd';
import { AxisX, AxisY } from './chartTools';
import './HistogramChart.scss';

const colors = {
  line: '#1890FF',
  altLine: '#CACACA'
};

const PointText = ({ step, value, asset, yAxisMetric, xAxisMetric, yLabel, xLabel, customScaleValue }) => {
  return (
    <div className='point-text'>
      <div>{asset}</div>
      <div>{`${yLabel}: ${value + (yAxisMetric || '')}`}</div>
      <div>{`${xLabel}: ${(customScaleValue || step) + (xAxisMetric || '')}`}</div>
    </div>
  );
};
const HistogramChart = ({
  histogramData,
  width,
  height,
  chartkey,
  activeAsset,
  xAxisMetric,
  yAxisMetric,
  xLabel,
  yLabel,
  title,
  subTitle,
  noDataWarning,
  customScale,
  stepSize,
  getDeviceName,
  dataTransformer,
  pointTransformer,
  yPointMetric,
  isRelative
}) => {
  const viewBox = { width: width - 80, height: height - 95 };
  const [hdata, setHdata] = useState(histogramData);
  const [dimension] = useState({
    height: viewBox.height,
    width: viewBox.width
  });

  const [xScale, yScale, lines] = useMemo(
    () => getScales({ viewBox, hdata, chartkey, stepSize, customScale, dataTransformer }),
    [viewBox, hdata, chartkey, customScale, stepSize, dataTransformer]
  );

  const selectedAsset = hdata?.find(asset => asset.assetId === activeAsset);

  const hasSelectedData = !!(selectedAsset && selectedAsset[chartkey]);

  useEffect(() => {
    setHdata(histogramData);
  }, [histogramData, setHdata]);

  const roundToTwo = num => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  let activeLine = null;
  let filteredLines = [];

  lines.forEach(line => {
    if (line.asset === activeAsset) {
      activeLine = line;
    } else {
      filteredLines.push(line);
    }
  });

  return (
    <div
      className='histogram-chart'
      style={{
        maxWidth: dimension.width + 80
      }}
    >
      <div>
        <h3>{title}</h3>
        <h5>
          {hasSelectedData && <span>{subTitle}</span>}
          {!hasSelectedData && <i className={'missing-asset'}>{noDataWarning}</i>}
        </h5>
      </div>
      <svg
        width={dimension.width}
        pointerEvents={'none'}
        height={dimension.height}
        style={{ overflow: 'visible' }}
        viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
      >
        {filteredLines.map(asset => {
          return (
            <Tooltip title={getDeviceName(asset.asset)} key={asset.asset + '-tooltip'} overlayClassName='line-tooltip'>
              <path
                pointerEvents={'auto'}
                id={asset.asset}
                d={asset.line}
                key={asset.asset}
                className='asset-path'
                fill='none'
              />
            </Tooltip>
          );
        })}
        {activeLine && (
          <path
            pointerEvents={'auto'}
            id={activeLine.asset + 'active'}
            d={activeLine.line}
            key={activeLine.asset + 'active'}
            stroke={colors.line}
            strokeWidth={4}
            fill='none'
          />
        )}
        {activeLine &&
          activeLine.data.map(({ step, value, asset }, index) => {
            return (
              <Tooltip
                key={`${asset}_${step}`}
                title={
                  <PointText
                    index={index}
                    step={step}
                    value={pointTransformer ? pointTransformer(value, roundToTwo) : roundToTwo(value)}
                    asset={getDeviceName(asset)}
                    yLabel={yPointMetric || yLabel}
                    xLabel={xLabel}
                    yAxisMetric={yAxisMetric}
                    xAxisMetric={xAxisMetric}
                    customScaleValue={customScale && customScale[index]}
                  />
                }
              >
                <circle
                  pointerEvents={'auto'}
                  cx={xScale(step)}
                  cy={yScale(value)}
                  r={5}
                  fill={'white'}
                  stroke={colors.line}
                />
              </Tooltip>
            );
          })}

        <AxisX
          height={dimension.height}
          axisMetric={xAxisMetric}
          label={xLabel}
          ticks={customScale || xScale.ticks()}
        />
        <AxisY
          height={dimension.height}
          axisMetric={yAxisMetric}
          label={yLabel}
          ticks={yScale.ticks()}
          isRelative={isRelative}
        />
      </svg>
    </div>
  );
};

HistogramChart.defaultProps = {
  width: 630,
  height: 300,
  xLabel: 'x Axis',
  yLabel: 'y Axis',
  noDataWarning: ''
};

HistogramChart.propTypes = {
  chartkey: PropTypes.string.isRequired,
  histogramData: PropTypes.any.isRequired,
  activeAsset: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  xAxisMetric: PropTypes.string,
  yAxisMetric: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  customScale: PropTypes.array,
  stepSize: PropTypes.number,
  getDeviceName: PropTypes.func,
  dataTransformer: PropTypes.func,
  pointTransformer: PropTypes.func,
  yPointMetric: PropTypes.string,
  isRelative: PropTypes.bool
};

export default HistogramChart;
