import React from 'react';
import { connect } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Tooltip } from 'antd';

import actions from '../../actions';
import LocaleString from './LocaleString';
import { triggerGenericEvent } from '../../helpers/googleAnalytics';

class RefreshODRButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshed: false
    };
  }

  componentDidUpdate(PrevProps) {
    const { trailerId } = this.props;

    if (PrevProps.trailerId !== trailerId) {
      this.setState({ refreshed: false });
    }
  }

  requestOdr = () => {
    const { requestOdr, trailerId } = this.props;
    triggerGenericEvent('Request', 'ODR', 'Events');
    this.setState({ refreshed: true });
    requestOdr(trailerId);
  };

  render() {
    const { refreshed } = this.state;
    const { className, refreshError, size, shape, style, light, processing } = this.props;
    let showError = false;
    if (
      refreshed &&
      refreshError &&
      refreshError ===
        'An ODR file for this device has already been requested in the last 30 minutes, please wait and try again.'
    ) {
      showError = true;
    }
    return (
      <div>
        {light && (
          <Tooltip
            placement='bottom'
            title={
              showError ? (
                <LocaleString type='button' id='alreadyRefreshedODR' />
              ) : (
                <LocaleString type='button' id='refreshODR' />
              )
            }
          >
            <Button
              type='link'
              style={style}
              size={size || 'small'}
              icon={<LegacyIcon className={className} type={refreshed && refreshError ? 'warning' : 'reload'} />}
              loading={processing}
              onClick={this.requestOdr}
            />
          </Tooltip>
        )}
        {!light && showError && (
          <Tooltip placement='bottom' title={<LocaleString type='button' id='alreadyRefreshedODR' />}>
            <Button
              style={style}
              icon={<LegacyIcon className={className} type={refreshed && refreshError ? 'warning' : 'reload'} />}
              size={size || 'small'}
              shape={shape || 'round'}
              loading={processing}
              onClick={this.requestOdr}
            >
              {' '}
              <LocaleString type='button' id='refreshODR' />
            </Button>
          </Tooltip>
        )}
        {!light && !showError && (
          <Button
            style={style}
            icon={<LegacyIcon className={className} type={refreshed && refreshError ? 'warning' : 'reload'} />}
            size={size || 'small'}
            shape={shape || 'round'}
            loading={processing}
            onClick={this.requestOdr}
          >
            {' '}
            <LocaleString type='button' id='refreshODR' />
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    processing: store.trailerDetails.odrRefresh.processing,
    refreshError: store.trailerDetails.odrRefresh.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestOdr: trailerId => dispatch(actions.trailers.requestOdr(trailerId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshODRButton);
