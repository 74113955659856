import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { selectProductFeatures } from '../../selectors';
import { getisFFPlacesNotificationsPageEnabled } from '../../app/feature-flags';
import { getIsShowPlaces } from '../../helpers/functions';

function RouteWrapper({ RouteComponent, productFeatures, match, location, flag, isFFShowPlacesNotifications }) {
  let history = useHistory();
  const pageIsAndCanShowPlacesNotifications =
    isFFShowPlacesNotifications && getIsShowPlaces() && flag === 'showPlacesNotifications';

  if (!(flag in productFeatures) && !pageIsAndCanShowPlacesNotifications) {
    history.push('/home');
  }
  return !flag || productFeatures[flag] || pageIsAndCanShowPlacesNotifications ? (
    <RouteComponent match={match} history={history} location={location} />
  ) : (
    <div className='centred'>
      <Spin size='large' />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    productFeatures: selectProductFeatures(state),
    isFFShowPlacesNotifications: getisFFPlacesNotificationsPageEnabled(state)
  };
}

export default injectIntl(connect(mapStateToProps)(RouteWrapper));
