import React from 'react';
import { connect } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';

import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';
import JetpackTable from '../Utils/JetpackTable';
import LocaleString from '../Utils/LocaleString';
import { getMediumDateShortTime } from '../../helpers/functions';
import { selectRegion } from '../../selectors';

class OdrFiles extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  downloadFile = fileId => {
    const { trailerId, downloadFile, vinNumber, odrFiles } = this.props;
    const timestamp = odrFiles.find(odrFile => odrFile.fileId === fileId).lastModified;
    downloadFile(trailerId, fileId, vinNumber, timestamp);
  };

  getColumns = () => {
    return [
      {
        title: <LocaleString type='short' id='fileId' />,
        key: 'fileId',
        dataIndex: 'fileId',
        sorter: (a, b) => (a.id > b.id ? -1 : 1),
        showSorterTooltip: false,
        filterType: 'search'
      },
      {
        title: <LocaleString type='short' id='date' />,
        dataIndex: 'lastModified',
        key: 'lastModified',
        render: date => getMediumDateShortTime(date),
        defaultSortOrder: 'ascend',
        sorter: (a, b) => (a.lastModified > b.lastModified ? -1 : 1),
        showSorterTooltip: false
      } /*, {
        title: <LocaleString type="short" id="fileSize" />,
        key: 'size',
        dataIndex: 'size',
        render: size => numeral(size).format('0b'),
        sorter: (a, b) => a.size > b.size ? -1 : 1,
      }*/,
      {
        title: <LocaleString type='short' id='download' />,
        key: 'action',
        dataIndex: 'fileId',
        render: fileId => this.getButton(fileId)
      }
    ];
  };

  getButton = fileId => {
    const {
      intl: { formatMessage },
      loading
    } = this.props;
    const isLoading = loading.includes(fileId);
    const icon = isLoading ? 'loading' : 'download';
    return (
      <Button
        className={'odr-file-download-btn-' + icon}
        type='link'
        disabled={isLoading}
        onClick={() => this.downloadFile(fileId)}
      >
        <LegacyIcon type={icon} />
        {formatMessage(strings.short.download)}
      </Button>
    );
  };

  render() {
    const { odrFiles, processing } = this.props;
    const columns = this.getColumns();
    return (
      <div>
        <JetpackTable
          id='odrFileList'
          size='middle'
          loading={processing}
          jetpackColumns={columns}
          data={odrFiles}
          rowKey='fileId'
        />
      </div>
    );
  }
}

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    processing: store.odrFiles.processing,
    error: store.odrFiles.error,
    odrFiles: store.odrFiles.items,
    loading: store.odrFiles.loading,
    region
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadFile: (trailerId, fileId, vinNumber, timestamp) =>
      dispatch(actions.odrFiles.downloadFile(trailerId, fileId, vinNumber, timestamp))
  };
}

OdrFiles.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OdrFiles));
