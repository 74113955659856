import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import './OdyNavButton.scss';

const OdyRouteNavButton = ({ to, icon, text, onClick }) => {
  return (
    <Link to={to} onClick={onClick} className={'button-container'}>
      <Button shape={'circle'} icon={icon} type={'primary'} size={'large'} />
      <Typography className='ody-nav-btn-text'>{text}</Typography>
    </Link>
  );
};

export default OdyRouteNavButton;
