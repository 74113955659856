import { selectRegion, selectFilteredEntitiesIds } from '../../../selectors';
import { receiveNotificationState } from '../../notifications';
import { receiveNewDeviceState } from '../../devices';
import { regions } from '../../../helpers/constants';
import { getAlertMessage } from '../../ActionResources/transformationFunctions';
import { receiveAlertNotifications } from '../../alerts';
import { getThrottleMqttMessages } from '../../../app/feature-flags/throttle-mqtt-messages';
import { selectQueuedMessages } from '../../../selectors/mqtt-queued-messages';
import { mqttQueuedMessagesSlice } from '../../../slices/mqtt-queued-messages';

export const onReceiveMessage = ({ dispatch, message, region }) => {
  // receiveNewDeviceState attempts to mutate state on a protected object and throws an error
  // if it is not able to. This is a temporary fix to prevent the error from being thrown.
  // TF: is moving to maintenance mode, the cost of refactoring the transformationFunctions is too high.
  dispatch(receiveNewDeviceState(JSON.parse(JSON.stringify(message.value))));
  dispatch(receiveNotificationState(message.value));
  const alertMessage = getAlertMessage(message.value);
  if (!alertMessage || (region === regions.america && alertMessage.alarm === 'rss')) {
    return;
  }
  dispatch(receiveAlertNotifications(alertMessage));
};

export const filterData = ({ data: messagePayload, deviceAssetIds }) => {
  const data = { value: messagePayload };
  const value = data.value || {};
  const keys = Object.keys(value).filter(key => deviceAssetIds.includes(key));

  if (!keys.length) {
    return null;
  }

  const filteredValue = {};
  const FilteredData = Object.assign({}, data);
  keys.forEach(key => {
    filteredValue[key] = value[key];
  });
  FilteredData.value = filteredValue;

  return FilteredData;
};

export const processCompanyTopicMessages = data => (dispatch, getState) => {
  const state = getState();
  const filteredData = filterData({ data, deviceAssetIds: selectFilteredEntitiesIds(state) });
  if (filteredData) {
    onReceiveMessage({
      dispatch,
      message: filteredData,
      region: selectRegion(state)
    });
  }
};

let mqttThrottleActive = false;
const MQTT_THROTTLE_FREQUENCY = 5 * 1000 * 60; // every 5 minutes;
export const processThrottledMqttMessages = data => (dispatch, getState) => {
  const { reset, queueMessage } = mqttQueuedMessagesSlice.actions;
  const loop = () => {
    const messages = selectQueuedMessages(getState());
    dispatch(processCompanyTopicMessages(messages));
    dispatch(reset());
    setTimeout(loop, MQTT_THROTTLE_FREQUENCY);
  };

  dispatch(queueMessage(data));

  if (!mqttThrottleActive) {
    mqttThrottleActive = true;
    setTimeout(loop, MQTT_THROTTLE_FREQUENCY);
  }
};

export const handleCompanyTopic = data => (dispatch, getState) => {
  const shouldThrottle = getThrottleMqttMessages(getState());
  if (shouldThrottle) {
    dispatch(processThrottledMqttMessages(data));
  } else {
    dispatch(processCompanyTopicMessages(data));
  }
};
