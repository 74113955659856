import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeckGL from '@deck.gl/react';
import { ScatterplotLayer, LineLayer, IconLayer } from '@deck.gl/layers';
import { WebMercatorViewport } from '@deck.gl/core';
import { TripsLayer } from '@deck.gl/geo-layers';
import { injectIntl } from 'react-intl';
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl';
import * as d3 from 'd3';
import GL from '@luma.gl/constants';

import { setMapboxLanguage } from '../../helpers/functions';
import { getTriggers } from '../../helpers/alarms/functions';
import marker from '../../assets/images/directionalMarker.png';
import { lineString, point } from '@turf/helpers';
import bbox from '@turf/bbox';
import { selectRegion, selectMapAccessToken, selectMapStyleURL, selectProductFeatures } from '../../selectors';

const turf = { lineString, bbox, point };

const statusColors = {
  2: [254, 99, 71],
  1: [254, 193, 8],
  0: [30, 144, 255]
};

const getAngle = heading => (!heading ? 180 : heading < 180 ? 180 - heading : 360 - heading + 180);

class EventMapContext extends Component {
  constructor(props) {
    super(props);
    const { region, productFeatures } = this.props;
    this.state = {
      viewState: {
        longitude: 3.736,
        latitude: 47.136,
        zoom: 3,
        pitch: 0,
        bearing: 0
      },
      eventLayer: null,
      historyIconLayer: null,
      alertLayer: null
    };
    this.triggerTypes = getTriggers(region, productFeatures);
  }

  componentDidMount() {
    const { event, trailerHistory, alerts, tripEvents } = this.props;

    const events = (event ? [event] : []).concat(tripEvents || []);
    let newEvent = events[0];
    if (!newEvent || !newEvent.GPSPosition_latitude || !newEvent.GPSPosition_longitude) {
      const lastTrailerHistory = trailerHistory[0];
      if (lastTrailerHistory) {
        newEvent = {
          ...newEvent,
          startGnss: lastTrailerHistory.startGnss,
          speed: lastTrailerHistory.speed
        };
      }
    }

    this.computeEventLayer(events);
    // Waiting 1.5 sec before zooming on the event (allow the map to render and allow to access its width/height)
    setTimeout(() => {
      if (newEvent) {
        newEvent.historyCount = trailerHistory ? trailerHistory.length : 0;
        this.recenterToHistoryPosition(newEvent);
      }
    }, 1500);
    // }
    if (trailerHistory) {
      this.computeTrailLayer(trailerHistory);
    }
    if (alerts) {
      this.computeAlertsLayer(alerts);
      // Waiting 1.5 sec before zooming on the event (allow the map to render and allow to access its width/height)
      setTimeout(() => this.recenterToHistoryPosition(alerts[0]), 1500);
    }

    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  componentDidUpdate(prevProps) {
    const { event, trailerHistory, pos, followTrailer, alerts, tripEvents } = this.props;
    if (
      JSON.stringify(event) !== JSON.stringify(prevProps.event) ||
      JSON.stringify(tripEvents) !== JSON.stringify(prevProps.tripEvents)
    ) {
      const events = (event ? [event] : []).concat(tripEvents || []);
      this.computeEventLayer(events);
    }

    if (trailerHistory && JSON.stringify(trailerHistory) !== JSON.stringify(prevProps.trailerHistory)) {
      this.computeTrailLayer(trailerHistory);
    }

    if (trailerHistory && pos && (!prevProps.pos || pos.startTime !== prevProps.pos.startTime)) {
      this.computeSubTrailLayer(trailerHistory, pos);
      this.computeHistoryIconLayer(pos);
      if (followTrailer) {
        this.recenterToHistoryPosition(pos);
      }
    }

    if (alerts && JSON.stringify(alerts) !== JSON.stringify(prevProps.alerts)) {
      this.computeAlertsLayer(alerts);
    }
  }

  onViewStateChange = ({ viewState }) => {
    this.setState({ viewState });
  };

  getZoomOfRecenteredPosition(pos) {
    if (pos.historyCount) {
      if (pos.historyCount < 15) {
        return 9;
      } else if (pos.historyCount < 60) {
        return 8;
      } else if (pos.historyCount < 240) {
        return 7;
      } else if (pos.historyCount < 1000) {
        return 6;
      } else {
        return 5;
      }
    }
    if (pos.speed != null && pos.speed <= 50) {
      if (pos.speed < 10) {
        return 15.5;
      }
      return 13;
    }
    return 10;
  }

  getDeviceBounds(history) {
    const points = [
      ...history
        .map(d =>
          d.startGnss.longitude && d.startGnss.latitude ? [d.startGnss.longitude, d.startGnss.latitude] : null
        )
        .filter(pos => pos)
    ];
    let line = null;
    try {
      line = points.length >= 2 ? turf.lineString(points) : null;
    } catch (e) {
      // Use try catch here in case all the points are the same which could cause an exception when determingin the line
      console.warn(`Problem generating line from device points: ${JSON.stringify(e)}`);
      line = null;
    }
    return {
      bounds: line !== null ? turf.bbox(line) : null,
      points
    };
  }

  recenterToHistoryPosition = historyPosition => {
    const { longitude, latitude } = historyPosition.startGnss || {
      longitude: historyPosition.GPSPosition_longitude,
      latitude: historyPosition.GPSPosition_latitude
    };
    if (longitude && latitude) {
      const { viewState } = this.props;
      let newViewState = {
        ...viewState,
        longitude,
        latitude,
        zoom: this.getZoomOfRecenteredPosition(historyPosition),
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubic
      };
      if (this.mapRef && this.mapRef._height - 200 > 2) {
        // if we have access to map size,
        // replace longitude latitude by coordinates that would place longitude latitude 200px higher
        const width = this.mapRef._width;
        const height = this.mapRef._height;
        const viewStateWithWidthHeight = { ...newViewState, width, height };

        const bounds = this.getDeviceBounds(this.props.trailerHistory).bounds;
        // prevent error when no bounds found
        if (bounds && bounds.length) {
          const { longitude, latitude, zoom } = new WebMercatorViewport(viewStateWithWidthHeight).fitBounds(
            [
              [bounds[0], bounds[1]],
              [bounds[2], bounds[3]]
            ],
            {
              padding: {
                top: 50,
                bottom: 50,
                left: 50,
                right: 50
              }
            }
          );

          newViewState.zoom = zoom;
          newViewState.latitude = latitude;
          newViewState.longitude = longitude;
        }
      }
      this.setState({ viewState: newViewState });
    }
  };

  getColor = event => {
    if (event.eventType === 'dtc') {
      if (event.priority) {
        return statusColors[event.priority];
      }
      return statusColors[0];
    }
    return [200, 200, 200];
  };

  computeEventLayer = events => {
    const eventWithPos = events.filter(e => e.GPSPosition_latitude && e.GPSPosition_longitude);
    const event = eventWithPos.length > 0 ? eventWithPos[0] : null;
    const coordinates = event ? [event.GPSPosition_longitude, event.GPSPosition_latitude] : [0, 0];
    const viewState = {
      ...this.state.viewState,
      longitude: coordinates[0],
      latitude: coordinates[1],
      zoom: coordinates[0] && coordinates[1] ? 7 : 1
    };

    const eventLayer =
      eventWithPos.length > 0
        ? new ScatterplotLayer({
            id: 'event-layer',
            data: eventWithPos,
            pickable: false,
            opacity: 1,
            radiusMinPixels: 9,
            radiusMaxPixels: 9,
            stroked: true,
            filled: true,
            lineWidthMinPixels: 3,
            getPosition: d => [d.GPSPosition_longitude, d.GPSPosition_latitude],
            getFillColor: this.getColor,
            getLineColor: [0, 0, 0]
          })
        : null;

    this.setState({ viewState, eventLayer });
  };

  computeTrailLayer = trailerHistory => {
    const lineLayer = new LineLayer({
      id: 'trail',
      data: trailerHistory,
      autoHighlight: false,
      pickable: true,
      getWidth: () => 4,
      getSourcePosition: d => d.from,
      getTargetPosition: d => d.to,
      parameters: {
        depthTest: false, // allow layers array position to determine which layer is above which (akin to Z-Index)
        // transparency-friendly blending options:
        [GL.BLEND_SRC_RGB]: GL.SRC_ALPHA,
        [GL.BLEND_DST_RGB]: GL.ONE_MINUS_SRC_COLOR,
        [GL.BLEND_EQUATION_RGB]: GL.FUNC_SUBTRACT,
        [GL.BLEND_SRC_ALPHA]: GL.ONE,
        [GL.BLEND_DST_ALPHA]: GL.ONE_MINUS_DST_ALPHA,
        [GL.BLEND_EQUATION_ALPHA]: GL.FUNC_ADD
      },
      getColor: () => [30, 144, 255]
    });

    this.setState({ lineLayer });
  };

  computeSubTrailLayer = (route, pos) => {
    if (route && pos && pos.startTime && route.length > 0) {
      let subTrailLayer = null;
      if (Array.isArray(route) && route.length > 0) {
        const startTime = Math.min(...route.map(t => t.startTime));
        const currentTimeInSec = pos.startTime;
        const duration = currentTimeInSec - startTime;
        const timeStep = Math.max(1, duration / 1000);
        subTrailLayer = new TripsLayer({
          id: 'sub-trips-layer',
          data: [route],
          pickable: true,
          getPath: r => r.map(d => [...d.from, Math.round((d.startTime - startTime) / timeStep)]),
          getColor: () => [233, 30, 99],
          opacity: 0.9,
          widthMinPixels: 6,
          rounded: true,
          parameters: {
            depthTest: false // allow layers array position to determine which layer is above which (akin to Z-Index)
          },
          trailLength: Math.round((currentTimeInSec - startTime) / (timeStep * 4)),
          currentTime: Math.round((currentTimeInSec - startTime) / timeStep)
        });
      }
      this.setState({ subTrailLayer });
    } else {
      this.setState({ subTrailLayer: null });
    }
  };

  computeHistoryIconLayer = pos => {
    if (pos && pos.startGnss) {
      const historyIconLayer = new IconLayer({
        id: 'history-icon-layer',
        data: [pos],
        pickable: true,
        iconAtlas: marker,
        iconMapping: {
          marker: {
            x: 0,
            y: 0,
            width: 128,
            height: 128,
            anchorY: 60,
            mask: true
          }
        },
        sizeScale: 3,
        getPosition: d => [d.startGnss.longitude, d.startGnss.latitude],
        getIcon: () => 'marker',
        getSize: () => 10,
        getColor: () => [233, 30, 99],
        getAngle: d => getAngle(d.startGnss.heading),
        autoHighlight: true
      });
      this.setState({ historyIconLayer });
    }
  };

  computeAlertsLayer = alerts => {
    const alert = alerts[0];
    const coordinates =
      alert && alert.GPSPosition_latitude && alert.GPSPosition_longitude
        ? [alert.GPSPosition_longitude, alert.GPSPosition_latitude]
        : [0, 0];
    const viewState = {
      ...this.state.viewState,
      longitude: coordinates[0],
      latitude: coordinates[1],
      zoom: coordinates[0] && coordinates[1] ? 7 : 1
    };

    const alertLayer = new IconLayer({
      id: 'alarm-layer',
      data: alerts,
      pickable: true,
      getIcon: d => ({
        url: this.triggerTypes[d.alarm] ? this.triggerTypes[d.alarm].pngUrl : this.triggerTypes['default'].pngUrl,
        height: 128,
        width: 128
      }),
      sizeScale: 5,
      getPosition: d =>
        d.GPSPosition_latitude && d.GPSPosition_longitude ? [d.GPSPosition_longitude, d.GPSPosition_latitude] : [0, 0],
      getSize: () => 8
    });

    this.setState({ viewState, alertLayer });
  };

  goToLiveMapPage = () => {
    const { updateSelectedTnTTrailer, selectedTrailer, goToLiveMap } = this.props;
    updateSelectedTnTTrailer(selectedTrailer);
    goToLiveMap();
  };

  componentWillUnmount() {
    if (this.gl) {
      const extension = this.gl.getExtension('WEBGL_lose_context');
      if (extension) extension.loseContext();
    }
  }

  setGL(gl, self) {
    self.gl = gl;
  }

  renderLayers = () => {
    const { lineLayer, subTrailLayer, eventLayer, historyIconLayer, alertLayer } = this.state;
    const layers = [lineLayer, subTrailLayer, eventLayer, historyIconLayer, alertLayer];
    return layers;
  };

  render() {
    const { viewState } = this.state;
    const { mapAccessToken, mapStyleURL } = this.props;

    return (
      <div
        style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}
        key={mapAccessToken + '-' + mapStyleURL}
      >
        <DeckGL
          viewState={viewState}
          layers={this.renderLayers()}
          onViewStateChange={this.onViewStateChange}
          onClick={this.onLayerClick}
          controller={true}
          onWebGLInitialized={gl => this.setGL(gl, this)}
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={mapAccessToken}
            mapStyle={mapStyleURL}
            preventStyleDiffing
            onLoad={evt => setMapboxLanguage(evt, this.props.language)}
            ref={map => {
              this.mapRef = map;
            }}
          ></ReactMapGL>
        </DeckGL>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const region = selectRegion(state);
  const mapAccessToken = selectMapAccessToken(state);
  const mapStyleURL = selectMapStyleURL(state);
  return {
    region,
    productFeatures: selectProductFeatures(state),
    language: state.language.language,
    mapAccessToken,
    mapStyleURL
  };
}
EventMapContext.propTypes = {};

export default injectIntl(connect(mapStateToProps)(EventMapContext));
