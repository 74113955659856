import React from 'react';
import { ExclamationCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import numeral from 'numeral';

import JetpackTable from '../Utils/JetpackTable';
import LocaleString from '../Utils/LocaleString';
import { getUnitString, unitType } from '../../helpers/unitConverter';
import * as strings from '../../helpers/defaultStrings';
import { getDate } from '../../helpers/functions';
import { getModuleMessage, getDescriptionMessage } from '../../helpers/odrerrorDefaultStrings';

const { Description } = DescriptionList;

function getPriorityIcon(priority) {
  switch (priority) {
    case 0:
      return <InfoCircleTwoTone style={{ fontSize: 24 }} />;
    case 1:
      return <WarningTwoTone style={{ fontSize: 24 }} twoToneColor='#ffc107' />;
    case 2:
      return <ExclamationCircleTwoTone style={{ fontSize: 24 }} twoToneColor='tomato' />;
    default:
      return <InfoCircleTwoTone style={{ fontSize: 24 }} />;
  }
}

function getColumns(isMetric, formatMessage, onClickFaultcode, isiABS) {
  return [
    {
      title: <LocaleString type='short' id='priority' />,
      key: 'priorityName',
      dataIndex: 'priority',
      render: value => getPriorityIcon(value),
      showSorterTooltip: false,
      sorter: (a, b) => ((a.priority || 0) < (b.priority || 0) ? -1 : 1),
      filterType: 'categorical'
    },
    {
      title: <LocaleString type='short' id='module' />,
      dataIndex: 'errorCode',
      key: 'module',
      render: value => getModuleMessage(value, formatMessage, isiABS),
      showSorterTooltip: false,
      sorter: (a, b) => (parseInt(a.module) > parseInt(b.module) ? -1 : 1)
    },
    {
      title: <LocaleString type='short' id='issue' />,
      key: 'issueType',
      dataIndex: 'errorCode',
      render: value => getDescriptionMessage(value, formatMessage, isiABS),
      showSorterTooltip: false,
      sorter: (a, b) => (parseInt(a.issueType) > parseInt(b.issueType) ? -1 : 1)
    },
    {
      title: <LocaleString type='short' id='date' />,
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: value => (value ? getDate(value) : <LocaleString type='short' id='unknown' />),
      showSorterTooltip: false,
      sorter: (a, b) => {
        if (a.timestamp && b.timestamp) {
          return a.timestamp.diff(b.timestamp);
        } else if (a.timestamp) {
          return -1;
        } else if (b.timestamp) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    {
      title: <LocaleString type='short' id='mileage' />,
      key: 'mileage',
      render: value => numeral(value).format('0,0'),
      dataIndex: 'mileage',
      showSorterTooltip: false,
      sorter: (a, b) => (a.mileage > b.mileage ? 1 : -1),
      defaultSortOrder: 'descend'
    },
    {
      title: <LocaleString type='short' id='faultCode' />,
      key: 'errorCode',
      dataIndex: 'errorCode',
      showSorterTooltip: false,
      sorter: (a, b) => (parseInt(a.errorCode) > parseInt(b.errorCode) ? -1 : 1),
      filterType: 'categorical'
    },
    {
      title: <LocaleString type='short' id='actions' />,
      key: 'action',
      render: (value, d) => {
        return (
          <div onClick={() => onClickFaultcode(d)} className='clickable-text'>
            <LocaleString type='short' id='readMore' />
          </div>
        );
      },
      dataIndex: 'action'
    }
  ];
}

function getListItems(record, isiABS, isMetric, formatMessage) {
  const keys = [
    'pneumaticPressure',
    'brakePressure',
    'brakePressureH2',
    'checksum',
    'supplyPressure',
    'diagnosticEventCounter',
    'supplyVoltage',
    'pressureCan',
    'speed'
  ];
  return keys
    .filter(key => {
      return !isiABS || record.situation[key];
    })
    .map((key, index) => {
      let value = record.situation[key];
      switch (key) {
        case 'speed':
          value = formatMessage(getUnitString(unitType.speed, isMetric), { value: numeral(value).format('0.0') });
          break;
        case 'pneumaticPressure':
        case 'brakePressure':
        case 'brakePressureH2':
        case 'supplyPressure':
        case 'pressureCan':
          value = formatMessage(getUnitString(unitType.pressure, isMetric), { value: numeral(value).format('0.0') });
          break;
        case 'supplyVoltage':
          value = formatMessage(strings.short.voltageVal, { value: numeral(value).format('0,0.[00]') });
          break;
        default:
          value = numeral(value).format('0,0.[00]');
      }
      return (
        <Description key={index} term={<LocaleString type='short' id={key} />}>
          {value}
        </Description>
      );
    });
}

const getExpandedRow = (record, isiABS, isMetric, formatMessage) => (
  <DescriptionList size='small' col='3'>
    {getListItems(record, isiABS, isMetric, formatMessage)}
  </DescriptionList>
);

const EventList = ({ dtc, isiABS = false, isMetric, formatMessage, onClickFaultcode }) => {
  return (
    <div className='event-list'>
      <JetpackTable
        id='eventList'
        size='middle'
        jetpackColumns={getColumns(isMetric, formatMessage, onClickFaultcode, isiABS)}
        data={dtc}
        rowKey='id'
        expandedRowRender={record => getExpandedRow(record, isiABS, isMetric, formatMessage)}
      />
    </div>
  );
};

export default EventList;
