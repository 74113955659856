import { Logger } from '../app/helpers/logger';

export const parseJson = str => {
  try {
    return JSON.parse(str);
  } catch (err) {
    Logger.error(err);
    return null;
  }
};
