import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../actions';
import { isScalar } from '../helpers/functions';
import ScalarDataLoader from './ScalarDataLoader';
import { subscribeToTopics } from '../actions/thunks/pubsub/subscribe-to-topics';
import { fetchTrailers } from '../actions/thunks/fetch-trailers';
import { PRODUCT_FEATURES } from '../helpers/constants';
import { filterTrailers } from '../actions/thunks/filter-trailers';
import { filterTrailers as filterEBPMSTrailers } from '../actions/ebpmsTrailers';
import { selectEntities } from '../selectors/trailers';
import { selectAllItems } from '../selectors/ebpms-trailers';
import {
  isTrackAndTraceEnabled,
  selectUser,
  selectProductFeatures,
  selectDisplayTrailers,
  selectDisplayTrailersEBPMS,
  selectFeatureFlagsIsDataLoaded
} from '../selectors';

const DataLoader = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const user = useSelector(selectUser);
  const trackAndTraceEnabled = useSelector(isTrackAndTraceEnabled);
  const productFeatures = useSelector(selectProductFeatures);
  const ebpmsEmailPreferences = useSelector(store => store.notifications.ebpmsEmailPreferences);
  const ebpmsEmailPreferencesProcessing = useSelector(store => store.notifications.ebpmsEmailPreferencesProcessing);
  const unfilteredTrailers = useSelector(selectEntities);
  const unfilteredEBPMSTrailers = useSelector(selectAllItems);
  const displayTrailers = useSelector(selectDisplayTrailers);
  const dispatch = useDispatch();
  const featureFlagsLoaded = useSelector(selectFeatureFlagsIsDataLoaded);

  // We depend on the user to retrieve data and the correct subset of ff flags
  // Currently, the Auth0App component, mounts the DataLoader before identifying the user
  useEffect(() => {
    //we need to wait for the feature flag response before we can fetch trailers
    if (user?.id && trackAndTraceEnabled && featureFlagsLoaded) {
      dispatch(fetchTrailers());
    }
  }, [user?.id, featureFlagsLoaded]);

  useEffect(() => {
    if (user?.id && !mounted && featureFlagsLoaded) {
      dispatch(actions.trailers.getAllTrailers());
      dispatch(subscribeToTopics());
      dispatch(actions.settings.getSettings());
      if (user.features.includes(PRODUCT_FEATURES.EBPMS)) {
        dispatch(actions.ebpmsTrailers.getAllEBPMSTrailers());
        dispatch(actions.fetchLastValidEbpmsMessage());
      }
      setMounted(true);
    }
    if (!Object.keys(ebpmsEmailPreferences).length && productFeatures.showEBPMS && !ebpmsEmailPreferencesProcessing) {
      dispatch(actions.notifications.getEBPMSNotificationsSubscriptions());
    }
    // eslint-disable-next-line
  }, [user, mounted, featureFlagsLoaded]);

  useEffect(() => {
    if (unfilteredTrailers?.length) {
      dispatch(filterTrailers());
    }
  }, [unfilteredTrailers?.length, displayTrailers]);

  useEffect(() => {
    if (unfilteredEBPMSTrailers?.length) {
      dispatch(filterEBPMSTrailers());
    }
  }, [unfilteredEBPMSTrailers?.length, selectDisplayTrailersEBPMS]);

  return isScalar() ? <ScalarDataLoader>{children}</ScalarDataLoader> : <>{children}</>;
};

export default DataLoader;
