import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DashboardOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Row, Switch, Select, Col, Button, Spin } from 'antd';
import * as d3 from 'd3';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { getModuleMessage, getDescriptionMessage } from '../../helpers/odrerrorDefaultStrings';
import EventTimeline from '../charts/eventTimeLine/EventTimelineChartComponent';
import EventList from './EventList';
import EventPriorityLegend from './EventPriorityLegend';
import ColorLegend from '../charts/colorLegend/ColorLegendComponent';
import DiscardedEvents from './DiscardedEvents';
import LocaleString from '../Utils/LocaleString';
import EventDrawerContext from '../EventDrawerContext/EventDrawerContext';
import RefreshODRButton from '../Utils/RefreshODRButton';
import './Events.scss';
import { getMetrics, isUsaCustomer, getDate } from '../../helpers/functions';
import { mobileOnly } from '../../helpers/responsive';
import { convertInt, unitType } from '../../helpers/unitConverter';
import { CSVLink } from 'react-csv';
import numeral from 'numeral';
import { selectRegion } from '../../selectors';

class Events extends React.Component {
  constructor(props) {
    super(props);
    const { region } = this.props;
    this.metrics = getMetrics(region);
    this.isiABS = isUsaCustomer(region);
    this.state = {
      removeZero: true,
      mileageTimeline: false,
      priorities: [undefined, 1, 2],
      colorDomain: null,
      trip: null,
      metric: this.metrics[0].value,
      discarded: null,
      selectedEvent: null,
      selectedTrip: null
    };
  }

  componentDidMount() {
    const { dtc, recordedEvents, trips } = this.props;
    const { removeZero, metric, priorities } = this.state;
    const colorDomain = this.getColorDomain(trips, metric, true);
    if (trips) {
      const filteredDtc = this.filterDtc(removeZero, priorities);
      const filteredRecordedEvents = recordedEvents
        ? recordedEvents.filter(d => this.filterByMileage(d, removeZero))
        : [];
      const discarded = this.getDiscardedEventStats(dtc, recordedEvents, trips);
      this.setState({
        dtc: filteredDtc,
        recordedEvents: filteredRecordedEvents,
        trips,
        colorDomain,
        discarded,
        mileageTimeline: discarded && discarded.notDiscardedCount === 0
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dtc, recordedEvents, trips } = this.props;
    const { removeZero, priorities } = this.state;
    if (dtc && JSON.stringify(prevProps.dtc) !== JSON.stringify(dtc)) {
      const filteredDtc = this.filterDtc(removeZero, priorities);
      const filteredRecordedEvents = recordedEvents.filter(d => this.filterByMileage(d, removeZero));
      const discarded = this.getDiscardedEventStats(dtc, recordedEvents, trips);
      this.setState({
        dtc: filteredDtc,
        recordedEvents: filteredRecordedEvents,
        discarded,
        mileageTimeline: discarded && discarded.notDiscardedCount === 0
      });
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { dtc, trips, isMetric, region } = nextProps;
    const { dtc: prevDtc, trips: prevTrips, metric } = prevState;
    const isUS = isUsaCustomer(region);
    if (JSON.stringify(dtc) !== JSON.stringify(prevDtc) && JSON.stringify(trips) !== JSON.stringify(prevTrips)) {
      let conversion = '';
      if (metric.includes('Load')) {
        conversion = unitType.ton;
      } else if (metric.includes('Speed')) {
        conversion = unitType.distance;
      }
      const colorDomain = d3
        .scaleLinear()
        .domain(d3.extent(trips, d => convertInt(isMetric, conversion, d[metric], isUS)))
        .nice()
        .domain();
      return { dtc, trips, colorDomain };
    }
    return null;
  };

  getDiscardedEventStats = (dtc, recordedEvents, trips) => {
    const { lastOdo } = this.props;
    const displayEvents = (dtc || [])
      .concat(recordedEvents)
      .concat(trips)
      .filter(e => e && e.timestamp && moment.utc(e.timestamp) <= moment.utc()).length;
    const dtcWithoutTimestamp = dtc ? dtc.filter(e => !e.timestamp).length : 0;
    const dtcInFuture = dtc ? dtc.filter(e => e.timestamp && moment.utc(e.timestamp) > moment.utc()).length : 0;
    const recordedEventsWithoutTimestamp = recordedEvents ? recordedEvents.filter(e => !e.timestamp).length : 0;
    const recordedEventsWithoutInFuture = recordedEvents
      ? recordedEvents.filter(e => e.timestamp && moment.utc(e.timestamp) > moment.utc()).length
      : 0;
    const tripsWithoutTimestamp = trips ? trips.filter(e => !e.timestamp).length : 0;
    const tripsInFuture = trips ? trips.filter(e => e.timestamp && moment.utc(e.timestamp) > moment.utc()).length : 0;
    const dtcWithInaccurateMileage = dtc && lastOdo ? dtc.filter(e => e.mileage <= lastOdo).length : 0;
    const recordedEventsWithInaccurateMileage =
      recordedEvents && lastOdo ? recordedEvents.filter(e => e.mileage <= lastOdo).length : 0;
    const tripsWithInaccurateMileage = trips && lastOdo ? trips.filter(e => e.mileage <= lastOdo).length : 0;
    if (
      dtcWithoutTimestamp ||
      dtcInFuture ||
      dtcWithInaccurateMileage ||
      recordedEventsWithoutTimestamp ||
      recordedEventsWithoutInFuture ||
      recordedEventsWithInaccurateMileage ||
      tripsWithoutTimestamp ||
      tripsInFuture ||
      tripsWithInaccurateMileage
    ) {
      return {
        total:
          dtcWithoutTimestamp +
          dtcInFuture +
          dtcWithInaccurateMileage +
          recordedEventsWithoutTimestamp +
          recordedEventsWithoutInFuture +
          recordedEventsWithInaccurateMileage +
          tripsWithoutTimestamp +
          tripsInFuture +
          tripsWithInaccurateMileage,
        notDiscardedCount: displayEvents,
        dtcWithoutTimestamp,
        dtcInFuture,
        dtcWithInaccurateMileage,
        recordedEventsWithoutTimestamp,
        recordedEventsWithoutInFuture,
        recordedEventsWithInaccurateMileage,
        tripsWithoutTimestamp,
        tripsInFuture,
        tripsWithInaccurateMileage
      };
    } else {
      return null;
    }
  };

  filterByMileage(item, removeZero) {
    return !removeZero || item.mileage >= 1;
  }

  filterByPriority(item, priorities) {
    return priorities.includes(item.priority);
  }

  filterDtc(removeZero, priorities) {
    const { dtc } = this.props;
    if (!dtc) {
      return null;
    }
    return dtc.filter(item => this.filterByMileage(item, removeZero) && this.filterByPriority(item, priorities));
  }

  onFilterByMileageChange = removeZero => {
    const { recordedEvents } = this.props;
    const { priorities } = this.state;
    const filteredDtc = this.filterDtc(removeZero, priorities);
    const filteredRecordedEvents = recordedEvents.filter(d => this.filterByMileage(d, removeZero));
    this.setState({ dtc: filteredDtc, recordedEvents: filteredRecordedEvents, removeZero });
  };

  switchTimeline = mileageTimeline => {
    this.setState({ mileageTimeline });
  };

  changePrioritySelection = priorities => {
    const { removeZero } = this.state;
    const dtc = this.filterDtc(removeZero, priorities);
    this.setState({ dtc, priorities });
  };

  setCurrentTrip = trip => {
    this.setState({ trip });
  };

  onMetricChange = metric => {
    const { trips } = this.state;
    const colorDomain = this.getColorDomain(trips, metric);
    this.setState({ metric, colorDomain });
  };

  getColorDomain = (trips, metric, isFirst = false) => {
    const { isMetric } = this.props;
    let conversion = '';
    if (metric.includes('Load')) {
      conversion = unitType.ton;
    } else if (metric.includes('Speed')) {
      conversion = unitType.distance;
    }
    return d3
      .scaleLinear()
      .domain(d3.extent(trips, d => (isFirst ? convertInt(isMetric, conversion, d[metric]) : d[metric])))
      .nice()
      .domain();
  };

  selectEvent = selectedEvent => {
    const error = selectedEvent?.errorCode;
    if (error) {
      this.errorClicked(error);
    }
    this.setState({ selectedEvent, selectedTrip: null });
  };

  selectTrip = selectedTrip => {
    const { dtc, recordedEvents } = this.props;
    let tripEvents = null;
    if (selectedTrip && (dtc || recordedEvents)) {
      const events = recordedEvents.concat(dtc);
      const tripEnd = selectedTrip.timestamp.clone().add(selectedTrip.duration, 'minutes');
      tripEvents = events.filter(event => event.timestamp >= selectedTrip.timestamp && event.timestamp < tripEnd);
    }
    this.setState({
      selectedTrip,
      selectedEvent: null,
      tripEvents: tripEvents && tripEvents.length > 0 ? tripEvents : null
    });
  };

  resetSelection = () => {
    this.selectEvent(null);
    this.selectTrip(null);
  };

  getPriorityName = (priority, formatMessage) => {
    switch (priority) {
      case 1: {
        return formatMessage(strings.short.important);
      }
      case 2: {
        return formatMessage(strings.short.critical);
      }
      default:
        return formatMessage(strings.short.info);
    }
  };

  convertItems(item, isMetric, isUS) {
    if (item && !item.isConverted) {
      item.isConverted = true;
      Object.keys(item).forEach(key => {
        switch (key) {
          case 'distance':
          case 'mileage':
          case 'MileageEnd':
          case 'MileageStart':
          case 'nextTripMileage':
            item[key] = convertInt(isMetric, unitType.distance, item[key]);
            break;
          case 'pneumaticPressure':
          case 'brakePressure':
          case 'brakePressureH2':
          case 'supplyPressure':
          case 'pressureCan':
            item[key] = convertInt(isMetric, unitType.pressure, item[key]);
            break;
          case 'maxSpeed':
          case 'vehicleSpeed':
          case 'VehicleSpeed':
          case 'speed':
            item[key] = convertInt(isMetric, unitType.speed, item[key]);
            break;
          case 'avgAxleLoad':
          case 'minAxleLoad':
          case 'maxAxleLoad':
          case 'AxleLoadSum':
            item[key] = convertInt(isMetric, unitType.ton, item[key], isUS);
            break;
          default:
            break;
        }
      });
    }
    return item;
  }

  generalConversion(isMetric, isUS, data, isConvertSituation) {
    return data.map(item => ({
      ...this.convertItems(item, isMetric, isUS),
      situation: isConvertSituation ? this.convertItems(item.situation, isMetric, isUS) : item.situation
    }));
  }

  getHeaders(isMetric) {
    const {
      intl: { formatMessage }
    } = this.props;
    const pressureUnit = isMetric.pressure
      ? formatMessage(strings.abbrev.bar)
      : formatMessage(strings.abbrev.poundPerSquareInch);
    return [
      { label: formatMessage(strings.short.priority), key: 'priorityName' },
      { label: formatMessage(strings.short.module), key: 'module' },
      { label: formatMessage(strings.short.issue), key: 'issueType' },
      { label: formatMessage(strings.short.date), key: 'date' },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.mileage),
          var_unit: isMetric.distance ? formatMessage(strings.abbrev.kilometer) : formatMessage(strings.abbrev.mile)
        }),
        key: 'mileage'
      },
      { label: formatMessage(strings.short.faultCode), key: 'errorCode' },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.speed),
          var_unit: isMetric.distance
            ? formatMessage(strings.abbrev.kilometerPerHour)
            : formatMessage(strings.abbrev.milePerHour)
        }),
        key: 'speed'
      },
      { label: formatMessage(strings.short.supplyVoltage), key: 'supplyVoltage' },
      { label: formatMessage(strings.short.diagnosticEventCounter), key: 'diagnosticEventCounter' },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.pressureCan),
          var_unit: pressureUnit
        }),
        key: 'pressureCan'
      },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.pneumaticPressure),
          var_unit: pressureUnit
        }),
        key: 'pneumaticPressure'
      },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.brakePressure),
          var_unit: pressureUnit
        }),
        key: 'brakePressure'
      },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.brakePressureH2),
          var_unit: pressureUnit
        }),
        key: 'brakePressureH2'
      },
      {
        label: formatMessage(strings.short.measurementUnit, {
          var_measurement: formatMessage(strings.short.supplyPressure),
          var_unit: pressureUnit
        }),
        key: 'supplyPressure'
      },
      { label: formatMessage(strings.short.checksum), key: 'checksum' }
    ];
  }

  getCSVData(data) {
    const {
      intl: { formatMessage },
      trailer
    } = this.props;
    const isTrailerIABS = trailer?.ebsType === 'IABS' || trailer?.eepromSize === 8;
    return data.map(e => {
      const dataItem = { ...e.situation, ...e };

      return {
        ...dataItem,
        module: getModuleMessage(dataItem.errorCode, formatMessage, isTrailerIABS),
        issueType: getDescriptionMessage(dataItem.errorCode, formatMessage, isTrailerIABS),
        priorityName: this.getPriorityName(dataItem.priority, formatMessage),
        date: dataItem.timestamp ? getDate(dataItem.timestamp) : formatMessage(strings.short.unknown),
        diagnosticEventCounter: Math.round(dataItem.diagnosticEventCounter),
        mileage: Math.round(dataItem.mileage),
        checksum: Math.round(dataItem.checksum),
        supplyVoltage: numeral(dataItem.supplyVoltage).format('0.0')
      };
    });
  }

  errorClicked = d => {
    this.setState({ errorClicked: d });
  };

  render() {
    const {
      dtc,
      recordedEvents,
      trip,
      tripEvents,
      removeZero,
      priorities,
      colorDomain,
      metric,
      mileageTimeline,
      selectedEvent,
      selectedTrip,
      discarded,
      errorClicked
    } = this.state;
    const { shared, trailer, lastOdo, intl, trips, isMetric, processing, region } = this.props;
    const { formatMessage } = intl;
    const isMobile = mobileOnly.matches;
    const isTrailerIABS = trailer?.ebsType === 'IABS' || trailer?.eepromSize === 8;
    const dtcData = dtc
      ? dtc.map(e => {
          const newEvent = { ...e, priorityName: this.getPriorityName(e.priority, formatMessage) };
          return newEvent;
        })
      : [];
    const isUS = isUsaCustomer(region);

    if (processing === true) {
      return (
        <div className='centred'>
          <Spin />
        </div>
      );
    }

    return (
      <div>
        <Row type='flex' align='bottom'>
          <h3>
            <LocaleString type='phrase' id='eventTimeline' val={1000} />
          </h3>
          <DiscardedEvents stats={discarded} />
        </Row>
        <Row type='flex' justify='start'>
          <Col span={isMobile ? 24 : 12} style={{ paddingLeft: isMobile || 8 }}>
            <div style={{ marginBottom: 8 }}>
              <Switch
                className='medium-margin-right'
                checkedChildren={<DashboardOutlined />}
                unCheckedChildren={<ClockCircleOutlined />}
                checked={mileageTimeline}
                onChange={this.switchTimeline}
                disabled={discarded && discarded.notDiscardedCount === 0}
              />
              <LocaleString type='button' id='timeMileage' />
            </div>
            <div style={{ marginBottom: 8 }}>
              <Switch
                className='medium-margin-right'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={removeZero}
                onChange={this.onFilterByMileageChange}
              />
              <LocaleString type='button' id='excludeZeroMileage' />
            </div>
            <div style={{ marginBottom: isMobile ? 8 : 0 }}>
              <RefreshODRButton trailerId={trailer.assetId} />
            </div>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <div className='event-legend-container'>
              <div style={{ marginBottom: 8 }}>
                <EventPriorityLegend priorities={priorities} onChange={this.changePrioritySelection} />
              </div>
              {!isMobile && (
                <Fragment>
                  <Select className='event-type-dropdown' onChange={this.onMetricChange} value={metric}>
                    {this.metrics.map(m => (
                      <Select.Option key={m.value} value={m.value}>
                        {m.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <ColorLegend
                    color='test'
                    minValue={colorDomain[0]}
                    maxValue={colorDomain[1]}
                    value={trip ? trip[metric] : null}
                    reverse
                  />
                </Fragment>
              )}
            </div>
          </Col>
        </Row>
        <EventTimeline
          dtc={
            dtc &&
            this.generalConversion(
              isMetric,
              isUS,
              dtc.filter(
                e =>
                  (!lastOdo || e.mileage <= lastOdo) &&
                  (mileageTimeline || (e.timestamp && moment.utc(e.timestamp) <= moment.utc()))
              )
            )
          }
          recordedEvents={
            recordedEvents &&
            this.generalConversion(
              isMetric,
              isUS,
              recordedEvents.filter(
                r =>
                  (!lastOdo || r.mileage <= lastOdo) &&
                  (mileageTimeline || (r.timestamp && moment.utc(r.timestamp)) <= moment.utc())
              )
            )
          }
          trips={
            trips &&
            this.generalConversion(
              isMetric,
              isUS,
              trips.filter(
                t =>
                  (!lastOdo || t.mileage <= lastOdo) &&
                  (mileageTimeline || (t.timestamp && moment.utc(t.timestamp) <= moment.utc()))
              )
            )
          }
          onTripHover={this.setCurrentTrip}
          metric={metric}
          colorDomain={colorDomain}
          mileageTimeline={mileageTimeline}
          selectEvent={this.selectEvent}
          selectTrip={this.selectTrip}
          isiABS={isTrailerIABS}
          isMetric={isMetric}
          trailer={trailer}
          onErrorTypeClicked={this.errorClicked}
        />
        <h3>
          <LocaleString type='short' id='eventList' />
          {dtcData && (
            <CSVLink
              data={this.getCSVData(this.generalConversion(isMetric, isUS, dtcData, true))}
              headers={this.getHeaders(isMetric)}
              filename={`${trailer.vinNumber}_${moment(new Date().getTime()).format(
                'YYYY-MM-DD--HH-mm-ss'
              )}_event_list.csv`}
              className='export-csv'
            >
              <Button icon={<DownloadOutlined />} onClick={this.exportCSV}>
                {formatMessage(strings.short.exportCSV)}
              </Button>
            </CSVLink>
          )}
        </h3>
        {dtc && (
          <EventList
            dtc={dtcData}
            isiABS={isTrailerIABS}
            isMetric={isMetric}
            formatMessage={formatMessage}
            region={region}
            onClickFaultcode={this.selectEvent}
          />
        )}
        {!dtc && (
          <div>
            <LocaleString type='short' id='notSupported32KbODR' />
          </div>
        )}
        {!shared && (
          <EventDrawerContext
            trailer={trailer}
            event={selectedEvent}
            trip={selectedTrip}
            tripEvents={tripEvents}
            resetSelection={this.resetSelection}
            onErrorChange={this.errorClicked}
            errorClicked={errorClicked}
            isConverted={true}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    trailers: store.trailerDetails.trailers.items,
    processing: store.trailerDetails.trailer.processingTrips,
    region,
    isMetric: store.auth.isMetric
  };
}

Events.propTypes = {};
export default injectIntl(connect(mapStateToProps)(Events));
