import React from 'react';
const SensorNotFound = () => (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.76367 17.2757L10.692 1.70203L19.6203 17.2757H1.76367ZM3.87401 16.0462H17.51L10.692 4.16103L3.87401 16.0462ZM10.7732 14.8782C10.949 14.8782 11.0945 14.8201 11.2094 14.704C11.3244 14.5879 11.3819 14.441 11.3819 14.2634C11.3819 14.0858 11.3244 13.939 11.2094 13.8228C11.0945 13.7067 10.949 13.6487 10.7732 13.6487C10.5973 13.6487 10.4519 13.7067 10.3369 13.8228C10.2219 13.939 10.1644 14.0858 10.1644 14.2634C10.1644 14.441 10.2219 14.5879 10.3369 14.704C10.4519 14.8201 10.5973 14.8782 10.7732 14.8782ZM10.1644 12.6036H11.3819V8.01346H10.1644V12.6036Z'
      fill='black'
    />
  </svg>
);

export default SensorNotFound;
