import actionTypes from '../actions/actionTypes';

const initialState = {
  items: [],
  processing: false,
  error: null
};

export default function trailersHistogramReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TRAILER_HISTOGRAMS_REQUEST: {
      return {
        ...state,
        items: [],
        processing: true,
        error: null
      };
    }
    case actionTypes.GET_TRAILER_HISTOGRAMS_SUCCESS: {
      return {
        ...state,
        items: [...state.items, ...action.trailers],
        processing: !action.final,
        error: null
      };
    }
    case actionTypes.GET_TRAILER_HISTOGRAMS_FAILURE: {
      return {
        ...state,
        items: [],
        processing: false,
        error: action.error
      };
    }
    default:
      return state;
  }
}
