export const characterSet = [
  'さ',
  'す',
  'せ',
  'そ',
  'た',
  'ち',
  'つ',
  'て',
  'と',
  'な',
  'に',
  'ぬ',
  'ね',
  'の',
  'は',
  'ひ',
  'ふ',
  'ほ',
  'ま',
  'み',
  'む',
  'め',
  'も',
  'や',
  'ゆ',
  'よ',
  'ら',
  'り',
  'る',
  'ろ',
  'れ',
  'わ',
  'あ',
  'い',
  'う',
  'え',
  'か',
  'き',
  'く',
  'け',
  'こ',
  '栃',
  '木',
  '嶋',
  'を',
  'ば',
  'だ',
  'が',
  'ぱ',
  'ざ',
  'び',
  'ぢ',
  'ぎ',
  'ぴ',
  'じ',
  'ぶ',
  'づ',
  'ぐ',
  'ぷ',
  'ず',
  'べ',
  'で',
  'げ',
  'ペ',
  'ぜ',
  'ぼ',
  'ど',
  'ご',
  'ぽ',
  'ぞ',
  'ゑ',
  'ゐ',
  'お',
  '尾',
  '張',
  '小',
  '牧',
  '一',
  '宮',
  '春',
  '日',
  '井',
  '屋',
  '古',
  '名',
  '豊',
  '橋',
  '三',
  '河',
  '岡',
  '崎',
  '田',
  '秋',
  '青',
  '森',
  '八',
  '戸',
  '千',
  '葉',
  '成',
  '習',
  '志',
  '野',
  '柏',
  '袖',
  'ヶ',
  '浦',
  '愛',
  '媛',
  '福',
  '筑',
  '北',
  '九',
  '州',
  '久',
  '留',
  '米',
  '島',
  '会',
  '津',
  '郡',
  '山',
  '岐',
  '阜',
  '飛',
  '騨',
  '群',
  '馬',
  '前',
  '高',
  '広',
  '旭',
  '川',
  '函',
  '館',
  '見',
  '釧',
  '路',
  '室',
  '蘭',
  '帯',
  '札',
  '幌',
  '姫',
  '神',
  '水',
  '土',
  '石',
  '金',
  '沢',
  '岩',
  '手',
  '平',
  '泉',
  '盛',
  '香',
  '鹿',
  '児',
  '奄',
  '美',
  '相',
  '模',
  '湘',
  '南',
  '横',
  '浜',
  '知',
  '熊',
  '本',
  '京',
  '都',
  '重',
  '鈴',
  '城',
  '仙',
  '台',
  '松',
  '諏',
  '訪',
  '長',
  '佐',
  '世',
  '保',
  '奈',
  '良',
  '新',
  '潟',
  '大',
  '分',
  '倉',
  '敷',
  '沖',
  '縄',
  '和',
  '堺',
  '阪',
  '賀',
  '部',
  '越',
  '谷',
  '口',
  '所',
  '滋',
  '埼',
  '玉',
  '茨',
  '兵',
  '根',
  '沼',
  '富',
  '士',
  '伊',
  '豆',
  '静',
  '渝',
  '沪',
  '皖',
  '闽',
  '甘',
  '粤',
  '贵',
  '琼',
  '冀',
  '黑',
  '豫',
  '鄂',
  '苏',
  '赣',
  '吉',
  '辽',
  '陕',
  '鲁',
  '晋',
  '云',
  '浙',
  '桂',
  '蒙',
  '宁',
  '藏',
  '使',
  '港',
  '澳',
  '警',
  '国',
  '甲',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  '_',
  '!',
  '£',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '+',
  '=',
  '#',
  ',',
  '.',
  '/',
  ':',
  '@',
  '~',
  '?',
  'А',
  'а',
  'Б',
  'б',
  'В',
  'в',
  'Г',
  'г',
  'Д',
  'д',
  'Е',
  'е',
  'Ё',
  'ё',
  'Ж',
  'ж',
  'З',
  'з',
  'И',
  'и',
  'Й',
  'й',
  'К',
  'к',
  'Л',
  'л',
  'М',
  'м',
  'Н',
  'н',
  'О',
  'о',
  'П',
  'п',
  'Р',
  'р',
  'С',
  'с',
  'Т',
  'т',
  'У',
  'у',
  'Ф',
  'ф',
  'Х',
  'х',
  'Ц',
  'ц',
  'Ч',
  'ч',
  'Ш',
  'ш',
  'Щ',
  'щ',
  'ъ',
  'Ы',
  'ы',
  'ь',
  'Э',
  'э',
  'Ю',
  'ю',
  'Я',
  'я'
];
