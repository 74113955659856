import { ENV_CONFIG } from '../app/helpers/env-configs';
import { API_NAMES } from '../helpers/api-names';

export const WORKER_NAME = 'aws-web-worker';

export const MESSAGE_TYPES = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  //
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  //
  TOPIC_SUBSCRIBE: 'TOPIC_SUBSCRIBE',
  TOPIC_RESPONSE: 'TOPIC_RESPONSE',
  TOPIC_ERROR: 'TOPIC_ERROR',
  //
  API_REQUEST: 'API_REQUEST',
  API_SUCCESS: 'API_SUCCESS',
  API_ERROR: 'API_ERROR',
  //
  CONFIGURE: 'CONFIGURE',
  CONFIGURE_SUCCESS: 'CONFIGURE_SUCCESS',
  CONFIGURE_ERROR: 'CONFIGURE_ERROR',
  //
  MEMORY_STORAGE_SET_ITEM: 'MEMORY_STORAGE_SET_ITEM',
  MEMORY_STORAGE_REMOVE_ITEM: 'MEMORY_STORAGE_REMOVE_ITEM',
  MEMORY_STORAGE_CLEAR: 'MEMORY_STORAGE_CLEAR',
  //
  CURRENT_AUTHENTICATED_USER: 'CURRENT_AUTHENTICATED_USER',
  CURRENT_AUTHENTICATED_USER_SUCCESS: 'CURRENT_AUTHENTICATED_USER_SUCCESS',
  CURRENT_AUTHENTICATED_USER_ERROR: 'CURRENT_AUTHENTICATED_USER_ERROR'
};

export const CONFIG_TYPES = {
  AWS_WORKER_STORAGE: 'AWS_WORKER_STORAGE',
  USER_AGENT: 'USER_AGENT'
};

export const MEMORY_STORAGE_MESSAGES = [
  MESSAGE_TYPES.MEMORY_STORAGE_SET_ITEM,
  MESSAGE_TYPES.MEMORY_STORAGE_REMOVE_ITEM,
  MESSAGE_TYPES.MEMORY_STORAGE_CLEAR
];

export const CONFIGURATION = {
  AWS_PUBSUB_REGION: 'eu-west-1',
  AWS_PUBSUB_ENDPOINT: ENV_CONFIG.REACT_APP_AWS_IOT_PUB_SUB_ENDPOINT,
  AWS_IDENTITY_POOL: ENV_CONFIG.REACT_APP_IDENTITY_POOL,
  AWS_REGION: 'eu-west-1',
  AWS_API_GATEWAY_TRAILERFIT: ENV_CONFIG.REACT_APP_API_GATEWAY_TRAILERFIT,
  AWS_API_GATEWAY_EBPMS: ENV_CONFIG.REACT_APP_API_GATEWAY_EBPMS,
  AWS_API_NAME_EBPMS: API_NAMES.EBPMS,
  AWS_API_NAME_TRAILERFIT: API_NAMES.ODR_LIVE_MAP
};
