import { worker } from '../../../app/web-workers/aws-web-worker';
import { MESSAGE_TYPES } from '../../../workers/constants';
import { redirectToPortal } from './helper';
import { onLoginSuccess } from './on-login-success';

export const checkUserIsLoggedIn = token => async dispatch => {
  try {
    const user = await worker.wrapMessageInPromise({
      types: {
        request: MESSAGE_TYPES.CURRENT_AUTHENTICATED_USER,
        success: MESSAGE_TYPES.CURRENT_AUTHENTICATED_USER_SUCCESS,
        error: MESSAGE_TYPES.CURRENT_AUTHENTICATED_USER_ERROR
      },
      payload: { token }
    });
    const doNotRedirect = true;
    dispatch(onLoginSuccess(user, doNotRedirect));
  } catch (err) {
    redirectToPortal();
  }
};
