export default {
  ADD_USER_DATA: 'ADD_USER_DATA',
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  AUTH0_USER_LOGIN_SUCCESS: 'AUTH0_USER_LOGIN_SUCCESS',
  AUTH0_USER_LOGIN_FAILURE: 'AUTH0_USER_LOGIN_FAILURE',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  USER_CHANGE_IS_METRIC: 'USER_CHANGE_IS_METRIC',
  USER_RETRY_LOGIN: 'USER_RETRY_LOGIN',
  USER_RETRY_FAILURE: 'USER_RETRY_FAILURE',

  GET_TRAILERS_REQUEST: 'GET_TRAILERS_REQUEST',
  GET_TRAILERS_SUCCESS: 'GET_TRAILERS_SUCCESS',
  GET_TRAILERS_FAILURE: 'GET_TRAILERS_FAILURE',

  GET_EBPMS_TRAILERS_ID_LIST_SUCCESS: 'GET_EBPMS_TRAILERS_ID_LIST_SUCCESS',
  GET_EBPMS_TRAILERS_ID_LIST_FAILURE: 'GET_EBPMS_TRAILERS_ID_LIST_FAILURE',

  GET_EBPMS_TRAILERS_PERFORMANCE_SUCCESS: 'GET_EBPMS_TRAILERS_PERFORMANCE_SUCCESS',
  GET_EBPMS_TRAILERS_PERFORMANCE_FAILURE: 'GET_EBPMS_TRAILERS_PERFORMANCE_FAILURE',

  GET_TRAILERS_HEALTH_REQUEST: 'GET_TRAILERS_HEALTH_REQUEST',
  GET_TRAILERS_HEALTH_SUCCESS: 'GET_TRAILERS_HEALTH_SUCCESS',
  GET_TRAILERS_HEALTH_FAILURE: 'GET_TRAILERS_HEALTH_FAILURE',

  GET_TRAILER_DETAILS_REQUEST: 'GET_TRAILER_DETAILS_REQUEST',
  GET_TRAILER_DETAILS_SUCCESS: 'GET_TRAILER_DETAILS_SUCCESS',
  GET_TRAILER_DETAILS_FAILURE: 'GET_TRAILER_DETAILS_FAILURE',
  CLEAR_TRAILER_DETAILS: 'CLEAR_TRAILER_DETAILS',

  GET_TRAILER_DETAILS_TRIPS_SUCCESS: 'GET_TRAILER_DETAILS_TRIPS_SUCCESS',
  GET_TRAILER_DETAILS_DTC_SUCCESS: 'GET_TRAILER_DETAILS_DTC_SUCCESS',
  GET_TRAILER_DETAILS_EVENTS_SUCCESS: 'GET_TRAILER_DETAILS_EVENTS_SUCCESS',

  FILTER_DEVICES: 'FILTER_DEVICES',
  NO_TRAILERS_TO_FILTER_DEVICES: 'NO_TRAILERS_TO_FILTER_DEVICES',

  GET_SHAREABLE_LINK_REQUEST: 'GET_SHAREABLE_LINK_REQUEST',
  GET_SHAREABLE_LINK_SUCCESS: 'GET_SHAREABLE_LINK_SUCCESS',
  GET_SHAREABLE_LINK_FAILURE: 'GET_SHAREABLE_LINK_FAILURE',

  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',

  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',

  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

  REQUEST_ODR_REQUEST: 'REQUEST_ODR_REQUEST',
  REQUEST_ODR_SUCCESS: 'REQUEST_ODR_SUCCESS',
  REQUEST_ODR_FAILURE: 'REQUEST_ODR_FAILURE',

  SAVE_SETTING_REQUEST: 'SAVE_SETTING_REQUEST',
  SAVE_SETTING_SUCCESS: 'SAVE_SETTING_SUCCESS',
  UPLOAD_SETTING_SUCCESS: 'UPLOAD_SETTING_SUCCESS',
  UPLOAD_SETTING_FAILURE: 'UPLOAD_SETTING_FAILURE',

  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  CHANGE_DISPLAY_BY_LOADING_EVENTS: 'CHANGE_DISPLAY_BY_LOADING_EVENTS',
  FETCH_TRAILERS_LOADING_EVENTS: 'FETCH_TRAILERS_LOADING_EVENTS',
  START_TRAILERS_LOADING_EVENTS: 'START_TRAILERS_LOADING_EVENTS',
  ERROR_TRAILERS_LOADING_EVENTS: 'ERROR_TRAILERS_LOADING_EVENTS',
  CHANGE_FILTER_DATES_LOADING_EVENTS: 'CHANGE_FILTER_DATES_LOADING_EVENTS',
  CHANGE_FILTER_MONTH_LOADING_EVENTS: 'CHANGE_FILTER_MONTH_LOADING_EVENTS',
  CHANGE_FILTER_ASSET_LOADING_EVENTS: 'CHANGE_FILTER_ASSET_LOADING_EVENTS',

  SELECT_DEVICES_LIVEMAP: 'SELECT_DEVICES_LIVEMAP',

  RESET_TRAILER: 'RESET_TRAILER',

  UPDATE_TRAILERS_FROM_DEVICES: 'UPDATE_TRAILERS_FROM_DEVICES',
  FETCH_INITIAL_DEVICES_STATES_REQUEST: 'FETCH_INITIAL_DEVICES_STATES_REQUEST',
  FETCH_INITIAL_DEVICES_STATES_SUCCESS: 'FETCH_INITIAL_DEVICES_STATES_SUCCESS',
  FETCH_INITIAL_DEVICES_STATES_FAILURE: 'FETCH_INITIAL_DEVICES_STATES_FAILURE',
  RECEIVE_NEW_DEVICE_STATE: 'RECEIVE_NEW_DEVICE_STATE',
  GET_TRAILER_GROUPS: 'GET_TRAILER_GROUPS',
  FILTER_TRAILERS: 'FILTER_TRAILERS',
  UPDATE_MAP_VIEW_STATE: 'UPDATE_MAP_VIEW_STATE',
  UPDATE_TRAILER_DRAWER_STATE: 'UPDATE_TRAILER_DRAWER_STATE',
  UPDATE_SELECTED_TNT_TRAILER: 'UPDATE_SELECTED_TNT_TRAILER',
  UPDATE_SELECTED_HEALTH_TRAILER: 'UPDATE_SELECTED_HEALTH_TRAILER',
  UPDATE_TNT_TRAILER_TABLE_PAGE: 'UPDATE_TNT_TRAILER_TABLE_PAGE',

  FETCH_HISTORICAL_NOTIFICATIONS_REQUEST: 'FETCH_HISTORICAL_NOTIFICATIONS_REQUEST',
  FETCH_HISTORICAL_NOTIFICATIONS_FAILURE: 'FETCH_HISTORICAL_NOTIFICATIONS_FAILURE',
  FETCH_HISTORICAL_NOTIFICATIONS_SUCCESS: 'FETCH_HISTORICAL_NOTIFICATIONS_SUCCESS',
  FETCH_INITIAL_NOTIFICATIONS_STATE_SUCCESS: 'FETCH_INITIAL_NOTIFICATIONS_STATE_SUCCESS',
  RECEIVE_NOTIFICATIONS_STATE: 'RECEIVE_NOTIFICATIONS_STATE',
  RECEIVE_NOTIFICATIONS_NOTIFICATION: 'RECEIVE_NOTIFICATIONS_NOTIFICATION',
  UPDATE_NOTIFICATIONS_DELETED: 'UPDATE_NOTIFICATIONS_DELETED',
  UPDATE_NOTIFICATIONS_READ: 'UPDATE_NOTIFICATIONS_READ',
  UPDATE_NOTIFICATIONS_REMOVE_READ: 'UPDATE_NOTIFICATIONS_REMOVE_READ',
  UPDATE_NOTIFICATION_TYPES_TO_LISTEN_TO: 'UPDATE_NOTIFICATION_TYPES_TO_LISTEN_TO',
  UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS: 'UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS',
  UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST: 'UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST',
  UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS: 'UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS',
  UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE: 'UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE',
  GET_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS: 'GET_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS',
  GET_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE: 'GET_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE',
  GET_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST: 'GET_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST',

  UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT: 'UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT',

  UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS: 'UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS',
  UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST: 'UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST',
  UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS: 'UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS',
  UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE: 'UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE',
  GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS: 'GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS',
  GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE: 'GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE',
  GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST: 'GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST',
  UPDATE_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT: 'UPDATE_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT',

  FETCH_MAP_MATCHING_ROUTE_REQUEST: 'FETCH_MAP_MATCHING_ROUTE_REQUEST',
  FETCH_MAP_MATCHING_ROUTE_SUCCESS: 'FETCH_MAP_MATCHING_ROUTE_SUCCESS',
  FETCH_MAP_MATCHING_ROUTE_FAILURE: 'FETCH_MAP_MATCHING_ROUTE_FAILURE',
  ADD_MESSAGE: 'ADD_MESSAGE',

  GET_TRAILER_HISTORY_REQUEST: 'GET_TRAILER_HISTORY_REQUEST',
  GET_TRAILER_HISTORY_SUCCESS: 'GET_TRAILER_HISTORY_SUCCESS',
  GET_TRAILER_HISTORY_FAILURE: 'GET_TRAILER_HISTORY_FAILURE',

  GET_TRAILER_CONTEXT_HISTORY_REQUEST: 'GET_TRAILER_CONTEXT_HISTORY_REQUEST',
  GET_TRAILER_CONTEXT_HISTORY_SUCCESS: 'GET_TRAILER_CONTEXT_HISTORY_SUCCESS',
  GET_TRAILER_CONTEXT_HISTORY_FAILURE: 'GET_TRAILER_CONTEXT_HISTORY_FAILURE',

  GET_ALERTS_REQUEST: 'GET_ALERTS_REQUEST',
  GET_ALERTS_SUCCESS: 'GET_ALERTS_SUCCESS',
  GET_ALERTS_FAILURE: 'GET_ALERTS_FAILURE',
  RECEIVE_NEW_ALERT_NOTIFICATION: 'RECEIVE_NEW_ALERT_NOTIFICATION',
  UPDATE_ALERT2H_NOTIFICATION: 'UPDATE_ALERT2H_NOTIFICATION',

  GET_EMAIL_TEMPLATE_REQUEST: 'GET_EMAIL_TEMPLATE_REQUEST',
  GET_EMAIL_TEMPLATE_SUCCESS: 'GET_EMAIL_TEMPLATE_SUCCESS',
  GET_EMAIL_TEMPLATE_FAILURE: 'GET_EMAIL_TEMPLATE_FAILURE',

  GET_ODR_FILE_LIST_REQUEST: 'GET_ODR_FILE_LIST_REQUEST',
  GET_ODR_FILE_LIST_SUCCESS: 'GET_ODR_FILE_LIST_SUCCESS',
  GET_ODR_FILE_LIST_FAILURE: 'GET_ODR_FILE_LIST_FAILURE',

  DOWNLOAD_ODR_FILE_REQUEST: 'DOWNLOAD_ODR_FILE_REQUEST',
  DOWNLOAD_ODR_FILE_SUCCESS: 'DOWNLOAD_ODR_FILE_SUCCESS',
  DOWNLOAD_ODR_FILE_FAILURE: 'DOWNLOAD_ODR_FILE_FAILURE',

  GET_TRAILER_CMI_REPORT_REQUEST: 'GET_TRAILER_CMI_REPORT_REQUEST',
  GET_TRAILER_CMI_REPORT_SUCCESS: 'GET_TRAILER_CMI_REPORT_SUCCESS',
  GET_TRAILER_CMI_REPORT_FAILURE: 'GET_TRAILER_CMI_REPORT_FAILURE',

  GET_TRAILER_RF_DATA_REQUEST: 'GET_TRAILER_RF_DATA_REQUEST',
  GET_TRAILER_RF_DATA_SUCCESS: 'GET_TRAILER_RF_DATA_SUCCESS',
  GET_TRAILER_RF_DATA_FAILURE: 'GET_TRAILER_RF_DATA_FAILURE',

  EBPMS_USER_LOGIN_REQUEST: 'EBPMS_USER_LOGIN_REQUEST',
  EBPMS_USER_LOGIN_SUCCESS: 'EBPMS_USER_LOGIN_SUCCESS',
  EBPMS_USER_LOGIN_FAILURE: 'EBPMS_USER_LOGIN_FAILURE',

  EBPMS_USER_LOGOUT_REQUEST: 'EBPMS_USER_LOGOUT_REQUEST',
  EBPMS_USER_LOGOUT_SUCCESS: 'EBPMS_USER_LOGOUT_SUCCESS',
  EBPMS_USER_LOGOUT_FAILURE: 'EBPMS_USER_LOGOUT_FAILURE',

  EBPMS_GET_TRAILERS_REQUEST: 'EBPMS_GET_TRAILERS_REQUEST',
  EBPMS_GET_TRAILERS_SUCCESS: 'EBPMS_GET_TRAILERS_SUCCESS',
  EBPMS_GET_TRAILERS_FAILURE: 'EBPMS_GET_TRAILERS_FAILURE',

  EBPMS_FILTER_TRAILERS: 'EBPMS_FILTER_TRAILERS',

  EBPMS_GET_PAST_TRAILERS_SUCCESS: 'EBPMS_GET_PAST_TRAILERS_SUCCESS',
  EBPMS_GET_PAST_TRAILERS_FAILURE: 'EBPMS_GET_PAST_TRAILERS_FAILURE',
  EBPMS_GET_PAST_TRAILERS_REQUEST: 'EBPMS_GET_PAST_TRAILERS_REQUEST',

  CHANGE_TRAILER_COMMENT_REQUEST: 'CHANGE_TRAILER_COMMENT_REQUEST',
  CHANGE_TRAILER_COMMENT_SUCCESS: 'CHANGE_TRAILER_COMMENT_SUCCESS',
  CHANGE_TRAILER_COMMENT_FAILURE: 'CHANGE_TRAILER_COMMENT_FAILURE',

  UPDATE_TRAILER_PERFORMANCE_ALGORITHM_REQUEST: 'UPDATE_TRAILER_PERFORMANCE_ALGORITHM_REQUEST',
  UPDATE_TRAILER_PERFORMANCE_ALGORITHM_SUCCESS: 'UPDATE_TRAILER_PERFORMANCE_ALGORITHM_SUCCESS',
  UPDATE_TRAILER_PERFORMANCE_ALGORITHM_FAILURE: 'UPDATE_TRAILER_PERFORMANCE_ALGORITHM_FAILURE',

  GET_TRAILER_DETAIL_REQUEST: 'GET_TRAILER_DETAIL_REQUEST',
  GET_TRAILER_DETAIL_SUCCESS: 'GET_TRAILER_DETAIL_SUCCESS',
  GET_TRAILER_DETAIL_FAILURE: 'GET_TRAILER_DETAIL_FAILURE',
  SELECT_TRAILER: 'SELECT_TRAILER',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
  GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',

  GET_BRAKE_EVENTS_REQUEST: 'GET_BRAKE_EVENTS_REQUEST',
  GET_BRAKE_EVENTS_SUCCESS: 'GET_BRAKE_EVENTS_SUCCESS',
  GET_BRAKE_EVENTS_FAILURE: 'GET_BRAKE_EVENTS_FAILURE',
  SELECT_BRAKE_EVENT: 'SELECT_BRAKE_EVENT',
  HOVER_BRAKE_EVENT: 'HOVER_BRAKE_EVENT',
  NO_BRAKE_EVENTS: 'NO_BRAKE_EVENTS',

  GET_TELEMETRY_REQUEST: 'GET_TELEMETRY_REQUEST',
  GET_TELEMETRY_SUCCESS: 'GET_TELEMETRY_SUCCESS',
  GET_TELEMETRY_FAILURE: 'GET_TELEMETRY_FAILURE',

  SAVE_SETTINGS_REQUEST: 'SAVE_SETTINGS_REQUEST',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILURE: 'SAVE_SETTINGS_FAILURE',

  GET_TRAILER_TPMS_HISTORY_SUCCESS: 'GET_TRAILER_TPMS_HISTORY_SUCCESS',
  GET_TRAILER_TPMS_HISTORY_FAILURE: 'GET_TRAILER_TPMS_HISTORY_FAILURE',
  GET_TRAILER_TPMS_HISTORY_REQUEST: 'GET_TRAILER_TPMS_HISTORY_REQUEST',
  TRAILER_TPMS_HISTORY_DEFAULT: 'TRAILER_TPMS_HISTORY_DEFAULT',

  GET_TRAILER_LOCATION_REQUEST: 'GET_TRAILER_LOCATION_REQUEST',
  GET_TRAILER_LOCATION_SUCCESS: 'GET_TRAILER_LOCATION_SUCCESS',
  GET_TRAILER_LOCATION_FAILURE: 'GET_TRAILER_LOCATION_FAILURE',

  SET_TRAILERS_SELECTED_WHEELS: 'SET_TRAILERS_SELECTED_WHEELS',

  GET_TRAILER_HISTOGRAMS_REQUEST: 'GET_TRAILER_HISTOGRAMS_REQUEST',
  GET_TRAILER_HISTOGRAMS_SUCCESS: 'GET_TRAILER_HISTOGRAMS_SUCCESS',
  GET_TRAILER_HISTOGRAMS_FAILURE: 'GET_TRAILER_HISTOGRAMS_FAILURE',

  GET_PLACES_REQUEST: 'GET_PLACES_REQUEST',
  GET_PLACES_SUCCESS: 'GET_PLACES_SUCCESS',
  GET_PLACES_FAILURE: 'GET_PLACES_FAILURE',

  CREATE_PLACE_REQUEST: 'CREATE_PLACE_REQUEST',
  CREATE_PLACE_SUCCESS: 'CREATE_PLACE_SUCCESS',
  CREATE_PLACE_EDIT_SUCCESS: 'CREATE_PLACE_EDIT_SUCCESS',
  DELETE_PLACE_REQUEST: 'DELETE_PLACE_REQUEST',
  DELETE_PLACE_SUCCESS: 'DELETE_PLACE_SUCCESS',

  GET_GEOZONES_REQUEST: 'GET_GEOZONES_REQUEST',
  GET_GEOZONES_SUCCESS: 'GET_GEOZONES_SUCCESS',
  GET_GEOZONES_FAILURE: 'GET_GEOZONES_FAILURE',

  GET_ODR_BRAKING_EVENTS_REQUEST: 'GET_ODR_BRAKING_EVENTS_REQUEST',
  GET_ODR_BRAKING_EVENTS_SUCCESS: 'GET_ODR_BRAKING_EVENTS_SUCCESS',
  GET_ODR_BRAKING_EVENTS_FAILURE: 'GET_ODR_BRAKING_EVENTS_FAILURE',

  GET_ODR_BRAKING_AVG_REQUEST: 'GET_ODR_BRAKING_AVG_REQUEST',
  GET_ODR_BRAKING_AVG_SUCCESS: 'GET_ODR_BRAKING_AVG_SUCCESS',
  GET_ODR_BRAKING_AVG_FAILURE: 'GET_ODR_BRAKING_AVG_FAILURE',

  CHANGE_TRAILER_LAST_INTERVENTION_SUCCESS: 'CHANGE_TRAILER_LAST_INTERVENTION_SUCCESS',

  GET_WSP_REQUEST: 'GET_WSP_REQUEST',
  GET_WSP_SUCCESS: 'GET_WSP_SUCCESS',
  GET_WSP_FAILURE: 'GET_WSP_FAILURE',

  SET_LIVE_MAP_SHOW_ALERTS: 'SET_LIVE_MAP_SHOW_ALERTS',
  SET_LIVE_MAP_FOLLOW_TRAILER: 'SET_LIVE_MAP_FOLLOW_TRAILER',
  SET_LIVE_MAP_HISTORY_POSITION: 'SET_LIVE_MAP_HISTORY_POSITION',
  SET_LIVE_MAP_HOURS: 'SET_LIVE_MAP_HOURS',
  SET_LIVE_MAP_END_DATE: 'SET_LIVE_MAP_END_DATE',
  SET_LIVE_MAP_VISIBLE_TRAILERS: 'SET_LIVE_MAP_VISIBLE_TRAILERS',
  SET_LIVE_MAP_SHOW_GEO_JSON_LAYERS: 'SET_LIVE_MAP_SHOW_GEO_JSON_LAYERS'
};
