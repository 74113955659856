import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const TimeDropdown = ({ disabled, onChange, value }) => {
  const clockValues = [
    { value: 0, display: '00:00' },
    { value: 1, display: '01:00' },
    { value: 2, display: '02:00' },
    { value: 3, display: '03:00' },
    { value: 4, display: '04:00' },
    { value: 5, display: '05:00' },
    { value: 6, display: '06:00' },
    { value: 7, display: '07:00' },
    { value: 8, display: '08:00' },
    { value: 9, display: '09:00' },
    { value: 10, display: '10:00' },
    { value: 11, display: '11:00' },
    { value: 12, display: '12:00' },
    { value: 13, display: '13:00' },
    { value: 14, display: '14:00' },
    { value: 15, display: '15:00' },
    { value: 16, display: '16:00' },
    { value: 17, display: '17:00' },
    { value: 18, display: '18:00' },
    { value: 19, display: '19:00' },
    { value: 20, display: '20:00' },
    { value: 21, display: '21:00' },
    { value: 22, display: '22:00' },
    { value: 23, display: '23:00' }
  ];
  return (
    <Select disabled={disabled} onSelect={onChange} value={value} className='full-width'>
      {clockValues.map(({ value, display }) => (
        <Option key={`frequency-dropdown-${value}`} value={value}>
          {display}
        </Option>
      ))}
    </Select>
  );
};

export default TimeDropdown;
