import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.externalCode) {
    return <p>{rowData.externalCode}</p>;
  }
  return <NoValueSpan />;
};

const ExternalCodeColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.externalCode)}>
        {formatMessage(strings.columns.externalCode)}
      </Tooltip>
    ),
    key: 'externalCode',
    dataIndex: 'externalCode',
    searchKey: formatMessage(strings.columns.externalCode),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} />,
    search: searchString =>
      trailers.filter('externalCode', t =>
        t.externalCode?.toString().toLowerCase().includes(searchString.toLowerCase())
      ),
    removeSearch: () => trailers.removeFilter('externalCode'),
    width: 200
  };
};

const ExternalCodeHeader = formatMessage => formatMessage(strings.columns.externalCode);
const ExternalCodeCSVData = trailer => trailer.externalCode;
const ExternalCodeCSVColumn = {
  header: ExternalCodeHeader,
  data: ExternalCodeCSVData
};

export default { table: ExternalCodeColumn, csv: ExternalCodeCSVColumn };
