import React from 'react';
import numeral from 'numeral';
import { Tooltip } from 'antd';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';

const getFrequency = data => {
  if (data.frequencyScore) {
    return numeral(data.frequencyScore).format('0.0');
  }
  return '';
};

const Cell = ({ rowData }) => {
  const frequency = getFrequency(rowData);
  if (frequency !== '') {
    return <p>{frequency}</p>;
  }
  return <NoValueSpan />;
};

const FrequencyScoreColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.frequencyScore)}>
        {formatMessage(strings.columns.frequencyScore)}{' '}
      </Tooltip>
    ),
    key: 'frequencyScore',
    dataIndex: 'frequencyScore',
    searchKey: formatMessage(strings.columns.frequencyScore),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () =>
      trailers.sort('frequencyScore', (a, b) =>
        a.frequencyScore === null || a.frequencyScore === undefined || a.frequencyScore < b.frequencyScore ? -1 : 1
      ),
    width: 180
  };
};

const FrequencyScoreColumnHeader = formatMessage => formatMessage(strings.columns.frequencyScore);
const FrequencyCSVData = trailer => getFrequency(trailer);
const FrequencyCSVColumn = {
  header: FrequencyScoreColumnHeader,
  data: FrequencyCSVData
};

export default { table: FrequencyScoreColumn, csv: FrequencyCSVColumn };
