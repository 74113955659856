import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import OdyTag from '../../../../../../ReusableComponents/Tag/OdyTag';
import { statusLabelSort } from '../../../../../../helpers/sorters/column-sorters';
import { Tooltip } from 'antd';

const formatCsvStatusLabel = statusLabel => statusLabel?.props.id;

const colourToTagTypeMap = {
  tomato: 'error',
  dodgerblue: 'info',
  '#ffc107': 'warning',
  '#87d068': 'success'
};

const Cell = ({ rowData, formatMessage }) => {
  const { statusLabel, statusColor } = rowData;

  if (statusColor) {
    return (
      <OdyTag type={colourToTagTypeMap[statusColor]}>
        {statusLabel && statusLabel.props && formatMessage(strings[statusLabel.props.type][statusLabel.props.id])}
      </OdyTag>
    );
  }
  return <NoValueSpan />;
};

const StatusColumn = ({ trailers, formatMessage }) => {
  const categories = [
    { text: formatMessage(strings.short.critical), value: 'critical' },
    { text: formatMessage(strings.short.warning), value: 'warning' },
    { text: formatMessage(strings.short.info), value: 'info' },
    { text: formatMessage(strings.short.safe), value: 'safe' },
    { text: formatMessage(strings.short.unknown), value: 'unknown' }
  ];

  const filterFunction = checkedCategories => {
    trailers.filter('statusLabel', t => {
      if (!t.statusLabelId) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.statusLabelId];
    });
  };

  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.healthStatus)}>
        {formatMessage(strings.columns.healthStatus)}
      </Tooltip>
    ),
    key: 'statusLabel',
    dataIndex: 'statusLabel',
    searchKey: formatMessage(strings.columns.healthStatus),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    sorter: () => trailers.sort('statusLabel', statusLabelSort),
    categories: categories,
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('statusLabel'),
    width: 150
  };
};

const StatusColumnHeader = formatMessage => formatMessage(strings.columns.healthStatus);
const StatusColumnCSVData = trailer => formatCsvStatusLabel(trailer.statusLabel);
const StatusCSVColumn = {
  header: StatusColumnHeader,
  data: StatusColumnCSVData
};

export default { table: StatusColumn, csv: StatusCSVColumn };
