import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import TireMicroView from '../../../../../Utils/TireMicroView';
import './TiresColumn.scss';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const formatTiresCsvValues = tires => {
  const reducer = (acc, tire) => {
    if (tire.suff && (!acc || acc === 'No issue')) {
      return 'No issue';
    } else if (!tire.suff && acc === 'No issue') {
      return `${tire.location}`;
    } else if (!tire.suff) {
      return `${acc} ${tire.location}`;
    } else {
      return acc;
    }
  };
  return tires.reduce(reducer, '');
};

const Cell = ({ rowData, isMetric, formatMessage }) => {
  const { tires } = rowData;

  if (tires) {
    return (
      <div className={'tire-container-view'}>
        <TireMicroView formatMessage={formatMessage} trailer={rowData} semiTransparent={false} isMetric={isMetric} />
      </div>
    );
  }
  return <NoValueSpan />;
};

const TiresColumn = ({ isMetric, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.tpms)}>{formatMessage(strings.short.tpms)}</Tooltip>,
    key: 'tires',
    dataIndex: 'tires',
    selectionSection: selectionHeaders.healthMonitoring.id,
    searchKey: 'tpms',
    render: rowData => <Cell rowData={rowData} isMetric={isMetric} formatMessage={formatMessage} />,
    width: 150
  };
};

const TiresCSVHeader = formatMessage => formatMessage(strings.short.tpms);
const TiresCSVData = trailer => {
  if (trailer?.tires) {
    return formatTiresCsvValues(trailer.tires);
  }

  return '';
};
const TiresCSVColumn = {
  header: TiresCSVHeader,
  data: TiresCSVData
};

export default { table: TiresColumn, csv: TiresCSVColumn };
