import React, { Component } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';
import { injectIntl } from 'react-intl';
import EventSummaryChart from '../charts/eventSummaryChart/EventSummaryChartComponent';
import OdoEventSummaryChart from '../charts/OdoEventSummaryChart/OdoEventSummaryChartComponent';
import * as strings from '../../helpers/defaultStrings';
import { getConversionString, unitType } from '../../helpers/unitConverter';

class EventSummary extends Component {
  constructor(props) {
    super();
    const { events, eventDomain, showWithoutEvents } = props;
    this.state = {
      timeLine: (events && events.length > 0) || (showWithoutEvents && eventDomain && eventDomain.length > 0)
    };
  }

  onToggleChange = () => {
    this.setState({ timeLine: !this.state.timeLine });
  };

  render() {
    const { timeLine } = this.state;
    const {
      className,
      events,
      start,
      end,
      odoEvents,
      odoStart,
      odoEnd,
      width,
      height,
      eventDomain,
      odoEventDomain,
      showWithoutEvents,
      isMetric
    } = this.props;
    const { formatMessage } = this.props.intl;
    const showEvent = timeLine || (eventDomain && showWithoutEvents);
    const maxDistanceString = formatMessage(...getConversionString(isMetric, 10000, unitType.distance));
    const minDistanceString = formatMessage(...getConversionString(isMetric, 0, unitType.distance));

    return (
      <div>
        {((events && events.length > 0) || (odoEvents && odoEvents.length > 0) || eventDomain) && (
          <Row type='flex' justify='space-between' align='top' style={{ width: 30 + width }}>
            <div className={className}>
              {timeLine && (
                <EventSummaryChart
                  events={events || {}}
                  start={start}
                  end={end}
                  width={width}
                  height={height}
                  domain={eventDomain}
                  showWithoutEvents={showWithoutEvents}
                />
              )}
              {!timeLine && (
                <OdoEventSummaryChart
                  events={odoEvents}
                  start={odoStart}
                  end={odoEnd}
                  width={width}
                  height={height}
                  domain={odoEventDomain}
                  maxDistanceString={maxDistanceString}
                  minDistanceString={minDistanceString}
                />
              )}
            </div>
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Button
                shape='circle'
                size='small'
                onClick={this.onToggleChange}
                disabled={!events || events.length === 0 || !odoEvents || odoEvents.length === 0}
                style={{ fontSize: timeLine ? 10 : 8, width: 20, height: 20, marginTop: 5 }}
              >
                {timeLine ? (
                  <CalendarOutlined />
                ) : (
                  formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile)
                )}
              </Button>
            </div>
          </Row>
        )}
        {!showEvent && (!odoEvents || odoEvents.length === 0) && (
          <div style={{ fontSize: 10, paddingLeft: 8, color: '#cacaca' }}>
            {formatMessage(strings.phrase.noDataAvailable)}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(EventSummary);
