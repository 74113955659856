import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import numeral from 'numeral';
import { getConversionString, unitType } from '../../helpers/unitConverter';
import { isUsaCustomer, getDate } from '../../helpers/functions';
import * as strings from '../../helpers/defaultStrings';
import { selectRegion } from '../../selectors';

const TripDescription = ({ trip, intl: { formatMessage }, isConverted }) => {
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const isUS = isUsaCustomer(region);

  return (
    <div className='trailer-history-description'>
      <h3>
        <strong>{formatMessage(strings.short.trip)}</strong>
      </h3>
      <Row>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.date)}</strong>:{' '}
            {trip.timestamp ? getDate(trip.timestamp) : formatMessage(strings.short.unknown)}
          </div>
          <div>
            <strong>{formatMessage(strings.short.mileage)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.mileage, unitType.distance, false, isConverted))}
          </div>
          <div>
            <strong>{formatMessage(strings.short.duration)}</strong>: {numeral(trip.duration * 60).format('00:00:00')}
          </div>
          <div>
            <strong>{formatMessage(strings.short.distance)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.distance, unitType.distance, false, isConverted))}
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.maxSpeed)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.maxSpeed, unitType.speed, false, isConverted))}
          </div>
          <div>
            <strong>{formatMessage(strings.short.maxAxleLoad)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.maxAxleLoad, unitType.ton, isUS, isConverted))}
          </div>
          <div>
            <strong>{formatMessage(strings.short.minAxleLoad)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.minAxleLoad, unitType.ton, isUS, isConverted))}
          </div>
          <div>
            <strong>{formatMessage(strings.short.avgAxleLoad)}</strong>:{' '}
            {formatMessage(...getConversionString(isMetric, trip.avgAxleLoad, unitType.ton, isUS, isConverted))}
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>{formatMessage(strings.short.brakeCount)}</strong>: {numeral(trip.brakeCount).format('0,0.[00]')}
          </div>
          <div>
            <strong>{formatMessage(strings.short.ABSevents)}</strong>: {numeral(trip.absCount).format('0,0.[00]')}
          </div>
          <div>
            <strong>{formatMessage(strings.short.latAcceleration)}</strong>:{' '}
            {numeral(trip.latAcceleration / 100).format('0,0.[00]')}g
          </div>
        </Col>
      </Row>
    </div>
  );
};

TripDescription.propTypes = {};

export default injectIntl(TripDescription);
