import React from 'react';

const IconColumnSettings = () => (
  <span className='anticon'>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.50004 0.166687C0.862421 0.166687 0.333374 0.695734 0.333374 1.33335V10.6667C0.333374 11.3043 0.862421 11.8334 1.50004 11.8334H3.83337C4.47099 11.8334 5.00004 11.3043 5.00004 10.6667V1.33335C5.00004 0.695734 4.47099 0.166687 3.83337 0.166687H1.50004ZM6.16671 0.166687V1.33335H8.50004V3.66669H6.16671V4.83335H8.50004V7.16669H6.16671V8.33335H8.50004V10.6667H6.16671V11.8334H8.50004C9.13766 11.8334 9.66671 11.3043 9.66671 10.6667V1.33335C9.66671 0.695734 9.13766 0.166687 8.50004 0.166687H6.16671ZM1.50004 1.33335H3.83337V3.66669H1.50004V1.33335ZM1.50004 4.83335H3.83337V7.16669H1.50004V4.83335ZM1.50004 8.33335H3.83337V10.6667H1.50004V8.33335Z' />
    </svg>
  </span>
);

export default IconColumnSettings;
