import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CaretDownFilled, CaretUpOutlined } from '@ant-design/icons';
import './SortBtn.scss';
import { sendCustomEvent } from '../../../../app/helpers/new-relic';
import { selectUser } from '../../../../selectors/auth';
import { EVENT_NAMES } from '../../../../analytics/constants';

export const SortBtn = ({ colKey, onClick, sortedUp, sortedDown }) => {
  const user = useSelector(selectUser);
  const colName = colKey?.toUpperCase() ?? EVENT_NAMES.UNKNOWN;
  const pushEvent = name => sendCustomEvent(name, { identity: user?.identity });
  const iconActive = 'icon-active';
  const classUp = classNames({ [iconActive]: sortedUp });
  const classDown = classNames({ [iconActive]: sortedDown });
  const handleOnClick = e => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
    pushEvent(`${colName}_${sortedUp ? EVENT_NAMES.SORT_DESC : EVENT_NAMES.SORT_ASC}`);
  };

  return (
    <div className='vt-sort-btn' onClick={handleOnClick}>
      <CaretUpOutlined className={classUp} />
      <CaretDownFilled className={classDown} />
    </div>
  );
};

SortBtn.propTypes = {
  colKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  sortedUp: PropTypes.bool,
  sortedDown: PropTypes.bool
};
