import { defineMessages } from 'react-intl';

export const charts = defineMessages({
  histNoDataWarning: {
    id: 'charts.histNoDataWarning',
    defaultMessage: 'No data available for {value}',
    description: '{value} is a variable and should not be translated. {value} will be a string, trailer name.'
  },
  histTitleAxleLoad: {
    id: 'charts.histTitleAxleLoad',
    defaultMessage: 'Total distance / Axle load',
    description: ''
  },
  histTitleAxleLoadH: {
    id: 'charts.histTitleAxleLoadH',
    defaultMessage: 'Total distance / Axle load H',
    description: ''
  },
  histTitleAxleLoadL: {
    id: 'charts.histTitleAxleLoadL',
    defaultMessage: 'Total distance / Axle load L',
    description: ''
  },
  histTitleAxleLoadCD: {
    id: 'charts.histTitleAxleLoadCD',
    defaultMessage: 'Total distance / Axle load c, d',
    description: ''
  },
  histTitleAxleLoadEF: {
    id: 'charts.histTitleAxleLoadEF',
    defaultMessage: 'Total distance / Axle load e, f',
    description: ''
  },
  histTitleAxleLoadPerc: {
    id: 'charts.histTitleAxleLoadPerc',
    defaultMessage: 'Relative distance / Axle load',
    description: 'Title of diagram that describes the relative distance driven per axle load'
  },
  histTitleAxleLoadHPerc: {
    id: 'charts.histTitleAxleLoadHPerc',
    defaultMessage: 'Relative distance / Axle load H',
    description: 'Title of diagram that describes the relative distance driven per axle load H'
  },
  histTitleAxleLoadLPerc: {
    id: 'charts.histTitleAxleLoadLPerc',
    defaultMessage: 'Relative distance / Axle load L',
    description: 'Title of diagram that describes the relative distance driven per axle load L'
  },
  histTitleAxleLoadCDPerc: {
    id: 'charts.histTitleAxleLoadCDPerc',
    defaultMessage: 'Relative distance / Axle load c, d',
    description: 'Title of diagram that describes the relative distance driven per axle load on axle c and d'
  },
  histTitleAxleLoadEFPerc: {
    id: 'charts.histTitleAxleLoadEFPerc',
    defaultMessage: 'Relative distance / Axle load e, f',
    description: 'Title of diagram that describes the relative distance driven per axle load on axle e and f'
  },
  histTitleAxleLoadSum: {
    id: 'charts.histTitleAxleLoadSum',
    defaultMessage: 'Axle Load Sum over mileage',
    description: ''
  },
  histTitleDrawbarTrailerLoad: {
    id: 'charts.histTitleDrawbarTrailerLoad',
    defaultMessage: 'Axle load distribution for drawbar trailer over mileage',
    description: ''
  },
  histTitleDrawbarTrailerLoadPerc: {
    id: 'charts.histTitleDrawbarTrailerLoadPerc',
    defaultMessage: 'Axle load distribution for drawbar trailer over relative mileage',
    description: ''
  },
  histTitleAxleLoad24n: {
    id: 'charts.histTitleAxleLoad24n',
    defaultMessage: 'Axle load sum during 24N over mileage',
    description: ''
  },
  histTitleAxleLoad24nPerc: {
    id: 'charts.histTitleAxleLoad24nPerc',
    defaultMessage: 'Axle load sum during 24N over relative mileage',
    description: ''
  },
  histTitleAxleLoadH24n: {
    id: 'charts.histTitleAxleLoadH24n',
    defaultMessage: 'Axle load H sum during 24N over mileage',
    description: ''
  },
  histTitleAxleLoadH24nPerc: {
    id: 'charts.histTitleAxleLoadH24nPerc',
    defaultMessage: 'Axle load H sum during 24N over relative mileage',
    description: ''
  },
  histTitleAxleLoadL24n: {
    id: 'charts.histTitleAxleLoadL24n',
    defaultMessage: 'Axle load L sum during 24N over mileage',
    description: ''
  },
  histTitleAxleLoadL24nPerc: {
    id: 'charts.histTitleAxleLoadL24nPerc',
    defaultMessage: 'Axle load L sum during 24N over relative mileage',
    description: ''
  },
  histTitleAxleLoadSumPerc: {
    id: 'charts.histTitleAxleLoadSumPerc',
    defaultMessage: 'Relative Axle Load Sum over mileage',
    description: ''
  },
  histTitleBrakeAppControlPressure: {
    id: 'charts.histTitleBrakeAppControlPressure',
    defaultMessage: 'Number of brake applications / Control pressure',
    description: ''
  },
  histTitleMaxBrakePressureApplications: {
    id: 'charts.histTitleMaxBrakePressureApplications',
    defaultMessage: 'Max brake pressure over brake applications',
    description: ''
  },
  histTitleBrakeDemandPressureTime: {
    id: 'charts.histTitleBrakeDemandPressureTime',
    defaultMessage: 'Brake demand pressure over time',
    description: ''
  },
  histTitleBrakeDemandPressureApplications: {
    id: 'charts.histTitleBrakeDemandPressureApplications',
    defaultMessage: 'Brake demand pressure over applications',
    description: ''
  },
  histTitleBrakeTimeControlPressure: {
    id: 'charts.histTitleBrakeTimeControlPressure',
    defaultMessage: 'Braking time / Control pressure',
    description: ''
  },
  histTitleTimeSupplyPressure: {
    id: 'charts.histTitleTimeSupplyPressure',
    defaultMessage: 'Time / Supply pressure',
    description: ''
  },
  histTitleTimeSpeed: {
    id: 'charts.histTitleTimeSpeed',
    defaultMessage: 'Time / Speed',
    description: ''
  },
  histTitleBrakeAppDownhillGrad: {
    id: 'charts.histTitleBrakeAppDownhillGrad',
    defaultMessage: 'Number of brake applications / Down hill gradient at start of braking',
    description: ''
  },
  histTitleTebsfBrakeAppDeceleration: {
    id: 'charts.histTitleTebsfBrakeAppDeceleration',
    defaultMessage: 'Number of brake applications / Deceleration for TEBS-F',
    description: ''
  },
  histTitleTebseBrakeAppDeceleration: {
    id: 'charts.histTitleTebseBrakeAppDeceleration',
    defaultMessage: 'Number of brake applications / Deceleration for TEBS-E',
    description: ''
  },
  histTitleDistanceLatAcceleration: {
    id: 'charts.histTitleDistanceLatAcceleration',
    defaultMessage: 'Total distance / Lateral acceleration',
    description: ''
  },
  histTitleDistanceLatAccelerationPerc: {
    id: 'charts.histTitleDistanceLatAccelerationPerc',
    defaultMessage: 'Relative distance / Lateral acceleration',
    description: 'Title of diagram that describes the relative distance driven per lateral acceleration'
  },
  histTitleRoadSurfaceMean: {
    id: 'charts.histTitleRoadSurfaceMean',
    defaultMessage: 'Road surface mean over mileage',
    description: 'Title of diagram that describes the road surface mean over mileage'
  },
  histTitleRoadSurfaceMax: {
    id: 'charts.histTitleRoadSurfaceMax',
    defaultMessage: 'Road surface max over mileage',
    description: 'Title of diagram that describes the road surface max over mileage'
  },
  histTitleFrameStressMean: {
    id: 'charts.histTitleFrameStressMean',
    defaultMessage: 'Frame stress mean over mileage',
    description: 'Title of diagram that describes the frame stress mean over mileage'
  },
  histTitleFrameStressMax: {
    id: 'charts.histTitleFrameStressMax',
    defaultMessage: 'Frame stress max over mileage',
    description: 'Title of diagram that describes the frame stress max over mileage'
  },
  histTitleECUTemperature: {
    id: 'charts.histTitleECUTemperature',
    defaultMessage: 'ECU temperature stress over mileage',
    description: 'Title of diagram that describes the ECU temperature stress over mileage'
  },
  histXSubTitleAxleLoadValue: {
    id: 'charts.histXSubTitleAxleLoadValue',
    defaultMessage: 'Axle load percentage (100% equals a load of {value})',
    description:
      'Explanation of values in a chart, {value} is a variable should not be translated and will be a value of load (ton or kilopound)'
  },
  histXlabelAxleLoadValue: {
    id: 'charts.histXlabelAxleLoadValue',
    defaultMessage: 'Axle load percentage: 100% equals a load of {value}',
    description:
      'Explanation of values in a chart, {value} is a variable should not be translated and will be a value of load (ton or kilopound)'
  },
  histXlabelAxleLoad: {
    id: 'charts.histXlabelAxleLoad',
    defaultMessage: 'Axle load percentage: 100% equals a load of 27t',
    description: 'Explanation of values in a chart (t for tons)'
  },
  histXlabelAxleLoadImperial: {
    id: 'charts.histXlabelAxleLoadImperial',
    defaultMessage: 'Axle load percentage: 100% equals a load of 26.5t',
    description: 'Explanation of values in a chart (t for tons)'
  },
  histXlabelAxleLoadUSA: {
    id: 'charts.histXlabelAxleLoadUSA',
    defaultMessage: 'Axle load percentage: 100% equals a load of 59.5k',
    description: 'Explanation of values in a chart (k for kilopounds)'
  },
  histXlabelControlPressureBar: {
    id: 'charts.histXlabelControlPressureBar',
    defaultMessage: 'Control pressure in bar',
    description: 'bar pressure unit'
  },
  histXlabelControlPressurePsi: {
    id: 'charts.histXlabelControlPressurePsi',
    defaultMessage: 'Control pressure in psi',
    description: 'Explanation of values in a chart (psi for pounds per square inch)'
  },
  histXlabelBrakeDemandPressureBar: {
    id: 'charts.histXlabelBrakeDemandPressureBar',
    defaultMessage: 'Brake demand pressure in bar',
    description: 'bar unit of pressure'
  },
  histXlabelBrakeDemandPressurePsi: {
    id: 'charts.histXlabelBrakeDemandPressurePsi',
    defaultMessage: 'Brake demand pressure in psi',
    description: 'Values explanation in a chart (psi for pounds per square inch)'
  },
  histXlabelMaxBrakePressureBar: {
    id: 'charts.histXlabelMaxBrakePressureBar',
    defaultMessage: 'Max brake pressure in bar',
    description: 'bar unit pressure'
  },
  histXlabelMaxBrakePressurePsi: {
    id: 'charts.histXlabelMaxBrakePressurePsi',
    defaultMessage: 'Max brake pressure in psi',
    description: 'Explanation of values in chart (psi for pounds per square inch)'
  },
  histXlabelSupplyPressureBar: {
    id: 'charts.histXlabelSupplyPressureBar',
    defaultMessage: 'Supply pressure in bar',
    description: 'Explanation of values in a chart (bar being  unit of pressure)'
  },
  histXlabelSupplyPressurePsi: {
    id: 'charts.histXlabelSupplyPressurePsi',
    defaultMessage: 'Supply pressure in psi',
    description: 'Explanation of values in a chart (psi for pounds / square inch)'
  },
  histXlabelSpeedkmph: {
    id: 'charts.histXlabelSpeedkmph',
    defaultMessage: 'Speed in km/h',
    description: 'Explanation of values in a chart (km/h being unit of speed kilometers per hour)'
  },
  histXlabelSpeedMph: {
    id: 'charts.histXlabelSpeedMph',
    defaultMessage: 'Speed in mph',
    description: 'Explanation of values in a chart (mph being unit of speed miles per hour)'
  },
  histXlabelDownhillGradPerc: {
    id: 'charts.histXlabelDownhillGradPerc',
    defaultMessage: 'Downhill gradient in %',
    description: ''
  },
  histXlabelDecelerationInG: {
    id: 'charts.histXlabelDecelerationInG',
    defaultMessage: 'Deceleration in g',
    description: 'g is gravity'
  },
  histXlabelLatAccelerationInG: {
    id: 'charts.histXlabelLatAccelerationInG',
    defaultMessage: 'Lateral acceleration in g',
    description: 'g is gravity'
  },
  histXlabelMeanValue: {
    id: 'charts.histXlabelMeanValue',
    defaultMessage: 'Mean value',
    description: ''
  },
  histXlabelMaxValue: {
    id: 'charts.histXlabelMaxValue',
    defaultMessage: 'Max value',
    description: ''
  },
  histXlabelECUTemperatureInCelsius: {
    id: 'charts.histXlabelECUTemperatureInCelsius',
    defaultMessage: 'ECU temperature stress in °C',
    description: ''
  },
  histXlabelECUTemperatureInFahrenheit: {
    id: 'charts.histXlabelECUTemperatureInFahrenheit',
    defaultMessage: 'ECU temperature stress in °F',
    description: ''
  },
  histYlabelBrakeApplications: {
    id: 'charts.histYlabelBrakeApplications',
    defaultMessage: 'Brake applications',
    description: ''
  },
  histYlabelDistance: {
    id: 'charts.histYlabelDistance',
    defaultMessage: 'Distance',
    description: 'naoi'
  },
  histYlabelDistancePerc: {
    id: 'charts.histYlabelDistancePerc',
    defaultMessage: 'Distance: 100% equals total distance for each trailer',
    description: 'Label inside a diagram'
  },
  histYlabelNumber: {
    id: 'charts.histYlabelNumber',
    defaultMessage: 'Number',
    description: ''
  },
  histYlabelNumberPerc: {
    id: 'charts.histYlabelNumberPerc',
    defaultMessage: 'Number: 100% equals total number for each trailer',
    description: 'Label inside a diagram'
  },
  histYlabelSeconds: {
    id: 'charts.histYlabelSeconds',
    defaultMessage: 'Seconds',
    description: ''
  },
  histYlabelSecondsPerc: {
    id: 'charts.histYlabelSecondsPerc',
    defaultMessage: 'Seconds: 100% equals total seconds for each trailer',
    description: 'Label inside a diagram'
  },
  histYlabelHours: {
    id: 'charts.histYlabelHours',
    defaultMessage: 'Hours',
    description: ''
  },
  histYlabelHoursPerc: {
    id: 'charts.histYlabelHoursPerc',
    defaultMessage: 'Hours: 100% equals total hours for each trailer',
    description: 'Label inside a diagram'
  },
  histYPointHoursMin: {
    id: 'charts.histYPointHoursMin',
    defaultMessage: 'HR:M',
    description: 'hour minutes'
  },
  histChartDataNotloaded: {
    id: 'charts.histChartDataNotloaded',
    defaultMessage: 'Chart data could not be loaded',
    description: ''
  },
  histSwitchTitle: {
    id: 'charts.histSwitchTitle',
    defaultMessage: ' Relative values on y-axis',
    description: ''
  },
  switchIdCArd: {
    id: 'charts.switchIdCArd',
    defaultMessage: 'Switch to {value} trailer ID card? ',
    description: '{value} is a variable and should not be translated. {value} is the name of a trailer'
  },
  averageShort: {
    id: 'charts.averageShort',
    defaultMessage: 'avg',
    description: 'Shorthand for average'
  },
  profileMileageTitle: {
    id: 'charts.profileMileageTitle',
    defaultMessage: 'Mileage',
    description: ''
  },
  profileMileageSubTitle: {
    id: 'charts.profileMileageSubTitle',
    defaultMessage: 'Total mileage in {value}',
    description: '{value} is a variable and should not be translated. {value} will be "kilometers" or "miles"'
  },
  profileBrakeFrequencyTitle: {
    id: 'charts.profileBrakeFrequencyTitle',
    defaultMessage: 'Brake frequency',
    description: 'Frequency of brake applications in a vehicle'
  },
  profileBrakeFrequencySubTitle: {
    id: 'charts.profileBrakeFrequencySubTitle',
    defaultMessage: 'Number of brake applications per {value}',
    description: '{value} is a variable and should not be translated. {value} will be "kilometer" or "mile"'
  },
  profileAvgCPressureTitle: {
    id: 'charts.profileAvgCPressureTitle',
    defaultMessage: 'Average control pressure',
    description: 'Average value of the control pressure pm at the yellow coupling head'
  },
  profileAvgCPressureSubTitle: {
    id: 'charts.profileAvgCPressureSubTitle',
    defaultMessage: 'Average value of the control pressure pm at the yellow coupling head in {value}',
    description: '{value} is a variable and should not be translated. {value} will be "bar" or "psi"'
  },
  profileAbsInterventionsTitle: {
    id: 'charts.profileAbsInterventionsTitle',
    defaultMessage: 'ABS interventions',
    description: 'Number of ABS interventions per 10,000km"'
  },
  profileAbsInterventionsSubTitle: {
    id: 'charts.profileAbsInterventionsSubTitle',
    defaultMessage: 'Number of ABS interventions per 10,000 {value}',
    description: '{value} is a variable and should not be translated. {value} will be "km" or "mi"'
  },
  profileStage1RssTitle: {
    id: 'charts.profileStage1RssTitle',
    defaultMessage: 'Stage 1 RSS interventions',
    description: 'Number of RSS test brake applications per 10,000 km. RSS - Roll Stability Support'
  },
  profileStage1RssSubTitle: {
    id: 'charts.profileStage1RssSubTitle',
    defaultMessage: 'Number of RSS test brake applications per 10,000 {value}',
    description:
      '{value} is a variable and should not be translated. {value} will be "km" or "mi" RSS - Roll Stability Support'
  },
  profileStage2RssTitle: {
    id: 'charts.profileStage2RssTitle',
    defaultMessage: 'Stage 2 RSS interventions',
    description: 'Number of RSS deceleration brake applications per 10,000 km RSS - Roll Stability Support'
  },
  profileStage2RssSubTitle: {
    id: 'charts.profileStage2RssSubTitle',
    defaultMessage: 'Number of RSS deceleration brake applications per 10,000 {value}',
    description:
      '{value} is a variable and should not be translated. {value} will be "km" or "mi" RSS - Roll Stability Support'
  },
  profileHandbrakeFreqTitle: {
    id: 'charts.profileHandbrakeFreqTitle',
    defaultMessage: 'Handbrake frequency',
    description: 'Number of handbrake applications per 10,000 km'
  },
  profileHandbrakeFreqSubTitle: {
    id: 'charts.profileHandbrakeFreqSubTitle',
    defaultMessage: 'Number of handbrake applications per 10,000 {value}',
    description: '{value} is a variable and should not be translated. {value} will be "km" or "mi"'
  }
});
