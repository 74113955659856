import React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useDispatch, useSelector } from 'react-redux';
import { mobileOnly } from '../../helpers/responsive';
import actions from '../../actions';
import { triggerEvent } from '../../helpers/googleAnalytics';
import ComponentToggleButton from '../Utils/ComponentToggleButton';
import TrailerMap from './left/TrailerMapV2Container';
import RightDrawer from './right/RightDrawer';
import { selectTntRightPanelWidth, selectTntRightPanelOpen } from '../../selectors';

export const LiveMapV2Layout = ({ trailers }) => {
  const trailerDrawerWidth = useSelector(selectTntRightPanelWidth);
  const trailerDrawerOpen = useSelector(selectTntRightPanelOpen);

  const dispatch = useDispatch();

  const onStopResize = e => {
    const width = e.component.props.flex;
    triggerEvent('AppState', 'Resized Live Map screen', 'Live Map screen', Math.round(width));
    dispatch(actions.settings.saveSetting('tntRightPanelWidth', width));
  };

  const hideTrailerDrawer = () => {
    triggerEvent('AppState', 'Closed Trailer Drawer', 'Live Map screen');
    dispatch(actions.settings.saveSetting('tntRightPanelOpen', false));
  };
  const showTrailerDrawer = () => {
    triggerEvent('AppState', 'Opened Trailer Drawer', 'Live Map screen');
    dispatch(actions.settings.saveSetting('tntRightPanelOpen', true));
  };

  const isMobile = mobileOnly.matches;

  return (
    <>
      <ReflexContainer orientation='vertical' className='trailer-map-list-container'>
        <ReflexElement
          flex={1 - (!isMobile && trailerDrawerOpen ? trailerDrawerWidth : 0)}
          style={{ position: 'relative' }}
        >
          <TrailerMap trailers={trailers} />
          <ComponentToggleButton isOpen={trailerDrawerOpen} onClose={hideTrailerDrawer} onOpen={showTrailerDrawer} />
        </ReflexElement>

        {trailerDrawerOpen && <ReflexSplitter />}
        {trailerDrawerOpen && (
          <ReflexElement
            flex={trailerDrawerWidth}
            minWidth={500}
            style={{ backgroundColor: '#e9edee' }}
            onStopResize={onStopResize}
          >
            <RightDrawer trailers={trailers} />
          </ReflexElement>
        )}
      </ReflexContainer>
    </>
  );
};
