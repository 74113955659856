import { push } from 'connected-react-router';

import { worker } from '../../../app/web-workers/aws-web-worker';
import { MESSAGE_TYPES } from '../../../workers/constants';
import { logoutRequest, logoutSuccess, logoutError } from '../../auth';
import { redirectToPortal } from './helper';

export const logout = () => {
  return async dispatch => {
    dispatch(logoutRequest());

    try {
      await worker.wrapMessageInPromise({
        types: {
          request: MESSAGE_TYPES.LOGOUT_REQUEST,
          success: MESSAGE_TYPES.LOGOUT_SUCCESS,
          error: MESSAGE_TYPES.LOGOUT_ERROR
        }
      });
      window.localStorage.removeItem('transics-token');
      dispatch(logoutSuccess());
      redirectToPortal();
      dispatch(push('/'));
    } catch (error) {
      dispatch(logoutError(error));
    }
  };
};
