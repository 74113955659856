import moment from 'moment';
import { mobileOnly } from '../../../../helpers/responsive';

export const PAGE_TABS = {
  default: 'default',
  subscriptionSettings: 'subscriptionSettings'
};

export const PAGE_SIZE = 20;

export const defaultDates = [
  moment(moment().subtract(2, 'd'), 'YYYY/MM/DD').startOf('day'),
  moment(moment(), 'YYYY/MM/DD')
];

export const isMobile = mobileOnly.matches;

export const date = new Date().toISOString();
