import { LOADING_STATUSES } from '../helpers/constants';

// the option `withNamespace' implies the state uses the `thunkType' to nest multiple requests under the current reducer.
export const generateAsyncThunkReducers = ({ withNamespace = false, thunkType, fulfilledStateReducer }) => {
  return {
    [`${thunkType}/pending`]: (reducerState, action) => {
      const state = withNamespace ? reducerState[thunkType] : reducerState;
      if (state.loading === LOADING_STATUSES.IDLE) {
        state.loading = LOADING_STATUSES.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [`${thunkType}/fulfilled`]: (reducerState, action) => {
      const state = withNamespace ? reducerState[thunkType] : reducerState;
      const { requestId } = action.meta;
      if (state.loading === LOADING_STATUSES.PENDING && state.currentRequestId === requestId) {
        state.loading = LOADING_STATUSES.IDLE;
        state.dataLoaded = true;
        state.currentRequestId = undefined;
        fulfilledStateReducer(state, action);
      }
    },
    [`${thunkType}/rejected`]: (reducerState, action) => {
      const state = withNamespace ? reducerState[thunkType] : reducerState;
      const { requestId } = action.meta;
      if (state.loading === LOADING_STATUSES.PENDING && state.currentRequestId === requestId) {
        state.loading = LOADING_STATUSES.IDLE;
        state.dataLoaded = false;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    }
  };
};
