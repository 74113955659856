import React from 'react';
import { Row, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { getTimeDate } from '../../helpers/functions';
import { getConversionString, unitType } from '../../helpers/unitConverter';

const TireLayout = (data, oldDataTime, semiTransparent, intl, isMetric) => (
  <Row type='flex' style={{ marginBottom: 4 }}>
    <div
      style={{
        width: 15,
        height: 40,
        backgroundColor: oldDataTime
          ? 'darkBlue'
          : data && data.pressure && (data.pressure.suff !== null || data.pressure.suff !== undefined)
          ? data.pressure.suff
            ? 'black'
            : 'tomato'
          : 'lightGray',
        borderRadius: 4,
        opacity: semiTransparent || oldDataTime ? 0.5 : 1
      }}
    />
    {!data && <div className='medium-margin-left'>{intl.formatMessage(strings.short.noData)}</div>}
    {data && (
      <div className='medium-margin-right medium-margin-left'>
        <div>
          {data.pressure !== null && data.pressure.val !== null
            ? intl.formatMessage(...getConversionString(isMetric, data.pressure.value, unitType.pressure))
            : ''}
        </div>
        <div>
          {data.temp != null && data.temp !== 65536
            ? intl.formatMessage(...getConversionString(isMetric, data.temp, unitType.temp))
            : ''}
        </div>
      </div>
    )}
  </Row>
);

const Tire = ({ data, semiTransparent, intl, isMetric }) => {
  let oldDataTime;
  if (data && data.pressure && data.pressure.time && !semiTransparent) {
    oldDataTime = data.pressure.time;
  }
  if (oldDataTime) {
    return (
      <Tooltip
        title={intl.formatMessage(strings.short.ebsSingleTireTimeVal, {
          value: getTimeDate(oldDataTime * 1000)
        })}
        placement='topLeft'
      >
        {TireLayout(data, oldDataTime, semiTransparent, intl, isMetric)}
      </Tooltip>
    );
  } else {
    return TireLayout(data, oldDataTime, semiTransparent, intl, isMetric);
  }
};

export default injectIntl(Tire);
