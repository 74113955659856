import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../../helpers/defaultStrings';

export const uniqueKeys = list => {
  const uniqueList = [];
  const keyDic = {};
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element && !keyDic[element]) {
      keyDic[element] = true;
      uniqueList.push(element);
    }
  }
  return uniqueList;
};

export const UniqueCategories = (list, showUnknownOption = false) => {
  const formatMessage = useIntl().formatMessage;
  const uniqueCategoryList = [];
  const keyDic = {};
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element && !keyDic[element]) {
      keyDic[element] = true;
      uniqueCategoryList.push({ text: element, value: element });
    }
  }
  if (showUnknownOption) {
    uniqueCategoryList.push({ text: formatMessage(strings.short.unknown), value: 'unknown' });
  }
  return uniqueCategoryList;
};

export const UniqueCategoriesValueText = (list, showUnknownOption = false) => {
  const formatMessage = useIntl().formatMessage;
  const uniqueCategoryList = [];
  const keyDic = {};
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element?.value && !keyDic[element.value]) {
      keyDic[element.value] = true;
      uniqueCategoryList.push({ text: element.text, value: element.value });
    }
  }
  if (showUnknownOption) {
    uniqueCategoryList.push({ text: formatMessage(strings.short.unknown), value: 'unknown' });
  }
  return uniqueCategoryList;
};

export const NoValueSpan = () => {
  const formatMessage = useIntl().formatMessage;
  return <span className='disabled'>{formatMessage(strings.short.singleHyphen)}</span>;
};

export const alphaNumericSorter = (a, b, key) => {
  if (a[key] == null) return 1;
  if (b[key] == null) return -1;
  const aIntValue = parseInt(a[key]);
  const bIntValue = parseInt(b[key]);
  if (Number.isNaN(aIntValue) && Number.isNaN(bIntValue)) {
    return a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1;
  }
  if (Number.isNaN(bIntValue)) {
    return -1;
  }
  return aIntValue - bIntValue;
};
