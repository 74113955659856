import { defineMessages } from 'react-intl';

// Trailer Status
export const status = defineMessages({
  moving: {
    id: 'status.moving',
    defaultMessage: 'Moving',
    description: ''
  },
  poweredDown: {
    id: 'status.poweredDown',
    defaultMessage: 'Powered down',
    description: ''
  },
  standstill: {
    id: 'status.standstill',
    defaultMessage: 'Standstill',
    description: ''
  }
});
