/* eslint-disable no-loop-func */
/* eslint-disable no-case-declarations */
import { maxBy } from '../../helpers/arrays';
import { mapValues } from '../../helpers/objects';
import moment from 'moment';
import {
  disableMapMatching,
  maxMatchedDataPointsToRender,
  priorityMapping,
  animatedHeadMaxInterval
} from './constants';
import { getDirectionBetween } from '../../helpers/coordinates';
import score from '../../Components/Score/Score';
import polyline from '@mapbox/polyline';
import tools from './helperTools';
import clean from './cleanerFunctions';
import { alarms } from '../../helpers/constants';
import { getIsTraileriABS } from '../../helpers/functions';
import { transformMonthlyAndAssets } from './transformations/loadingEvents';
import { getWheelTPMSValue } from '../../helpers/trailer';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { parseJson } from '../../helpers/parse-json';

const {
  REACT_APP_NOTIFICATIONS_MAX_PER_TYPE,
  REACT_APP_NOTIFICATIONS_API_OLDEST,
  REACT_APP_MAX_DEVICES_DATA,
  REACT_APP_MAP_MATCHING_CONFIDENCE_THRESHOLD,
  REACT_APP_MQQT_MIN_PROCESSING_INTERVAL
} = ENV_CONFIG;

const formatGOHC = gohc => {
  if (gohc && gohc.operation_hours && gohc.operation_hours !== 4294967295) {
    const operationHours = Math.floor(gohc.operation_hours / 3600);
    const nextService = Math.floor(gohc.next_service / 3600);
    const serviceIntervalCust = Math.floor(gohc.service_interval_cust / 3600);
    const timeInService = operationHours - (nextService - serviceIntervalCust);
    const percOfTimeSpent = (timeInService / serviceIntervalCust) * 100;

    let status = 'danger';
    if (percOfTimeSpent <= 10) {
      status = 'empty';
    } else if (percOfTimeSpent <= 80) {
      status = 'safe';
    } else if (percOfTimeSpent <= 99) {
      status = 'warning';
    }
    return {
      ...gohc,
      status,
      timeInService,
      percOfTimeSpent,
      serviceTime: serviceIntervalCust
    };
  }
  return gohc;
};

const processTrailerEvents = (state, trailers) => {
  const {
    //computeRecencyScore,
    //computeDurationScore,
    //computeFrequencyScore,
    computeHealthScore,
    getDaysSinceLastEvent,
    getAverageSequenceDuration,
    getAverageDaysBetweenEvents
  } = score;

  return (
    trailers
      .map(d => ({
        ...d,
        lastMileage: d.last_fms && d.last_fms.km !== null ? d.last_fms.km : null
      }))
      .map(d => {
        const trailer = state?.trailers?.items?.find(trailer => trailer.assetId === d.assetId);
        const isTraileriABS = getIsTraileriABS(trailer);
        return {
          ...d,
          events:
            d.daily_events && d.daily_events.events
              ? d.daily_events.events
                  .map(e => ({
                    ...e,
                    date: moment.utc(new Date(e.date)).toDate(),
                    color: priorityMapping[+e.priority + 1].color,
                    rawDate: e.date
                  }))
                  // Making sure we take the full history when dealing with the 900 static ODR files TODO remove d.deviceIMEI === d.assetId once we don't need it anymore
                  .filter(
                    e => (d.imei === d.assetId || moment.utc().subtract(1, 'year') <= e.date) && e.date <= moment.utc()
                  )
              : null,
          eventDomain:
            d.daily_events && d.daily_events.start && d.daily_events.stop
              ? [
                  moment.utc(new Date(d.daily_events.start)).toDate(),
                  moment.utc(new Date(d.daily_events.stop)).toDate()
                ]
              : null,
          odoEvents:
            d['100km_events'] && d['100km_events'].events
              ? d['100km_events'].events.map(e => ({
                  ...e,
                  lastMileage: d['100km_events'].stop ? Math.abs(e.mileage - d['100km_events'].stop) : null,
                  color: priorityMapping[+e.priority + 1].color
                }))
              : null,
          odoEventDomain:
            d['100km_events'] && d['100km_events'].stop && d['100km_events'].start
              ? [d['100km_events'].start, d['100km_events'].stop]
              : null, //d['100km_events'] && d['100km_events'].start && d['100km_events'].stop ? [d['100km_events'].stop - d['100km_events'].start, 0] : null,
          lastDtcEvent: d.last_known_odr_dtc_event
            ? clean.dtcEvent(state, d.last_known_odr_dtc_event, Math.random(), isTraileriABS)
            : null,
          lastRecordedEvent: d.last_known_odr_event ? clean.recordedEvent(d.last_known_odr_event) : null,
          lastTrip: d.last_known_odr_trip ? clean.trailerTrip(d.last_known_odr_trip) : null,
          sinceKm: d.last_fms && d.last_odr && d.last_fms.km && d.last_odr.km ? d.last_fms.km - d.last_odr.km : null,
          sinceDays:
            d.last_odr && d.last_odr.parsing_timestamp
              ? moment().diff(moment.utc(d.last_odr.parsing_timestamp), 'days')
              : null,
          fmsParsingStatus: d.last_fms && d.last_fms.odr_status ? d.last_fms.odr_status : null,
          odrParsingStatus: d.last_odr && d.last_odr.status_parsing ? d.last_odr.status_parsing : null,
          GOHC: formatGOHC(d.GOHC)
        };
      })
      //.filter(t => t.events && t.events.length > 0)
      .map(d => ({
        ...d,
        lastDate: d.events && d.events.length > 0 ? maxBy(d.events, e => e.date).date : null,
        firstOdrDate: d.daily_events && d.daily_events.start ? moment.utc(d.daily_events.start) : null,
        lastOdrDate: d.daily_events && d.daily_events.stop ? moment.utc(d.daily_events.stop) : null,
        since:
          d.events && d.events.length > 0
            ? Math.max(
                1,
                moment.utc().diff(
                  moment.utc(
                    maxBy(d.events, e => e.date)
                      .date.toISOString()
                      .substring(0, 10)
                  ),
                  'days'
                )
              )
            : null,
        statusPriority: tools.getStatusPriority(d)
      }))
      // Score computation
      .map(d => ({
        ...d,
        ...computeHealthScore(d.events, d.eventDomain, d.odoEvents, d.odoEventDomain),
        // Used for analytics
        criticalDaysSinceLastEvent:
          d.events && d.events.length > 0 ? getDaysSinceLastEvent(d.events, d.lastOdrDate, 2) : null,
        warningDaysSinceLastEvent:
          d.events && d.events.length > 0 ? getDaysSinceLastEvent(d.events, d.lastOdrDate, 1) : null,
        criticalAverageSequenceDuration:
          d.events && d.events.length > 0 ? getAverageSequenceDuration(d.events, 2) : null,
        warningAverageSequenceDuration:
          d.events && d.events.length > 0 ? getAverageSequenceDuration(d.events, 1) : null,
        daysBetweenCriticalEvents:
          d.events && d.events.length > 0
            ? getAverageDaysBetweenEvents(d.events, d.firstOdrDate, d.lastOdrDate, 2)
            : null,
        daysBetweenWarningEvents:
          d.events && d.events.length > 0
            ? getAverageDaysBetweenEvents(d.events, d.firstOdrDate, d.lastOdrDate, 1)
            : null
      }))
      .map(d => ({
        ...d,
        statusColor: d.statusPriority !== null ? priorityMapping[d.statusPriority].color : null,
        statusLabel: d.statusPriority !== null ? priorityMapping[d.statusPriority].name : null,
        statusLabelId: d.statusPriority !== null ? priorityMapping[d.statusPriority].id : null
      }))
  );
};

function combine(state, trailers, eventHistory) {
  const eventsMap = processTrailerEvents(state, eventHistory).reduce((mapping, trailer) => {
    mapping[trailer.assetId] = trailer;
    return mapping;
  }, {});

  const combinedData = trailers.map(trailer => {
    const combinedData = {
      ...trailer,
      ...eventsMap[trailer.assetId]
    };
    const odrStatus = tools.getOdrStatusObject(combinedData);
    return {
      ...combinedData,
      odrStatus,
      statusPriority:
        trailer.statusPriority == null && odrStatus && odrStatus.id.startsWith('Decoded')
          ? trailer.statusPriority
          : null,
      ...(combinedData.healthScore == null && odrStatus && odrStatus.id && odrStatus.id.startsWith('Decoded')
        ? {
            healthScoreType: 'timeBased',
            healthScore: 0,
            recencyScore: 0,
            durationScore: 0,
            frequencyScore: 0
          }
        : combinedData.healthScore)
    };
  });
  return combinedData;
}

function computeEvolution(trailers) {
  const allWeeks = [];
  const yearStart = moment.utc().subtract(1, 'year');
  const yearEnd = moment.utc();
  for (let date = yearStart; date < yearEnd; date = moment(date.clone().add(7, 'd').toDate())) {
    allWeeks.push({
      date: date,
      priority: -2
    });
  }
  const trailersWeekly = trailers
    .filter(
      trailer =>
        trailer.daily_events && trailer.daily_events.events && trailer.daily_events.start && trailer.daily_events.stop
    )
    .map(trailer => {
      const events = (trailer.daily_events.events || []).map(e => ({
        date: moment.utc(e.date),
        priority: e.priority
      }));
      const start = moment.utc(trailer.daily_events.start, 'YYYY-MM-DD');
      const end = moment.utc(trailer.daily_events.stop, 'YYYY-MM-DD');
      const daysOfActivity = end.diff(start, 'days') || 0;
      const activityEvents = new Array(daysOfActivity).fill(0).map((_, i) => ({
        date: moment(start.clone().add(i, 'd').toDate()),
        priority: -1
      }));
      return events
        .concat(activityEvents)
        .filter(e => e.date.isBetween(moment.utc().subtract(1, 'year'), moment.utc()));
    })
    .filter(trailerEvents => trailerEvents.length > 0)
    .map(trailerEvents => trailerEvents.reduce(weekReduce, {}));

  trailersWeekly.push(allWeeks.reduce(weekReduce, {}));

  const weekPriorityList = trailersWeekly.reduce((weekPriorities, t) => {
    const weekPriority = Object.entries(t).map(([week, priority]) => ({
      week: moment.utc(week).local().format('YYYY-MM-DD'),
      priority
    }));
    return weekPriorities.concat(weekPriority);
  }, []);

  const weeklyEventCounts = weekPriorityList.reduce((counts, { week, priority }) => {
    if (!counts[week]) {
      counts[week] = {
        date: week,
        safe: 0,
        info: 0,
        warning: 0,
        critical: 0,
        unknown: 0
      };
    }

    if (priority === 2) {
      counts[week].critical += 1;
    } else if (priority === 1) {
      counts[week].warning += 1;
    } else if (priority === 0) {
      counts[week].info += 1;
    } else if (priority === -1) {
      counts[week].safe += 1;
    } else {
      counts[week].unknown += 1;
    }
    return counts;
  }, {});

  return Object.values(weeklyEventCounts);
}

function treatTrailerHistory(history) {
  let firstPoint = null;
  const route = history
    .map(v => ({ ...v, ...v.gnss, ...v.header }))
    .filter(v => v.valid && (v.latitude || v.longitude))
    .reduce((agg, elem, index) => {
      if (index === 0) {
        firstPoint = elem;
      } else {
        const startEvent = index === 1 ? firstPoint.event : agg[agg.length - 1].endEvent;
        const startSequence = index === 1 ? firstPoint.sequence : agg[agg.length - 1].endSequence;
        const startGnss = index === 1 ? firstPoint.gnss : agg[agg.length - 1].endGnss;
        const startSpeed = index === 1 ? tools.getSpeed(firstPoint) : agg[agg.length - 1].endSpeed;
        const startAxleLoad = index === 1 ? tools.getAxleLoad(firstPoint) : agg[agg.length - 1].endAxleLoad;
        const endSpeed = tools.getSpeed(elem);
        const endAxleLoad = tools.getAxleLoad(elem);
        const startTime = index === 1 ? firstPoint.time : agg[agg.length - 1].endTime;
        const avgSegSpeed =
          startSpeed !== null && endSpeed !== null
            ? Math.round((startSpeed + endSpeed) / 2)
            : startSpeed !== null
            ? startSpeed
            : endSpeed;
        const avgAxleLoad =
          startAxleLoad !== null && endAxleLoad !== null
            ? Math.round((startAxleLoad + endAxleLoad) / 2)
            : startAxleLoad !== null
            ? startAxleLoad
            : endAxleLoad;

        agg.push({
          from: index === 1 ? [firstPoint.longitude, firstPoint.latitude] : agg[agg.length - 1].to,
          to: [elem.longitude, elem.latitude],
          distance: 0,
          duration: elem.time - startTime,
          startSpeed,
          endSpeed,
          startAxleLoad,
          endAxleLoad,
          speed: avgSegSpeed,
          axleLoad: avgAxleLoad,
          imei: elem.imei,
          assetId: elem.assetId,
          startTime,
          endTime: elem.time,
          startEvent,
          endEvent: elem.event,
          startGnss,
          endGnss: elem.gnss,
          startSequence,
          endSequence: elem.sequence,
          source: 'fmsHistory'
        });
      }
      return agg;
    }, []);

  return route;
}

function weekReduce(weeklyStatus, e) {
  const week = e.date ? e.date.startOf('isoWeek').toISOString() : null;
  if (week) {
    weeklyStatus[week] = weeklyStatus[week] ? Math.max(weeklyStatus[week], e.priority) : e.priority;
  }
  return weeklyStatus;
}

export function getCleanedTrailer(state, trailer, trailersDefaultDisplayNames) {
  return clean.trailer(state, trailer, trailersDefaultDisplayNames);
}

export function formatTrailersEventHistoryWeeklyEvolution(state, trailers, final) {
  const cleanTrailers = [...(state.trailerEventHistory.items || []), ...trailers];
  const trailerEventHistory = {
    items: cleanTrailers,
    processing: !final,
    error: null,
    done: final
  };
  let newTrailers = state.trailers;
  let weeklyEvolution = state.weeklyEvolution;
  if (final && state.trailers.items) {
    const combinedData = combine(state, state.trailers.items, cleanTrailers);
    newTrailers = {
      items: combinedData,
      processing: false,
      error: null,
      done: true
    };
    const computedWeeklyEvolution = computeEvolution(combinedData);
    weeklyEvolution = {
      items: computedWeeklyEvolution,
      processing: false,
      error: null
    };
  }

  return {
    trailerEventHistory: trailerEventHistory,
    weeklyEvolution: weeklyEvolution,
    trailers: newTrailers
  };
}

export function formatTrailersWeeklyEvolutionDefaultDisplayNames(state, devices) {
  const cleanTrailers = clean.trailers(devices);
  const trailersDefaultDisplayNames = tools.getDefaultDisplayNames(devices);
  let trailers;
  let weeklyEvolution;
  if (!state.trailerEventHistory.done) {
    trailers = {
      items: cleanTrailers,
      processing: false,
      error: null,
      done: false
    };
    weeklyEvolution = state.weeklyEvolution;
  } else {
    const combinedData = combine(state, cleanTrailers, state.trailerEventHistory.items);
    trailers = {
      items: combinedData,
      processing: false,
      error: null,
      done: true
    };
    const weeklyEvolutiondata = computeEvolution(combinedData);
    weeklyEvolution = {
      items: weeklyEvolutiondata,
      processing: false,
      error: null
    };
  }

  return {
    trailers: trailers,
    weeklyEvolution: weeklyEvolution,
    trailersDefaultDisplayNames: trailersDefaultDisplayNames
  };
}

export function formatTripsProcessingTrips(state, trips, trailerId, final) {
  if (state.trailer.item && state.trailer.item.assetId === trailerId) {
    const cleanTrips = clean.tripsWithDuration(trips.map(trip => clean.trailerTrip(trip, Math.random())));
    const newTrips = state.trailer.item.trips.concat(cleanTrips);
    const processingTrips = !final;
    const processing = !final || state.trailer.processingEvents || state.trailer.processingDtc;
    return {
      trips: newTrips,
      processingTrips,
      processing
    };
  }
}

export function formatEventsProcessingEvents(state, events, trailerId, final) {
  if (state.trailer.item && state.trailer.item.assetId === trailerId) {
    const cleanEvents = events.map(event => clean.recordedEvent(event, Math.random()));
    const recordedEvents = state.trailer.item.recordedEvents.concat(cleanEvents);
    const processingEvents = !final;
    const processing = !final || state.trailer.processingTrips || state.trailer.processingDtc;

    return {
      recordedEvents,
      processingEvents,
      processing
    };
  }
}

export function formatDtcProcessingDtc(state, dtc, trailerId, final) {
  const trailer = state?.trailers?.items?.find(trailer => trailer.assetId === trailerId);
  if (trailer) {
    const isTraileriABS = getIsTraileriABS(trailer);
    const cleanDtc = dtc.map(dtc => clean.dtcEvent(state, dtc, Math.random(), isTraileriABS));
    const dtcEvents = state.trailer.item.dtc.concat(cleanDtc);
    const processingDtc = !final;
    const processing = !final || state.trailer.processingTrips;

    return {
      dtcEvents,
      processingDtc,
      processing
    };
  }
}

export function formatCleanTrailer(state, trailer) {
  const cleanTrailer = clean.trailer(state, { data: trailer }, state.trailersDefaultDisplayNames);
  return { ...cleanTrailer, uploaded: true };
}

export function getNextNotificationState(now, state, newNotifications) {
  let buffer = state.buffer || [];
  let lastMQTTProcessedTime = state.lastMQTTProcessedTime;
  buffer.unshift(newNotifications);
  if (now - state.lastMQTTProcessedTime < parseInt(REACT_APP_MQQT_MIN_PROCESSING_INTERVAL, 10)) {
    return {
      buffer
    };
  }
  lastMQTTProcessedTime = now;
  let i = buffer.length;

  let currentMessages = { ...state.messages };

  let latestTimeBasedMessages = { ...state.lastTimeBasedMessages };
  const notificationTypesToListenToCheck =
    state.notificationTypesToListenTo || state.notificationTypesToListenToDefaultValues;
  while (i--) {
    const notificationsDatas = Object.entries(buffer[i]).map(([assetId, values]) =>
      values.map(notificationsData => Object.assign({}, notificationsData, { assetId }))
    );
    currentMessages = [].concat
      .apply(
        [],
        notificationsDatas.map(messageData => messageData.sort((a, b) => a.time - b.time))
      )
      .map(v => Object.assign({}, v, v.event))
      .filter(v => tools.getIsValidNotification(v, notificationTypesToListenToCheck))
      .reduce((agg, elem) => {
        if (!elem.assetId) {
          return agg;
        }
        let triggerType = elem.trigger.toLowerCase();
        let generateNotification = true;

        if (triggerType === 'timebased') {
          generateNotification = false;
          const data =
            (elem.data ? elem.data : elem.event).toLowerCase().substr(0, 10) === 'standstill' ? 'standstill' : 'moving';
          // we need to check to see if we have gone from standstill to moving or vice versa.
          // if so we generate a Start/Stop event
          if (latestTimeBasedMessages[elem.assetId]) {
            generateNotification = latestTimeBasedMessages[elem.assetId] !== data;
            elem.extraInfo = data;
            triggerType = 'startstop';
          }

          latestTimeBasedMessages[elem.assetId] = data;
        }

        if (generateNotification) {
          elem.key = elem.time + '_' + triggerType + '_' + elem.assetId;

          if (!agg[triggerType] || agg[triggerType].length === 0) {
            agg[triggerType] = [elem];
          } else {
            agg[triggerType].push(elem);
          }

          agg[triggerType].sort((a, b) => (a.time < b.time ? 1 : -1));
        }

        return agg;
      }, Object.assign({}, currentMessages));
    buffer.splice(i, 1);
  }

  Object.keys(currentMessages).forEach(type => {
    currentMessages[type] = currentMessages[type].slice(-REACT_APP_NOTIFICATIONS_MAX_PER_TYPE);
  });

  if (!Object.keys(latestTimeBasedMessages).length) {
    latestTimeBasedMessages = null;
  }

  return {
    buffer,
    lastMQTTProcessedTime,
    lastTimeBasedMessages: latestTimeBasedMessages,
    messages: currentMessages,
    processing: false
  };
}

export function getNextNotificationNotification(now, state, notificationAlert) {
  const notificationObject = Object.assign(
    {},
    {
      ...notificationAlert.event,
      reason: notificationAlert.reason,
      user: notificationAlert.user,
      ...notificationAlert.event.gnss,
      ...notificationAlert.event.header,
      ...notificationAlert.event.header.event
    }
  );

  let messages = Object.assign({}, { ...state.messages });
  let triggerType;
  switch (notificationObject.reason) {
    case 'amber':
      triggerType = 'ebsAmber';
      break;
    case 'red':
      triggerType = 'ebsRed';
      break;
    default:
      triggerType = notificationObject.reason;
      break;
  }

  notificationObject.key = notificationObject.time + '_' + triggerType + '_' + notificationObject.assetId;

  if (!messages[triggerType] || messages[triggerType].length === 0) {
    messages[triggerType] = [notificationObject];
  } else if (!tools.getIsDuplicate(notificationObject.key, messages[triggerType])) {
    messages[triggerType] = messages[triggerType].concat(notificationObject);
    messages[triggerType] = messages[triggerType].slice(-REACT_APP_NOTIFICATIONS_MAX_PER_TYPE);
  }

  return {
    messages
  };
}

export function getInitialNotificationState(now, state, notifications) {
  // Until we have an API for this we can use localStorage for testing
  let initialDeletedMessages = parseJson(localStorage.getItem('deletedMessages'));
  initialDeletedMessages = Array.isArray(initialDeletedMessages) ? initialDeletedMessages : [];
  let notificationTypesToListenTo = state.notificationTypesToListenTo || state.notificationTypesToListenToDefaultValues;
  let lastTimeBasedMessages = {};
  const oldestMessageTime = now - Number(REACT_APP_NOTIFICATIONS_API_OLDEST);

  const notificationsData = notifications.sort((a, b) => a.time - b.time);
  let messages = notificationsData
    .map(v => Object.assign({}, v, v.gnss, v.header))
    .filter(
      v =>
        v.time >= oldestMessageTime &&
        v.event &&
        tools.getIsValidNotification(v.event, notificationTypesToListenTo, false, true) &&
        initialDeletedMessages.indexOf(v.time + '_' + v.event.trigger.toLowerCase() + '_' + v.assetId) === -1
    )
    .reduce((agg, elem) => {
      if (!elem.assetId) {
        return agg;
      }
      let triggerType = elem.event.trigger.toLowerCase();
      let generateNotification = true;

      if (triggerType === 'timebased') {
        generateNotification = false;
        const data =
          (elem.event.data ? elem.event.data : elem.event.event).toLowerCase().substr(0, 10) === 'standstill'
            ? 'standstill'
            : 'moving';
        // we need to check to see if we have gone from standstill to moving or vice versa.
        // if so we generate a Start/Stop event
        if (lastTimeBasedMessages[elem.assetId]) {
          generateNotification = lastTimeBasedMessages[elem.assetId] !== data;
          elem.extraInfo = data;
          triggerType = 'startstop';
        }

        lastTimeBasedMessages[elem.assetId] = data;
      } else if (triggerType === 'ebs') {
        generateNotification = false;
        let eventType = (elem.event.event || elem.event.data || '').toLowerCase();
        if (eventType === 'warninglamps') {
          const amberOn =
            elem.ebs &&
            elem.ebs.extended &&
            elem.ebs.extended.lights &&
            elem.ebs.extended.lights.amber &&
            elem.ebs.extended.lights.amber.toString().toLowerCase() === 'on';
          const redOn =
            elem.ebs &&
            elem.ebs.extended &&
            elem.ebs.extended.lights &&
            elem.ebs.extended.lights.red &&
            elem.ebs.extended.lights.red.toString().toLowerCase() === 'on';
          generateNotification = amberOn || redOn;

          if (redOn) {
            triggerType = 'ebsRed';
          } else {
            triggerType = 'ebsAmber';
          }
        } else if (eventType === 'tpms alarms') {
          generateNotification = (elem.ebs && elem.tpms && Array.isArray(elem.tpms.data) ? elem.tpms.data : []).reduce(
            (agg, wheel) => {
              agg = agg || (wheel.pressure && wheel.pressure.suff === false);
              return agg;
            },
            false
          );
        }
      }

      elem.key = elem.time + '_' + triggerType + '_' + elem.assetId;

      if (generateNotification && initialDeletedMessages.indexOf(elem.key) === -1) {
        if (!agg[triggerType]) {
          agg[triggerType] = [elem];
        } else {
          agg[triggerType].push(elem);
        }

        agg[triggerType].sort((a, b) => (a.time < b.time ? 1 : -1));
      }

      return agg;
    }, {});

  if (!Object.keys(messages).length) {
    messages = null;
  } else {
    Object.keys(messages).forEach(type => {
      messages[type] = messages[type].slice(-REACT_APP_NOTIFICATIONS_MAX_PER_TYPE);
    });
  }

  if (!Object.keys(lastTimeBasedMessages).length) {
    lastTimeBasedMessages = null;
  }
  const initialReadMessages = parseJson(localStorage.getItem('readMessages'));
  return {
    notificationTypesToListenTo,
    lastTimeBasedMessages,
    messages,
    readMessages: Array.isArray(initialReadMessages) ? initialReadMessages : [],
    deletedMessages: initialDeletedMessages,
    processing: false
  };
}

export function getNotificationsDeleted(state, deletedNotificationIds) {
  let deletedMessages = (state.deletedMessages ? state.deletedMessages : [])
    .filter(id => deletedNotificationIds.indexOf(id) === -1)
    .concat(deletedNotificationIds);
  localStorage.setItem('deletedMessages', JSON.stringify(deletedMessages));

  const currentStateMessages = Object.entries(state.messages).map(([type, values]) =>
    values.map(notificationsData => Object.assign({}, notificationsData, { type }))
  );
  let messages = [].concat
    .apply([], currentStateMessages)
    .filter(message => deletedMessages.indexOf(message.key) === -1)
    .reduce((agg, elem) => {
      if (!agg[elem.type]) {
        agg[elem.type] = [elem];
      } else {
        agg[elem.type].push(elem);
      }

      return agg;
    }, {});

  return {
    deletedMessages,
    messages
  };
}

export function getNotificationsRead(now, state, removeNotificationIds) {
  let latestReadMessages = (state.readMessages ? state.readMessages : []).filter(
    id => removeNotificationIds.indexOf(id) === -1
  );
  localStorage.setItem('readMessages', JSON.stringify(latestReadMessages));
  return latestReadMessages;
}

export function formatTrailerHistory(now, data, history, assetId, final, isMetric) {
  const { devicesReducer: state, trailers } = data;
  if (state.history.current.assetId !== assetId) {
    return;
  }
  let historyCurrentData = state.history.current.data || [];
  historyCurrentData = [...historyCurrentData, ...history];
  let retrievedHistory = state.history && { ...state.history.retrievedHistory };
  let animatedHeadRoutes = { ...state.animatedHeadRoutes };
  let mapRoutes = { ...state.mapRoutes };
  let devices = JSON.parse(JSON.stringify(trailers));

  const isTailIndex = historyCurrentData.length > 2 ? Math.round(historyCurrentData.length / 10) : -1;
  if (final) {
    let firstPoint = null;
    // we need to process the data
    const currentDevices = Object.assign({}, ...devices.map(device => ({ [device['assetId']]: device })));
    let updateLatestDevices = false;
    let route = historyCurrentData
      .map(v => Object.assign({}, v, v.gnss, v.header, v.power))
      .filter(v => v.valid && (v.latitude || v.longitude))
      .reduce((agg, elem, index) => {
        if (index === 0) {
          firstPoint = elem;
        } else {
          if (elem?.time > currentDevices[assetId]?.time) {
            const validGPS = elem.valid && (elem.latitude || elem.longitude);
            updateLatestDevices = true;
            currentDevices[assetId].time = elem.time;
            currentDevices[elem.assetId].lastReport = moment.unix(elem.time);
            if (validGPS) {
              currentDevices[elem.assetId].gnss = { ...elem.gnss };
              currentDevices[elem.assetId].lastPosition = elem.time;
            }
            if (!currentDevices[elem.assetId].header) {
              currentDevices[elem.assetId].header = {
                imei: elem.imei
              };
            }
            currentDevices[elem.assetId].header.event = { ...elem.event };
            currentDevices[elem.assetId].header.time = elem.time;
            currentDevices[elem.assetId].header.sequence = elem.sequence;
            const speed = tools.getSpeed(elem);
            currentDevices[elem.assetId].speed = speed === null ? currentDevices[elem.assetId].speed : speed;
            const axleLoad = tools.getAxleLoad(elem);
            currentDevices[elem.assetId].axleLoad =
              axleLoad === null ? currentDevices[elem.assetId].axleLoad : axleLoad;
            currentDevices[elem.assetId].odometer = tools.getOdometer(elem) || currentDevices[elem.assetId].odometer;
            currentDevices[elem.assetId].ebsTime = tools.getEbsTime(elem) || currentDevices[elem.assetId].ebsTime;
            currentDevices[elem.assetId].tires =
              tools.getTPMS(elem, currentDevices[elem.assetId]) || currentDevices[elem.assetId].tires;
            currentDevices[elem.assetId].brakePads = tools.getBrakePads(elem) || currentDevices[elem.assetId].brakePads;
            currentDevices[elem.assetId].useGnssSpeed =
              !currentDevices[elem.assetId].ebsTime ||
              (currentDevices[elem.assetId].lastPosition &&
                currentDevices[elem.assetId].ebsTime < currentDevices[elem.assetId].lastPosition);
            currentDevices[elem.assetId].activityStatus = tools.getActivityStatus(
              elem.sinceState,
              currentDevices[elem.assetId].lastPosition
                ? moment.utc(moment.unix(currentDevices[elem.assetId].lastPosition))
                : null
            );
          }
          const startEvent = index === 1 ? firstPoint.event : agg[agg.length - 1].endEvent;
          const startSequence = index === 1 ? firstPoint.sequence : agg[agg.length - 1].endSequence;
          const startGnss = index === 1 ? firstPoint.gnss : agg[agg.length - 1].endGnss;
          const startSpeed = index === 1 ? tools.getSpeed(firstPoint) : agg[agg.length - 1].endSpeed;
          const startAxleLoad = index === 1 ? tools.getAxleLoad(firstPoint) : agg[agg.length - 1].endAxleLoad;
          const endSpeed = tools.getSpeed(elem);
          const endAxleLoad = tools.getAxleLoad(elem);
          const startTime = index === 1 ? firstPoint.time : agg[agg.length - 1].endTime;
          const avgSegSpeed =
            startSpeed !== null && endSpeed !== null
              ? Math.round((startSpeed + endSpeed) / 2)
              : startSpeed !== null
              ? startSpeed
              : endSpeed;
          const avgAxleLoad =
            startAxleLoad !== null && endAxleLoad !== null
              ? Math.round((startAxleLoad + endAxleLoad) / 2)
              : startAxleLoad !== null
              ? startAxleLoad
              : endAxleLoad;

          agg.push({
            from: index === 1 ? [firstPoint.longitude, firstPoint.latitude] : agg[agg.length - 1].to,
            to: [elem.longitude, elem.latitude],
            distance: 0,
            duration: elem.time - startTime,
            startSpeed,
            endSpeed,
            startAxleLoad,
            ebs: elem.ebs,
            endAxleLoad,
            speed: avgSegSpeed,
            axleLoad: avgAxleLoad,
            imei: elem.imei,
            assetId: elem.assetId,
            tpms: elem.tpms,
            startTime,
            endTime: elem.time,
            startEvent,
            endEvent: elem.event,
            startGnss,
            endGnss: elem.gnss,
            startSequence,
            endSequence: elem.sequence,
            tail: index <= isTailIndex,
            source: 'fmsHistory'
          });
        }

        return agg;
      }, []);

    if (
      currentDevices[assetId] &&
      route &&
      route.length > 0 &&
      route[route.length - 1].startGnss &&
      (!currentDevices[assetId].gnss ||
        (currentDevices[assetId].gnss &&
          !currentDevices[assetId].gnss.longitude &&
          !currentDevices[assetId].gnss.latitude))
    ) {
      currentDevices[assetId].gnss = route[route.length - 1].startGnss;
    }

    if (!tools.getDisableHeadAnimation(state)) {
      // we get the animated head trails and update device latLng accordingly
      if (route.length >= 2) {
        const lastSegment = route[route.length - 1];
        const estimatedNextEvent = lastSegment.endTime + lastSegment.duration + (now - lastSegment.endTime);
        const timingOK = estimatedNextEvent >= now && lastSegment.duration <= animatedHeadMaxInterval;
        const timeBased =
          lastSegment.endEvent &&
          lastSegment.endEvent.trigger &&
          lastSegment.endEvent.trigger.toLowerCase() === 'timebased' &&
          lastSegment.startEvent &&
          lastSegment.startEvent.trigger &&
          lastSegment.startEvent.trigger.toLowerCase() === 'timebased';
        const moving =
          timeBased &&
          (lastSegment.endEvent.event ? lastSegment.endEvent.event : lastSegment.endEvent.data) &&
          (lastSegment.endEvent.event ? lastSegment.endEvent.event : lastSegment.endEvent.data)
            .toLowerCase()
            .substr(0, 6) === 'moving';

        if (timingOK && timeBased && moving) {
          animatedHeadRoutes[assetId] = {
            startTime: now,
            endTime: estimatedNextEvent,
            segment: lastSegment
          };

          // we also want to move the device to second last position and make its direction point towards the animated route point
          lastSegment.startGnss.heading = getDirectionBetween(lastSegment.from, lastSegment.to);
          currentDevices[assetId].gnss = lastSegment.startGnss;
          updateLatestDevices = true;
          route.splice(route.length - 1, 1);
          mapRoutes[assetId] = route;
        }
      }
    }

    mapRoutes[assetId] = route;

    retrievedHistory[assetId] = {
      dataHash: tools.getDataHash(),
      hours: state.history.current.hours
    };

    if (updateLatestDevices) {
      devices = Object.values(currentDevices);
    }
  }

  const tpmsGroupedHistory = (state.history.current.tpms || []).reduce((agg, val) => {
    agg[val.time] = val;
    return agg;
  }, {});

  historyCurrentData.forEach(({ time, tpms }) => {
    if (tpms && tpms.data && tpms.data.length) {
      // round time to specific minute
      time = time - (time % 60);

      if (typeof tpmsGroupedHistory[time] === 'undefined') {
        tpmsGroupedHistory[time] = {
          time,
          startTime: time,
          endTime: time + 59,
          wheels: {}
        };
      }

      tpms.data.forEach(wheel => {
        if (wheel.location) {
          const newData = getWheelTPMSValue(wheel, isMetric, time);
          if (!tpmsGroupedHistory[time].wheels[newData.label]) {
            tpmsGroupedHistory[time].wheels[newData.label] = newData;
          } else {
            tpmsGroupedHistory[time].wheels[newData.label] = {
              ...tpmsGroupedHistory[time].wheels[newData.label],
              ...newData,
              hasAlert: newData.hasAlert || tpmsGroupedHistory[time].wheels[newData.label].hasAlert,
              pressureThreshold:
                newData.pressureThreshold || tpmsGroupedHistory[time].wheels[newData.label].pressureThreshold,
              pressure: newData.pressure || tpmsGroupedHistory[time].wheels[newData.label].pressure
            };
          }
        }
      });
    }
  });

  return {
    ...state,
    history: {
      retrievedHistory: retrievedHistory,
      current: {
        ...state.history.current,
        data: historyCurrentData,
        tpms: Object.values(tpmsGroupedHistory),
        isoCable: getIsoCable(state, history)
      }
    },
    animatedHeadRoutes,
    mapRoutes,
    devices,
    processingApi: !final
  };
}

function getIsoCable(state, history) {
  const isTPB = history.some(event => 'power' in event);
  let isoCable = [];

  if (isTPB) {
    history.forEach(event => {
      const length = isoCable.length;
      const powerSource = event?.power?.source;
      const lastEventEnd = isoCable[length - 1]?.end;
      const isMoving = !!event?.gnss?.speed;

      if (powerSource === 'Battery' && isMoving && (!length || lastEventEnd)) {
        isoCable.push({ start: event.time, assetId: event.assetId });
      } else if ((powerSource === 'External' || !isMoving) && length && !lastEventEnd) {
        isoCable[length - 1].end = event.time;
      }
    });

    const lastIsoCable = state?.history?.current?.isoCable ?? [];
    const isNewAssetId = lastIsoCable?.[0]?.assetId !== history[0].assetId;

    if (!isNewAssetId) {
      isoCable.forEach(item => {
        const index = getItemIndex(lastIsoCable, item);
        if (index < 0) {
          lastIsoCable.push(item);
        } else {
          lastIsoCable[index] = item;
        }
      });

      isoCable = lastIsoCable.sort((a, b) => a.start - b.start);
    }
  }
  return isoCable;
}

function getItemIndex(variableArray = [], value) {
  return variableArray.findIndex(item => item.start === value.start || item.end === value.end);
}

export function formatTrailerContextHistory(state, history, assetId, final) {
  if (state.contextHistory.assetId !== assetId) {
    return;
  }
  let contextHistoryData = [...(state.contextHistory.data || []), ...history];

  if (final) {
    contextHistoryData = treatTrailerHistory(contextHistoryData);
  }
  return {
    contextHistory: {
      ...state.contextHistory,
      data: contextHistoryData,
      processing: !final
    }
  };
}

function getMaxDataPoints(state) {
  // Assuming REACT_APP_MAX_DEVICES_DATA is 100, then 100 data points for <= 100 devices, 10 for 1000, 5 for 2000+ devices
  return Math.round(
    Math.max(
      parseInt(REACT_APP_MAX_DEVICES_DATA, 10) /
        Math.max((Array.isArray(state.devices) ? state.devices.length : 100) / 100, 1),
      5
    )
  );
}

export function formatInitialDeviceStates(state, devicesStates, final) {
  const combinedDevicesStates = [...state.initialRawStates, ...devicesStates];
  let initialRawStates = combinedDevicesStates;
  let devices = state.devices || [];
  if (!final) {
    return {
      initialRawStates
    };
  }
  let processingApi = false;
  let mapRoutes = { ...state.mapRoutes };
  let latestDevices = devices
    ? Object.assign(
        {},
        devices.map(device => ({ [device['assetId']]: device }))
      )
    : {};
  let updatedDevicesLatLng = {};
  let firstPoints = {};
  let states = combinedDevicesStates
    .sort((a, b) => a.time - b.time)
    .map(v => Object.assign({}, v, v.gnss, v.header))
    .filter(v => v.valid && (v.latitude || v.longitude))
    .reduce((agg, elem) => {
      if (!elem.assetId) {
        return agg;
      }

      if (
        latestDevices[elem.assetId] &&
        latestDevices[elem.assetId].gnss &&
        elem.gnss &&
        !latestDevices[elem.assetId].gnss.longitude &&
        !latestDevices[elem.assetId].gnss.latitude
      ) {
        // in case the data we got from the latest devices call (devices API) has lat/lng of 0,0 we need to update it with latest record from this API
        if (!updatedDevicesLatLng[elem.assetId]) {
          updatedDevicesLatLng[elem.assetId] = {};
        }
        updatedDevicesLatLng[elem.assetId].gnss = elem.gnss;
      }

      if (
        (!mapRoutes[elem.assetId] || mapRoutes[elem.assetId].length === 0) &&
        (!firstPoints || !firstPoints[elem.assetId])
      ) {
        firstPoints[elem.assetId] = elem;
      } else {
        if (!mapRoutes[elem.assetId]) {
          mapRoutes[elem.assetId] = [];
        }

        const startEvent =
          mapRoutes[elem.assetId].length === 0
            ? firstPoints[elem.assetId].event
            : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endEvent;
        const startSequence =
          mapRoutes[elem.assetId].length === 0
            ? firstPoints[elem.assetId].sequence
            : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endSequence;
        const startGnss =
          mapRoutes[elem.assetId].length === 0
            ? firstPoints[elem.assetId].gnss
            : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endGnss;
        const startSpeed =
          mapRoutes[elem.assetId].length === 0
            ? firstPoints[elem.assetId].speed
            : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endSpeed;
        const startTime =
          mapRoutes[elem.assetId].length === 0
            ? firstPoints[elem.assetId].time
            : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endTime;

        mapRoutes[elem.assetId].push({
          from:
            mapRoutes[elem.assetId].length === 0
              ? [firstPoints[elem.assetId].longitude, firstPoints[elem.assetId].latitude]
              : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].to,
          to: [elem.longitude, elem.latitude],
          distance: 0,
          duration: elem.time - startTime,
          startSpeed,
          endSpeed: elem.speed,
          speed: Math.round((startSpeed + elem.speed) / 2),
          imei: elem.imei,
          ebs: elem.ebs,
          tpms: elem.tpms,
          assetId: elem.assetId,
          startTime,
          endTime: elem.time,
          startEvent,
          endEvent: elem.event,
          startGnss,
          endGnss: elem.gnss,
          startSequence,
          endSequence: elem.sequence,
          tail: mapRoutes[elem.assetId].length <= 1,
          source: 'fmsInit'
        });
      }

      if (!agg[elem.assetId]) {
        agg[elem.assetId] = [elem];
      } else {
        agg[elem.assetId].push(elem);
      }

      return agg;
    }, {});

  if (Object.keys(updatedDevicesLatLng).length > 0) {
    Object.keys(updatedDevicesLatLng).forEach(assetId => {
      latestDevices[assetId].gnss = updatedDevicesLatLng[assetId].gnss;
    });
    devices = Object.values(latestDevices);
  }

  const maxDataPoints = getMaxDataPoints(state);
  mapRoutes = mapValues(mapRoutes, route => route.slice(-maxDataPoints));

  return {
    initialRawStates,
    processingApi,
    states,
    mapRoutes,
    devices
  };
}

export function formatNewDeviceState(now, state, newDeviceStates, trailers) {
  const maxDataPoints = getMaxDataPoints(state);
  const segmentsLimit = disableMapMatching ? maxDataPoints : maxMatchedDataPointsToRender;
  let buffer = state.buffer || [];
  const odrInfo = tools.getOdrInfoPerDevice(newDeviceStates);
  if (state.processing || state.processingApi) {
    buffer.unshift(newDeviceStates);
    return {
      buffer
    };
  }

  if (now - state.lastMQTTProcessedTime < parseInt(REACT_APP_MQQT_MIN_PROCESSING_INTERVAL, 10)) {
    buffer.unshift(newDeviceStates);
    return {
      buffer
    };
  }
  let devices = trailers || [];
  let lastMQTTProcessedTime = now;
  let states = { ...state.states };
  let mapRoutes = { ...state.mapRoutes };
  let animatedHeadRoutes = { ...state.animatedHeadRoutes };
  let retrievedHistory = state.history ? { ...state.history.retrievedHistory } : {};

  const keys = Object.keys(newDeviceStates);
  keys.forEach(key => {
    let foundDevice = devices.find(device => device?.assetId === key);
    const device = newDeviceStates?.[key]?.[0];
    if (foundDevice && device && device.tpms && device.tpms.data) {
      foundDevice = { ...foundDevice, tpms: device.tpms };
      foundDevice.tpms.data = device.tpms.data.map(t => ({
        ...t,
        time: device.time * 1000
      }));
    }
  });

  // get current devices in object form
  let currentDevices = devices?.length
    ? Object.assign({}, ...devices.map(device => ({ [device['assetId']]: device })))
    : {};

  //updating activity status if needed
  currentDevices = mapValues(currentDevices, d => ({
    ...d,
    activityStatus: tools.getUpdatedActivityStatus(d.activityStatus, null, d.lastReport)
  }));

  buffer.unshift(newDeviceStates);

  let i = buffer.length;
  // IMEIs that we received new data for and therefore can use to animate head segment
  let receivedNewData = {};
  while (i--) {
    const deviceDatas = Object.entries(buffer[i]).map(([assetId, values]) =>
      values.map(deviceData => Object.assign({}, deviceData, { assetId }))
    );
    let firstRoute = {};

    states = [].concat
      .apply(
        [],
        deviceDatas.map(deviceStateData =>
          deviceStateData.sort((a, b) => (a.time === b.time ? a.sequence - b.sequence : a.time - b.time))
        )
      )
      .map(v => Object.assign({}, v, v.gnss))
      .reduce((agg, elem) => {
        if (!elem.assetId) {
          return agg;
        }
        const originalCurrentDevice = { ...currentDevices[elem.assetId] };

        if (!elem.gnss) {
          elem.gnss = { ...elem };
        }
        if (currentDevices[elem.assetId]) {
          // should always, except in tests
          currentDevices[elem.assetId].odr = odrInfo[elem.assetId]
            ? odrInfo[elem.assetId]
            : currentDevices[elem.assetId].odr;
        }

        const renderedTime = animatedHeadRoutes[elem.assetId]
          ? animatedHeadRoutes[elem.assetId].segment.endTime
          : currentDevices[elem.assetId]
          ? currentDevices[elem.assetId].time
          : 0;
        const renderedSequence = animatedHeadRoutes[elem.assetId]
          ? animatedHeadRoutes[elem.assetId].segment.sequence
          : renderedTime
          ? currentDevices[elem.assetId].header.sequence
          : 0;
        const addPoint =
          !renderedTime ||
          elem.time > renderedTime ||
          (elem.time === renderedTime && elem.sequence && (!renderedSequence || elem.sequence > renderedSequence));

        if (addPoint) {
          const validGPS = elem.valid && (elem.latitude || elem.longitude);
          const speed = tools.getSpeed(elem);
          const axleLoad = tools.getAxleLoad(elem);
          const odometer = tools.getOdometer(elem);
          const ebsTime = tools.getEbsTime(elem);
          const tires = tools.getTPMS(elem, currentDevices[elem.assetId]);
          const brakePads = tools.getBrakePads(elem);
          const lastEBSValid = tools.getEBSValid(elem);

          if (currentDevices[elem.assetId]) {
            if (currentDevices[elem.assetId].time < elem.time) {
              currentDevices[elem.assetId].time = elem.time;
              currentDevices[elem.assetId].lastReport = moment.unix(elem.time);
              if (validGPS) {
                currentDevices[elem.assetId].gnss = { ...elem.gnss };
                currentDevices[elem.assetId].lastPosition = elem.time;
              }
              if (!currentDevices[elem.assetId].header) {
                currentDevices[elem.assetId].header = {
                  imei: elem.imei
                };
              }
              currentDevices[elem.assetId].lastEBSValid = lastEBSValid;
              currentDevices[elem.assetId].header = {
                ...currentDevices[elem.assetId].header,
                event: elem.event,
                time: elem.time,
                sequence: elem.sequence
              };
              currentDevices[elem.assetId].speed = speed || originalCurrentDevice.speed;
              currentDevices[elem.assetId].axleLoad = axleLoad || originalCurrentDevice.axleLoad;
              currentDevices[elem.assetId].odometer = odometer || originalCurrentDevice.odometer;
              currentDevices[elem.assetId].ebsTime = ebsTime || originalCurrentDevice.ebsTime;
              currentDevices[elem.assetId].tires = tires || originalCurrentDevice.tires;
              currentDevices[elem.assetId].brakePads = brakePads || originalCurrentDevice.brakePads;
              currentDevices[elem.assetId].useGnssSpeed =
                !currentDevices[elem.assetId].ebsTime ||
                (currentDevices[elem.assetId].lastPosition &&
                  currentDevices[elem.assetId].ebsTime < currentDevices[elem.assetId].lastPosition);
              currentDevices[elem.assetId].activityStatus = tools.getUpdatedActivityStatus(
                currentDevices[elem.assetId].activityStatus,
                elem.event,
                elem.lastReport
              );
            }
          } else {
            currentDevices[elem.assetId] = {
              time: elem.time,
              imei: elem.imei,
              lastReport: moment.unix(elem.time),
              lastPosition: validGPS ? elem.time : null,
              gnss: validGPS ? { ...elem.gnss } : {},
              header: {
                event: { ...elem.event },
                imei: elem.imei,
                time: elem.time,
                sequence: elem.sequence
              },
              assetId: elem.assetId,
              lastEBSValid,
              speed,
              axleLoad,
              odometer,
              tires,
              brakePads,
              ebsTime,
              useGnssSpeed: !ebsTime && validGPS,
              defaultDisplayName: elem.assetId,
              activityStatus: tools.getUpdatedActivityStatus(null, elem.event, moment.unix(elem.time))
            };
          }

          if (!validGPS) {
            return agg;
          }

          if (animatedHeadRoutes[elem.assetId]) {
            // we need to add draft.animatedHeadRoutes[elem.assetId] to the route and remove them from head segments
            mapRoutes[elem.assetId].push(Object.assign({}, animatedHeadRoutes[elem.assetId].segment));
            delete animatedHeadRoutes[elem.assetId];
          }

          if (retrievedHistory[elem.assetId]) {
            retrievedHistory[elem.assetId].dataHash = tools.getDataHash();
          }

          if (
            (!mapRoutes[elem.assetId] || mapRoutes[elem.assetId].length === 0) &&
            (!firstRoute || !firstRoute[elem.assetId])
          ) {
            // check to see if we have a single point from previous history or mqtt calls
            if (states && Array.isArray(states[elem.assetId]) && states[elem.assetId].length === 1) {
              const startSpeed = tools.getSpeed(states[elem.assetId][0]);
              const endSpeed = tools.getSpeed(elem);
              const avgSegSpeed =
                startSpeed !== null && endSpeed !== null
                  ? Math.round((startSpeed + endSpeed) / 2)
                  : startSpeed !== null
                  ? startSpeed
                  : endSpeed;
              const startAxleLoad = tools.getAxleLoad(states[elem.assetId][0]);
              const endAxleLoad = tools.getAxleLoad(elem);
              const avgAxleLoad =
                startAxleLoad !== null && endAxleLoad !== null
                  ? Math.round((startAxleLoad + endAxleLoad) / 2)
                  : startAxleLoad !== null
                  ? startAxleLoad
                  : endAxleLoad;

              mapRoutes[elem.assetId] = [
                {
                  from: [states[elem.assetId][0].longitude, states[elem.assetId][0].latitude],
                  to: [elem.longitude, elem.latitude],
                  distance: 0,
                  duration: elem.time - states[elem.assetId][0].time,
                  startSpeed,
                  endSpeed,
                  startAxleLoad,
                  endAxleLoad,
                  speed: avgSegSpeed,
                  axleLoad: avgAxleLoad,
                  imei: elem.imei,
                  ebs: elem.ebs,
                  assetId: elem.assetId,
                  startTime: states[elem.assetId][0].time,
                  endTime: elem.time,
                  startEvent: states[elem.assetId][0].event,
                  endEvent: elem.event,
                  startGnss: states[elem.assetId][0].gnss,
                  endGnss: elem.gnss,
                  startSequence: states[elem.assetId][0].sequence,
                  endSequence: elem.sequence,
                  tail: true,
                  source: 'mqtt'
                }
              ];
            } else {
              firstRoute[elem.assetId] = elem;
            }
          } else {
            receivedNewData[elem.assetId] = true;

            if (!mapRoutes[elem.assetId]) {
              mapRoutes[elem.assetId] = [];
            }

            const startEvent =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].event
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endEvent;
            const startGnss =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].gnss
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endGnss;
            const startSpeed =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].speed
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endSpeed;
            const endSpeed = tools.getSpeed(elem);
            const avgSegSpeed =
              startSpeed !== null && endSpeed !== null
                ? Math.round((startSpeed + endSpeed) / 2)
                : startSpeed !== null
                ? startSpeed
                : endSpeed;
            const startTime =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].time
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endTime;
            const startSequence =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].sequence
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endSequence;
            const startAxleLoad =
              mapRoutes[elem.assetId].length === 0
                ? firstRoute[elem.assetId].endAxleLoad
                : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].endAxleLoad;
            const endAxleLoad = tools.getAxleLoad(elem);
            const avgAxleLoad =
              startAxleLoad !== null && endAxleLoad !== null
                ? Math.round((startAxleLoad + endAxleLoad) / 2)
                : startAxleLoad !== null
                ? startAxleLoad
                : endAxleLoad;

            mapRoutes[elem.assetId].push({
              from:
                mapRoutes[elem.assetId].length === 0
                  ? [firstRoute[elem.assetId].longitude, firstRoute[elem.assetId].latitude]
                  : mapRoutes[elem.assetId][mapRoutes[elem.assetId].length - 1].to,
              to: [elem.longitude, elem.latitude],
              distance: 0,
              duration: elem.time - startTime,
              startSpeed,
              endSpeed,
              startAxleLoad,
              endAxleLoad,
              speed: avgSegSpeed,
              axleLoad: avgAxleLoad,
              imei: elem.imei,
              ebs: elem.ebs,
              assetId: elem.assetId,
              startTime: startTime,
              endTime: elem.time,
              startEvent: startEvent,
              endEvent: elem.event,
              startGnss: startGnss,
              endGnss: elem.gnss,
              startSequence,
              endSequence: elem.sequence,
              tail: mapRoutes[elem.assetId].length <= 1,
              source: 'mqtt'
            });
          }
        }

        // Fix for sub topic not always giving newer states than we already have
        if (!agg[elem.assetId] || agg[elem.assetId][agg[elem.assetId].length - 1].time < elem.time) {
          if (!agg[elem.assetId]) {
            agg[elem.assetId] = [];
          }

          agg[elem.assetId] = [...agg[elem.assetId], elem];
        }

        return agg;
      }, Object.assign({}, states));
    buffer.splice(i, 1);
  }

  if (!tools.getDisableHeadAnimation(state)) {
    // we get the animated head trails and update device latLng accordingly
    // loop through the last two states of each device we just got real time data for to see if they should have an animated head
    Object.keys(receivedNewData).forEach(assetId => {
      const route = mapRoutes[assetId];
      if (route.length >= 2) {
        const lastSegment = animatedHeadRoutes[assetId] ? animatedHeadRoutes[assetId].segment : route[route.length - 1];
        const estimatedNextEvent = lastSegment.endTime + lastSegment.duration + (now - lastSegment.endTime);
        const timingOK = estimatedNextEvent >= now && lastSegment.duration <= animatedHeadMaxInterval;
        const timeBased =
          lastSegment.endEvent &&
          lastSegment.endEvent.trigger &&
          lastSegment.endEvent.trigger.toLowerCase() === 'timebased' &&
          lastSegment.startEvent &&
          lastSegment.startEvent.trigger &&
          lastSegment.startEvent.trigger.toLowerCase() === 'timebased';
        const moving =
          timeBased &&
          (lastSegment.endEvent.event ? lastSegment.endEvent.event : lastSegment.endEvent.data) &&
          (lastSegment.endEvent.event ? lastSegment.endEvent.event : lastSegment.endEvent.data)
            .toLowerCase()
            .substr(0, 6) === 'moving';

        if (timingOK && timeBased && moving) {
          animatedHeadRoutes[assetId] = {
            startTime: now,
            endTime: estimatedNextEvent,
            segment: lastSegment
          };

          // we also want to move the device to second last position and make its direction point towards the animated route point
          lastSegment.startGnss = {
            ...lastSegment.startGnss,
            heading: getDirectionBetween(lastSegment.from, lastSegment.to)
          };
          currentDevices[assetId] = { ...currentDevices[assetId], gnss: lastSegment.startGnss };
          route.splice(route.length - 1, 1);
          mapRoutes[assetId] = route;
        }
      }
    });

    // now we do a loop through animated heads that we didnt get data for this time to see if they need to be added back to the route
    Object.keys(animatedHeadRoutes).forEach(assetId => {
      if (!receivedNewData[assetId] && now > animatedHeadRoutes[assetId].endTime) {
        // we remove it from the animatedHeadRoutes and add to the currentMapRoute
        mapRoutes[assetId].push(Object.assign({}, animatedHeadRoutes[assetId].segment));
        // we also want to move the device to second last position
        currentDevices[assetId].gnss = mapRoutes[assetId][mapRoutes[assetId].length - 1].endGnss;
        delete animatedHeadRoutes[assetId];
      }
    });
  }

  Object.keys(states).forEach(assetId => {
    states[assetId] = states[assetId].slice(-maxDataPoints);
  });

  Object.keys(mapRoutes).forEach(assetId => {
    if (!retrievedHistory[assetId]) {
      mapRoutes[assetId] = mapRoutes[assetId]?.slice(-segmentsLimit);
    }
  });
  return {
    buffer,
    devices: Object.values(currentDevices),
    lastMQTTProcessedTime,
    states,
    mapRoutes,
    animatedHeadRoutes,
    history: { ...state.history, retrievedHistory: retrievedHistory }
  };
}

export function formatMapMatching(
  state,
  assetId,
  timestamp,
  firstEventTime,
  values,
  points,
  segments,
  timestamps,
  callBack
) {
  const lastMapMatchedData =
    state.mapMatchedData && state.mapMatchedData[assetId] ? state.mapMatchedData[assetId] : null;
  const lastMapMatchedTime = lastMapMatchedData ? lastMapMatchedData.time : 0;

  let eventTime = timestamp;
  let oldMapMatchedData = { ...state.mapMatchedData };
  let mapMatchedData = {
    points: [],
    time: timestamp
  };

  let runCallBack = true;
  let mapRoutes = { ...state.mapRoutes };
  if (values && Array.isArray(values.matchings) && values.matchings.length > 0 && values.code === 'Ok') {
    // strip out all matches that have a confidence level lower than %25, including those that have exponential values eg: xxxx e-11
    const matchings = values.matchings.filter(
      v =>
        v.confidence &&
        v.confidence.toString().indexOf('e') === -1 &&
        v.confidence > REACT_APP_MAP_MATCHING_CONFIDENCE_THRESHOLD
    );
    let firstPoint = null;
    mapMatchedData.points = matchings.reduce((reducedPoints, elem) => {
      let annotations = {
        distance: [],
        duration: [],
        speed: []
      };
      if (Array.isArray(elem.legs)) {
        elem.legs.forEach(leg => {
          if (leg.annotation) {
            annotations.distance = annotations.distance.concat(leg.annotation.distance);
            annotations.duration = annotations.duration.concat(leg.annotation.duration);
            annotations.speed = annotations.speed.concat(leg.annotation.speed);
          }
        });
      }

      const polyPoints = polyline.decode(elem.geometry);

      polyPoints.forEach((point, index) => {
        const toPoint = [point[1] / 10, point[0] / 10];
        if (reducedPoints.length === 0 && !firstPoint) {
          firstPoint = point;
          if (lastMapMatchedData && lastMapMatchedData.points[lastMapMatchedData.points.length - 1].to !== toPoint) {
            // we need to add in a connecting point between the previously map matched route and this newer one
            reducedPoints.push({
              from: lastMapMatchedData.points[lastMapMatchedData.points.length - 1].to,
              to: toPoint,
              distance: 0,
              duration: 0,
              speed: 0,
              assetId: assetId,
              tail: false
            });
          }
        } else {
          // allow for multiple matching objects
          const adjIndex = index === 0 ? 0 : index - 1;
          const newPoint = {
            from:
              reducedPoints.length === 0
                ? [firstPoint[1] / 10, firstPoint[0] / 10]
                : reducedPoints[reducedPoints.length - 1].to,
            to: toPoint,
            distance: annotations.distance[adjIndex] ? annotations.distance[adjIndex] : 0,
            duration: annotations.duration[adjIndex] ? annotations.duration[adjIndex] : 0,
            speed: annotations.speed[adjIndex] ? annotations.speed[adjIndex] : 0,
            assetId: assetId,
            tail: index < 5 && !lastMapMatchedData
          };

          reducedPoints.push(newPoint);
        }
      });

      return reducedPoints;
    }, []);

    const totalPoints = segments.length;
    const totalMatchedPoints = mapMatchedData.points.length;
    const previousMatchedDataLength =
      lastMapMatchedData && Array.isArray(lastMapMatchedData.points) ? lastMapMatchedData.points.length : 0;
    if (totalMatchedPoints > totalPoints) {
      if (lastMapMatchedTime === firstEventTime && previousMatchedDataLength > 0) {
        mapMatchedData.points = lastMapMatchedData.points.concat(mapMatchedData.points);
      }

      const indexWeight = totalPoints / totalMatchedPoints;
      // we now need to fill in the time going from current known time of latest point as far back as we can
      for (let i = mapMatchedData.points.length - 1; i >= 0; i--) {
        const pointIndex = Math.floor((i - previousMatchedDataLength) * indexWeight);
        if (!mapMatchedData.points[i].endTime) {
          mapMatchedData.points[i].startEvent = segments[pointIndex].startEvent;
          mapMatchedData.points[i].endEvent = segments[pointIndex].endEvent;
          mapMatchedData.points[i].endTime = eventTime;
          eventTime -= mapMatchedData.points[i].duration;
          mapMatchedData.points[i].startTime = eventTime;
        } else if (lastMapMatchedTime === firstEventTime && i <= mapMatchedData.points.length - 2) {
          // the first one that has time will be from the old data set
          mapMatchedData.points[i + 1].duration =
            mapMatchedData.points[i + 1].startTime - mapMatchedData.points[i].endTime;
          mapMatchedData.points[i + 1].from = mapMatchedData.points[i].to;
          break;
        }
      }

      runCallBack = false;
      mapMatchedData.points = mapMatchedData.points.slice(-maxMatchedDataPointsToRender);
      mapRoutes[assetId] = mapMatchedData.points;
    }
  }

  oldMapMatchedData[assetId] = mapMatchedData;

  if (runCallBack && callBack) {
    callBack();
  }

  return {
    mapRoutes,
    mapMatchedData: oldMapMatchedData,
    processingMapMatching: false
  };
}

export function getAlertMessage(newNotifications) {
  for (var key in newNotifications) {
    const trigger = newNotifications?.[key]?.[0]?.event?.trigger;
    if (trigger === 'ebs') {
      const notification = newNotifications[key][0];
      const alarm = getAlarm(notification);
      if (alarm) {
        return { ...notification, assetId: key, alarm };
      }
    }
  }
  return null;
}

function isValidTPMS(tpmsArray) {
  return tpmsArray.some(tpmsItem => tpmsItem?.pressure?.suff === false);
}

function getAlarm(notification) {
  const reason = notification?.event?.event;
  if (
    !reason ||
    (reason === 'ABS' && !notification?.ebs?.extended?.abs) ||
    (reason === 'RSS' && !notification?.ebs?.extended?.rss) ||
    (reason === 'TPMS Alarms' && !isValidTPMS(notification?.tpms?.data ?? []))
  ) {
    return null;
  }

  if (reason !== 'WarningLamps') {
    return alarms[reason];
  } else {
    if (isOn(notification, 'amber')) {
      return alarms.ebsAmber;
    }
    if (isOn(notification, 'red')) {
      return alarms.ebsRed;
    }
  }
  return null;
}

function isOn(notification, colour) {
  const lightStatus = notification?.ebs?.extended?.lights?.[colour] ?? '';
  const isLightOn = lightStatus.toString().toLowerCase() === 'on';
  const isEbsValid = notification?.ebs?.valid ?? false;
  const isEbsSpeed = (notification?.ebs?.speed ?? 0) > 10;
  return isLightOn && isEbsSpeed && isEbsValid;
}

export function filterLastHours(itemArray, time = 2) {
  const now = moment();
  const filteredItems = itemArray.filter(item => now.diff(moment(item.time * 1000), 'hours') < time);
  return { items: filteredItems, lastUpdated: now };
}

export { transformMonthlyAndAssets };
