import React from 'react';
import { Row, Col } from 'antd';
import LocaleString from '../Utils/LocaleString';
import { mobileOnly } from '../../helpers/responsive';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import './Installation.scss';

const { Description } = DescriptionList;

const RfDetails = ({ rfDetails }) => {
  const isMobile = mobileOnly.matches;
  if (!rfDetails.sensors) {
    return <div></div>;
  }
  return (
    <div>
      <Row className='installation-details'>
        <Col span={isMobile ? 24 : 10}>
          <h2>
            <LocaleString type='short' id='rfConfig' />
          </h2>
          {rfDetails.sensors.map((sensor, index) => (
            <div key={index} className='rf-detail'>
              <div className='tire' />
              <div className='rf-labels'>
                <DescriptionList title='' size='small' col={1}>
                  <Description term={<LocaleString type='short' id='wheelLocation' />}>{sensor.loc}</Description>
                  <Description term={<LocaleString type='short' id='sensorId' />}>
                    {parseInt(sensor.ID, 16)}
                  </Description>
                </DescriptionList>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

RfDetails.propTypes = {};

export default RfDetails;
