import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { injectIntl } from 'react-intl';

import TrailerHistory from '../TrailerMap/TrailerHistory';
import EventMapContext from './EventMapContext';
import EventDescription from './EventDescription';
import TripDescription from './TripDescription';
import AlertDescription from './AlertDescription';
import LoadingEventDescription from './LoadingEventDescription';
import ErrorDrawerContext from '../ErrorDrawerContext/ErrorDrawerContext';
import actions from '../../actions';
import * as strings from '../../helpers/defaultStrings';
import './EventDrawerContext.scss';
import { mobileOnly } from '../../helpers/responsive';
import { googleMaps } from '../../helpers/constants';

class EventDrawerContext extends React.Component {
  state = { visible: false };

  componentDidUpdate(prevProps) {
    const { event, trip, alert, loadingEvent } = this.props;
    const { visible } = this.state;
    if (
      visible &&
      ((event && JSON.stringify(prevProps.event) === JSON.stringify(event)) ||
        (trip && JSON.stringify(prevProps.trip) === JSON.stringify(trip)) ||
        (loadingEvent && JSON.stringify(prevProps.loadingEvent) === JSON.stringify(loadingEvent)) ||
        (alert && JSON.stringify(prevProps.alert) === JSON.stringify(alert)))
    ) {
      return;
    }
    this.getTrailerContext();
  }

  componentDidMount() {
    this.getTrailerContext();
  }

  getTrailerContext() {
    const { trailer, event, trip, getTrailerContextHistory, alert, loadingEvent, errorClicked } = this.props;

    if (event && !event.notification /*&& event.GPSPosition_latitude && event.GPSPosition_longitude*/) {
      this.setVisible();
      getTrailerContextHistory(trailer.assetId, event.timestamp);
    } else if (event && event.notification) {
      this.setVisible();
      getTrailerContextHistory(event.assetId, event.timestamp);
    } else if (loadingEvent) {
      this.setVisible();
      getTrailerContextHistory(trailer.assetId, loadingEvent.timestampSearch, loadingEvent.durationSearch);
    } else if (trip) {
      this.setVisible();
      getTrailerContextHistory(trailer.assetId, trip.timestamp, trip.duration);
    }

    if (errorClicked) {
      this.showChildrenDrawer(errorClicked);
    }
    if (alert) {
      this.setVisible();
      getTrailerContextHistory(alert.assetId, alert.timestamp);
    }
  }

  setVisible() {
    const { visible } = this.state;
    if (!visible) {
      this.setState({ visible: true });
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
      pos: null,
      followTrailer: false
    });
  };

  onClose = e => {
    const { resetSelection } = this.props;
    const eventClass = e?.currentTarget?.className ?? '';
    if (eventClass.includes('mask')) {
      this.onChildrenDrawerClose();
    }
    this.setState({
      visible: false,
      followTrailer: false
    });
    resetSelection();
  };

  showChildrenDrawer = errorClicked => {
    const { isErrorDrawerOpen, error } = this.state;
    if (!isErrorDrawerOpen && error !== errorClicked) {
      this.setState({
        isErrorDrawerOpen: true,
        error: errorClicked
      });
    }
  };

  onChildrenDrawerClose = () => {
    const { onErrorChange } = this.props;
    onErrorChange();
    this.setState({
      isErrorDrawerOpen: false,
      error: null
    });
  };

  googleMapsUrl = event => {
    if (!event) return null;
    const { GPSPosition_longitude: lon, GPSPosition_latitude: lat } = event;
    if (lon && lat) {
      return `${googleMaps.webURL}?q=${lat},${lon}&t=k`;
    }
    return null;
  };

  goToGoogleMap = mapsUrl => {
    window.open(mapsUrl);
  };

  onHistoryPositionChange = pos => {
    this.setState({ pos });
  };

  toggleFollowTrailer = followTrailer => {
    this.setState({ followTrailer });
  };

  render() {
    const {
      event,
      trailerHistory,
      trailer,
      trip,
      processing,
      alert,
      tripEvents,
      loadingEvent,
      onErrorChange,
      isConverted
    } = this.props;
    const { pos, followTrailer, visible, isErrorDrawerOpen, error } = this.state;
    const { formatMessage } = this.props.intl;
    const altTitle = !trailer && alert && alert.trailer ? alert.trailer : null;
    const events = event ? [event] : tripEvents || null;
    const isMobile = mobileOnly.matches;
    const mapUrl = this.googleMapsUrl(event);
    const isShowMap =
      alert || (event && event.GPSPosition_latitude && event.GPSPosition_longitude) || trip || loadingEvent;
    const isTrailerIABS = trailer?.ebsType === 'IABS' || trailer?.eepromSize === 8;
    return (
      <>
        <Drawer
          mask={!isMobile}
          width={isMobile ? '90%' : '40%'}
          className={isErrorDrawerOpen && error && onErrorChange && 'error-event-context-drawer'}
          title={
            <div>
              {loadingEvent ? formatMessage(strings.phrase.tripContext) : formatMessage(strings.phrase.eventContext)}
            </div>
          }
          placement='right'
          closable={true}
          onClose={this.onClose}
          visible={visible}
          bodyStyle={{ padding: 8, height: 'calc(100% - 56px)' }}
        >
          {!processing && (
            <div className='event-drawer-context-flex-box'>
              {event && (
                <EventDescription
                  isTrailerIABS={isTrailerIABS}
                  event={event}
                  showReadMore={onErrorChange && event.errorCode}
                  onErrorClicked={this.showChildrenDrawer}
                  mapUrl={mapUrl}
                  isConverted={isConverted}
                />
              )}
              {trip && <TripDescription trip={trip} isConverted={isConverted} />}
              {alert && <AlertDescription alert={alert} />}
              {loadingEvent && <LoadingEventDescription loadingEvent={loadingEvent} />}
              {isShowMap && (
                <EventMapContext
                  trailer={trailer}
                  event={event}
                  trailerHistory={trailerHistory || []}
                  pos={pos}
                  followTrailer={followTrailer}
                  alerts={alert ? [alert] : null}
                  tripEvents={tripEvents}
                />
              )}
              {(alert || event || trip || loadingEvent) && (
                <TrailerHistory
                  trailer={trailer}
                  forceCreateChart={true}
                  altTitle={altTitle}
                  onHistoryPositionChange={this.onHistoryPositionChange}
                  toggleFollowTrailer={this.toggleFollowTrailer}
                  events={events}
                  alerts={alert ? [alert] : null}
                  hideIfNoData
                  historicalData={trailerHistory || []}
                  floatingPanel={false}
                  eventContext
                />
              )}
            </div>
          )}
        </Drawer>
        {error && onErrorChange && (
          <ErrorDrawerContext
            isTrailerIABS={isTrailerIABS}
            isMask={!visible}
            onCloseCallback={this.onChildrenDrawerClose}
            visible={isErrorDrawerOpen}
            errorCode={error}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    trailerHistory: store.devices.contextHistory.data,
    devices: store.trailerDetails.trailers.items,
    processing: store.devices.contextHistory.processing,
    error: store.devices.contextHistory.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrailerContextHistory: (assetId, start, duration) => {
      dispatch(actions.trailerHistory.getTrailerContextHistory(assetId, start, duration));
    }
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventDrawerContext));
