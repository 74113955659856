import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LiveMapV2Layout } from './LiveMapV2Layout';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { formatGroteStatusTag } from '../../helpers/grote';
import { filterTrailers } from '../../helpers/liveMap/sharedTrailers';
import { useQuery } from '../../helpers/liveMap/useQuery';
import { selectItems } from '../../selectors/alerts';
import { isTrackAndTraceEnabled } from '../../selectors/auth';
import actions from '../../actions';
import { isDeepEqual } from '../../helpers/functions';
import { selectItems as selectTrailerItems } from '../../selectors/trailer-details';

const prepTrailersData = (rawTrailers, mapRoutes, alerts2h, visibleTrailerIds) => {
  const oldestTime = Math.round(Date.now() / 1000) - 2 * 60 * 60;
  if (!rawTrailers) {
    return [];
  }
  const alerts2hMap = {};
  alerts2h.items.forEach(alert => {
    if (alerts2hMap[alert.assetId]) {
      alerts2hMap[alert.assetId].push({
        ...alert,
        id: `${alert.assetId}${alert.time}`,
        datetime: moment.utc(alert.time * 1000),
        lastUpdated: alerts2h.lastUpdated
      });
    } else {
      alerts2hMap[alert.assetId] = [
        {
          ...alert,
          id: `${alert.assetId}${alert.time}`,
          datetime: moment.utc(alert.time * 1000),
          lastUpdated: alerts2h.lastUpdated
        }
      ];
    }
  });

  return rawTrailers.map(trailer => ({
    ...trailer,
    trail:
      mapRoutes && mapRoutes[trailer.assetId]
        ? mapRoutes[trailer.assetId].filter(d => d.startTime >= oldestTime)
        : null,
    alerts: alerts2hMap[trailer.assetId],
    visibility: visibleTrailerIds?.includes(trailer.assetId),
    categoryTitle: trailer?.category,
    trailerType: trailer.metadata?.odr_trailer_type,
    lightMonitoring: formatGroteStatusTag(trailer.lastValidValues?.grote)
  }));
};

export const LiveMapV2 = () => {
  const rawTrailers = useSelector(selectTrailerItems);
  const mapRoutes = useSelector(store => store.devices.mapRoutes);
  const alerts2h = useSelector(store => store.alerts.alerts2h);
  const visibleTrailerIds = useSelector(store => store.liveMap.visibleTrailerIds);
  const trackAndTraceEnabled = useSelector(isTrackAndTraceEnabled);
  const dispatch = useDispatch();
  const [trailerFilters, setTrailerFilters] = useState({});
  const [trailerSortFunction, setTrailerSortFunction] = useState({});
  const currentTrailers = prepTrailersData(rawTrailers, mapRoutes, alerts2h, visibleTrailerIds);
  // Filtering Trailers
  const filterList = Object.values(trailerFilters);
  const filteredTrailers = filterList.reduce((prev, filterFun) => prev.filter(filterFun), currentTrailers);

  // Sorting Trailers
  const sortedTrailers = trailerSortFunction
    ? filteredTrailers.sort(trailerSortFunction.sortFunction)
    : filteredTrailers;

  // Building Trailers Object Models, common to all subcomponents
  //  Usage:
  //  - data:
  //      trailers.data
  //      trailers.unfilteredData
  //  - Filtering:
  //      trailers.filter(filterName, filterFunction)
  //      trailers.removeFilter(filterName)
  //      trailers.clearAllFilters()
  //  - Sorting:
  //      trailers.sort(sortName, sortFunction)
  //      trailers.clearSort()
  let visibleTrailers = {
    data: sortedTrailers,
    unfilteredData: currentTrailers,
    filter: (filterName, filterFunction) => {
      setTrailerFilters({ ...trailerFilters, [filterName]: filterFunction });
    },
    removeFilter: filterName => {
      const nextFilters = { ...trailerFilters };
      delete nextFilters[filterName];
      setTrailerFilters(nextFilters);
    },
    clearAllFilters: () => {
      setTrailerFilters({});
    },
    trailerFilters,
    sort: (sortName, sortFunction) => {
      if (sortName + 'reversed' === trailerSortFunction.sortName && trailerSortFunction.sortIsReversed) {
        setTrailerSortFunction({});
        return;
      }
      if (sortName === trailerSortFunction.sortName) {
        const newSortName = sortName + 'reversed';
        setTrailerSortFunction({
          sortName: newSortName,
          sortFunction: (a, b) => sortFunction(b, a),
          sortIsReversed: true
        });
        return;
      }
      setTrailerSortFunction({ sortName, sortFunction, sortIsReversed: false });
    },
    clearSort: () => {
      setTrailerSortFunction({});
    },
    sortName: trailerSortFunction.sortName,
    sortIsReversed: trailerSortFunction.sortIsReversed
  };

  let query = useQuery();
  let { selectedDevices } = useParams();

  const alerts = useSelector(selectItems);

  useEffect(() => {
    if (trackAndTraceEnabled) {
      dispatch(actions.devices.fetchInitialDeviceState(rawTrailers?.length || 0));
    }
  }, [rawTrailers]);

  useEffect(() => {
    if (alerts.length > 0) {
      filterTrailers(selectedDevices, query, alerts).then(res => {
        if (!isDeepEqual(trailerFilters, res)) {
          setTrailerFilters({ ...trailerFilters, ...res });
        }
      });
    }
    // eslint-disable-next-line
  }, [alerts]);

  return (
    <>
      <LiveMapV2Layout trailers={visibleTrailers} filteredTrailers={filteredTrailers} />
    </>
  );
};
