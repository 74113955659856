import { languageCode } from './constants';
import en_US from 'antd/lib/locale/en_US';
import zh_CN from 'antd/lib/locale/zh_CN';
import en_GB from 'antd/lib/locale/en_GB';
import bg_BG from 'antd/lib/locale/bg_BG';
import pt_BR from 'antd/lib/locale/pt_BR';
import cs_CZ from 'antd/lib/locale/cs_CZ';
import da_DK from 'antd/lib/locale/da_DK';
import de_DE from 'antd/lib/locale/de_DE';
import es_ES from 'antd/lib/locale/es_ES';
import fr_FR from 'antd/lib/locale/fr_FR';
import it_IT from 'antd/lib/locale/it_IT';
import ja_JP from 'antd/lib/locale/ja_JP';
import nl_NL from 'antd/lib/locale/nl_NL';
import nb_NO from 'antd/lib/locale/nb_NO';
import pl_PL from 'antd/lib/locale/pl_PL';
import pt_PT from 'antd/lib/locale/pt_PT';
import ro_RO from 'antd/lib/locale/ro_RO';
import ru_RU from 'antd/lib/locale/ru_RU';
import sk_SK from 'antd/lib/locale/sk_SK';
import sl_SI from 'antd/lib/locale/sl_SI';
import sv_SE from 'antd/lib/locale/sv_SE';
import tr_TR from 'antd/lib/locale/tr_TR';

export const getAntLocale = language => {
  switch (language) {
    case languageCode.Czech:
      return cs_CZ;
    case languageCode.Chinese:
      return zh_CN;
    case languageCode.Danish:
      return da_DK;
    case languageCode.Bulgarian:
      return bg_BG;
    case languageCode.German:
      return de_DE;
    case languageCode.EnglishUSA:
      return en_US;
    case languageCode.Spanish:
      return es_ES;
    case languageCode.French:
      return fr_FR;
    case languageCode.Italian:
      return it_IT;
    case languageCode.Japanese:
      return ja_JP;
    case languageCode.Dutch:
      return nl_NL;
    case languageCode.Norwegian:
      return nb_NO;
    case languageCode.Polish:
      return pl_PL;
    case languageCode.Portuguese:
      return pt_PT;
    case languageCode.PortugueseBrazilian:
      return pt_BR;
    case languageCode.Russian:
      return ru_RU;
    case languageCode.Romanian:
      return ro_RO;
    case languageCode.Slovak:
      return sk_SK;
    case languageCode.Slovenian:
      return sl_SI;
    case languageCode.Swedish:
      return sv_SE;
    case languageCode.Turkish:
      return tr_TR;
    default:
      return en_GB;
  }
};
