import { selectFeatureFlagsIsDataLoaded } from '../../../selectors/feature-flags';

export const waitForFeatureFlags = getState => {
  const checkCondition = () => selectFeatureFlagsIsDataLoaded(getState());
  const timeout = 100;
  const maxRetries = 100; // 10 seconds
  let retries = 0;

  return new Promise((resolve, reject) => {
    const isDataLoaded = () => {
      if (checkCondition()) {
        resolve();
      } else if (retries >= maxRetries) {
        reject(new Error('waitForFeatureFlags: timed out'));
      } else {
        retries += 1;
        setTimeout(isDataLoaded, timeout);
      }
    };

    isDataLoaded();
  });
};
