import GeoViewport from '@mapbox/geo-viewport';
import { polygon } from '@turf/helpers';

export const getViewPortPolygon = ({ viewState, mapRef }) => {
  if (!viewState || !mapRef) {
    return;
  }

  const bounds = GeoViewport.bounds(
    [viewState.longitude, viewState.latitude],
    viewState.zoom,
    [mapRef.current._width, mapRef.current._height],
    512
  );

  const hasNaN = bounds.some(el => isNaN(el));
  if (!bounds.length || hasNaN) {
    return;
  }
  const bottomLeft = [bounds[0], bounds[1]];
  const topRight = [bounds[2], bounds[3]];
  const topLeft = [bounds[0], bounds[3]];
  const bottomRight = [bounds[2], bounds[1]];

  return polygon([[bottomLeft, topLeft, topRight, bottomRight, bottomLeft]]);
};
