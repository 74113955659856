import * as d3 from 'd3';
import Chart from '../chart';

export default class OdoEventSummaryChart extends Chart {
  // First step of the D3 rendering.
  create() {
    const { maxDistanceString, minDistanceString } = this.props;
    this.svg = super.createRoot();
    this.main = this.svg
      .append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.height / 2})`);

    this.frontInteraction = this.svg
      .append('rect')
      .attr('width', this.props.width)
      .attr('height', this.props.width)
      .attr('opacity', 0);

    this.background = this.main.append('g').attr('class', 'background');

    this.events = this.main.append('g').attr('class', 'events');

    this.legend = this.main.append('g').attr('class', 'legend').attr('opacity', 0);

    this.legend
      .append('text')
      .attr('x', 0)
      .attr('y', 13)
      .attr('text-anchor', 'start')
      .attr('font-size', 10)
      .text(maxDistanceString);

    this.legend
      .append('text')
      .attr('x', this.props.width)
      .attr('y', 13)
      .attr('text-anchor', 'end')
      .attr('font-size', 10)
      .text(minDistanceString);

    this.barHeight = 8;
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.events && state.events.length > 0) {
      this._drawChart(state);
    }
    this.drawLegend();
  }

  _drawChart(state) {
    const { start, end } = state;
    const eventData = state.events
      .map(e => e)
      .sort((a, b) => +a.priority - +b.priority)
      .filter(e => e.mileage >= Math.max(state.domain ? state.domain[0] : 0, start));
    const x = d3.scaleLinear().range([0, this.props.width]).domain([start, end]);

    this.background
      .append('rect')
      .attr('x', 0)
      .attr('y', -this.barHeight / 2)
      .attr('width', this.props.width)
      .attr('height', this.barHeight)
      .attr('rx', this.barHeight / 2)
      .attr('ry', this.barHeight / 2)
      .style('fill', 'lightgrey');

    if (state.domain) {
      this.background
        .append('rect')
        .attr('x', x(Math.max(state.domain[0], start)))
        .attr('y', -this.barHeight / 2)
        .attr('width', Math.max(0, x(state.domain[1]) - x(Math.max(state.domain[0], start))))
        .attr('height', this.barHeight)
        .attr('rx', this.barHeight / 2)
        .attr('ry', this.barHeight / 2)
        .style('fill', '#59bc7c');
    }

    const events = this.events.selectAll('.event').data(eventData);
    const pathsEnter = events.enter().append('circle').attr('class', 'event');
    pathsEnter
      .merge(events)
      .transition()
      .duration(750)
      .attr('fill', d => d.color)
      .attr('cy', 0)
      .attr('cx', d => x(d.mileage))
      .attr('r', this.barHeight / 2);

    this.legend.attr('opacity', 0);
  }

  drawLegend() {
    this.frontInteraction
      .on('mouseenter', () => {
        this.legend.attr('opacity', 1);
      })
      .on('mouseleave', () => {
        this.legend.attr('opacity', 0);
      });
  }
}
