import { defineMessages } from 'react-intl';

export const ebpms = defineMessages({
  computedMoreThanTwoDaysAgo: {
    id: 'ebpms.computedMoreThanTwoDaysAgo',
    defaultMessage: 'Was computed more than 2 days ago',
    description: ''
  },
  notEnoughValidBrakeEvents: {
    id: 'ebpms.notEnoughValidBrakeEvents',
    defaultMessage: 'Not enough valid brake events',
    description: ''
  },
  noHistoricalData: {
    id: 'ebpms.noHistoricalData',
    defaultMessage: 'No Historical Data',
    description: ''
  },
  windowSize: {
    id: 'ebpms.windowSize',
    defaultMessage: 'Window size',
    description: ''
  },
  errorMargin: {
    id: 'ebpms.errorMargin',
    defaultMessage: 'Error margin',
    description: ''
  },
  maxValidDays: {
    id: 'ebpms.maxValidDays',
    defaultMessage: 'Max days valid',
    description: ''
  },
  brakingEvents: {
    id: 'ebpms.brakingEvents',
    defaultMessage: 'Braking Events',
    description: ''
  },
  noSelection: {
    id: 'ebpms.noSelection',
    defaultMessage: 'no selection',
    description: ''
  },
  fromTo: {
    id: 'ebpms.fromTo',
    defaultMessage: 'from {fromTime} to {toTime}',
    description:
      '{fromTime} and {toTime} are variables and should not be translated. {fromTime} and {toTime} are times.'
  },
  performance: {
    id: 'ebpms.performance',
    defaultMessage: 'performance',
    description: ''
  },
  brakeEvents: {
    id: 'ebpms.brakeEvents',
    defaultMessage: 'brake events',
    description: ''
  },
  selected: {
    id: 'ebpms.selected',
    defaultMessage: 'selected',
    description: ''
  },
  rejected: {
    id: 'ebpms.rejected',
    defaultMessage: 'rejected',
    description: ''
  },
  averageDeceleration: {
    id: 'ebpms.averageDeceleration',
    defaultMessage: 'Average Deceleration  [{value}]',
    description: '{value} is a variable and should not be translated. {value} will be string %g.'
  },
  averagePressure: {
    id: 'ebpms.averagePressure',
    defaultMessage: 'Average Pressure  [{value}]',
    description: '{value} is a variable and should not be translated. {value} will be a string bar.'
  },
  startSpeed: {
    id: 'ebpms.startSpeed',
    defaultMessage: 'Start Speed:',
    description: ''
  },
  slope: {
    id: 'ebpms.slope',
    defaultMessage: 'Slope:',
    description: ''
  },
  distance: {
    id: 'ebpms.distance',
    defaultMessage: 'Distance:',
    description: ''
  },
  startTime: {
    id: 'ebpms.startTime',
    defaultMessage: 'Start Time:',
    description: ''
  },
  duration: {
    id: 'ebpms.duration',
    defaultMessage: 'Duration:',
    description: ''
  },
  seconds: {
    id: 'ebpms.seconds',
    defaultMessage: 'seconds',
    description: 'nami'
  },
  rejectedBecause: {
    id: 'ebpms.rejectedBecause',
    defaultMessage: 'REJECTED because',
    description: ''
  },
  commentChangeProceed: {
    id: 'ebpms.commentChangeProceed',
    defaultMessage: 'This change will be visible by the customer. Proceed?',
    description: 'warning message, asking customer are they happy to continue'
  },
  lastUpdatedBy: {
    id: 'ebpms.lastUpdatedBy',
    defaultMessage: 'Last updated by: {VALUE}',
    description: '{VALUE} is a variable and should not be translated. {VALUE} is a user name'
  },
  lastEditedOn: {
    id: 'ebpms.lastEditedOn',
    defaultMessage: 'Last edited on: {VALUE}',
    description: '{VALUE} is a variable and should not be translated. {VALUE} is a full date and time'
  },
  neverUpdated: {
    id: 'ebpms.neverUpdated',
    defaultMessage: 'Never updated',
    description: ''
  },
  comment: {
    id: 'ebpms.comment',
    defaultMessage: 'TIP comment',
    description: 'TIP is a company name, this is to indicated a comment added by the company TIP'
  },
  hasComment: {
    id: 'ebpms.hasComment',
    defaultMessage: 'Has comment',
    description: ''
  },
  noComment: {
    id: 'ebpms.noComment',
    defaultMessage: 'No comment',
    description: ''
  },
  algorithm: {
    id: 'ebpms.algorithm',
    defaultMessage: 'Enable Load BPV',
    description: ''
  },
  standard65Version: {
    id: 'ebpms.standard65Version',
    defaultMessage: 'Standard version',
    description: ''
  },
  standardLoadVersion: {
    id: 'ebpms.standardLoadVersion',
    defaultMessage: 'Load version (new)',
    description: ''
  },
  standardLoadBPV: {
    id: 'ebpms.standardLoadBPV',
    defaultMessage: 'Load BPV',
    description: ''
  },
  ladenRBT: {
    id: 'ebpms.ladenRBT',
    defaultMessage: 'Laden RBT',
    description: 'Heavy load Roller Break Test'
  },
  ladenRBTPass: {
    id: 'ebpms.ladenRBTPass',
    defaultMessage: 'Laden RBT: Pass',
    description: 'Passed status for the Heavy load Roller Break Test'
  },
  ladenRBTFail: {
    id: 'ebpms.ladenRBTFail',
    defaultMessage: 'Laden RBT: Fail',
    description: 'Failed status for the Heavy load Roller Break Test'
  },
  relined: {
    id: 'ebpms.relined',
    defaultMessage: 'Relined',
    description: 'Relined status for the last intervention action'
  },
  ladenRollerBTPass: {
    id: 'ebpms.ladenRollerBTPass',
    defaultMessage: 'Laden Roller Break Test - Pass',
    description: 'Passed status for the Heavy load Roller Break Test'
  },
  ladenRollerBTFail: {
    id: 'ebpms.ladenRollerBTFail',
    defaultMessage: 'Laden Roller Break Test - Fail',
    description: 'Failed status for the Heavy load Roller Break Test'
  },
  lastIntervention: {
    id: 'ebpms.lastIntervention',
    defaultMessage: 'Last Intervention',
    description: 'date field label: the last time an intervention happened for this trailer'
  },
  LRBTestResult: {
    id: 'ebpms.LRBTestResult',
    defaultMessage: 'Laden Roller Brake Test results',
    description: 'Result of the RBT test with load'
  },
  LISaveError: {
    id: 'ebpms.LISaveError',
    defaultMessage: 'There was an error saving last intervention. Please try again later.',
    description: 'Error message for saving the date of the last intervention of this trailer'
  },
  requiredField: {
    id: 'ebpms.requiredField',
    defaultMessage: 'This field is required',
    description: 'Error message for an empty required text field'
  },
  errorFutureDate: {
    id: 'ebpms.errorFutureDate',
    defaultMessage: 'The date cannot be in the future',
    description: 'Error message for an invalid date field"'
  },
  invalidNumber: {
    id: 'ebpms.invalidNumber',
    defaultMessage: 'Invalid number',
    description: 'Error message for an invalid date field"'
  },
  LRBT: {
    id: 'ebpms.LRBT',
    defaultMessage: 'Laden Roller Brake Test',
    description: 'Header for the RBT test with load'
  },
  noActionRequired: {
    id: 'ebpms.noActionRequired',
    defaultMessage: 'No action required',
    description: 'Intervention description'
  },
  rectificationWork: {
    id: 'ebpms.rectificationWork',
    defaultMessage: 'Rectification work carried out',
    description: 'Intervention description'
  },
  mileageSinceLastIntervention: {
    id: 'ebpms.mileageSinceLastIntervention',
    defaultMessage: 'Mileage Since Intervention',
    description: 'km or miles driven since the last intervention date'
  },
  daysSinceLastIntervention: {
    id: 'ebpms.daysSinceLastIntervention',
    defaultMessage: 'Days Since Intervention',
    description: 'days since the last intervention date'
  },
  daysFailing: {
    id: 'ebpms.daysFailing',
    defaultMessage: 'Days failing',
    description: ''
  },
  ebpmsState: {
    id: 'ebpms.ebpmsState',
    defaultMessage: 'EBPMS state',
    description: 'EBPMS is a name do not translate'
  },
  stateChange: {
    id: 'ebpms.stateChange',
    defaultMessage: 'State change',
    description: ''
  },
  started: {
    id: 'ebpms.started',
    defaultMessage: 'Started',
    description: ''
  },
  ended: {
    id: 'ebpms.ended',
    defaultMessage: 'Ended',
    description: ''
  },
  inactive: {
    id: 'ebpms.inactive',
    defaultMessage: 'Inactive',
    description: ''
  },
  ebpmsStateTooltip: {
    id: 'ebpms.ebpmsStateTooltip',
    defaultMessage: 'Current state of data collection related to EBPMS',
    description: 'EBPMS is a name do not translate'
  },
  stateChangeTooltip: {
    id: 'ebpms.stateChangeTooltip',
    defaultMessage: 'Date of the last change for the EBPMS State.​',
    description: 'EBPMS is a name do not translate'
  },
  stateChangeTooltipStarted: {
    id: 'ebpms.stateChangeTooltipStarted',
    defaultMessage: '“Started” state means the date EBPMS service was activated​',
    description: 'EBPMS is a name do not translate'
  },
  stateChangeTooltipEnded: {
    id: 'ebpms.stateChangeTooltipEnded',
    defaultMessage: '“Ended” state means the date EBPMS was de-activated​',
    description: 'EBPMS is a name do not translate'
  },
  stateChangeTooltipInactive: {
    id: 'ebpms.stateChangeTooltipInactive',
    defaultMessage: '“Inactive” state means it was never started',
    description: ''
  },
  last7Days: {
    id: 'ebpms.last7Days',
    defaultMessage: 'Last 7 days'
  },
  last7DaysTooltip: {
    id: 'ebpms.last7DaysTooltip',
    defaultMessage: 'Performance trend over the last 7 days'
  },
  tipAlgorithmTooltip: {
    id: 'ebpms.tipAlgorithmTooltip',
    defaultMessage: 'The new performance algorithm version can only be chosen by TIP',
    description: ''
  },
  tipCommentTooltip: {
    id: 'ebpms.tipCommentTooltip',
    defaultMessage: 'Comments can only be added by TIP',
    description: ''
  },
  daysFailingTooltip: {
    id: 'ebpms.daysFailingTooltip',
    defaultMessage: 'Number of continuous days since last Pass',
    description: ''
  },
  lastInterventionTooltip: {
    id: 'ebpms.lastInterventionTooltip',
    defaultMessage: 'Laden RBT Pass or Fail',
    description: ''
  },
  mileageSinceLastInterventionTooltip: {
    id: 'ebpms.mileageSinceLastInterventionTooltip',
    defaultMessage: 'Distance in KM since last intervention',
    description: ''
  },
  mileageSinceLastInterventionTooltipMiles: {
    id: 'ebpms.mileageSinceLastInterventionTooltipMiles',
    defaultMessage: 'Distance in MI since last intervention',
    description: ''
  },
  daysSinceLastInterventionTooltip: {
    id: 'ebpms.daysSinceLastInterventionTooltip',
    defaultMessage: 'Number of continuous days since last intervention',
    description: ''
  }
});
