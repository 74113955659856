import React from 'react';
import { injectIntl } from 'react-intl';
import actions from '../../actions';
import {
  CodeOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  LayoutOutlined,
  LineChartOutlined,
  SnippetsOutlined,
  WarningOutlined
} from '@ant-design/icons';

import { Row, Col, Tabs } from 'antd';
import Inspector from 'react-inspector';
import { connect } from 'react-redux';

import HistogramList from './HistogramList';
import OdrBrakingList from './OdrBrakingList';
import OdrFiles from './OdrFiles';
import Events from '../Events/Events';
import Installation from '../Installation/Installation';
import RfDetails from '../Installation/RfDetails';
import LocaleString from '../Utils/LocaleString';
import { tabOrModalView } from '../../helpers/googleAnalytics';
import { isUsaCustomer, getIsTraileriABS, getIsShowODRData, getIsTrailerTEBSF } from '../../helpers/functions';
import './TrailerIdCardContent.scss';
import TrailerIdCardContentGeneralInfo from './TrailerIdCardContentGeneralInfo';
import { selectRegion } from '../../selectors';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { isEmpty } from '../../helpers/objects';
import { Logger } from '../../app/helpers/logger';

const { REACT_APP_ENV } = ENV_CONFIG;

class TrailerIdCardContent extends React.Component {
  constructor(props) {
    super(props);
    const { region } = this.props;
    this.state = {
      // if the default tab is ever changed please update the GoogleAnalytics pageView code in LocaleContainer.js to reflect this
      shared: true
    };
    this.isUsaCustomer = isUsaCustomer(region);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match && !match.url.startsWith('/temp')) {
      this.setState({ shared: false });
    }
  }

  onChangeTab = tabKey => {
    this.props.history.push(this.props.location.pathname + '?tab=' + tabKey);
    switch (parseInt(tabKey, 10)) {
      case 0:
        tabOrModalView('general-info');
        break;
      case 1:
        tabOrModalView('events');
        break;
      case 2:
        tabOrModalView('full-description');
        break;
      default:
        Logger.log('Unknown tab in TrailerIdCard', tabKey);
        break;
    }
  };

  selectTrailerOdrBraking = assetId => {
    const { updateSelectedHealthTrailer, trailers } = this.props;
    const trailerIdTab = this.getActiveTab();
    this.props.history.push(`/trailer/${assetId}?tab=${trailerIdTab}`);
    const trailer = trailers.find(t => t.assetId === assetId);
    if (trailer) {
      updateSelectedHealthTrailer(trailer);
    }
  };

  getActiveTab(defaultTab) {
    const urlQuery = this.props.location?.search;
    const query = new URLSearchParams(urlQuery);
    const queryTab = query.get('tab');
    const activeTab = queryTab ? queryTab : defaultTab;
    return activeTab;
  }

  render() {
    const { trailer, trailerCurrent, trailers, installationDetails, rfDetails, isTempAccess, getOdrBrakingAvg, match } =
      this.props;
    // Assumption: if trailer.vinNumber is not present the device is assumed to be not decoded
    const { shared } = this.state;

    const defaultTab = match && match.url.startsWith('/health') ? '1' : '0';
    const activeTab = this.getActiveTab(defaultTab);

    const dtc =
      trailer &&
      (getIsShowODRData(trailer) || getIsTrailerTEBSF(trailer) || trailer.eepromSize === 8 || this.isUsaCustomer)
        ? trailer.dtc
        : null;
    const trips = trailer ? trailer.trips : null;
    const recordedEvents = trailer ? trailer.recordedEvents : null;
    const { lastOdo } = trailer;
    const selectedTrailer = trailers ? trailers.filter(item => item.assetId === trailer.assetId)[0] : null;
    const isiABS = getIsTraileriABS(trailer);

    if (
      selectedTrailer &&
      selectedTrailer.tires &&
      selectedTrailer.ebsTime &&
      selectedTrailer.lastPosition &&
      selectedTrailer.ebsTime < selectedTrailer.lastPosition
    ) {
      trailer.tires = selectedTrailer.tires;
      trailer.ebsTime = selectedTrailer.ebsTime;
    }

    return (
      <div style={{ height: '100%' }}>
        {/*
        Desabling pdf export for now
        <Row type="flex" justify="end">
          <Button style={{ margin: 8}} size="small" onClick={this.printDocument} icon="file-pdf">PDF</Button>
        </Row>*/}
        <Row type='flex' style={{ height: '100%' }}>
          <Col span={24}>
            <div style={{ backgroundColor: 'white', height: '100%' }}>
              {trailer && (
                <div>
                  <Tabs
                    tabPosition={window.innerWidth > 768 ? 'left' : 'top'}
                    defaultActiveKey={trailer.vinNumber ? defaultTab : '2'}
                    onChange={this.onChangeTab}
                    activeKey={activeTab}
                    className='trailer-id-card-content__tabs'
                  >
                    <Tabs.TabPane
                      className='trailer-id-card-content__tabs__tab'
                      tab={
                        <span>
                          <InfoCircleOutlined />
                          <LocaleString type='short' id='generalInfo' />
                        </span>
                      }
                      key='0'
                      disabled={!trailer.vinNumber}
                    >
                      {trailer.vinNumber && (
                        <TrailerIdCardContentGeneralInfo
                          trailer={trailer}
                          trailerCurrent={trailerCurrent}
                          hideAxleView={isiABS}
                        />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      className='trailer-id-card-content__tabs__tab'
                      tab={
                        <span>
                          <WarningOutlined />
                          <LocaleString type='short' id='events' />
                        </span>
                      }
                      key='1'
                      disabled={!trailer.vinNumber}
                    >
                      {activeTab === '1' && (
                        <div id='componentToPrint2'>
                          <Events
                            dtc={dtc}
                            trips={trips}
                            recordedEvents={recordedEvents}
                            trailer={trailer}
                            shared={shared}
                            lastOdo={lastOdo}
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    {!trailer.uploaded && (installationDetails || (rfDetails && !isEmpty(rfDetails))) && (
                      <Tabs.TabPane
                        className='trailer-id-card-content__tabs__tab'
                        tab={
                          <span>
                            <SnippetsOutlined />
                            <LocaleString type='short' id='installation' />
                          </span>
                        }
                        key='5'
                        disabled={!trailer.vinNumber}
                      >
                        {installationDetails && <Installation installationDetails={installationDetails} />}
                        {rfDetails && <RfDetails rfDetails={rfDetails} />}
                      </Tabs.TabPane>
                    )}
                    {!trailer.uploaded && !isTempAccess && (
                      <Tabs.TabPane
                        className='trailer-id-card-content__tabs__tab'
                        tab={
                          <span>
                            <FolderOpenOutlined />
                            <LocaleString type='short' id='ordFiles' />
                          </span>
                        }
                        key='2'
                      >
                        <OdrFiles trailerId={trailer.assetId} vinNumber={trailer.vinNumber} />
                      </Tabs.TabPane>
                    )}
                    <Tabs.TabPane
                      className='trailer-id-card-content__tabs__tab'
                      tab={
                        <span>
                          <LineChartOutlined />
                          <LocaleString type='short' id='histograms' />
                        </span>
                      }
                      key='3'
                    >
                      <HistogramList trailer={trailer} isTempAccess={isTempAccess} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      className='trailer-id-card-content__tabs__tab'
                      tab={
                        <span>
                          <LineChartOutlined />
                          <LocaleString type='short' id='trailerDrivingProfile' />
                        </span>
                      }
                      key='OdrBraking'
                    >
                      <OdrBrakingList
                        trailer={trailer}
                        selectTrailer={this.selectTrailerOdrBraking}
                        isTempAccess={isTempAccess}
                        getOdrBrakingAvg={getOdrBrakingAvg}
                      />
                    </Tabs.TabPane>

                    {trailer.adap25 && (
                      <Tabs.TabPane
                        className='trailer-id-card-content__tabs__tab'
                        tab={
                          <span>
                            <LayoutOutlined />
                            <LocaleString type='short' id='extraInfo' />
                          </span>
                        }
                        key='4'
                      >
                        <Inspector data={trailer.adap25} expandPaths={['$', '$.GOHC']} />
                      </Tabs.TabPane>
                    )}

                    {REACT_APP_ENV.toString().trim().toLowerCase() === 'integration' && (
                      <Tabs.TabPane
                        className='trailer-id-card-content__tabs__tab'
                        tab={
                          <span>
                            <CodeOutlined />
                            <LocaleString type='short' id='fullDescription' />
                          </span>
                        }
                        key='6'
                      >
                        <Inspector data={trailer} expandPaths={['$', '$.originalData', '$.originalData.data']} />
                      </Tabs.TabPane>
                    )}
                  </Tabs>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOdrBrakingAvg: () => dispatch(actions.trailersOdrBraking.getOdrBrakingAvg()),
    updateSelectedHealthTrailer: trailer => dispatch(actions.appState.updateSelectedHealthTrailer(trailer))
  };
}

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    trailers: store.trailerDetails.trailers.items,
    region
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerIdCardContent));
