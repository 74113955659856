import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Layout, Modal, Spin } from 'antd';
import { Route, Switch } from 'react-router';
import { injectIntl } from 'react-intl';
import { logout } from '../../actions/thunks/auth/logout';
import { checkUserIsLoggedIn } from '../../actions/thunks/auth/check-user-is-logged-in';
import HelpContainer from '../Help/HelpContainer';
import ExtraRoutes from './ExtraRoutes';
import paths from '../../app/paths';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import NotificationCentre from '../Notifications/NotificationCentre';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import * as strings from '../../helpers/defaultStrings';
import { mobileOnly } from '../../helpers/responsive';
import RouteWrapper from './RouteWrapper';
import './Layout.scss';
import { favIcons, PRODUCT_FEATURES, ERROR_BOUNDARY_LEVELS } from '../../helpers/constants';
import TrailerIdCard from '../TrailerIdCard/TrailerIdCard';
import { LiveMapV2 } from '../LiveMapV2/LiveMapV2';
import ReportList from '../ReportList/ReportList';
import { isScalar } from '../../helpers/functions';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import PlacesAlarmsCentre from '../Places/PlacesAlarms/PlacesAlarmsCentre';
import { selectProductFeatures } from '../../selectors/auth';
import { selectSettingsItems } from '../../selectors/settings';
import { Logger } from '../../app/helpers/logger';

const Home = lazy(() => import('../Home/Home'));
const EbpmsV2 = lazy(() => import('../EbpmsV2/EbpmsV2'));
const Reports = lazy(() => import('../Reports/Reports'));
const Safety = lazy(() => import('../Safety/Safety'));
const Health = lazy(() => import('../Health/Health'));
const LoadingEvents = lazy(() => import('../LoadingEvents/Dashboard'));

const renderLoader = () => (
  <div className='centred'>
    <Spin />
  </div>
);

const { Content, Sider } = Layout;

const WaitingComponent = (props, Component) => (
  <Suspense fallback={renderLoader()}>
    <Component {...props} />
  </Suspense>
);

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      logoutPrompt: null,
      promptCountdownTime: 30,
      expiresAt: 0
    };
  }

  componentDidMount() {
    const { checkUserLoggedIn, location } = this.props;
    if (!location.pathname.startsWith('/login') && !isScalar()) {
      const token = window.localStorage.getItem('transics-token');
      checkUserLoggedIn(token);
    }
  }

  componentDidUpdate(prevProps) {
    const { user, logout, locationPath } = this.props;
    const { logoutPrompt, promptCountdownTime } = this.state;

    if (user && JSON.stringify(prevProps.user) !== JSON.stringify(user)) {
      const expiresAt =
        !user.expires_at && user.sessionTtl && user.iat
          ? (parseInt(user.sessionTtl, 10) + parseInt(user.iat, 10)) * 1000
          : user.expires_at;
      if (expiresAt) {
        if (expiresAt > Date.now()) {
          const timeToPrompt = Math.round((expiresAt - Date.now()) / 1000) - promptCountdownTime;
          if (logoutPrompt) {
            // shouldnt ever be the case as Layout should only be updated when browser is refreshed
            // in case future feature updates user object we need to be able to clear old prompt here
            clearTimeout(logoutPrompt);
          }

          this.setState({
            logoutPrompt: setTimeout(() => this.logoutCountDown(), timeToPrompt * 1000),
            expiresAt
          });
        } else {
          logout();
        }
      }
    }
    // Close menu Drawer when the route changes
    if (mobileOnly.matches && prevProps.locationPath !== locationPath) {
      this.onCollapse(true);
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  toggleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  logoutCountDown() {
    const { logout } = this.props;
    const { formatMessage } = this.props.intl;
    const { promptCountdownTime, expiresAt } = this.state;

    if (expiresAt > 0 && expiresAt < Date.now()) {
      logout();
      return;
    }

    let secondsToGo = promptCountdownTime;
    const modal = Modal.warning({
      title: formatMessage(strings.alert.sessionExpiringTitle),
      okText: formatMessage(strings.button.sessionRenew),
      content: (
        <span>
          <br />
          {formatMessage(strings.alert.sessionExpiringDescriptionVal, {
            value: secondsToGo
          })}
          <br />
          <br />
          {formatMessage(strings.alert.sessionExpiringActionDetails)}
          <br />
        </span>
      ),
      onOk: logout
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content:
          secondsToGo > 0 ? (
            <span>
              <br />
              {formatMessage(strings.alert.sessionExpiringDescriptionVal, {
                value: secondsToGo
              })}
              <br />
              <br />
              {formatMessage(strings.alert.sessionExpiringActionDetails)}
              <br />
            </span>
          ) : (
            <span>{formatMessage(strings.alert.sessionExpiringLoggingOut)}</span>
          )
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      logout();
    }, (secondsToGo + 1) * 1000); // add 1 second to ensure it is after MyTransics session
  }

  updateFavIcon() {
    const {
      productFeatures: { logoImage }
    } = this.props;
    const oldLink = document.getElementById('favicon');
    if (oldLink) {
      const link = document.createElement('link');
      const publicUrl = oldLink.getAttribute('publicurl');
      link.id = 'favicon';
      link.rel = 'shortcut icon';
      link.setAttribute('publicurl', publicUrl);
      link.href = publicUrl + favIcons[logoImage];
      document.head.removeChild(oldLink);
      document.head.appendChild(link);
    }
    if (logoImage === 'tip') {
      document.title = 'BrakePlus ';
    } else if (logoImage === 'default') {
      document.title = 'TX - TRAILERFIT ';
    }
  }

  render() {
    const {
      location: { pathname: pathName },
      authProcessing,
      user
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { collapsed } = this.state;
    this.updateFavIcon();
    const isLegacy = !isScalar();

    return (
      <ErrorBoundary errorLevel={ERROR_BOUNDARY_LEVELS.CRITICAL}>
        <Modal
          visible={authProcessing}
          closable={false}
          title={null}
          width={100}
          footer={null}
          wrapClassName='loading-modal'
          centered={true}
        >
          <Spin size='large' />
        </Modal>
        {!authProcessing && (
          <Layout className='trailerfit-layout'>
            <HeaderMenu
              toggleCollapse={this.toggleCollapse}
              collapsed={collapsed}
              formatMessage={formatMessage}
              user={user}
            />
            <ErrorBoundary errorLevel={ERROR_BOUNDARY_LEVELS.IMPORTANT}>
              <Layout>
                <Sider
                  collapsible
                  collapsed={collapsed}
                  onCollapse={this.toggleCollapse}
                  trigger={null}
                  theme='light'
                  className='layout-slider'
                >
                  {user && <NavigationMenu currentPath={pathName} productFeatures={this.props.productFeatures} />}
                </Sider>
                <ErrorBoundary errorLevel={ERROR_BOUNDARY_LEVELS.HIGH}>
                  <Content
                    style={{
                      height: 'calc(100vh - 55px)'
                    }}
                  >
                    <Switch>
                      <Route
                        exact
                        path={paths.liveMap}
                        render={props => (
                          <RouteWrapper RouteComponent={LiveMapV2} flag={PRODUCT_FEATURES.SHOW_LIVE_MAP} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={paths.liveMapIds}
                        render={props => (
                          <RouteWrapper RouteComponent={LiveMapV2} flag={PRODUCT_FEATURES.SHOW_LIVE_MAP} {...props} />
                        )}
                      />
                      <Route exact path={paths.home} component={props => WaitingComponent(props, Home)} />
                      <Route exact path={paths.safety} component={props => WaitingComponent(props, Safety)} />
                      <Route
                        exact
                        path={paths.health}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={props => WaitingComponent(props, Health)}
                            flag={PRODUCT_FEATURES.SHOW_TRAILER_HEALTH}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.healthDetails}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={TrailerIdCard}
                            flag={PRODUCT_FEATURES.SHOW_VEHICLE_ID}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.trailers}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={TrailerIdCard}
                            flag={PRODUCT_FEATURES.SHOW_VEHICLE_ID}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.trailerId}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={TrailerIdCard}
                            flag={PRODUCT_FEATURES.SHOW_VEHICLE_ID}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.notifications}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={NotificationCentre}
                            flag={PRODUCT_FEATURES.SHOW_NOTIFICATION}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.notificationsTab}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={NotificationCentre}
                            flag={PRODUCT_FEATURES.SHOW_NOTIFICATION}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.loadingEvents}
                        component={props => WaitingComponent(props, LoadingEvents)}
                      />
                      <Route
                        exact
                        path={paths.reportList}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={ReportList}
                            flag={PRODUCT_FEATURES.SHOW_TRACK_TRACE_REPORTS}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.reports}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={props => WaitingComponent(props, Reports)}
                            flag={PRODUCT_FEATURES.SHOW_EBPMS}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={paths.ebpms}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={props => WaitingComponent(props, EbpmsV2)}
                            flag={PRODUCT_FEATURES.SHOW_EBPMS}
                            {...props}
                          />
                        )}
                      />

                      <Route
                        exact
                        path={paths.help}
                        render={props => (
                          <RouteWrapper RouteComponent={HelpContainer} flag={PRODUCT_FEATURES.SHOW_EBPMS} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={paths.placesAlarms}
                        render={props => (
                          <RouteWrapper
                            RouteComponent={PlacesAlarmsCentre}
                            {...props}
                            flag={'showPlacesNotifications'}
                          />
                        )}
                      />
                      <ExtraRoutes isLegacy={isLegacy} />
                    </Switch>
                  </Content>
                </ErrorBoundary>
              </Layout>
            </ErrorBoundary>
          </Layout>
        )}
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state, props) {
  let selectedColumnKeys = null;
  const items = selectSettingsItems(state);
  const productFeatures = selectProductFeatures(state);

  if (items[`columnSelection${props.id}`]) {
    selectedColumnKeys = items[`columnSelection${props.id}`];
  } else if (props.defaultSelectedColumnKeys) {
    selectedColumnKeys = props.defaultSelectedColumnKeys;
  }
  if (props.id) {
    Logger.debug('AppLayout', {
      propsId: props.id,
      selectedColumnKeys
    });
  }

  return {
    selectedColumnKeys,
    user: state.auth.user,
    authProcessing: state.auth.processing,
    locationPath: state.router.location.pathname,
    productFeatures
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkUserLoggedIn: token => dispatch(checkUserIsLoggedIn(token)),
    logout: () => dispatch(logout())
  };
}

AppLayout.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppLayout));
