import actionTypes from './actionTypes';
import { API_NAMES, defaultSettings, trailerInfoPanel } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';
import { setIsMetric } from './auth';
import { fetchSettings } from './thunks/settings/fetch-settings';

const notificationsSettings = 'notification/settings';

function getSettingsRequest() {
  return {
    type: actionTypes.GET_SETTINGS_REQUEST
  };
}

function getSettingsSuccess(settings, appState) {
  return {
    type: actionTypes.GET_SETTINGS_SUCCESS,
    settings,
    appState
  };
}

function getSettingsFailure(settings, error) {
  return {
    type: actionTypes.GET_SETTINGS_FAILURE,
    settings,
    error
  };
}

function saveSettingRequest() {
  return {
    type: actionTypes.SAVE_SETTING_REQUEST
  };
}

function saveSettingSuccess(settings, appState) {
  return {
    type: actionTypes.SAVE_SETTING_SUCCESS,
    settings,
    appState
  };
}

function uploadSettingSuccess() {
  return {
    type: actionTypes.UPLOAD_SETTING_SUCCESS
  };
}

function uploadSettingFailure(error) {
  return {
    type: actionTypes.UPLOAD_SETTING_FAILURE,
    error
  };
}

function parseLiveMapTrailerComponents(trailerInfoComponentsSettings = {}) {
  trailerInfoPanel.livemap.forEach((componentName, key) => {
    if (!trailerInfoComponentsSettings[componentName]) {
      trailerInfoComponentsSettings[componentName] = {
        order: 1000 + key,
        visible: true,
        key: componentName
      };
    }
  });
  return trailerInfoComponentsSettings;
}

function getSettings() {
  return (dispatch, getState) => {
    dispatch(fetchSettings());
    dispatch(getSettingsRequest());

    return worker
      .Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: notificationsSettings })
      .then(res => {
        const settings = res ? { ...defaultSettings, ...res } : { ...defaultSettings };
        settings.trailerInfoComponents = parseLiveMapTrailerComponents(settings.trailerInfoComponents);
        const isMetric = res?.isMetric;
        if (isMetric !== undefined && isMetric !== null) {
          localStorage.setItem('chosenIsMetric', JSON.stringify(isMetric));
          dispatch(setIsMetric(isMetric));
        }
        delete settings.isMetric;
        dispatch(getSettingsSuccess(settings, getState()));
      })
      .catch(err => {
        console.error(err);
        const settings = { ...defaultSettings };
        settings.trailerInfoComponents = parseLiveMapTrailerComponents(settings.trailerInfoComponents);
        dispatch(getSettingsFailure(settings, err));
      });
  };
}

function saveSetting(settingName, value) {
  return (dispatch, getState) => {
    const settings = getState().settings.items;
    const isMetric = getState().auth.isMetric;
    const newSettings = { ...settings, isMetric };
    newSettings[settingName] = value;
    dispatch(saveSettingsObject(newSettings));
  };
}

function saveSettingGroup(value) {
  return (dispatch, getState) => {
    const settings = getState().settings.items;
    const isMetric = getState().auth.isMetric;
    const newSettings = { ...settings, ...value, isMetric };
    dispatch(saveSettingsObject(newSettings));
  };
}

function saveSettingsObject(value) {
  return (dispatch, getState) => {
    dispatch(saveSettingRequest());
    const settings = { ...defaultSettings, ...value };
    const { maxErrorMargin, windowSize } = settings;
    settings.trailerInfoComponents = parseLiveMapTrailerComponents(settings.trailerInfoComponents);
    dispatch(saveSettingSuccess(settings, getState()));

    worker.Api({
      method: 'post',
      api: API_NAMES.EBPMS,
      endpoint: notificationsSettings,
      body: { maxErrorMargin, windowSize }
    });

    worker
      .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: notificationsSettings, body: { ...settings } })
      .then(() => {
        dispatch(uploadSettingSuccess());
      })
      .catch(err => {
        console.error(err);
        dispatch(uploadSettingFailure(err));
      });
  };
}

export default {
  getSettings,
  saveSetting,
  saveSettingGroup
};
