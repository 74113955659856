import moment from 'moment';
import { Logger } from '../../app/helpers/logger.js';
import { selectProcessingAlerts } from '../../selectors/alerts.js';
import { getAllAlertsRequest, callAPI } from '../alerts';

export function getAllAlerts(dates) {
  return (dispatch, getState) => {
    const store = getState();
    let days = 2;
    try {
      if (dates) {
        days = dates[1].diff(dates[0], 'days');
      } else {
        dates = [moment(moment().subtract(days, 'd'), 'YYYY/MM/DD'), moment(moment(), 'YYYY/MM/DD')];
      }
      const from = moment(dates[1]); // copy!
      const processing = selectProcessingAlerts(store);
      if (!processing) {
        dispatch(getAllAlertsRequest());
        callAPI(dispatch, days, from);
      }
    } catch (e) {
      Logger.error(e);
    }
  };
}
