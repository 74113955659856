import React from 'react';
import { useIntl } from 'react-intl';
import * as strings from '../../../../../../../helpers/defaultStrings';

export const RecentSummaryInfoModal = ({ isMetric }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {formatMessage(isMetric ? strings.description.recentSummary : strings.description.recentSummaryMile)}
      <div style={{ paddingLeft: 16 }} className='large-margin-top'>
        <div>
          <span style={{ color: 'lightgrey' }}>&#9679;</span> {formatMessage(strings.short.unknownStatus)}
        </div>
        <div>
          <span className='safe'>&#9679;</span> {formatMessage(strings.short.noIssue)}
        </div>
        <div>
          <span className='info'>&#9679;</span> {formatMessage(strings.short.info)}
        </div>
        <div>
          <span className='warning'>&#9679;</span> {formatMessage(strings.short.warning)}
        </div>
        <div>
          <span className='critical'>&#9679;</span> {formatMessage(strings.short.critical)}
        </div>
      </div>
    </div>
  );
};
