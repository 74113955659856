import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OdoEventSummary from './OdoEventSummaryChart';

function getChartState(props) {
  return {
    events: props.events,
    start: props.start,
    end: props.end,
    domain: props.domain
  };
}

class OdoEventSummaryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = getChartState(nextProps);
    if (nextProps.events && chart) {
      chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state;
    if (chart) {
      chart.destroy();
    }

    const { width, maxDistanceString, minDistanceString } = this.props;
    const elWidth = width || 150;
    const elHeight = 30;

    const margin = {
      top: 0,
      right: 8,
      bottom: 0,
      left: 4
    };

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      maxDistanceString,
      minDistanceString
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new OdoEventSummary(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(getChartState(this.props));
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return <div ref='chart' />;
  }
}

export default OdoEventSummaryChartComponent;
