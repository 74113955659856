import React from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import LocaleString from '../Utils/LocaleString';

const DiscardedEvents = ({ stats }) => (
  <div>
    {stats && (
      <Tooltip
        overlayStyle={{ width: 400, maxWidth: 400 }}
        placement='bottom'
        title={
          <div>
            <div style={{ fontSize: 24, fontWeight: 600 }}>
              <LocaleString type='phrase' id='eventsDiscardedTotalVal' val={stats.total} />
            </div>
            {stats.dtcWithoutTimestamp > 0 && (
              <div>
                <LocaleString type='phrase' id='eventsDiscardedDTCNoTimestampVal' val={stats.dtcWithoutTimestamp} />
              </div>
            )}
            {stats.dtcInFuture > 0 && (
              <div>
                <LocaleString type='phrase' id='eventsDiscardedDTCInFutureVal' val={stats.dtcInFuture} />
              </div>
            )}
            {stats.dtcWithInaccurateMileage > 0 && (
              <div>
                <LocaleString
                  type='phrase'
                  id='eventsDiscardedDtcWithInaccurateMileageVal'
                  val={stats.dtcWithInaccurateMileage}
                />
              </div>
            )}
            {stats.recordedEventsWithoutTimestamp > 0 && (
              <div>
                <LocaleString
                  type='phrase'
                  id='eventsDiscardedNoTimestampVal'
                  val={stats.recordedEventsWithoutTimestamp}
                />
              </div>
            )}
            {stats.recordedEventsWithoutInFuture > 0 && (
              <div>
                <LocaleString type='phrase' id='eventsDiscardedInFutureVal' val={stats.recordedEventsWithoutInFuture} />
              </div>
            )}
            {stats.recordedEventsWithInaccurateMileage > 0 && (
              <div>
                <LocaleString
                  type='phrase'
                  id='eventsDiscardedRecordedEventsWithInaccurateMileageVal'
                  val={stats.recordedEventsWithInaccurateMileage}
                />
              </div>
            )}
            {stats.tripsWithoutTimestamp > 0 && (
              <div>
                <LocaleString type='phrase' id='eventsDiscardedTripsNoTimestampVal' val={stats.tripsWithoutTimestamp} />
              </div>
            )}
            {stats.tripsInFuture > 0 && (
              <div>
                <LocaleString type='phrase' id='eventsDiscardedTripsInFutureVal' val={stats.tripsInFuture} />
              </div>
            )}
            {stats.tripsWithInaccurateMileage > 0 && (
              <div>
                <LocaleString
                  type='phrase'
                  id='eventsDiscardedTripsWithInaccurateMileageVal'
                  val={stats.tripsWithInaccurateMileage}
                />
              </div>
            )}
          </div>
        }
      >
        <WarningTwoTone twoToneColor='orange' className='warning-icon' />
      </Tooltip>
    )}
  </div>
);

export default DiscardedEvents;
