import { ENV_CONFIG } from './env-configs';

const LOGGER_TYPES = {
  LOG: 'LOG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  DEBUG: 'DEBUG'
};

export const concreteLogger = ({ type, args }) => {
  const isDev = ENV_CONFIG.NODE_ENV === 'development';
  const isIntegration = ENV_CONFIG.REACT_APP_ENV === 'integration';

  // only errors in above integration
  if (!(isDev || isIntegration) && type !== 'error') {
    return;
  }

  // in dev or integration we can disable the debugger
  if (window.debugDisabled && type === LOGGER_TYPES.DEBUG) {
    return;
  }

  const date = new Date().toISOString();
  console[type.toLowerCase()](`${date} ${type}`, ...args);
};

export const Logger = {
  log: (...args) => concreteLogger({ type: LOGGER_TYPES.LOG, args }),
  error: (...args) => concreteLogger({ type: LOGGER_TYPES.ERROR, args }),
  info: (...args) => concreteLogger({ type: LOGGER_TYPES.INFO, args }),
  warning: (...args) => concreteLogger({ type: LOGGER_TYPES.WARN, args }),
  debug: (...args) => concreteLogger({ type: LOGGER_TYPES.DEBUG, args })
};
