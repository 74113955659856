import actionTypes from '../actions/actionTypes';

export const initialOdrFilesState = {
  items: null,
  error: null,
  processing: false,
  assetId: null,
  loading: []
};

export default function userReducer(state = initialOdrFilesState, action) {
  switch (action.type) {
    case actionTypes.GET_ODR_FILE_LIST_REQUEST:
      return {
        ...state,
        items: null,
        error: null,
        processing: true,
        assetId: action.assetId
      };
    case actionTypes.GET_ODR_FILE_LIST_SUCCESS:
      if (state.assetId === action.assetId) {
        return {
          ...state,
          items: action.odrFiles,
          processing: false
        };
      } else {
        return state;
      }
    case actionTypes.GET_ODR_FILE_LIST_FAILURE:
      if (state.assetId === action.assetId) {
        return {
          ...state,
          items: null,
          error: action.error,
          processing: false
        };
      } else {
        return state;
      }
    case actionTypes.DOWNLOAD_ODR_FILE_REQUEST:
      if (state.assetId === action.assetId) {
        return {
          ...state,
          loading: [...state.loading, action.fileId]
        };
      } else {
        return state;
      }
    case actionTypes.DOWNLOAD_ODR_FILE_SUCCESS:
      if (state.assetId === action.assetId) {
        return {
          ...state,
          loading: state.loading.filter(item => item !== action.fileId)
        };
      } else {
        return state;
      }
    case actionTypes.DOWNLOAD_ODR_FILE_FAILURE:
      if (state.assetId === action.assetId) {
        return {
          ...state,
          loading: state.loading.filter(item => item !== action.fileId),
          error: action.error
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}
