import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_NAMES, TRAILERS_THUNK_TYPE } from '../../helpers/constants';
import { selectLoadingAcrossRequests } from '../../selectors/trailers';
import { trailersSlice } from '../../slices/trailers';
import { worker } from '../../app/web-workers/aws-web-worker';
import { getTrailersNewVersion } from '../../app/feature-flags/get-trailers-new-version';
import { getTrailersByCustomerSize } from '../../app/feature-flags/trailers-by-customer-size';
import { waitForFeatureFlags } from './pubsub/helper';
import { Logger } from '../../app/helpers/logger';

export const calcNextPage = (batchLimit, fullCount, currentPage) => {
  if (batchLimit < 1 || !fullCount) {
    return 0;
  }
  let numberOfPages;
  if (batchLimit > fullCount) {
    numberOfPages = 1;
  } else {
    numberOfPages = Math.ceil(fullCount / batchLimit);
  }
  return numberOfPages > currentPage ? currentPage + 1 : 0;
};

// +info https://redux-toolkit.js.org/api/createAsyncThunk#type
export const trailersThunkNew = async (nextPage = 1, thunkApi) => {
  const url = `trailersByCustomer?view=TrailerFitView`;
  const { dispatch, getState } = thunkApi;
  const DEFAULT_BATCH_SIZE = 800;
  const batchLimit = (getTrailersByCustomerSize(getState()) ?? DEFAULT_BATCH_SIZE).toString();
  const data = await worker.Api({
    method: 'get',
    api: API_NAMES.ODR_LIVE_MAP,
    endpoint: url,
    headers: { limit: batchLimit, page: nextPage.toString() }
  });
  const loadingAcrossRequests = selectLoadingAcrossRequests(getState());
  const localNextPage = calcNextPage(batchLimit, data?.metadata?.fullCount, nextPage);
  if (localNextPage && !loadingAcrossRequests) {
    dispatch(trailersSlice.actions.loadingAcrossRequests(true));
  } else if (!localNextPage && loadingAcrossRequests) {
    dispatch(trailersSlice.actions.loadingAcrossRequests(false));
  }

  if (localNextPage) {
    // set timeout is required to break the async flow of the thunk
    // and ensure we get data for all the requests feed into the reducer
    setTimeout(() => dispatch(fetchTrailersNew(localNextPage)), 0);
  }

  // return values will update the reducer state accordingly on action creator '*/fulfilled '
  return data?.trailers ?? [];
};

export const trailersThunkOld = async (nextPage, thunkApi) => {
  const url = `trailersByCustomer${nextPage ? `?pageKey=${nextPage}` : ''}`;
  const data = await worker.Api({ method: 'get', api: API_NAMES.ODR_LIVE_MAP, endpoint: url });
  const localNextPage = data?.metadata?.nextPage;
  const { dispatch, getState } = thunkApi;
  const loadingAcrossRequests = selectLoadingAcrossRequests(getState());

  if (localNextPage && !loadingAcrossRequests) {
    dispatch(trailersSlice.actions.loadingAcrossRequests(true));
  } else if (!localNextPage && loadingAcrossRequests) {
    dispatch(trailersSlice.actions.loadingAcrossRequests(false));
  }

  if (localNextPage) {
    // set timeout is required to break the async flow of the thunk
    // and ensure we get data for all the requests feed into the reducer
    setTimeout(() => dispatch(fetchTrailersOld(localNextPage)), 0);
  }

  // return values will update the reducer state accordingly on action creator '*/fulfilled '
  return data?.trailers ?? [];
};

export const fetchTrailersNew = createAsyncThunk(TRAILERS_THUNK_TYPE, trailersThunkNew);
export const fetchTrailersOld = createAsyncThunk(TRAILERS_THUNK_TYPE, trailersThunkOld);

export const fetchTrailers = nextPage => {
  return async (dispatch, getState) => {
    try {
      await waitForFeatureFlags(getState);
    } catch (err) {
      Logger.error('waitForFeatureFlags', err);
      // report the error and continue business as usual, assuming the feature flag as false.
      // but not a blocker for the app to continue.
    }

    const useNewApi = getTrailersNewVersion(getState());
    if (useNewApi) {
      dispatch(fetchTrailersNew(nextPage));
    } else {
      dispatch(fetchTrailersOld(nextPage));
    }
  };
};
