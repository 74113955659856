import React, { useEffect, useState } from 'react';
import { OrderedListOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Row } from 'antd';
import { injectIntl } from 'react-intl';
import LocaleString from '../Utils/LocaleString';
import actions from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';

import './TrailerInfoPanelModalSelector.scss';
import HealthButton from '../Utils/HealthButton';
import IdCardButton from '../Utils/IdCardButton';
import OdyTextButton from '../../ReusableComponents/Buttons/OdyTextButton';
import IconSections from '../Icons/IconSections';
import { selectProductFeatures, selectTrailerInfoComponents } from '../../selectors';

const TrailerInfoPanelModalSelector = ({
  showLivemapHealthButtons = true,
  showSettingsButton = true,
  disableTrailerIdCardButton = false,
  overWriteShowSettings,
  onVisibilityChange
}) => {
  const dispatch = useDispatch();
  const trailer = useSelector(store => store.appState.selectedTnTTrailer);
  const componentSettings = useSelector(selectTrailerInfoComponents);
  const productFeatures = useSelector(selectProductFeatures);

  const [showModal, setShowModal] = useState(false);
  const [componentsToShow, setComponentsToShow] = useState([]);
  const [trailerInfoComponents, setTrailerInfoComponents] = useState([]);
  const [changedBoxes, setChangedBoxes] = useState([]);

  const handleOk = () => {
    let newComponentSettings = {};
    const newOverWriteShowSettings = Object.assign({}, overWriteShowSettings);
    const overWriteKeys = Object.keys(newOverWriteShowSettings);
    trailerInfoComponents.forEach((component, key) => {
      const componentKey = component.key;
      const isVisible = componentsToShow.includes(componentKey);

      newComponentSettings[componentKey] = component;
      newComponentSettings[componentKey].order = key;
      newComponentSettings[componentKey].visible = isVisible;

      if (changedBoxes.includes(componentKey) && overWriteKeys.includes(componentKey)) {
        newOverWriteShowSettings[componentKey] = !isVisible;
      }
    });

    onVisibilityChange(newOverWriteShowSettings);
    setShowModal(false);

    dispatch(actions.settings.saveSetting(`trailerInfoComponents`, newComponentSettings));
  };

  const handleOpen = () => setShowModal(true);
  const handleClose = () => {
    setChangedBoxes([]);
    setShowModal(false);
  };

  const onCheckBoxChange = (checked, key) => {
    let newComponentsToShow = [...componentsToShow];
    let newChangedBoxes = [...changedBoxes];
    if (!checked) {
      newComponentsToShow = newComponentsToShow.filter(item => item !== key);
    } else {
      newComponentsToShow.push(key);
    }

    newChangedBoxes.push(key);

    setChangedBoxes(newChangedBoxes);
    setComponentsToShow(newComponentsToShow);
  };

  const onSortEnd = ({ oldIndex, newIndex }) =>
    setTrailerInfoComponents(arrayMove(trailerInfoComponents, oldIndex, newIndex));

  useEffect(() => {
    if (!productFeatures.livemapFCF) {
      delete componentSettings['historyFCF'];
    }

    if (!productFeatures.showLightMonitoring) {
      delete componentSettings['lightMonitoring'];
    }

    const componentsToShow = Object.entries(componentSettings)
      .map(([, component]) => component)
      .sort((cA, cB) => cA.order - cB.order);

    const visibleKeys = componentsToShow.reduce(
      (accumulator, currentValue) => (currentValue.visible ? [...accumulator, currentValue.key] : accumulator),
      []
    );
    setTrailerInfoComponents(componentsToShow);
    setComponentsToShow(visibleKeys.filter(item => !overWriteShowSettings[item.key]));
  }, [componentSettings, productFeatures, overWriteShowSettings]);

  return (
    <>
      {(showLivemapHealthButtons || showSettingsButton) && (
        <>
          <div style={{ display: 'flex' }}>
            {showSettingsButton && (
              <OdyTextButton icon={<IconSections />} onClick={handleOpen}>
                <LocaleString type='short' id='sections' />
              </OdyTextButton>
            )}
            {showLivemapHealthButtons && (
              <>
                <HealthButton blue key='1' selectedTrailer={trailer} />
                <IdCardButton blue key='2' selectedTrailer={trailer} disabled={disableTrailerIdCardButton} />
              </>
            )}
          </div>

          {showModal && (
            <Modal
              title={<LocaleString type='short' id='selectionSettings' />}
              visible={showModal}
              onOk={handleOk}
              onCancel={handleClose}
              okText={<LocaleString type='button' id='ok' />}
              className='column-selection'
              cancelText={<LocaleString type='button' id='cancel' />}
            >
              <SortableList
                helperClass='sortable-helper'
                items={trailerInfoComponents}
                onSortEnd={onSortEnd}
                lockAxis='y'
                overWriteShowSettings={overWriteShowSettings}
                onCheckBoxChange={onCheckBoxChange}
                componentsToShow={componentsToShow}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

const DragHandle = sortableHandle(() => (
  <span>
    <OrderedListOutlined />
  </span>
));

const SortableItem = SortableElement(({ value, onCheckBoxChange }) => {
  return (
    <Row className={'sortable-list__row'} type='flex' justify='space-between'>
      <span className={'sortable-list__col--checkbox sortable-list__col--checkbox--item'}>
        <Checkbox
          defaultChecked={value.defaultChecked}
          onChange={e => onCheckBoxChange(e?.target?.checked, value.key)}
        />
      </span>
      <span className={'sortable-list__col--name sortable-list__col--name--item'}>
        <LocaleString type='sections' id={value.key} />
      </span>
      <span className={'sortable-list__col--move sortable-list__col--move--item'}>
        <DragHandle />
      </span>
    </Row>
  );
});

const SortableList = SortableContainer(({ items, overWriteShowSettings, onCheckBoxChange, componentsToShow }) => (
  <ul className={'sortable-list'}>
    <Row className={'sortable-list__row'} type='flex' justify='space-between'>
      <span className={'sortable-list__col--checkbox'}>
        <LocaleString type='short' id='show' />
      </span>
      <span className={'sortable-list__col--name'}>
        <LocaleString type='short' id='sectionName' />
      </span>
      <span className={'sortable-list__col--move'}>
        <LocaleString type='short' id='move' />
      </span>
    </Row>
    {items.map((value, index) => (
      <SortableItem
        key={value.key}
        index={index}
        value={{ ...value, defaultChecked: componentsToShow.includes(value.key) && !overWriteShowSettings[value.key] }}
        onCheckBoxChange={onCheckBoxChange}
      />
    ))}
  </ul>
));

export default injectIntl(TrailerInfoPanelModalSelector);
