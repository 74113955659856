import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';

class ComponentToggleButton extends React.Component {
  open = () => {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }
  };

  close = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <Button
        style={{ position: 'absolute', right: '8px', top: '8px' }}
        shape='circle'
        icon={<LegacyIcon type={isOpen ? 'right' : 'left'} />}
        onClick={isOpen ? this.close : this.open}
      />
    );
  }
}

export default ComponentToggleButton;
