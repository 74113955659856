import { defineMessages } from 'react-intl';

export const columns = defineMessages({
  searchResults: {
    id: 'columns.searchResults',
    defaultMessage: 'Search results',
    description: 'data table column header'
  },
  generalInfo: {
    id: 'columns.generalInfo',
    defaultMessage: 'General Trailer Information',
    description: ''
  },
  generalMonitoring: {
    id: 'columns.generalMonitoring',
    defaultMessage: 'General Monitoring',
    description: 'Heading for a set of data that allows general monitoring of the fleet'
  },
  healthMonitoring: {
    id: 'columns.healthMonitoring',
    defaultMessage: 'Health Monitoring',
    description: 'Heading for a set of data that allows monitoring of the health of the fleet'
  },
  trailerSystems: {
    id: 'columns.trailerSystems',
    defaultMessage: 'Trailer Systems',
    description: 'Heading for a set of data that refers to internal systems'
  },
  map: {
    id: 'columns.map',
    defaultMessage: 'Map',
    description: 'data table column header'
  },
  healthScore: {
    id: 'columns.healthScore',
    defaultMessage: 'Health score',
    description: 'data table column header'
  },
  healthStatus: {
    id: 'columns.healthStatus',
    defaultMessage: 'Health status',
    description: 'data table column header'
  },
  batteryLevel: {
    id: 'columns.batteryLevel',
    defaultMessage: 'Battery level',
    description: 'data table column header'
  },
  externalCode: {
    id: 'columns.externalCode',
    defaultMessage: 'External code',
    description: 'data table column header'
  },
  ebsBrand: {
    id: 'columns.ebsBrand',
    defaultMessage: 'EBS brand',
    description: 'data table column header. EBS Electronic Braking System.'
  },
  absBrand: {
    id: 'columns.absBrand',
    defaultMessage: 'ABS brand',
    description: 'data label; ABS: Anti-lock Breaking System'
  },
  trailerMake: {
    id: 'columns.trailerMake',
    defaultMessage: 'Trailer make',
    description: 'data table column header'
  },
  trailerModel: {
    id: 'columns.trailerModel',
    defaultMessage: 'Trailer model',
    description: 'data table column header'
  },
  last2h: {
    id: 'columns.last2h',
    defaultMessage: 'Speed last 2h',
    description: 'data table column header'
  },
  since: {
    id: 'columns.since',
    defaultMessage: 'Time/Distance since last ODR',
    description: 'data table column header'
  },
  durationScore: {
    id: 'columns.durationScore',
    defaultMessage: 'Duration score',
    description: 'data table column header'
  },
  frequencyScore: {
    id: 'columns.frequencyScore',
    defaultMessage: 'Frequency score',
    description: 'data table column header'
  },
  recencyScore: {
    id: 'columns.recencyScore',
    defaultMessage: 'Recency score',
    description: 'data table column header'
  },
  lightMonitoring: {
    id: 'columns.lightMonitoring',
    defaultMessage: 'Light Monitoring',
    description: 'data table column header'
  }
});
