import { createSelector } from '@reduxjs/toolkit';

const selectDevices = state => state?.devices;

export const selectDeviceIds = createSelector(selectDevices, state => state?.deviceAssetIds ?? []);
export const selectDevicesDevices = createSelector(selectDevices, state => state?.devices ?? []);
export const selectUnfilteredDevices = createSelector(selectDevices, state => state?.unfilteredDevices);
export const selectMapRoutes = createSelector(selectDevices, state => state?.mapRoutes);

export const selectHistory = createSelector(selectDevices, state => state?.history);
export const selectRetrievedHistory = createSelector(selectHistory, state => state?.retrievedHistory);
export const selectProcessingApi = createSelector(selectDevices, state => state?.processingApi);
