import React, { useState } from 'react';
import './GroteGuardianLightStatus.scss';
import { Tooltip } from 'antd';
import { formatGroteToVisualisationProps, formatLampStatusString } from '../../helpers/grote';
import { LampPosition, MouseEvent } from '../../helpers/grote';

const tooltipBackgroundColor = '#00000080';

const LampStatusTooltip = ({ statuses = [], formatMessage }) => (
  <React.Fragment>
    {statuses.map((lamp, i) => (
      <div key={`lamp-${i}`}>{formatLampStatusString(lamp.colour, lamp.code, formatMessage)}</div>
    ))}
  </React.Fragment>
);

const Lamp = ({ position, statuses, formatMessage, colour, cx, cy, r, onMouseEvent, hoveredLamps }) => (
  <Tooltip
    color={tooltipBackgroundColor}
    title={<LampStatusTooltip statuses={statuses} formatMessage={formatMessage} />}
  >
    <circle
      className={`${colour} ${hoveredLamps[position] ? 'lamp-hover' : ''}`}
      cx={cx}
      cy={cy}
      r={r}
      onMouseEnter={() => onMouseEvent(MouseEvent.ENTER, position)}
      onMouseLeave={() => onMouseEvent(MouseEvent.LEAVE, position)}
    />
  </Tooltip>
);

const GroteGuardianLightVisualisation = ({ left, right, topMiddle, topLeft, topRight, formatMessage }) => {
  const [hoveredLamps, setHoveredLamps] = useState({
    [LampPosition.LEFT]: false,
    [LampPosition.RIGHT]: false,
    [LampPosition.TOP_LEFT]: false,
    [LampPosition.TOP_MIDDLE]: false,
    [LampPosition.TOP_RIGHT]: false
  });

  const onMouseHoverEvent = (mouseEventInput, position) => {
    const valueToSet = mouseEventInput === MouseEvent.ENTER;

    if (position === LampPosition.TOP_RIGHT || position === LampPosition.TOP_LEFT) {
      setHoveredLamps({
        ...hoveredLamps,
        [LampPosition.TOP_LEFT]: valueToSet,
        [LampPosition.TOP_RIGHT]: valueToSet
      });
    } else if (position === LampPosition.RIGHT || position === LampPosition.LEFT) {
      setHoveredLamps({
        ...hoveredLamps,
        [LampPosition.LEFT]: valueToSet,
        [LampPosition.RIGHT]: valueToSet
      });
    } else {
      setHoveredLamps({
        ...hoveredLamps,
        [position]: valueToSet
      });
    }
  };

  return (
    <svg
      className={'grote-guardian-visualisation-container'}
      width='164'
      height='175'
      viewBox='0 0 164 175'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='81.594'
        cy='171.279'
        rx='61.7502'
        ry='3.36645'
        fill='url(#paint0_radial_2712_151482)'
        fillOpacity='0.6'
      />
      <rect x='35.4629' y='135.905' width='9.47908' height='35.3743' rx='1.28634' fill='black' />
      <rect x='45.4329' y='135.905' width='9.47908' height='35.3743' rx='1.28634' fill='black' />
      <rect x='108.795' y='135.905' width='9.47908' height='35.3743' rx='1.28634' fill='black' />
      <rect x='118.765' y='135.905' width='9.47908' height='35.3743' rx='1.28634' fill='black' />
      <path
        d='M34.6953 134.478C34.6953 134.533 34.7401 134.578 34.7953 134.578H128.914C128.969 134.578 129.014 134.533 129.014 134.478V37.2656H34.6953V134.478Z'
        fill='white'
        stroke='#808080'
        strokeWidth='2'
      />
      <rect x='34.9953' y='135.128' width='93.7182' height='17.025' fill='white' stroke='#j' strokeWidth='0.6' />
      <path
        d='M53.3007 136.816C53.4885 136.816 53.6407 136.968 53.6407 137.156L53.6407 142.034C53.6407 142.224 53.487 142.377 53.2975 142.377L38.6841 142.377C38.4946 142.377 38.3409 142.224 38.3409 142.034L38.3409 137.156C38.3409 136.968 38.4932 136.816 38.6809 136.816L53.3007 136.816Z'
        fill='#D3D3D3'
        stroke='#D3D3D3'
        strokeWidth='0.6'
      />
      <path
        d='M125.027 136.816C125.215 136.816 125.367 136.968 125.367 137.156L125.367 142.034C125.367 142.224 125.214 142.377 125.024 142.377L110.411 142.377C110.221 142.377 110.068 142.224 110.068 142.034L110.068 137.156C110.068 136.968 110.22 136.816 110.408 136.816L125.027 136.816Z'
        fill='#D3D3D3'
        stroke='#D3D3D3'
        strokeWidth='0.6'
      />
      <mask
        id='mask0_2712_151482'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='37'
        y='42'
        width='90'
        height='88'
      >
        <path d='M37.6191 129.641H126.09V42.3281H37.6191V129.641Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2712_151482)'>
        <line x1='-95.3568' y1='169.941' x2='39.1432' y2='35.9407' stroke='#808080' />
        <line x1='-75.3568' y1='169.941' x2='59.1432' y2='35.9407' stroke='#808080' />
        <line x1='-55.3568' y1='169.941' x2='79.1432' y2='35.9407' stroke='#808080' />
        <line x1='-35.3568' y1='169.941' x2='99.1432' y2='35.9407' stroke='#808080' />
        <line x1='-15.3568' y1='169.941' x2='119.143' y2='35.9407' stroke='#808080' />
        <line x1='4.6432' y1='169.941' x2='139.143' y2='35.9407' stroke='#808080' />
        <line x1='24.6432' y1='169.941' x2='159.143' y2='35.9407' stroke='#808080' />
        <line x1='44.6432' y1='169.941' x2='179.143' y2='35.9407' stroke='#808080' />
        <line x1='64.6432' y1='169.941' x2='199.143' y2='35.9407' stroke='#808080' />
        <line x1='84.6432' y1='169.941' x2='219.143' y2='35.9407' stroke='#808080' />
        <line x1='104.643' y1='169.941' x2='239.143' y2='35.9407' stroke='#808080' />
        <line x1='124.643' y1='169.941' x2='259.143' y2='35.9407' stroke='#808080' />
      </g>
      <path
        d='M55.0734 135.128H108.636V147.228C108.636 147.394 108.501 147.528 108.336 147.528H55.3734C55.2078 147.528 55.0734 147.394 55.0734 147.228V135.128Z'
        stroke='#808080'
        strokeWidth='0.6'
      />
      <rect x='37.0051' y='135.128' width='89.6963' height='13.45' stroke='#808080' strokeWidth='0.6' />
      <rect x='55.1484' y='151.859' width='53.4121' height='5.59375' fill='' />
      <path
        d='M98.5455 136.816C98.7333 136.816 98.8855 136.968 98.8855 137.156L98.8855 143.159C98.8855 143.349 98.7319 143.502 98.5424 143.502L65.1667 143.502C64.9772 143.502 64.8235 143.349 64.8235 143.159L64.8235 137.156C64.8235 136.968 64.9758 136.816 65.1635 136.816L98.5455 136.816Z'
        fill='white'
        stroke='#808080'
        strokeWidth='0.6'
      />
      <rect x='66.8234' y='139.053' width='30.0621' height='2.2125' fill='#808080' stroke='#808080' strokeWidth='0.6' />
      <line x1='42.3848' y1='150.278' x2='50.418' y2='150.278' stroke='#808080' strokeWidth='0.8' />
      <line x1='60.1113' y1='150.278' x2='68.1445' y2='150.278' stroke='#808080' strokeWidth='0.8' />
      <line x1='77.8379' y1='150.278' x2='85.8711' y2='150.278' stroke='#808080' strokeWidth='0.8' />
      <line x1='95.5645' y1='150.278' x2='103.598' y2='150.278' stroke='#808080' strokeWidth='0.8' />
      <line x1='113.291' y1='150.278' x2='121.324' y2='150.278' stroke='#808080' strokeWidth='0.8' />
      <rect
        x='75.6516'
        y='121.034'
        width='12.4059'
        height='2.2125'
        rx='0.3'
        fill='white'
        stroke='#808080'
        strokeWidth='0.6'
      />
      <line x1='77.8723' y1='120.766' x2='77.8723' y2='123.547' stroke='#808080' strokeWidth='0.6' />
      <line x1='85.9387' y1='120.766' x2='85.9387' y2='123.547' stroke='#808080' strokeWidth='0.6' />
      <line x1='49.3535' y1='130.253' x2='58.0741' y2='130.253' stroke='white' strokeWidth='1.4' />
      <line x1='63.4243' y1='130.253' x2='72.1449' y2='130.253' stroke='white' strokeWidth='1.4' />
      <line x1='77.4934' y1='130.253' x2='86.214' y2='130.253' stroke='white' strokeWidth='1.4' />
      <line x1='91.5642' y1='130.253' x2='100.285' y2='130.253' stroke='white' strokeWidth='1.4' />
      <line x1='105.633' y1='130.253' x2='114.354' y2='130.253' stroke='white' strokeWidth='1.4' />
      <path
        d='M55.4949 152.159L55.4949 158.722C55.4949 158.911 55.3413 159.065 55.1518 159.065L35.3386 159.065C35.1491 159.065 34.9954 158.911 34.9954 158.722L34.9954 152.159L55.4949 152.159Z'
        fill='#808080'
        stroke='#808080'
        strokeWidth='0.6'
      />
      <path
        d='M128.714 152.159L128.714 158.722C128.714 158.911 128.56 159.065 128.371 159.065L108.557 159.065C108.368 159.065 108.214 158.911 108.214 158.722L108.214 152.159L128.714 152.159Z'
        fill='#808080'
        stroke='#808080'
        strokeWidth='0.6'
      />
      <rect x='38.125' y='42.625' width='87.5625' height='87' stroke='#808080' />
      <path
        className={hoveredLamps[LampPosition.TOP_MIDDLE] && 'lamp-trailer-light-hover'}
        d='M90.36 36C90.7135 36 91 36.2865 91 36.64L91 42.3568C91 42.712 90.712 43 90.3568 43L72.6432 43C72.288 43 72 42.712 72 42.3568L72 36.64C72 36.2865 72.2865 36 72.64 36L90.36 36Z'
        fill='#D3D3D3'
      />
      <path
        className={hoveredLamps[LampPosition.TOP_LEFT] && 'lamp-trailer-light-hover'}
        d='M46.0495 37C46.4029 37 46.6895 37.2865 46.6895 37.64L46.6895 42.3568C46.6895 42.712 46.4015 43 46.0463 43L35.3326 43C34.9774 43 34.6895 42.712 34.6895 42.3568L34.6895 37.64C34.6895 37.2865 34.976 37 35.3295 37L46.0495 37Z'
        fill='#D3D3D3'
      />
      <path
        className={hoveredLamps[LampPosition.TOP_RIGHT] && 'lamp-trailer-light-hover'}
        d='M128.381 37C128.735 37 129.021 37.2865 129.021 37.64L129.021 42.3568C129.021 42.712 128.734 43 128.378 43L117.665 43C117.309 43 117.021 42.712 117.021 42.3568L117.021 37.64C117.021 37.2865 117.308 37 117.661 37L128.381 37Z'
        fill='#D3D3D3'
      />
      <path
        d='M40.6664 23.1206L40.6664 22.7706L39.9664 22.7706L39.9664 23.1206L40.6664 23.1206ZM39.9664 40.0643C39.9664 40.2576 40.1231 40.4143 40.3164 40.4143C40.5097 40.4143 40.6664 40.2576 40.6664 40.0643L39.9664 40.0643ZM39.9664 23.1206L39.9664 40.0643L40.6664 40.0643L40.6664 23.1206L39.9664 23.1206Z'
        fill='black'
      />
      <Lamp
        position={LampPosition.TOP_LEFT}
        statuses={topLeft.tooltipValues}
        formatMessage={formatMessage}
        colour={topLeft.colour}
        cx={40.3164}
        cy={11.877}
        r={11.15}
        onMouseEvent={onMouseHoverEvent}
        hoveredLamps={hoveredLamps}
      />
      <path
        d='M82.7191 22.9546L82.7191 22.6046L82.0191 22.6046L82.0191 22.9546L82.7191 22.9546ZM82.0191 39.8983C82.0191 40.0916 82.1758 40.2483 82.3691 40.2483C82.5624 40.2483 82.7191 40.0916 82.7191 39.8983L82.0191 39.8983ZM82.0191 22.9546L82.0191 39.8983L82.7191 39.8983L82.7191 22.9546L82.0191 22.9546Z'
        fill='black'
      />
      <Lamp
        position={LampPosition.TOP_MIDDLE}
        statuses={topMiddle.tooltipValues}
        formatMessage={formatMessage}
        colour={topMiddle.colour}
        cx={82.3691}
        cy={11.7109}
        r={11.15}
        onMouseEvent={onMouseHoverEvent}
        hoveredLamps={hoveredLamps}
      />
      <path
        d='M123.643 23.1206L123.643 22.7706L122.943 22.7706L122.943 23.1206L123.643 23.1206ZM122.943 40.0643C122.943 40.2576 123.1 40.4143 123.293 40.4143C123.486 40.4143 123.643 40.2576 123.643 40.0643L122.943 40.0643ZM122.943 23.1206L122.943 40.0643L123.643 40.0643L123.643 23.1206L122.943 23.1206Z'
        fill='black'
      />
      <Lamp
        position={LampPosition.TOP_RIGHT}
        statuses={topRight.tooltipValues}
        formatMessage={formatMessage}
        colour={topRight.colour}
        cx={123.293}
        cy={11.877}
        r={11.15}
        onMouseEvent={onMouseHoverEvent}
        hoveredLamps={hoveredLamps}
      />
      <path
        className={hoveredLamps[LampPosition.LEFT] && 'lamp-trailer-light-hover'}
        d='M45.5358 116.891C45.8892 116.891 46.1758 117.177 46.1758 117.531L46.1758 133.935C46.1758 134.29 45.8878 134.578 45.5326 134.578L35.3327 134.578C34.9775 134.578 34.6896 134.29 34.6896 133.935L34.6896 117.531C34.6896 117.177 34.9761 116.891 35.3296 116.891L45.5358 116.891Z'
        fill='#D3D3D3'
      />
      <path
        className={hoveredLamps[LampPosition.RIGHT] && 'lamp-trailer-light-hover'}
        d='M128.381 116.891C128.735 116.891 129.021 117.177 129.021 117.531L129.021 133.935C129.021 134.29 128.734 134.578 128.378 134.578L118.178 134.578C117.823 134.578 117.535 134.29 117.535 133.935L117.535 117.531C117.535 117.177 117.822 116.891 118.175 116.891L128.381 116.891Z'
        fill='#D3D3D3'
      />
      <path
        d='M23 126.404L22.65 126.404L22.65 127.104L23 127.104L23 126.404ZM39.9437 127.104C40.137 127.104 40.2937 126.947 40.2937 126.754C40.2937 126.561 40.137 126.404 39.9437 126.404V127.104ZM23 127.104L39.9437 127.104V126.404L23 126.404L23 127.104Z'
        fill='black'
      />
      <Lamp
        position={LampPosition.LEFT}
        statuses={left.tooltipValues}
        formatMessage={formatMessage}
        colour={left.colour}
        cx={11.5}
        cy={126.754}
        r={11.15}
        onMouseEvent={onMouseHoverEvent}
        hoveredLamps={hoveredLamps}
      />
      <path
        d='M123.292 126.404C123.099 126.404 122.942 126.561 122.942 126.754C122.942 126.947 123.099 127.104 123.292 127.104L123.292 126.404ZM140.236 127.104L140.586 127.104V126.404L140.236 126.404V127.104ZM123.292 127.104L140.236 127.104V126.404L123.292 126.404L123.292 127.104Z'
        fill='black'
      />
      <Lamp
        position={LampPosition.RIGHT}
        statuses={right.tooltipValues}
        formatMessage={formatMessage}
        colour={right.colour}
        cx={'151.735'}
        cy={'126.754'}
        r={'11.15'}
        onMouseEvent={onMouseHoverEvent}
        hoveredLamps={hoveredLamps}
      />
      <defs>
        <radialGradient
          id='paint0_radial_2712_151482'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(81.594 171.279) rotate(90) scale(3.36645 61.7502)'
        >
          <stop stopColor='#C4C4C4' />
          <stop offset='1' stopColor='#C4C4C4' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
};

const GroteGuardianLightStatus = ({ grote, formatMessage }) => {
  return (
    <GroteGuardianLightVisualisation
      {...formatGroteToVisualisationProps(grote)}
      grote={grote}
      formatMessage={formatMessage}
    />
  );
};

export default GroteGuardianLightStatus;
