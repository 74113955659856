import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { useIntl } from 'react-intl';

import { logout as myTransicsLogout } from '../../actions/thunks/auth/logout';
import UserDropdown from './UserDropdown';
import './UserMenu.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { isScalar } from '../../helpers/functions';

const UserMenu = ({ avatarSrc }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const formatMessage = useIntl().formatMessage;

  const user = useSelector(store => store.auth.user);
  const [open, setOpen] = useState(false);

  const toggleMenuOpen = () => {
    setOpen(!open);
  };

  const onLogout = () => {
    setOpen(false);
    if (isScalar()) {
      return logout({ returnTo: window.location.origin });
    }
    return dispatch(myTransicsLogout());
  };

  if (!user) {
    return null;
  }

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      content={
        <UserDropdown
          user={user}
          logOut={onLogout}
          toggleMenuOpen={toggleMenuOpen}
          formatMessage={formatMessage}
          avatarSrc={avatarSrc}
        />
      }
      visible={open}
      onVisibleChange={toggleMenuOpen}
      overlayClassName='user-menu'
    >
      <div className='header-menu-right__icon-wrap'>
        <Avatar className='user-menu__avatar' src={avatarSrc} />
      </div>
    </Popover>
  );
};

export default UserMenu;
