import React, { useEffect, useState, useRef, Fragment } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import OdrBrakingChart from '../charts/OdrBrakingChart/OdrBrakingChart';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { getIsTrailerTEBSF, getValuePerDistance } from '../../helpers/functions';
import { convertInt, getConversionString, getUnit, unitType } from '../../helpers/unitConverter';

const useBbox = () => {
  const ref = useRef();
  const [bbox, setBbox] = useState({});

  const set = () => setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [bbox, ref];
};

const OdrBrakingList = ({ trailer, selectTrailer, isTempAccess, intl: { formatMessage }, getOdrBrakingAvg }) => {
  const [bbox, ref] = useBbox();
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const height = windowHeight - ((bbox.top || 0) + 18);
  const width = Math.round(windowWidth / 2);
  const [trailerId, setTrailerId] = useState(false);
  const [odrBrakingData, setOdrBrakingData] = useState([]);
  const [assetHighlighted, setAssetHighlighted] = useState(null);
  const brakingEventsGroupData = useSelector(store => store.trailersOdrBraking.items);
  const brakingEventsGroupAvg = useSelector(store => store.trailersOdrBraking.averages);
  const processing = useSelector(store => store.trailersOdrBraking.processing);
  const avgProcessing = useSelector(store => store.trailersOdrBraking.avgProcessing);
  const devices = useSelector(store => store.trailerDetails.trailers.items);
  const isMetric = useSelector(store => store.auth.isMetric);
  useEffect(() => {
    const trailerId = trailer?.assetId || 'default_asset';
    setTrailerId(trailerId);
    let odrBrakingData;
    if (trailer?.uploaded || isTempAccess) {
      const isTEBSF = getIsTrailerTEBSF(trailer);
      odrBrakingData = [
        {
          ...trailer?.brakeValues,
          ABS_counter: getValuePerDistance(trailer?.brakeValues?.ABS_counter, trailer?.brakeValues?.mileage),
          RSS_counter_1: isTEBSF
            ? getValuePerDistance(trailer?.brakeValues?.RSS_control_level_1_counter, trailer?.brakeValues?.mileage)
            : getValuePerDistance(trailer?.brakeValues?.RSS_counter_1, trailer?.brakeValues?.mileage),
          RSS_counter_2: isTEBSF
            ? getValuePerDistance(trailer?.brakeValues?.RSS_control_level_2_counter, trailer?.brakeValues?.mileage)
            : getValuePerDistance(trailer?.brakeValues?.RSS_counter_2, trailer?.brakeValues?.mileage),
          Handbrake_counter: getValuePerDistance(
            trailer?.brakeValues?.Handbrake_counter,
            trailer?.brakeValues?.mileage
          ),
          assetId: trailerId
        }
      ];
    } else {
      odrBrakingData = brakingEventsGroupData;
    }
    setOdrBrakingData(odrBrakingData);
  }, [brakingEventsGroupData, trailer, isTempAccess, setOdrBrakingData, setTrailerId]);

  useEffect(() => {
    getOdrBrakingAvg();
  }, [getOdrBrakingAvg]);

  const getDeviceName = assetId => {
    if (assetId === trailerId) {
      return trailer.trailer || trailer.defaultDisplayName;
    }
    const device = devices && devices.find(device => device.assetId === assetId);

    if (device && device.trailer) {
      return device.trailer || device.defaultDisplayName;
    } else {
      return formatMessage(strings.short.unknown);
    }
  };

  if (processing || avgProcessing) {
    return <LoadingOutlined className='large-margin-left' />;
  }
  return (
    <div>
      <div className='histogram-list-header'>
        <div>
          <h2>{formatMessage(strings.short.trailerDrivingProfile)}</h2>
          <span className='histogram-chart-subtitle'>
            {formatMessage(strings.short.trailerDrivingProfileSubtitle, {
              value: formatMessage(getUnit(unitType.distance, isMetric))
            })}
          </span>
        </div>
        <div className='histogram-switch-element'></div>
      </div>
      <div className='histogram-chart-grid' ref={ref} height={height} style={{ maxHeight: height }}>
        {!!(trailer && odrBrakingData?.length) && (
          <Fragment>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileMileageTitle)}
                subTitle={formatMessage(strings.charts.profileMileageSubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric, false, true, true))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'mileage'}
                xAxisMetric='k'
                width={width}
                activeAsset={trailerId}
                formatTotal={x => formatMessage(...getConversionString(isMetric, x, unitType.distance))}
                dataTransformer={x => Math.round(convertInt(isMetric, unitType.distance, x) / 1000)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileBrakeFrequencyTitle)}
                subTitle={formatMessage(strings.charts.profileBrakeFrequencySubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric, false, false, true))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'brakeFrequency'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x =>
                  +convertInt(isMetric, unitType.perDistance, x).toFixed(2) +
                  ` / ${formatMessage(getUnit(unitType.distance, isMetric))}`
                }
                dataTransformer={x => +convertInt(isMetric, unitType.perDistance, x).toFixed(2)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileAvgCPressureTitle)}
                subTitle={formatMessage(strings.charts.profileAvgCPressureSubTitle, {
                  value: formatMessage(getUnit(unitType.pressure, isMetric))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'avgControlPressure'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x => formatMessage(...getConversionString(isMetric, x, unitType.pressure))}
                dataTransformer={x => +convertInt(isMetric, unitType.pressure, x).toFixed(2)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileAbsInterventionsTitle)}
                subTitle={formatMessage(strings.charts.profileAbsInterventionsSubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'ABS_counter'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x =>
                  +convertInt(isMetric, unitType.perDistance, x).toFixed(2) +
                  ` / 10,000 ${formatMessage(getUnit(unitType.distance, isMetric))}`
                }
                dataTransformer={x => +convertInt(isMetric, unitType.perDistance, x).toFixed(2)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileStage1RssTitle)}
                subTitle={formatMessage(strings.charts.profileStage1RssSubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'RSS_counter_1'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x =>
                  +convertInt(isMetric, unitType.perDistance, x).toFixed(2) +
                  ` / 10,000 ${formatMessage(getUnit(unitType.distance, isMetric))}`
                }
                dataTransformer={x => +convertInt(isMetric, unitType.perDistance, x).toFixed(2)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileStage2RssTitle)}
                subTitle={formatMessage(strings.charts.profileStage2RssSubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'RSS_counter_2'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x =>
                  +convertInt(isMetric, unitType.perDistance, x).toFixed(2) +
                  ` / 10,000 ${formatMessage(getUnit(unitType.distance, isMetric))}`
                }
                dataTransformer={x => +convertInt(isMetric, unitType.perDistance, x).toFixed(2)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
            <div className='odrBraking-chart-div'>
              <OdrBrakingChart
                title={formatMessage(strings.charts.profileHandbrakeFreqTitle)}
                subTitle={formatMessage(strings.charts.profileHandbrakeFreqSubTitle, {
                  value: formatMessage(getUnit(unitType.distance, isMetric))
                })}
                odrBrakingData={odrBrakingData}
                averages={brakingEventsGroupAvg}
                getDeviceName={getDeviceName}
                chartkey={'Handbrake_counter'}
                width={width}
                activeAsset={trailerId}
                formatTotal={x =>
                  +convertInt(isMetric, unitType.perDistance, x).toFixed(0) +
                  ` / 10,000 ${formatMessage(getUnit(unitType.distance, isMetric))}`
                }
                dataTransformer={x => +convertInt(isMetric, unitType.perDistance, x).toFixed(0)}
                switchTrailer={selectTrailer}
                assetHighlighted={assetHighlighted}
                setAssetHighlighted={setAssetHighlighted}
              />
            </div>
          </Fragment>
        )}
      </div>
      {!(trailerId && odrBrakingData && odrBrakingData.length) && (
        <div className='histogram-warning'>{formatMessage(strings.charts.histChartDataNotloaded)}</div>
      )}
    </div>
  );
};

export default injectIntl(OdrBrakingList);
