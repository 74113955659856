import React from 'react';
import { Typography, Row } from 'antd';
const { Title, Text } = Typography;

const TitleSubTitle = ({ title, subtitle }) => {
  return (
    <>
      <Row>
        <Title className={subtitle ? 'margin-bottom-zero' : ''} level={5}>
          {title}
        </Title>
      </Row>
      {subtitle && (
        <Row>
          <Text type='secondary'>{subtitle}</Text>
        </Row>
      )}
    </>
  );
};

export default TitleSubTitle;
