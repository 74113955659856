import IconClusterLayer from '../Components/Utils/IconClusterLayer';

import arrow from '../assets/images/trailer/marker_arrow.png';
import transparent from '../assets/images/trailer/empty.png';
import { GeoJsonLayer } from '@deck.gl/layers';
import TagmapLayer from '../Components/Utils/tagmap-layer';
import { TripsLayer } from '@deck.gl/geo-layers';
import GL from '@luma.gl/constants';
import { IconLayer, LineLayer } from '@deck.gl/layers';
import { DrawRectangleMode, EditableGeoJsonLayer } from 'nebula.gl';
import iconAtlas from '../assets/images/trailer/map-marker-sprite.png';
import iconMarker from '../assets/images/marker.png';
import iconMapping from '../assets/images/trailer/map-marker-sprite-map.json';
import { getTriggers } from './alarms/functions';
import moment from 'moment';
import { getIsShowPlaces, getGeozoneColor, getPlacesVariable } from './functions';
import { ENV_CONFIG } from '../app/helpers/env-configs';

const { REACT_APP_ANIMATED_HEAD_MAX_INTERVAL } = ENV_CONFIG;

const getAngle = heading => {
  heading = heading < 0 ? 360 + heading : heading;
  return !heading ? 180 : heading < 180 ? heading * -1 + 180 : 360 - heading + 180;
};

const getPosition = (selectedTrailer, historyPosition, device, mapRoutes) => {
  if (selectedTrailer.assetId) {
    if (
      historyPosition &&
      selectedTrailer.assetId === historyPosition.assetId &&
      historyPosition.time &&
      historyPosition.to
    ) {
      return historyPosition.to;
    } else if (mapRoutes.length) {
      return mapRoutes[mapRoutes.length - 1].to;
    }
  }
  if (device.gnss) {
    return [device.gnss.longitude, device.gnss.latitude];
  }
};

const getClusterCount = properties => {
  if (properties.point_count <= 10) {
    return properties.point_count;
  } else if (properties.point_count >= 100) {
    return 100;
  } else {
    return properties.point_count - (properties.point_count % 10);
  }
};

const getCircleColor = (trailer = {}, historyPosition = null) => {
  if (historyPosition && historyPosition.time) {
    return 'circle-blue';
  }

  let color = 'circle-grey';
  if (trailer.activityStatus && trailer.activityStatus.status) {
    if (trailer.activityStatus.status === 'driving') {
      color = 'circle-blue';
    } else if (trailer.activityStatus.status === 'paused') {
      color = 'circle-yellow';
    }
  }

  return color;
};

const getGhostPointerColor = (trailer = {}, timeTravelActive = false, lastEvent) => {
  let color = 'ghost-pointer-grey';
  if (timeTravelActive && lastEvent && lastEvent.imei) {
    if (parseInt(lastEvent.endSpeed) > 0) {
      color = 'ghost-pointer-blue';
    }
  } else if (trailer.activityStatus && trailer.activityStatus.status) {
    if (trailer.activityStatus.status === 'driving') {
      color = 'ghost-pointer-blue';
    } else if (trailer.activityStatus.status === 'paused') {
      color = 'ghost-pointer-yellow';
    }
  }

  return color;
};

const getMapRoutePoints = (devices, mapRoutes) => {
  const latestDevices = devices ? Object.assign({}, ...devices.map(device => ({ [device.assetId]: device }))) : {};
  const updatedMapRoutes = Object.assign({}, mapRoutes);
  Object.keys(updatedMapRoutes).forEach(key => {
    if (!latestDevices[key]) {
      delete updatedMapRoutes[key];
    }
  });

  return [].concat.apply(
    [],
    Object.entries(updatedMapRoutes).map(([, segments]) => segments)
  );
};

const getMapRoutesFilteredByDatePicker = (
  mapRoutesPoints,
  selectedTrailer,
  historyPosition,
  hours,
  trailerInfoPanelEndDate
) => {
  const timeNow = Math.round((selectedTrailer.assetId && trailerInfoPanelEndDate) || Date.now() / 1000);

  const oldestTime = timeNow - (selectedTrailer.assetId ? hours : 1) * 60 * 60;

  const mapRoutesFilterByDatePicker = mapRoutesPoints.filter(
    pos =>
      (!historyPosition ||
        !selectedTrailer.assetId ||
        !historyPosition.time ||
        (selectedTrailer.assetId === historyPosition.assetId && historyPosition.time / 1000 >= pos.startTime)) &&
      pos.startTime >= oldestTime &&
      pos.endTime <= timeNow
  );
  if (!mapRoutesFilterByDatePicker.length && mapRoutesPoints.length) {
    mapRoutesFilterByDatePicker.push(mapRoutesPoints[0]);
  }
  return mapRoutesFilterByDatePicker;
};

export const getClusterLayers = (
  activeDevices = [],
  setVisibleTrailers,
  updateTrailer,
  selectedTrailer,
  onClusterClick,
  geozones,
  setVisibleGeozones,
  onClickPlacesCluster
) => {
  const clusterLayers = [];

  if (getIsShowPlaces() && geozones?.length) {
    const geozoneClusters = new IconClusterLayer({
      id: 'places-icon-cluster',
      data: geozones,
      pickable: true,
      iconAtlas,
      iconMapping,
      getIcon: d => (!d.properties.cluster ? null : `place-marker-${getClusterCount(d.properties)}`),
      sizeScale: 90,
      getPosition: d => [d.address.longitude, d.address.latitude],
      getColor: () => [255, 255, 255],
      getAngle: () => {},
      onClick: d => onClickPlacesCluster(d.objects),
      onClusterChange: index => {
        setVisibleGeozones(index.filter(d => !d.properties.cluster).map(d => d.properties));
      }
    });
    clusterLayers.push(geozoneClusters);
  }
  if (!selectedTrailer.assetId) {
    const clusterTrailerLayer = new IconClusterLayer({
      id: 'icon-cluster',
      data: activeDevices,
      pickable: true,
      iconAtlas,
      iconMapping,
      getIcon: d =>
        !d?.properties?.cluster ? getCircleColor(d?.properties) : `marker-${getClusterCount(d?.properties)}`,
      sizeScale: 60,
      getPosition: d => {
        return [d.gnss.longitude, d.gnss.latitude];
      },
      getColor: () => [255, 255, 255],
      getAngle: d => (!d?.properties?.cluster ? getAngle(d?.properties?.gnss?.heading) : 0),
      onClick: d => (!d?.object.cluster ? updateTrailer(d?.object) : onClusterClick(d?.objects)),
      onClusterChange: index => {
        setVisibleTrailers(index.filter(d => !d?.properties?.cluster).map(d => d?.properties));
      }
    });
    clusterLayers.push(clusterTrailerLayer);
  }
  return clusterLayers;
};

const getAngleByHistory = (selectedTrailer, historyPosition, device, mapRoutes) => {
  let heading;
  if (selectedTrailer.assetId) {
    if (
      historyPosition &&
      selectedTrailer.assetId === historyPosition.assetId &&
      historyPosition.time &&
      historyPosition.endGnss
    ) {
      heading = historyPosition.endGnss.heading;
    } else if (mapRoutes.length) {
      heading = mapRoutes[mapRoutes.length - 1].endGnss.heading;
    }
  }
  if (!heading) {
    heading = device.gnss && device.gnss.heading;
  }
  return heading && getAngle(heading);
};

export const getTrailersIconLayers = (
  devices = [],
  mapRoutes,
  selectedTnTTrailer,
  historyPosition,
  updateTrailer,
  updateTooltip,
  hours,
  trailerInfoPanelEndDate
) => {
  if (selectedTnTTrailer.assetId) {
    devices = [selectedTnTTrailer];
  }

  let mapRoutesFilterByDatePicker = [];
  if (selectedTnTTrailer.assetId) {
    const mapRoutesPoints = getMapRoutePoints(devices, mapRoutes);

    mapRoutesFilterByDatePicker = getMapRoutesFilteredByDatePicker(
      mapRoutesPoints,
      selectedTnTTrailer,
      historyPosition,
      hours,
      trailerInfoPanelEndDate
    );
  }

  const trailers = devices.map(d => {
    d.position = getPosition(selectedTnTTrailer, historyPosition, d, mapRoutesFilterByDatePicker);
    d.angle = getAngleByHistory(selectedTnTTrailer, historyPosition, d, mapRoutesFilterByDatePicker);
    return d;
  });

  const angleLayer = new IconLayer({
    id: 'angle-icon-layer',
    data: trailers,
    pickable: true,
    getIcon: d => ({
      url:
        (d.activityStatus && d.activityStatus.status === 'driving') ||
        (selectedTnTTrailer.assetId &&
          historyPosition &&
          selectedTnTTrailer.assetId === historyPosition.assetId &&
          historyPosition.time)
          ? arrow
          : transparent,
      mask: true,
      x: 0,
      y: 0,
      width: 144,
      height: 144
    }),
    sizeScale: 60,
    getPosition: d => d.position,
    getColor: () => [0, 100, 170],
    getAngle: d => d.angle
  });
  const trailerLayer = new IconLayer({
    id: 'trailer-icon-layer',
    data: trailers,
    pickable: true,
    iconAtlas,
    iconMapping,
    getIcon: d => (d.category !== 'other' && iconMapping[d.category] ? d.category : 'other_trailer'),
    sizeScale: 60,
    getPosition: d => d.position,
    getColor: () => [255, 255, 255],
    getAngle: () => 0,
    // autoHighlight: true,
    onHover: updateTooltip,
    onClick: d => updateTrailer(d.object)
  });
  const layers = [];
  if (selectedTnTTrailer.assetId) {
    const clusterTrailerLayer = new IconLayer({
      id: 'trailer-bg-layer',
      data: trailers,
      pickable: true,
      iconAtlas,
      iconMapping,
      getIcon: () => getCircleColor(selectedTnTTrailer, historyPosition),
      sizeScale: 60,
      getPosition: d => d.position,
      getAngle: () => 0,
      // onHover: this.onHover,
      onClick: d => updateTrailer(d.object)
    });

    layers.push(clusterTrailerLayer);
  }

  layers.push(angleLayer);
  layers.push(trailerLayer);

  return layers;
};

export const getTrailerAlertsLayers = (
  visibleTrailers,
  alerts,
  selectedTnTTrailer,
  mapRoutes,
  hours,
  updateTooltip,
  region,
  trailerInfoPanelEndDate,
  productFeatures
) => {
  if (!alerts) {
    return [];
  }

  if (selectedTnTTrailer.assetId) {
    visibleTrailers = [selectedTnTTrailer];
  }

  const assetIds = (visibleTrailers || []).reduce((agg, item) => {
    agg[item.assetId] = 1;
    return agg;
  }, {});

  const triggerTypes = getTriggers(region, productFeatures);

  const startTime = trailerInfoPanelEndDate || Date.now() / 1000;
  const oldestTime =
    Math.round(trailerInfoPanelEndDate || Date.now() / 1000) - (selectedTnTTrailer.assetId ? hours : 1) * 60 * 60;
  // filtering the alarms based on the selected context
  const alarms = !alerts
    ? []
    : alerts
        .filter(alert => {
          if (selectedTnTTrailer.assetId && selectedTnTTrailer.assetId !== alert.assetId) {
            return false;
          }
          if (!assetIds[alert.assetId]) {
            return false;
          }
          const trail = mapRoutes[alert.assetId];
          if (!trail || trail.length === 0) {
            return false;
          }

          const startTrailTime = trail[0].startTime;
          const endTrailTime = trail[trail.length - 1].endTime;

          return (
            alert.time <= startTime &&
            alert.time >= oldestTime &&
            alert.time >= startTrailTime &&
            alert.time <= endTrailTime
          );
        })
        .map(alert => {
          if (!alert.gnss) {
            alert.gnss = {
              latitude: alert.latitude || alert.GPSPosition_latitude,
              longitude: alert.logitude || alert.GPSPosition_longitude
            };
          }
          return alert;
        });
  return [
    new IconLayer({
      id: 'alarm-layer',
      data: alarms,
      pickable: true,
      iconMapping,
      getIcon: d => {
        return {
          url: triggerTypes[d.alarm] ? triggerTypes[d.alarm].pngUrl : transparent,
          height: 128,
          width: 128
        };
      },
      sizeScale: 5,
      getPosition: d => (d.gnss && d.gnss.longitude && d.gnss.latitude ? [d.gnss.longitude, d.gnss.latitude] : [0, 0]),
      getSize: 8,
      onHover: updateTooltip
    })
  ];
};

export const getTrailersLayers = (
  devices = [],
  mapRoutes,
  animatedHeadRoutes,
  hours,
  selectedTnTTrailer,
  mapRef,
  historyPosition,
  updateTooltip,
  trailerInfoPanelEndDate
) => {
  if (selectedTnTTrailer.assetId) {
    devices = [selectedTnTTrailer];
  }

  const layers = [];

  if (Array.isArray(devices)) {
    const data = devices
      .filter(device => device.gnss && device.gnss.longitude && device.gnss.latitude)
      .map(device => ({
        label: device.defaultDisplayName,
        position: getPosition(selectedTnTTrailer, historyPosition, device, mapRoutes),
        weight: 2
      }));

    const isIE = /* @cc_on!@ */ false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    const isMicrosoftCrap = isIE || isEdge;
    if (isMicrosoftCrap) return [];
    layers.push(
      new TagmapLayer({
        id: 'label-layer',
        data
      })
    );
  }

  if (animatedHeadRoutes) {
    const computeAnimatedHeadLayer = routes => {
      const initTimeOffset = Date.now() / 1000;

      const animatedHeadSegments = Object.keys(routes).map(assetId => ({
        vendor: assetId,
        segments: [
          [
            routes[assetId].segment.from[0],
            routes[assetId].segment.from[1],
            routes[assetId].startTime - initTimeOffset
          ],
          [routes[assetId].segment.to[0], routes[assetId].segment.to[1], routes[assetId].endTime - initTimeOffset]
        ]
      }));

      return animatedHeadSegments;
    };

    layers.push(
      new TripsLayer({
        // live trail in orange
        id: 'trips',
        data: computeAnimatedHeadLayer(animatedHeadRoutes),
        getPath: d => d.segments,
        getColor: [255, 140, 0],
        widthMinPixels: 3,
        trailLength: parseInt(REACT_APP_ANIMATED_HEAD_MAX_INTERVAL, 10),
        currentTime: Date.now(),
        parameters: {
          [GL.BLEND]: true,
          [GL.BLEND_SRC_RGB]: GL.ONE,
          [GL.BLEND_DST_RGB]: GL.ONE,
          [GL.BLEND_EQUATION]: GL.FUNC_SUBTRACT
        }
      })
    );
  }

  const mapRoutesPoints = getMapRoutePoints(devices, mapRoutes);

  const mapRoutesFilterByDatePicker = getMapRoutesFilteredByDatePicker(
    mapRoutesPoints,
    selectedTnTTrailer,
    historyPosition,
    hours,
    trailerInfoPanelEndDate
  );

  const lineLayer = new LineLayer({
    id: 'trail',
    data: mapRoutesFilterByDatePicker,
    autoHighlight: false,
    pickable: true,
    justified: true,
    getWidth: d => (d.tail && !selectedTnTTrailer.assetId ? 2 : 3),
    getSourcePosition: d => d.from,
    getTargetPosition: d => d.to,
    parameters: {
      depthTest: false, // allow layers array position to determine which layer is above which (akin to Z-Index)
      // transparency-friendly blending options:
      [GL.BLEND_SRC_RGB]: GL.SRC_ALPHA,
      [GL.BLEND_DST_RGB]: GL.ONE_MINUS_SRC_COLOR,
      [GL.BLEND_EQUATION_RGB]: GL.FUNC_SUBTRACT,
      [GL.BLEND_SRC_ALPHA]: GL.ONE,
      [GL.BLEND_DST_ALPHA]: GL.ONE_MINUS_DST_ALPHA,
      [GL.BLEND_EQUATION_ALPHA]: GL.FUNC_ADD
    },
    getColor: d => getTrailColour(d, selectedTnTTrailer),
    onHover: updateTooltip
  });
  layers.push(lineLayer);

  return layers;
};

const getTrailColour = (device, selectedTnTTrailer) => {
  // TAG: opacity of trail, both body and tail, are potential customer parameters in the future
  const percentOpaqueTail =
    !selectedTnTTrailer.assetId || (selectedTnTTrailer.assetId && selectedTnTTrailer.assetId === device.assetId)
      ? 0.8
      : 0.4; // 80% or 40% opaque
  const percentOpaqueBody =
    !selectedTnTTrailer.assetId || (selectedTnTTrailer.assetId && selectedTnTTrailer.assetId === device.assetId)
      ? 1
      : 0.5; // 100% or 50% opaque

  const opacityTail = Math.round(255 * percentOpaqueTail);
  const opacityBody = Math.round(255 * percentOpaqueBody);
  const colour =
    !selectedTnTTrailer.assetId || (selectedTnTTrailer && selectedTnTTrailer.assetId === device.assetId)
      ? [30, 144, 255]
      : [218, 165, 32];

  return device.tail ? [...colour, opacityTail] : [...colour, opacityBody];
};

export const getSelectedTrailerLayers = (
  selectedTnTTrailer,
  mapRoutes,
  hours,
  mapRef,
  trailerInfoPanelEndDate,
  showGhostLayer = true,
  skipWaitMapStyleLoad = false
) => {
  if (mapRef && mapRef.current) {
    const map = mapRef.current.getMap();

    if (skipWaitMapStyleLoad || map.isStyleLoaded()) {
      if (map.getLayer('line-animation')) {
        map.removeLayer('line-animation');
      }
      if (map.getSource('line-animation')) {
        map.removeSource('line-animation');
      }

      if (!selectedTnTTrailer.assetId) {
        return [];
      }

      const mapRoutesPoints = getMapRoutePoints([selectedTnTTrailer], mapRoutes);

      const mapRoutesFilterByDatePicker = getMapRoutesFilteredByDatePicker(
        mapRoutesPoints,
        selectedTnTTrailer,
        null,
        hours,
        trailerInfoPanelEndDate
      );

      const items = [];
      mapRoutesFilterByDatePicker.forEach(item => {
        items.push(item.from);
        items.push(item.to);
      });

      if (mapRoutesFilterByDatePicker.length) {
        try {
          map.addLayer({
            id: 'line-animation',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: items
                    }
                  }
                ]
              }
            },
            paint: {
              'line-color': '#1890FF',
              'line-width': 2,
              'line-dasharray': [1, 2]
            }
          });
        } catch (e) {
          console.error(e);
        }

        if (showGhostLayer) {
          const lastEvent = mapRoutesFilterByDatePicker.sort((a, b) => a.endTime - b.endTime)[
            mapRoutesFilterByDatePicker.length - 1
          ];
          const now = moment().subtract('5', 'minutes').toDate().getTime() / 1000;
          // check if there is an active date in the datepicker and check if it's older than now - 5minutes
          // (5 minutes is just an extra time to certify that is using the datepicker and not a app delay)
          const timeTravelActive = trailerInfoPanelEndDate && trailerInfoPanelEndDate < now;

          const ghostLayer = new IconLayer({
            id: 'ghost-icon-layer',
            data: [selectedTnTTrailer],
            pickable: true,
            iconAtlas,
            iconMapping,
            getIcon: () => getGhostPointerColor(selectedTnTTrailer, timeTravelActive, lastEvent),
            sizeScale: 60,
            getPosition: () => lastEvent.to,
            getColor: () => [255, 255, 255],
            getAngle: () => lastEvent.endGnss && getAngle(lastEvent.endGnss.heading)
          });
          return [ghostLayer];
        }
      }
    }
  }

  return [];
};

export const getSelectionToolLayer = (selectionTool, onSelectDrawingTool) => {
  // if selectionTool is not active, remove the drawing tool layer
  if (!selectionTool) {
    return [];
  }
  return [
    new EditableGeoJsonLayer({
      id: 'geojson-layer',
      data: {
        type: 'FeatureCollection',
        features: []
      },
      mode: DrawRectangleMode,
      selectedFeatureIndexes: [],
      modeConfig: {
        dragToDraw: true
      },

      onEdit: onSelectDrawingTool
    })
  ];
};
/**
 * @param key
 * @returns number[]
 */

export const getGetJSONLayers = (
  zones = [],
  geoJsonTooltip = {},
  setGeoJsonTooltip = () => {},
  onPlaceClick = () => {}
) => {
  const layers = zones.map((place, geoKey) => {
    const color = getPlacesVariable(place.type, 'colour');

    return new GeoJsonLayer({
      id: `places-layer-${geoKey}`,
      data: {
        ...place.geofenceDefinition,
        place: place,
        features: { ...place.geofenceDefinition, id: geoKey + '-feature' },
        id: geoKey + '-data'
      },
      opacity: 1,
      pickable: true,
      filled: true,
      getFillColor: [...color, 25],
      lineWidthUnits: 'pixels',
      updateTriggers: {
        getLineWidth: [geoJsonTooltip]
      },
      getLineColor: color,
      getLineWidth: d => (geoJsonTooltip && geoJsonTooltip.id === d.id && geoJsonTooltip.position.picked ? 4 : 3),
      getRadius: d => d.properties?.radius?.size,
      onHover: d => (d.picked ? setGeoJsonTooltip({ id: d.object.id, position: d, place }) : setGeoJsonTooltip(false)),
      onClick: () => onPlaceClick(place)
    });
  });

  const placesIconLayer = new IconLayer({
    id: `places-icon-layer`,
    data: zones,
    iconAtlas,
    iconMapping,
    pickable: true,
    getIcon: d => (iconMapping[d.type] ? d.type : 'other'),
    getPosition: d => [d?.address?.longitude, d.address?.latitude],
    updateTriggers: {
      getSize: [geoJsonTooltip]
    },
    opacity: 1,
    getSize: d => (geoJsonTooltip?.place?.id === d.id && geoJsonTooltip.position.picked ? 144 : 108),
    onHover: d =>
      d.picked && d.object
        ? setGeoJsonTooltip({ id: d.object.id, position: d, place: d.object })
        : setGeoJsonTooltip(false)
  });

  return [layers, placesIconLayer];
};
export const getGetJSONSelectedPlaceLayer = place => {
  const layers = [];

  layers.push(
    new IconLayer({
      id: 'icon-place-' + place.placeId,
      data: [place],
      iconAtlas: iconMarker,
      iconMapping: {
        marker: { x: 0, y: 0, width: 256, height: 256, mask: true }
      },
      getIcon: () => 'marker',
      getPosition: d => d.placeLocation,
      sizeScale: 5,
      getSize: 8,
      getColor: d => d?.placeColor?.rgb || getGeozoneColor(d.index)
    })
  );

  layers.push(
    new TagmapLayer({
      id: 'label-layer',
      data: [
        {
          label: place.placeName,
          position: place.placeLocation,
          weight: 2
        }
      ]
    })
  );

  return layers;
};
