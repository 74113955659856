import React from 'react';

const IconWithoutISOCable = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='black'>
    <path d='M12.9319 9.07511C12.6889 9.31811 12.2949 9.31811 12.0519 9.07511L6.9249 3.94809C6.68189 3.70508 6.68189 3.31108 6.9249 3.06808C7.1679 2.82508 7.5619 2.82506 7.80491 3.06808L7.90056 3.16374L9.01014 2.05416C10.219 0.845266 12.0993 0.716993 13.4507 1.66931L14.2711 0.848925C14.5141 0.605921 14.9081 0.605921 15.1511 0.848925C15.3941 1.09193 15.3941 1.48593 15.1511 1.72893L14.3237 2.55626C15.2418 3.9041 15.103 5.75614 13.9076 6.95161L12.798 8.06117L12.9319 8.1951C13.1749 8.4381 13.1749 8.83209 12.9319 9.07511Z' />
    <path d='M5.99506 6.75273C6.20637 6.54142 6.20637 6.19882 5.99506 5.9875C5.78375 5.77619 5.44115 5.77619 5.22984 5.9875L4.12026 7.09709L3.94809 6.92492C3.70507 6.6819 3.31109 6.68191 3.06807 6.92492C2.82506 7.16793 2.82506 7.56192 3.06807 7.80492L3.202 7.93884L2.09242 9.04841C0.896947 10.2439 0.758225 12.0959 1.67625 13.4438L0.848927 14.2711C0.60591 14.5141 0.60591 14.9081 0.848927 15.1511C1.09193 15.3941 1.48592 15.3941 1.72894 15.1511L2.54931 14.3307C3.90069 15.283 5.78097 15.1547 6.98985 13.9459L8.09943 12.8363L8.19509 12.9319C8.43809 13.1749 8.83209 13.1749 9.07509 12.9319C9.3181 12.6889 9.3181 12.2949 9.07509 12.0519L9.01769 11.9945L10.1273 10.8849C10.3386 10.6736 10.3386 10.331 10.1273 10.1197C9.91597 9.90841 9.57336 9.90841 9.36205 10.1197L8.25247 11.2293L7.98465 10.9615L9.09422 9.85189C9.30554 9.64058 9.30554 9.29798 9.09422 9.08667C8.88291 8.87536 8.54031 8.87536 8.329 9.08667L7.21942 10.1962L6.95158 9.92842L8.06117 8.81884C8.27249 8.60753 8.27249 8.26492 8.06117 8.05362C7.84986 7.8423 7.50725 7.8423 7.29595 8.05362L6.18636 9.16319L5.91853 8.89537L7.02811 7.78578C7.23942 7.57448 7.23942 7.23187 7.02811 7.02056C6.81681 6.80925 6.4742 6.80925 6.26289 7.02056L5.15331 8.13014L4.88548 7.86231L5.99506 6.75273Z' />
  </svg>
);

export default IconWithoutISOCable;
