import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUSES, TRAILERS_THUNK_TYPE } from '../helpers/constants';
import { generateAsyncThunkReducers } from '../app/generators';
import { trailers } from '../data-transformation';

const initialState = {
  entities: {},
  filteredEntitiesIds: [],
  loading: LOADING_STATUSES.IDLE,
  loadingAcrossRequests: false
};

const fulfilledStateReducer = (state, action) => {
  const { dataNormalization, filterTrailers, applyFn } = trailers;
  const transformedTrailers = applyFn(action.payload, dataNormalization, filterTrailers);

  const entities = transformedTrailers.reduce((entities, entity) => {
    if (entity.assetId) {
      entities[entity.assetId] = entity;
    }
    return entities;
  }, state.entities);
  state.entities = entities.length ? trailers.sortTrailers(entities) : entities;

  // currently we need to load all data, before giving the user the choice to filter and search
  // given we only display data based on filtered entities, we need to update as we receive
  // to render on the UI in real time
  state.filteredEntitiesIds = trailers.sortTrailers(Object.values(state.entities)).map(e => e.assetId);
};

export const loadingAcrossRequests = (state, action) => {
  state.loadingAcrossRequests = action.payload;
};

export const updateFilteredEntitiesIds = (state, action) => {
  state.filteredEntitiesIds = action.payload;
};

export const updateTrailers = (state, action) => {
  if (action.payload) {
    action.payload.forEach(entity => {
      if (entity.assetId) {
        state.entities[entity.assetId] = entity;
      }
    });
  }
};

// ok to mutate inside these callbacks, see https://redux-toolkit.js.org/api/createSlice
export const sliceOptions = {
  name: 'trailers',
  initialState,
  reducers: {
    loadingAcrossRequests,
    updateFilteredEntitiesIds,
    updateTrailers
  },
  extraReducers: {
    ...generateAsyncThunkReducers({ thunkType: TRAILERS_THUNK_TYPE, fulfilledStateReducer })
  }
};

export const trailersSlice = createSlice(sliceOptions);

export default {
  trailers: trailersSlice.reducer
};
