import { createSlice } from '@reduxjs/toolkit';
import { generateAsyncThunkReducers } from '../app/generators';
import { LOADING_STATUSES, FEATURE_FLAGS_THUNK_TYPE } from '../helpers/constants';

const initialState = {
  featureFlags: {},
  loading: LOADING_STATUSES.IDLE
};

const fulfilledStateReducer = (state, action) => {
  state.featureFlags = action.payload;
};

export const sliceOptions = {
  name: 'featureFlags',
  initialState,
  extraReducers: {
    ...generateAsyncThunkReducers({ thunkType: FEATURE_FLAGS_THUNK_TYPE, fulfilledStateReducer })
  }
};

export const featureFlagsSlice = createSlice(sliceOptions);

export default {
  featureFlags: featureFlagsSlice.reducer
};
