import React from 'react';

const IconParked = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.17333 3.45067V12.64H6.98667V9.18934L8.256 9.15734C9.61067 9.12 10.0213 9.072 10.4853 8.90134C11.1573 8.64534 11.776 7.98934 12.0053 7.264C12.1387 6.85334 12.1653 5.92 12.0587 5.472C11.824 4.48 10.992 3.72267 9.98933 3.57333C9.78667 3.54667 8.624 3.504 7.40267 3.48267L5.17333 3.45067ZM10.2347 6.05334C10.3733 6.77867 9.94667 7.40267 9.21067 7.55734C9.01333 7.59467 8.496 7.62667 7.936 7.62667H6.98667V5.01334H7.936C8.496 5.01334 9.01333 5.04534 9.21067 5.08267C9.776 5.20534 10.1387 5.54667 10.2347 6.05334Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.51466 14.608C4.64 15.3547 5.82933 15.7867 7.2 15.9413C7.824 16.0107 8.21333 16.0053 8.89066 15.92C10.8 15.6693 12.3147 14.9333 13.6267 13.6267C14.9813 12.2667 15.7547 10.6133 15.952 8.64533C16.128 6.86933 15.5947 4.92266 14.5013 3.36C14.144 2.848 13.152 1.856 12.64 1.49866C10.6987 0.138665 8.31466 -0.309335 6.05866 0.261332C5.06133 0.517332 4.208 0.906665 3.36 1.49866C2.848 1.856 1.856 2.848 1.49866 3.36C-0.474668 6.176 -0.474668 9.81866 1.49866 12.64C1.89866 13.216 2.91733 14.2027 3.51466 14.608ZM10.992 13.728C10.464 14 10.1173 14.1333 9.49333 14.304C9.18399 14.384 8.88533 14.416 8.16 14.4267C7.648 14.4373 7.17866 14.4373 7.12 14.4213C7.08212 14.4144 6.99311 14.3964 6.89172 14.3759C6.83607 14.3647 6.77669 14.3527 6.72 14.3413C5.6 14.128 4.37866 13.4827 3.49866 12.6453C2.57066 11.76 1.86666 10.432 1.62133 9.09333C1.504 8.44266 1.54133 7.03466 1.696 6.45333C2.05866 5.09333 2.69866 4.04266 3.73866 3.12C5.25333 1.76533 7.41866 1.22133 9.41333 1.68C11.264 2.112 12.8747 3.35466 13.728 5.008C14 5.536 14.1333 5.88266 14.2987 6.50666C14.4587 7.088 14.496 8.44266 14.3787 9.09333C14.0053 11.104 12.752 12.816 10.992 13.728Z'
    />
  </svg>
);

export default IconParked;
