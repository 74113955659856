import React from 'react';
import LocaleString from '../Utils/LocaleString';

const WheelLayoutInfoToolTip = () => (
  <div className={'wheel-layout__tooltip'}>
    <p>
      <LocaleString type='phrase' id='wheelLayoutColorsInfo1' />
    </p>
    <ul className={'wheel-layout__tooltip__list'}>
      <li className={'wheel-layout__tooltip__item'}>
        <span className={'wheel-layout__tooltip__item__wheel  wheel-layout__tooltip__item__wheel--ok'}></span>
        <span className='wheel-layout__tooltip__item__text'>
          <LocaleString type='phrase' id='wheelLayoutColorsInfoDark' />
        </span>
      </li>
      <li className={'wheel-layout__tooltip__item'}>
        <span className={'wheel-layout__tooltip__item__wheel  wheel-layout__tooltip__item__wheel--old-data'}></span>
        <span className='wheel-layout__tooltip__item__text'>
          <LocaleString type='phrase' id='wheelLayoutColorsInfoGrey' />
        </span>
      </li>
      <li className={'wheel-layout__tooltip__item'}>
        <span className={'wheel-layout__tooltip__item__wheel  wheel-layout__tooltip__item__wheel--issue'}></span>
        <span className='wheel-layout__tooltip__item__text'>
          <LocaleString type='phrase' id='wheelLayoutColorsInfoOrange' />
        </span>
      </li>
      <li className={'wheel-layout__tooltip__item'}>
        <span
          className={'wheel-layout__tooltip__item__wheel  wheel-layout__tooltip__item__wheel--issue-old-data'}
        ></span>
        <span className='wheel-layout__tooltip__item__text'>
          <LocaleString type='phrase' id='wheelLayoutColorsInfoLightOrange' />
        </span>
      </li>
    </ul>
    <p>
      <LocaleString type='phrase' id='wheelLayoutColorsInfo2' />
    </p>
  </div>
);

export default WheelLayoutInfoToolTip;
