import { line, scaleLinear, curveMonotoneX } from 'd3';

const mapper = { x: 'step', y: 'value' };

export const path = (xScale, yScale) =>
  line()
    .x(row => xScale(row[mapper.x]))
    .y(row => yScale(row[mapper.y]))
    .curve(curveMonotoneX);

const scales = ({ viewBox, hdata, chartkey, stepSize = 10, customScale, dataTransformer }) => {
  let step = stepSize;
  if (chartkey && hdata) {
    const validData = hdata.filter(asset => asset[chartkey] && asset[chartkey].length);
    const stepCount = validData.reduce((length, asset) => {
      if (asset[chartkey].length > length) {
        return asset[chartkey].length;
      } else {
        return length;
      }
    }, 0);
    if (!customScale && stepCount === 8) {
      step = step * 2;
    }

    const data = validData.map(asset => {
      let multiplier = 1;

      if (stepCount === 16 && asset[chartkey].length === 8) {
        multiplier = 2;
      }

      return asset[chartkey].map((row, index) => ({
        step: index * step * multiplier,
        value: dataTransformer ? dataTransformer(row) : row,
        asset: asset['assetId']
      }));
    });

    const max = data.reduce((max, values) => {
      return Math.max(max, values && values.length ? Math.max(...values.map(row => row.value)) : 0);
    }, 0);

    const xScaled = scaleLinear()
      .domain([0, stepCount * step])
      .range([5, customScale ? viewBox.width + 60 : viewBox.width - 10]);

    const yScaled = scaleLinear()
      .domain([max ? max : 1, 0])
      .range([0, viewBox.height]);

    const lines = [];

    data.forEach(asset => {
      const line = path(xScaled, yScaled)(asset);
      lines.push({ asset: asset[0].asset, line, data: asset });
    });

    return [xScaled, yScaled, lines];
  }
};
export default scales;
