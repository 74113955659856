import actionTypes from './actionTypes';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

function getTrailerHistogramsRequest() {
  return {
    type: actionTypes.GET_TRAILER_HISTOGRAMS_REQUEST
  };
}

function getTrailerHistogramsSuccess(trailers, final) {
  return {
    type: actionTypes.GET_TRAILER_HISTOGRAMS_SUCCESS,
    trailers,
    final
  };
}

function getTrailerHistogramsFailure(error) {
  return {
    type: actionTypes.GET_TRAILER_HISTOGRAMS_FAILURE,
    error
  };
}

function callAPI(dispatch, lastEvaluatedKey) {
  const body = {
    LastEvaluatedKey: lastEvaluatedKey || null
  };

  worker
    .Api({ method: 'post', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'trailersHistogram', body })
    .then(res => {
      if (res.LastEvaluatedKey) {
        callAPI(dispatch, res.LastEvaluatedKey);
      }
      return dispatch(getTrailerHistogramsSuccess(res.Items, !res.LastEvaluatedKey));
    })
    .catch(err => dispatch(getTrailerHistogramsFailure(err)));
}

function getTrailerHistograms() {
  return (dispatch, getState) => {
    const store = getState();
    const { data, processing } = store.trailerHistograms;
    if (!data && !processing) {
      dispatch(getTrailerHistogramsRequest());
      callAPI(dispatch);
    }
  };
}

export default {
  getTrailerHistograms
};
