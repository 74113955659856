import actionTypes from '../actions/actionTypes';

export const initialState = {
  items: [],
  loading: false,
  loaded: false
};

export default function workshopServicePartnersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_WSP_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.GET_WSP_SUCCESS: {
      return { ...state, loading: false, loaded: true, items: action.items };
    }
    case actionTypes.GET_WSP_FAILURE: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}
