import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { getTriggers } from '../../../helpers/alarms/functions';
import { updateSelectedTnTTrailer } from '../../../actions/appState';
import { getAllAlerts } from '../../../actions/thunks/get-all-alerts';
import { unitType } from '../../../helpers/unitConverter';
import 'react-reflex/styles.css';
import '../../Notifications/Notifications.scss';
import EventDrawerContextInfoPanel from '../../EventDrawerContext/EventDrawerContextInfoPanel';
import { useIntl } from 'react-intl';
import PlacesAlarmsHistory from './PlacesAlarmsHistory';
import {
  selectRegion,
  selectPlacesItems,
  selectSelectedTrailer,
  selectIsMetric,
  selectAlertItems,
  selectFilteredEntities,
  selectProductFeatures
} from '../../../selectors';
import {
  getExportCSVData,
  showTotal,
  getHeaders,
  getDropDownTrailers,
  onChangeTab,
  refreshList,
  updateAlerts,
  updateFilteredAlerts,
  onDatePickerChange,
  selectEvent,
  onFilterChange,
  toggleSelectedType,
  gotoAlert,
  resetFilters,
  paginationConfig
} from './helpers/functions';
import { PAGE_TABS, defaultDates, isMobile, date } from './helpers/constants';

const { TabPane } = Tabs;

const PlacesAlarmsCentre = ({ match, history }) => {
  // these selectors can all be mocked in the test with a custom object acting as  store
  const devices = useSelector(selectFilteredEntities);
  const places = useSelector(selectPlacesItems);
  const selectedTrailer = useSelector(selectSelectedTrailer);
  const region = useSelector(selectRegion);
  const isMetric = useSelector(selectIsMetric);
  const alertsFromStore = useSelector(selectAlertItems);
  const productFeatures = useSelector(selectProductFeatures);

  const dispatch = useDispatch();

  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [scrollTo, setScrollTo] = useState(null);
  const [dates, setDates] = useState(defaultDates);
  const [lastDates, setLastDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [alerts, setAlerts] = useState(alertsFromStore);

  const triggerTypes = getTriggers(region, productFeatures);
  const { formatMessage } = useIntl();

  const getAlerts = dates => dispatch(getAllAlerts(dates));
  const updateTnTTrailer = trailer => dispatch(updateSelectedTnTTrailer(trailer));

  useEffect(() => {
    getAlerts(dates);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAlerts(updateAlerts(alertsFromStore, formatMessage, devices, places, triggerTypes));
    // eslint-disable-next-line
  }, [alertsFromStore]);

  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollTo(null);
    }
  }, [scrollTo]);

  useEffect(() => {
    updateFilteredAlerts(setFilteredAlerts, alerts, selectedTypes);
  }, [selectedTypes, filterValue, alerts]);

  useEffect(() => {
    if (dates[1]) {
      refreshList(getAlerts, dates);
    }
  }, [dates]);

  const onPageChange = page => {
    setCurrentPage(page);
    setScrollTo('notification-search-element');
  };

  const activeTab = match.params?.tabId ? match.params.tabId : PAGE_TABS.default;

  return (
    <div>
      {selectedEvent && (
        <EventDrawerContextInfoPanel
          trailer={selectedTrailer}
          alert={selectedEvent}
          resetSelection={() => selectEvent(null, updateTnTTrailer, setSelectedEvent, devices)}
        />
      )}
      <Tabs defaultActiveKey={PAGE_TABS.default} activeKey={activeTab} className='notifications-screen-container'>
        <TabPane key={PAGE_TABS.default} className='history-tabpane'>
          <PlacesAlarmsHistory
            goToSubSettings={() => onChangeTab(PAGE_TABS.subscriptionSettings, history)}
            paginationConfig={paginationConfig(showTotal, onPageChange, currentPage, filteredAlerts, formatMessage)}
            dropDownTrailers={getDropDownTrailers(alerts)}
            selectedTypes={selectedTypes}
            filterValue={filterValue}
            gotoAlert={alert => gotoAlert(alert, filteredAlerts, setCurrentPage, setScrollTo)}
            resetFilters={() => resetFilters(setDates)}
            onFilterChange={newFilterValue => onFilterChange(newFilterValue, onPageChange, setFilterValue, filterValue)}
            toggleSelectedType={type => toggleSelectedType(type, onPageChange, setSelectedTypes, selectedTypes)}
            triggerTypes={triggerTypes}
            onDatePickerChange={selectedDates =>
              onDatePickerChange(selectedDates, setDates, setLastDates, dates, onPageChange)
            }
            selectEvent={event => selectEvent(event, updateTnTTrailer, setSelectedEvent, devices)}
            formatMessage={formatMessage}
            getExportCSVData={() => getExportCSVData(isMetric, filteredAlerts, unitType)}
            isMetric={isMetric}
            isMobile={isMobile}
            getHeaders={getHeaders}
            dates={dates}
            date={date}
            //exportCSV={exportCSV}
            filteredAlerts={filteredAlerts}
            region={region}
            lastDates={lastDates}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PlacesAlarmsCentre;
