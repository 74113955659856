import * as strings from '../../../../helpers/defaultStrings';

const getNotifData = formatMessage => {
  const notifData = {
    monthlyMileageReport: {
      key: 'monthlyMileageReport',
      description: formatMessage(strings.description.monthlyMileageReport),
      frequency: formatMessage(strings.reports.monthlyOn1st),
      isEmailActive: false
    }
  };
  return notifData;
};

export default getNotifData;
