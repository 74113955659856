import React, { Fragment } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import score from './Score';

import ScoreChart from '../charts/scoreChart/ScoreChartComponent';
import * as strings from '../../helpers/defaultStrings';
import { COLUMNS } from '../../helpers/constants';
import ColumnInfoModal from '../Utils/ColumnInfoModal';
import { parseJson } from '../../helpers/parse-json';

const {
  recencyCriticalScoreFunction,
  recencyWarningScoreFunction,
  durationCriticalScoreFunction,
  durationWarningScoreFunction,
  frequencyCriticalScoreFunction,
  frequencyWarningScoreFunction
} = score;

const { HEALTH_SCORE, FREQUENCY_SCORE, RECENCY_SCORE, DURATION_SCORE } = COLUMNS;

const InfoModalWrapper = ({ devices, columnKey, isMetric }) => {
  const { formatMessage } = useIntl();
  const modalTitle = type => {
    switch (type) {
      case HEALTH_SCORE:
        return formatMessage(strings.phrase.howIsScoreComputed);
      case FREQUENCY_SCORE:
        return formatMessage(strings.columns.frequencyScore);
      case RECENCY_SCORE:
        return formatMessage(strings.columns.recencyScore);
      case DURATION_SCORE:
        return formatMessage(strings.columns.durationScore);
      default:
        return '';
    }
  };

  const props = {
    devices,
    columnKey,
    isMetric
  };

  return <ColumnInfoModal title={modalTitle(columnKey)} body={<ScoreDoc {...props} />} />;
};

const ScoreDoc = ({ devices, columnKey, isMetric }) => {
  const { formatMessage } = useIntl();
  const locale = parseJson(localStorage.getItem('locale') || '{}');

  return (
    // as this is a modal it is not a child of LocaleContainer and therefore doesnt have IntlProvider as an ancestor which means using <FormattedMessage> would not work.
    // To get around this we wrap the modal in its own <IntlProvider> which we populate with locale and messages stored in localStorage
    <IntlProvider locale={locale.lang} messages={locale.messages}>
      <div className='large-margin-top'>
        {columnKey === 'healthScore' &&
          formatMessage(
            isMetric.distance ? strings.description.howIsScoreComputed : strings.description.howIsScoreComputedImperial
          )}
        <div className='large-margin-top'>
          {(columnKey === 'healthScore' || columnKey === 'recencyScore') && (
            <Fragment>
              <h4>{formatMessage(strings.short.recency)}</h4>
              <p>
                {formatMessage(
                  isMetric.distance ? strings.description.recencyVal : strings.description.recencyValImperial,
                  {
                    critical: <strong className='critical'>{formatMessage(strings.short.critical)}</strong>,
                    warning: <strong className='warning'>{formatMessage(strings.short.warning)}</strong>
                  }
                )}
              </p>
              <ScoreChart
                criticalScoreFunction={recencyCriticalScoreFunction}
                warningScoreFunction={recencyWarningScoreFunction}
                domain={[0, 90]}
                xLabel={formatMessage(
                  isMetric.distance
                    ? strings.phrase.numDaysSinceLastEvent
                    : strings.phrase.numDaysSinceLastEventImperial
                )}
                criticalValues={devices ? devices.map(t => t.criticalDaysSinceLastEvent).filter(t => t) : []}
                warningValues={devices ? devices.map(t => t.warningDaysSinceLastEvent).filter(t => t) : []}
              />
            </Fragment>
          )}
          {(columnKey === 'healthScore' || columnKey === 'durationScore') && (
            <Fragment>
              <h4>{formatMessage(strings.short.duration)}</h4>
              <p>
                {formatMessage(
                  isMetric.distance ? strings.description.durationValMetric : strings.description.durationValimperial,
                  {
                    critical: <strong className='critical'>{formatMessage(strings.short.critical)}</strong>,
                    warning: <strong className='warning'>{formatMessage(strings.short.warning)}</strong>
                  }
                )}
              </p>
              <ScoreChart
                criticalScoreFunction={durationCriticalScoreFunction}
                warningScoreFunction={durationWarningScoreFunction}
                domain={[0, 10]}
                xLabel={formatMessage(
                  isMetric.distance ? strings.phrase.avgNumDaysWithStatus : strings.phrase.avgNumDaysWithStatusImperial
                )}
                criticalValues={devices ? devices.map(t => t.criticalAverageSequenceDuration).filter(t => t) : []}
                warningValues={devices ? devices.map(t => t.warningAverageSequenceDuration).filter(t => t) : []}
              />
            </Fragment>
          )}
          {(columnKey === 'healthScore' || columnKey === 'frequencyScore') && (
            <Fragment>
              <h4> {formatMessage(strings.short.frequency)}</h4>
              <p>
                {formatMessage(
                  isMetric.distance ? strings.description.frequencyVal : strings.description.frequencyValImperial,
                  {
                    critical: <strong className='critical'>{formatMessage(strings.short.critical)}</strong>,
                    warning: <strong className='warning'>{formatMessage(strings.short.warning)}</strong>
                  }
                )}
              </p>
              <ScoreChart
                criticalScoreFunction={frequencyCriticalScoreFunction}
                warningScoreFunction={frequencyWarningScoreFunction}
                domain={[0, 90]}
                xLabel={formatMessage(
                  isMetric.distance
                    ? strings.phrase.avgNumDaysBetween2Events
                    : strings.phrase.avgNumDaysBetween2EventsImperial
                )}
                criticalValues={devices ? devices.map(t => t.daysBetweenCriticalEvents).filter(t => t) : []}
                warningValues={devices ? devices.map(t => t.daysBetweenWarningEvents).filter(t => t) : []}
              />
            </Fragment>
          )}
        </div>
      </div>
    </IntlProvider>
  );
};

const ScoreDocWrapper = ({ isDeprecatedVariant = false, devices, columnKey, isMetric }) => {
  const props = {
    devices,
    columnKey,
    isMetric
  };

  if (isDeprecatedVariant) {
    return <InfoModalWrapper {...props} />;
  }

  return <ScoreDoc {...props} />;
};

export default ScoreDocWrapper;
