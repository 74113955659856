import React from 'react';

const IconWarningLight = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.19998 2.81974C4.3492 2.70053 4.44444 2.51945 4.44444 2.31667C4.44444 1.95769 4.14597 1.66667 3.77778 1.66667C3.61228 1.66667 3.46088 1.72547 3.34435 1.82279C1.31936 3.23805 0 5.5521 0 8.16663C0 10.7809 1.31912 13.0948 3.3438 14.5101C3.46042 14.6077 3.61203 14.6667 3.77778 14.6667C4.14597 14.6667 4.44444 14.3757 4.44444 14.0167C4.44444 13.804 4.33968 13.6152 4.17773 13.4966V13.4929C2.45779 12.3174 1.33333 10.37 1.33333 8.16663C1.33333 5.95375 2.4675 3.99914 4.19998 2.82526V2.81974Z' />
    <path d='M14.6667 8.16663C14.6667 5.96324 13.5422 4.0159 11.8223 2.84039V2.83675C11.6603 2.71817 11.5556 2.52935 11.5556 2.31667C11.5556 1.95769 11.854 1.66667 12.2222 1.66667C12.3877 1.66667 12.5391 1.72546 12.6557 1.82278C14.6806 3.23803 16 5.5521 16 8.16663C16 10.7809 14.6809 13.0948 12.6563 14.51C12.5397 14.6077 12.388 14.6667 12.2222 14.6667C11.854 14.6667 11.5556 14.3757 11.5556 14.0167C11.5556 13.804 11.6603 13.6152 11.8223 13.4966V13.4929C13.5422 12.3174 14.6667 10.37 14.6667 8.16663Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3333 8.16667C13.3333 11.0385 10.9455 13.3667 8 13.3667C5.05447 13.3667 2.66667 11.0385 2.66667 8.16667C2.66667 5.29479 5.05447 2.96667 8 2.96667C10.9455 2.96667 13.3333 5.29479 13.3333 8.16667ZM8 5.13334C7.75453 5.13334 7.55556 5.32734 7.55556 5.56667V9.46667C7.55556 9.706 7.75453 9.90001 8 9.90001C8.24547 9.90001 8.44444 9.706 8.44444 9.46667V5.56667C8.44444 5.32734 8.24547 5.13334 8 5.13334ZM8 11.4167C8.24547 11.4167 8.44444 11.2227 8.44444 10.9833C8.44444 10.744 8.24547 10.55 8 10.55C7.75453 10.55 7.55556 10.744 7.55556 10.9833C7.55556 11.2227 7.75453 11.4167 8 11.4167Z'
    />
  </svg>
);

export default IconWarningLight;
