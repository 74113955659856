import React, { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import './ColumnSelection.scss';
import LocaleString from './LocaleString';
import ColumnSelection from './ColumnSelection';

const ColumnSelectionWrapper = ({
  tableId,
  columns,
  selectedColumnKeys,
  columnsLocked,
  saveSettingsGroup,
  dynamicWidths,
  hideColumnSelectorBtn,
  setColumnSelectionOpen,
  isColumnSelectionOpen
}) => {
  const [localIsOpen, localSetIsOpen] = useState(false);
  const [isOpen, setIsOpen] = setColumnSelectionOpen
    ? [isColumnSelectionOpen, setColumnSelectionOpen]
    : [localIsOpen, localSetIsOpen];

  return (
    <div style={{ textAlign: 'center' }}>
      {!hideColumnSelectorBtn && (
        <Tooltip title={<LocaleString type='short' id='columnSettings' />} placement='left'>
          <Button
            className='selector-button'
            shape='circle'
            size='small'
            icon={<EllipsisOutlined />}
            onClick={() => setIsOpen(true)}
          />
        </Tooltip>
      )}
      {isOpen && (
        <ColumnSelection
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          columns={columns}
          tableId={tableId}
          selectedColumnKeys={selectedColumnKeys}
          saveSettingsGroup={saveSettingsGroup}
          columnsLocked={columnsLocked}
          dynamicWidths={dynamicWidths}
        />
      )}
    </div>
  );
};
export default ColumnSelectionWrapper;
