import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TrailerInfoPanelV2 from './components/TrailerInforPanel/TrailerInfoPanel';
import Dashboard from './components/Dashboard/Dashboard';
import DashboardTitleMenu from './components/DashboardTitleMenu/DashboardTitleMenu';
import TrailerTable from './components/TrailerTable/TrailerTable';
import './RightDrawer.scss';
import { getSelectedTnTTrailerAssetId } from '../../../selectors';
import { TrailersLoading } from '../components/TrailersLoading';

const RightDrawer = ({ trailers }) => {
  const [showDashboard, setShowDashboard] = useState(true);
  const selectedTnTTrailer = useSelector(getSelectedTnTTrailerAssetId);
  let trailerDetailsClass = 'livemapv2-right-drawer-trailer';
  let tableContainerClass = 'tf-right-drawer';

  if (selectedTnTTrailer) tableContainerClass += ' hidden';
  else trailerDetailsClass += ' hidden';

  return (
    <>
      <div className={trailerDetailsClass}>{selectedTnTTrailer && <TrailerInfoPanelV2 />}</div>
      <div className={tableContainerClass}>
        {showDashboard && <Dashboard trailers={trailers} />}
        <DashboardTitleMenu
          trailers={trailers}
          showDashboard={showDashboard}
          toogleDashboard={() => setShowDashboard(!showDashboard)}
        />
        <TrailersLoading />
        <TrailerTable trailers={trailers} />
      </div>
    </>
  );
};

export default RightDrawer;
