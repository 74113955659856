import React from 'react';
import * as strings from '../../helpers/defaultStrings';
import { DISPLAY_TRAILERS } from '../../helpers/constants';
import './Settings.scss';
import {
  LanguageOption,
  UnitSystemOption,
  MaxValidDaysOption,
  TrailersOption,
  DropdownOption,
  Warning
} from './SettingsCellComponents';

const SettingsOptionCell = ({ options, warning, args }) => {
  let OptionComponent = null;
  let optionArgs = [{ ...args, warning, key: options.type }];

  switch (options.type) {
    case 'language':
      OptionComponent = LanguageOption;
      break;
    case 'unitSystem':
      OptionComponent = UnitSystemOption;
      break;
    case 'maxValidDays':
      OptionComponent = MaxValidDaysOption;
      break;
    case 'displayTrailers': {
      OptionComponent = TrailersOption;
      const { optionalSettings, settings, formatMessage, showEBPMS } = args;
      optionArgs = [
        {
          ...args,
          checkboxTitle: formatMessage(
            showEBPMS
              ? strings.settings.displayTrailersThatAreInactiveInFleetConnected
              : strings.settings.displayTrailersThatAreInactive
          ),
          checkboxValue: DISPLAY_TRAILERS.INACTIVE_TRAILERS,
          checkboxGroupvalue: optionalSettings.displayTrailers || settings.displayTrailers,
          type: options.type,
          warning: warning[0],
          key: DISPLAY_TRAILERS.INACTIVE_TRAILERS
        }
      ];
      if (showEBPMS) {
        optionArgs.push({
          ...args,
          checkboxTitle: formatMessage(strings.settings.displayTrailersEBPMS),
          checkboxValue: DISPLAY_TRAILERS.INACTIVE_EBPMS_TRAILERS,
          checkboxGroupvalue: optionalSettings.displayTrailersEBPMS || settings.displayTrailersEBPMS,
          type: 'displayTrailersEBPMS',
          warning: warning[1],
          key: DISPLAY_TRAILERS.INACTIVE_EBPMS_TRAILERS
        });
      }
      break;
    }
    case 'mapStyle':
      break;
    default:
      OptionComponent = DropdownOption;
      break;
  }

  return (
    <div>
      {optionArgs.map(args => (
        <div key={args.key} className='options-cell'>
          <div className='option-div'>{OptionComponent && <OptionComponent {...args} />}</div>
          <div className='warning-div'>{args.warning && <Warning {...args} />}</div>
        </div>
      ))}
    </div>
  );
};

export default SettingsOptionCell;
