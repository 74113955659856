import { defineMessages } from 'react-intl';

export const alert = defineMessages({
  altitudeVal: {
    id: 'alert.altitudeVal',
    defaultMessage: 'Altitude: {value} m',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  altitudeFeetVal: {
    id: 'alert.altitudeFeetVal',
    defaultMessage: 'Altitude: {value} ft',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  dateVal: {
    id: 'alert.dateVal',
    defaultMessage: 'Date: {value}',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  ebsAmberTitle: {
    id: 'alert.ebsAmberTitle',
    defaultMessage: 'EBS amber light',
    description: 'EBS Electronic Braking System. Amber light as in an amber coloured bulb emitting light'
  },
  ebsAmberCardText: {
    id: 'alert.ebsAmberCardText',
    defaultMessage: 'Amber light on EBS',
    description: 'EBS Electronic Braking System. Amber light as in an amber coloured bulb emitting light'
  },
  absWarningCardText: {
    id: 'alert.absWarningCardText',
    defaultMessage: 'Warning lamp on ABS',
    description: 'ABS Anti-lock Braking System. Lamp as in a light'
  },
  ebsRedTitle: {
    id: 'alert.ebsRedTitle',
    defaultMessage: 'EBS red light',
    description: 'EBS Electronic Braking System. Red light as in an red coloured bulb emitting light'
  },
  absWarningTitle: {
    id: 'alert.absWarningTitle',
    defaultMessage: 'ABS warning lamp',
    description: 'ABS Anti-lock Braking System. Lamp as in a light bulb'
  },
  rssTitle: {
    id: 'alert.rssTitle',
    defaultMessage: 'RSS',
    description: 'RSS - Roll Stability Support'
  },
  rssTriggers: {
    id: 'alert.rssTriggers',
    defaultMessage: 'RSS triggers',
    description: 'RSS - Roll Stability Support'
  },
  absTitle: {
    id: 'alert.absTitle',
    defaultMessage: 'ABS',
    description: 'ABS - Anti-lock Braking System'
  },
  absTriggers: {
    id: 'alert.absTriggers',
    defaultMessage: 'ABS triggers',
    description: 'ABS - Anti-lock Braking System'
  },
  isoTitle: {
    id: 'alert.isoTitle',
    defaultMessage: 'ISO cable',
    description: 'ISO is name do not translate'
  },
  drivingWithoutISOTitle: {
    id: 'alert.drivingWithoutISOTitle',
    defaultMessage: 'Driving without ISO cable',
    description: 'ISO is name do not translate'
  },
  movingWithoutISO: {
    id: 'alert.movingWithoutISO',
    defaultMessage: 'Moving without ISO cable',
    description: 'ISO is name do not translate'
  },
  ebsRedCardText: {
    id: 'alert.ebsRedCardText',
    defaultMessage: 'Red light on EBS',
    description: 'EBS Electronic Braking System. Red light as in an red coloured bulb emitting light'
  },
  gpsSatellitesVal: {
    id: 'alert.gpsSatellitesVal',
    defaultMessage: 'GPS satellites: {value}',
    description:
      '{value} is a variable and should not be translated. {value} will be a number. GPS - Global Positioning System'
  },
  harshBrakeCardText: {
    id: 'alert.harshBrakeCardText',
    defaultMessage: 'Harsh braking detected',
    description: ''
  },
  harshBrakeTitle: {
    id: 'alert.harshBrakeTitle',
    defaultMessage: 'Harsh Braking',
    description: ''
  },
  noGeoLocationAvailable: {
    id: 'alert.noGeoLocationAvailable',
    defaultMessage: 'No geolocation available',
    description: ''
  },
  noPositionInfoVal: {
    id: 'alert.noPositionInfoVal',
    defaultMessage: '{value} has no position information',
    description: '{value} is a variable and should not be translated. {value} will be a string, the trailers name.'
  },
  notificationConfirmDelete: {
    id: 'alert.notificationConfirmDelete',
    defaultMessage: 'Are you sure you want to delete this notification?',
    description: ''
  },
  notificationDeleted: {
    id: 'alert.notificationDeleted',
    defaultMessage: 'Notification deleted',
    description: ''
  },
  notificationsDeleted: {
    id: 'alert.notificationsDeleted',
    defaultMessage: 'Notifications deleted',
    description: ''
  },
  notificationsPrefUpdateError: {
    id: 'alert.notificationsPrefUpdateError',
    defaultMessage: 'There was an error setting up your notification preferences. Please try again later.',
    description: ''
  },
  numNotificationsVal: {
    id: 'alert.numNotificationsVal',
    defaultMessage: 'You have {value} new notifications',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  powerOnTitle: {
    id: 'alert.powerOnTitle',
    defaultMessage: 'Power on',
    description: ''
  },
  powerOnCardText: {
    id: 'alert.powerOnCardText',
    defaultMessage: 'The device has powered on',
    description: ''
  },
  sessionExpiringTitle: {
    id: 'alert.sessionExpiringTitle',
    defaultMessage: 'Logout notice',
    description: 'notice as in warning'
  },
  sessionExpiringDescriptionVal: {
    id: 'alert.sessionExpiringDescriptionVal',
    defaultMessage: 'You will be logged out in {value} seconds.',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  sessionExpiringActionDetails: {
    id: 'alert.sessionExpiringActionDetails',
    defaultMessage: 'If you would like to stay logged in, please renew your session by clicking the button below',
    description: ''
  },
  sessionExpiringLoggingOut: {
    id: 'alert.sessionExpiringLoggingOut',
    defaultMessage: 'Logging out...',
    description: ''
  },
  speedVal: {
    id: 'alert.speedVal',
    defaultMessage: 'Speed: {value} km/h',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  speedMileVal: {
    id: 'alert.speedMileVal',
    defaultMessage: 'Speed: {value} mph',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  startedMovingCardText: {
    id: 'alert.startedMovingCardText',
    defaultMessage: 'Started moving',
    description: ''
  },
  stoppedMovingCardText: {
    id: 'alert.stoppedMovingCardText',
    defaultMessage: 'Stopped moving',
    description: ''
  },
  rssCardText: {
    id: 'alert.rssCardText',
    defaultMessage: 'RSS event occurs',
    description: 'RSS - Roll Stability Support'
  },
  absCardText: {
    id: 'alert.absCardText',
    defaultMessage: 'ABS event occurs',
    description: 'ABS - Anti-lock Braking System'
  },
  isoCardText: {
    id: 'alert.isoCardText',
    defaultMessage: 'The ISO cable connection could not be detected while trailer is driving',
    description: 'ISO is a cable name do not translate'
  },
  placeText: {
    id: 'alert.placeText',
    defaultMessage: 'Place event occurs',
    description: 'an alarm traveling into out out of a predefined area on the map'
  },
  enterPlaceText: {
    id: 'alert.enterPlaceText',
    defaultMessage: 'Entering {VAL_NAME}',
    description: 'an alarm traveling into a predefined area on the map'
  },
  leavePlaceText: {
    id: 'alert.leavePlaceText',
    defaultMessage: 'Leaving {VAL_NAME}',
    description: 'an alarm traveling out of a predefined area on the map'
  },
  startStopTitle: {
    id: 'alert.startStopTitle',
    defaultMessage: 'Start/Stop',
    description: ''
  },
  tpmsCardText: {
    id: 'alert.tpmsCardText',
    defaultMessage: 'TPMS: Tire pressure event detected',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsEUPCardText: {
    id: 'alert.tpmsEUPCardText',
    defaultMessage: 'TPMS: Tire extreme under pressure event detected',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsUPCardText: {
    id: 'alert.tpmsUPCardText',
    defaultMessage: 'TPMS: Tire under pressure event detected',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsEOPCardText: {
    id: 'alert.tpmsEOPCardText',
    defaultMessage: 'TPMS: Tire extreme over pressure event detected',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsOPCardText: {
    id: 'alert.tpmsOPCardText',
    defaultMessage: 'TPMS: Tire over pressure event detected',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsInfoCentreVal: {
    id: 'alert.tpmsInfoCentreVal',
    defaultMessage: 'Centre wheel of axle {axleNum} has pressure {pressure} bar',
    description: '{axleNum} and {pressure} are variables do not translate. {axleNum} and {pressure} are numbers'
  },
  tpmsInfoLeftVal: {
    id: 'alert.tpmsInfoLeftVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the left side of axle {axleNum} has pressure {pressure} bar',
    description: '{axleNum} and {pressure} are variables do not translate. {axleNum} and {pressure} are numbers'
  },
  tpmsInfoRightVal: {
    id: 'alert.tpmsInfoRightVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the right side of axle {axleNum} has pressure {pressure} bar',
    description: '{axleNum} and {pressure} are variables do not translate. {axleNum} and {pressure} are numbers'
  },
  tpmsInfoCentrePsiVal: {
    id: 'alert.tpmsInfoCentrePsiVal',
    defaultMessage: 'Centre wheel of axle {axleNum} has pressure {pressure} psi',
    description: '{axleNum} and {pressure} are variables do not translate. {axleNum} and {pressure} are numbers'
  },
  tpmsInfoLeftPsiVal: {
    id: 'alert.tpmsInfoLeftPsiVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the left side of axle {axleNum} has pressure {pressure} psi',
    description:
      '{wheelNum}, {axleNum} and {pressure} are variables do not translate. {wheelNum}, {axleNum} and {pressure} are numbers'
  },
  tpmsInfoRightPsiVal: {
    id: 'alert.tpmsInfoRightPsiVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the right side of axle {axleNum} has pressure {pressure} psi',
    description:
      '{wheelNum}, {axleNum} and {pressure} are variables do not translate. {wheelNum}, {axleNum} and {pressure} are numbers'
  },
  tpmsInfoCentreNoVal: {
    id: 'alert.tpmsInfoCentreNoVal',
    defaultMessage: 'Centre wheel of axle {axleNum} has insufficient pressure',
    description: '{axleNum} is a variable do not translate. {axleNum} is a number'
  },
  tpmsInfoLeftNoVal: {
    id: 'alert.tpmsInfoLeftNoVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the left side of axle {axleNum} has insufficient pressure',
    description: '{wheelNum} and {axleNum} are variables do not translate. {wheelNum} and {axleNum} are numbers'
  },
  tpmsInfoRightNoVal: {
    id: 'alert.tpmsInfoRightNoVal',
    defaultMessage: 'Wheel {wheelNum} from centre on the right side of axle {axleNum} has insufficient pressure',
    description: '{wheelNum} and {axleNum} are variables do not translate. {wheelNum} and {axleNum} are numbers'
  },
  tpmsTitle: {
    id: 'alert.tpmsTitle',
    defaultMessage: 'TPMS',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsEOPTitle: {
    id: 'alert.tpmsEOPTitle',
    defaultMessage: 'TPMS extreme over pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsOPTitle: {
    id: 'alert.tpmsOPTitle',
    defaultMessage: 'TPMS over pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsEUPTitle: {
    id: 'alert.tpmsEUPTitle',
    defaultMessage: 'TPMS extreme under pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  tpmsUPTitle: {
    id: 'alert.tpmsUPTitle',
    defaultMessage: 'TPMS under pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  unknownVehicleId: {
    id: 'alert.unknownVehicleId',
    defaultMessage: 'Unknown vehicle ID',
    description: ''
  },
  extremeHighTP: {
    id: 'alert.extremeHighTP',
    defaultMessage: 'Extreme high tire pressure',
    description: ''
  },
  highTP: {
    id: 'alert.highTP',
    defaultMessage: 'High tire pressure',
    description: ''
  },
  extremeLowTP: {
    id: 'alert.extremeLowTP',
    defaultMessage: 'Extreme low tire pressure',
    description: ''
  },
  lowTP: {
    id: 'alert.lowTP',
    defaultMessage: 'Low tire pressure',
    description: ''
  },
  geofence: {
    id: 'alert.geofence',
    defaultMessage: 'Places alarm',
    description: 'A place is an area on the map, Alarm triggered when something enters of leaves the place'
  },
  geofenceDesc: {
    id: 'alert.geofenceDesc',
    defaultMessage: 'Get notified when a trailer enters and leaves a place area.',
    description: 'A place is an area on the map, Alarm triggered when something enters of leaves the place'
  },
  ebsAmberLightDesc: {
    id: 'alert.ebsAmberLightDesc',
    defaultMessage:
      'The amber light for the trailer Electronic braking system (EBS) lights up on the truck’s dashboard',
    description: 'EBS Electronic Braking System. Amber light as in amber bulb that lights up'
  },
  absWarningLightDesc: {
    id: 'alert.absWarningLightDesc',
    defaultMessage:
      'The warning lamp for the trailer anti-lock braking system (ABS) lights up on the truck’s dashboard',
    description: 'ABS anti-lock Braking System.'
  },
  ebsRedLightDesc: {
    id: 'alert.ebsRedLightDesc',
    defaultMessage: 'The red light for the trailer Electronic braking system (EBS) lights up on the truck’s dashboard',
    description: 'EBS Electronic Braking System. Red light as in red bulb that lights up'
  },
  extremeHighPressureDesc: {
    id: 'alert.extremeHighPressureDesc',
    defaultMessage: 'The tire pressure monitoring system (TPMS) detects extreme high pressure for a tire',
    description: 'TPMS - tire pressure monitoring system'
  },
  highPressureDesc: {
    id: 'alert.highPressureDesc',
    defaultMessage: 'The tire pressure monitoring system (TPMS) detects high pressure for a tire',
    description: 'TPMS - tire pressure monitoring system'
  },
  extremeLowPressureDesc: {
    id: 'alert.extremeLowPressureDesc',
    defaultMessage: 'The tire pressure monitoring system (TPMS) detects extreme low pressure for a tire',
    description: 'TPMS - tire pressure monitoring system'
  },
  lowPressureDesc: {
    id: 'alert.lowPressureDesc',
    defaultMessage: 'The tire pressure monitoring system (TPMS) detects low pressure for a tire',
    description: 'TPMS - tire pressure monitoring system'
  },
  harshBrakeDesc: {
    id: 'alert.harshBrakeDesc',
    defaultMessage: "The Electronic braking system (EBS) detects a harsh break on the trailer's breaks",
    description: 'EBS Electronic Braking System.'
  },
  absDesc: {
    id: 'alert.absDesc',
    defaultMessage: 'The Anti-lock breaking system (ABS) triggers the trailer’s brake to prevent wheel lock-up',
    description: 'ABS - Anti-lock Braking System'
  },
  drivingWithoutISODesc: {
    id: 'alert.drivingWithoutISODesc',
    defaultMessage: 'The trailer drives without the ISO cable connection (battery devices only)',
    description: 'ISO is type of cable do not translate'
  },
  groteLightAnomalyDesc: {
    id: 'alert.groteLightAnomalyDesc',
    defaultMessage: 'Light anonmaly detected. Check exterior lights',
    description: ''
  },
  groteLightOutageDesc: {
    id: 'alert.groteLightOutageDesc',
    defaultMessage: 'Light outage detected. Replace it without delay',
    description: ''
  },
  rssDesc: {
    id: 'alert.rssDesc',
    defaultMessage: 'The Roll Stability Support (RSS) triggers the trailer’s brake to prevent roll-over',
    description: 'RSS - Roll Stability Support'
  },
  placeVal: {
    id: 'alert.placeVal',
    defaultMessage: 'Place: {VAL_NAME}',
    description: 'Do not translate {VAL_NAME}. place as in an area on a map'
  },
  enterVal: {
    id: 'alert.enterVal',
    defaultMessage: 'Enter: {VAL_DATE}',
    description: 'Do not translate {VAL_DATE}. Enter is to travel into an area'
  },
  exitVal: {
    id: 'alert.exitVal',
    defaultMessage: 'Exit: {VAL_DATE}',
    description: 'Do not translate {VAL_DATE}. Enter is to travel out of an area'
  },
  durationVal: {
    id: 'alert.durationVal',
    defaultMessage: 'Duration: {VAL_TIME}',
    description: 'Do not translate {VAL_TIME}. Duration as in amount of time'
  },
  groteLightAnomaly: {
    id: 'alert.groteLightAnomaly',
    defaultMessage: 'Light Anomaly',
    description: ''
  },
  groteLightOutage: {
    id: 'alert.groteLightOutage',
    defaultMessage: 'Light Outage',
    description: ''
  },
  wheelEndOverTemperature: {
    id: 'alert.wheelEndOverTemperature',
    defaultMessage: 'Wheel end over temperature',
    description: ''
  },
  parkedWithLowPressure: {
    id: 'alert.parkedWithLowPressure',
    defaultMessage: 'Parked with low pressure',
    description: ''
  },
  parkedWithSlowLeak: {
    id: 'alert.parkedWithSlowLeak',
    defaultMessage: 'Parked with slow leak',
    description: ''
  },
  slowLeak: {
    id: 'alert.slowLeak',
    defaultMessage: 'Slow leak (while operating)',
    description: ''
  },
  criticalLeak: {
    id: 'alert.criticalLeak',
    defaultMessage: 'Critical leak',
    description: ''
  },
  highWheelTemperatureDesc: {
    id: 'alert.highWheelTemperatureDesc',
    defaultMessage: 'High temperature detected and may have serious damage. Stop and inspect immediately.',
    description: ''
  },
  parkedWithLowPressureDesc: {
    id: 'alert.parkedWithLowPressureDesc',
    defaultMessage: 'Low tire pressure event detected while not operating. Schedule service soon.',
    description: ''
  },
  parkedWithSlowLeakDesc: {
    id: 'alert.parkedWithSlowLeakDesc',
    defaultMessage: 'Slow decrease in tire pressure detected while not operating. Schedule service soon.',
    description: ''
  },
  slowLeakDesc: {
    id: 'alert.slowLeakDesc',
    defaultMessage: 'Slow decrease in tire pressure detected while operating. Stop and inspect immediately.',
    description: ''
  },
  moderateLeakDesc: {
    id: 'alert.moderateLeakDesc',
    defaultMessage: 'Fast decrease in tire pressure detected. Stop and inspect immediately.',
    description: ''
  },
  electricalFault: {
    id: 'alert.electricalFault',
    defaultMessage: 'Electrical fault',
    description: ''
  },
  sensorNotFound: {
    id: 'alert.sensorNotFound',
    defaultMessage: 'Wheel end sensor not found',
    description: ''
  },
  electricalFaultDesc: {
    id: 'alert.electricalFaultDesc',
    defaultMessage: 'The wheel-end sensor is not operating properly. Schedule service soon.',
    description: ''
  },
  sensorNotFoundDesc: {
    id: 'alert.sensorNotFoundDesc',
    defaultMessage: 'The wheel-end sensor is not longer connected to the gateway. Schedule service soon.',
    description: ''
  },
  tireLowPressure: {
    id: 'alert.tireLowPressure',
    defaultMessage: 'Tire low pressure (while operating)',
    description: ''
  },
  tireLowPressureDesc: {
    id: 'alert.tireLowPressureDesc',
    defaultMessage: 'Low tire pressure event',
    description: ''
  },
  hxAlertInfo: {
    id: 'alert.hxAlertInfo',
    defaultMessage: 'Alarm was detected on wheel end at {wheelLocation} ({axleOrdinal} axle on {side} side).',
    description:
      'Do not translate {wheelLocation}, {axleOrdinal} or {side} , when plugged in the final string will be something like Alarm was detected on wheel end at A2L (second axle on left side).'
  },
  hxAlertInfoWithSinglePressure: {
    id: 'alert.hxAlertInfoWithSinglePressure',
    defaultMessage:
      'Alarm was detected on wheel end at {wheelLocation} ({axleOrdinal} axle on {side} side) with a pressure of {pressureValue}.',
    description:
      'Do not translate {wheelLocation}, {axleOrdinal}, {side} or {pressureValue}, when plugged in the final string will be something like Alarm was detected on wheel end at A2L (second axle on left side) with a pressure of 9.1 bar.'
  },
  hxAlertInfoWithPressure: {
    id: 'alert.hxAlertInfoWithPressure',
    defaultMessage:
      'Alarm was detected on wheel end at {wheelLocation} ({axleOrdinal} axle on {side} side) with a pressure of {pressureValuePortA} at port A and {pressureValuePortB} at port B.',
    description:
      'Do not translate {wheelLocation}, {axleOrdinal}, {side} or {pressureValue}, when plugged in the final string will be something like Alarm was detected on wheel end at A2L (second axle on left side) with a pressure of 9.1 bar.'
  },
  hxAlertInfoWithTemp: {
    id: 'alert.hxAlertInfoWithTemp',
    defaultMessage:
      'Alarm was detected on wheel end at {wheelLocation} ({axleOrdinal} axle on {side} side) with a temperature of {temperatureValue}.',
    description:
      'Do not translate {wheelLocation}, {axleOrdinal}, {side} or {temperatureValue}, when plugged in the final string will be something like Alarm was detected on wheel end at A2L (second axle on left side) with a temperature of 10°C.'
  },
  lowWheelTemperature: {
    id: 'alert.lowWheelTemperature',
    defaultMessage: 'Low wheel temperature',
    description: ''
  },
  tyreHighPressure: {
    id: 'alert.tyreHighPressure',
    defaultMessage: 'Tire High Pressure',
    description: ''
  },
  unknown: {
    id: 'alert.unknown',
    defaultMessage: 'Unknown alert',
    description: ''
  }
});
