import React from 'react';
const IconRSS = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.096 4.96549C14.4488 4.35433 14.2394 3.57286 13.6283 3.22001L8.92526 0.504731C8.31411 0.151883 7.53263 0.361279 7.17978 0.97243L3.82561 6.78202C3.47276 7.39317 3.68216 8.17465 4.29331 8.52749L8.99631 11.2428C9.60746 11.5956 10.3889 11.3862 10.7418 10.7751L14.096 4.96549Z' />
    <path d='M7.95812 13.041L7.87826 13.1793C7.70183 13.4849 7.80653 13.8756 8.11211 14.052C8.41768 14.2285 8.80842 14.1238 8.98484 13.8182L9.62373 12.7116C9.80016 12.406 9.69546 12.0153 9.38988 11.8389C9.08431 11.6624 8.69357 11.7671 8.51715 12.0727L8.43728 12.211L8.16064 12.0513L8.2405 11.913C8.41692 11.6074 8.31222 11.2167 8.00665 11.0403C7.70107 10.8638 7.31033 10.9685 7.13391 11.2741L7.05405 11.4124L5.11752 10.2944L5.19738 10.156C5.37381 9.85047 5.26911 9.45973 4.96353 9.28331C4.65796 9.10688 4.26722 9.21158 4.09079 9.51716L4.01093 9.65548L3.73429 9.49576L3.81415 9.35744C3.99057 9.05186 3.88587 8.66112 3.5803 8.4847C3.27472 8.30827 2.88398 8.41297 2.70756 8.71855L2.06867 9.82513C1.89224 10.1307 1.99694 10.5214 2.30252 10.6979C2.60809 10.8743 2.99883 10.7696 3.17526 10.464L3.25512 10.3257L3.53177 10.4854L3.4519 10.6237C3.27548 10.9293 3.38018 11.3201 3.68575 11.4965C3.99133 11.6729 4.38207 11.5682 4.55849 11.2626L4.63835 11.1243L6.57488 12.2424L6.49502 12.3807C6.3186 12.6863 6.4233 13.077 6.72887 13.2534C7.03445 13.4299 7.42519 13.3252 7.60161 13.0196L7.68147 12.8813L7.95812 13.041Z' />
    <path d='M14.1875 14.7083C14.4521 14.7083 14.6667 14.9229 14.6667 15.1875C14.6667 15.4521 14.4521 15.6667 14.1875 15.6667H1.40972C1.14508 15.6667 0.930553 15.4521 0.930553 15.1875C0.930553 14.9229 1.14508 14.7083 1.40972 14.7083H14.1875Z' />
  </svg>
);

export default IconRSS;
