import moment from 'moment';
import LocaleString from '../../Components/Utils/LocaleString';
import { ebsTypeMapping, disableMapMatching } from './constants';
import React from 'react';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { maxBy } from '../../helpers/arrays';

const { REACT_APP_HEAD_ANIMATION_ENABLE, REACT_APP_HEAD_ANIMATION_MAX_DEVICES, REACT_APP_ENV } = ENV_CONFIG;

export function getEbsType(type) {
  if (typeof type === 'string') {
    return type;
  }
  if (type != null && (ebsTypeMapping[type] || type > 4)) {
    if (type > 4) {
      return `Type ${type}`;
    }
    return ebsTypeMapping[type];
  }
  return <LocaleString type='short' id='unknown' />;
}

export function getDefaultDisplayNames(trailers) {
  return trailers.reduce((agg, trailer) => {
    agg[trailer.assetId] = trailer.trailer ? trailer.trailer : trailer.name ? trailer.name : 'ID: ' + trailer.assetId;
    return agg;
  }, {});
}

export const getODREepromDumpSize = ODR_eeprom_dump_size => {
  return !ODR_eeprom_dump_size || ODR_eeprom_dump_size / 1024;
};

export const getStatusPriority = trailer => {
  let priority = 0;
  if (trailer?.odoEvents?.length) {
    const lastOdoEvent = maxBy(trailer.odoEvents, e => e.mileage);
    const kmAgo = trailer.lastMileage - lastOdoEvent.mileage;
    if (kmAgo <= 100) {
      priority = lastOdoEvent.priority + 1;
    }
  } else if (trailer?.events?.length && trailer?.daily_events?.stop) {
    const lastDay = trailer.daily_events.stop.slice(0, 10);
    const lastEvent = trailer.events.filter(event => event.rawDate === lastDay)[0] || { priority: 0 };
    priority = lastEvent.priority;
  } else if (trailer.eepromSize === 32) {
    priority = '32kb';
  }
  return priority;
};

export function getOdrStatusObject(trailer) {
  let odrStatus = {};

  if (trailer.odr) {
    odrStatus = trailer.odr.info;
  } else if (trailer.odrParsingStatus === 'ERROR_IN_DECODING') {
    return { type: 'phrase', id: 'decodingError' };
  } else {
    odrStatus = trailer.fmsParsingStatus;
    if (odrStatus && odrStatus.endsWith('finished')) {
      return { type: 'phrase', id: 'kbFinished', val: odrStatus.split(' ')[0] };
    }
  }

  if ((!odrStatus || odrStatus === 'IDLE') && trailer.odrParsingStatus != null) {
    if (trailer.odrParsingStatus === 'ERROR_IN_DECODING') {
      return { type: 'phrase', id: 'decodingError' };
    } else {
      odrStatus =
        trailer.odrParsingStatus === 'DECODING_OK'
          ? { type: 'short', id: 'decoded' }
          : { type: 'phrase', id: 'decodedNoDTC' };
    }
  }

  if (trailer.odr && trailer.odr.progressinfo) {
    return { type: 'phrase', id: 'decodedPercentage', val: Math.round((trailer.odr.progressinfo / 65536) * 100) };
  } else if (!odrStatus) {
    return { type: 'phrase', id: 'noODRFileAvailable' };
  }

  return odrStatus.id && odrStatus.type ? odrStatus : { type: 'short', id: 'unknown' };
}

export function getIsValidNotification(
  message,
  notificationTypesToListenToCheck,
  isNotificationSub = false,
  isApi = false
) {
  if (isNotificationSub) {
    return true;
  }

  // notification from the state subscription/api data
  return (
    message.trigger &&
    ((isApi &&
      message.trigger.toLowerCase() === 'ebs' &&
      notificationTypesToListenToCheck['ebsAmber'] &&
      notificationTypesToListenToCheck['ebsAmber']['website']) ||
      (isApi &&
        message.trigger.toLowerCase() === 'ebs' &&
        notificationTypesToListenToCheck['ebsRed'] &&
        notificationTypesToListenToCheck['ebsRed']['website']))
    //||
    //((message.trigger.toLowerCase() === 'poweron') && (notificationTypesToListenToCheck['poweron']) && (notificationTypesToListenToCheck['poweron']['website'])) ||
    /*(((message.trigger.toLowerCase() === 'timebased') && (notificationTypesToListenToCheck['startstop']) && (notificationTypesToListenToCheck['startstop']['website'])) 
        && (((typeof message.data === 'string') &&
              ((message.data.toLowerCase().substr(0, 10) === 'standstill') || (message.data.toLowerCase().substr(0, 6) === 'moving'))) ||
            ((typeof message.event === 'string') &&
              ((message.event.toLowerCase().substr(0, 10) === 'standstill') || (message.event.toLowerCase().substr(0, 6) === 'moving')))
          ))*/
  );
}

export function getIsDuplicate(key, dataArray) {
  if (!dataArray || dataArray.length < 1) {
    return false;
  }
  return dataArray.some(dataItem => {
    return dataItem.key === key;
  });
}

export function getDataHash() {
  // TODO:
  // This impurity could make testing difficult -- need to see if alternative way to set this value.
  return Date.now() + '_' + Math.random();
}

export function getSpeed(d, useLastValidGNSS = false) {
  if (!useLastValidGNSS && d.lastValidValues && d.lastValidValues.ebs) {
    return d.lastValidValues.ebs.speed;
  }

  if (d.lastValidValues && d.lastValidValues.gnss) {
    return d.lastValidValues.gnss.speed;
  }

  if (d.ebs && !isNaN(d.ebs.speed) && d.ebs.valid) {
    return d.ebs.speed;
  }

  if (d.gnss && !isNaN(d.gnss.speed) && d.gnss.valid) {
    return d.gnss.speed;
  }
  return null;
}

export function getAxleLoad(d) {
  if (d.lastValidValues && d.lastValidValues.ebs) {
    return d.lastValidValues.ebs.axleload;
  }

  if (d.ebs && d.ebs.valid) {
    return d.ebs.axleload;
  }

  return null;
}

export function getOdometer(d) {
  if (d.lastValidValues && d.lastValidValues.ebs) {
    return d.lastValidValues.ebs.odometer;
  }

  if (d.ebs && d.ebs.valid) {
    return d.ebs.odometer;
  }

  return null;
}

export function getEbsTime(d) {
  if (d.lastValidValues && d.lastValidValues.ebs) {
    return d.lastValidValues.ebs.time;
  }

  if (d.ebs && d.ebs.valid) {
    return d.time;
  }

  return null;
}

export function getTPMSWithLastWheelValues(device) {
  if (device && device.lastValidValues) {
    const keys = Object.keys(device.lastValidValues).filter(key => key.includes('ebs_tpms_'));
    if (keys.length) {
      return keys.map(key => device.lastValidValues[key]);
    }
  }

  return null;
}

export function getTPMS(d, lastDeviceState) {
  let tpms = getTPMSWithLastWheelValues(d);

  if (!tpms && d.tpms) {
    tpms = getTPMSWithLastWheelValues(lastDeviceState);
    if (!tpms) {
      return d.tpms.data || [];
    }
  }

  return tpms;
}

export function getLatestValidTPMS(device) {
  const lastValidTpms = getTPMSWithLastWheelValues(device);
  const newTPMS = (device.tpms && device.tpms.data) || null;
  if (lastValidTpms && !newTPMS) {
    return lastValidTpms;
  } else if (lastValidTpms && newTPMS) {
    return lastValidTpms.map(lastValidWheel => {
      const update = newTPMS.filter(newWheel => newWheel.location === lastValidWheel.location)[0];
      return update || lastValidWheel;
    });
  }
  return null;
}

export function getBrakePads(d) {
  if (d.lastValidValues && d.lastValidValues.ebs_extended && d.lastValidValues.ebs_extended.brakePads) {
    return d.lastValidValues.ebs_extended.brakePads;
  }

  if (d.ebs && d.ebs.valid && d.ebs.extended && d.ebs.extended.brakePads) {
    return d.ebs.extended.brakePads;
  }

  return null;
}

export function getEBSValid(d) {
  if (d.ebs && d.ebs.valid) {
    return true;
  }
  return false;
}

export function getUpdatedActivityStatus(currentStatus, event, lastReport) {
  if (event) {
    const { trigger } = event;

    if (trigger === 'StartMovement') {
      return {
        status: 'driving',
        since: currentStatus && currentStatus.status === 'driving' ? currentStatus.since : moment.utc()
      };
    }
    if (trigger === 'StopMovement') {
      return {
        status: 'paused',
        since: currentStatus && currentStatus.status === 'paused' ? currentStatus.since : moment.utc()
      };
    }
    if (trigger === 'PowerOff') {
      return {
        status: 'parked',
        since: currentStatus && currentStatus.status === 'parked' ? currentStatus.since : moment.utc()
      };
    }
    if (trigger === 'PowerOn') {
      return {
        status: 'paused',
        since: moment.utc()
      };
    }
  } else if (lastReport && moment.utc().diff(lastReport, 'minutes') > 30) {
    return {
      status: 'parked',
      since: lastReport
    };
  }
  return currentStatus;
}

export function getActivityStatus(sinceState, lastPosition) {
  if (!sinceState || moment.utc().diff(lastPosition, 'minutes') > 30) {
    return {
      status: 'parked',
      since: lastPosition
    };
  }

  const { moving, power } = sinceState;

  if (power && power.state) {
    const { since, state } = power;
    if (
      state === 'PowerOff' &&
      !(moving && moving.state && moment.utc(new Date(moving.since * 1000)).isAfter(moment.utc(new Date(since * 1000))))
    ) {
      return {
        status: 'parked',
        since: moment.utc(new Date(since * 1000))
      };
    }
    if (
      state === 'PowerOn' &&
      !(
        moving &&
        moving.state &&
        moment.utc(new Date(moving.since * 1000)).isAfter(moment.utc(new Date(since * 1000)))
      ) &&
      moment.utc(new Date(since * 1000)).isAfter(moment().subtract('24', 'hours'))
    ) {
      return {
        status: 'paused',
        since: moment.utc(new Date(since * 1000))
      };
    }
  }

  if (moving && moving.state && moment.utc(new Date(moving.since * 1000)).isAfter(moment().subtract('24', 'hours'))) {
    const { since, state } = moving;
    if (state === 'StopMovement') {
      return {
        status: 'paused',
        since: moment.utc(new Date(since * 1000))
      };
    } else {
      return {
        status: 'driving',
        since: moment.utc(new Date(since * 1000))
      };
    }
  }

  return null;
}

export function getDisableHeadAnimation(state) {
  return (
    !disableMapMatching ||
    !REACT_APP_HEAD_ANIMATION_ENABLE ||
    REACT_APP_HEAD_ANIMATION_ENABLE.toString().trim() !== 'true' ||
    (Array.isArray(state.devices) && parseInt(REACT_APP_HEAD_ANIMATION_MAX_DEVICES, 10) < state.devices.length)
  );
}

export function getOdrInfoPerDevice(devices) {
  return Object.entries(devices).reduce((mapping, [deviceId, updates]) => {
    const updateWithOdr = updates.filter(update => update.odr);
    const lastUpdateWithOdr = updateWithOdr && updateWithOdr.length > 0 ? maxBy(updateWithOdr, e => e.time) : null;
    mapping[deviceId] = lastUpdateWithOdr ? lastUpdateWithOdr.odr : null;
    return mapping;
  }, {});
}

export function getIsIntergration() {
  return REACT_APP_ENV.toString().trim().toLowerCase() === 'integration';
}

const tools = {
  getEbsType,
  getDefaultDisplayNames,
  getStatusPriority,
  getOdrStatusObject,
  getIsValidNotification,
  getIsDuplicate,
  getDataHash,
  getSpeed,
  getAxleLoad,
  getOdometer,
  getEbsTime,
  getTPMS,
  getLatestValidTPMS,
  getBrakePads,
  getEBSValid,
  getUpdatedActivityStatus,
  getActivityStatus,
  getDisableHeadAnimation,
  getOdrInfoPerDevice,
  getIsIntergration
};

export default tools;
