import { ENV_CONFIG } from '../../../app/helpers/env-configs';
import { buildAuthDataFromResponse, parseJwt } from './helper';
import { login } from './login';
import { worker } from '../../../app/web-workers/aws-web-worker';
import { API_NAMES } from '../../../helpers/constants';
import { loginFailure } from '../../auth';
import { addUserData } from '../../user';

const VALIDATE_RETRY_TIMEOUT = 3000;
const MAX_VALIDATE_TOKEN_ATTEMPTS = 3;

export const validateAuth0Token = ({ token, retryCount = 0 }) => {
  return async dispatch => {
    const headers = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      locale: Intl.NumberFormat().resolvedOptions().locale,
      token: token
    };

    try {
      const data = await worker.Api({
        method: 'get',
        api: API_NAMES.ODR_LIVE_MAP,
        endpoint: `validateOdcToken/${ENV_CONFIG.REACT_APP_SCALAR_ENV}`,
        headers
      });
      const authData = buildAuthDataFromResponse(data);
      dispatch(login(authData));
      // we use this to ensure we can add the attributes: 'org' and 'picture' from the auth0 data.
      dispatch(addUserData(parseJwt(token)));
    } catch (error) {
      if (retryCount < MAX_VALIDATE_TOKEN_ATTEMPTS) {
        setTimeout(() => dispatch(validateAuth0Token({ token, retryCount: retryCount + 1 })), VALIDATE_RETRY_TIMEOUT);
      } else {
        dispatch(loginFailure(error));
      }
    }
  };
};
