import React from 'react';
import { getTriggerTitles, NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import moment from 'moment';
import AlertTimeline from '../../../../../charts/AlertTimeline/AlertTimelineComponent';
import './Alarm2hColumn.scss';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData, region }) => {
  const { alerts } = rowData;

  const dates = [moment(moment().subtract(2, 'h'), 'YYYY/MM/DD'), moment(moment(), 'YYYY/MM/DD')];

  if (alerts) {
    return (
      <div className={'chart-container'}>
        <AlertTimeline
          height={30}
          width={150}
          alerts={alerts}
          region={region}
          dates={dates}
          dataHash={`${alerts.length}${alerts}${alerts.assetId}`}
          isHideTimeline
        />
      </div>
    );
  }

  return <NoValueSpan />;
};

const Alarm2hColumn = ({ trailers, region, formatMessage, productFeatures }) => {
  const filterFunction = checkedCategories =>
    trailers.filter('alerts', t => {
      if (!t.alerts) {
        return checkedCategories['unknown'];
      }

      const filtersArray = Object.keys(checkedCategories).filter(key => checkedCategories[key]);

      return t.alerts.some(a => filtersArray.includes(a.alarm));
    });

  return {
    title: <Tooltip title={formatMessage(strings.short.alarm2h)}>{formatMessage(strings.short.alarm2h)}</Tooltip>,
    key: 'alerts',
    dataIndex: 'alerts',
    searchKey: formatMessage(strings.short.alarm2h),
    categories: getTriggerTitles(region, productFeatures),
    selectionSection: selectionHeaders.generalMonitoring.id,
    onFilter: filterFunction,
    render: rowData => <Cell rowData={rowData} region={region} />,
    removeFilter: () => trailers.removeFilter('alerts'),
    width: 150
  };
};

export default { table: Alarm2hColumn, csv: null };
