import React, { memo } from 'react';
import './OdyButton.scss';

const OdyButton = ({ icon, onClick, children }) => {
  return (
    <button onClick={onClick} className='ody-btn'>
      {icon && <span className='ody-btn-icon'>{icon}</span>}
      {children}
    </button>
  );
};

export default memo(OdyButton);
