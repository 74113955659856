import { createSelector } from '@reduxjs/toolkit';
import { AUTH_PROVIDER, regions, PRODUCT_FEATURES } from '../helpers/constants';
import { ENV_CONFIG } from '../app/helpers/env-configs';

const selectAuth = state => state?.auth;

const getRegion = createSelector(selectAuth, state => state?.region);
const getProvider = createSelector(selectAuth, state => state?.user?.provider);
const getFeatures = createSelector(selectAuth, state => state?.user?.features ?? []);

export const selectUser = createSelector(selectAuth, state => state?.user);

export const selectRegion = createSelector([getRegion, getProvider], (region, provider) => {
  if (region) {
    return region;
  }

  // This is a stop gap measure until region is a valid attribute for the auth object
  // This is the root cause of the bug: #403063
  // This code is temporary and should be removed auth is fully ported into scalar fit
  // #404749 tracks the removal of this code.
  return provider === AUTH_PROVIDER ? regions.america : null;
});

export const selectIsMetric = createSelector(selectAuth, state => state?.isMetric);

export const selectProductFeatures = createSelector(getFeatures, features => {
  const productFeatures = features.map(flag => flag.toLowerCase());

  const hasEBPMS = productFeatures.includes(PRODUCT_FEATURES.EBPMS);
  const hasTrackTrace = productFeatures.includes(PRODUCT_FEATURES.TRACKTRACE);

  const showLiveMapSelectionTool = (() => {
    const isStaging = ENV_CONFIG.REACT_APP_ENV === 'staging';
    const isProd = ENV_CONFIG.REACT_APP_ENV === 'prod';
    if (!isStaging && !isProd) {
      return true;
    }

    return !productFeatures.includes(PRODUCT_FEATURES.DISABLE_SELECTION_TOOL);
  })();

  return {
    showEBPMS: hasEBPMS,
    logoImage: hasEBPMS ? 'tip' : 'default',
    showLiveMap: hasTrackTrace,
    showTrailerHealth: hasTrackTrace,
    showVehicleId: hasTrackTrace,
    showNotification: hasTrackTrace,
    showUnitSettings: hasTrackTrace,
    showTrackTraceReports: hasTrackTrace,
    hasTrackTrace,
    showLiveMapSelectionTool,
    livemapFCF: productFeatures.includes(PRODUCT_FEATURES.LIVE_MAP_FCF),
    gohc: productFeatures.includes(PRODUCT_FEATURES.GOHC),
    showTPMSReport: productFeatures.includes(PRODUCT_FEATURES.TPMS_REPORT),
    showLightMonitoring: productFeatures.includes(PRODUCT_FEATURES.GROTE_GUARDIAN),
    showWatchman: productFeatures.includes(PRODUCT_FEATURES.WATCHMAN)
  };
});
export const isTrackAndTraceEnabled = createSelector(selectProductFeatures, state => state?.hasTrackTrace ?? false);
