const sizes = {
  mobileSmall: 320,
  mobileMedium: 376,
  mobile: 425,
  tablet: 768,
  desktopSmall: 1024,
  desktop: 1440,
  desktop4k: 2560
};

export const mediaQueryHelper = mq => window.matchMedia(`${mq}`);

const mediaData = {
  viewport: {
    mobileOnly: mediaQueryHelper(`(max-width:${sizes.tablet - 1}px)`),
    tabletOnly: mediaQueryHelper(`(min-width:${sizes.tablet}px) and (max-width:${sizes.desktopSmall - 1}px)`),
    desktopOnly: mediaQueryHelper(`(min-width:${sizes.desktopSmall}px)`),
    desktopLargeOnly: mediaQueryHelper(`(min-width:${sizes.desktop}px)`)
    // add more as needed
  }
};

/**
 * Usage:
 * import { mobileOnly } from '<path>/resposive'
 *
 * if (mobileOnly.matches) {
 *  // code
 * }
 *
 * // Listener for media change
 * mobileOnly.addListener(evt => {
 *  if (evt.matches) {
 *      // code
 *  } else {
 *      // code
 *  }
 * })
 */

export const { mobileOnly, tabletOnly, desktopOnly, desktopLargeOnly } = mediaData.viewport;
