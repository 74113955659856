import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { List, Row, Button, Col, Select, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import 'react-reflex/styles.css';
import './Notifications.scss';
import * as strings from '../../helpers/defaultStrings';
import AlertTimeline from '../charts/AlertTimeline/AlertTimelineComponent';
import { convert, unitType } from '../../helpers/unitConverter';
import { getGeofenceDurationString, getMediumDateShortTime } from '../../helpers/functions';
import { getAlarmIconSVG, getIsTypeGeofence } from '../../helpers/alarms/functions';
import { getNotificationTypeName } from '../../helpers/alarms/functions';
import { useLocation } from 'react-router';
import moment from 'moment';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { getisFFPlacesNotificationsPageEnabled } from '../../app/feature-flags';

const { Option } = Select;
const { RangePicker } = DatePicker;

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = ENV_CONFIG;

const NotificationCentreHistory = ({
  paginationConfig,
  dropDownTrailers,
  selectedTypes,
  filterValue,
  gotoAlert,
  isMobile,
  formatMessage,
  onFilterChange,
  toggleSelectedType,
  triggerTypes,
  onDatePickerChange,
  selectEvent,
  resetFilters,
  getExportCSVData,
  getHeaders,
  dates,
  date,
  exportCSV,
  filteredAlerts,
  goToSubSettings,
  lastDates
}) => {
  const processingAlerts = useSelector(state => state?.alerts?.processing);
  const region = useSelector(state => state?.auth?.region);
  const isMetric = useSelector(state => state?.auth?.isMetric);
  const [hasDoneInitialScroll, setHasDoneInitialScroll] = useState(false);
  const [idScrolledTo, setIdScrolledTo] = useState(false);
  const isFFPlacesNotificationsPageEnabled = useSelector(getisFFPlacesNotificationsPageEnabled);

  const query = new URLSearchParams(useLocation().search);
  const notification_key = query.get('notification_key');

  const notifKeyToAlertID = key => {
    const parts = key.split('_');
    return parts[1] + parts[2] + parts[0];
  };
  const shouldScroll = (hasDoneInitialScroll, idScrolledTo, id) => {
    if (!hasDoneInitialScroll) return true;
    if (idScrolledTo !== id) return true;
    return false;
  };
  useLayoutEffect(() => {
    if (notification_key) {
      const id = notifKeyToAlertID(notification_key);
      const element = document.getElementById(id);
      if (element && shouldScroll(hasDoneInitialScroll, idScrolledTo, id)) {
        gotoAlert({ id });
        setHasDoneInitialScroll(true);
        setIdScrolledTo(id);
      }
    }
  }, [notification_key, filteredAlerts, gotoAlert, hasDoneInitialScroll, idScrolledTo]);

  const disabledDate = current => {
    const startValue = dates[0];
    const endValue = dates[1];

    if (endValue) {
      return current > moment().endOf('day') || current < moment().subtract(1, 'year');
    }

    return (
      current > moment().endOf('day') ||
      current > moment(startValue).add(31, 'd') ||
      current < moment(startValue).startOf('day')
    );
  };

  return (
    <>
      <Row type='flex' justify='end' className='large-margin-bottom'>
        <Button type='link' onClick={goToSubSettings}>
          {formatMessage(strings.button.subscriptionSettings)}
        </Button>
        <Button icon={<ReloadOutlined />} onClick={resetFilters} className='medium-margin-right'>
          {formatMessage(strings.short.reload)}
        </Button>
        <CSVLink
          data={getExportCSVData(isMetric)}
          headers={getHeaders(isMetric)}
          filename={`alarms_export_${date}.csv`}
        >
          {}{' '}
          <Button icon={<DownloadOutlined />} onClick={exportCSV}>
            {formatMessage(strings.short.exportCSV)}
          </Button>
        </CSVLink>
      </Row>
      <Row type='flex' justify='space-between' align='bottom' className='large-margin-bottom alert-timeline__row'>
        <Col span={isMobile ? null : 16} className='alert-timeline__col alert-timeline__col--chart'>
          <AlertTimeline
            className='full-width'
            height={100}
            alerts={filteredAlerts}
            region={region}
            dates={dates[1] ? dates : lastDates}
            onClick={alert => gotoAlert(alert)}
            dataHash={`${filteredAlerts.length}${dates}${filterValue}${selectedTypes}`}
          />
        </Col>
        <Col span={isMobile ? null : 8} className='alert-timeline__col alert-timeline__col--filters'>
          <div className='notification-type-selector'>
            <h3 className='title'>{formatMessage(strings.short.alert)}</h3>
            <Row type='flex' gutter={4}>
              {Object.keys(triggerTypes)
                .filter(key => {
                  if (isFFPlacesNotificationsPageEnabled && key === 'geofence') {
                    return false;
                  } else {
                    return (
                      [
                        'default',
                        'tireExtremeUnderPressure',
                        'tireUnderPressure',
                        'tireExtremeOverPressure',
                        'tireOverPressure'
                      ].indexOf(key) < 0
                    );
                  }
                })
                .map(key => {
                  const trigger = triggerTypes[key];
                  const name = getNotificationTypeName(key, formatMessage);
                  const isSelected = selectedTypes.includes(key);
                  return (
                    <Col
                      key={key}
                      onClick={() => toggleSelectedType(key)}
                      className={'item'}
                      style={{
                        backgroundColor: isSelected ? '#e6f7ff' : 'transparent'
                      }}
                    >
                      {getAlarmIconSVG(trigger)}
                      <div className='type-name'>{name}</div>
                      {isSelected && <CheckOutlined className='right-aligned-icon' />}
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Col>
      </Row>
      <Row type='flex' justify='center' gutter={16} className='large-margin-top' id='notification-search-element'>
        <Col span={isMobile ? 10 : null}>
          <Select
            showSearch
            allowClear
            filterOption
            showArrow={false}
            placeholder={formatMessage(strings.phrase.filterByTrailer)}
            onChange={onFilterChange}
            className='notification-trailer-selector'
            value={filterValue}
          >
            {dropDownTrailers.map(trailer => (
              <Option key={trailer} value={trailer}>
                {trailer}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={isMobile ? 14 : null}>
          <RangePicker
            disabledDate={disabledDate}
            value={dates}
            format='YYYY-MM-DD'
            onCalendarChange={onDatePickerChange}
            disabled={processingAlerts}
          />
        </Col>
      </Row>
      <List
        itemLayout='vertical'
        size='large'
        loading={processingAlerts}
        //dataSource={notifications}
        pagination={paginationConfig}
        dataSource={filteredAlerts}
        rowKey='id'
        className='notification-centre__notification-list'
        renderItem={item => {
          const isGeofence = getIsTypeGeofence(item);
          return (
            <List.Item
              className='padding20'
              id={item.id}
              onClick={() => selectEvent(item)}
              extra={
                !item.longitude && !item.latitude ? (
                  <span className='no-gps-text'>{formatMessage(strings.phrase.noGpsData)}</span>
                ) : (
                  <img
                    onClick={() => selectEvent(item)}
                    width={272}
                    style={{
                      opacity: item.read === 1 ? '0.15' : '1',
                      cursor: 'pointer'
                    }}
                    alt='logo'
                    src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson({"type":"Point","coordinates":[${item.longitude},${item.latitude}]})/${item.longitude},${item.latitude},10/500x300?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`}
                  />
                )
              }
            >
              <List.Item.Meta
                avatar={
                  <div className={`${item.alarmIconClass} ${item.read === 1 ? 'list-avatar-read' : ''}`}>
                    {item.avatarSVG}
                  </div>
                }
                title={<div className={item.read === 1 ? 'list-item-read' : ''}>{item.title}</div>}
                description={<span className={item.read === 1 ? 'list-item-read' : ''}>{item.description}</span>}
              />
              <span className={item.read === 1 ? 'list-item-read' : ''}>
                <div>
                  {item.wheelInfo}
                  <span>
                    {isGeofence
                      ? formatMessage(strings.alert.placeVal, {
                          VAL_NAME: item.placeData?.name
                        })
                      : formatMessage(strings.alert.dateVal, {
                          value: getMediumDateShortTime(item.datetime)
                        })}
                  </span>
                  <br />
                  <span>
                    {isGeofence
                      ? formatMessage(item.isEnteringAlert ? strings.alert.enterVal : strings.alert.exitVal, {
                          VAL_DATE: item.time ? getMediumDateShortTime(item.time * 1000) : ''
                        })
                      : item.speed == null
                      ? formatMessage(strings.short.noSpeedVal)
                      : formatMessage(isMetric.distance ? strings.alert.speedVal : strings.alert.speedMileVal, {
                          value: convert(isMetric, unitType.speed, item.speed)
                        })}
                  </span>
                  <br />
                  <span>
                    {isGeofence
                      ? item.isEnteringAlert
                        ? ''
                        : formatMessage(strings.alert.durationVal, {
                            VAL_TIME: formatMessage(...getGeofenceDurationString(item))
                          })
                      : item.alt == null
                      ? formatMessage(strings.short.noAltitudeVal)
                      : formatMessage(isMetric.distance ? strings.alert.altitudeVal : strings.alert.altitudeFeetVal, {
                          value: convert(isMetric, unitType.meterFeet, item.alt)
                        })}
                  </span>
                </div>
              </span>
            </List.Item>
          );
        }}
        locale={{ emptyText: formatMessage(strings.short.noData) }}
      />
    </>
  );
};

export default injectIntl(NotificationCentreHistory);
