import { defineMessages } from 'react-intl';

export const button = defineMessages({
  cancel: {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
    description: ''
  },
  confirmDelete: {
    id: 'button.confirmDelete',
    defaultMessage: 'Confirm delete',
    description: ''
  },
  delete: {
    id: 'button.delete',
    defaultMessage: 'Delete',
    description: ''
  },
  excludeZeroMileage: {
    id: 'button.excludeZeroMileage',
    defaultMessage: 'Exclude zero mileage',
    description: ''
  },
  health: {
    id: 'button.health',
    defaultMessage: 'Health',
    description: ''
  },
  hideWabcoPartners: {
    id: 'button.hideWabcoPartners',
    defaultMessage: 'Hide WABCO Partners',
    description: 'WABCo is a name do not translate'
  },
  ignoreNotifications: {
    id: 'button.ignoreNotifications',
    defaultMessage: 'Ignore these notifications',
    description: ''
  },
  liveMap: {
    id: 'button.liveMap',
    defaultMessage: 'Live map',
    description: 'Live is real time'
  },
  logOut: {
    id: 'button.logOut',
    defaultMessage: 'Log out',
    description: ''
  },
  markAsRead: {
    id: 'button.markAsRead',
    defaultMessage: 'Mark as read',
    description: ''
  },
  markAsUnread: {
    id: 'button.markAsUnread',
    defaultMessage: 'Mark as unread',
    description: ''
  },
  no: {
    id: 'button.no',
    defaultMessage: 'No',
    description: ''
  },
  notificationCentre: {
    id: 'button.notificationCentre',
    defaultMessage: 'Notification centre',
    description: ''
  },
  ok: {
    id: 'button.ok',
    defaultMessage: 'OK',
    description: ''
  },
  pickATrailer: {
    id: 'button.pickATrailer',
    defaultMessage: 'Pick a trailer',
    description: ''
  },
  refreshODR: {
    id: 'button.refreshODR',
    defaultMessage: 'Refresh ODR',
    description: ''
  },
  alreadyRefreshedODR: {
    id: 'button.alreadyRefreshedODR',
    defaultMessage:
      'An ODR file for this device has already been requested in the last 30 minutes, please wait and try again',
    description: 'ODR is a name do not translate'
  },
  sessionRenew: {
    id: 'button.sessionRenew',
    defaultMessage: 'Renew session',
    description: ''
  },
  settings: {
    id: 'button.settings',
    defaultMessage: 'Settings',
    description: ''
  },
  share: {
    id: 'button.share',
    defaultMessage: 'Share',
    description: ''
  },
  showOnHealthPage: {
    id: 'button.showOnHealthPage',
    defaultMessage: "Show on 'Health' page",
    description: ''
  },
  showOnLiveMapPage: {
    id: 'button.showOnLiveMapPage',
    defaultMessage: "Show on 'Live Map' page",
    description: 'Live is real time'
  },
  showOnTrailerIdCardPage: {
    id: 'button.showOnTrailerIdCardPage',
    defaultMessage: "Show on 'Trailer ID card' page",
    description: ''
  },
  showWabcoPartners: {
    id: 'button.showWabcoPartners',
    defaultMessage: 'Show WABCO partners',
    description: 'WABCO is a name do not translate'
  },
  timeMileage: {
    id: 'button.timeMileage',
    defaultMessage: 'Time / Mileage',
    description: ''
  },
  viewTrailerDetails: {
    id: 'button.viewTrailerDetails',
    defaultMessage: 'View trailer details',
    description: ''
  },
  yes: {
    id: 'button.yes',
    defaultMessage: 'Yes',
    description: ''
  },
  save: {
    id: 'button.save',
    defaultMessage: 'Save',
    description: 'button text to save user input'
  },
  subscriptionSettings: {
    id: 'button.subscriptionSettings',
    defaultMessage: 'Subscription settings',
    description: 'settings around subscriptions to email notifications'
  },
  signOut: {
    id: 'button.signOut',
    defaultMessage: 'Sign out'
  },
  manageYourAccount: {
    id: 'button.manageYourAccount',
    defaultMessage: 'Manage your account'
  },
  trailerSettings: {
    id: 'button.trailerSettings',
    defaultMessage: 'Trailer settings'
  },
  pageError: {
    id: 'button.pageError',
    defaultMessage: 'Reload this page'
  }
});
