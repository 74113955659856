import React from 'react';

import {
  AlertOutlined,
  AuditOutlined,
  CalculatorOutlined,
  CompassOutlined,
  FileTextOutlined,
  HeartOutlined,
  MonitorOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

import { Menu, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import paths from '../../app/paths';
import ReportListIcon from '../Icons/ReportListIcon';
import HeartIcon from '../Utils/CustomIcons';
import * as strings from '../../helpers/defaultStrings';
import './NavigationMenu.scss';
import { isIntegration, getIsShowPlaces } from '../../helpers/functions';
import IconPlaces from '../Icons/Places';
import { useSelector } from 'react-redux';
import { getisFFPlacesNotificationsPageEnabled, getDisableBrakeplusMap } from '../../app/feature-flags';

import { APP_VERSION } from '../../app/helpers/app-version';

const getMenuItems = (formatMessage, isIntegration, isMicrosoftCrap, productFeatures = {}) => [
  ...(productFeatures.showLiveMap
    ? [
        {
          icon: (
            <Link to={paths.liveMap}>
              <CompassOutlined />
            </Link>
          ),
          label: formatMessage(strings.navMenu.mapView),
          key: paths.liveMap
        }
      ]
    : []),
  ...(productFeatures.showTrailerHealth
    ? [
        {
          icon: <Link to={paths.health}>{isMicrosoftCrap ? <HeartOutlined /> : <HeartIcon />}</Link>,
          label: formatMessage(strings.navMenu.health),
          key: paths.health
        }
      ]
    : []),
  ...(productFeatures.showVehicleId
    ? [
        {
          icon: (
            <Link to={paths.trailers}>
              <AuditOutlined />
            </Link>
          ),
          label: formatMessage(strings.navMenu.trailerIdCard),
          key: paths.trailers
        }
      ]
    : []),
  ...(productFeatures.showNotification
    ? [
        {
          icon: (
            <Link to={paths.notifications}>
              <AlertOutlined />
            </Link>
          ),
          label: formatMessage(strings.short.notifications),
          key: paths.notifications
        }
      ]
    : []),
  ...(productFeatures.showEBPMS
    ? [
        {
          icon: (
            <Link to={paths.ebpms}>
              <MonitorOutlined />
            </Link>
          ),
          label: formatMessage(
            productFeatures.disableBrakeplusMap ? strings.pageTitle.brakePlusInsights : strings.pageTitle.brakePlusMap
          ),
          key: paths.ebpms
        },
        {
          icon: (
            <Link to={paths.reports}>
              <FileTextOutlined />
            </Link>
          ),
          label: formatMessage(strings.pageTitle.brakePlusReport),
          key: paths.reports
        }
      ]
    : []),
  ...(isIntegration
    ? [
        {
          icon: (
            <a href='https://odrsimulator.wabco.aloalto.com/' target='_blank' rel='noopener noreferrer'>
              <CalculatorOutlined />
            </a>
          ),
          label: formatMessage(strings.navMenu.simulator),
          key: 'odr-simulator'
        },
        {
          icon: (
            <a href='https://simulator.wabco-fleet.team/' target='_blank' rel='noopener noreferrer'>
              <CalculatorOutlined />
            </a>
          ),
          label: formatMessage(strings.navMenu.simulatorv2),
          key: 'odr-simulator-v2'
        }
      ]
    : []),
  ...(productFeatures.showEBPMS
    ? [
        {
          icon: (
            <Link to={paths.help}>
              <QuestionCircleOutlined />
            </Link>
          ),
          label: formatMessage(strings.navMenu.help),
          key: paths.help
        }
      ]
    : []),
  ...(productFeatures.showTrackTraceReports
    ? [
        {
          icon: (
            <Link to={paths.reportList}>
              <ReportListIcon />
            </Link>
          ),
          label: formatMessage(strings.navMenu.report),
          key: paths.reportList
        }
      ]
    : []),
  ...(productFeatures.showPlacesNotifications && getIsShowPlaces()
    ? [
        {
          icon: (
            <Link to={paths.placesAlarms}>
              <IconPlaces />
            </Link>
          ),
          label: formatMessage(strings.pageTitle.placesNotifications),
          key: paths.placesAlarms
        }
      ]
    : [])
];

const NavigationMenu = ({
  currentPath,
  productFeatures: {
    showEBPMS,
    showLiveMap,
    showTrailerHealth,
    showVehicleId,
    showNotification,
    showTrackTraceReports
  },
  intl: { formatMessage }
}) => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isMicrosoftBrowser = isIE || isEdge;
  const showPlacesNotifications = useSelector(getisFFPlacesNotificationsPageEnabled);
  const disableBrakeplusMap = useSelector(getDisableBrakeplusMap);

  return (
    <>
      <Menu
        className='navigation-menu'
        theme='light'
        mode='inline'
        selectedKeys={[currentPath]}
        items={getMenuItems(formatMessage, isIntegration(), isMicrosoftBrowser, {
          showEBPMS,
          showLiveMap,
          showTrailerHealth,
          showVehicleId,
          showNotification,
          showTrackTraceReports,
          showPlacesNotifications,
          disableBrakeplusMap
        })}
      />
      <Tag className='version-style'>{APP_VERSION}</Tag>
    </>
  );
};

NavigationMenu.propTypes = {};

export default injectIntl(NavigationMenu);
