export default {
  home: '/home',
  liveMap: '/live-map',
  liveMapV2: '/live-map-v2',
  liveMapV2Ids: '/live-map-v2/:selectedDevices',
  liveMapIds: '/live-map/:selectedDevices',
  safety: '/safety',
  health: '/health',
  settings: '/settings',
  healthDetails: '/health/:trailerId',
  loadingEvents: '/loading-events',
  trailers: '/trailer',
  trailerId: '/trailer/:trailerId',
  notifications: '/notifications',
  notificationsTab: '/notifications/:tabId',
  subscriptionSettings: '/notifications/subscriptionSettings',
  ebpms: '/ebpms',
  reports: '/reports',
  reportList: '/report-list',
  help: '/help',
  places: '/places',
  placesAlarms: '/places-alarms'
};
