import * as d3 from 'd3';
import textures from 'textures';
import Chart from '../chart';

import './TruckChart.scss';

export default class TruckChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg
      .append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.texture = textures.lines().lighter().stroke('grey');
    this.svg.call(this.texture);

    if (d3.select('.generic-tooltip').empty()) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'generic-tooltip')
        .style('opacity', 0)
        .style('left', 0)
        .style('top', 0)
        .style('pointer-events', 'none');
    } else {
      this.tooltip = d3.select('.generic-tooltip').style('opacity', 0);
    }

    this.lateralView = this.main.append('g').attr('class', 'lateralView');

    this.aerialView = this.main
      .append('g')
      .attr('class', 'aerialView')
      .attr('transform', `translate(0,${this.props.height * 0.55})`);

    this.rigidAxles = this.aerialView.append('g').attr('class', 'rigidAxles');

    this.connections = this.aerialView
      .append('g')
      .attr('class', 'connections')
      .attr('transform', `translate(0,${this.props.height * 0.55})`);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.data) {
      this.drawTruck(state.data);
    }
  }

  drawTruck(data) {
    const axleCount = data.axles.count;
    const lateralViewHeight = (this.props.height / 2) * 0.7;
    const remainingHeight = this.props.height / 2 - lateralViewHeight;
    const wheelRadius = remainingHeight / 2;

    this.lateralView.selectAll('*').interrupt();
    this.aerialView.selectAll('*').interrupt();
    this.lateralView.selectAll('*').remove();
    this.aerialView.selectAll('*').remove();

    this.lateralView
      .append('rect')
      .attr('class', 'trailer-box')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.props.width - 1.5)
      .attr('height', lateralViewHeight)
      .style('fill', this.texture.url())
      .style('stroke', 'grey')
      .style('stroke-width', 3)
      .style('outline-offset', 3);

    const axleLateral = this.lateralView.selectAll('.axle').data(data.axles.details);

    const axleLateralEnter = axleLateral.enter();

    let frontAxleCount;
    let backAxleCount;
    let offset = 0;
    let split = false;

    if (data.vehicleType === 'semi') {
      frontAxleCount = 0;
      backAxleCount = axleCount;
    } else if (data.vehicleType === 'centralAxle') {
      frontAxleCount = axleCount;
      backAxleCount = 0;
      offset = this.props.width / 2 - (axleCount * wheelRadius * 2.2) / 2;
    } else if (data.vehicleType === 'drawbar') {
      frontAxleCount = Math.floor(axleCount / 2);
      backAxleCount = Math.ceil(axleCount / 2);
      split = true;
    } else if (data.vehicleType === 'dolly') {
      frontAxleCount = axleCount;
      backAxleCount = 2;
    }

    const frontPositions = [...Array(frontAxleCount)].map((dummy, i) => wheelRadius * i * 2.2);
    const backPositions = [...Array(backAxleCount)].map(
      (dummy, i) => this.props.width - wheelRadius * (backAxleCount - i) * 2.2
    );
    const axlePosition = frontPositions.concat(backPositions).map(p => p + offset + wheelRadius * 1.1);
    const cdAxisIndex = data.axles.details.findIndex(a => a.Sensor === 1);
    const efAxisIndex = data.axles.details.findIndex(a => a.Sensor === 2);
    const multiGroup = split || data.absSystem === '4S/3M' || data.absSystem === '4S/2M+1M';

    const groups = data.axles.details.map((a, i) => {
      if (multiGroup) {
        if (
          efAxisIndex !== null &&
          ((efAxisIndex < cdAxisIndex && (i <= efAxisIndex || i < frontAxleCount)) ||
            (efAxisIndex > cdAxisIndex && i >= efAxisIndex))
        ) {
          return 'secondGroup';
        }
      }
      return 'mainGroup';
    });

    const [min, max] = d3.extent(
      data.axles.details.map((a, i) => (groups[i] === 'mainGroup' ? axlePosition[i] : null))
    );
    const mainGroupCenter = (min + max) / 2;
    const secondGroupCenter = efAxisIndex !== null ? axlePosition[efAxisIndex] : null;

    axleLateralEnter
      .append('rect')
      .attr('x', (d, i) => axlePosition[i] - wheelRadius * 1.1)
      .attr('y', lateralViewHeight + 2)
      .attr('width', wheelRadius * 2.2)
      .attr('height', wheelRadius * 0.9)
      .style('fill', 'grey');

    axleLateralEnter
      .append('circle')
      .attr('class', 'axle')
      .attr('cx', (d, i) => axlePosition[i])
      .attr('cy', lateralViewHeight + wheelRadius)
      .attr('r', wheelRadius * 0.95)
      .style('fill', 'black');

    axleLateralEnter
      .append('circle')
      .attr('class', 'axle')
      .attr('cx', (d, i) => axlePosition[i])
      .attr('cy', lateralViewHeight + wheelRadius)
      .attr('r', wheelRadius * 0.55)
      .style('fill', 'lightgrey');

    axleLateralEnter
      .append('circle')
      .attr('class', 'axle')
      .attr('cx', (d, i) => axlePosition[i])
      .attr('cy', lateralViewHeight + wheelRadius)
      .attr('r', wheelRadius * 0.2)
      .style('fill', '#0070b4')
      .style('opacity', d => (d.Sensor ? 1 : 0));

    this.aerialView
      .append('rect')
      .attr('class', 'trailer-box')
      .attr('x', 0)
      .attr('y', (this.props.height / 2) * 0.1)
      .attr('width', this.props.width - 1.5)
      .attr('height', (this.props.height / 2) * 0.6)
      .style('fill', 'none')
      .style('stroke', 'grey')
      .style('stroke-width', 3);

    const axleAerial = this.aerialView.selectAll('.axle').data(data.axles.details);

    const axleAerialEnter = axleAerial.enter();

    const axle = axleAerialEnter.append('g').attr('class', 'axle');

    if (data.independentWheelSuspension === 'rigidAxle') {
      axle
        .append('rect')
        .attr('class', 'axleConnector')
        .attr('x', (d, i) => axlePosition[i] - wheelRadius * 0.25)
        .attr('y', (this.props.height / 2) * 0.12)
        .attr('width', wheelRadius / 2)
        .attr('height', (this.props.height / 2) * 0.55)
        .style('fill', 'grey')
        .style('opacity', 0.2);
    }

    axle
      .append('text')
      .attr('class', 'label')
      .attr('x', (d, i) => axlePosition[i])
      .attr('y', 0)
      .style('text-anchor', 'middle')
      .style('font-size', 24)
      .style('font-family', 'Raleway')
      .text((d, i) => `${i + 1}`);

    const midHeight = this.props.height * 0.2;
    const ModulatorPos = {
      x: mainGroupCenter,
      y: midHeight
    };
    const xbsPos = {
      x: secondGroupCenter,
      y: midHeight
    };

    const modulatorHeight = this.props.height * 0.1;
    const modulatorWidth = modulatorHeight * 0.7;

    if (data.absSystem === '4S/2M+1M') {
      const shvCenter = (mainGroupCenter + secondGroupCenter) / 2;
      const shvWidth = modulatorHeight * 0.5;
      const shvHeight = modulatorHeight * 0.6;

      const shvPos = {
        x: shvCenter,
        y: midHeight
      };

      this.aerialView
        .append('path')
        .attr('class', 'shv')
        .style('fill', 'none')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2)
        .attr('d', this.getConnectionPath(shvPos, xbsPos));

      this.aerialView
        .append('path')
        .attr('class', 'shv')
        .style('fill', 'none')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2)
        .attr('d', this.getConnectionPath({ ...shvPos, y: midHeight - shvHeight / 2 }, ModulatorPos));

      this.aerialView
        .append('path')
        .attr('class', 'shv')
        .style('fill', 'none')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2)
        .attr('d', this.getConnectionPath({ ...shvPos, y: midHeight + shvHeight / 2 }, ModulatorPos));

      this.aerialView
        .append('rect')
        .attr('class', 'shv')
        .attr('x', shvCenter - shvWidth / 2)
        .attr('y', midHeight - shvHeight / 2)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', shvWidth)
        .attr('height', shvHeight)
        .style('fill', '#ebf4f9')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('circle')
        .attr('class', 'shv')
        .attr('cx', shvCenter)
        .attr('cy', midHeight)
        .attr('r', shvWidth * 0.2)
        .style('fill', '#ebf4f9')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('line')
        .attr('class', 'shv')
        .attr('x1', shvCenter - shvWidth * 0.4)
        .attr('y1', midHeight - midHeight * 0.02)
        .attr('x2', shvCenter)
        .attr('y2', midHeight - shvHeight * 0.4)
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('line')
        .attr('class', 'shv')
        .attr('x1', shvCenter)
        .attr('y1', midHeight - shvHeight * 0.4)
        .attr('x2', shvCenter + shvWidth * 0.4)
        .attr('y2', midHeight - midHeight * 0.02)
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('line')
        .attr('class', 'shv')
        .attr('x1', shvCenter + shvWidth * 0.4)
        .attr('y1', midHeight + midHeight * 0.02)
        .attr('x2', shvCenter)
        .attr('y2', midHeight + shvHeight * 0.4)
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('line')
        .attr('class', 'shv')
        .attr('x1', shvCenter)
        .attr('y1', midHeight + shvHeight * 0.4)
        .attr('x2', shvCenter - shvWidth * 0.4)
        .attr('y2', midHeight + midHeight * 0.02)
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);
    }

    axle
      .append('path')
      .attr('class', 'mainConnectionLeft')
      .style('fill', 'none')
      .style('stroke', '#0070b4')
      .style('stroke-width', 2)
      .attr('d', (d, i) => {
        const tyreLeft = {
          x: axlePosition[i],
          y: midHeight + this.props.height * 0.1
        };
        return this.getConnectionPath(tyreLeft, groups[i] === 'mainGroup' ? ModulatorPos : xbsPos);
      });

    axle
      .append('path')
      .attr('class', 'mainConnectionRight')
      .style('fill', 'none')
      .style('stroke', '#0070b4')
      .style('stroke-width', 2)
      .attr('d', (d, i) => {
        const tyreRight = {
          x: axlePosition[i],
          y: midHeight - this.props.height * 0.1
        };
        return this.getConnectionPath(tyreRight, groups[i] === 'mainGroup' ? ModulatorPos : xbsPos);
      });

    if (data.tyreChoice === 'singleTyres') {
      axle
        .append('rect')
        .attr('class', 'tyreRight')
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.1})`)
        .attr('x', -wheelRadius)
        .attr('y', -(this.props.height / 2) * 0.005)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.05)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');

      axle
        .append('rect')
        .attr('class', 'tyreLeft')
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.6})`)
        .attr('x', -wheelRadius)
        .attr('y', 0)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.05)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');
    } else {
      const tyreRight = axle
        .append('g')
        .attr('class', 'tyreRight')
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.1})`);

      tyreRight
        .append('rect')
        .attr('x', -wheelRadius)
        .attr('y', -(this.props.height / 2) * 0.02)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.03)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');

      tyreRight
        .append('rect')
        .attr('x', -wheelRadius)
        .attr('y', (this.props.height / 2) * 0.05)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.03)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');

      const tyreLeft = axle
        .append('g')
        .attr('class', 'tyreLeft')
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.6})`);

      tyreLeft
        .append('rect')
        .attr('x', -wheelRadius)
        .attr('y', -(this.props.height / 2) * 0.02)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.03)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');

      tyreLeft
        .append('rect')
        .attr('x', -wheelRadius)
        .attr('y', (this.props.height / 2) * 0.05)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.03)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black');
    }

    if (data.vehicleType === 'dolly') {
      this.main.selectAll('.trailer-box').style('opacity', 0.1);

      axleLateralEnter
        .append('circle')
        .attr('class', 'axle')
        .attr('cx', (d, i) => axlePosition[axleCount + i])
        .attr('cy', lateralViewHeight + wheelRadius)
        .attr('r', wheelRadius * 0.95)
        .style('fill', 'black')
        .style('opacity', 0.1);

      axle
        .append('rect')
        .attr('class', 'tyreRight')
        .attr('transform', (d, i) => `translate(${axlePosition[axleCount + i]}, ${(this.props.height / 2) * 0.1})`)
        .attr('x', -wheelRadius)
        .attr('y', -(this.props.height / 2) * 0.005)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.05)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black')
        .style('opacity', 0.1);

      axle
        .append('rect')
        .attr('class', 'tyreLeft')
        .attr('transform', (d, i) => `translate(${axlePosition[axleCount + i]}, ${(this.props.height / 2) * 0.6})`)
        .attr('x', -wheelRadius)
        .attr('y', 0)
        .attr('width', wheelRadius * 2)
        .attr('height', this.props.height * 0.05)
        .attr('rx', this.props.height * 0.01)
        .attr('ry', this.props.height * 0.01)
        .style('fill', 'black')
        .style('opacity', 0.1);
    }

    const getSensorLabel = (sensor, position) => {
      if (sensor === 1) {
        // C-D Axle
        if (
          (position === 'left' && data.modulatorDirection === 'directionOfTravel') ||
          (position === 'right' && data.modulatorDirection !== 'directionOfTravel')
        ) {
          return 'C';
        }
        if (
          (position === 'left' && data.modulatorDirection !== 'directionOfTravel') ||
          (position === 'right' && data.modulatorDirection === 'directionOfTravel')
        ) {
          return 'D';
        }
      }
      if (sensor === 2) {
        // E-F Axle
        if (
          (position === 'left' && data.modulatorDirection === 'directionOfTravel') ||
          (position === 'right' && data.modulatorDirection !== 'directionOfTravel')
        ) {
          return 'E';
        }
        if (
          (position === 'left' && data.modulatorDirection !== 'directionOfTravel') ||
          (position === 'right' && data.modulatorDirection === 'directionOfTravel')
        ) {
          return 'F';
        }
      }
      return '';
    };

    axle
      .append('circle')
      .attr('class', 'sensorLabelBack')
      .attr('cx', (d, i) => axlePosition[i])
      .attr('cy', this.props.height * 0.07)
      .style('r', d => (d.Sensor ? 10 : 0))
      .style('fill', '#0070b4');

    axle
      .append('text')
      .attr('class', 'sensorLabel')
      .attr('x', (d, i) => axlePosition[i])
      .attr('y', this.props.height * 0.085)
      .style('text-anchor', 'middle')
      .style('font-size', 14)
      .style('font-weight', 600)
      .style('font-family', 'Raleway')
      .style('fill', 'white')
      .text(d => getSensorLabel(d.Sensor, 'right'));

    axle
      .append('circle')
      .attr('class', 'sensorLabelBack')
      .attr('cx', (d, i) => axlePosition[i])
      .attr('cy', this.props.height * 0.325)
      .style('r', d => (d.Sensor ? 10 : 0))
      .style('fill', '#0070b4');

    axle
      .append('text')
      .attr('class', 'sensorLabel')
      .attr('x', (d, i) => axlePosition[i])
      .attr('y', this.props.height * 0.34)
      .style('text-anchor', 'middle')
      .style('font-size', 14)
      .style('font-weight', 600)
      .style('font-family', 'Raleway')
      .style('fill', 'white')
      .text(d => getSensorLabel(d.Sensor, 'left'));

    this.aerialView
      .append('rect')
      .attr('class', 'modulator')
      .attr('x', mainGroupCenter - modulatorWidth / 2)
      .attr('y', this.props.height * 0.05 + (this.props.height * 0.3 - modulatorHeight) / 2)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', modulatorWidth)
      .attr('height', modulatorHeight)
      .style('fill', '#ebf4f9')
      .style('stroke', '#0070b4')
      .style('stroke-width', 2);

    this.aerialView
      .append('path')
      .attr('d', d3.symbol().type(d3.symbolTriangle))
      .attr('fill', '#0070b4')
      .attr(
        'transform',
        `translate(${mainGroupCenter}, ${this.props.height * 0.2}) rotate(${
          data.modulatorDirection === 'directionOfTravel' ? -90 : 90
        })`
      );

    if (multiGroup) {
      const xbsSize = modulatorHeight * 0.5;
      this.aerialView
        .append('rect')
        .attr('class', 'xbs')
        .attr('x', secondGroupCenter - xbsSize / 2)
        .attr('y', midHeight - xbsSize / 2)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', xbsSize)
        .attr('height', xbsSize)
        .style('fill', '#ebf4f9')
        .style('stroke', '#0070b4')
        .style('stroke-width', 2);

      this.aerialView
        .append('text')
        .attr('class', 'xbsText')
        .attr('x', secondGroupCenter)
        .attr('y', midHeight + 5)
        .attr('fill', '#0070b4')
        .style('text-anchor', 'middle')
        .style('font-size', 14)
        .style('font-weight', 600)
        .style('font-family', 'Raleway')
        .text(data.absSystem === '4S/2M+1M' ? 'A' : 'E');
    }

    const animateLift = () => {
      this.lateralView
        .selectAll('.axle')
        .filter(d => d.Lift_axle > 0)
        .transition()
        .duration(1000)
        .attr('cy', lateralViewHeight + wheelRadius / 2)
        .transition()
        .duration(1000)
        .attr('cy', lateralViewHeight + wheelRadius)
        .on('end', animateLift);
    };

    const animateSteering = () => {
      this.aerialView
        .selectAll('.tyreRight')
        // .filter(d => d.Lift_axle === 1)
        .transition()
        .duration(1000)
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.1}) rotate(-15)`)
        .transition()
        .duration(1000)
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.1}) rotate(15)`);

      this.aerialView
        .selectAll('.tyreLeft')
        // .filter(d => d.Lift_axle === 1)
        .transition()
        .duration(1000)
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.6}) rotate(-15)`)
        .transition()
        .duration(1000)
        .attr('transform', (d, i) => `translate(${axlePosition[i]}, ${(this.props.height / 2) * 0.6}) rotate(15)`)
        .on('end', animateSteering);
    };

    animateLift();
    if (data.steeringAxle === 'steeringAxle') {
      animateSteering();
    }
  }

  getConnectionPath(a, b) {
    const bendingPos = (a.y + b.y) / 2;
    const data = [a, { x: a.x, y: bendingPos }, { x: b.x, y: bendingPos }, b];

    return d3
      .line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(d3.curveBasis)(data);
  }
}
