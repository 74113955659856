import React, { Fragment } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserMenu from '../UserMenu/UserMenu';
import NotificationPanel from '../Notifications/NotificationPanel';
import IconMenu from '../Icons/IconMenu';
import AppSwitcher from '../AppSwitcher/AppSwitcher';
import zfLogo from '../../assets/images/ZFLogo.png';
import TIPlogo from '../../assets/images/TIPlogo.jpg';
import ZFScalarLogo from '../../assets/images/ZFScalarLogo.svg';
import * as strings from '../../helpers/defaultStrings';
import './HeaderMenu.scss';
import { getUserInitials, isScalar } from '../../helpers/functions';
import gravatar from 'gravatar';
import { selectProductFeatures } from '../../selectors';

const { Header } = Layout;

const MenuButton = ({ onClick }) => (
  <button className='trigger' onClick={onClick}>
    <IconMenu />
  </button>
);

const HeaderMenu = ({ collapsed, toggleCollapse, formatMessage, user }) => {
  const imageImports = {
    default: zfLogo,
    tip: TIPlogo
  };

  const logoImage = useSelector(selectProductFeatures).logoImage;
  const auth0User = useSelector(store => store.auth.auth0User);
  const companyLogo = isScalar() ? ZFScalarLogo : logoImage ? imageImports[logoImage] : null;
  const name = user?.name?.givenName || user?.name;

  const initials = getUserInitials(name);
  const avatarSrc =
    auth0User?.picture ||
    gravatar.url(user?.emails?.[0], {
      protocol: 'https',
      s: '480',
      r: 'x',
      d: `cdn.auth0.com/avatars/${initials}.png`
    });

  const isScalarUser = isScalar();

  return (
    <Header className='layout-header'>
      {user && (
        <Fragment>
          <div className='header-menu-left'>
            <MenuButton onClick={toggleCollapse} />
            <Link to='/' className={`sidebar-logo ${collapsed ? 'sidebar-logo--collapsed' : ''}`}>
              {companyLogo && <img src={companyLogo} alt='logo' />}
            </Link>
            <h2 className='layout-header__title'>{formatMessage(strings.short.trailer)}</h2>
          </div>
          <div className='header-menu-right'>
            <NotificationPanel formatMessage={formatMessage} />
            {isScalarUser && <AppSwitcher avatarSrc={avatarSrc} />}
            <UserMenu formatMessage={formatMessage} avatarSrc={avatarSrc} />
          </div>
        </Fragment>
      )}
    </Header>
  );
};

export default HeaderMenu;
