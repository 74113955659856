import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { convert, unitType } from '../../helpers/unitConverter';
import * as strings from '../../helpers/defaultStrings';
import { getGeofenceDurationString, getMediumDateShortTime } from '../../helpers/functions';
import './AlertDescription.scss';
import { getIsTypeGeofence } from '../../helpers/alarms/functions';

const AlertDescription = ({ alert, intl: { formatMessage } }) => {
  const isMetric = useSelector(store => store.auth.isMetric);
  const isGeofence = getIsTypeGeofence(alert);

  const ListItems = isGeofence
    ? [
        { title: strings.short.place, value: alert.placeData?.name },
        { title: strings.short.enter, value: alert.time ? getMediumDateShortTime(alert.time * 1000) : '' },
        { title: strings.short.duration, value: formatMessage(...getGeofenceDurationString(alert)) }
      ]
    : [
        { title: strings.short.date, value: getMediumDateShortTime(alert.datetime) },
        {
          title: strings.short.speed,
          value: formatMessage(isMetric.distance ? strings.short.kmSpeedVal : strings.short.mileSpeedVal, {
            value:
              alert.speed == null
                ? formatMessage(strings.short.unknown)
                : convert(isMetric, unitType.speed, alert.speed)
          })
        },
        {
          title: strings.short.altitude,
          value: formatMessage(isMetric.distance ? strings.short.metersVal : strings.short.feetVal, {
            value:
              alert.alt == null
                ? formatMessage(strings.short.unknown)
                : convert(isMetric, unitType.meterFeet, alert.alt)
          })
        }
      ];

  if (alert.isEnteringAlert) ListItems.pop();

  return (
    <div className='event-drawer-context-alert trailer-history-description'>
      <span className='event-drawer-context-alert__title'>{alert.display}</span>
      <span className='event-drawer-context-alert__info'>{alert.wheelInfo}</span>
      <div className='event-drawer-context-alert__details'>
        {ListItems.map(({ title, value }, index) => (
          <div key={`event-drawer-alert-description-${index}`} className={'event-drawer-context-alert__details__item'}>
            <strong>{formatMessage(title)}:</strong> {value}
          </div>
        ))}
      </div>
    </div>
  );
};

AlertDescription.propTypes = {};

export default injectIntl(AlertDescription);
