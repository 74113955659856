import React from 'react';

const WheelEnd = () => (
  <svg width='152' height='152' viewBox='0 0 152 152' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M75.8964 0.532593C34.4179 0.532593 0.480957 34.4701 0.480957 75.9493C0.480957 117.428 34.4179 151.366 75.8964 151.366C117.375 151.366 151.312 117.428 151.312 75.9493C151.312 34.4701 117.375 0.532593 75.8964 0.532593ZM68.3549 16.1462V30.6993V38.2409H83.438V30.6993V16.1462C99.8641 18.2234 114.258 26.9536 123.856 39.5519L111.395 46.74L104.87 50.5109L112.411 63.5762L118.951 59.8054L131.486 52.5583C134.542 59.762 136.229 67.665 136.229 75.9493C136.229 84.2336 134.542 92.1366 131.486 99.3402L119.054 92.1668L112.529 88.396L104.987 101.461L111.513 105.232L123.856 112.347C114.258 124.945 99.8641 133.675 83.438 135.752V121.199V113.658H68.3549V121.199V135.752C51.9247 133.675 37.5345 124.935 27.9369 112.332L40.5012 105.085L47.0412 101.314L39.4996 88.2634L32.9597 92.0342L20.307 99.3402C17.2513 92.1366 15.5641 84.2336 15.5641 75.9493C15.5641 67.665 17.2513 59.762 20.307 52.5583L32.7977 59.7759L39.3376 63.5468L46.8792 50.4814L40.3392 46.7106L27.9369 39.5519C37.5345 26.9536 51.9288 18.2234 68.3549 16.1462ZM75.8964 53.3243C63.4905 53.3243 53.2718 63.5431 53.2718 75.9493C53.2718 88.3554 63.4905 98.5743 75.8964 98.5743C88.3024 98.5743 98.5211 88.3554 98.5211 75.9493C98.5211 63.5431 88.3024 53.3243 75.8964 53.3243ZM75.8964 68.4076C80.1508 68.4076 83.438 71.6948 83.438 75.9493C83.438 80.2037 80.1508 83.4909 75.8964 83.4909C71.642 83.4909 68.3549 80.2037 68.3549 75.9493C68.3549 71.6948 71.642 68.4076 75.8964 68.4076Z'
      fill='black'
    />
  </svg>
);

export default WheelEnd;
