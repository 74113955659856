import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ScoreChart from './ScoreChart';

function getChartState(props) {
  return {
    ...props
  };
}

class EventSummaryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = getChartState(nextProps);
    if (chart) {
      chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state;
    if (chart) {
      chart.destroy();
    }

    const { width } = this.props;
    const elWidth = width || 400;
    const elHeight = 200;

    const margin = {
      top: 10,
      right: 30,
      bottom: 60,
      left: 20
    };

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new ScoreChart(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(getChartState(this.props));
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return <div ref='chart' />;
  }
}

export default EventSummaryChartComponent;
