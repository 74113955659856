import actionTypes from '../actions/actionTypes';

export const initialSettingsState = {
  items: {
    trailerInfoComponents: {},
    fcfCustomization: {
      title: 'FCF',
      options: {}
    },
    displayTrailers: [],
    displayTrailersEBPMS: []
  },
  error: null,
  processing: false,
  loaded: false
};

export default function userReducer(state = initialSettingsState, action) {
  switch (action.type) {
    case actionTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        items: null,
        error: null,
        processing: true,
        loaded: false
      };
    case actionTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        items: action.settings,
        processing: false,
        loaded: true
      };
    case actionTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        items: action.settings,
        processing: false,
        error: action.error,
        loaded: false
      };
    case actionTypes.SAVE_SETTING_REQUEST:
      return state;
    case actionTypes.SAVE_SETTING_SUCCESS:
      return {
        ...state,
        items: action.settings,
        error: null
      };
    case actionTypes.UPLOAD_SETTING_SUCCESS:
      return {
        ...state,
        processing: false,
        error: null
      };
    case actionTypes.UPLOAD_SETTING_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error
      };

    default:
      return state;
  }
}
