import React from 'react';
import ColumnInfoModal from '../../../Components/Utils/ColumnInfoModal';
import { RecentSummaryInfoModal } from '../../../Components/LiveMapV2/right/components/Columns/ColumnComponents/infoModal/RecentSummaryInfoModal';
import { LightMonitoringModal } from '../../../Components/LiveMapV2/right/components/Columns/ColumnComponents/infoModal/LightMonitoringModal';
import { GohcInfoModal } from '../../../Components/LiveMapV2/right/components/Columns/ColumnComponents/infoModal/GohcInfoModal';
import WheelLayoutInfoToolTip from '../../../Components/TrailerMap/WheelLayoutInfoToolTip';
import * as strings from '../../../helpers/defaultStrings';
import { COLUMNS } from '../../../helpers/constants';
import { useIntl } from 'react-intl';
import ScoreDoc from '../../../Components/Score/ScoreDoc';

const { TIRES, GOHC, RECENT_SUMMARY, DURATION_SCORE, FREQUENCY_SCORE, RECENCY_SCORE, LIGHT_MONITORING } = COLUMNS;

const getDetailsMap = ({ columnKey, isMetric, devices }) => {
  const scoreDocProps = { isMetric, devices, columnKey };

  return {
    [TIRES]: {
      title: strings.short.tpms,
      body: <WheelLayoutInfoToolTip />
    },
    [GOHC]: {
      title: strings.short.gohc,
      body: <GohcInfoModal />
    },
    [RECENT_SUMMARY]: {
      title: strings.phrase.recentSummary,
      body: <RecentSummaryInfoModal isMetric={isMetric} />
    },
    [DURATION_SCORE]: {
      title: strings.columns.durationScore,
      body: <ScoreDoc {...scoreDocProps} />
    },
    [FREQUENCY_SCORE]: {
      title: strings.columns.frequencyScore,
      body: <ScoreDoc {...scoreDocProps} />
    },
    [RECENCY_SCORE]: {
      title: strings.columns.recencyScore,
      body: <ScoreDoc {...scoreDocProps} />
    },
    [LIGHT_MONITORING]: {
      title: strings.columns.lightMonitoring,
      body: <LightMonitoringModal />
    }
  };
};

export const InfoHeader = ({ columnKey, isMetric, devices, hasSibling }) => {
  const detailsMap = getDetailsMap({ columnKey, isMetric, devices });
  if (!(columnKey in detailsMap)) {
    return null;
  }

  const { title, body } = detailsMap[columnKey];
  const { formatMessage } = useIntl();
  const props = {
    hasSibling,
    title: formatMessage(title),
    body
  };

  return <ColumnInfoModal {...props} />;
};
