export const ENV_CONFIG = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_SCALAR_ENV: process.env.REACT_APP_SCALAR_ENV,
  REACT_APP_IS_SCALAR: process.env.REACT_APP_IS_SCALAR,
  REACT_APP_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  REACT_APP_ODYSSEY_AUTH_CLIENTID: process.env.REACT_APP_ODYSSEY_AUTH_CLIENTID,
  REACT_APP_ODYSSEY_MY_ACCOUNT: process.env.REACT_APP_ODYSSEY_MY_ACCOUNT,
  REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE,
  REACT_APP_GEO_GATEWAY: process.env.REACT_APP_GEO_GATEWAY,
  REACT_APP_ODYSSEY_ADMIN_DOMAIN: process.env.REACT_APP_ODYSSEY_ADMIN_DOMAIN,
  REACT_APP_AUTH_REDIRECT_URL_QA: process.env.REACT_APP_AUTH_REDIRECT_URL_QA,
  REACT_APP_AUTH_REDIRECT_URL_FT: process.env.REACT_APP_AUTH_REDIRECT_URL_FT,
  REACT_APP_AUTH_REDIRECT_URL_PA: process.env.REACT_APP_AUTH_REDIRECT_URL_PA,
  REACT_APP_AUTH_REDIRECT_URL_PB: process.env.REACT_APP_AUTH_REDIRECT_URL_PB,
  REACT_APP_AUTH_REDIRECT_URL_FCS: process.env.REACT_APP_AUTH_REDIRECT_URL_FCS,
  REACT_APP_HELP_BASE_URL: process.env.REACT_APP_HELP_BASE_URL,
  REACT_APP_HELP_URL: process.env.REACT_APP_HELP_URL,
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  REACT_APP_MAXBOX_STYLE: process.env.REACT_APP_MAXBOX_STYLE,
  REACT_APP_MAX_DEVICES_DATA: process.env.REACT_APP_MAX_DEVICES_DATA,
  REACT_APP_MAP_MATCHING_ENABLE: process.env.REACT_APP_MAP_MATCHING_ENABLE,
  REACT_APP_MAP_MATCHING_ZOOM_LEVEL: process.env.REACT_APP_MAP_MATCHING_ZOOM_LEVEL,
  REACT_APP_MAP_MATCHING_CONFIDENCE_THRESHOLD: process.env.REACT_APP_MAP_MATCHING_CONFIDENCE_THRESHOLD,
  REACT_APP_MAP_MATCHING_MAX_DATA_REQUEST: process.env.REACT_APP_MAP_MATCHING_MAX_DATA_REQUEST,
  REACT_APP_MAP_MATCHING_MAX_DATA_RENDER: process.env.REACT_APP_MAP_MATCHING_MAX_DATA_RENDER,
  REACT_APP_NOTIFICATIONS_ALERT_GROUP_TYPE: process.env.REACT_APP_NOTIFICATIONS_ALERT_GROUP_TYPE,
  REACT_APP_NOTIFICATIONS_MAX_PER_TYPE: process.env.REACT_APP_NOTIFICATIONS_MAX_PER_TYPE,
  REACT_APP_NOTIFICATIONS_API_OLDEST: process.env.REACT_APP_NOTIFICATIONS_API_OLDEST,
  REACT_APP_HEAD_ANIMATION_ENABLE: process.env.REACT_APP_HEAD_ANIMATION_ENABLE,
  REACT_APP_HEAD_ANIMATION_MAX_DEVICES: process.env.REACT_APP_HEAD_ANIMATION_MAX_DEVICES,
  REACT_APP_MQQT_MIN_PROCESSING_INTERVAL: process.env.REACT_APP_MQQT_MIN_PROCESSING_INTERVAL,
  REACT_APP_ANIMATED_HEAD_MAX_INTERVAL: process.env.REACT_APP_ANIMATED_HEAD_MAX_INTERVAL,
  REACT_APP_CONSIDERED_POWERED_DOWN_INTERVAL_MINS: process.env.REACT_APP_CONSIDERED_POWERED_DOWN_INTERVAL_MINS,
  REACT_APP_AWS_IOT_PUB_SUB_ENDPOINT: process.env.REACT_APP_AWS_IOT_PUB_SUB_ENDPOINT,
  REACT_APP_GOOGLE_ANALYTICS_CODE: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE,
  NODE_ENV: process.env.NODE_ENV,
  // https://create-react-app.dev/docs/using-the-public-folder/
  PUBLIC_URL: process.env.PUBLIC_URL,
  REACT_APP_IDENTITY_POOL: process.env.REACT_APP_IDENTITY_POOL,
  REACT_APP_API_GATEWAY_TRAILERFIT: process.env.REACT_APP_API_GATEWAY_TRAILERFIT,
  REACT_APP_API_GATEWAY_EBPMS: process.env.REACT_APP_API_GATEWAY_EBPMS,
  REACT_APP_CI_COMMIT_BRANCH: process.env.REACT_APP_CI_COMMIT_BRANCH,
  REACT_APP_CI_COMMIT_SHORT_SHA: process.env.REACT_APP_CI_COMMIT_SHORT_SHA
};
