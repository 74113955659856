import actionTypes from './actionTypes';
import { getAxleCount } from '../actions/ActionResources/cleanerFunctions';
import { formatGroteStatusTag } from '../helpers/grote';

function updateMapViewState(viewState, firstShow, callBack) {
  return {
    type: actionTypes.UPDATE_MAP_VIEW_STATE,
    viewState,
    firstShow,
    callBack
  };
}

export function updateSelectedTnTTrailer(selectedTnTTrailer, callBack) {
  return {
    type: actionTypes.UPDATE_SELECTED_TNT_TRAILER,
    selectedTnTTrailer: selectedTnTTrailer && {
      ...selectedTnTTrailer,
      axles: getAxleCount(selectedTnTTrailer),
      lightMonitoring: formatGroteStatusTag(selectedTnTTrailer?.lastValidValues?.grote)
    },
    callBack
  };
}

function updateTnTTrailerTablePage(tnTTrailerTablePage, callBack) {
  return {
    type: actionTypes.UPDATE_TNT_TRAILER_TABLE_PAGE,
    tnTTrailerTablePage,
    callBack
  };
}

function updateSelectedHealthTrailer(selectedHealthTrailer, callBack) {
  return {
    type: actionTypes.UPDATE_SELECTED_HEALTH_TRAILER,
    selectedHealthTrailer,
    callBack
  };
}

export default {
  updateMapViewState,
  updateSelectedTnTTrailer,
  updateSelectedHealthTrailer,
  updateTnTTrailerTablePage
};
