import { push } from 'connected-react-router';

import { Logger } from '../../../app/helpers/logger';
import { loginSuccess } from '../../auth';
import { fetchFeatureFlags } from '../fetch-feature-flags';
import { PRODUCT_FEATURES } from '../../../helpers/constants';
import { isScalar } from '../../../helpers/functions';
import { waitForFeatureFlags } from '../pubsub/helper';

const { TRACKTRACE, EBPMS } = PRODUCT_FEATURES;

export const onLoginSuccess =
  (user, doNotRedirect = false) =>
  async (dispatch, getState) => {
    dispatch(fetchFeatureFlags());

    try {
      await waitForFeatureFlags(getState);
    } catch (err) {
      Logger.error('waitForFeatureFlags', err);
      // report the error and continue business as usual, assuming the feature flag as false.
      // but not a blocker for the app to continue.
    }

    dispatch(loginSuccess(user));
    Logger.debug('onLoginSuccess:', user);
    if (!doNotRedirect) {
      if (!user.features.includes(TRACKTRACE) && user.features.includes(EBPMS)) {
        dispatch(push('/ebpms'));
      } else if (!isScalar()) {
        dispatch(push('/'));
      }
    }
  };
