import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleOutlined } from '@ant-design/icons';
import { Drawer, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import AlertMapContext from './AlertMapContext';
import EventDescription from './EventDescription';
import TripDescription from './TripDescription';
import AlertDescription from './AlertDescription';
import LoadingEventDescription from './LoadingEventDescription';
import actions from '../../actions';
import * as strings from '../../helpers/defaultStrings';
import './EventDrawerContext.scss';
import { mobileOnly } from '../../helpers/responsive';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import TrailerInfoPanel from '../TrailerMap/TrailerInfoPanel';
import { trailerInfoPanel as trailerInfoPanelConstant, googleMaps } from '../../helpers/constants';
import moment from 'moment';

const EventDrawerContextInfoPanel = ({
  trailer = {},
  event,
  trip,
  alert,
  loadingEvent,
  tripEvents,
  resetSelection,
  intl: { formatMessage }
}) => {
  const dispatch = useCallback(useDispatch(), []);
  const { data: trailerHistory, processing } = useSelector(store => store.devices.contextHistory);
  const selectedTnTTrailer = useSelector(store => store.appState.selectedTnTTrailer);

  const [visible, setVisible] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [pos, setPos] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hours, setHours] = useState(null);
  const [followTrailer, setFollowTrailer] = useState(false);
  const [resizeChart, setResizeChart] = useState(Math.random());

  useEffect(() => {
    const getTrailerContextHistory = (assetId, start, duration) => {
      let startDateInSeconds;
      let endDateInSeconds;

      // WARNING duration is prior the endDateInSeconds, the time window then correspond to [(endDateInSeconds - duration), endDateInSeconds]
      if (duration) {
        // Range is specified like in case of a trip, so only limiting data to this range
        endDateInSeconds = moment(start).add(duration, 'minutes').valueOf() / 1000;
        startDateInSeconds = endDateInSeconds - 60 * duration;
      } else {
        // Just a timestamp specified like in the case of an event, so limiting data to x hours before and after
        let contextHours = 6;
        endDateInSeconds =
          moment(start)
            .add(contextHours / 2, 'hours')
            .valueOf() / 1000;
        startDateInSeconds = endDateInSeconds - 3600 * contextHours;
      }

      if (endDateInSeconds * 1000 > new Date().getTime()) {
        endDateInSeconds = Date.now() / 1000;
      }
      setVisible(true);
      setStartDate(startDateInSeconds);
      setEndDate(endDateInSeconds);
      setHours(Math.round((endDateInSeconds - startDateInSeconds) / 60 / 60));
      setPos({ assetId, time: moment(start).toDate().getTime(), startTime: start / 1000 });

      dispatch(actions.trailerHistory.getTrailerContextHistory(assetId, start, duration));
      dispatch(actions.trailerHistory.getTrailerLastTPMS(assetId, startDateInSeconds, endDateInSeconds));
    };

    if (visible && !loaded) {
      if (event && !event.notification && event.GPSPosition_latitude && event.GPSPosition_longitude) {
        getTrailerContextHistory(selectedTnTTrailer.assetId, event.timestamp);
      } else if (event && event.notification) {
        getTrailerContextHistory(event.assetId, event.timestamp);
      } else if (loadingEvent) {
        getTrailerContextHistory(selectedTnTTrailer.assetId, loadingEvent.timestampSearch, loadingEvent.durationSearch);
      } else if (trip) {
        getTrailerContextHistory(selectedTnTTrailer.assetId, trip.timestamp, trip.duration);
      }
      if (alert) {
        getTrailerContextHistory(alert.assetId, alert.timestamp);
      }

      setLoaded(true);
    }
  }, [event, alert, trip, loadingEvent, visible, loaded, dispatch, selectedTnTTrailer]);

  useEffect(() => {
    dispatch(actions.settings.getSettings());
  }, []);

  const onClose = () => {
    setVisible(false);
    resetSelection();
  };

  const googleMapsUrl = event => {
    if (!event) return null;
    const { GPSPosition_longitude: lon, GPSPosition_latitude: lat } = event;
    if (lon && lat) {
      return `${googleMaps.webURL}?q=${lat},${lon}&t=k`;
    }
    return null;
  };

  const isMobile = mobileOnly.matches;

  const mapUrl = googleMapsUrl(event);
  return (
    <Drawer
      mask={!isMobile}
      width={'90%'}
      className='trailer-event-context-drawer'
      // eslint-disable-next-line react/jsx-one-expression-per-line
      title={
        <div>
          {loadingEvent ? formatMessage(strings.phrase.tripContext) : formatMessage(strings.phrase.eventContext)}
          {mapUrl && (
            <Tooltip title={formatMessage(strings.phrase.openInGoogleMaps)} className='google-icon'>
              <a href={mapUrl} target='_blank' rel='noopener noreferrer'>
                <GoogleOutlined />
              </a>
            </Tooltip>
          )}
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: 0, height: 'calc(100% - 56px)' }}
    >
      {(alert || event || trip || loadingEvent) && !processing && (
        <ReflexContainer orientation='vertical' className={'trailer-event-context-drawer__panels'}>
          <ReflexElement className={'trailer-event-context-drawer__map-container'}>
            {event && <EventDescription event={event} />}
            {trip && <TripDescription trip={trip} />}
            {alert && <AlertDescription alert={alert} />}
            {loadingEvent && <LoadingEventDescription loadingEvent={loadingEvent} />}
            <AlertMapContext
              trailer={selectedTnTTrailer}
              event={event}
              trailerHistory={trailerHistory}
              pos={pos}
              followTrailer={followTrailer}
              alerts={alert ? [alert] : null}
              tripEvents={tripEvents}
            />
          </ReflexElement>
          <ReflexSplitter />
          <ReflexElement onStopResize={() => setResizeChart(Math.random())}>
            {selectedTnTTrailer && (
              <TrailerInfoPanel
                key={resizeChart}
                resizeChart={resizeChart}
                onHistoryPositionChange={setPos}
                alerts={alert ? [alert] : null}
                toggleFollowTrailer={setFollowTrailer}
                showLivemapHealthButtons={false}
                showSettingsButton={false}
                showBackButton={false}
                showTimeSelection={false}
                limitStartDate={startDate}
                limitEndDate={endDate}
                limitHours={hours}
                currentPos={pos}
                currentTrailer={trailer}
                overrideComponentSettings={trailerInfoPanelConstant.eventDrawerContextDefaultComponents}
              />
            )}
          </ReflexElement>
        </ReflexContainer>
      )}
    </Drawer>
  );
};

export default injectIntl(EventDrawerContextInfoPanel);
