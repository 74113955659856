import actionTypes from '../actions/actionTypes';

const initialState = {
  trailer: {
    item: null,
    processing: false,
    processingTrips: false,
    processingEvents: false,
    processingDtc: false,
    error: null
  },
  trailersDefaultDisplayNames: {},
  trailers: { allItems: null, items: null, processing: false, error: null, done: false },
  trailerEventHistory: { items: null, processing: false, error: null, done: false },
  trailerLocationDetails: { items: {}, processing: false, error: null },
  odrRefresh: { item: null, processing: false, error: null },
  installationDetails: { item: null, processing: false, error: null },
  rfDetails: { item: null, processing: false, error: null },
  weeklyEvolution: { items: null, processing: false, error: null },
  trailerSelectedWheels: {
    trailerAssetId: null,
    selectedWheels: {}
  }
};

export default function trailersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TRAILERS_REQUEST: {
      return {
        ...state,
        trailers: { items: null, processing: true, error: null, done: false }
      };
    }
    case actionTypes.GET_TRAILERS_FAILURE: {
      return {
        ...state,
        trailers: { items: null, processing: false, error: action.error, done: false }
      };
    }
    case actionTypes.UPDATE_TRAILERS_FROM_DEVICES: {
      return {
        ...state,
        trailers: action.trailers,
        trailersDefaultDisplayNames: action.trailersDefaultDisplayNames,
        weeklyEvolution: action.weeklyEvolution
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_SUCCESS: {
      let listTrailer = null;
      if (state.trailers.items && action.trailer) {
        listTrailer = state.trailers.items.find(trailer => trailer.assetId === action.trailer.assetId);
      }

      return {
        ...state,
        trailer: {
          item: action.trailer,
          listTrailer: listTrailer,
          processing: true,
          processingTrips: true,
          processingEvents: true,
          processingDtc: true,
          error: null
        }
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_REQUEST: {
      return {
        ...state,
        trailer: {
          item: null,
          listTrailer: null,
          processing: true,
          processingTrips: true,
          processingEvents: true,
          processingDtc: true,
          error: null
        }
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_FAILURE: {
      return {
        ...state,
        trailer: {
          ...state.trailer,
          processing: false,
          processingTrips: false,
          processingEvents: false,
          processingDtc: false,
          error: action.error
        }
      };
    }
    case actionTypes.CLEAR_TRAILER_DETAILS: {
      return {
        ...state,
        trailer: {
          item: null,
          listTrailer: null,
          processing: false,
          processingTrips: false,
          processingEvents: false,
          processingDtc: false,
          error: null
        }
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_TRIPS_SUCCESS: {
      return {
        ...state,
        trailer: {
          item: { ...state.trailer.item, trips: action.trips },
          listTrailer: state.trailer.listTrailer,
          processingTrips: !!action.processingTrips,
          processing: action.processing
        }
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_EVENTS_SUCCESS: {
      return {
        ...state,
        trailer: {
          item: { ...state.trailer.item, recordedEvents: action.recordedEvents },
          listTrailer: state.trailer.listTrailer,
          processingEvents: action.processingEvents,
          processingTrips: state.trailer?.processingTrips,
          processing: action.processing
        }
      };
    }
    case actionTypes.GET_TRAILER_DETAILS_DTC_SUCCESS: {
      return {
        ...state,
        trailer: {
          item: { ...state.trailer.item, dtc: action.dtcEvents },
          listTrailer: state.trailer.listTrailer,
          processingEvents: action.processingDtc,
          processingTrips: state.trailer?.processingTrips,
          processing: action.processing
        }
      };
    }
    case actionTypes.UPLOAD_FILE_REQUEST: {
      return {
        ...state,
        trailer: { item: null, processing: true, error: null }
      };
    }
    case actionTypes.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        trailer: {
          item: action.trailer,
          processing: false,
          error: null
        }
      };
    }
    case actionTypes.UPLOAD_FILE_FAILURE: {
      return {
        ...state,
        trailer: { item: null, processing: false, error: action.error }
      };
    }
    case actionTypes.RESET_TRAILER: {
      return {
        ...state,
        trailer: { item: null, processing: false, error: null }
      };
    }
    case actionTypes.GET_TRAILERS_HEALTH_REQUEST: {
      return {
        ...state,
        trailerEventHistory: {
          item: [],
          processing: true,
          error: null,
          done: false
        }
      };
    }
    case actionTypes.GET_TRAILERS_HEALTH_SUCCESS: {
      return {
        ...state,
        trailerEventHistory: action.payload.trailerEventHistory,
        weeklyEvolution: action.payload.weeklyEvolution,
        trailers: action.payload.trailers
      };
    }
    case actionTypes.GET_TRAILERS_HEALTH_FAILURE: {
      return {
        ...state,
        trailerEventHistory: {
          items: [],
          processing: false,
          error: action.error,
          done: false
        }
      };
    }
    case actionTypes.REQUEST_ODR_REQUEST: {
      const odrRefresh = { item: null, processing: true, error: null };
      return { ...state, odrRefresh };
    }
    case actionTypes.REQUEST_ODR_SUCCESS: {
      const odrRefresh = { item: null, processing: false, error: null };
      return { ...state, odrRefresh };
    }
    case actionTypes.REQUEST_ODR_FAILURE: {
      const odrRefresh = { item: null, processing: false, error: action.error };
      return { ...state, odrRefresh };
    }
    case actionTypes.GET_TRAILER_CMI_REPORT_REQUEST: {
      const installationDetails = { item: null, processing: true, error: null };
      return { ...state, installationDetails };
    }
    case actionTypes.GET_TRAILER_CMI_REPORT_SUCCESS: {
      const installationDetails = { item: action.data, processing: false, error: null };
      return { ...state, installationDetails };
    }
    case actionTypes.GET_TRAILER_CMI_REPORT_FAILURE: {
      const installationDetails = { item: null, processing: false, error: action.error };
      return { ...state, installationDetails };
    }
    case actionTypes.GET_TRAILER_RF_DATA_REQUEST: {
      const rfDetails = { item: null, processing: true, error: null };
      return { ...state, rfDetails };
    }
    case actionTypes.GET_TRAILER_RF_DATA_SUCCESS: {
      const rfDetails = { item: action.data, processing: false, error: null };
      return { ...state, rfDetails };
    }
    case actionTypes.GET_TRAILER_RF_DATA_FAILURE: {
      const rfDetails = { item: null, processing: false, error: action.error };
      return { ...state, rfDetails };
    }
    case actionTypes.GET_TRAILER_LOCATION_REQUEST: {
      const trailerLocationDetails = { items: state.trailerLocationDetails.items, processing: true, error: null };
      return { ...state, trailerLocationDetails };
    }
    case actionTypes.GET_TRAILER_LOCATION_SUCCESS: {
      const trailerLocationDetails = {
        items: { ...state.trailerLocationDetails.items, [action.assetId]: action.location },
        processing: false,
        error: null
      };
      return { ...state, trailerLocationDetails };
    }
    case actionTypes.GET_TRAILER_LOCATION_FAILURE: {
      const trailerLocationDetails = {
        items: state.trailerLocationDetails.items,
        processing: false,
        error: action.error
      };
      return { ...state, trailerLocationDetails };
    }
    case actionTypes.SET_TRAILERS_SELECTED_WHEELS: {
      return {
        ...state,
        trailerSelectedWheels: {
          trailerAssetId: action.trailerAssetId,
          selectedWheels: action.selectedWheels || {}
        }
      };
    }
    default:
      return state;
  }
}
