import React from 'react';
import { Provider } from 'react-redux';
import '../scss/App.scss';
import configureStore from './configureStore';
import LocaleContainer from './LocaleContainer';
import DataLoader from './dataLoader';
import '../scss/OdysseyStyling.scss';

const store = configureStore;

const App = () => {
  return (
    <Provider store={store}>
      <DataLoader>
        <LocaleContainer />
      </DataLoader>
    </Provider>
  );
};
export default App;
