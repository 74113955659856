import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import OdyTag from '../../../../../../ReusableComponents/Tag/OdyTag';
import { NoValueSpan } from '../../../../../EbpmsV2/right/components/Columns/ColumnComponents/columnHelper/columnHelperFunctions';
import { Tooltip } from 'antd';
import { formatLampStatusString, groteLightState } from '../../../../../../helpers/grote';
import './LightMonitoringColumn.scss';

const formatCsvData = (lightMonitoring, formatMessage) => {
  let text = '';
  const { type, severity, anomalies, outages } = lightMonitoring;

  if (!type) return '';

  if (severity === 1) {
    return formatMessage(strings.lightMonitoringStatus.good);
  }

  if (anomalies?.length > 0 || outages?.length > 0) {
    text = [...anomalies, ...outages]
      .reduce((acc, cur) => {
        acc.push(formatLampStatusString(cur.colour, groteLightState[cur.code], formatMessage));
        return acc;
      }, [])
      .join('/');
  }

  return text;
};

const Cell = ({ rowData, formatMessage }) => {
  const { lightMonitoring } = rowData;
  const { type, value, tooltip } = lightMonitoring;

  if (type) {
    return (
      <OdyTag type={type}>
        <Tooltip title={formatMessage(strings.lightMonitoringStatus[tooltip])}>
          {formatMessage(strings.lightMonitoringStatus[value])}
        </Tooltip>
      </OdyTag>
    );
  }

  return <NoValueSpan />;
};

const LightMonitoringColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.lightMonitoring)}>
        {formatMessage(strings.columns.lightMonitoring)}
      </Tooltip>
    ),
    key: 'lightMonitoring',
    dataIndex: 'lightMonitoring',
    searchKey: formatMessage(strings.columns.lightMonitoring),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} />,
    sorter: () =>
      trailers.sort('lightMonitoring', (a, b) => (a.lightMonitoring?.severity < b.lightMonitoring?.severity ? -1 : 1)),
    width: 180
  };
};

const LightMonitoringColumnHeader = formatMessage => formatMessage(strings.columns.lightMonitoring);
const LightMonitoringCSVData = (trailer, formatMessage) => formatCsvData(trailer.lightMonitoring, formatMessage);
const LightMonitoringCSVColumn = {
  header: LightMonitoringColumnHeader,
  data: LightMonitoringCSVData
};

export default { table: LightMonitoringColumn, csv: LightMonitoringCSVColumn };
