import { MESSAGE_TYPES, CONFIG_TYPES, WORKER_NAME } from '../../workers/constants';
import { onMessageHandler, sendMessageHandler, subscribe } from './messages';
import { v4 as uuidv4 } from 'uuid';
import { initWebWorker } from './init-web-worker';
import { Logger } from '../helpers/logger';
import { parseJson } from '../../helpers/parse-json';

let webWorker = null;
let sendMessage = null;

export const worker = {
  name: WORKER_NAME,
  Api: null,
  subscribe: null,
  sendMessage: null,
  wrapMessageInPromise: null
};

export const extractAwsWorkerStorage = () => {
  const awsWorkerKeys = [];
  const prefix = `${WORKER_NAME}:`;

  Object.entries(localStorage).forEach(([key, value]) => {
    if (key.startsWith(prefix)) {
      awsWorkerKeys.push([key.replace(prefix, ''), parseJson(value)]);
    }
  });

  return awsWorkerKeys;
};

export const initialize = async () => {
  webWorker = initWebWorker();
  webWorker.onmessage = onMessageHandler;
  sendMessage = sendMessageHandler(webWorker);

  worker.Api = Api;
  worker.sendMessage = sendMessage;
  worker.subscribe = subscribe;
  worker.wrapMessageInPromise = wrapMessageInPromise;

  try {
    await worker.wrapMessageInPromise({
      types: {
        request: MESSAGE_TYPES.CONFIGURE,
        success: MESSAGE_TYPES.CONFIGURE_SUCCESS,
        error: MESSAGE_TYPES.CONFIGURE_ERROR
      },
      payload: {
        [CONFIG_TYPES.USER_AGENT]: window.navigator.userAgent,
        [CONFIG_TYPES.AWS_WORKER_STORAGE]: extractAwsWorkerStorage()
      }
    });
  } catch (err) {
    Logger.error('worker:initialize', err);
  }
};

export const wrapMessageInPromise = ({ types: { request, success, error }, payload }) => {
  const topic = uuidv4();
  const promise = new Promise((resolve, reject) => {
    const unsubscribe = worker.subscribe(topic, (currentTopic, message) => {
      if (message.type === success) {
        resolve(message.payload);
      } else if (message.type === error) {
        Logger.error(message.payload);
        reject(message.payload);
      }
      unsubscribe();
    });
  });

  const transicsUserProfileToken = window.localStorage.getItem('transics-token');

  sendMessage({
    type: request,
    payload,
    metadata: { topic, transicsUserProfileToken }
  });

  return promise;
};

export const Api = payload => {
  if (!payload?.method) {
    throw new Error('missing method');
  }

  return wrapMessageInPromise({
    types: {
      request: MESSAGE_TYPES.API_REQUEST,
      success: MESSAGE_TYPES.API_SUCCESS,
      error: MESSAGE_TYPES.API_ERROR
    },
    payload
  });
};
