import React from 'react';

const IconHand = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 9.5L15 9.66602V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V10L18 10.166V5C18 4.44772 18.4477 4 19 4C19.5523 4 20 4.44772 20 5V19C20 20.645 18.645 22 17 22H10.2422C9.44628 22 8.68277 21.6828 8.12109 21.1211L8.08594 21.0859L3.63111 15.6224C3.31283 15.2321 3.33453 14.6659 3.68176 14.3011L4.42187 13.5234C5.13821 12.7732 6.26458 12.5619 7.20508 13L7.28125 13.0352L9 14.2207V3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44772 11 3V9L12 9.16602L12 2C12 1.44772 12.4477 1 13 1C13.5523 1 14 1.44772 14 2L14 9.5ZM11 11.0273L18 12.1953V19C18 19.565 17.565 20 17 20H10.2422C9.98064 20 9.73412 19.8952 9.54687 19.7129L5.85786 15.1877C5.78956 15.1039 5.7944 14.9824 5.86914 14.9043C5.99412 14.7734 6.17527 14.7475 6.34179 14.8164L9.43222 16.948C10.0956 17.4055 11 16.9306 11 16.1248V11.0273Z'
    />
  </svg>
);

export default IconHand;
