import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import EventTimelineChart from './EventTimelineChart';
import { injectIntl } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import { isUsaCustomer, getTimeFormatLocale } from '../../../helpers/functions';
import { selectRegion } from '../../../selectors';

function getChartState(props) {
  return {
    dtc: props.dtc,
    trips: props.trips,
    onTripHover: props.onTripHover,
    metric: props.metric,
    colorDomain: props.colorDomain,
    mileageTimeline: props.mileageTimeline,
    recordedEvents: props.recordedEvents,
    selectEvent: props.selectEvent,
    selectTrip: props.selectTrip,
    trailer: props.trailer,
    onErrorTypeClicked: props.onErrorTypeClicked
  };
}

class EventTimelineChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const { mileageTimeline, dtc, metric } = this.props;
    const currentState = getChartState(nextProps);
    if (
      chart &&
      (nextProps.mileageTimeline !== mileageTimeline ||
        (nextProps.dtc !== dtc && JSON.stringify(nextProps.dtc.map(d => d.id)) !== JSON.stringify(dtc.map(d => d.id))))
    ) {
      chart.update(currentState);
    }
    if (nextProps.metric !== null && nextProps.metric !== metric && chart) {
      chart.updateMetric(nextProps.metric, nextProps.colorDomain);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state;
    const {
      isiABS,
      isMetric,
      intl: { formatMessage },
      region
    } = this.props;
    if (chart) {
      chart.destroy();
    }

    const margin = {
      top: 20,
      right: 250,
      bottom: 20,
      left: 5
    };

    const elWidth = el.offsetWidth;
    const elHeight = this.props.height || 200;
    const isUS = isUsaCustomer(region);

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      strings: {
        abs: formatMessage(strings.eventLabel.abs),
        absControlCritical: formatMessage(strings.eventLabel.absControlCritical),
        absCount: formatMessage(strings.short.absCount),
        allRecordedEvents: formatMessage(strings.short.allRecordedEvents),
        antiSnaking: formatMessage(strings.eventLabel.antiSnaking),
        avgAxleLoad: formatMessage(strings.short.avgAxleLoad),
        axleLoad: formatMessage(strings.short.axleLoad),
        brakeCount: formatMessage(strings.short.brakeCount),
        collision: formatMessage(strings.eventLabel.collision),
        date: formatMessage(strings.short.date),
        details: formatMessage(strings.short.details),
        diagnosticErrorMessage: formatMessage(strings.eventLabel.diagnosticErrorMessage),
        diagnosticModulesEvents: formatMessage(strings.eventLabel.diagnosticModulesEvents),
        distance: formatMessage(strings.short.distance),
        driveDetectionMemoryBit: formatMessage(strings.eventLabel.driveDetectionMemoryBit),
        duration: formatMessage(strings.short.duration),
        ecasBadRoadDetection: formatMessage(strings.eventLabel.ecasBadRoadDetection),
        ecasEmergencyMode: formatMessage(strings.eventLabel.ecasEmergencyMode),
        ecasOutsideRequestedLevel: formatMessage(strings.eventLabel.ecasOutsideRequestedLevel),
        ecasPlausibilityErrorFrontAxle: formatMessage(strings.eventLabel.ecasPlausibilityErrorFrontAxle),
        ecasPlausibilityErrorRearAxle: formatMessage(strings.eventLabel.ecasPlausibilityErrorRearAxle),
        emergencyBrakeAlert: formatMessage(strings.eventLabel.emergencyBrakeAlert),
        epbDeactivatedByUser: formatMessage(strings.eventLabel.epbDeactivatedByUser),
        error: formatMessage(strings.eventLabel.error),
        errorCode: formatMessage(strings.short.errorCode),
        fingerPrintHistory: formatMessage(strings.eventLabel.fingerPrintHistory),
        gio1: formatMessage(strings.eventLabel.gio1),
        gio2: formatMessage(strings.eventLabel.gio2),
        gioFcf1: formatMessage(strings.eventLabel.gioFcf1),
        gioFcf2: formatMessage(strings.eventLabel.gioFcf2),
        gioFcf3: formatMessage(strings.eventLabel.gioFcf3),
        gioFcf4: formatMessage(strings.eventLabel.gioFcf4),
        gioFcf5: formatMessage(strings.eventLabel.gioFcf5),
        gioFcf6: formatMessage(strings.eventLabel.gioFcf6),
        gioFcf7: formatMessage(strings.eventLabel.gioFcf7),
        gioFcf8: formatMessage(strings.eventLabel.gioFcf8),
        id: formatMessage(strings.abbrev.identification),
        immobiliserLockTrailer: formatMessage(strings.eventLabel.immobiliserLockTrailer),
        immobiliserUnauthorisedUse: formatMessage(strings.eventLabel.immobiliserUnauthorisedUse),
        immobiliserUnlockTrailer: formatMessage(strings.eventLabel.immobiliserUnlockTrailer),
        immobiliserWrongPin: formatMessage(strings.eventLabel.immobiliserWrongPin),
        issPlusAnalog: formatMessage(strings.eventLabel.issPlusAnalog),
        issPlusDigital: formatMessage(strings.eventLabel.issPlusDigital),
        issue: formatMessage(strings.short.issue),
        km: formatMessage(strings.abbrev.kilometer),
        mi: formatMessage(strings.abbrev.mile),
        kph: formatMessage(strings.abbrev.kilometerPerHour),
        mph: formatMessage(strings.abbrev.milePerHour),
        latAcceleration: formatMessage(strings.short.latAcceleration),
        liftAxlePlausibility: formatMessage(strings.eventLabel.liftAxlePlausibility),
        liftAxlePlausibilityValveDetection: formatMessage(strings.eventLabel.liftAxlePlausibilityValveDetection),
        liningWear: formatMessage(strings.eventLabel.liningWear),
        lowTyrePressure: formatMessage(strings.eventLabel.lowTyrePressure),
        m: formatMessage(strings.abbrev.meter),
        maxAxleLoad: formatMessage(strings.short.maxAxleLoad),
        maxSpeed: formatMessage(strings.short.maxSpeed),
        meritorTireInflationSystem: formatMessage(strings.eventLabel.meritorTireInflationSystem),
        minAxleLoad: formatMessage(strings.short.minAxleLoad),
        mileage: formatMessage(strings.short.mileage),
        module: formatMessage(strings.short.module),
        nd: formatMessage(strings.eventLabel.nd),
        notSupported: formatMessage(strings.short.notSupported),
        operation24N: formatMessage(strings.eventLabel.operation24N),
        optiLoad: formatMessage(strings.eventLabel.optiLoad),
        optiTireLowBattery: formatMessage(strings.eventLabel.optiTireLowBattery),
        optiTireTimeout: formatMessage(strings.eventLabel.optiTireTimeout),
        optiTurn: formatMessage(strings.eventLabel.optiTurn),
        plausibilityResPressureControl: formatMessage(strings.eventLabel.plausibilityResPressureControl),
        recordedEvents: formatMessage(strings.eventLabel.recordedEvents),
        relativeBrakeDemand: formatMessage(strings.eventLabel.relativeBrakeDemand),
        ropSystemDisabled: formatMessage(strings.eventLabel.ropSystemDisabled),
        rssLevel1: formatMessage(strings.eventLabel.rssLevel1),
        rssLevel2: formatMessage(strings.eventLabel.rssLevel2),
        scbEvent: formatMessage(strings.eventLabel.scbEvent),
        scbTelematicsControlRequest: formatMessage(strings.eventLabel.scbTelematicsControlRequest),
        speed: formatMessage(strings.short.speed),
        standByOperation: formatMessage(strings.eventLabel.standByOperation),
        supplyPressureWarning: formatMessage(strings.eventLabel.supplyPressureWarning),
        tailGuardDeactivated: formatMessage(strings.eventLabel.tailGuardDeactivated),
        tbsBrakeIntervention: formatMessage(strings.eventLabel.tbsBrakeIntervention),
        tbsDeactivationByUser: formatMessage(strings.eventLabel.tbsDeactivationByUser),
        tiltAlert: formatMessage(strings.eventLabel.tiltAlert),
        time: formatMessage(strings.short.time),
        ton: formatMessage(strings.abbrev.ton),
        kiloPoundUSA: formatMessage(strings.abbrev.kiloPoundUSA),
        tpmCalibrationRequest: formatMessage(strings.eventLabel.tpmCalibrationRequest),
        trailerDocked: formatMessage(strings.eventLabel.trailerDocked),
        trailerOverload: formatMessage(strings.eventLabel.trailerOverload),
        trip: formatMessage(strings.short.trip),
        trips: formatMessage(strings.short.trips),
        tyrePressureInsufficient: formatMessage(strings.eventLabel.tyrePressureInsufficient),
        unknown: formatMessage(strings.short.unknown),
        warningLamp: formatMessage(strings.eventLabel.warningLamp),
        wheelEndOverTemp: formatMessage(strings.eventLabel.wheelEndOverTemp),
        ycSystemDisabled: formatMessage(strings.eventLabel.ycSystemDisabled),
        ABSevents: formatMessage(strings.short.ABSevents),
        noGps: formatMessage(strings.phrase.noGps)
      },
      isiABS: isiABS,
      isMetric: isMetric,
      formatMessage,
      isUS,
      timeFormatLocale: getTimeFormatLocale(formatMessage, isUS)
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new EventTimelineChart(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(getChartState(this.props));
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return <div ref='chart' className='events__timeline' />;
  }
}

EventTimelineChartComponent.propTypes = {};

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    region
  };
}

export default injectIntl(connect(mapStateToProps)(EventTimelineChartComponent));
