import { cleanHistoricalPerformances } from './datacleaners';
import actionTypes from '../actions/actionTypes';
import { filterOutInactiveTrailersIfNeeded, getIsLoadBPVAlgorithm } from '../helpers/functions';
import { selectPerformanceKeys } from '../selectors/settings';

export const initialTrailersState = {
  allItems: null,
  items: null,
  selected: null,
  error: null,
  firstTrailersReceived: false,
  processing: false,
  performanceKey: null,
  fallbackPerformanceKey: null,
  processingReport: {},
  reports: [],
  pastTrailers: null,
  ptProcessing: false,
  getReportProcessing: false,
  ptError: null,
  deviceAssetsIds: null
};

const objectWithoutKey = (object, key) => {
  // eslint-disable-next-line no-unused-vars
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

const updateTrailersWithSettings = (state, appState) => {
  const { items, selected, performanceKey: currentPerformanceKey } = state;
  if (items) {
    const { performanceKey, fallbackPerformanceKey } = selectPerformanceKeys(appState);
    if (performanceKey !== currentPerformanceKey) {
      return {
        ...state,
        items: items.map(trailer => {
          return {
            ...trailer,
            ...trailer[performanceKey]
          };
        }),
        performanceKey,
        fallbackPerformanceKey,
        selected: selected
          ? {
              ...selected,
              ...selected[performanceKey]
            }
          : selected
      };
    }
  }
  return state;
};

function ebpmsTrailerReducer(state = initialTrailersState, action) {
  switch (action.type) {
    // get trailers list
    case actionTypes.EBPMS_GET_TRAILERS_REQUEST:
      return {
        ...state,
        items: null,
        error: null,
        processing: true
      };
    case actionTypes.EBPMS_GET_TRAILERS_SUCCESS:
      return {
        ...state,
        firstTrailersReceived: true,
        allItems: action.allItems,
        performanceKey: action.key,
        fallbackPerformanceKey: action.fallbackPerformanceKey,
        processing: !action.final,
        items: action.items
      };
    case actionTypes.EBPMS_FILTER_TRAILERS:
      return {
        ...state,
        items: action.items
      };
    case actionTypes.EBPMS_GET_TRAILERS_FAILURE:
      return {
        ...state,
        items: null,
        error: action.error,
        processing: false
      };
    // get trailer detail
    case actionTypes.GET_TRAILER_DETAIL_REQUEST:
      if (state.selected && action.deviceId === state.selected.deviceId) {
        return {
          ...state,
          selected: {
            ...state.selected,
            historicalPerformances: {
              processing: true,
              error: null,
              items: null
            }
          }
        };
      } else {
        return state;
      }
    case actionTypes.GET_TRAILER_DETAIL_SUCCESS:
      if (
        state.selected &&
        action.deviceId === state.selected.deviceId &&
        action.trailer &&
        action.trailer.performanceHistory
      ) {
        return {
          ...state,
          selected: {
            ...state.selected,
            historicalPerformances: {
              processing: false,
              error: null,
              items: cleanHistoricalPerformances(
                action.trailer.performanceHistory,
                getIsLoadBPVAlgorithm(state.selected)
              )
            }
          }
        };
      } else {
        return state;
      }
    case actionTypes.GET_TRAILER_DETAIL_FAILURE:
      if (state.selected && action.deviceId === state.selected.deviceId) {
        return {
          ...state,
          selected: {
            ...state.selected,
            historicalPerformances: {
              processing: false,
              error: action.error,
              items: null
            }
          }
        };
      } else {
        return state;
      }
    // select trailer
    case actionTypes.SELECT_TRAILER:
      if (state.items && action.deviceId) {
        const selected = state.items.find(trailer => trailer.deviceId === action.deviceId);
        return {
          ...state,
          selected: selected || state.selected
        };
      } else {
        return {
          ...state,
          selected: null
        };
      }
    // update values
    case actionTypes.GET_SETTINGS_SUCCESS:
      const { allItems } = state;
      const items = filterOutInactiveTrailersIfNeeded({
        trailers: allItems,
        displayTrailers: action?.settings?.displayTrailers,
        displayTrailersEBPMS: action?.settings?.displayTrailersEBPMS,
        isEBPMS: true
      });
      return updateTrailersWithSettings({ ...state, items }, action.appState);
    case actionTypes.SAVE_SETTING_SUCCESS:
      return updateTrailersWithSettings(state, action.appState);
    // get report
    case actionTypes.GET_REPORT_REQUEST:
      return {
        ...state,
        processingReport: { [action.filename]: true }
      };
    case actionTypes.GET_REPORT_SUCCESS:
    case actionTypes.GET_REPORT_FAILURE:
      return {
        ...state,
        processingReport: objectWithoutKey(state.processingReport, action.filename)
      };
    case actionTypes.GET_REPORTS_REQUEST:
      return {
        ...state,
        reports: [],
        getReportProcessing: true
      };
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, ...action.reports],
        getReportProcessing: false
      };
    case actionTypes.GET_REPORTS_FAILURE:
      return {
        ...state,
        getReportProcessing: false
      };
    case actionTypes.EBPMS_GET_PAST_TRAILERS_REQUEST:
      return {
        ...state,
        pastTrailers: null,
        ptError: null,
        ptProcessing: true
      };
    case actionTypes.EBPMS_GET_PAST_TRAILERS_SUCCESS:
      return {
        ...state,
        pastTrailers: action.trailers,
        ptProcessing: false
      };
    case actionTypes.EBPMS_GET_PAST_TRAILERS_FAILURE:
      return {
        ...state,
        pastTrailers: null,
        ptError: action.error,
        ptProcessing: false
      };
    case actionTypes.FILTER_DEVICES:
      return {
        ...state,
        items: action.ebpmsItems
      };
    case actionTypes.CHANGE_TRAILER_COMMENT_REQUEST:
      return {
        ...state,
        items: state.items.map(trailer =>
          trailer.assetId === action.deviceId
            ? { ...trailer, comment: { ...trailer?.comment, text: action.comment, last_comment_time: action.time } }
            : trailer
        )
      };
    case actionTypes.CHANGE_TRAILER_COMMENT_SUCCESS:
      return state;
    case actionTypes.CHANGE_TRAILER_COMMENT_FAILURE:
      return {
        ...state,
        items: state.items.map(trailer =>
          trailer.assetId === action.deviceId
            ? {
                ...trailer,
                comment: { ...trailer?.comment, text: action.lastComment, last_comment_time: action.lastTime }
              }
            : trailer
        )
      };
    case actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_REQUEST:
      return {
        ...state,
        items: state.items.map(trailer =>
          trailer.assetId === action.deviceId
            ? {
                ...trailer,
                metadata: {
                  ...trailer?.metadata,
                  ebpms_performance_algorithm: {
                    ...trailer?.metadata?.ebpms_performance_algorithm,
                    value: action.algorithm,
                    last_value_time: action.time
                  }
                }
              }
            : trailer
        )
      };
    case actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_SUCCESS:
      return state;
    case actionTypes.UPDATE_TRAILER_PERFORMANCE_ALGORITHM_FAILURE:
      return {
        ...state,
        items: state.items.map(trailer =>
          trailer.assetId === action.deviceId
            ? {
                ...trailer,
                metadata: {
                  ...trailer?.metadata,
                  ebpms_performance_algorithm: {
                    ...trailer?.metadata?.ebpms_performance_algorithm,
                    value: action.lastAlgorithm,
                    last_value_time: action.lastTime
                  }
                }
              }
            : trailer
        )
      };
    case actionTypes.CHANGE_TRAILER_LAST_INTERVENTION_SUCCESS:
      return {
        ...state,
        items: state.items.map(trailer =>
          trailer.assetId === action.deviceId
            ? { ...trailer, lastIntervention: { ...trailer?.lastIntervention, ...action.lastIntervention } }
            : trailer
        )
      };
    default:
      return state;
  }
}

export default ebpmsTrailerReducer;
