import React from 'react';

const IconDefault = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9.24316 11.498C9.24967 11.1107 9.29362 10.8047 9.375 10.5801C9.45638 10.3555 9.6224 10.1064 9.87305 9.83301L10.5127 9.17383C10.7861 8.86458 10.9229 8.53255 10.9229 8.17773C10.9229 7.83594 10.8333 7.56901 10.6543 7.37695C10.4753 7.18164 10.2148 7.08398 9.87305 7.08398C9.54102 7.08398 9.27409 7.17188 9.07227 7.34766C8.87044 7.52344 8.76953 7.75944 8.76953 8.05566H7.86621C7.87272 7.52832 8.0599 7.10352 8.42773 6.78125C8.79883 6.45573 9.2806 6.29297 9.87305 6.29297C10.4883 6.29297 10.9668 6.45898 11.3086 6.79102C11.6536 7.11979 11.8262 7.57227 11.8262 8.14844C11.8262 8.7181 11.5625 9.27962 11.0352 9.83301L10.5029 10.3604C10.2653 10.624 10.1465 11.0033 10.1465 11.498H9.24316ZM9.2041 13.0459C9.2041 12.8994 9.24805 12.7773 9.33594 12.6797C9.42708 12.5788 9.56055 12.5283 9.73633 12.5283C9.91211 12.5283 10.0456 12.5788 10.1367 12.6797C10.2279 12.7773 10.2734 12.8994 10.2734 13.0459C10.2734 13.1924 10.2279 13.3145 10.1367 13.4121C10.0456 13.5065 9.91211 13.5537 9.73633 13.5537C9.56055 13.5537 9.42708 13.5065 9.33594 13.4121C9.24805 13.3145 9.2041 13.1924 9.2041 13.0459Z' />
    <defs>
      <filter
        id='filter0_d'
        x='0'
        y='0'
        width='20'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset />
        <feGaussianBlur stdDeviation='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
  </svg>
);

export default IconDefault;
