import React from 'react';

const IconDashboard = () => (
  <span className='anticon'>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.33335 0.166626C0.695734 0.166626 0.166687 0.695673 0.166687 1.33329V5.41663C0.166687 6.05425 0.695734 6.58329 1.33335 6.58329H4.25002C4.88764 6.58329 5.41669 6.05425 5.41669 5.41663V1.33329C5.41669 0.695673 4.88764 0.166626 4.25002 0.166626H1.33335ZM7.75002 0.166626C7.1124 0.166626 6.58335 0.695673 6.58335 1.33329V3.08329C6.58335 3.72091 7.1124 4.24996 7.75002 4.24996H10.6667C11.3043 4.24996 11.8334 3.72091 11.8334 3.08329V1.33329C11.8334 0.695673 11.3043 0.166626 10.6667 0.166626H7.75002ZM1.33335 1.33329H4.25002V5.41663H1.33335V1.33329ZM7.75002 1.33329H10.6667V3.08329H7.75002V1.33329ZM7.75002 5.41663C7.1124 5.41663 6.58335 5.94567 6.58335 6.58329V10.6666C6.58335 11.3042 7.1124 11.8333 7.75002 11.8333H10.6667C11.3043 11.8333 11.8334 11.3042 11.8334 10.6666V6.58329C11.8334 5.94567 11.3043 5.41663 10.6667 5.41663H7.75002ZM7.75002 6.58329H10.6667V10.6666H7.75002V6.58329ZM1.33335 7.74996C0.695734 7.74996 0.166687 8.27901 0.166687 8.91663V10.6666C0.166687 11.3042 0.695734 11.8333 1.33335 11.8333H4.25002C4.88764 11.8333 5.41669 11.3042 5.41669 10.6666V8.91663C5.41669 8.27901 4.88764 7.74996 4.25002 7.74996H1.33335ZM1.33335 8.91663H4.25002V10.6666H1.33335V8.91663Z' />
    </svg>
  </span>
);

export default IconDashboard;
