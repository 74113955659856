import { formatCleanTrailer } from './ActionResources/transformationFunctions';
import actionTypes from './actionTypes';
import store from '../app/configureStore.js';
import { API_NAMES } from '../helpers/constants';
import { worker } from '../app/web-workers/aws-web-worker';

function uploadFileRequest() {
  return {
    type: actionTypes.UPLOAD_FILE_REQUEST
  };
}

function uploadFileSuccess(trailer) {
  const state = store.getState().trailerDetails;
  const cleanTrailerWithUploadInfo = formatCleanTrailer(state, trailer);
  return {
    type: actionTypes.UPLOAD_FILE_SUCCESS,
    trailer: cleanTrailerWithUploadInfo
  };
}

function uploadFileFailure(error) {
  return {
    type: actionTypes.UPLOAD_FILE_FAILURE,
    error
  };
}

function uploadFile(body) {
  return dispatch => {
    dispatch(uploadFileRequest());
    return worker
      .Api({ method: 'put', api: API_NAMES.ODR_LIVE_MAP, endpoint: 'fileUploadDataURL', body })
      .then(res => {
        return dispatch(uploadFileSuccess(res));
      })
      .catch(err => {
        console.error(err);
        return dispatch(uploadFileFailure(err));
      });
  };
}

export default {
  uploadFile
};
