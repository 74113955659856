import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { selectProductFeatures } from '../../selectors/auth';
import SendToken from '../Login/SendToken';
import paths from '../../app/paths';

const ExtraRoutes = ({ isLegacy }) => {
  const productFeatures = useSelector(selectProductFeatures);
  const homePath = productFeatures.logoImage === 'tip' ? paths.ebpms : paths.liveMap;
  if (isLegacy) {
    return (
      <>
        <Route exact path='/login' component={SendToken} />
        <Route exact path='/login/:token' component={SendToken} />
        <Route exact path='/login/:env/:token' component={SendToken} />
        <Redirect to={homePath} />
      </>
    );
  }
  return <Redirect to={homePath} />;
};

export default ExtraRoutes;
