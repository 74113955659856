import { createSelector } from '@reduxjs/toolkit';

const selectRoot = state => state?.featureFlags;
const selectFeatureFlags = createSelector(selectRoot, state => state?.featureFlags);

export const selectFeatureFlag = createSelector(
  [
    selectFeatureFlags,
    // https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
    (state, featureFlag) => featureFlag
  ],
  (featureFlags, featureFlag) => featureFlags[featureFlag] ?? false
);

export const selectFeatureFlagsIsDataLoaded = createSelector(selectRoot, state => state?.dataLoaded ?? false);
