import React from 'react';
import { injectIntl } from 'react-intl';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import * as strings from '../../helpers/defaultStrings';
import { getConversionString, unitType } from '../../helpers/unitConverter';
import { getTitleValueDiv, getTimeDate, isUsaCustomer } from '../../helpers/functions';
import { getModuleMessage, getDescriptionMessage } from '../../helpers/odrerrorDefaultStrings';
import { selectRegion } from '../../selectors';

const EventDescription = ({
  event,
  intl: { formatMessage },
  onErrorClicked,
  mapUrl,
  showReadMore,
  isTrailerIABS,
  isConverted
}) => {
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const isGPSValid = !!(event.GPSPosition_latitude && event.GPSPosition_longitude);
  const isUS = isUsaCustomer(region);

  return (
    <div className='trailer-history-description'>
      <div className='flex-box-container'>
        <div className='event-description'>
          {event.errorCode && (
            <h3>
              <strong>{getModuleMessage(event.errorCode, formatMessage, isTrailerIABS)}</strong>
            </h3>
          )}
          {!event.eventNameId && !event.description && (
            <h3>
              {formatMessage(strings.short.errorCode)}: <strong>{event.errorCode}</strong>
            </h3>
          )}
          {event.eventNameId && (
            <h3>
              <strong>
                {strings.eventLabel[event.eventNameId]
                  ? formatMessage(strings.eventLabel[event.eventNameId])
                  : event.eventNameId}
              </strong>
            </h3>
          )}
          <div>
            {getTitleValueDiv(
              formatMessage(strings.short.mileage),
              formatMessage(...getConversionString(isMetric, event.mileage, unitType.distance, isUS, isConverted))
            )}
            {getTitleValueDiv(
              formatMessage(strings.short.time),
              event.timestamp ? getTimeDate(event.timestamp) : formatMessage(strings.short.unknown)
            )}
          </div>
          <div className='large-margin-top'>
            {getTitleValueDiv(
              formatMessage(strings.short.axleLoad),
              formatMessage(...getConversionString(isMetric, event.AxleLoadSum, unitType.ton, isUS, isConverted))
            )}
            {getTitleValueDiv(
              formatMessage(strings.short.speed),
              formatMessage(...getConversionString(isMetric, event.VehicleSpeed, unitType.speed, isUS, isConverted))
            )}
          </div>
        </div>
        {event.errorCode && (
          <div className='error-box'>
            <div>
              {getTitleValueDiv(formatMessage(strings.short.errorCode), event.errorCode)}
              {getTitleValueDiv(
                formatMessage(strings.short.module),
                getModuleMessage(event.errorCode, formatMessage, isTrailerIABS)
              )}
              {getTitleValueDiv(
                formatMessage(strings.short.details),
                getDescriptionMessage(event.errorCode, formatMessage, isTrailerIABS)
              )}
            </div>
            {showReadMore && (
              <div className='clickable-text float-right' onClick={() => onErrorClicked(event.errorCode)}>
                {formatMessage(strings.short.readMore)}
              </div>
            )}
          </div>
        )}
      </div>

      {isGPSValid && (
        <div className='float-right flex-box-container large-margin-top'>
          <div className='large-margin-right'>
            <strong>{formatMessage(strings.short.longitude)}</strong>:{' '}
            {numeral(event.GPSPosition_longitude).format('0.000000')}
          </div>
          <div className='large-margin-right'>
            <strong>{formatMessage(strings.short.latitude)}</strong>:{' '}
            {numeral(event.GPSPosition_latitude).format('0.000000')}
          </div>
          {mapUrl && (
            <div className='clickable-text' onClick={() => window.open(mapUrl)}>
              {formatMessage(strings.phrase.openGoogleMaps)}
            </div>
          )}
        </div>
      )}

      {!isGPSValid && (
        <div className='no-gps-text grey-italic-text'>{formatMessage(strings.phrase.noGpsCoordinatesAvailable)}</div>
      )}

      {event.display && (
        <div>
          <br />
          <div>
            <strong>{event.display}</strong>
          </div>
          {event.wheelInfo && <div>{event.wheelInfo}</div>}
        </div>
      )}
    </div>
  );
};

EventDescription.propTypes = {};

export default injectIntl(EventDescription);
