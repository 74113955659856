import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import JetpackTable from './JetpackTable';
import actions from '../../actions';
import * as strings from '../../helpers/defaultStrings';
import { getColumnObject } from '../TrailerIdCard/TrailerColumns';
import { getTrailerColumns } from '../../helpers/constants';
import { vehicleTypeMapping } from '../../actions/ActionResources/constants';
import moment from 'moment';
import { filterLastHours } from '../../actions/ActionResources/transformationFunctions';
import { getAllAlerts } from '../../actions/thunks/get-all-alerts';
import {
  selectAlertItems,
  selectLivemapAlarmsHours,
  selectProductFeatures,
  selectRegion,
  selectTrailerLiveMapColumns
} from '../../selectors';

class TrailerTable extends React.Component {
  refreshTimer = null;
  constructor(props) {
    super(props);
    this.state = {
      devices: null,
      mapRoutes: null,
      visibleDevices: null,
      locationsCalled: false,
      miniDashboardHeight: 0,
      trailerlocations: {},
      // driving, parked, paused, unknown
      filterByDeviceState: {}
    };
    this.newAlertMessages = [];
  }

  componentDidMount() {
    const { visibleDevices, devices, mapRoutes, trailerLocationDetails, selectedColumnKeys } = this.props;

    const locationColumnSelected = this.isLocationColumnSelected(selectedColumnKeys);
    if (devices) {
      const devicesWithlocations = this.getDevicesWithLocations(
        locationColumnSelected,
        devices,
        trailerLocationDetails
      );
      this.setVisibility(visibleDevices, devicesWithlocations, mapRoutes);
    }
    this.updateAlertsInterval = setInterval(this.updateAlerts, 30000);
    this.updateLocationsInterval = setInterval(() => this.updateLocations(locationColumnSelected, devices), 600000);
  }

  updateAlerts = () => {
    const {
      alerts2h: { items },
      getAllAlerts,
      processingAlerts
    } = this.props;
    if (items.length > 0 && !processingAlerts) {
      getAllAlerts();
    }
  };

  updateLocations = (locationColumnSelected, devices) => {
    locationColumnSelected &&
      devices &&
      devices.forEach(device => {
        if (device.gnss) {
          const nextLocation = { longitude: device.gnss.longitude, latitude: device.gnss.latitude };
          this.memoizedUpdateTrailerlocation(device.assetId, nextLocation);
        }
      });
  };

  isLocationColumnSelected = selectedColumnKeys =>
    !!(
      selectedColumnKeys &&
      selectedColumnKeys.find(column => column === 'country' || column === 'region' || column === 'city')
    );

  getDevicesWithLocations = (locationColumnSelected, devices, trailerLocationDetails) => {
    let listDevices = null;
    if (locationColumnSelected) {
      listDevices =
        devices &&
        devices.map(device => ({
          ...device,
          location: trailerLocationDetails[device.assetId]
        }));
    } else {
      listDevices = devices;
    }
    return listDevices;
  };

  memoizedUpdateTrailerlocation = (assetId, nextLocation) => {
    const { trailerlocations } = this.state;
    const { updateTrailerlocation } = this.props;
    if (trailerlocations[assetId]) {
      if (
        nextLocation.longitude === trailerlocations[assetId].longitude &&
        nextLocation.latitude === trailerlocations[assetId].latitude
      ) {
        return;
      }
    }
    this.setState({ trailerlocations: { ...trailerlocations, [assetId]: nextLocation } });
    updateTrailerlocation(assetId, nextLocation);
  };

  componentDidUpdate(prevProps) {
    const { alerts2h, devices, visibleDevices, mapRoutes, trailerLocationDetails, selectedColumnKeys } = this.props;
    const { locationsCalled } = this.state;
    const isNewAlerts = devices && alerts2h && JSON.stringify(prevProps.alerts2h) !== JSON.stringify(alerts2h);
    const locationColumnSelected = this.isLocationColumnSelected(selectedColumnKeys);
    if (
      (devices && JSON.stringify(prevProps.devices) !== JSON.stringify(devices)) ||
      (visibleDevices && JSON.stringify(prevProps.visibleDevices) !== JSON.stringify(visibleDevices)) ||
      (trailerLocationDetails &&
        locationColumnSelected &&
        JSON.stringify(prevProps.trailerLocationDetails) !== JSON.stringify(trailerLocationDetails)) ||
      (selectedColumnKeys && JSON.stringify(prevProps.selectedColumnKeys) !== JSON.stringify(selectedColumnKeys)) ||
      isNewAlerts
    ) {
      const devicesWithlocations = this.getDevicesWithLocations(
        locationColumnSelected,
        devices,
        trailerLocationDetails
      );
      this.setVisibility(visibleDevices, devicesWithlocations, mapRoutes, isNewAlerts);
    }
    if (devices && locationColumnSelected && !locationsCalled) {
      this.updateLocations(locationColumnSelected, devices);
      this.setState({ locationsCalled: true });
    }
  }

  setVisibility = (visibleDevices, devices, mapRoutes, isNewAlerts) => {
    const {
      alerts2h: { items, lastUpdated },
      newAlertMessage,
      intl: { formatMessage },
      setStateDevices
    } = this.props;

    if (isNewAlerts) {
      this.newAlertMessages.push(newAlertMessage);
    }

    const oldestTime = Math.round(Date.now() / 1000) - 2 * 60 * 60;
    const stateDevices = devices?.map(device => ({
      ...device,
      visibility: !visibleDevices || !visibleDevices?.includes(device.assetId) ? 'Not Visible' : 'Visible',
      categoryTitle:
        device.category && strings.trailerType[device.category]
          ? formatMessage(strings.trailerType[device.category])
          : '-',
      trailerType: this.getTrailerType(device),
      trail:
        mapRoutes && mapRoutes[device.assetId]
          ? mapRoutes[device.assetId].filter(d => d.startTime >= oldestTime)
          : null,
      alerts: items
        ?.filter(alert => alert.assetId === device.assetId)
        .map(alert => ({
          ...alert,
          id: `${alert.assetId}${alert.time}`,
          datetime: moment.utc(alert.time * 1000),
          lastUpdated
        }))
    }));
    this.setState({
      devices: stateDevices,
      mapRoutes,
      visibleDevices
    });
    if (setStateDevices) {
      setStateDevices(stateDevices);
    }

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }

    this.refreshTimer = setTimeout(this.refreshGrid, 30000);
  };

  componentWillUnmount() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
    if (this.updateAlertsInterval) {
      clearInterval(this.updateAlertsInterval);
    }
    if (this.updateLocationsInterval) {
      clearInterval(this.updateLocationsInterval);
    }
  }

  refreshGrid = () => {
    const { devices, visibleDevices, mapRoutes } = this.state;

    if (devices) {
      this.setVisibility(visibleDevices, devices, mapRoutes);
    }
  };

  getTrailerType(device) {
    const {
      intl: { formatMessage }
    } = this.props;
    const metaType = device.metaDataTrailerType;
    if (vehicleTypeMapping[metaType]) {
      return formatMessage(strings.type[vehicleTypeMapping[device.metaDataTrailerType]]);
    }
    return null;
  }

  getColumns = () => {
    const { devices } = this.state;

    const {
      region,
      isMetric,
      groups,
      id,
      productFeatures,
      setGroupColumns,
      intl: { formatMessage }
    } = this.props;
    const columnsSelection = getTrailerColumns(region);
    const today = moment.utc();
    const startDate = today.clone().subtract(1, 'year').toDate();
    const endDate = today.toDate();
    return getColumnObject(columnsSelection, {
      tableId: id,
      startDate,
      endDate,
      formatMessage,
      devices,
      region,
      isMetric,
      groups,
      productFeatures,
      setGroupColumns
    });
  };

  selectTrailer = selectedTrailer => {
    const { selectedTnTTrailer, updateSelectedTnTTrailer } = this.props;
    updateSelectedTnTTrailer(
      selectedTnTTrailer && selectedTrailer.assetId === selectedTnTTrailer.assetId ? null : selectedTrailer
    );
  };

  changePage = page => {
    const { updateTnTTrailerTablePage } = this.props;
    updateTnTTrailerTablePage(page);
  };

  getFilteredDevices = () => {
    const {
      livemapSelectedDevices,
      alerts,
      livemapAlarmsHours,
      filterByDeviceState,
      filterByAlarm,
      hasSelectableDevices
    } = this.props;
    const { devices: stateDevices } = this.state;

    let filteredDevices =
      livemapSelectedDevices.length <= 0 || !stateDevices || !hasSelectableDevices
        ? stateDevices
        : stateDevices.filter(device => livemapSelectedDevices.includes(device.assetId));

    if (filterByDeviceState && Object.keys(filterByDeviceState).length > 0) {
      filteredDevices =
        filteredDevices?.filter(device => {
          const status = device.activityStatus ? device.activityStatus.status : 'unknown';
          return !!filterByDeviceState[status];
        }) || filteredDevices;
    }

    let fullFilterByAlarm = filterByAlarm;
    if (filterByAlarm && filterByAlarm.tpms) {
      fullFilterByAlarm = {
        ...filterByAlarm,
        tireExtremeUnderPressure: 1,
        tireUnderPressure: 1,
        tireExtremeOverPressure: 1,
        tireOverPressure: 1
      };
    }
    if (filterByAlarm && Object.keys(fullFilterByAlarm).length > 0) {
      const filteredAlerts = filterLastHours(alerts, livemapAlarmsHours).items;
      const assetIds = [
        ...new Set(filteredAlerts.filter(alert => !!fullFilterByAlarm[alert.alarm]).map(item => item.assetId))
      ];
      filteredDevices = filteredDevices?.filter(device => assetIds.includes(device.assetId));
    }

    return filteredDevices;
  };

  defaultOnRow = record => {
    const { setMessage } = this.props;
    return {
      onClick: () => {
        if (record.lastPosition) {
          this.selectTrailer(record);
        } else {
          setMessage('No data available for that trailer yet.');
        }
      }
    };
  };

  render() {
    const {
      processing,
      selectedTnTTrailer,
      selectedHealthTrailer,
      tnTTrailerTablePage,
      id,
      topAndBottomPadding,
      defaultSelectedColumnKeys,
      defaultColumnsLocked,
      pageSize,
      onRow,
      statusFilter,
      onChange
    } = this.props;
    const devices = this.getFilteredDevices();
    const data = statusFilter
      ? devices
        ? devices.filter(trailer => statusFilter.length <= 0 || statusFilter.includes(trailer.statusLabelId))
        : null
      : devices;
    return (
      <div className='trailer-list'>
        {devices && (
          <JetpackTable
            id={id}
            loading={processing}
            data={data}
            className='device-drawer'
            rowClassName={record => {
              if (id === 'trailerHealth') {
                return record?.assetId === selectedHealthTrailer?.assetId ? 'trailerRowSelected' : '';
              }
              return record?.assetId === selectedTnTTrailer?.assetId ? 'trailerRowSelected' : '';
            }}
            jetpackColumns={this.getColumns()}
            pagination={
              devices.length > pageSize && {
                pageSize,
                size: 'small',
                current: tnTTrailerTablePage,
                onChange: this.changePage
              }
            }
            onChange={onChange}
            columnSelector
            hideColumnSelectorBtn={this.props.hideColumnSelectorBtn}
            setColumnSelectionOpen={this.props.setColumnSelectionOpen}
            isColumnSelectionOpen={this.props.isColumnSelectionOpen}
            topAndBottomPadding={topAndBottomPadding}
            stickyTable={true}
            size='middle'
            defaultSelectedColumnKeys={defaultSelectedColumnKeys}
            defaultColumnsLocked={defaultColumnsLocked}
            rowKey='assetId'
            onRow={onRow ?? this.defaultOnRow}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const region = selectRegion(state);

  return {
    processing: state.trailersHealth.processing || state.trailerDetails.trailers.processing,
    error: state.trailerDetails.trailers.error,
    devices: state.trailerDetails.trailers.items,
    livemapSelectedDevices: state.devices.livemapSelectedDevices,
    alerts: selectAlertItems(state),
    processingAlerts: state.alerts.processing,
    alerts2h: state.alerts.alerts2h,
    notifications: state.notifications.messages,
    mapRoutes: state.devices.mapRoutes,
    region,
    selectedTnTTrailer: state.appState.selectedTnTTrailer,
    selectedHealthTrailer: state.appState.selectedHealthTrailer,
    tnTTrailerTablePage: state.appState.tnTTrailerTablePage,
    isMetric: state.auth.isMetric,
    trailerLocationDetails: state.trailerDetails.trailerLocationDetails.items,
    selectedColumnKeys: selectTrailerLiveMapColumns(state),
    livemapAlarmsHours: selectLivemapAlarmsHours(state),
    groups: state.groups.groups,
    productFeatures: selectProductFeatures(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedTnTTrailer: trailer => dispatch(actions.appState.updateSelectedTnTTrailer(trailer)),
    updateTnTTrailerTablePage: page => dispatch(actions.appState.updateTnTTrailerTablePage(page)),
    updateAlertNotifications: () => dispatch(actions.alerts.updateAlertNotifications()),
    getAllAlerts: () => dispatch(getAllAlerts()),
    setMessage: msg => dispatch(actions.messages.addWarningMessage(msg)),
    updateTrailerlocation: (assetId, location) => dispatch(actions.trailers.updateTrailerlocation(assetId, location))
  };
}

TrailerTable.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerTable));
