import { Logger } from '../app/helpers/logger';
import { worker } from '../app/web-workers/aws-web-worker';
import { API_NAMES } from '../helpers/api-names';
import { DEFAULT_PAGE_SIZE } from '../helpers/constants';

// sample contract response
// metadata:{
//       "page": 1,
//       "count": 11368,
//       "fullCount": 11368,
//       "pageSize": 100,
//   },
//   trailers:[]
// }
//
export const loadPageData = (page, pageSize) => {
  return worker.Api({
    method: 'get',
    api: API_NAMES.ODR_LIVE_MAP,
    endpoint: `v3/trailersHealth?page=${page}&pageSize=${pageSize}`
  });
};

export const loadPageDataNoReject = (page, pageSize) => {
  try {
    return loadPageData(page, pageSize);
  } catch (err) {
    Logger.error(err);
    return Promise.resolve(null);
  }
};

/**
 *
 * @param {number} fullCount
 * @param {number} pageSize
 * @param {function} loadPageDataNoReject
 */
export const getLoadAllPagesPromises = (fullCount, pageSize, loadFunc) => {
  // calculate number of requests required to load all data
  const remainder = fullCount % pageSize;
  const totalPages = Math.floor(fullCount / pageSize) + (remainder > 0 ? 1 : 0);

  const dataLoadingPromises = [];
  Logger.debug('loadTrailersHealthData.totalPages: ', totalPages);

  let remainingPages = totalPages - 1;
  while (remainingPages > 0) {
    const nextPage = totalPages - remainingPages + 1;
    dataLoadingPromises.push(loadFunc(nextPage, pageSize));
    remainingPages -= 1;
  }

  return dataLoadingPromises;
};

/**
 *
 * @param {number} size
 */
export const loadAllTrailersHealthData = async (
  size,
  // dependency injecting to make life easier on the testing side
  {
    _loadPageData = loadPageData,
    _getLoadAllPagesPromises = getLoadAllPagesPromises,
    _loadPageDataNoReject = loadPageDataNoReject
  } = {}
) => {
  const page = 1;
  const pageSize = (() => {
    if (!Number.isInteger(size) && size > 0) {
      size = DEFAULT_PAGE_SIZE;
    }

    // enforce type as str
    return size.toString();
  })();
  // first page to establish how many requests we'll need
  const data = await _loadPageData(page, pageSize);
  const fullCount = data?.metadata?.fullCount;
  const dataLoadingPromises = _getLoadAllPagesPromises(fullCount, pageSize, _loadPageDataNoReject);
  let trailersData = data.trailers;
  const results = await Promise.all(dataLoadingPromises);
  results.forEach(result => {
    if (Array.isArray(result?.trailers)) {
      trailersData = [...trailersData, ...result.trailers];
    }
  });

  Logger.debug('loadAllPages.trailersData: ', trailersData);
  return trailersData;
};
