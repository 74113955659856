import React from 'react';
import numeral from 'numeral';
import { Tooltip } from 'antd';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';

const getDuration = data => {
  if (data.durationScore != null) {
    return numeral(data.durationScore).format('0.0');
  }
  return '';
};

const Cell = ({ rowData }) => {
  const durationScore = getDuration(rowData);
  if (durationScore !== '') {
    return <p>{durationScore}</p>;
  }
  return <NoValueSpan />;
};

const DurationScoreColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.durationScore)}>
        {formatMessage(strings.columns.durationScore)}
      </Tooltip>
    ),
    key: 'durationScore',
    dataIndex: 'durationScore',
    searchKey: formatMessage(strings.columns.durationScore),
    selectionSection: selectionHeaders.healthMonitoring.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () =>
      trailers.sort('durationScore', (a, b) =>
        a.durationScore === null || a.durationScore === undefined || a.durationScore < b.durationScore ? -1 : 1
      ),
    width: 180
  };
};

const DurationScoreColumnHeader = formatMessage => formatMessage(strings.columns.durationScore);
const DurationCSVData = trailer => getDuration(trailer);
const DurationCSVColumn = {
  header: DurationScoreColumnHeader,
  data: DurationCSVData
};

export default { table: DurationScoreColumn, csv: DurationCSVColumn };
