import { defineMessages } from 'react-intl';

export const trailerType = defineMessages({
  adr_transport: {
    id: 'trailerType.adr_transport',
    defaultMessage: 'ADR Transport',
    description: 'ADR is a name do not translate'
  },
  agr_transport: {
    id: 'trailerType.agr_transport',
    defaultMessage: 'Agro Transport',
    description: 'Argo is an abbreviations of agrocultural'
  },
  animal_transport: {
    id: 'trailerType.animal_transport',
    defaultMessage: 'Animal Transport',
    description: ''
  },
  bulk_transport: {
    id: 'trailerType.bulk_transport',
    defaultMessage: 'Bulk Transport',
    description: ''
  },
  chemical_transport: {
    id: 'trailerType.chemical_transport',
    defaultMessage: 'Chemical Transport',
    description: ''
  },
  concrete_mixer: {
    id: 'trailerType.concrete_mixer',
    defaultMessage: 'Concrete Mixer',
    description: ''
  },
  container_transport: {
    id: 'trailerType.container_transport',
    defaultMessage: 'Container Transport',
    description: ''
  },
  curtain_side_trailer: {
    id: 'trailerType.curtain_side_trailer',
    defaultMessage: 'Curtain Side Trailer',
    description: ''
  },
  distribution: {
    id: 'trailerType.distribution',
    defaultMessage: 'Distribution',
    description: ''
  },
  exceptional_transport: {
    id: 'trailerType.exceptional_transport',
    defaultMessage: 'Exceptional Transport',
    description: ''
  },
  food_transport: {
    id: 'trailerType.food_transport',
    defaultMessage: 'Food Transport',
    description: ''
  },
  general_cargo: {
    id: 'trailerType.general_cargo',
    defaultMessage: 'General Cargo',
    description: ''
  },
  refrigerated_transport: {
    id: 'trailerType.refrigerated_transport',
    defaultMessage: 'Refrigerated Transport',
    description: ''
  },
  rigid_trailer: {
    id: 'trailerType.rigid_trailer',
    defaultMessage: 'Rigid Trailer',
    description: ''
  },
  road_train: {
    id: 'trailerType.road_train',
    defaultMessage: 'Road Train',
    description: ''
  },
  silo_transport: {
    id: 'trailerType.silo_transport',
    defaultMessage: 'Silo Transport',
    description: ''
  },
  tipper_trailer: {
    id: 'trailerType.tipper_trailer',
    defaultMessage: 'Tipper Trailer',
    description: ''
  },
  van: {
    id: 'trailerType.van',
    defaultMessage: 'Van',
    description: ''
  },
  vehicle_transport: {
    id: 'trailerType.vehicle_transport',
    defaultMessage: 'Vehicle Transport',
    description: ''
  },
  waste_transport: {
    id: 'trailerType.waste_transport',
    defaultMessage: 'Waste Transport',
    description: ''
  },
  other: {
    id: 'trailerType.other',
    defaultMessage: 'Other',
    description: ''
  }
});
