import { defineMessages } from 'react-intl';

export const settings = defineMessages({
  language: {
    id: 'settings.language',
    defaultMessage: 'Language',
    description: ''
  },
  defaultLanguageNotification: {
    id: 'settings.defaultLanguageNotification',
    defaultMessage: 'Default language',
    description: ''
  },
  defaultLanguageNotificationFleetConnect: {
    id: 'settings.defaultLanguageNotificationFleetConnect',
    defaultMessage: 'Default language in FleetConnect',
    description: 'FleetConnect is a name do not translate'
  },
  emailNotifications: {
    id: 'settings.emailNotifications',
    defaultMessage: 'Email notifications will be received in that language',
    description: ''
  },
  unitSystem: {
    id: 'settings.unitSystem',
    defaultMessage: 'Unit System',
    description: ''
  },
  windowSize: {
    id: 'settings.windowSize',
    defaultMessage: 'Window Size',
    description: ''
  },
  maxErrorMargin: {
    id: 'settings.maxErrorMargin',
    defaultMessage: 'Max Error Margin',
    description: 'Max abbreviation for Maximum'
  },
  maxValidDays: {
    id: 'settings.maxValidDays',
    defaultMessage: 'Max Days Valid',
    description: 'Max abbreviation for Maximum'
  },
  maxValidDaysExtraInfo: {
    id: 'settings.maxValidDaysExtraInfo',
    defaultMessage:
      'The number of days that a trailer will have a faded ‘Pass’ or ‘low performance’ ‘Status’ displayed before changing to ‘Unqualified’. Enter ‘0’ to display a faded ‘Pass’ or ‘Low performance’ indefinitely, as opposed to ‘Unqualified’.',
    description: 'Explanation of a setting; faded refers to the color of the status'
  },
  defaultUnitSystem: {
    id: 'settings.defaultUnitSystem',
    defaultMessage: 'Default unit system',
    description: ''
  },
  emailNotificationsUnitSystem: {
    id: 'settings.emailNotificationsUnitSystem',
    defaultMessage: 'Email notifications will be received with that unit system',
    description: ''
  },
  metric: {
    id: 'settings.metric',
    defaultMessage: 'Metric',
    description: ''
  },
  imperial: {
    id: 'settings.imperial',
    defaultMessage: 'Imperial',
    description: ''
  },
  lessThan10: {
    id: 'settings.lessThan10',
    defaultMessage: 'Less than 10%',
    description: ''
  },
  lessThan3: {
    id: 'settings.lessThan3',
    defaultMessage: `Less than 3%`,
    description: ''
  },
  all: {
    id: 'settings.all',
    defaultMessage: `All`,
    description: ''
  },
  fortyFiveDays: {
    id: 'settings.fortyFiveDays',
    defaultMessage: '45 days',
    description: ''
  },
  ninetyDays: {
    id: 'settings.ninetyDays',
    defaultMessage: '90 days',
    description: ''
  },
  displayTrailers: {
    id: 'settings.displayTrailers',
    defaultMessage: 'Display trailers',
    description: ''
  },
  displayTrailersThatAreInactive: {
    id: 'settings.displayTrailersThatAreInactive',
    defaultMessage: 'Display trailers that are inactive',
    description: ''
  },
  displayTrailersInfoText: {
    id: 'settings.displayTrailersInfoText',
    defaultMessage: 'Show/hide inactive trailers and trailers with unsupported devices on maps and data tables.',
    description: ''
  },
  mapStyle: {
    id: 'settings.mapStyle',
    defaultMessage: 'Mapbox Style',
    description: 'Mapbox is a name do not translate'
  },
  displayTrailersEBPMS: {
    id: 'settings.displayTrailersEBPMS',
    defaultMessage: 'Display trailers that are not EBPMS activated',
    description: 'EBPMS is a name do not translate'
  },
  hideShowEBPMSData: {
    id: 'settings.hideShowEBPMSData',
    defaultMessage: 'Show/hide trailers that are not collecting any EBPMS data',
    description: 'EBPMS is a name do not translate'
  },
  deviceAlarms: {
    id: 'settings.deviceAlarms',
    defaultMessage: 'Device alarms',
    description: ''
  },
  placesAlarms: {
    id: 'settings.placesAlarms',
    defaultMessage: 'Places notifications',
    description: ''
  },
  reportEmails: {
    id: 'settings.reportEmails',
    defaultMessage: 'Report emails',
    description: ''
  },
  displayTrailersThatAreInactiveInFleetConnected: {
    id: 'settings.displayTrailersThatAreInactiveInFleetConnected',
    defaultMessage: 'Display trailers that are inactive in FleetConnected',
    description: 'FleetConnected is a name do not translate'
  }
});
