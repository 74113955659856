import { filterLastHours } from '../../actions/ActionResources/transformationFunctions';

export const filterTrailers = (selectedAssetIds, query, alerts) =>
  Promise.all([
    checkSelectedTrailers(selectedAssetIds),
    checkActivityFilters(query),
    checkAlarmFilters(query, alerts)
  ]).then(values => {
    let filters = {};
    for (let i = 0; i < values.length; i++) {
      if (values[i]) {
        filters = { ...filters, [values[i][0]]: values[i][1] };
      }
    }
    return filters;
  });

export const checkSelectedTrailers = selectedAssetIds => {
  if (!selectedAssetIds) {
    return;
  }

  const assetIds = selectedAssetIds.split('|');
  if (!assetIds.length) {
    return;
  }

  const assetIdsMap = {};
  assetIds.forEach(assetId => (assetIdsMap[assetId] = true));
  return ['map-selection', trailer => Boolean(assetIdsMap[trailer.assetId])];
};

export const checkActivityFilters = query => {
  const activityFilters = query.get('activities')?.split('|');
  if (!activityFilters || !activityFilters.length) {
    return;
  }

  const activityFilterObject = activityFilters.reduce((res, item) => {
    if (item.length) {
      return {
        ...res,
        [item]: true
      };
    }

    return res;
  }, {});

  if (!Object.keys(activityFilterObject).length) {
    return;
  }

  const activityFilterFunction = trailer => activityFilterObject[trailer.activityStatus?.status] === true;
  return ['activityFilter', activityFilterFunction];
};

export const checkAlarmFilters = (query, alerts) => {
  const sharedFilters = query.get('alarms')?.split('|');
  if (!sharedFilters) {
    return;
  }

  const lastHours = query.get('hours');
  const alertsInLastHours = filterLastHours(alerts, lastHours).items;
  const alarmFilters = sharedFilters.reduce((res, item) => {
    if (item.length) {
      return {
        ...res,
        [item]: true
      };
    }

    return res;
  }, {});

  // Filtering trailers by trailersIds with active alarms:
  if (alarmFilters['tpms']) {
    alarmFilters['tireExtremeUnderPressure'] = true;
    alarmFilters['tireUnderPressure'] = true;
    alarmFilters['tireExtremeOverPressure'] = true;
    alarmFilters['tireOverPressure'] = true;
    delete alarmFilters['tpms'];
  }

  const assetIdsWithActiveAlarms = alertsInLastHours
    .filter(alert => alarmFilters[alert.alarm] === true)
    .map(alert => alert.assetId)
    .reduce((dic, id) => ({ ...dic, [id]: true }), {});

  const alarmsFilterName = 'alarmsFilter';
  const alarmsFilter = trailer => Boolean(assetIdsWithActiveAlarms[trailer.assetId]);
  return [alarmsFilterName, alarmsFilter];
};
