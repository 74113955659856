import { downloadFile } from './download-file';

export const arrayToCSVRow = row => {
  for (let i = 0; i < row.length; i++) {
    const element = row[i];
    if (typeof element === 'string') {
      row[i] = element.replace(/,/g, '');
    } else if (row[i] === null) {
      row[i] = '';
    } else if (typeof element === 'object') {
      row[i] = '[object]';
    }
  }
  return row.join(',') + '\r\n';
};

export const arrayToCSV = (dataArray, filename) => {
  let dataStr = '';
  for (let i = 0; i < dataArray.length; i++) {
    const row = dataArray[i];
    dataStr += arrayToCSVRow(row);
  }
  downloadFile(dataStr, filename);
};
