import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import notifications from './notifications';
import devices from './devices';
import trailerDetails from './trailerDetails';
import trailersHealth from './trailersHealth';
import auth from './auth';
import link from './link';
import settings from './settings';
import language from './language';
import appState from './appState';
import emailTemplates from './emailTemplates';
import alerts from './alerts';
import odrFiles from './odrFiles';
import trailersLoadingEvents from './trailersLoadingEvents';
import ebpmsAuth from './ebpmsAuth';
import brakingEvents from './brakingEvents';
import ebpmsTrailers from './ebpmsTrailers';
import ebpmsTrailerIDList from './ebpmsTrailerIDListReducer';
import ebpmsTrailerPerformance from './ebpmsTrailerPerformanceReducer';
import ebpmsLastValidMessageSlice from '../slices/ebpmsLastValidMessage';
import groups from './groups';
import trailerHistograms from './trailerHistograms';
import places from './places';
import trailersOdrBraking from './trailersOdrBraking';
import workshopServicePartners from './workshopServicePartners';
import liveMap from './liveMapReducer';
import trailersSlice from '../slices/trailers';
import featureFlagsSlice from '../slices/feature-flags';
import settingsSlice from '../slices/settings';
import mqttQueuedMessages from '../slices/mqtt-queued-messages';

export default history =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    devices,
    trailerDetails,
    trailersHealth,
    auth,
    link,
    settings,
    language,
    appState,
    emailTemplates,
    alerts,
    odrFiles,
    trailersLoadingEvents,
    ebpmsAuth,
    brakingEvents,
    ebpmsTrailers,
    ebpmsTrailerIDList,
    ebpmsTrailerPerformance,
    groups,
    places,
    trailerHistograms,
    trailersOdrBraking,
    workshopServicePartners,
    liveMap,
    ...trailersSlice,
    ...featureFlagsSlice,
    ...settingsSlice,
    ...mqttQueuedMessages,
    ...ebpmsLastValidMessageSlice
  });
