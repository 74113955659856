import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import TrailerHistorySparkLine from './chartHelper/TrailerHistorySparkLine/TrailerHistorSparkLineComponent';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  return (
    <TrailerHistorySparkLine
      width={100}
      height={30}
      data={
        !rowData.trail
          ? null
          : rowData.trail.map(v => ({
              time: new Date(v.startTime),
              speed: new Date(v.speed)
            }))
      }
    />
  );
};

const SpeedLast2hColumn = ({ formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.columns.last2h)}>{formatMessage(strings.columns.last2h)}</Tooltip>,
    key: 'trail',
    dataIndex: 'trail',
    searchKey: formatMessage(strings.columns.last2h),
    selectionSection: selectionHeaders.generalMonitoring.id,
    render: rowData => <Cell rowData={rowData} />,
    width: 120
  };
};

export default { table: SpeedLast2hColumn, csv: null };
