import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import moment from 'moment';
import { defaultAvatar } from './NotificationPanel';
import { getHxAlertInfoString, getTriggers } from '../../helpers/alarms/functions';
import actions from '../../actions';
import { injectIntl } from 'react-intl';
import { mobileOnly } from '../../helpers/responsive';
import 'react-reflex/styles.css';
import './Notifications.scss';
import * as strings from '../../helpers/defaultStrings';
import { tabOrModalView } from '../../helpers/googleAnalytics';
import { unitType, convert, convertInt } from '../../helpers/unitConverter';
import { thdTriggers } from '../../helpers/constants';
import { filterAlertsByFeature, getPlaceCategoryString, getTimeDate, isUsaCustomer } from '../../helpers/functions';
import EventDrawerContextInfoPanel from '../EventDrawerContext/EventDrawerContextInfoPanel';
import NotificationCentreHistory from './NotificationCentreHistory';
import SubscriptionSettings from './SubscriptionSettings';
import paths from '../../app/paths';
import { selectAlertItems, selectRegion, selectFilteredEntities, selectProductFeatures } from '../../selectors';
import { getAllAlerts } from '../../actions/thunks/get-all-alerts';
import { getHeaders, showTotal } from '../Places/PlacesAlarms/helpers/functions';
import { getisFFPlacesNotificationsPageEnabled } from '../../app/feature-flags';
import { triggerTypes } from '../../helpers/alarms/constants';

const { TabPane } = Tabs;

const PAGE_TABS = {
  default: 'default',
  subscriptionSettings: 'subscriptionSettings'
};

const PAGE_SIZE = 20;
const defaultdates = [moment(moment().subtract(2, 'd'), 'YYYY/MM/DD').startOf('day'), moment(moment(), 'YYYY/MM/DD')];
class NotificationCentre extends Component {
  optionsUpdatedTime = 0;

  constructor(props) {
    super(props);
    const { region, productFeatures } = this.props;
    this.state = {
      alerts: [],
      filteredAlerts: [],
      currentPage: 1,
      selectedEvent: null,
      filterValue: undefined,
      dates: defaultdates,
      selectedTypes: [],
      scrollTo: null
    };

    this.triggerTypes = getTriggers(region, productFeatures);
  }

  componentDidMount() {
    const { getAllAlerts } = this.props;
    const { dates } = this.state;

    getAllAlerts(dates);
  }

  refreshList = () => {
    const { getAllAlerts } = this.props;
    const { dates } = this.state;
    getAllAlerts(dates);
  };

  resetFilters = () => {
    this.setState(
      {
        dates: defaultdates,
        filterValue: undefined,
        selectedTypes: []
      },
      this.refreshList
    );
  };

  componentDidUpdate(prevProps) {
    const { alerts, devices } = this.props;
    const { scrollTo } = this.state;

    if (
      (alerts && JSON.stringify(prevProps.alerts) !== JSON.stringify(alerts)) ||
      (devices && JSON.stringify(prevProps.devices) !== JSON.stringify(devices))
    ) {
      this.updateAlerts(alerts);
    }

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
      this.setState({ scrollTo: null });
    }
  }

  updateAlerts(alerts) {
    const { devices, isMetric, places, isFFPlacesNotificationsPageEnabled, region } = this.props;
    const { formatMessage } = this.props.intl;

    if (!alerts) {
      return;
    }
    const latestDevices = devices ? Object.assign({}, ...devices.map(device => ({ [device['assetId']]: device }))) : {};
    const updatedAlerts = alerts.reduce((updated, alert) => {
      if ((alert.alarm !== 'geofence' && isFFPlacesNotificationsPageEnabled) || !isFFPlacesNotificationsPageEnabled) {
        let title, description, extraInfoValues;
        const type = alert.alarm;

        let placeData = {};
        const placeId = alert?.geofences_details?.geofences?.[0]?.id;
        let wheelInfo = '';

        switch (type) {
          case 'poweron':
            title = '';
            description = formatMessage(strings.alert.powerOnCardText);
            break;
          case 'ebsAmber':
            title = '';
            description = formatMessage(strings.alert.ebsAmberCardText);
            break;
          case 'absWarningLamp':
            title = '';
            description = formatMessage(strings.alert.absWarningCardText);
            break;
          case 'ebsRed':
            title = '';
            description = formatMessage(strings.alert.ebsRedCardText);
            break;
          case 'harshBrakeEvent':
            title = '';
            description = formatMessage(strings.alert.harshBrakeCardText);
            break;
          case 'startstop':
            extraInfoValues = {
              standstill: formatMessage(strings.alert.stoppedMovingCardText),
              moving: formatMessage(strings.alert.startedMovingCardText)
            };
            title = '';
            description = '';
            break;
          case 'tpms':
            title = '';
            description = formatMessage(strings.alert.tpmsCardText);
            break;
          case 'tireExtremeUnderPressure':
            title = '';
            description = formatMessage(strings.alert.tpmsEUPCardText);
            break;
          case 'tireUnderPressure':
            title = '';
            description = formatMessage(strings.alert.tpmsUPCardText);
            break;
          case 'tireExtremeOverPressure':
            title = '';
            description = formatMessage(strings.alert.tpmsEOPCardText);
            break;
          case 'tireOverPressure':
            title = '';
            description = formatMessage(strings.alert.tpmsOPCardText);
            break;
          case 'rss':
            title = '';
            description = formatMessage(strings.alert.rssCardText);
            break;
          case 'abs':
            title = '';
            description = formatMessage(strings.alert.absCardText);
            break;
          case 'withoutISOCable':
            title = '';
            description = formatMessage(strings.alert.isoCardText);
            break;
          case 'groteLightAnomaly':
            title = '';
            description = formatMessage(strings.alert.groteLightAnomaly);
            break;
          case 'groteLightOutage':
            title = '';
            description = formatMessage(strings.alert.groteLightOutage);
            break;
          case 'geofence':
            title = '';
            if (placeId) {
              placeData = places.find(place => place.id === placeId) || {};
            }
            alert.isEnteringAlert = alert?.geofences_details?.duration === 0;
            description = formatMessage(
              alert.isEnteringAlert ? strings.geofence.enterDescription : strings.geofence.leavingDescription,
              {
                VAL_NAME: formatMessage(getPlaceCategoryString(placeData))
              }
            );
            break;
          default:
            title = '';
            description = type && strings.alert[type] ? formatMessage(strings.alert[type]) : '';
            break;
        }
        wheelInfo =
          [
            'tpms',
            'tireExtremeUnderPressure',
            'tireUnderPressure',
            'tireExtremeOverPressure',
            'tireOverPressure'
          ].indexOf(type) >= 0 &&
          alert.tpms &&
          alert.tpms.data &&
          Array.isArray(alert.tpms.data)
            ? alert.tpms.data.reduce((agg, wheelData) => {
                if (wheelData.trigger?.some(t => thdTriggers[t] === type)) {
                  let axleNum, lrc, wheelNum;
                  if (wheelData.location) {
                    // if sent via the notification sub
                    const posAxleLetter =
                      wheelData.location.toUpperCase().indexOf('L') > 0
                        ? wheelData.location.toUpperCase().indexOf('L')
                        : wheelData.location.toUpperCase().indexOf('R') > 0
                        ? wheelData.location.toUpperCase().indexOf('R')
                        : wheelData.location.toUpperCase().indexOf('C');
                    axleNum = parseInt(wheelData.location.substr(0, posAxleLetter));
                    lrc = wheelData.location.substr(posAxleLetter, 1).toUpperCase();
                    wheelNum = parseInt(wheelData.location.substr(posAxleLetter + 1));
                  } else if (wheelData.wheel) {
                    // if part of fmshistory (not yet sent in this call)
                    const wheelHex = wheelData.wheel.toString(16);
                    axleNum = parseInt(wheelHex.substr(0, 1), 16);
                    wheelNum = parseInt(wheelHex.substr(1, 1), 16) - 8;
                    lrc = wheelNum < 0 ? 'L' : wheelNum > 0 ? 'R' : 'C';
                    wheelNum = Math.abs(wheelNum);
                  }

                  if (axleNum) {
                    const pressure = convert(
                      isMetric,
                      unitType.pressure,
                      wheelData.pressure.value || wheelData.pressure.val / 10
                    );
                    agg.push(
                      <span key={Math.round(Math.random() * 10000)}>
                        <br />
                        {this.getPressureString(lrc, pressure, axleNum, wheelNum)}
                      </span>
                    );
                  }
                }
                return agg;
              }, [])
            : Object.keys(triggerTypes.watchman).includes(type)
            ? getHxAlertInfoString(alert, formatMessage, isMetric, isUsaCustomer(region))
            : '';

        const updatedAlert = {
          ...alert,
          type,
          alarm: type,
          time: alert.time,
          placeData,
          datetime: alert.time * 1000,
          datetimeFormatted: getTimeDate(alert.time * 1000),
          avatarSVG: this.triggerTypes[type] ? this.triggerTypes[type].getAvatarSVG() : defaultAvatar,
          alarmIconClass: 'large ' + this.triggerTypes?.[type]?.class,
          description:
            alert.extraInfo && extraInfoValues && extraInfoValues[alert.extraInfo]
              ? extraInfoValues[alert.extraInfo]
              : description,
          title:
            title +
            ' ' +
            (latestDevices && latestDevices[alert.assetId]
              ? latestDevices[alert.assetId].defaultDisplayName
              : alert.assetId),
          trailer:
            latestDevices && latestDevices[alert.assetId]
              ? latestDevices[alert.assetId].defaultDisplayName
              : alert.assetId,
          assetId: alert.assetId,
          code: alert._device ? alert._device.name : null,
          speed: alert.gnss.valid ? alert.gnss.speed : null,
          longitude: alert.gnss.valid ? alert.gnss.longitude : null,
          latitude: alert.gnss.valid ? alert.gnss.latitude : null,
          alt: alert.gnss.valid ? alert.gnss.alt : null,
          satellites: alert.gnss.valid ? alert.gnss.satellites : null,
          AxleLoadSum: alert.ebs ? alert.ebs.axleload / 1000 : null,
          mileage: alert.ebs ? alert.ebs.odometer : null,
          wheelInfo,
          id: alert.id
        };
        updated.push(updatedAlert);
      }
      return updated;
    }, []);

    this.setState(
      {
        alerts: updatedAlerts.sort((a, b) => (a.datetime - b.datetime < 0 ? 1 : -1))
      },
      this.updateFilteredAlerts
    );
  }

  updateFilteredAlerts() {
    const { filterValue, alerts, selectedTypes } = this.state;
    const { productFeatures } = this.props;
    const usedSelectedTypes = [...selectedTypes];
    if (usedSelectedTypes.indexOf('tpms') >= 0) {
      usedSelectedTypes.push(
        'tireExtremeUnderPressure',
        'tireUnderPressure',
        'tireExtremeOverPressure',
        'tireOverPressure'
      );
    }

    const alertsFilteredByFeature = filterAlertsByFeature(productFeatures, alerts);

    const filteredAlerts = alertsFilteredByFeature
      .filter(alert => !filterValue || alert.trailer === filterValue)
      .filter(alert => usedSelectedTypes.length === 0 || usedSelectedTypes.includes(alert.type));

    this.setState({ filteredAlerts });
  }

  getPressureString(side, pressure, axleNum, wheelNum) {
    const {
      isMetric,
      intl: { formatMessage }
    } = this.props;
    if (side === 'L') {
      return pressure
        ? formatMessage(isMetric.pressure ? strings.alert.tpmsInfoLeftVal : strings.alert.tpmsInfoLeftPsiVal, {
            axleNum,
            wheelNum,
            pressure
          })
        : formatMessage(strings.alert.tpmsInfoLeftNoVal, { axleNum, wheelNum });
    } else if (side === 'R') {
      return pressure
        ? formatMessage(isMetric.pressure ? strings.alert.tpmsInfoRightVal : strings.alert.tpmsInfoRightPsiVal, {
            axleNum,
            wheelNum,
            pressure
          })
        : formatMessage(strings.alert.tpmsInfoRightNoVal, { axleNum, wheelNum });
    } else {
      return pressure
        ? formatMessage(isMetric.pressure ? strings.alert.tpmsInfoCentreVal : strings.alert.tpmsInfoCentrePsiVal, {
            axleNum,
            pressure
          })
        : formatMessage(strings.alert.tpmsInfoCentreNoVal, { axleNum });
    }
  }

  onChangeTab = tabKey => {
    this.props.history.push(paths.notifications + '/' + tabKey);

    switch (tabKey) {
      case PAGE_TABS.subscriptionSettings:
        tabOrModalView('configure');
        break;
      case PAGE_TABS.default:
      default:
        tabOrModalView('history');
        break;
    }
  };

  onDatePickerChange = dates => {
    const { dates: currentDates } = this.state;

    const currentStartDate = currentDates[0];
    const currentEndDate = currentDates[1];

    this.onPageChange(1);
    if (dates?.length > 0) {
      let startDate = dates[0]?.startOf('day');
      const isEndDateToday = dates[1]?.isSame(moment(), 'd');
      const endDate = isEndDateToday ? moment() : dates[1]?.endOf('day');

      if (currentStartDate !== startDate) {
        if (currentEndDate) {
          this.setState({ dates: [startDate, null], lastDates: currentDates });
        }
        this.setState({ dates: [startDate, null] });
        return;
      } else if (endDate > moment(startDate).add(31, 'd')) {
        startDate = moment(endDate).subtract(31, 'd').startOf('day');
      }
      this.setState({ dates: [startDate, endDate] }, this.refreshList);
    } else {
      this.setState({ dates: defaultdates }, this.refreshList);
    }
  };

  onPageChange = page => {
    this.setState({
      currentPage: page,
      scrollTo: 'notification-search-element'
    });
  };

  getSelectedEvent = event => ({
    ...event,
    GPSPosition_latitude: event.latitude,
    GPSPosition_longitude: event.longitude,
    timestamp: event.datetime,
    VehicleSpeed: event.speed,
    notification: true,
    eventNameId: event.trailer,
    display: event.description,
    description: null
  });

  selectEvent = event => {
    const { devices, updateSelectedTnTTrailer } = this.props;
    const selectedEvent = event ? this.getSelectedEvent(event) : null;
    const trailer = event ? devices.find(item => item.assetId === event.assetId) : null;

    updateSelectedTnTTrailer(trailer);
    this.setState({ selectedEvent });
  };

  onFilterChange = newFilterValue => {
    const { filterValue } = this.state;
    if (newFilterValue !== filterValue) {
      this.onPageChange(1);
      this.setState({ filterValue: newFilterValue }, this.updateFilteredAlerts);
    }
  };

  toggleSelectedType = type => {
    this.onPageChange(1);
    const { selectedTypes } = this.state;
    if (selectedTypes.includes(type)) {
      this.setState(
        {
          selectedTypes: selectedTypes.filter(k => k !== type) // remove
        },
        this.updateFilteredAlerts
      );
    } else {
      this.setState(
        {
          selectedTypes: [...selectedTypes, type] // add
        },
        this.updateFilteredAlerts
      );
    }
  };

  getDropDownTrailers() {
    const { alerts } = this.state;
    const dropDownTrailers = [];
    alerts.forEach(alert => {
      if (!dropDownTrailers.includes(alert.trailer)) {
        dropDownTrailers.push(alert.trailer);
      }
    });
    return dropDownTrailers;
  }

  gotoAlert = alert => {
    const { filteredAlerts } = this.state;
    const i = filteredAlerts.findIndex(a => a.id === alert.id);
    const page = Math.ceil((i + 1) / PAGE_SIZE);
    this.setState({
      currentPage: page,
      scrollTo: alert.id
    });
  };

  getExportCSVData = isMetric => {
    const { filteredAlerts } = this.state;
    return filteredAlerts.map(alert => {
      alert.speedMph = convertInt(isMetric, unitType.speed, alert.speed);
      alert.altFt = convertInt(isMetric, unitType.meterFeet, alert.alt);
      return alert;
    });
  };

  render() {
    const { currentPage, selectedEvent, selectedTypes, dates, filterValue, filteredAlerts, lastDates } = this.state;
    const { selectedTrailer, region, isMetric, match, history, location } = this.props;
    const { formatMessage } = this.props.intl;
    const activeTab = match.params?.tabId ? match.params.tabId : PAGE_TABS.default;

    const dropDownTrailers = this.getDropDownTrailers();
    const paginationConfig = filteredAlerts.length
      ? {
          position: 'both',
          pageSize: PAGE_SIZE,
          current: currentPage,
          showSizeChanger: false,
          onChange: this.onPageChange,
          className: 'notification-centre__pagination',
          showTotal: (total, range) => showTotal(total, range, formatMessage)
        }
      : false;
    const date = new Date().toISOString();
    const isMobile = mobileOnly.matches;

    return (
      <div>
        {selectedEvent && (
          <EventDrawerContextInfoPanel
            trailer={selectedTrailer}
            alert={selectedEvent}
            resetSelection={() => this.selectEvent(null)}
          />
        )}
        <Tabs defaultActiveKey={PAGE_TABS.default} activeKey={activeTab} className='notifications-screen-container'>
          <TabPane key={PAGE_TABS.default} className='history-tabpane'>
            <NotificationCentreHistory
              goToSubSettings={() => this.onChangeTab(PAGE_TABS.subscriptionSettings)}
              paginationConfig={paginationConfig}
              dropDownTrailers={dropDownTrailers}
              selectedTypes={selectedTypes}
              filterValue={filterValue}
              gotoAlert={this.gotoAlert}
              resetFilters={this.resetFilters}
              onFilterChange={this.onFilterChange}
              toggleSelectedType={this.toggleSelectedType}
              triggerTypes={this.triggerTypes}
              onDatePickerChange={this.onDatePickerChange}
              selectEvent={this.selectEvent}
              formatMessage={formatMessage}
              getExportCSVData={this.getExportCSVData}
              isMetric={isMetric}
              isMobile={isMobile}
              getHeaders={getHeaders}
              dates={dates}
              date={date}
              exportCSV={this.exportCSV}
              filteredAlerts={filteredAlerts}
              region={region}
              lastDates={lastDates}
            />
          </TabPane>
          <TabPane key={PAGE_TABS.subscriptionSettings} className='configure-tabpane'>
            <SubscriptionSettings history={history} location={location} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const region = selectRegion(state);

  return {
    devices: selectFilteredEntities(state),
    alerts: selectAlertItems(state),
    places: state.places.items,
    selectedTrailer: state.trailerDetails.trailer.item,
    region,
    productFeatures: selectProductFeatures(state),
    isMetric: state.auth.isMetric,
    isFFPlacesNotificationsPageEnabled: getisFFPlacesNotificationsPageEnabled(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllAlerts: dates => dispatch(getAllAlerts(dates)),
    updateSelectedTnTTrailer: trailer => dispatch(actions.appState.updateSelectedTnTTrailer(trailer))
  };
}

NotificationCentre.propTypes = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationCentre));
