import { v4 as uuidv4 } from 'uuid';

import { selectUser } from '../../../selectors';
import { ENV_CONFIG } from '../../../app/helpers/env-configs';
import { Logger } from '../../../app/helpers/logger';
import { worker } from '../../../app/web-workers/aws-web-worker';
import { MESSAGE_TYPES } from '../../../workers/constants';
import { handleCustomerTopic } from './handle-customer-topic';
import { handleCompanyTopic } from './handle-company-topic';
import { getShouldDisableMqttSubs } from '../../../app/feature-flags/disable-mqtt-subscriptions';
import { waitForFeatureFlags } from './helper';

const genCompanyTopic = companyCode => `${ENV_CONFIG.REACT_APP_ENV.toString().trim()}/customer/${companyCode}`;

export const subscriptionCallback =
  ({ message, onSuccess }) =>
  dispatch => {
    if (message.type === MESSAGE_TYPES.TOPIC_RESPONSE) {
      dispatch(onSuccess(message.payload));
    } else if (message.type === MESSAGE_TYPES.TOPIC_ERROR) {
      Logger.error(message.payload);
    }
  };

export const subscribeToCompanyTopic =
  ({ companyCode }) =>
  dispatch => {
    const topic = uuidv4();

    worker.subscribe(topic, (currentTopic, message) =>
      dispatch(subscriptionCallback({ message, onSuccess: handleCompanyTopic }))
    );

    worker.sendMessage({
      type: MESSAGE_TYPES.TOPIC_SUBSCRIBE,
      payload: genCompanyTopic(companyCode),
      metadata: { topic }
    });
  };

export const subscribeToCustomerTopic =
  ({ companyCode, identityId }) =>
  dispatch => {
    const topic = uuidv4();
    worker.subscribe(topic, (currentTopic, message) =>
      dispatch(subscriptionCallback({ message, onSuccess: handleCustomerTopic }))
    );

    worker.sendMessage({
      type: MESSAGE_TYPES.TOPIC_SUBSCRIBE,
      payload: `${genCompanyTopic(companyCode)}/inApp/${identityId}`,
      metadata: { topic }
    });
  };

export const subscribeToTopics = () => {
  return async (dispatch, getState) => {
    try {
      await waitForFeatureFlags(getState);
    } catch (err) {
      Logger.error('waitForFeatureFlags', err);
      // report the error and continue business as usual, assuming the feature flag as false.
      // but not a blocker for the app to continue.
    }

    const state = getState();
    const disableMqtt = getShouldDisableMqttSubs(state) ?? false;
    const user = selectUser(state);
    const companyCode = user?.companyCode;
    const identityId = user?.identity_id;

    if (!disableMqtt) {
      dispatch(subscribeToCompanyTopic({ companyCode }));
    }
    dispatch(subscribeToCustomerTopic({ companyCode, identityId }));
  };
};
