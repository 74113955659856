import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from 'antd';
import * as strings from '../../../helpers/defaultStrings';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import LocaleString from '../../Utils/LocaleString';
import * as d3 from 'd3';
import { selectFcfCustomization } from '../../../selectors';

const TrailerFCFFormSettings = ({ isOpen, handleOk, handleCancel, chartData, intl: { formatMessage } }) => {
  const fcfCustomizationSettings = useSelector(selectFcfCustomization);
  const [values, setValues] = useState(fcfCustomizationSettings);

  const colors = d3.schemeCategory10;

  return (
    <Modal
      title={<LocaleString type='short' id='fcfLabels' />}
      visible={isOpen}
      onOk={() => handleOk(values)}
      onCancel={handleCancel}
      okText={<LocaleString type='button' id='ok' />}
      className='column-selection'
      cancelText={<LocaleString type='button' id='cancel' />}
    >
      {isOpen && (
        <Form layout='vertical'>
          <Form.Item label={formatMessage(strings.short.diagramName)}>
            <Input
              placeholder={formatMessage(strings.short.diagramName)}
              value={values.title || formatMessage(strings.phrase.historyFCF)}
              onChange={evt => setValues({ ...values, title: evt.target.value })}
            />
          </Form.Item>
          {chartData.keys.map((key, index) => (
            <>
              <Form.Item
                label={
                  <>
                    <span
                      className={'livemap-fcf-wrapper__settings__item-label'}
                      style={{ backgroundColor: colors[index] }}
                    ></span>{' '}
                    {key}
                  </>
                }
              >
                <Input
                  placeholder={key}
                  value={values.options[key] || ''}
                  onChange={evt => setValues({ ...values, options: { ...values.options, [key]: evt.target.value } })}
                />
              </Form.Item>
            </>
          ))}
        </Form>
      )}
    </Modal>
  );
};

export default injectIntl(TrailerFCFFormSettings);
