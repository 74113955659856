/* eslint-disable react/display-name */
import React from 'react';
import LocaleString from '../Components/Utils/LocaleString';
import { RiseOutlined, FallOutlined, MinusOutlined } from '@ant-design/icons';
import * as strings from './defaultStrings';
import { ENV_CONFIG } from '../app/helpers/env-configs';
import { getScaleFromValues, unitType } from './unitConverter';

export const TRAILERS_THUNK_TYPE = 'trailers/fetchTrailers';
export const FEATURE_FLAGS_THUNK_TYPE = 'featureFlags/fetchFeatureFlags';
export const SETTINGS_TRAILERFIT_THUNK_TYPE_SAVE = 'settings/saveTrailerFitSettings';
export const SETTINGS_EBPMS_THUNK_TYPE_SAVE = 'settings/saveEbpmsSettings';
export const SETTINGS_TRAILERFIT_THUNK_TYPE_FETCH = 'settings/fetchTrailerFitSettings';
export const LAST_VALID_EBPMS_EVENT = 'trailers/fetchLastValidEBPMS';

export * from './api-names';
// features listed here are defined in the customer portal app
export const PRODUCT_FEATURES = {
  TRACKTRACE: 'tracktrace',
  EBPMS: 'ebpms',
  DISABLE_SELECTION_TOOL: 'disableselectiontool',
  LIVE_MAP_FCF: 'livemapfcf',
  GOHC: 'gohc',
  TPMS_REPORT: 'tpmsreport',
  GROTE_GUARDIAN: 'groteguardian',
  SHOW_EBPMS: 'showEBPMS',
  SHOW_LIVE_MAP: 'showLiveMap',
  SHOW_TRAILER_HEALTH: 'showTrailerHealth',
  SHOW_VEHICLE_ID: 'showVehicleId',
  SHOW_NOTIFICATION: 'showNotification',
  SHOW_LIGHT_MONITORING: 'showLightMonitoring',
  SHOW_TRACK_TRACE_REPORTS: 'showTrackTraceReports',
  WATCHMAN: 'hendricksonwatchman'
};

export const LOADING_STATUSES = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

export const ERROR_BOUNDARY_LEVELS = {
  CRITICAL: {
    type: 'critical',
    // app unable to display user content
    description: 'app unusable'
  },
  IMPORTANT: {
    type: 'important',
    // app should display the top bar, and should work as expected
    // side-menu doesn't load
    description: 'side-menu crash'
  },
  HIGH: {
    type: 'high',
    // side menu module crashed
    description: 'module crash'
  }
};
export const defaultIsMetric = {
  base: true,
  weight: true,
  pressure: true,
  distance: true,
  temperature: true
};

export const defaultNotMetric = {
  base: false,
  weight: false,
  pressure: false,
  distance: false,
  temperature: false
};

export const unitSystems = { metric: 'metric', imperial: 'imperial' };

export const settingsConstants = {
  maxErrorMargin: {
    0.1: 'lessThan10',
    0.03: 'lessThan3'
  },
  windowSize: {
    45: 'fortyFiveDays',
    90: 'ninetyDays'
  },
  maxValidDays: {
    0: 'noChangeValid',
    84: 'twelveWeeks'
  }
};

/**
 * @deprecated please refer to APP_SETTINGS instead
 */
export const defaultSettings = {
  maxErrorMargin: 0.1,
  windowSize: 90,
  maxValidDays: 90,
  fcfCustomization: {
    title: 'FCF',
    options: {}
  },
  displayTrailers: [],
  displayTrailersEBPMS: []
};

/**
 * @deprecated please refer to DISPLAY_TRAILERS instead
 */
export const displayTrailers = {
  inactiveTrailers: 'inactiveTrailers',
  inactiveEBPMSTrailers: 'inactiveEBPMSTrailers'
};

export const DISPLAY_TRAILERS = {
  INACTIVE_TRAILERS: 'inactiveTrailers',
  INACTIVE_EBPMS_TRAILERS: 'inactiveEBPMSTrailers'
};

export const googleMaps = {
  webURL: 'https://maps.google.com/maps'
};

export const regions = {
  america: 'america',
  default: 'default'
};

const commonColumns = [
  'imei',
  'speed',
  'odometer',
  'time',
  'visibility',
  'name',
  'trailer',
  'code',
  'trail',
  'tires',
  'activity',
  'ebsBrand',
  'ebsType',
  'alarm2h',
  'metaDataTpmsSource',
  'metaDataVin',
  'externalCode',
  'categoryTitle',
  'metaDataTrailerMake',
  'metaDataTrailerModel',
  'trailerType',
  'metaDataTrailerYear',
  'vinNumber',
  'statusLabel',
  'since',
  'healthScore',
  'recencyScore',
  'durationScore',
  'frequencyScore',
  'recentSummary',
  'odrStatus',
  'lastIntervention',
  'mileageLastIntervention',
  'daysLastIntervention',
  'lightMonitoring',
  'gohc'
];

const nonUSColumns = ['axleLoad'];

export const getTrailerColumns = region => {
  if (region === 'america') {
    return commonColumns;
  } else {
    return [...commonColumns, ...nonUSColumns];
  }
};

export const trailerColumns = {
  liveMap: {
    america: [
      'imei',
      'speed',
      'odometer',
      'time',
      'visibility',
      'name',
      'trailer',
      'code',
      'trail',
      'tires',
      'activity',
      'ebsBrand',
      'ebsType',
      'alarm2h',
      'metaDataTpmsSource',
      'metaDataVin',
      'externalCode',
      'categoryTitle',
      'metaDataTrailerMake',
      'metaDataTrailerModel',
      'trailerType',
      'metaDataTrailerYear',
      'lightMonitoring'
    ],
    default: [
      'imei',
      'speed',
      'odometer',
      'axleLoad',
      'time',
      'visibility',
      'name',
      'trailer',
      'code',
      'trail',
      'tires',
      'activity',
      'ebsBrand',
      'ebsType',
      'alarm2h',
      'metaDataTpmsSource',
      'metaDataVin',
      'externalCode',
      'categoryTitle',
      'metaDataTrailerMake',
      'metaDataTrailerModel',
      'trailerType',
      'metaDataTrailerYear',
      'lightMonitoring'
    ]
  },
  healthMap: [
    'trailer',
    'name',
    'code',
    'imei',
    'vinNumber',
    'statusLabel',
    'since',
    'healthScore',
    'recencyScore',
    'durationScore',
    'frequencyScore',
    'lastMileage',
    'recentSummary',
    'odrStatus',
    'name',
    'externalCode',
    'gohc'
  ]
};

export const COLUMNS = {
  ODOMETER: 'odometer',
  ACTIVITY: 'activity',
  LOAD: 'load',
  SPEED: 'speed',
  LAST_POSITION: 'lastPosition',
  TIRES: 'tires',
  GOHC: 'GOHC',
  RECENT_SUMMARY: 'recentSummary',
  DURATION_SCORE: 'durationScore',
  FREQUENCY_SCORE: 'frequencyScore',
  RECENCY_SCORE: 'recencyScore',
  HEALTH_SCORE: 'healthScore',
  SINCE: 'since',
  STATUS_LABEL: 'statusLabel',
  TRAILER: 'trailer',
  LIGHT_MONITORING: 'lightMonitoring',
  PLACES_NOTIFICATIONS: 'placesAlarms2h'
};

export const COLUMNS_EBPMS = {
  TIP_NUM: 'tipNum',
  EXTERNAL_CODE: 'externalCode',
  PERFORMANCE: 'performance',
  STATUS: 'status',
  COMMENT: 'comment',
  ALGORITHM: 'performanceAlgorithm',
  LOAD_PERFORMANCE: 'loadAlgorithmPerformance',
  EVENT_COUNT: 'eventCount'
};

export const DEFAULT_SELECTED_EBPMS_COLUMNS = [
  COLUMNS_EBPMS.TIP_NUM,
  COLUMNS_EBPMS.EXTERNAL_CODE,
  COLUMNS_EBPMS.PERFORMANCE,
  COLUMNS_EBPMS.STATUS,
  COLUMNS_EBPMS.COMMENT,
  COLUMNS_EBPMS.EVENT_COUNT
];
export const DEFAULT_SELECTED_EBPMS_COLUMNS_TIP = [
  COLUMNS_EBPMS.TIP_NUM,
  COLUMNS_EBPMS.EXTERNAL_CODE,
  COLUMNS_EBPMS.PERFORMANCE,
  COLUMNS_EBPMS.STATUS,
  COLUMNS_EBPMS.COMMENT,
  COLUMNS_EBPMS.ALGORITHM,
  COLUMNS_EBPMS.LOAD_PERFORMANCE,
  COLUMNS_EBPMS.EVENT_COUNT
];
export const DEFAULT_SELECTED_LIVE_MAP_COLUMNS = [
  COLUMNS.TRAILER,
  COLUMNS.ACTIVITY,
  COLUMNS.LAST_POSITION,
  COLUMNS.SPEED,
  COLUMNS.LOAD,
  COLUMNS.ODOMETER
];

export const HEALTH_TABLE_COLUMNS = [
  COLUMNS.TRAILER,
  COLUMNS.STATUS_LABEL,
  COLUMNS.SINCE,
  COLUMNS.HEALTH_SCORE,
  COLUMNS.RECENT_SUMMARY
];

export const INFO_HEADER_COLUMNS = [
  COLUMNS.TIRES,
  COLUMNS.GOHC,
  COLUMNS.RECENT_SUMMARY,
  COLUMNS.DURATION_SCORE,
  COLUMNS.FREQUENCY_SCORE,
  COLUMNS.RECENCY_SCORE,
  COLUMNS.HEALTH_SCORE,
  COLUMNS.LIGHT_MONITORING
];

export const selectionHeaders = {
  default: { title: <LocaleString type='short' id='standardSet' />, order: 0 },
  generalInfo: { id: 'generalInfo', title: <LocaleString type='columns' id='generalInfo' />, order: 1 },
  generalMonitoring: {
    id: 'generalMonitoring',
    title: <LocaleString type='columns' id='generalMonitoring' />,
    order: 2
  },
  healthMonitoring: { id: 'healthMonitoring', title: <LocaleString type='columns' id='healthMonitoring' />, order: 3 },
  trailerSystems: { id: 'trailerSystems', title: <LocaleString type='columns' id='trailerSystems' />, order: 4 },
  map: { id: 'map', title: <LocaleString type='columns' id='map' />, order: 5 },
  groups: { id: 'groups', title: <LocaleString type='short' id='groups' />, order: 6 }
};

export const trailerInfoPanel = {
  livemap: [
    'historySpeedLoad',
    'historyTPMSPressure',
    'historyTPMSTemperature',
    'historyFCF',
    'trailerDetails',
    'wheelLayout',
    'lightMonitoring'
  ],
  defaultTimeRange: 6,
  eventDrawerContextDefaultComponents: {
    historySpeedLoad: {
      order: 0,
      visible: true,
      key: 'historySpeedLoad'
    },
    wheelLayout: {
      order: 1,
      visible: true,
      key: 'wheelLayout'
    },
    historyTPMSPressure: {
      order: 2,
      visible: true,
      key: 'historyTPMSPressure'
    },
    historyTPMSTemperature: {
      order: 3,
      visible: true,
      key: 'historyTPMSTemperature'
    },
    lightMonitoring: {
      order: 4,
      visible: true,
      key: 'lightMonitoring'
    }
  }
};

export const alarms = {
  'TPMS Alarms': 'tpms',
  HarshBrakeEvent: 'harshBrakeEvent',
  RSS: 'rss',
  ABS: 'abs',
  ebsRed: 'ebsRed',
  ebsAmber: 'ebsAmber',
  geofence: 'geofence',
  withoutISOCable: 'withoutISOCable'
};

export const metrics = {
  america: [
    {
      value: 'avgAxleLoad',
      label: <LocaleString type='short' id='avgAxleLoad' />
    },
    { value: 'maxSpeed', label: <LocaleString type='short' id='maxSpeed' /> },
    {
      value: 'brakeCount',
      label: <LocaleString type='short' id='brakeCount' />
    },
    { value: 'absCount', label: <LocaleString type='short' id='absCount' /> },
    {
      value: 'latAcceleration',
      label: <LocaleString type='short' id='latAcceleration' />
    }
  ],
  default: [
    {
      value: 'avgAxleLoad',
      label: <LocaleString type='short' id='avgAxleLoad' />
    },
    {
      value: 'minAxleLoad',
      label: <LocaleString type='short' id='minAxleLoad' />
    },
    {
      value: 'maxAxleLoad',
      label: <LocaleString type='short' id='maxAxleLoad' />
    },
    { value: 'maxSpeed', label: <LocaleString type='short' id='maxSpeed' /> },
    {
      value: 'brakeCount',
      label: <LocaleString type='short' id='brakeCount' />
    },
    { value: 'absCount', label: <LocaleString type='short' id='absCount' /> },
    {
      value: 'latAcceleration',
      label: <LocaleString type='short' id='latAcceleration' />
    }
  ]
};

export const loadingEventsOptions = {
  tons: 'tons',
  events: 'events',
  monthly: 'monthly',
  weekly: 'weekly',
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  LOADED: 'LOADED',
  UNLOADED: 'UNLOADED',
  colors: ['#1f2a31', '#2F4858', '#206874', '#23897F', '#59A777', '#A0C068', '#f4d166'],
  colors2Option: ['#59A777', '#f4d166'],
  maxTons: 40,
  colorsBins: {
    LOADED: '#59A777',
    LOADING: '#59A777',
    UNLOADED: '#f4d166',
    UNLOADING: '#f4d166',
    0: '#f4d166', // 5, 8, 10
    5: '#D5C969', // 5
    8: '#CDC76A', // 8
    10: '#C0C36C', // 5, 10
    15: '#97B870', // 5
    16: '#A7BC6F', // 8
    20: '#8DB571', // 5, 10
    24: '#78AF74', // 8
    25: '#78AF74', // 5
    30: '#59A777', // 5, 10
    32: '#59A777', // 8
    35: '#419862' // 5
  }
};

export const defaultEmailSubscription = {
  subjects: {
    poweron: {
      inApp: false,
      browser: false
    },
    amberLight: {
      inApp: true,
      browser: true,
      email: false
    },
    redLight: {
      inApp: true,
      browser: true,
      email: false
    },
    harshBrakeEvent: {
      inApp: false,
      browser: false,
      email: false
    },
    startstop: {
      inApp: false,
      browser: false
    },
    tpms: {
      inApp: true,
      browser: true,
      email: false
    },
    rss: {
      inApp: false,
      browser: false,
      email: false
    },
    abs: {
      inApp: false,
      browser: false,
      email: false
    },
    withoutISOCable: {
      inApp: false,
      browser: false,
      email: false
    },
    tireExtremeOverPressure: {
      inApp: false,
      browser: false,
      email: false
    },
    tireOverPressure: {
      inApp: false,
      browser: false,
      email: false
    },
    tireUnderPressure: {
      inApp: false,
      browser: false,
      email: false
    },
    tireExtremeUnderPressure: {
      inApp: false,
      browser: false,
      email: false
    }
  }
};

export const defaultEBPMSEmailSubscription = {
  ebpms: {
    email: false
  },
  ebpmsReminder: {
    email: false
  },
  ebpmsLastReminder: {
    email: false
  },
  ebpmsImmediateAction: {
    email: false
  }
};

export const eventsListKeys = [
  'pneumaticPressure',
  'brakePressure',
  'brakePressureH2',
  'checksum',
  'supplyPressure',
  'diagnosticEventCounter',
  'supplyVoltage',
  'pressureCan',
  'speed'
];

export const unitSystemOptions = [
  {
    key: 'weight',
    unitOptions: { key: 'weight', metric: 'kilogram', imperial: 'pound' }
  },
  {
    key: 'pressure',
    unitOptions: {
      key: 'pressure',
      metric: 'bar',
      imperial: 'poundPerSquareInch'
    }
  },
  {
    key: 'distance',
    unitOptions: { key: 'distance', metric: 'kilometer', imperial: 'mile' }
  },
  {
    key: 'temperature',
    unitOptions: {
      key: 'temperature',
      metric: 'celsius',
      imperial: 'fahrenheit'
    }
  }
];

export const wheelsColors = {
  A1R1: '#000',
  A1R2: '#960200',
  A1R3: '#5d1027',
  A1L1: '#0000FF',
  A1L2: '#AA767C',
  A1L3: '#b23724',
  A2R1: '#009900',
  A2R2: '#241e4e',
  A2R3: '#f9e900',
  A2L1: '#05698b',
  A2L2: '#e79e47',
  A2L3: '#00c2d1',
  A3R1: '#7dd669',
  A3R2: '#0E6655',
  A3R3: '#bbe035',
  A3L1: '#f6af65',
  A3L2: '#FF00FF',
  A3L3: '#f2718f'
};

export const favIcons = {
  default: 'favicon.ico',
  tip: 'favicontip.ico'
};

export const livemapDatepickerBubbles = {
  minDistance: 10,
  maxDistance: 600,
  percentOpacity: 0.0406779661, // 24 / (maxDistance - minDistance);
  percentSize: 0.0271186441 // 16 / (maxDistance - minDistance);
};

const tebsfPercentageScaleValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150];
export const tebsfPercentageScale = getScaleFromValues(tebsfPercentageScaleValues, true, unitType.percentage, '>');

const pressureScaleValues = [0, 1, 1.2, 1.4, 1.7, 2, 2.5, 4.5];
export const controlPressureScale = {
  metric: getScaleFromValues(pressureScaleValues, true, unitType.pressure, '>'),
  imperial: getScaleFromValues(pressureScaleValues, false, unitType.pressure, '>')
};

const maxBrakePressureScaleValues = [4.5, 6.5, 7, 7.5, 8, 8.5, 9, 9.5];
export const maxBrakePressureScale = {
  metric: getScaleFromValues(maxBrakePressureScaleValues, true, unitType.pressure, '>'),
  imperial: getScaleFromValues(maxBrakePressureScaleValues, false, unitType.pressure, '>')
};

const brakeDemandPressureTimeScaleValues = [0, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3];
export const brakeDemandPressureTimeScale = {
  metric: getScaleFromValues(brakeDemandPressureTimeScaleValues, true, unitType.pressure, '>'),
  imperial: getScaleFromValues(brakeDemandPressureTimeScaleValues, false, unitType.pressure, '>')
};

const brakeDemandPressureApplicationsScaleValues = [0, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3];
export const brakeDemandPressureApplicationsScale = {
  metric: getScaleFromValues(brakeDemandPressureApplicationsScaleValues, true, unitType.pressure, '', '', false),
  imperial: getScaleFromValues(brakeDemandPressureApplicationsScaleValues, false, unitType.pressure, '', '', false)
};

const supplyPressureScaleValues = [0, 4.5, 6.5, 7.5, 8, 8.5, 9, 9.5, 12.5];
export const supplyPressureScale = {
  metric: getScaleFromValues(supplyPressureScaleValues, true, unitType.pressure),
  imperial: getScaleFromValues(supplyPressureScaleValues, false, unitType.pressure)
};

const downhillGradientScaleValues = [2, 0, -2, -4, -6, -10, -18, -100];
export const downhillGradientScale = getScaleFromValues(
  downhillGradientScaleValues,
  true,
  unitType.percentage,
  '',
  '>='
);

const tebseDecelerationScaleValues = [0, 0.01, 0.03, 0.05, 0.08, 0.1, 0.15, 0.39];
export const tebseDecelerationScale = getScaleFromValues(
  tebseDecelerationScaleValues,
  true,
  unitType.acceleration,
  '',
  '',
  false
);

const tebsfDecelerationScaleValues = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4];
export const tebsfDecelerationScale = getScaleFromValues(
  tebsfDecelerationScaleValues,
  true,
  unitType.acceleration,
  '',
  '',
  false
);

const tebseLatAccelerationScaleValues = [0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5];
export const tebseLatAccelerationScale = getScaleFromValues(
  tebseLatAccelerationScaleValues,
  true,
  unitType.acceleration
);

const tebsfLatAccelerationScaleValues = [0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45];
export const tebsfLatAccelerationScale = getScaleFromValues(
  tebsfLatAccelerationScaleValues,
  true,
  unitType.acceleration,
  '>'
);

const speedScaleValues = [0, 20, 40, 60, 70, 80, 90, 100, 160];
export const speedScale = {
  metric: getScaleFromValues(speedScaleValues, true, unitType.speed),
  imperial: getScaleFromValues(speedScaleValues, false, unitType.speed)
};

const surfaceMeanScaleValues = [0, 50, 100, 150, 200, 250, 300, 350];
export const surfaceMeanScale = getScaleFromValues(surfaceMeanScaleValues, true, unitType.percentage, '>');

const surfaceMaxScaleValues = [0, 200, 400, 600, 800, 1000, 1200, 1400];
export const surfaceMaxScale = getScaleFromValues(surfaceMaxScaleValues, true, unitType.percentage, '>');

const frameStressMeanScaleValues = [0, 10, 20, 30, 40, 50, 60, 70];
export const frameStressMeanScale = getScaleFromValues(frameStressMeanScaleValues, true, unitType.percentage, '>');

const frameStressMaxScaleValues = [0, 40, 80, 120, 160, 200, 240, 280];
export const frameStressMaxScale = getScaleFromValues(frameStressMaxScaleValues, true, unitType.percentage, '>');

const ecuTemperatureScaleValues = [-40, -30, -20, -10, 0, 10, 20, 30, 40, 50, 60, 65];
export const ecuTemperatureScale = {
  metric: getScaleFromValues(ecuTemperatureScaleValues, true, unitType.temp, '>', '<'),
  imperial: getScaleFromValues(ecuTemperatureScaleValues, false, unitType.temp, '>', '<')
};

export const languageCodes = {
  tr: 'tr',
  en: 'en',
  pl: 'pl',
  br: 'pt-br',
  jp: 'ja',
  nl: 'nl',
  ko: 'ko',
  no: 'no',
  cs: 'cs',
  it: 'it',
  hu: 'hu',
  fr: 'fr',
  sv: 'sv',
  pt: 'pt',
  bg: 'bg',
  es: 'es',
  ru: 'ru',
  cn: 'ch',
  ro: 'ro',
  de: 'de',
  el: 'el',
  hr: 'hr',
  fi: 'fi',
  da: 'dk'
};

export const thdTriggers = {
  ThdEOP: 'tireExtremeOverPressure',
  ThdOP: 'tireOverPressure',
  ThdEUP: 'tireExtremeUnderPressure',
  ThdUP: 'tireUnderPressure',
  Suff: 'tpms'
};

export const geozoneColors = [
  [211, 72, 62],
  [131, 49, 164],
  [67, 154, 223],
  [58, 135, 123],
  [136, 177, 83],
  [59, 75, 165],
  [248, 216, 89],
  [237, 144, 53],
  [104, 77, 67],
  [89, 109, 121],
  [198, 52, 97],
  [87, 59, 171],
  [87, 59, 171],
  [76, 169, 190],
  [93, 157, 82],
  [194, 201, 81],
  [245, 181, 63],
  [226, 92, 51],
  [226, 92, 51]
];

export const ebsBrand = {
  wabco: 'wabco'
};

export const ebpmsStatusId = {
  actionRequired: 'actionRequired',
  lowPerformance: 'lowPerformance',
  pass: 'pass',
  unqualified: 'unqualified',
  gatheringData: 'gatheringData'
};

export const ebpmsStatus = {
  actionRequired: {
    name: <LocaleString type='short' id='actionRequired' />,
    id: ebpmsStatusId.actionRequired,
    color: '#FF0303',
    order: 0,
    legend: <LocaleString type='description' id='actionRequiredMeaning' />,
    legendOld: <LocaleString type='short' id='actionRequiredOldMeaning' />
  },
  lowPerformance: {
    name: <LocaleString type='short' id='lowPerformance' />,
    id: ebpmsStatusId.lowPerformance,
    color: '#FFC000',
    order: 1,
    legend: <LocaleString type='description' id='lowPerformanceMeaning' />,
    legendOld: <LocaleString type='short' id='failOldMeaning' />
  },
  pass: {
    name: <LocaleString type='short' id='pass' />,
    id: ebpmsStatusId.pass,
    color: '#87d068',
    order: 2,
    legend: <LocaleString type='short' id='passMeaning' />,
    legendOld: <LocaleString type='short' id='passOldMeaning' />
  },
  unqualified: {
    name: <LocaleString type='short' id='unqualified' />,
    id: ebpmsStatusId.unqualified,
    color: 'gray',
    order: 3,
    legend: <LocaleString type='description' id='unqualifiedMeaning' />
  },
  gatheringData: {
    name: <LocaleString type='short' id='gatheringData' />,
    id: ebpmsStatusId.gatheringData,
    color: 'gray',
    order: 4,
    legend: <LocaleString type='short' id='unknownMeaning' />
  }
};

export const odrBrakingDistanceDivider = 10000;

export const histogramDataType = {
  AssetId: 'assetId',
  BrakePressure: 'Brake_Pressure',
  DynamicAxleLoadH: 'Dynamic axle load H',
  DynamicAxleLoadL: 'Dynamic axle load L',
  DistanceLatAcceleration: 'Lateral_Acceleration',
  MileageOverAxleLoadDistribution: 'Mileage_over_axle_load_distribution',
  RoadSurfaceMax: 'Road Surface Max',
  RoadSurfaceMean: 'Road Surface Mean',
  TimeSupplyPressure: 'Supply_Pressure',
  TimeSpeed: 'VehicleSpeed',
  TebseBrakeAppDeceleration: 'acceleration',
  TebsfBrakeAppDeceleration: 'tebsf_acceleration',
  AxleLoad: 'axle_load',
  AxleLoadH: 'h_axle_load',
  AxleLoadCD: 'axleload_h',
  AxleLoadL: 'l_axle_load',
  AxleLoadEF: 'axleload_l',
  AxleLoadSum: 'axle_load_sum',
  BrakeAppControlPressure: 'control_pressure',
  BrakeTimeControlPressure: 'control_pressure_braking_time',
  BrakeAppDownhillGrad: 'slope',
  DrawbarTrailerLoad: 'drawbar_trailer_load',
  AxleLoad24n: 'axle_load_24n',
  AxleLoadH24n: 'h_axle_load_24n',
  AxleLoadL24n: 'l_axle_load_24n',
  MaxBrakePressureApplications: 'max_brake_pressure_applications',
  BrakeDemandPressureTime: 'brake_demand_pressure_time',
  BrakeDemandPressureApplications: 'brake_demand_pressure_applications',
  FrameStressMean: 'frame_stress_mean',
  FrameStressMax: 'frame_stress_max',
  ECUTemperature: 'ECU_temperature_stress_time'
};

export const languageCode = {
  Czech: 'cs',
  Chinese: 'cn',
  Danish: 'da',
  Bulgarian: 'bg',
  German: 'de',
  EnglishUSA: 'en-us',
  French: 'fr',
  Spanish: 'es',
  Italian: 'it',
  Japanese: 'jp',
  Dutch: 'nl',
  Norwegian: 'no',
  Polish: 'pl',
  Portuguese: 'pt',
  PortugueseBrazilian: 'br',
  Russian: 'ru',
  Romanian: 'ro',
  Slovak: 'sk',
  Slovenian: 'sl',
  Swedish: 'sv',
  Turkish: 'tr'
};

export const ebpmsStates = {
  started: 'started',
  ended: 'ended',
  inactive: 'inactive'
};

export const ebpmsTrends = {
  up: { id: 'up', icon: <RiseOutlined className='safe' /> },
  down: { id: 'down', icon: <FallOutlined className='critical' /> },
  neutral: { id: 'neutral', icon: <MinusOutlined /> }
};

export const mapboxLayerLabels = [
  'road-label',
  'natural-line-label',
  'natural-point-label',
  'waterway-label',
  'water-line-label',
  'water-point-label',
  'poi-label',
  'airport-label',
  'settlement-subdivision-label',
  'settlement-label',
  'state-label',
  'country-label',
  'housenum-label'
];

export const reportType = {
  monthlyMileageReport: 'monthlyMileageReport',
  TPMSReport: 'TPMSReport'
};

export const reportVariables = {
  monthlyMileageReport: {
    subscriptionFrequencyDropDown: [
      { value: 'FIRST_OF_MONTH', stringKey: strings.reports.onTheFirst },
      { value: 'FIRST_MONDAY', stringKey: strings.reports.onTheFirstMonday }
    ],
    defaultSubscription: {
      emissionDate: 'FIRST_OF_MONTH',
      email: false,
      emissionTime: 9
    }
  },
  TPMSReport: {
    subscriptionFrequencyDropDown: [
      { value: -1, stringKey: strings.short.daily },
      { value: 1, stringKey: strings.reports.weeklyOnMondays },
      { value: 2, stringKey: strings.reports.weeklyOnTuesdays },
      { value: 3, stringKey: strings.reports.weeklyOnWednesdays },
      { value: 4, stringKey: strings.reports.weeklyOnThursdays },
      { value: 5, stringKey: strings.reports.weeklyOnFridays },
      { value: 6, stringKey: strings.reports.weeklyOnSaturdays },
      { value: 0, stringKey: strings.reports.weeklyOnSundays }
    ],
    defaultSubscription: {
      frequency: 'WEEKLY',
      email: false,
      dayOfWeek: 1, //Sunday = 0, Monday = 1, ...
      emissionTime: 9
    }
  }
};

export const bpvThreshold = {
  drawbar: { upper: 0.495, lower: 0.395, line: 0.5 },
  default: { upper: 0.445, lower: 0.355, line: 0.45 }
};

export const STATUSES_LABELS = {
  INFO: 'info',
  SAFE: 'safe',
  WARNING: 'warning',
  CRITICAL: 'critical'
};

export const TAG_MAP = {
  [STATUSES_LABELS.INFO]: 'info',
  [STATUSES_LABELS.SAFE]: 'success',
  [STATUSES_LABELS.WARNING]: 'warning',
  [STATUSES_LABELS.CRITICAL]: 'error'
};

export const dtcWarningColorMap = {
  tomato: '#B00920',
  dodgerblue: '#006285',
  '#ffc107': '#B08E13'
};

export const publicUrl = new URL(ENV_CONFIG.PUBLIC_URL, window.location.protocol + '//' + window.location.host + '/');

export const groteCableColourToLampMap = {
  red: 'stopLamp',
  green: 'rightTurn',
  yellow: 'leftTurn',
  black: 'clearanceLamps',
  brown: 'tailLamps'
};

export const GroteGuardianDisplayString = 'Grote Guardian';
export const AUTH_PROVIDER = 'scalar';

// JetpackTable uses these dynamic ids:
// trailerEbpms, eventList, odrFileList, trailerHealth, liveMap, drawerList
export const TABLE_IDENTIFIERS = {
  LIVE_MAP: 'liveMap',
  TRAILER_HEALTH: 'trailerHealth',
  TRAILER_EBPMS: 'trailerEbpms',
  EVENT_LIST: 'eventList',
  ODR_FILE_LIST: 'odrFileList',
  DRAWER_LIST: 'drawerList'
};

const WINDOW_SIZE = 90;

const COLUMNS_LOCKED = 'columnsLocked';
const COLUMNS_WIDTHS = 'columnsWidths';
const COLUMNS_SELECTION = 'columnSelection';

export const DEFAULT_APP_SETTINGS = {
  maxErrorMargin: 0.1,
  windowSize: WINDOW_SIZE,
  maxValidDays: 90,
  fcfCustomization: {
    title: 'FCF',
    options: {}
  },
  displayTrailers: [],
  displayTrailersEBPMS: [],
  livemapAlarmsHours: 2,
  tntRightPanelWidth: 0.5,
  ebpmsLeftPanelWidth: 0.5,
  tntRightPanelOpen: false,
  performanceKey: `margin01${WINDOW_SIZE}`,
  fallbackPerformanceKey: `nomargin${WINDOW_SIZE}`,

  [`${COLUMNS_LOCKED}${TABLE_IDENTIFIERS.TRAILER_EBPMS}`]: [],
  [`${COLUMNS_WIDTHS}${TABLE_IDENTIFIERS.TRAILER_EBPMS}`]: [],

  [`${COLUMNS_SELECTION}${TABLE_IDENTIFIERS.TRAILER_EBPMS}`]: DEFAULT_SELECTED_EBPMS_COLUMNS,
  [`${COLUMNS_SELECTION}${TABLE_IDENTIFIERS.TRAILER_HEALTH}`]: HEALTH_TABLE_COLUMNS,
  [`${COLUMNS_SELECTION}${TABLE_IDENTIFIERS.LIVE_MAP}`]: DEFAULT_SELECTED_LIVE_MAP_COLUMNS
};

export const ORDINAL_NUMBERS_STRING_KEYS = {
  1: strings.short.first,
  2: strings.short.second,
  3: strings.short.third,
  4: strings.short.fourth,
  5: strings.short.fifth,
  6: strings.short.sixth
};

export const DEFAULT_PAGE_SIZE = 300;
