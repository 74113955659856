import React from 'react';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';
import { detect } from 'detect-browser';
const browser = detect();

const unsupportedBrowser =
  browser.name === 'ie' || (browser.name === 'edge' && parseInt(browser.version.split('.')[0]) < 80);

const Banner = ({ intl }) =>
  !!unsupportedBrowser && <Alert message={intl.formatMessage(strings.phrase.unsupportedBrowser)} banner closable />;

export default injectIntl(Banner);
