import * as d3 from 'd3';
import React from 'react';
import moment from 'moment';
import * as strings from './defaultStrings';
import { ENV_CONFIG } from '../app/helpers/env-configs';
import {
  odrBrakingDistanceDivider as distanceDivider,
  ebpmsStatus,
  mapboxLayerLabels,
  ebpmsTrends,
  regions,
  metrics,
  alarms,
  languageCode,
  languageCodes,
  ebpmsStatusId,
  bpvThreshold,
  DISPLAY_TRAILERS
} from './constants';
import { placesVariables } from './alarms/functions';
import { triggerTypes } from './alarms/constants';

export const ALGORITHM_VERSION = {
  STANDARD: 'standard_6_5',
  LOAD: 'standard_load_6_5'
};

export function isContainsTrailerPulseBattery(trailers) {
  return trailers?.some(trailer => {
    return !!trailer.power;
  });
}

export function isUsaCustomer(region) {
  return region === regions.america || isScalar();
}

export function getRegion(customerCountry) {
  if (customerCountry === 'US' || customerCountry === 'CA') {
    return regions.america;
  }
  return null;
}

export function getMetrics(region) {
  return metrics[region] || metrics.default;
}

export function getTimeFormatLocale(formatMessage, isUS) {
  return d3.timeFormatLocale({
    dateTime: isUS ? '%a %e %b %-I:%M:%S %p %Y' : '%a %e %b %-H:%M:%S %p %Y',
    date: '%d/%m/%Y',
    time: isUS ? '%I:%M:%S%-p' : '%H:%M:%S',
    periods: isUS ? ['AM', 'PM'] : ['', ''],
    days: [
      formatMessage(strings.day.sunday),
      formatMessage(strings.day.monday),
      formatMessage(strings.day.tuesday),
      formatMessage(strings.day.wednesday),
      formatMessage(strings.day.thursday),
      formatMessage(strings.day.friday)
    ],
    shortDays: [
      formatMessage(strings.day.abbreviatedSunday),
      formatMessage(strings.day.abbreviatedMonday),
      formatMessage(strings.day.abbreviatedTuesday),
      formatMessage(strings.day.abbreviatedWednesday),
      formatMessage(strings.day.abbreviatedThursday),
      formatMessage(strings.day.abbreviatedFriday)
    ],
    months: [
      formatMessage(strings.month.january),
      formatMessage(strings.month.february),
      formatMessage(strings.month.march),
      formatMessage(strings.month.april),
      formatMessage(strings.month.may),
      formatMessage(strings.month.june),
      formatMessage(strings.month.july),
      formatMessage(strings.month.august),
      formatMessage(strings.month.september),
      formatMessage(strings.month.october),
      formatMessage(strings.month.november),
      formatMessage(strings.month.december)
    ],
    shortMonths: [
      formatMessage(strings.month.abbreviatedJanuary),
      formatMessage(strings.month.abbreviatedFebruary),
      formatMessage(strings.month.abbreviatedMarch),
      formatMessage(strings.month.abbreviatedApril),
      formatMessage(strings.month.abbreviatedMay),
      formatMessage(strings.month.abbreviatedMarch),
      formatMessage(strings.month.abbreviatedJuly),
      formatMessage(strings.month.abbreviatedAugust),
      formatMessage(strings.month.abbreviatedSeptember),
      formatMessage(strings.month.abbreviatedOctober),
      formatMessage(strings.month.abbreviatedNovember),
      formatMessage(strings.month.abbreviatedDecember)
    ]
  });
}

export function dateMultiFormat(date, isUS, timeFormatLocale) {
  const formatMillisecond = timeFormatLocale.format('.%L');
  const formatSecond = timeFormatLocale.format(':%S');
  const formatMinute = timeFormatLocale.format(isUS ? '%I:%M%-p' : '%H:%M');
  const formatHour = timeFormatLocale.format(isUS ? '%I:%M%-p' : '%H:%M');
  const formatDay = timeFormatLocale.format('%a %d');
  const formatWeek = timeFormatLocale.format('%b %d');
  const formatMonth = timeFormatLocale.format('%B');
  const formatYear = timeFormatLocale.format('%Y');
  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

export function getAvatarShape(key) {
  switch (key) {
    case alarms.withoutISOCable:
    case alarms['TPMS Alarms']:
      return 'square';
    default:
      return '';
  }
}

export const getCheckedGroups = (devices, groups) => {
  if (!devices || !devices.length || !groups) {
    return groups;
  }

  let deviceGroups = [];
  devices.forEach(device => {
    if (device.groups && device.groups.length) {
      deviceGroups = [...new Set([...deviceGroups, ...device.groups])];
    }
  });
  return groups
    .map(checkedGroup => ({
      ...checkedGroup,
      subgroups: checkedGroup.subgroups.filter(subGroup => deviceGroups.includes(subGroup.id))
    }))
    .filter(checkedGroup => checkedGroup.subgroups.length || deviceGroups.includes(checkedGroup.id));
};

export const getTitleValueDiv = (title, value) => (
  <div className='flex'>
    <strong>{title}</strong>:<div className='small-margin-left'>{value}</div>
  </div>
);

export const getFixedInternationalLanguageCode = (lang, defaultLang = false) =>
  languageCodes[lang] || defaultLang || lang;

const getMapBoxLangCode = language => {
  // mapbox supported languages as of 11/1/2022
  const mapboxSuportedLanguages = [
    'ar',
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ja',
    'ko',
    'mul',
    'pt',
    'ru',
    'vi',
    'zh-Hans',
    'zh-Hant'
  ];

  if (language === languageCode.Chinese) return 'zh-Hans';

  const mapboxLang = getFixedInternationalLanguageCode(language);
  if (mapboxSuportedLanguages.includes(mapboxLang)) return mapboxLang;

  return languageCodes.en;
};

export const setMapboxLanguage = (evt, language) => {
  const lang = getMapBoxLangCode(language);
  mapboxLayerLabels.forEach(label => evt.target.setLayoutProperty(label, 'text-field', ['get', 'name_' + lang]));
};

export const isNullOrUndefined = value => value === null || value === undefined;

export const shouldFilterOutTrailer = ({ trailer, isShowInactive }) => {
  if (isShowInactive) {
    return false;
  }
  return trailer.active !== true;
};

export const filterOutInactiveTrailersIfNeeded = ({
  trailers,
  displayTrailers,
  displayTrailersEBPMS,
  isEBPMS = false
}) => {
  let filteredTrailers = [];
  if (trailers) {
    const filterEBPMSActive = isEBPMS && !displayTrailersEBPMS.includes(DISPLAY_TRAILERS.INACTIVE_EBPMS_TRAILERS);
    const filterActive = !displayTrailers.includes(DISPLAY_TRAILERS.INACTIVE_TRAILERS);

    switch (true) {
      case filterEBPMSActive && filterActive:
        filteredTrailers = trailers?.filter(t => t.active !== false && t?.ebpms?.active !== false);
        break;
      case filterEBPMSActive:
        filteredTrailers = trailers?.filter(t => t?.ebpms?.active !== false);
        break;
      case filterActive:
        filteredTrailers = trailers?.filter(t => t.active !== false);
        break;
      default:
        filteredTrailers = trailers;
    }
  }
  return filteredTrailers;
};

export const filterByDeviceAssetIds = (items, deviceAssetIds) => {
  return items?.filter(item => deviceAssetIds.includes(item.assetId));
};

export const byOrder = (a, b) => {
  var orderA = a.order;
  var orderB = b.order;
  if (orderA < orderB) {
    return -1;
  }
  if (orderA > orderB) {
    return 1;
  }
  return 0;
};

export function getValuePerDistance(value, mileage) {
  if (!isNullOrUndefined(value) && !isNullOrUndefined(mileage)) {
    return (value / mileage) * distanceDivider;
  }
  return null;
}

export const isAllMetric = isMetric => {
  return !Object.values(isMetric).includes(false);
};

export const getDateTime = (timestamp, isWithSeconds) => {
  return `${getDate(timestamp)} ${getTime(timestamp, isWithSeconds)}`;
};

export const getTime = (timestamp, isWithSeconds) => {
  return moment(timestamp)
    .local()
    .format(isWithSeconds ? 'LTS' : 'LT');
};

export const getDate = (timestamp, isMediumDate) => {
  return moment(timestamp)
    .local()
    .format(isMediumDate ? 'll' : 'l');
};

export const getTimeDate = (timestamp, isWithSeconds, isMediumDate) => {
  return `${getTime(timestamp, isWithSeconds)} ${getDate(timestamp, isMediumDate)}`;
};

export const getMediumDateShortTime = (timestamp, isWithSeconds) => {
  return moment(timestamp)
    .local()
    .format(isWithSeconds ? 'll LTS' : 'lll');
};

export const isUserTIP = user => {
  const { REACT_APP_ENV } = ENV_CONFIG;
  const isIntegrationEnv = REACT_APP_ENV.toString().trim().toLowerCase() === 'integration';
  const TIPcompanyCode = 'ee82ab8a8e7580d319d156283b7aa007';
  const IntegrationAchimCompanyCode = 'a80c2e58ef56031f6805771c9bd720d5';
  const companyCode = user?.companyCode;

  if (companyCode === TIPcompanyCode) return true;
  if (isIntegrationEnv && companyCode === IntegrationAchimCompanyCode) return true;
  return false;
};

export const getIsTraileriABS = trailer =>
  trailer && (trailer.ebsType === 'IABS' || getIsRSSPlus(trailer) || trailer.eepromSize === 8);

export const getIsTrailerTEBSF = trailer =>
  trailer && (trailer?.data?.ODR_eeprom_dump_size === 2 ** 17 || trailer.eepromSize === 128);

export const mapErrorCodes = jsonDtcErrors => {
  return jsonDtcErrors.reduce((map, error) => {
    map[`${error.ErrorCode}`] = error.Priority;
    return map;
  }, {});
};

export const getDisplayStatus = (trailer, maxValidDays, fromDate, isLoadBPV = false) => {
  const performanceCheck = isLoadBPV ? trailer.loadAlgorithm?.performance : trailer.performance;
  if (!performanceCheck) {
    return ebpmsStatus.gatheringData;
  }
  const isUnknown = isEBPMSStatusUnknown(trailer, maxValidDays, fromDate, isLoadBPV);
  if (!isUnknown) {
    return isLoadBPV ? trailer.loadAlgorithm?.status : trailer.status;
  }
  const lastActive =
    trailer?.lastActive || trailer?.metadata?.ebs_firstconnect || trailer?.metadata?.config_ebpms_changed;

  if (lastActive) {
    const activeMoment = moment(lastActive * 1000);
    const thirtyDaysBeforeDate = moment(fromDate?.toDate()).subtract(30, 'days').startOf('day');

    // isGatheringData is true if thirtyDaysBeforeDate is older than activeMoment because the trailer has not been active long enough to have a status
    const isGatheringData = activeMoment.diff(thirtyDaysBeforeDate) > 0;

    if (isGatheringData) {
      return ebpmsStatus.gatheringData;
    }
  }

  return ebpmsStatus.unqualified;
};

export const isEBPMSStatusUnknown = (trailer, maxValidDays, fromDate, isLoadBPV) => {
  const compareDate = moment(fromDate?.toDate());
  const dateTime = isLoadBPV ? trailer.loadAlgorithm.datetime : trailer.datetime;
  const performance = isLoadBPV ? trailer.loadAlgorithm.performance : trailer.performance;
  const status = isLoadBPV ? trailer.loadAlgorithm.status : trailer.status;

  if (!dateTime || isNullOrUndefined(performance)) {
    return true;
  }
  const date = moment(dateTime);
  return (
    Object.keys(status).length === 0 ||
    (maxValidDays && date < compareDate.subtract(maxValidDays, 'days').startOf('day'))
  );
};

export const isEBPMSStatusUnknownByDisplayStatus = displayStatus => {
  if (!displayStatus) {
    return true;
  }
  return displayStatus.id === ebpmsStatus.unqualified.id || displayStatus.id === ebpmsStatus.gatheringData.id;
};

export const getEPMSTrend = trailer => {
  const statusId = trailer?.status?.id;
  const trendValue = trailer.last7Days;
  const lastInterventionTime = trailer?.lastIntervention?.time;
  const isRequiredStatus = statusId === ebpmsStatusId.lowPerformance || statusId === ebpmsStatusId.actionRequired;

  switch (true) {
    case !isRequiredStatus:
    case isNullOrUndefined(lastInterventionTime):
    case isNullOrUndefined(trendValue):
      return;
    case trendValue > 0:
      return ebpmsTrends.up;
    case trendValue < 0:
      return ebpmsTrends.down;
    default:
      return ebpmsTrends.neutral;
  }
};

export const getBPVThreshold = trailerType => {
  const isDrawBar = trailerType === 1 || trailerType === 2;
  return isDrawBar ? bpvThreshold.drawbar : bpvThreshold.default;
};

export const isInactive = ({ activityStatus, lastEBSValid }) =>
  !lastEBSValid || (activityStatus !== null && activityStatus.status === 'parked');

export const isScalar = () =>
  window.location.origin.includes('.us.') ||
  window.location.origin.includes('zf-scalar.com') ||
  window.location.origin.includes('scalar.zf.com') ||
  ENV_CONFIG.REACT_APP_IS_SCALAR;

export const isBrakePlus = () => window.location.origin.indexOf('brakeplus.') >= 0;

export const openNewTab = url => window.open(url).focus();

export const isIntegration = () => ENV_CONFIG.REACT_APP_ENV?.toString()?.trim()?.toLowerCase() === 'integration';

export const getPlaceCategoryString = place => (place?.type && strings.geofence[place.type]) || strings.short.unknown;

export const getIsShowPlaces = () => isScalar();

export const getGeozoneColor = ({ type }) => getPlacesVariable(type, 'colour');

export const getPlacesVariable = (category, variableName) =>
  (category && variableName && placesVariables?.[category]?.[variableName]) || placesVariables.other.colour;

export const getUserInitials = userName => {
  if (!userName || userName === '') return '';
  const name = userName.toString().trim().toLowerCase();
  const names = name.split(' ');
  let initials = names[0][0]; // first name initial
  if (names.length > 1) {
    initials = initials + names.pop()[0]; // add last name initial
  }
  return initials;
};

export const getGeofenceDurationString = alert => {
  const duration = alert?.geofences_details?.duration;
  if (!duration) return [strings.short.unknown];
  const timeDuration = moment.duration(duration);
  const hours = Math.round(timeDuration.asHours());
  if (hours) {
    return [strings.short.hrsVal, { VAR_AMOUNT: hours }];
  }
  return [strings.short.minsVal, { VAR_AMOUNT: Math.round(timeDuration.asMinutes()) }];
};

export const getIsShowODRData = ({ ebsType, eepromSize }) => eepromSize === 64 || getIsRSSPlus({ ebsType });

export const getIsRSSPlus = ({ ebsType }) => ebsType === 'RSS-Plus' || ebsType === 'RSS';

export const getIsLoadBPVAlgorithm = trailer =>
  trailer?.metadata?.ebpms_performance_algorithm?.value === ALGORITHM_VERSION.LOAD;

export const getTrailerPerformance = trailer =>
  getIsLoadBPVAlgorithm(trailer) ? trailer?.loadAlgorithm?.performance : trailer?.performance;
export const getTrailerBPV8Performance = trailer =>
  getIsLoadBPVAlgorithm(trailer) ? trailer?.loadAlgorithm?.performanceBPV8 : trailer?.performanceBPV8;
export const getTrailerDateTime = trailer =>
  getIsLoadBPVAlgorithm(trailer) ? trailer?.loadAlgorithm?.datetime : trailer?.datetime;
export const getTrailerStatus = trailer =>
  getIsLoadBPVAlgorithm(trailer) ? trailer?.loadAlgorithm?.status : trailer?.status;
export const getIsTrailerFallbackValues = trailer =>
  getIsLoadBPVAlgorithm(trailer) ? trailer?.loadAlgorithm?.isFallbackValues : trailer?.isFallbackValues;

export const getTrailerValueOpacity = (date, status, isFallbackValues = false) => {
  const isTooOld = !moment().isSame(date, 'day');
  const isUnknown = isEBPMSStatusUnknownByDisplayStatus(status);
  const isFade = isTooOld || isUnknown || isFallbackValues;
  return isFade ? 0.5 : 1;
};

export const getTableHeight = (
  topAndBottomPadding = 0,
  windowHeight = 0,
  actualTableHeaderHeight = 77,
  defaultTableHeaderHeight = 77
) => {
  //77 as default header height seems to be taken into account in the topAndBottomPadding calculation but when I add it there it doesnt work out. Leaving here for now.
  let newHeight = Math.floor(windowHeight - topAndBottomPadding);
  if (actualTableHeaderHeight < 250) {
    //for some reason on the health table tableHeaderHeight returns a massively inflated value onComponentDidMount on the healthTable but is fine on onCompnentDidUpdate
    newHeight = Math.floor(newHeight + defaultTableHeaderHeight - actualTableHeaderHeight);
  }
  return newHeight;
};

export const isDeepEqual = (x, y) => {
  if (x === y) {
    return true;
  }
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) {
    return false;
  }
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) {
      return false;
    }
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) {
      continue;
    }
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') {
      return false;
    }
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!isDeepEqual(x[p], y[p])) {
      return false;
    }
    // Objects and Arrays must be tested recursively
  }

  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  // allows x[ p ] to be set to undefined

  return true;
};

/**
 * Filters alerts by features from the token in Notifications History
 * This can be refactored for ABS alarms as well
 * @param alerts
 * @param productFeatures
 * @returns {*[]}
 */
export const filterAlertsByFeature = (productFeatures, alerts = []) => {
  let filtered = alerts;

  if (!productFeatures.showWatchman) {
    const watchmanAlerts = Object.keys(triggerTypes.watchman);
    filtered = alerts.filter(alert => !watchmanAlerts.includes(alert.type));
  }

  return filtered;
};

export const roundToXPlaces = (num, places = 2) => {
  if (isNullOrUndefined(num)) {
    return num;
  }
  const factor = Math.pow(10, places);
  return Math.round(num * factor) / factor;
};
