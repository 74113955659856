import MakeColumn from './ColumnComponents/MakeColumn';
import TrailerColumn from './ColumnComponents/TrailerColumn';
import NameColumn from './ColumnComponents/NameColumn';
import ImeiColumn from './ColumnComponents/ImeiColumn';
import SpeedColumn from './ColumnComponents/SpeedColumn';
import SpeedLast2hColumn from './ColumnComponents/SpeedLast2hColumn';
import OdometerColumn from './ColumnComponents/OdometerColumn';
import TrailerModelColumn from './ColumnComponents/TrailerModelColumn';
import TrailerTypeColumn from './ColumnComponents/TrailerTypeColumn';
import TpmsSourceColumn from './ColumnComponents/TpmsSourceColumn';
import EbsBrandColumn from './ColumnComponents/EbsBrandColumn';
import EbsVersionColumn from './ColumnComponents/EbsVersionColumn';
import RecentSummaryColumn from './ColumnComponents/RecentSummaryColumn';
import TrailerProductionDateColumn from './ColumnComponents/TrailerProductionDateColumn';
import ExternalCodeColumn from './ColumnComponents/ExternalCodeColumn';
import VinNumberColumn from './ColumnComponents/VinNumberColumn';
import StatusColumn from './ColumnComponents/StatusColumn';
import SinceColumn from './ColumnComponents/SinceColumn';
import HealthScoreColumn from './ColumnComponents/HealthScoreColumn';
import BatteryLevelColumn from './ColumnComponents/BatteryLevelColumn';
import VisibilityColumn from './ColumnComponents/VisibilityColumn';
import OdrStatusColumn from './ColumnComponents/OdrStatusColumn';
import FrequencyScoreColumn from './ColumnComponents/FrequencyScoreColumn';
import RecencyScoreColumn from './ColumnComponents/RecencyScoreColumn';
import DurationScoreColumn from './ColumnComponents/DurationScoreColumn';
import BrakeLiningColumn from './ColumnComponents/BrakeLiningColumn';
import GohcColumn from './ColumnComponents/GohcColumn';
import TiresColumn from './ColumnComponents/TiresColumn';
import ActivityColumn from './ColumnComponents/ActivityColumn';
import LastPositionColumn from './ColumnComponents/LastPositionColumn';
import CodeColumn from './ColumnComponents/CodeColumn';
import AxleLoadColumn from './ColumnComponents/AxleLoadColumn';
import Alarm2hColumn from './ColumnComponents/Alarm2hColumn';
import CategoryTitleColumn from './ColumnComponents/CategoryTitleColumn';
import { COLUMNS } from '../../../../../helpers/constants';
import LightMonitoringColumn from './ColumnComponents/LightMonitoringColumn';
import PlacesAlarm2hColumn from './ColumnComponents/PlacesAlarm2hColumn';

export const isInactive = ({ activityStatus, lastEBSValid }) =>
  !lastEBSValid || (activityStatus !== null && activityStatus.status === 'parked');

/**
 * Make sure the attribute name in this object and CSVColumns for each column matches the 'searchKey' in
 * the column component or the column won't show up in the table
 */
export const TableColumns = {
  trailer: TrailerColumn.table,
  metaDataTrailerMake: MakeColumn.table,
  name: NameColumn.table,
  imei: ImeiColumn.table,
  speed: SpeedColumn.table,
  trail: SpeedLast2hColumn.table,
  odometer: OdometerColumn.table,
  activity: ActivityColumn.table,
  alerts: Alarm2hColumn.table,
  metaDataTrailerModel: TrailerModelColumn.table,
  trailerType: TrailerTypeColumn.table,
  metaDataTrailerYear: TrailerProductionDateColumn.table,
  code: CodeColumn.table,
  axleLoad: AxleLoadColumn.table,
  externalCode: ExternalCodeColumn.table,
  vinNumber: VinNumberColumn.table,
  statusLabel: StatusColumn.table,
  since: SinceColumn.table,
  power: BatteryLevelColumn.table,
  visibility: VisibilityColumn.table,
  odrStatus: OdrStatusColumn.table,
  brakePads: BrakeLiningColumn.table,
  metaDataTpmsSource: TpmsSourceColumn.table,
  ebsBrand: EbsBrandColumn.table,
  ebsType: EbsVersionColumn.table,
  lastPosition: LastPositionColumn.table,
  categoryTitle: CategoryTitleColumn.table,
  lightMonitoring: LightMonitoringColumn.table,
  //
  [COLUMNS.GOHC]: GohcColumn.table,
  [COLUMNS.TIRES]: TiresColumn.table,
  [COLUMNS.RECENT_SUMMARY]: RecentSummaryColumn.table,
  [COLUMNS.FREQUENCY_SCORE]: FrequencyScoreColumn.table,
  [COLUMNS.DURATION_SCORE]: DurationScoreColumn.table,
  [COLUMNS.RECENCY_SCORE]: RecencyScoreColumn.table,
  [COLUMNS.HEALTH_SCORE]: HealthScoreColumn.table,
  [COLUMNS.PLACES_NOTIFICATIONS]: PlacesAlarm2hColumn.table
};

export const CsvColumns = {
  trailer: TrailerColumn.csv,
  imei: ImeiColumn.csv,
  vinNumber: VinNumberColumn.csv,
  name: NameColumn.csv,
  code: CodeColumn.csv,
  speed: SpeedColumn.csv,
  odometer: OdometerColumn.csv,
  metaDataTrailerMake: MakeColumn.csv,
  metaDataTrailerYear: TrailerProductionDateColumn.csv,
  externalCode: ExternalCodeColumn.csv,
  trailerModel: TrailerModelColumn.csv,
  metaDataTrailerModel: TrailerModelColumn.csv,
  trailerType: TrailerTypeColumn.csv,
  ebsBrand: EbsBrandColumn.csv,
  ebsType: EbsVersionColumn.csv,
  axleLoad: AxleLoadColumn.csv,
  power: BatteryLevelColumn.csv,
  statusLabel: StatusColumn.csv,
  lastPosition: LastPositionColumn.csv,
  time: LastPositionColumn.csv,
  brakePads: BrakeLiningColumn.csv,
  odrStatus: OdrStatusColumn.csv,
  metaDataTpmsSource: TpmsSourceColumn.csv,
  categoryTitle: CategoryTitleColumn.csv,
  lightMonitoring: LightMonitoringColumn.csv,
  //
  [COLUMNS.TIRES]: TiresColumn.csv,
  [COLUMNS.DURATION_SCORE]: DurationScoreColumn.csv,
  [COLUMNS.FREQUENCY_SCORE]: FrequencyScoreColumn.csv,
  [COLUMNS.RECENCY_SCORE]: RecencyScoreColumn.csv,
  [COLUMNS.HEALTH_SCORE]: HealthScoreColumn.csv
};
