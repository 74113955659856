import { scaleLinear } from 'd3';
import { isNullOrUndefined } from '../../../helpers/functions';

export const xScale = ({ width }, maxDomain, minDomain) => {
  return scaleLinear().domain([minDomain, maxDomain]).range([0, width]);
};

function roundUp(num, step) {
  return Math.ceil(num / step) * step;
}
function roundDown(num, step) {
  return Math.floor(num / step) * step;
}

function roundDecUp(num, p) {
  const precision = Math.pow(10, p);
  return Math.ceil(num * precision) / precision;
}

function roundDecDown(num, p) {
  const precision = Math.pow(10, p);
  return Math.floor(num * precision) / precision;
}

function getDomain(values) {
  let maxDomain;
  let minDomain;
  let step;
  let precision;
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);
  const range = maxValue - minValue;

  if (range > 1) {
    const ceilString = Math.ceil(range).toString();

    let notZeroed = false;
    if (ceilString[0] === '1') {
      for (let i = 1; i < ceilString.length - 1; i++) {
        if (ceilString[i] !== '0') {
          notZeroed = true;
          break;
        }
      }
    } else {
      notZeroed = true;
    }
    precision = notZeroed ? ceilString.length - 1 : ceilString.length - 2;
    step = Math.pow(10, precision);
    maxDomain = roundUp(maxValue, step);
    minDomain = roundDown(minValue, step);
  } else {
    const fracString = range.toString();
    let zeroCount = 0;
    for (let i = 2; i < fracString.length - 1; i++) {
      if (fracString[i] === '0') {
        zeroCount++;
      } else {
        break;
      }
    }
    precision = zeroCount + 1;
    maxDomain = roundDecUp(maxValue, precision);
    minDomain = roundDecDown(minValue, precision);
    step = 1 / Math.pow(10, precision);
  }
  return { maxDomain, minDomain };
}

export default ({ viewBox, oData, chartkey, dataTransformer, avgValue }) => {
  const xValues = oData
    ?.filter(row => !isNullOrUndefined(row[chartkey]))
    ?.map(row => (dataTransformer ? dataTransformer(row[chartkey]) : row[chartkey]));
  if (xValues?.length) {
    if (!isNullOrUndefined(avgValue)) {
      xValues.push(dataTransformer ? dataTransformer(avgValue) : avgValue);
    }
    const { maxDomain, minDomain } = getDomain(xValues);

    const xScaled = xScale(viewBox, maxDomain, minDomain);

    return [xScaled];
  } else return [];
};
