import React, { useEffect, useState } from 'react';
import LocaleString from '../Utils/LocaleString';

import './WheelLayout.scss';
import { injectIntl } from 'react-intl';
import WheelLayoutItem from './WheelLayoutItem';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import WheelLayoutInfoToolTip from './WheelLayoutInfoToolTip';
import { mostRecentValuesTPMS } from '../../helpers/trailer';

const WheelLayout = ({ selectedTrailer: trailerData, pos, onToggleWheel, selectedWheels }) => {
  const [tiresData, setTiresData] = useState({});
  const contextTPMS = useSelector(store => store.devices.contextTPMS);
  const loading = useSelector(store => store.devices.processingApi);
  const history = useSelector(store => store.devices.history.current);
  const isMetric = useSelector(store => store.auth.isMetric);

  const axleCount = parseInt(trailerData.axles) || 0;
  const tires = trailerData.tires;
  const twinTyres =
    trailerData.tyreChoice === 'twinTyres' || (tires && tires.some(t => t.location && t.location.endsWith('2')));

  useEffect(() => {
    if (!loading) {
      let localCurrentValue;
      if (!contextTPMS.default && contextTPMS.assetId === trailerData.assetId && pos && pos.time) {
        localCurrentValue = pos;
      }
      const mostRecentTiresData = mostRecentValuesTPMS(trailerData, history, localCurrentValue, isMetric);
      setTiresData(mostRecentTiresData);
    }
  }, [pos, contextTPMS, trailerData, history, loading, isMetric]);

  return (
    <>
      <h2>
        <LocaleString type='phrase' id={'wheelLayout'} />{' '}
        <span className='wheels-layout-info-icon'>
          <Tooltip title={<WheelLayoutInfoToolTip />} placement='right'>
            <InfoCircleOutlined className='tooltip-icon' />
          </Tooltip>
        </span>
      </h2>
      {axleCount > 0 && (
        <div className='wheels-layout-group' key={trailerData.assetId}>
          <div className='wheel-layout'>
            {Object.keys(new Array(axleCount).fill(1)).map(key => (
              <div className={`wheel-layout__axle wheel-layout__axle--${twinTyres ? 'twin' : 'single'}`} key={key}>
                <div className='wheel-layout__axle__tires wheel-layout__axle__tires--right'>
                  {twinTyres && (
                    <WheelLayoutItem
                      axle={parseInt(key) + 1}
                      position={'R'}
                      number={'2'}
                      tiresData={tiresData}
                      selectedWheels={selectedWheels}
                      onClick={onToggleWheel}
                    />
                  )}
                  <WheelLayoutItem
                    axle={parseInt(key) + 1}
                    position={'R'}
                    number={'1'}
                    tiresData={tiresData}
                    selectedWheels={selectedWheels}
                    onClick={onToggleWheel}
                  />
                </div>
                <div className={`wheel-layout__axle__tires wheel-layout__axle__tires--left`}>
                  <WheelLayoutItem
                    axle={parseInt(key) + 1}
                    position={'L'}
                    number={'1'}
                    tiresData={tiresData}
                    selectedWheels={selectedWheels}
                    onClick={onToggleWheel}
                  />
                  {twinTyres && (
                    <WheelLayoutItem
                      axle={parseInt(key) + 1}
                      position={'L'}
                      number={'2'}
                      tiresData={tiresData}
                      selectedWheels={selectedWheels}
                      onClick={onToggleWheel}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!axleCount && <LocaleString type='phrase' id={'numberAxlesInvalid'} />}
    </>
  );
};

export default injectIntl(WheelLayout);
