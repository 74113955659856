import React from 'react';
import { UniqueCategories, NoValueSpan } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { isUsaCustomer } from '../../../../../../helpers/functions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  const { ebsType } = rowData;

  if (ebsType) {
    return ebsType.toUpperCase();
  }
  return <NoValueSpan />;
};

const EbsVersionColumn = ({ trailers, region, formatMessage }) => {
  const isUS = isUsaCustomer(region);

  const filterFunction = checkedCategories => {
    trailers.filter('ebsType', t => {
      if (!t.ebsType) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.ebsType];
    });
  };

  const titleStr = strings.short[isUS ? 'absVersion' : 'ebsVersion'];

  return {
    title: <Tooltip title={formatMessage(titleStr)}>{formatMessage(titleStr)}</Tooltip>,
    key: 'ebsType',
    dataIndex: 'ebsType',
    searchKey: formatMessage(strings.short[isUS ? 'absVersion' : 'ebsVersion']),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <Cell rowData={rowData} />,
    categories: UniqueCategories(
      trailers.unfilteredData.map(t => t.ebsType),
      true
    ),
    sorter: () => trailers.sort('ebsType', (a, b) => (a.ebsType > b.ebsType ? -1 : 1)),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('ebsType'),
    width: 150
  };
};

// does this need to be absBrand too if in US?
const EbsVersionCSVColumnHeader = formatMessage => formatMessage(strings.short.ebsVersion);
const EbsVersionCSVData = trailer => trailer.ebsType;
const EbsVersionCSVColumn = {
  header: EbsVersionCSVColumnHeader,
  data: EbsVersionCSVData
};

export default { table: EbsVersionColumn, csv: EbsVersionCSVColumn };
