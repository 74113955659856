import React from 'react';
import { Typography, Row } from 'antd';
const { Text } = Typography;

const ValueItem = ({ title, value }) => {
  return (
    <>
      <Row gutter={[0, 16]}>
        <Text type='secondary'>{title}</Text>
      </Row>
      <Row>
        <Text>{value}</Text>
      </Row>
    </>
  );
};

export default ValueItem;
