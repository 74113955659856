import React from 'react';

const IconTPMS = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7445 13.2254C14.7391 11.9738 15.3333 10.3896 15.3333 8.66666C15.3333 4.61657 12.0501 1.33333 8 1.33333C3.94991 1.33333 0.666666 4.61657 0.666666 8.66666C0.666666 10.3896 1.26085 11.9738 2.25548 13.2254C1.90829 13.33 1.66667 13.5628 1.66667 13.8333C1.66667 14.2015 2.11438 14.5 2.66667 14.5V14.5098C4.00369 14.6064 5.89863 14.6667 8 14.6667C10.1014 14.6667 11.9963 14.6064 13.3333 14.5098V14.5C13.8856 14.5 14.3333 14.2015 14.3333 13.8333C14.3333 13.5628 14.0917 13.33 13.7445 13.2254ZM13 8.66666C13 9.33967 12.867 9.98162 12.6259 10.5677L9.96665 9.03234C9.98855 8.91378 10 8.79156 10 8.66666C10 8.54179 9.98856 8.41959 9.96666 8.30106L12.626 6.76571C12.867 7.35175 13 7.99368 13 8.66666ZM7.33333 6.78047C7.09925 6.8632 6.88516 6.98828 6.70028 7.1465L4.04164 5.61153C4.82776 4.59449 5.99713 3.88875 7.33333 3.71072V6.78047ZM3 8.66666C3 7.99367 3.13296 7.35174 3.37404 6.7657L6.03334 8.30104C6.01144 8.41958 6 8.54178 6 8.66666C6 8.79156 6.01145 8.91379 6.03336 9.03235L3.37407 10.5677C3.13297 9.98163 3 9.33967 3 8.66666ZM11.9583 11.7218C11.1722 12.7388 10.0028 13.4446 8.66667 13.6226V10.5529C8.90073 10.4701 9.1148 10.3451 9.29967 10.1869L11.9583 11.7218ZM11.9584 5.61154C11.1722 4.5945 10.0029 3.88875 8.66667 3.71072V6.78047C8.90076 6.8632 9.11484 6.98829 9.29973 7.14651L11.9584 5.61154ZM6.70034 10.1869C6.88521 10.3451 7.09927 10.4701 7.33333 10.5529V13.6226C5.99716 13.4446 4.8278 12.7389 4.04168 11.7218L6.70034 10.1869ZM8 9.33333C8.36819 9.33333 8.66667 9.03485 8.66667 8.66666C8.66667 8.29847 8.36819 7.99999 8 7.99999C7.63181 7.99999 7.33333 8.29847 7.33333 8.66666C7.33333 9.03485 7.63181 9.33333 8 9.33333Z'
    />
  </svg>
);

export default IconTPMS;
