import { defineMessages } from 'react-intl';

// Trailer Types
export const type = defineMessages({
  centralAxle: {
    id: 'type.centralAxle',
    defaultMessage: 'Central axle trailer',
    description: ''
  },
  dolly: {
    id: 'type.dolly',
    defaultMessage: 'Dolly',
    description: 'Dolly is a trailer type'
  },
  drawbar: {
    id: 'type.drawbar',
    defaultMessage: 'Drawbar trailer',
    description: 'Drawbar is a trailer type'
  },
  semi: {
    id: 'type.semi',
    defaultMessage: 'Semi-trailer',
    description: 'Semi s a trailer type'
  }
});
