import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

const TrailerProductionDateColumn = ({ trailers, formatMessage }) => {
  return {
    title: (
      <Tooltip title={formatMessage(strings.short.trailerProductionDate)}>
        {formatMessage(strings.short.trailerProductionDate)}
      </Tooltip>
    ),
    key: 'metaDataTrailerYear',
    dataIndex: 'metaDataTrailerYear',
    searchKey: formatMessage(strings.short.trailerProductionDate),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => rowData.metaDataTrailerYear || <NoValueSpan />,
    sorter: () =>
      trailers.sort('metaDataTrailerYear', (a, b) =>
        !a.metaDataTrailerYear || a.metaDataTrailerYear < b.metaDataTrailerYear ? -1 : 1
      ),
    width: 200
  };
};

const TrailerProductionDateHeader = formatMessage => formatMessage(strings.short.trailerProductionDate);
const TrailerProductionDateCSVData = trailer => trailer.metaDataTrailerYear;
const TrailerProductionDateCSVColumn = {
  header: TrailerProductionDateHeader,
  data: TrailerProductionDateCSVData
};

export default { table: TrailerProductionDateColumn, csv: TrailerProductionDateCSVColumn };
