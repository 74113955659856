import { defineMessages } from 'react-intl';

export const navMenu = defineMessages({
  mapView: {
    id: 'navMenu.mapView',
    defaultMessage: 'Map view',
    description: ''
  },
  health: {
    id: 'navMenu.health',
    defaultMessage: 'Health',
    description: ''
  },
  trailerIdCard: {
    id: 'navMenu.trailerIdCard',
    defaultMessage: 'Trailer ID card',
    description: ''
  },
  simulator: {
    id: 'navMenu.simulator',
    defaultMessage: 'Simulator',
    description: ''
  },
  simulatorv2: {
    id: 'navMenu.simulatorv2',
    defaultMessage: 'Simulator v2',
    description: ''
  },
  notifications: {
    id: 'navMenu.notifications',
    defaultMessage: 'Notifications',
    description: ''
  },
  help: {
    id: 'navMenu.help',
    defaultMessage: 'Help',
    description: ''
  },
  report: {
    id: 'navMenu.report',
    defaultMessage: 'Report',
    description: ''
  }
});
