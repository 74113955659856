import React from 'react';
const IconPlaces = () => (
  <span role='img' aria-label='places' className='anticon anticon-calculator'>
    <svg
      viewBox='0 0 12 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width='0.9em'
      focusable='false'
      style={{ margin: '0 2px' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.37153 6.21793C3.37153 4.76744 4.54744 3.59152 5.99793 3.59152C7.44842 3.59152 8.62434 4.76744 8.62434 6.21793C8.62434 7.66842 7.44842 8.84433 5.99793 8.84433C4.54744 8.84433 3.37153 7.66842 3.37153 6.21793ZM6.63775 7.76255C6.84058 7.67854 7.0248 7.55527 7.17982 7.39981C7.49618 7.08345 7.66928 6.66412 7.66928 6.21793C7.66928 5.77174 7.49618 5.35241 7.17982 5.03605C6.86345 4.71968 6.44412 4.54658 5.99793 4.54658C5.55174 4.54658 5.13242 4.71968 4.81605 5.03605C4.50118 5.35241 4.32659 5.77174 4.32659 6.21793C4.32625 6.43746 4.36931 6.6549 4.45332 6.85774C4.53732 7.06057 4.66059 7.24479 4.81605 7.39981C5.13242 7.71468 5.55174 7.88927 5.99793 7.88927C6.21747 7.88962 6.43491 7.84655 6.63775 7.76255Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91814 2.25445C10.4245 2.74663 10.8295 3.33318 11.1105 3.98101C11.4015 4.65253 11.5492 5.36584 11.5492 6.10004C11.5492 7.15507 11.297 8.20265 10.7986 9.21292C10.4001 10.0262 9.84352 10.8201 9.14216 11.5722C7.94386 12.8585 6.68736 13.645 6.33071 13.8554C6.22774 13.9166 6.11284 13.9464 5.99644 13.9464C5.87887 13.9466 5.76341 13.9152 5.66217 13.8554C5.30552 13.645 4.04902 12.8585 2.85073 11.5722C2.14936 10.8186 1.59274 10.0262 1.1943 9.21292C0.69887 8.20116 0.446676 7.15209 0.446676 6.09854C0.446676 5.36435 0.594411 4.65104 0.885404 3.97952C1.16672 3.3319 1.57173 2.74541 2.07773 2.25295C2.58809 1.75603 3.18052 1.36505 3.8416 1.09197C4.52506 0.809926 5.25031 0.666668 5.99793 0.666668C6.74556 0.666668 7.47081 0.809926 8.15427 1.09346C8.8113 1.36365 9.41015 1.75782 9.91814 2.25445ZM1.52111 6.10004C1.52111 9.70089 5.01453 12.1766 5.99793 12.8018C6.98134 12.1766 10.4748 9.70089 10.4748 6.10004C10.4748 4.93756 10.0107 3.84521 9.16752 3.02297C8.32141 2.19625 7.19623 1.74111 5.99793 1.74111C4.79964 1.74111 3.67446 2.19625 2.82834 3.02148C1.98521 3.84521 1.52111 4.93756 1.52111 6.10004Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.35191 12.3434C2.4185 12.3292 2.48775 12.3506 2.53562 12.3991C2.80298 12.6695 3.06977 12.9162 3.32679 13.1387C3.37031 13.1764 3.34948 13.2483 3.29257 13.2569C2.82456 13.3277 2.40383 13.4156 2.04285 13.5159C1.93399 13.5461 1.83352 13.5767 1.74117 13.6072C1.68237 13.6266 1.68237 13.7067 1.74117 13.7261C1.83352 13.7566 1.93399 13.7872 2.04285 13.8174C3.0118 14.0866 4.41124 14.2667 6 14.2667C7.58876 14.2667 8.9882 14.0866 9.95715 13.8174C10.066 13.7872 10.1665 13.7566 10.2588 13.7261C10.3176 13.7067 10.3176 13.6266 10.2588 13.6072C10.1665 13.5767 10.066 13.5461 9.95715 13.5159C9.59462 13.4152 9.17182 13.327 8.70138 13.256C8.64446 13.2474 8.62361 13.1755 8.66713 13.1378C8.92422 12.9152 9.19107 12.6684 9.45847 12.3978C9.50632 12.3494 9.57553 12.3279 9.6421 12.3421C11.0755 12.6466 12 13.1267 12 13.6667C12 14.5871 9.31371 15.3333 6 15.3333C2.68629 15.3333 0 14.5871 0 13.6667C0 13.1274 0.921958 12.648 2.35191 12.3434ZM11.0366 13.3513C11.0366 13.3513 11.0336 13.3558 11.0237 13.3645C11.0311 13.3556 11.0366 13.3513 11.0366 13.3513ZM0.963355 13.3513C0.963355 13.3513 0.968919 13.3556 0.976341 13.3645C0.966449 13.3558 0.963355 13.3513 0.963355 13.3513ZM0.963355 13.982C0.963355 13.982 0.966449 13.9775 0.976341 13.9688C0.968919 13.9778 0.963355 13.982 0.963355 13.982ZM11.0237 13.9689C11.0336 13.9775 11.0366 13.982 11.0366 13.982C11.0366 13.982 11.0311 13.9778 11.0237 13.9689Z'
      />
    </svg>
  </span>
);

export default IconPlaces;
