import actionTypes from './actionTypes';
import { ENV_CONFIG } from '../app/helpers/env-configs';

function getEmailTemplateRequest(templateType) {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_REQUEST,
    templateType
  };
}

function getEmailTemplateSuccess(templateType, template) {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_SUCCESS,
    templateType,
    template
  };
}

function getEmailTemplateFailure(templateType, error) {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_FAILURE,
    templateType,
    error
  };
}

function getEmailTemplate(templateType) {
  return dispatch => {
    dispatch(getEmailTemplateRequest(templateType));
    fetch(`${ENV_CONFIG.REACT_APP_API_GATEWAY_TRAILERFIT}/emailTemplate/${templateType}.html`, {
      headers: { 'X-transics-token': window.localStorage.getItem('transics-token') }
    })
      .then(res => {
        return res.text();
      })
      .then(html => {
        return dispatch(getEmailTemplateSuccess(templateType, html));
      })
      .catch(err => dispatch(getEmailTemplateFailure(templateType, err)));
  };
}

export default {
  getEmailTemplate
};
