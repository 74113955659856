import * as d3 from 'd3';
import Chart from '../chart';

import './TrailerHistorySparkLine.scss';

export default class TrailerHistorySparkLine extends Chart {
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g').attr('class', 'main');

    this.areas = this.main.append('g').attr('class', 'areas');

    this.empty = this.main.append('line').attr('class', 'empty').style('opacity', 0);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state) {
      this.drawChart(state);
    }
  }

  drawChart(state) {
    this.data = (state.data || []).map(d => ({ ...d, time: new Date(d.time * 1000) })).sort((a, b) => a.time - b.time);

    this.x = d3
      .scaleTime()
      .range([0, this.props.width])
      .domain(this.data ? d3.extent(this.data, d => d.time) : null);

    const ySpeed = d3.scaleLinear().range([this.props.height, 0]).domain([0, 120]).nice();
    const yLoad = d3
      .scaleLinear()
      .range([this.props.height, 0])
      .domain([0, d3.max(this.data, d => d.axleLoad)])
      .nice();

    const area = (accessor, yScale) =>
      d3
        .area()
        .curve(d3.curveStepAfter)
        .x(d => this.x(d.time))
        .y0(yScale(0))
        .y1(d => yScale(d[accessor]));

    const line = (accessor, yScale) =>
      d3
        .line()
        .curve(d3.curveStepAfter)
        .x(d => this.x(d.time))
        .y(d => yScale(d[accessor]));

    const dataSeries = [
      {
        color: '#00abe7',
        accessor: 'speed',
        scale: ySpeed,
        data: this.data.filter(d => d.speed !== null && d.speed !== undefined)
      },
      {
        color: 'tomato',
        accessor: 'axleLoad',
        scale: yLoad,
        data: this.data.filter(d => d.axleLoad)
      }
    ];

    const areaCurve = this.areas.selectAll('.areaCurve').data(dataSeries.slice(0, 1));

    const areaCurveEnter = areaCurve.enter().append('path').attr('class', 'areaCurve');

    areaCurveEnter
      .merge(areaCurve)
      .transition()
      .duration(750)
      .attr('fill', d => d.color)
      .attr('opacity', 0.2)
      .attr('d', d => area(d.accessor, d.scale)(d.data));

    const lineCurve = this.areas.selectAll('.lineCurve').data(dataSeries);

    const lineCurveEnter = lineCurve.enter().append('path').attr('class', 'lineCurve');

    lineCurveEnter
      .merge(lineCurve)
      .transition()
      .duration(750)
      .attr('fill', 'none')
      .attr('stroke', d => d.color)
      .attr('stroke-width', 1)
      .attr('d', d => line(d.accessor, d.scale)(d.data));

    if (!state.data || state.data.length === 0) {
      this.empty
        .attr('x1', 0)
        .attr('x2', this.props.width)
        .attr('y1', this.props.height)
        .attr('y2', this.props.height)
        .style('stroke', 'lightgrey')
        .style('opacity', 1);
    } else {
      this.empty.style('opacity', 0);
    }
  }
}
