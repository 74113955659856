import React from 'react';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Checkbox, Row, InputNumber, Button, Table, message } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { selectionHeaders } from '../../helpers/constants';
import { injectIntl } from 'react-intl';
import LocaleString from '../Utils/LocaleString';
import * as strings from '../../helpers/defaultStrings';
import './ColumnSelection.scss';

const DragHandle = sortableHandle(() => (
  <span className='sortable-handle'>
    <svg width='20' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8' cy='5' r='2' fill='black' />
      <circle cx='16' cy='5' r='2' fill='black' />
      <circle cx='8' cy='12' r='2' fill='black' />
      <circle cx='16' cy='12' r='2' fill='black' />
      <circle cx='8' cy='19' r='2' fill='black' />
      <circle cx='16' cy='19' r='2' fill='black' />
    </svg>
  </span>
));

const RegularItem = ({
  value,
  dynamicWidths,
  dispatch,
  colLocked,
  filter,
  regular,
  showSubtitle,
  intl,
  analyticsColumnsWidths,
  setAnalyticsColumnsWidths
}) => {
  const setColWidth = (val, key) => {
    if (!isNaN(val)) {
      dispatch({ type: 'updateColWidth', value: { [key]: Math.max(val, 80) } });
      if (!analyticsColumnsWidths.includes(key)) {
        setAnalyticsColumnsWidths([...analyticsColumnsWidths, key]);
      }
    }
  };

  const setColLocked = (val, key, title, formatMessage) => {
    message.success(
      <span>
        {title}
        {'  '}
        {val ? formatMessage(strings.short.pinned) : formatMessage(strings.short.unpinned)}
      </span>
    );
    dispatch({ type: 'updateColLocked', value: { [key]: val } });
  };

  return (
    <Row className={`list-row-item ${regular ? '' : 'list-row-draggable'} ${!filter(value) ? 'hidden' : ''}`}>
      <span className='handle-element'>{!regular && <DragHandle />}</span>
      <span className='row-element space-top'>
        <Checkbox value={value.key} />
      </span>
      {!dynamicWidths && (
        <InputNumber
          key={`${value.key}${value.width}`}
          className='row-input'
          defaultValue={value.width}
          onChange={val => setColWidth(val, value.key)}
        />
      )}

      <div className={`row-title-wrapper ${dynamicWidths ? 'dynamic-width' : 'not-dynamic-width'}`}>
        <span>{value.title}</span>

        {showSubtitle && (
          <>
            <span className='row-subtitle'>
              {!value.selectionSection ? (
                <LocaleString type='short' id='standardSet' />
              ) : (
                selectionHeaders[value.selectionSection]?.title
              )}
            </span>
          </>
        )}
      </div>
      <span className='row-element'>
        {!regular && (
          <Button
            type='link'
            className='selection-lock'
            onClick={() => setColLocked(!colLocked[value.key], value.key, value.searchKey, intl.formatMessage)}
          >
            {colLocked[value.key] ? <PushpinFilled /> : <PushpinOutlined style={{ color: 'gray' }} />}
          </Button>
        )}
      </span>
    </Row>
  );
};
const RegItem = injectIntl(RegularItem);
const SortableItem = SortableElement(RegItem);

export const RegList = ({
  items,
  dynamicWidths,
  colLocked,
  dispatch,
  filter,
  regular,
  showSubtitle,
  hasHeaders,
  analyticsColumnsWidths,
  setAnalyticsColumnsWidths
}) => {
  return (
    <ul className='reg-list-container'>
      {hasHeaders && (
        <Row className='list-row-item'>
          <span className='handle-element'></span>
          <span className='row-element'>
            <LocaleString type='short' id='show' />
          </span>
          {!dynamicWidths && (
            <span className='row-element'>
              <LocaleString type='short' id='width' />
            </span>
          )}
          <span className={`row-title-header ${dynamicWidths ? 'dynamic-width' : 'not-dynamic-width'}`}>
            <LocaleString type='short' id='columnName' />
          </span>
          <span className='row-element'>{!regular && <LocaleString type='short' id='pin' />}</span>
        </Row>
      )}
      {!items?.length && <Table />}
      {items?.map((value, index) => {
        return regular ? (
          <RegItem
            showSubtitle={showSubtitle}
            key={`${value.key}-${index}`}
            index={index}
            value={value}
            filter={filter}
            dispatch={dispatch}
            regular={true}
            colLocked={colLocked}
            analyticsColumnsWidths={analyticsColumnsWidths}
            setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
          />
        ) : (
          <SortableItem
            showSubtitle={showSubtitle}
            key={`${value.key}-${index}`}
            index={index}
            value={value}
            filter={filter}
            dynamicWidths={dynamicWidths}
            dispatch={dispatch}
            colLocked={colLocked}
            analyticsColumnsWidths={analyticsColumnsWidths}
            setAnalyticsColumnsWidths={setAnalyticsColumnsWidths}
          />
        );
      })}
    </ul>
  );
};
export const SortableList = SortableContainer(RegList);
