import actionTypes from '../actions/actionTypes';

export const initialSettingsState = {
  language: null
};

export default function userReducer(state = initialSettingsState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
}
