/* eslint-disable no-loop-func */
import actionTypes from '../actions/actionTypes';

export const initialState = {
  messages: null, // Notification messages
  lastTimeBasedMessages: null, // used to keep track of the last message event so we can trigger a notification based on it vs next one we get
  processing: false,
  emailSubProcessing: false,
  emailSubError: null,
  emailEBPMSSubProcessing: false,
  emailEBPMSSubError: null,
  isNotificationSubProcessing: false,
  getNotificationSubError: null,
  error: null,
  buffer: [],
  readMessages: null, // used to keep track of read messages
  deletedMessages: null, // used to keep track of deleted messages
  lastMQTTProcessedTime: 0,
  ebpmsEmailPreferences: {},
  ebpmsEmailPreferencesProcessing: false,
  notificationTypesToListenTo: {},
  notificationTypesToListenToDefaultValues: {
    // default values. Omit if not an option for that type
    poweron: {
      website: 0
    },
    ebsAmber: {
      website: 1,
      email: 0
    },
    absWarningLamp: {
      website: 1,
      email: 0
    },
    ebsRed: {
      website: 1,
      email: 0
    },
    harshBrakeEvent: {
      website: 0,
      email: 0
    },
    startstop: {
      website: 0
    },
    tpms: {
      website: 1,
      email: 0
    },
    rss: {
      website: 0,
      email: 0
    },
    abs: {
      website: 0,
      email: 0
    },
    withoutISOCable: {
      website: 0,
      email: 0
    },
    geofence_blackSpot: {
      website: 0,
      email: 0
    },
    geofence_customerSite: {
      website: 0,
      email: 0
    },
    geofence_driverHomeAddress: {
      website: 0,
      email: 0
    },
    geofence_ferryTerminal: {
      website: 0,
      email: 0
    },
    geofence_industrialZone: {
      website: 0,
      email: 0
    },
    geofence_maintenancePoint: {
      website: 0,
      email: 0
    },
    geofence_openParkingArea: {
      website: 0,
      email: 0
    },
    geofence_other: {
      website: 0,
      email: 0
    },
    geofence_petrolStation: {
      website: 0,
      email: 0
    },
    geofence_restArea: {
      website: 0,
      email: 0
    },
    geofence_secureParking: {
      website: 0,
      email: 0
    }
  }
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_HISTORICAL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        processing: true
      };
    case actionTypes.FETCH_HISTORICAL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false
      };
    case actionTypes.FETCH_HISTORICAL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        processing: false
      };
    case actionTypes.RECEIVE_NOTIFICATIONS_STATE:
      return {
        ...state,
        buffer: action.buffer || state.buffer,
        lastMQTTProcessedTime: action.lastMQTTProcessedTime || state.lastMQTTProcessedTime,
        lastTimeBasedMessages: action.lastTimeBasedMessages || state.lastTimeBasedMessages,
        messages: action.messages || state.messages,
        processing: action.processing !== undefined && action.processing !== null ? action.processing : state.processing
      };
    case actionTypes.RECEIVE_NOTIFICATIONS_NOTIFICATION:
      return {
        ...state,
        messages: action.messages
      };
    case actionTypes.FETCH_INITIAL_NOTIFICATIONS_STATE_SUCCESS:
      return {
        ...state,
        lastTimeBasedMessages: action.lastTimeBasedMessages,
        messages: action.messages,
        readMessages: action.readMessages,
        deletedMessages: action.deletedMessages,
        processing: action.processing
      };
    case actionTypes.UPDATE_NOTIFICATIONS_REMOVE_READ:
      return {
        ...state,
        readMessages: action.readMessages
      };
    case actionTypes.UPDATE_NOTIFICATIONS_DELETED:
      return {
        ...state,
        messages: action.messages,
        deletedMessages: action.deletedMessages
      };
    case actionTypes.UPDATE_NOTIFICATION_TYPES_TO_LISTEN_TO:
      return {
        ...state,
        notificationTypesToListenTo: {
          ...initialState.notificationTypesToListenToDefaultValues,
          ...action.notificationTypesToListenTo
        }
      };
    case actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        emailSubProcessing: true,
        emailSubError: null
      };
    case actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        emailSubProcessing: false,
        emailSubError: null
      };
    case actionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        emailSubProcessing: false,
        emailSubError: action.error
      };
    case actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        notificationTypesToListenTo: {
          ...initialState.notificationTypesToListenToDefaultValues,
          ...action.notificationTypesToListenTo
        },
        isNotificationSubProcessing: false,
        getNotificationSubError: null
      };
    case actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isNotificationSubProcessing: false,
        getNotificationSubError: action.error
      };
    case actionTypes.GET_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isNotificationSubProcessing: true,
        getNotificationSubError: null
      };
    case actionTypes.UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT:
      return {
        ...state,
        notificationTypesToListenTo: initialState.notificationTypesToListenToDefaultValues
      };
    case actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        emailEBPMSSubProcessing: true,
        emailEBPMSSubError: null
      };
    case actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        emailEBPMSSubProcessing: false,
        emailEBPMSSubError: null,
        ebpmsEmailPreferences: action.subjects
      };
    case actionTypes.UPDATE_EBPMS_EMAIL_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        emailEBPMSSubProcessing: false,
        emailEBPMSSubError: action.error
      };
    case actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        ebpmsEmailPreferencesProcessing: true
      };
    case actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        ebpmsEmailPreferences: action.ebpmsEmailPreferences,
        ebpmsEmailPreferencesProcessing: false
      };
    case actionTypes.GET_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        ebpmsEmailPreferences: null,
        ebpmsEmailPreferencesProcessing: false
      };
    case actionTypes.UPDATE_EBPMS_NOTIFICATIONS_SUBSCRIPTIONS_DEFAULT:
      return {
        ...state,
        ebpmsEmailPreferences: action.defaultEBPMSEmailSubscription
      };
    default:
      return state;
  }
}
