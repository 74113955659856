import actionTypes from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
  displayBy: 'events', // ['tons','events', 'monthly']
  searchDates: [moment(moment().subtract(6, 'months'), 'YYYY/MM/DD').startOf('day'), moment(moment(), 'YYYY/MM/DD')],
  searchMonth: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
  loadingEvents: [],
  searchAssets: [],
  loaded: false,
  loading: false
};

export default function trailersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_DISPLAY_BY_LOADING_EVENTS: {
      return {
        ...state,
        displayBy: action.payload
      };
    }
    case actionTypes.CHANGE_FILTER_DATES_LOADING_EVENTS: {
      return {
        ...state,
        searchDates: action.payload
      };
    }
    case actionTypes.CHANGE_FILTER_MONTH_LOADING_EVENTS: {
      return {
        ...state,
        searchMonth: action.payload
      };
    }
    case actionTypes.CHANGE_FILTER_ASSET_LOADING_EVENTS: {
      return {
        ...state,
        searchAssets: action.payload
      };
    }
    case actionTypes.FETCH_TRAILERS_LOADING_EVENTS: {
      return {
        ...state,
        loadingEvents: action.payload.events,
        loading: !action.payload.finished,
        loaded: action.payload.finished
      };
    }
    case actionTypes.START_TRAILERS_LOADING_EVENTS: {
      return {
        ...state,
        loadingEvents: [],
        loading: true,
        loaded: false
      };
    }
    case actionTypes.ERROR_TRAILERS_LOADING_EVENTS: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    default:
      return state;
  }
}
