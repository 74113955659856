import { Tooltip } from 'antd';
import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { getTimeDate, isInactive } from '../../../../../../helpers/functions';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';

function convertSpeed(value = 0.0, isMetric = true) {
  const computedValue = isMetric ? value : value * 0.621371;
  return computedValue.toFixed(1);
}

const Cell = ({ rowData, isMetric, formatMessage }) => {
  if (rowData.speed == null) {
    return <NoValueSpan />;
  }
  const speed = convertSpeed(rowData.speed, isMetric.distance);
  const speedMetric = formatMessage(isMetric.distance ? strings.abbrev.kilometerPerHour : strings.abbrev.milePerHour);
  const time = getTimeDate(rowData.ebsTime * 1000);
  const val = {
    value: speed,
    time: time
  };

  if (isInactive(rowData)) {
    return (
      <Tooltip title={formatMessage(strings.short.ebsTimeVal, val)} placement='topLeft'>
        <span style={{ color: '#ccc' }}>
          {speed} <small>{speedMetric}</small>
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      {speed} <small>{speedMetric}</small>
    </span>
  );
};

const SpeedColumn = ({ trailers, isMetric, formatMessage }) => {
  return {
    title: <Tooltip title={formatMessage(strings.short.speed)}>{formatMessage(strings.short.speed)}</Tooltip>,
    key: 'speed',
    dataIndex: 'speed',
    searchKey: formatMessage(strings.short.speed),
    selectionSection: selectionHeaders.generalMonitoring.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} isMetric={isMetric} />,
    sorter: () => trailers.sort('speed', (a, b) => (a.speed < b.speed ? -1 : 1)),
    width: 100
  };
};

const SpeedCSVHeader = formatMessage => formatMessage(strings.short.speed);
const SpeedCSVData = (trailer, _, isMetric) => {
  if (trailer?.speed) {
    return convertSpeed(trailer.speed, isMetric.distance);
  }

  return '';
};
const SpeedCSVColumn = {
  header: SpeedCSVHeader,
  data: SpeedCSVData
};

export default { table: SpeedColumn, csv: SpeedCSVColumn };
