import actionTypes from '../actions/actionTypes';

export const initialLinkState = {
  processing: false,
  error: null,
  link: null
};

export default function linkReducer(state = initialLinkState, action) {
  switch (action.type) {
    case actionTypes.GET_SHAREABLE_LINK_SUCCESS:
      const url = window.location;
      const baseUrl = url.protocol + '//' + url.host;
      return {
        ...state,
        link: `${baseUrl}/temp/${action.link.token}`,
        processing: false
      };
    case actionTypes.GET_SHAREABLE_LINK_REQUEST:
      return {
        ...state,
        link: null,
        processing: true
      };
    case actionTypes.GET_SHAREABLE_LINK_FAILURE:
      return {
        ...state,
        link: null,
        processing: false,
        error: action.error
      };
    default:
      return state;
  }
}
