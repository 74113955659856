import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateTransicsToken } from '../../actions/thunks/auth/validate-transics-token';
import Home from '../Home/Home';
import { Logger } from '../../app/helpers/logger';

class SendToken extends Component {
  componentDidMount() {
    const {
      match: {
        params: { token = '', env: paramsEnv }
      },
      authenticateUser
    } = this.props;
    Logger.debug(`MyTransics token: ${token} on env ${env}`);

    let env = paramsEnv;
    if (env) {
      window.localStorage.setItem('transics-env', env);
    } else {
      env = window.localStorage.getItem('transics-env');
    }

    authenticateUser(token, env);
  }

  render() {
    return <Home />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (token, env) =>
      dispatch(
        validateTransicsToken({
          token,
          env
        })
      )
  };
}

export default connect(undefined, mapDispatchToProps)(SendToken);
