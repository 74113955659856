import React from 'react';

const IconDrawRectangle = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20 6V14.9804L22 16.8416V4H1.99999V20H14V18H3.99999V6H20Z' />
    <path d='M16 18V20H22V19.5865L20 17.7253V18H16Z' />
    <path d='M21.6412 19.2219L16.5775 14.4923C16.4775 14.3992 16.3318 14.3741 16.2064 14.4282C16.081 14.4827 16 14.6061 16 14.7429V21.602C16 21.734 16.0757 21.8542 16.1947 21.9111C16.3135 21.9685 16.4549 21.9523 16.5578 21.8698L17.9563 20.7513L19.2782 23.7935C19.3537 23.9673 19.556 24.0471 19.7297 23.9714L20.779 23.516C20.9529 23.4405 21.0325 23.2386 20.957 23.0649L19.6352 20.0228L21.446 19.8136C21.5789 19.7983 21.6908 19.7071 21.7327 19.5802C21.7746 19.4528 21.7389 19.3133 21.6412 19.2219Z' />
  </svg>
);

export default IconDrawRectangle;
