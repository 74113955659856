import React from 'react';
import emptyChart from '../../assets/images/empty-chart.png';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import './NoDataOrLoading.scss';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';

const NoDataOrLoading = ({
  processing = false,
  setPickerOpen,
  startDate,
  endDate,
  showButtons = false,
  onChangeEndDateTime,
  intl: { formatMessage }
}) => {
  const trailer = useSelector(store => store.appState.selectedTnTTrailer);
  const lastComm = trailer?.lastValidValues?.ebs?.time ?? false;
  return (
    <div className={'nodata-loading__wrapper'}>
      <span className='nodata-loading__figure'>
        {processing ? (
          <LoadingOutlined className={'nodata-loading__icon'} />
        ) : (
          <img src={emptyChart} className={'nodata-loading__image'} alt='Empty' />
        )}
      </span>
      <p className={'nodata-loading-wrapper__text'}>
        {!processing ? formatMessage(strings.phrase.noDataAvailableDate) : formatMessage(strings.short.loadingIndictor)}
      </p>
      {!processing && (
        <div className='nodata-loading__buttons'>
          <Button className='nodata-loading__button' onClick={() => setPickerOpen(true)}>
            {formatMessage(strings.short.selectDifferentDate)}
          </Button>
          {showButtons && lastComm && (lastComm > endDate || lastComm < startDate) && (
            <Button
              className='nodata-loading__button'
              type={'primary'}
              onClick={() => onChangeEndDateTime(moment(lastComm * 1000))}
            >
              {formatMessage(strings.phrase.jumpLastDateWithData)}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default injectIntl(NoDataOrLoading);
