import actionTypes from './actionTypes';

function changeLanguage(language) {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    language
  };
}

function setLanguage(language) {
  return dispatch => {
    return dispatch(changeLanguage(language));
  };
}

export default {
  setLanguage
};
