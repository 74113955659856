import React from 'react';
import { connect } from 'react-redux';
import { ShareAltOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Button, Spin, Alert, Row, Tooltip, Select } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import actions from '../../actions';
import LocaleString from '../Utils/LocaleString';
import { tabOrModalView, triggerGenericEvent } from '../../helpers/googleAnalytics';

class TrailerShare extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      period: '1d'
    };
  }

  componentDidMount() {
    const { getShareableLink, assetId } = this.props;
    const { period } = this.state;
    if (assetId) getShareableLink(assetId, period);
  }

  componentDidUpdate(prevProps) {
    const { getShareableLink, assetId } = this.props;
    const { period } = this.state;
    if (prevProps.assetId !== assetId && assetId) {
      getShareableLink(assetId, period);
    }
  }

  showModal = () => {
    tabOrModalView('share');
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    tabOrModalView('');
    this.setState({
      visible: false
    });
  };

  onTrailerSelect = assetId => {
    const { selectTrailer, mainPath } = this.props;
    selectTrailer(mainPath, assetId);
    this.setState({
      visible: false
    });
  };

  selectPeriod = period => {
    const { getShareableLink, assetId } = this.props;
    this.setState({ period });
    getShareableLink(assetId, period);
    let valueType = period.charAt(period.length - 1);
    let valueStr = period.slice(0, period.length - 1);
    triggerGenericEvent(
      'Share',
      'Changed link share period',
      '',
      parseInt(valueStr, 10) * (valueType === 'd' ? 24 : 1)
    );
  };

  copiedToClipboard = () => {
    triggerGenericEvent('Share', 'Copied link to clipboard', '');
  };

  render() {
    const { visible, period } = this.state;
    const { trailerVin, processing, shareableLink } = this.props;
    return (
      <div>
        <Button onClick={this.showModal} icon={<ShareAltOutlined />} className='medium-margin-right'>
          {' '}
          <LocaleString type='button' id='share' />
        </Button>
        <Modal
          title={<LocaleString type='phrase' id='shareODRFile' />}
          footer={null}
          visible={visible}
          onCancel={this.onClose}
        >
          <div>
            <div>
              <LocaleString type='phrase' id='shareTrailerForDurationVal' val={trailerVin} />{' '}
              <Select value={period} style={{ width: 120 }} onChange={this.selectPeriod}>
                <Select.Option value='1h'>
                  <LocaleString type='short' id='oneHour' />
                </Select.Option>
                <Select.Option value='10h'>
                  <LocaleString type='short' id='tenHours' />
                </Select.Option>
                <Select.Option value='1d'>
                  <LocaleString type='short' id='oneDay' />
                </Select.Option>
                <Select.Option value='2d'>
                  <LocaleString type='short' id='twoDays' />
                </Select.Option>
                <Select.Option value='7d'>
                  <LocaleString type='short' id='oneWeek' />
                </Select.Option>
              </Select>
            </div>
            {processing && (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )}
            {shareableLink && (
              <div>
                <Row style={{ position: 'relative' }}>
                  <Alert
                    style={{ marginTop: 16, width: '100%' }}
                    message={shareableLink}
                    type='info'
                    showIcon={false}
                  />
                  <CopyToClipboard text={shareableLink} onCopy={this.copiedToClipboard}>
                    <Tooltip title={<LocaleString type='short' id='copyToClipboard' />} placement='right'>
                      <Button
                        style={{ position: 'absolute', right: 10, top: 20 }}
                        type='dashed'
                        shape='circle'
                        icon={<LegacyIcon type={this.state.copied ? 'check' : 'copy'} />}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </Row>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    processing: store.link.processing,
    error: store.link.error,
    shareableLink: store.link.link
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getShareableLink: (assetId, period) => dispatch(actions.trailers.getShareableLink(assetId, period))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrailerShare);
