import actionTypes from '../actions/actionTypes';

export const initialEBPMSTrailerPerformance = {
  finishedLoading: false,
  performanceMap: {},
  error: null
};

export default function ebpmsTrailerPerformanceReducer(state = initialEBPMSTrailerPerformance, action) {
  switch (action.type) {
    case actionTypes.GET_EBPMS_TRAILERS_PERFORMANCE_SUCCESS:
      const newPerformanceMap = { ...state.performanceMap };
      for (let i = 0; i < action.list.length; i++) {
        const performanceObj = action.list[i];
        const id = performanceObj['device_id'];
        newPerformanceMap[id] = performanceObj;
      }
      return {
        ...state,
        performanceMap: newPerformanceMap,
        finishedLoading: action.final,
        error: null
      };
    case actionTypes.GET_EBPMS_TRAILERS_PERFORMANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
