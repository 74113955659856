import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import LocaleString from '../Utils/LocaleString';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import { getIsTraileriABS } from '../../helpers/functions';
import { ebsBrand } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { selectFilteredEntities } from '../../selectors';

const { Description } = DescriptionList;
const getEbsVersion = trailer => trailer?.originalData?.lastFms?._device?.hwInfo?.ebs?.type;

const renderIABS = (trailer, device, vinNumber) => {
  const openIABSPartPage = evt => {
    evt.preventDefault();

    if (trailer.ebsType?.toLowerCase() === 'rss-plus') {
      window.open('https://www.wabco-customercentre.com/catalog/en_US/4801070010');
    } else {
      window.open('https://www.wabco-customercentre.com/catalog/docs/mm19001_web.pdf');
    }
  };

  return (
    <DescriptionList size='small' col='1'>
      <Description term={<LocaleString type='abbrev' id='vehicleIdentificationNumber' />}>{vinNumber}</Description>

      {trailer.brand ? (
        <Description term={<LocaleString type='short' id='brand' />}>{trailer.brand}</Description>
      ) : null}

      {trailer.model ? (
        <Description term={<LocaleString type='short' id='model' />}>{trailer.model}</Description>
      ) : null}

      {device && device.category && (
        <Description term={<LocaleString type='short' id='trailerCategory' />}>
          <LocaleString type='trailerType' id={device.category} />
        </Description>
      )}

      <Description term={<LocaleString type='short' id='absSystem' />}>{trailer.absSystem}</Description>

      {device && device.ebsBrand && (
        <Description term={<LocaleString type='columns' id='absBrand' />}>{device.ebsBrand?.toUpperCase()}</Description>
      )}

      <Description term={<LocaleString type='short' id='iabsType' />}>{trailer.ebsType}</Description>
      <Description term={<LocaleString type='short' id='iabsPartNumber' />}>
        {trailer.ebsPartNumber ? (
          <a href={'/'} onClick={openIABSPartPage}>
            {trailer.ebsPartNumber} <LinkOutlined />
          </a>
        ) : null}
      </Description>

      {trailer.ebsBrakeCalculationNumber && (
        <Description term={<LocaleString type='short' id='ebsBrakeCalcNum' />}>
          {trailer.ebsBrakeCalculationNumber}
        </Description>
      )}

      {trailer.steeringAxle && trailer.steeringAxle !== 'noSteeringAxle' ? (
        <Description term={<LocaleString type='short' id='steeringAxle' />}>
          <LocaleString type='short' id={trailer.steeringAxle} />
        </Description>
      ) : null}
    </DescriptionList>
  );
};

const renderEBS = (trailer, device, vinNumber) => {
  const openEBSPartPage = evt => {
    evt.preventDefault();
    window.open(`https://www.wabco-customercentre.com/catalog/${trailer.ebsPartNumber.split(' ').join('')}`);
  };
  const ebsVersion = getEbsVersion(trailer);

  return (
    <DescriptionList size='small' col='1'>
      <Description term={<LocaleString type='abbrev' id='vehicleIdentificationNumber' />}>{vinNumber}</Description>
      <Description term={<LocaleString type='short' id='brand' />}>{trailer.brand}</Description>
      <Description term={<LocaleString type='short' id='model' />}>{trailer.model}</Description>

      {device && device.category && (
        <Description term={<LocaleString type='short' id='trailerCategory' />}>
          <LocaleString type='trailerType' id={device.category} />
        </Description>
      )}

      <Description term={<LocaleString type='short' id='productionDate' />}>
        {trailer.productionDate ? trailer.productionDate.format('ll') : ''}
      </Description>
      <Description term={<LocaleString type='short' id='absSystem' />}>{trailer.absSystem}</Description>
      <Description term={<LocaleString type='short' id='suspensionType' />}>{trailer.suspensionType}</Description>

      {device && device.ebsBrand && (
        <Description term={<LocaleString type='columns' id='ebsBrand' />}>{device.ebsBrand?.toUpperCase()}</Description>
      )}
      {ebsVersion && <Description term={<LocaleString type='short' id='ebsVersion' />}>{ebsVersion}</Description>}

      <Description term={<LocaleString type='short' id='ebsType' />}>{trailer.ebsType}</Description>
      <Description term={<LocaleString type='short' id='ebsPartNumber' />}>
        <a href={'/'} onClick={openEBSPartPage}>
          {trailer.ebsPartNumber} <LinkOutlined />
        </a>
      </Description>
      <Description term={<LocaleString type='short' id='ebsBrakeCalcNum' />}>
        {trailer.ebsBrakeCalculationNumber}
      </Description>
      <Description term={<LocaleString type='short' id='steeringAxle' />}>
        <LocaleString type='short' id={trailer.steeringAxle} />
      </Description>
      <Description term={<LocaleString type='short' id='independentWheelSuspension' />}>
        <LocaleString type='short' id={trailer.independentWheelSuspension} />
      </Description>
      <Description term={<LocaleString type='short' id='tyreConfiguration' />}>
        <LocaleString type='short' id={trailer.tyreChoice} />
      </Description>
    </DescriptionList>
  );
};

const TrailerIdCardContentGeneralInfoDescription = ({ trailer }) => {
  const device = useSelector(selectFilteredEntities).find(device => device.assetId === trailer.assetId);
  const isiABS = getIsTraileriABS(trailer);
  const ebsVersion = getEbsVersion(trailer);

  const vinNumber = trailer?.vinNumber || device?.vinNumber || device?.lastValidValues?.ebs_trailer?.vin;

  if (device && device.ebsBrand !== ebsBrand.wabco) {
    return (
      <>
        <DescriptionList size='small' col='1'>
          {vinNumber && (
            <Description term={<LocaleString type='abbrev' id='vehicleIdentificationNumber' />}>
              {vinNumber}
            </Description>
          )}
          {device.category && (
            <Description term={<LocaleString type='short' id='trailerCategory' />}>
              <LocaleString type='trailerType' id={device.category} />
            </Description>
          )}
          {device.ebsBrand && (
            <Description term={<LocaleString type='columns' id='ebsBrand' />}>
              {device.ebsBrand?.toUpperCase()}
            </Description>
          )}
          {ebsVersion && <Description term={<LocaleString type='short' id='ebsVersion' />}>{ebsVersion}</Description>}
        </DescriptionList>
      </>
    );
  }

  if (isiABS) {
    return renderIABS(trailer, device, vinNumber);
  } else {
    return renderEBS(trailer, device, vinNumber);
  }
};
export default TrailerIdCardContentGeneralInfoDescription;
