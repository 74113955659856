import React from 'react';
const ElectricalFault = () => (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.4771 15.0472L12.7887 10.6031H11.0106V7.67022L8.59533 12.0994H10.4771V15.0472Z' fill='black' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.692 1.70203L1.76367 17.2757H19.6203L10.692 1.70203ZM10.692 4.16102L17.51 16.0462H3.87401L10.692 4.16102Z'
      fill='black'
    />
  </svg>
);

export default ElectricalFault;
