import React from 'react';

const IconZoomIn = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 12V10H12V8H10V6H8V8H6V10H8V12H10Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 2C7.14348 2 5.36301 2.7375 4.05025 4.05025C2.7375 5.36301 2 7.14348 2 9C2 10.8565 2.7375 12.637 4.05025 13.9497C5.36301 15.2625 7.14348 16 9 16C10.6891 16 12.3153 15.3895 13.5836 14.2907L14 14.7071V16L20 22L22 20L16 14H14.7071L14.2907 13.5836C15.3895 12.3153 16 10.6891 16 9C16 7.14348 15.2625 5.36301 13.9497 4.05025C12.637 2.7375 10.8565 2 9 2ZM5.46447 5.46447C6.40215 4.52678 7.67392 4 9 4C10.3261 4 11.5979 4.52678 12.5355 5.46447C13.4732 6.40215 14 7.67392 14 9C14 10.3261 13.4732 11.5979 12.5355 12.5355C11.5979 13.4732 10.3261 14 9 14C7.67392 14 6.40215 13.4732 5.46447 12.5355C4.52678 11.5979 4 10.3261 4 9C4 7.67392 4.52678 6.40215 5.46447 5.46447Z'
    />
  </svg>
);

export default IconZoomIn;
