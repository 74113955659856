import { defineMessages } from 'react-intl';

// Short to medium length phrase, or title, possibly used more than once but rarely
export const phrase = defineMessages({
  firstTimeConnecting: {
    id: 'phrase.firstTimeConnecting',
    defaultMessage:
      'Welcome. This is the first time that you are connecting. We need to synchronize some data for you. This might take a minute.',
    description: ''
  },
  syncTakingLonger: {
    id: 'phrase.syncTakingLonger',
    defaultMessage:
      'The synchronization of your data is taking longer than usual, please try again later. If the issue persists please contact our Service Desk.',
    description: ''
  },
  oldData: {
    id: 'phrase.oldData',
    defaultMessage:
      'Please note that the Braking Performance Report is only valid as of {date}. In order to produce a more recently valid Braking Performance Report, more braking events are required from the trailer (i.e. trailer is required to undertake additional mileage)',
    description: ''
  },
  oldDataToolTip: {
    id: 'phrase.oldDataToolTip',
    defaultMessage: 'If you track more than 2000 units, this notice can be ignored.',
    description: ''
  },
  someOldData: {
    id: 'phrase.someOldData',
    defaultMessage:
      'Please note that one or more trailers has a Braking Performance Report that is valid at a date earlier than today. In order to produce a more recently valid Braking Performance Report for those trailer(s), more braking events are required (i.e. those trailer(s) are required to undertake additional mileage)',
    description: ''
  },
  accessRevoked: {
    id: 'phrase.accessRevoked',
    defaultMessage: 'Access revoked!',
    description: ''
  },
  avgNumDaysBetween2Events: {
    id: 'phrase.avgNumDaysBetween2Events',
    defaultMessage: 'Average number of days/100km between two events',
    description: 'km abbreviation of unit of distance kilometers'
  },
  avgNumDaysBetween2EventsImperial: {
    id: 'phrase.avgNumDaysBetween2EventsImperial',
    defaultMessage: 'Average number of days/62mi between two events',
    description: 'mi abbreviation of unit of distance miles'
  },
  avgNumDaysWithStatus: {
    id: 'phrase.avgNumDaysWithStatus',
    defaultMessage: 'Average number of days/100km  with status',
    description: 'km abbreviation of unit of distance kilometers'
  },
  avgNumDaysWithStatusImperial: {
    id: 'phrase.avgNumDaysWithStatusImperial',
    defaultMessage: 'Average number of days/62mi with status',
    description: 'mi abbreviation of unit of distance miles'
  },
  deleteNotificationsVal: {
    id: 'phrase.deleteNotificationsVal',
    defaultMessage: 'Delete {value} notifications',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  detailsForTrailerVal: {
    id: 'phrase.detailsForTrailerVal',
    defaultMessage: 'Details for trailer {value}',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  dayOfInactivity: {
    id: 'phrase.dayOfInactivity',
    defaultMessage: 'day of inactivity',
    description: ''
  },
  dayOfInactivityCriticalDetected: {
    id: 'phrase.dayOfInactivityCriticalDetected',
    defaultMessage: 'day where a critical issue has been detected',
    description: ''
  },
  dayOfInactivityInfoProvided: {
    id: 'phrase.dayOfInactivityInfoProvided',
    defaultMessage: 'day where an info has been provided',
    description: ''
  },
  dayOfInactivityWarningDetected: {
    id: 'phrase.dayOfInactivityWarningDetected',
    defaultMessage: 'day where a warning has been detected',
    description: ''
  },
  dayOfInactivityWithoutIssues: {
    id: 'phrase.dayOfInactivityWithoutIssues',
    defaultMessage: 'day of activity without any issues',
    description: ''
  },
  eventsDiscardedDTCNoTimestampVal: {
    id: 'phrase.eventsDiscardedDTCNoTimestampVal',
    defaultMessage: 'DTC without timestamp: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedDTCInFutureVal: {
    id: 'phrase.eventsDiscardedDTCInFutureVal',
    defaultMessage: 'DTC in the future: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedInFutureVal: {
    id: 'phrase.eventsDiscardedInFutureVal',
    defaultMessage: 'Recorded events in the future: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedNoTimestampVal: {
    id: 'phrase.eventsDiscardedNoTimestampVal',
    defaultMessage: 'Recorded events without timestamp: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedTotalVal: {
    id: 'phrase.eventsDiscardedTotalVal',
    defaultMessage: 'Discarded events ({value})',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedTripsInFutureVal: {
    id: 'phrase.eventsDiscardedTripsInFutureVal',
    defaultMessage: 'Trip events in the future: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedTripsNoTimestampVal: {
    id: 'phrase.eventsDiscardedTripsNoTimestampVal',
    defaultMessage: 'Trip events without timestamp: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedDtcWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedDtcWithInaccurateMileageVal',
    defaultMessage: 'DTC events with inaccurate mileage: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedRecordedEventsWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedRecordedEventsWithInaccurateMileageVal',
    defaultMessage: 'Recorded events with inaccurate mileage: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventsDiscardedTripsWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedTripsWithInaccurateMileageVal',
    defaultMessage: 'Trip events with inaccurate mileage: {value}',
    description: '{value} is a variable and should not be translated.'
  },
  eventTimeline: {
    id: 'phrase.eventTimeline',
    defaultMessage: 'Events timeline (last {value})',
    description: '{value} is a variable and should not be translated.'
  },
  fleetHealthEvolution: {
    id: 'phrase.fleetHealthEvolution',
    defaultMessage: 'Fleet health evolution',
    description: ''
  },
  howIsScoreComputed: {
    id: 'phrase.howIsScoreComputed',
    defaultMessage: 'How is the health score calculated?',
    description: ''
  },
  last10kKmSummary: {
    id: 'phrase.last10kKmSummary',
    defaultMessage: 'Last 10,000 km summary',
    description: 'km abbreviation of unit of distance kilometers'
  },
  lastYearSummary: {
    id: 'phrase.lastYearSummary',
    defaultMessage: 'Last year summary',
    description: ''
  },
  nearestWabcoPartner: {
    id: 'phrase.nearestWabcoPartner',
    defaultMessage: 'Nearest WABCO partner',
    description: 'WABCO is a name do not translate'
  },
  noTrailerSelected: {
    id: 'phrase.noTrailerSelected',
    defaultMessage: 'No trailer selected. Please pick one from the list',
    description: ''
  },
  numDaysSinceLastEvent: {
    id: 'phrase.numDaysSinceLastEvent',
    defaultMessage: 'Number of days/100km since last event',
    description: 'km abbreviation of unit of distance kilometers'
  },
  numDaysSinceLastEventImperial: {
    id: 'phrase.numDaysSinceLastEventImperial',
    defaultMessage: 'Number of days/62mi since last event',
    description: ''
  },
  odrUploadHint: {
    id: 'phrase.odrUploadHint',
    defaultMessage: 'Support for a single or bulk upload.',
    description: ''
  },
  odrUploadText: {
    id: 'phrase.odrUploadText',
    defaultMessage: 'Click or drag an ODR file to this area to upload',
    description: 'ODR is a name do not translate'
  },
  recentSummary: {
    id: 'phrase.recentSummary',
    defaultMessage: 'Recent summary',
    description: ''
  },
  sectionOf100kmCriticalDetected: {
    id: 'phrase.sectionOf100kmCriticalDetected',
    defaultMessage: 'sequence of 100 km where a critical issue has been detected',
    description: 'km abbreviation of unit of distance kilometers'
  },
  sectionOf100kmInfoProvided: {
    id: 'phrase.sectionOf100kmInfoProvided',
    defaultMessage: 'sequence of 100 km where an info has been provided',
    description: 'km abbreviation of unit of distance kilometers'
  },
  sectionOf100kmNoIssues: {
    id: 'phrase.sectionOf100kmNoIssues',
    defaultMessage: 'sequence of 100 km without any issues',
    description: 'km abbreviation of unit of distance kilometers'
  },
  sectionOf100kmUnknownStatus: {
    id: 'phrase.sectionOf100kmUnknownStatus',
    defaultMessage: 'sequence of 100 km with an unknown status',
    description: 'km abbreviation of unit of distance kilometers'
  },
  sectionOf100kmWarningDetected: {
    id: 'phrase.sectionOf100kmWarningDetected',
    defaultMessage: 'sequence of 100 km where a warning has been detected',
    description: 'km abbreviation of unit of distance kilometers'
  },
  shareODRFile: {
    id: 'phrase.shareODRFile',
    defaultMessage: 'Share ODR file',
    description: 'ODR is a name do not translate'
  },
  shareTrailerForDurationVal: {
    id: 'phrase.shareTrailerForDurationVal',
    defaultMessage: 'Sharing trailer {value} for',
    description:
      '{value} is a variable and should not be translated. {value} will be the trailer vin (vehicle identification number).'
  },
  userGreetingVal: {
    id: 'phrase.userGreetingVal',
    defaultMessage: 'Hi {value}',
    description: '{value} is a variable and should not be translated. {value} will be the users name.'
  },
  noGps: {
    id: 'phase.noGps',
    defaultMessage: 'No GPS position available',
    description: 'GPS - Global Positioning System'
  },
  noEbsSupport: {
    id: 'phrase.noEbsSupport',
    defaultMessage: 'Not supported by EBS',
    description: 'EBS Electronic Braking System.'
  },
  noAbsSupport: {
    id: 'phrase.noAbsSupport',
    defaultMessage: 'Not supported by ABS',
    description: 'ABS Automatic Braking System.'
  },
  noDataAvailable: {
    id: 'phrase.noDataAvailable',
    defaultMessage: 'No data available',
    description: ''
  },
  decodingError: {
    id: 'phrase.decodingError',
    defaultMessage: 'Decoding Error',
    description: ''
  },
  decodedNoDTC: {
    id: 'phrase.decodedNoDTC',
    defaultMessage: 'Decoded (No DTC)',
    description: 'DTC is a name do not translate'
  },
  noODRFileAvailable: {
    id: 'phrase.noODRFileAvailable',
    defaultMessage: 'No ODR file available',
    description: 'ODR is a name do not translate'
  },
  latestError: {
    id: 'phrase.latestError',
    defaultMessage: 'Latest Error',
    description: ''
  },
  latestEvent: {
    id: 'phrase.latestEvent',
    defaultMessage: 'Latest Event',
    description: ''
  },
  drivenSince: {
    id: 'phrase.drivenSince',
    defaultMessage: '{value}km driven since',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  drivenSinceMile: {
    id: 'phrase.drivenSinceMile',
    defaultMessage: '{value}mi driven since',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  eventContext: {
    id: 'phrase.eventContext',
    defaultMessage: 'Event Context',
    description: ''
  },
  filterByTrailer: {
    id: 'phrase.filterByTrailer',
    defaultMessage: 'Filter by trailer',
    description: ''
  },
  kbFinished: {
    id: 'phrase.kbFinished',
    defaultMessage: '{value} KB Finished',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  decodedPercentage: {
    id: 'phrase.decodedPercentage',
    defaultMessage: 'Decoded ({value}%)',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  openInGoogleMaps: {
    id: 'phrase.openInGoogleMaps',
    defaultMessage: 'Open in Google Maps',
    description: ''
  },
  clickDrillDown: {
    id: 'phrase.clickDrilldown',
    defaultMessage: 'Click to drilldown',
    description: ''
  },
  tripContext: {
    id: 'phrase.tripContext',
    defaultMessage: 'Trip Context',
    description: ''
  },
  tripDistance: {
    id: 'phrase.tripDistance',
    defaultMessage: 'Trip Distance',
    description: ''
  },
  tripAsset: {
    id: 'phrase.tripAsset',
    defaultMessage: 'Trips per Asset',
    description: ''
  },
  trendAchieved10t: {
    id: 'phrase.trendAchieved10t',
    defaultMessage: 'Total distance driven per load level (bins of {value} t)',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  trendAchievedEvent: {
    id: 'phrase.trendAchievedEvent',
    defaultMessage: 'Total distance driven empty/loaded (empty threshold at {value} t)',
    description: '{value} is a variable and should not be translated. {value} will be a number.'
  },
  emptyLoadedTripsAMonthCalendarDays: {
    id: 'phrase.emptyLoadedTripsAMonthCalendarDays',
    defaultMessage: 'Empty and Loaded trips per trailer over last month calendar days',
    description: ''
  },
  trailerRecentHistory: {
    id: 'phrase.trailerRecentHistory',
    defaultMessage: 'Trailer recent history',
    description: ''
  },
  historyTPMSPressure: {
    id: 'phrase.historyTPMSPressure',
    defaultMessage: 'TPMS Pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  historyTPMSTemperature: {
    id: 'phrase.historyTPMSTemperature',
    defaultMessage: 'TPMS Temperature',
    description: 'TPMS - tire pressure monitoring system'
  },
  historyFCF: {
    id: 'phrase.historyFCF',
    defaultMessage: 'FCF',
    description: 'FCF is a name do not translate'
  },
  wheelLayout: {
    id: 'phrase.wheelLayout',
    defaultMessage: 'Wheels Layout',
    description: ''
  },
  wheelLayoutColorsInfo1: {
    id: 'phrase.wheelLayoutColorsInfo1',
    defaultMessage: 'Wheels colors designate the status and integrity of TPMS data for each wheel:',
    description: 'TPMS - tire pressure monitoring system'
  },
  wheelLayoutColorsInfo2: {
    id: 'phrase.wheelLayoutColorsInfo2',
    defaultMessage: 'Hovering over wheels with old data will display the date and time of the last valid reading.',
    description: ''
  },
  wheelLayoutColorsInfoDark: {
    id: 'phrase.wheelLayoutColorsInfoDark',
    defaultMessage: 'TPMS data is current, pressure is sufficient.',
    description: 'TPMS - tire pressure monitoring system'
  },
  wheelLayoutColorsInfoGrey: {
    id: 'phrase.wheelLayoutColorsInfoGrey',
    defaultMessage: 'TPMS data is old, pressure at the time of reading was sufficient.',
    description: 'TPMS - tire pressure monitoring system'
  },
  wheelLayoutColorsInfoOrange: {
    id: 'phrase.wheelLayoutColorsInfoOrange',
    defaultMessage: 'TPMS data is current, pressure is insufficient.',
    description: 'TPMS - tire pressure monitoring system'
  },
  wheelLayoutColorsInfoLightOrange: {
    id: 'phrase.wheelLayoutColorsInfoLightOrange',
    defaultMessage: 'TPMS data is old, pressure at the time of reading was insufficient.',
    description: 'TPMS - tire pressure monitoring system'
  },
  numberAxlesInvalid: {
    id: 'phrase.numberAxlesInvalid',
    defaultMessage: 'Number of axles is invalid.',
    description: ''
  },
  performanceOnDate: {
    id: 'phrase.performanceOnDate',
    defaultMessage: '{VAR_PERFORMANCE} (on {VAR_DATE})',
    description:
      '{VAR_PERFORMANCE} and {VAR_DATE} are variables and should not be translated. {VAR_PERFORMANCE} is a percentage. {VAR_DATE} is a date'
  },
  emailSubConfirmationText: {
    id: 'phrase.emailSubConfirmationText',
    defaultMessage: 'Email notification will be sent to :  {VAR_EMAIL_LIST}.',
    description:
      '{VAR_EMAIL_LIST} is a variable and should not be translated. {VAR_EMAIL_LIST} is a sting of email addresses.'
  },
  noEmailErrorText: {
    id: 'phrase.noEmailErrorText',
    defaultMessage:
      'There are no email addresses associated with your account. See online help for further instructions.',
    description: ''
  },
  noUnknownTrailers: {
    id: 'phrase.noUnknownTrailers',
    defaultMessage: 'You cannot download reports for trailers with Unqualified or Gathering data status',
    description: 'Tooltip explanation'
  },
  unsupportedBrowser: {
    id: 'phrase.unsupportedBrowser',
    defaultMessage:
      'Warning: Your browser or its version might not support all the functionality in TX-TrailerFit. Update your browser to the latest version of Chrome or Edge for an uninterrupted experience.',
    description: 'TX-TrailerFit is a name do not translate'
  },
  noGpsCoordinatesAvailable: {
    id: 'phrase.noGpsCoordinatesAvailable',
    defaultMessage: 'No GPS coordinates available',
    description: 'GPS - Global Positioning System'
  },
  clickTheEventToReadMore: {
    id: 'phrase.clickTheEventToReadMore',
    defaultMessage: 'click the event to read more',
    description: ''
  },
  clickToReadMore: {
    id: 'phrase.clickToReadMore',
    defaultMessage: 'click to read more',
    description: ''
  },
  openEventContext: {
    id: 'phrase.openEventContext',
    defaultMessage: 'open event context',
    description: ''
  },
  openGoogleMaps: {
    id: 'phrase.openGoogleMaps',
    defaultMessage: 'open Google Maps',
    description: ''
  },
  noDescriptionAvailable: {
    id: 'phrase.noDescriptionAvailable',
    defaultMessage: 'no description available',
    description: ''
  },
  gioOperatingHrsCounter: {
    id: 'phrase.gioOperatingHrsCounter',
    defaultMessage: 'GIO operating hours counter',
    description: 'GIO is a name do not translate'
  },
  noDataAvailableDate: {
    id: 'phrase.noDataAvailableDate',
    defaultMessage: 'No data available for this date',
    description: ''
  },
  jumpLastDateWithData: {
    id: 'phrase.jumpLastDateWithData',
    defaultMessage: 'Jump to the last date with data',
    description: ''
  },
  noGpsData: {
    id: 'phrase.noGpsData',
    defaultMessage: 'No GPS data available',
    description: 'Empty value for when GPS data is not available. GPS - Global Positioning System'
  },
  statusFooter: {
    id: 'phrase.statusFooter',
    defaultMessage:
      "If the result indicated is 'Low performance' (or if BrakePlus otherwise identifies braking deficiencies), the vehicle operator, as defined in the DVSA 'EBPMS Industry Standard Specification' document, needs to ensure appropriate action is taken to investigate, remedy and evidence any reported defects, as detailed in the DVSA publication ‘Guide to Maintaining Roadworthiness’..",
    description: 'Tooltip explanation'
  },
  editsApplyToCurrentUser: {
    id: 'phrase.editsApplyToCurrentUser',
    defaultMessage: 'Edits are applied for current user: {value}',
    description: '{value} is a variable and should not be translated. Value will be an email address'
  },
  subscribeToEmail: {
    id: 'phrase.subscribeToEmail',
    defaultMessage: 'Subscribe to email'
  },
  pageErrorHeader: {
    id: 'phrase.pageErrorHeader',
    defaultMessage: "There's a problem loading this page"
  }
});
