import { createSelector } from '@reduxjs/toolkit';
import { selectFilteredEntitiesIds } from './trailers';

const selectRoot = state => state?.alerts;

export const selectAllItems = createSelector(selectRoot, state => state?.allItems);

export const selectAlertItems = createSelector(selectRoot, state => state.items ?? []);
export const selectProcessingAlerts = createSelector(selectRoot, state => state.processing);
export const selectItems = createSelector([selectRoot, selectFilteredEntitiesIds], (state, filteredEntitiesIds) => {
  return state?.items?.filter(item => {
    return filteredEntitiesIds.includes(item.assetId);
  });
});
