import { isNullOrUndefined } from '../../../../helpers/functions';

// in deck.js : x = long, y = lat
const getBounds = rect => {
  let minX = Number.MAX_SAFE_INTEGER,
    maxX = Number.MIN_SAFE_INTEGER,
    minY = Number.MAX_SAFE_INTEGER,
    maxY = Number.MIN_SAFE_INTEGER;
  for (let i = 0; i < rect.length; i++) {
    const point = rect[i];
    if (minX > point[0]) minX = point[0];
    if (maxX < point[0]) maxX = point[0];
    if (minY > point[1]) minY = point[1];
    if (maxY < point[1]) maxY = point[1];
  }
  return { minX, maxX, minY, maxY };
};

export const isInsideBounds = (pointX, pointY, bounds) => {
  if (isNullOrUndefined(pointX) || isNullOrUndefined(pointY)) return false;
  if (pointX < bounds.minX) return false;
  if (pointX > bounds.maxX) return false;
  if (pointY < bounds.minY) return false;
  if (pointY > bounds.maxY) return false;
  return true;
};

export const filterDataByRect = (data, rect) => {
  const bounds = getBounds(rect);
  const pointsInside = [];
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const x = element.gnss.longitude;
    const y = element.gnss.latitude;
    if (isInsideBounds(x, y, bounds)) {
      pointsInside.push(element);
    }
  }
  return pointsInside;
};
