import { GeoJsonLayer, IconLayer } from '@deck.gl/layers';
import iconMarker from '../assets/images/marker.png';
import { EditableGeoJsonLayer, ModifyMode, DrawPolygonMode } from 'nebula.gl';
import { getPlacesVariable } from '../helpers/functions';
import { getIsTypeGeofence } from './alarms/functions';
import iconAtlas from '../assets/images/trailer/map-marker-sprite.png';
import iconMapping from '../assets/images/trailer/map-marker-sprite-map.json';

export const getAddPlaceLayers = (
  location,
  geoJson,
  formColor,
  createEnabled,
  editEnabled,
  onEditPolygon,
  setPolygonEditMode,
  setLocationToCreatePlaceState,
  setControlled
) => {
  const layers = [];

  const color = hexToRgb(formColor);

  if (geoJson && !createEnabled && !editEnabled) {
    const layer = new GeoJsonLayer({
      id: `place-geojson-layer`,
      data: geoJson,
      pickable: true,
      filled: true,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      getFillColor: () => [...color, 55],
      getLineColor: color,
      updateTriggers: {
        getFillColor: [color]
      },
      getLineWidth: 2,
      getRadius: d => d.properties?.radius?.size || d.properties?.radius || 300,
      onClick: () => (geoJson && !editEnabled ? setPolygonEditMode(true) : true)
    });
    layers.push(layer);
  } else if (createEnabled || editEnabled) {
    const layer = new EditableGeoJsonLayer({
      id: 'geojson-layer',
      data: geoJson || {
        type: 'FeatureCollection',
        features: []
      },
      mode: editEnabled && geoJson ? ModifyMode : DrawPolygonMode,
      modeConfig: {
        dragToDraw: createEnabled
      },
      getTentativeLineColor: color,
      getTentativeFillColor: [...color, 0],
      getFillColor: [...color, 55],
      getLineColor: color,
      getEditHandlePointColor: point =>
        point?.properties?.positionIndexes[0] === 0 || editEnabled ? [0, 0, 0, 180] : [0, 0, 0, 0],
      getEditHandlePointOutlineColor: point =>
        point?.properties?.positionIndexes[0] === 0 || editEnabled ? [255, 255, 255, 255] : [0, 0, 0, 0],
      updateTriggers: {
        getFillColor: [color]
      },
      getLineDashArray: [],
      selectedFeatureIndexes: [0],
      onEdit: onEditPolygon,
      onClick: () => (geoJson && !editEnabled ? setPolygonEditMode(true) : true)
    });
    layers.push(layer);
  }
  // const
  layers.push(
    new IconLayer({
      id: 'icon-place',
      data: [location],
      iconAtlas: iconMarker,
      iconMapping: {
        marker: { x: 0, y: 0, width: 256, height: 256, mask: true }
      },
      getIcon: () => 'marker',
      getPosition: d => d,
      sizeScale: 5,
      getSize: 8,
      draggable: true,
      pickable: true,
      getColor: color,
      onDragStart: () => setControlled(false),
      onDrag: e => setLocationToCreatePlaceState(e.coordinate),
      onDragEnd: () => setControlled(true)
    })
  );

  return layers;
};

export const getAlertsGeofenceLayers = alerts => {
  const layers = [];
  alerts
    .filter(alert => getIsTypeGeofence(alert))
    .forEach(alert => {
      const color = getPlacesVariable(alert.placeData?.type, 'colour');
      const id = alert.placeData?.id;

      layers.push(
        new GeoJsonLayer({
          id: `place-geojson-layer-${id}`,
          data: alert.placeData?.geofenceDefinition,
          place: alert.placeData,
          features: { ...(alert.placeData?.geofenceDefinition || {}), id: id + '-feature' },
          pickable: false,
          filled: true,
          getFillColor: () => [...color, 25],
          lineWidthUnits: 'pixels',
          updateTriggers: {
            getFillColor: [color]
          },
          getLineColor: color,
          getLineWidth: 3,
          getRadius: d => d.properties?.radius?.size
        })
      );
      layers.push(
        new IconLayer({
          id: `places-icon-layer`,
          data: alert.geofences_details?.geofences,
          iconAtlas,
          iconMapping,
          getIcon: d => (iconMapping[d.type] ? d.type : 'other'),
          getPosition: d => [d?.address?.longitude, d.address?.latitude],
          opacity: 1,
          getSize: 90
        })
      );
    });
  return layers;
};

export const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};
