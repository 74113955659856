import React from 'react';

const IconHarshBraking = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.91366 3.44806C7.32008 3.63349 5.84148 3.86037 4.67587 3.28445C3.21886 2.56456 2.50654 2.40095 1.56758 3.18628C0.816395 3.81455 1.10348 4.53881 1.34093 4.8224C1.5244 5.08418 2.11799 5.80408 3.02459 6.58941C4.15781 7.57109 5.84148 8.52003 9.01452 9.10904L8.69074 9.95982L12.1552 11.9559C12.792 11.4214 14.2144 9.90092 14.8102 8.09464C15.4618 6.11896 14.4277 6.0106 13.7251 5.93699C13.6248 5.92648 13.5312 5.91668 13.4503 5.90224C12.4142 5.71746 11.4105 4.88785 11.0543 4.39701C10.9416 4.2417 10.8581 4.10604 10.7822 3.98279C10.6183 3.71652 10.49 3.50813 10.1801 3.28445C9.81749 3.02267 9.35989 3.04448 9.1764 3.08811L7.91366 3.44806Z' />
    <path d='M1.75007 7.70492C1.51093 7.56685 1.20513 7.64879 1.06707 7.88793C0.929005 8.12708 1.01093 8.43288 1.25007 8.57095L4.35035 10.3609V13.3333C4.35035 13.4315 4.39281 13.5198 4.4604 13.5808C4.60411 13.8313 4.8742 14 5.18368 14C5.64393 14 6.01702 13.6269 6.01702 13.1667V11.3231L9.16008 13.1378C9.39924 13.2759 9.70503 13.1939 9.84311 12.9548C9.98117 12.7156 9.89924 12.4098 9.66008 12.2718L1.75007 7.70492Z' />
  </svg>
);

export default IconHarshBraking;
