import React, { useMemo, useState, useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Tag } from 'antd';
import getScales from './d3';
import LocaleString from '../../Utils/LocaleString';
import { isNullOrUndefined } from '../../../helpers/functions';
import './OdrBrakingChart.scss';

const colors = {
  tag: '#E9F6FE',
  scale: '#E8E8E8',
  dark: 'rgba(0, 0, 0, 0.85)',
  selected: '#1890FF',
  other: '#CACACA'
};

const SwitchConfirm = ({ value, changeSelected, assetName, assetId, children, onVisibleChange, open }) => {
  return (
    <Popconfirm
      icon={null}
      open={open}
      placement='top'
      title={
        <div>
          <div className='odrBraking-popConfirm'>
            <span>{value}</span>
          </div>
          <LocaleString type='charts' id='switchIdCArd' val={assetName} />
        </div>
      }
      onConfirm={() => changeSelected(assetId)}
      onCancel={() => onVisibleChange(false)}
      okText={<LocaleString type='short' id='yes' />}
      cancelText={<LocaleString type='short' id='no' />}
    >
      {children}
    </Popconfirm>
  );
};

// The OdrBrakingChart component renders data points of the selected trailer
// on a scale as well as data points from other trailers for comparison.

// The data points can have these states:
// - Normal Asset       [default]
// - Asset Hovered      [point that the mouse is currently hovering]
// - Highlighted Asset  [point that has the same id as a hovered point on another chart]
// - Active Asset       [point correspondent to currently selected trailer]

// Based on the state the point look and functionality changes.
// The points are drawn in a specific order (normal > active) so that
// the most relevant state is drawn on top of closely points and not
// overlapped by subsequent points.

const OdrBrakingChart = ({
  odrBrakingData,
  averages,
  width,
  height,
  chartkey,
  activeAsset,
  xAxisMetric,
  formatTotal,
  title,
  subTitle,
  dataTransformer,
  switchTrailer,
  getDeviceName,
  assetHighlighted,
  setAssetHighlighted
}) => {
  const viewBox = { width: width, height: height };
  const [oData, setOdata] = useState(odrBrakingData);
  const [assetHovered, setAssetHovered] = useState(null);
  const [hoverPopUpOpen, setHoverPopUpOpen] = useState(false);

  const selectedAsset = oData?.find(asset => asset.assetId === activeAsset);
  const hasSelectedData = !!(
    selectedAsset &&
    selectedAsset[chartkey] !== null &&
    selectedAsset[chartkey] !== undefined
  );
  const avgValue = averages && averages[chartkey];

  const [xScale] = useMemo(
    () => getScales({ viewBox, oData, chartkey, dataTransformer, avgValue }),
    [viewBox, oData, chartkey, dataTransformer, avgValue]
  );
  const ticks = xScale?.ticks();
  let tickOffset = viewBox.width / 100;
  const tickWidth = ticks?.length > 1 ? viewBox.width / (ticks.length - 1) : viewBox.width;

  useEffect(() => {
    setOdata(odrBrakingData);
  }, [odrBrakingData, setOdata]);

  const setHighlight = id => {
    if (id === assetHighlighted) {
      return;
    }
    if (!id) {
      return;
    }
    setAssetHighlighted(id);
    setAssetHovered(id);
  };

  const checkHighlighted = useCallback(
    assetHighlighted => {
      if (assetHighlighted !== assetHovered) {
        setAssetHovered(null);
      }
    },
    [assetHovered]
  );

  useEffect(() => {
    checkHighlighted(assetHighlighted);
  }, [assetHighlighted, checkHighlighted]);

  const clearHighlight = () => {
    setAssetHighlighted(null);
    setAssetHovered(null);
  };

  return (
    <div
      className='odrBraking-chart'
      style={{
        maxWidth: viewBox.width + 80
      }}
    >
      <div className='odrBraking-header'>
        <div>
          <h3>{title}</h3>
          <h5>
            <span>{subTitle}</span>
          </h5>
        </div>
        <Tag
          color={hasSelectedData ? colors.tag : colors.scale}
          className='odrBraking-tag'
          style={{ color: colors.dark }}
        >
          {hasSelectedData ? formatTotal(selectedAsset[chartkey]) : <LocaleString type='short' id='noData' />}
        </Tag>
      </div>
      <svg
        width={viewBox.width}
        height={viewBox.height}
        style={{ overflow: 'visible' }}
        viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
      >
        {ticks?.map((tick, index) => (
          <line
            key={`marker-${index}`}
            x1={index * tickWidth}
            y1={4}
            x2={index * tickWidth}
            y2={viewBox.height}
            fill={colors.scale}
            stroke={colors.scale}
          />
        ))}
        {!ticks && (
          <Fragment>
            <line
              key={`marker-start`}
              x1={viewBox.width}
              y1={4}
              x2={viewBox.width}
              y2={viewBox.height}
              fill={colors.scale}
              stroke={colors.scale}
            />
            <line
              key={`marker-end`}
              x1={0}
              y1={4}
              x2={0}
              y2={viewBox.height}
              fill={colors.scale}
              stroke={colors.scale}
            />
          </Fragment>
        )}

        <line
          x1={0}
          y1={viewBox.height}
          x2={viewBox.width}
          y2={viewBox.height}
          fill={colors.scale}
          stroke={colors.scale}
        />
        {ticks?.map((tick, index) => (
          <foreignObject
            key={`tick-${index}`}
            y={viewBox.height}
            x={index * tickWidth - tickOffset}
            width='100%'
            height='20'
            fontSize='10'
          >
            <span key={tick}>
              {tick}
              {xAxisMetric}
            </span>
          </foreignObject>
        ))}
        {!!xScale && !isNullOrUndefined(avgValue) && (
          <Fragment>
            <line
              key={`marker-avg`}
              x1={dataTransformer ? xScale(dataTransformer(avgValue)) : xScale(avgValue)}
              y1={2}
              x2={dataTransformer ? xScale(dataTransformer(avgValue)) : xScale(avgValue)}
              y2={viewBox.height}
              fill={colors.dark}
              stroke={colors.dark}
              strokeWidth={2}
            />
            <foreignObject
              key={`marker-text`}
              y={-18}
              x={(dataTransformer ? xScale(dataTransformer(avgValue)) : xScale(avgValue)) - 5}
              width='100%'
              height='20'
              fontSize='10'
            >
              <span>
                <LocaleString type='charts' id='averageShort' />:{' '}
                {dataTransformer ? dataTransformer(avgValue) : avgValue}
                {xAxisMetric}
              </span>
            </foreignObject>
          </Fragment>
        )}
        {/* Normal Asset: */}
        {!!xScale &&
          oData
            ?.filter(asset => asset.assetId !== assetHighlighted && asset.assetId !== activeAsset)
            .map(
              (row, index) =>
                !isNullOrUndefined(row[chartkey]) && (
                  <circle
                    key={`pop-${index}`}
                    cx={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                    cy={viewBox.height / 2}
                    r={6}
                    fill={colors.other}
                    stroke={'white'}
                    onMouseOver={() => {
                      setHighlight(row.assetId);
                    }}
                  />
                )
            )}
        {/* Asset Hovered*/}
        {!!xScale &&
          oData
            ?.filter(asset => asset.assetId === assetHovered && asset.assetId !== activeAsset)
            .map((row, index) => {
              const hoverCircle = (
                <circle
                  className={'cursor-pointer'}
                  cx={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                  cy={viewBox.height / 2}
                  r={6}
                  fill={'white'}
                  stroke={colors.selected}
                  strokeWidth={2}
                  onClick={() => {
                    setHoverPopUpOpen(true);
                  }}
                  onMouseOver={() => {
                    setHoverPopUpOpen(false);
                  }}
                  onMouseLeave={() => {
                    if (!hoverPopUpOpen) {
                      clearHighlight();
                    }
                  }}
                />
              );
              return (
                <>
                  {!hoverPopUpOpen && (
                    <>
                      <text
                        x={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                        y={4}
                        dominantBaseline='middle'
                        textAnchor='middle'
                        fontSize='12'
                        fill={colors.selected}
                      >
                        {formatTotal(row[chartkey])}
                      </text>
                      {hoverCircle}
                    </>
                  )}

                  <SwitchConfirm
                    open={hoverPopUpOpen}
                    key={`pop-${index}`}
                    assetId={row.assetId}
                    changeSelected={switchTrailer}
                    assetName={getDeviceName(row.assetId)}
                    onVisibleChange={isVisible => {
                      if (!isVisible) {
                        setHoverPopUpOpen(false);
                        clearHighlight();
                      }
                    }}
                  >
                    {hoverCircle}
                  </SwitchConfirm>
                </>
              );
            })}
        {/* Highlighted Asset: */}
        {!!xScale &&
          oData
            ?.filter(
              asset =>
                asset.assetId === assetHighlighted && asset.assetId !== assetHovered && asset.assetId !== activeAsset
            )
            .map(
              (row, index) =>
                !isNullOrUndefined(row[chartkey]) && (
                  <g key={`pop-${index}`}>
                    <text
                      x={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                      y={4}
                      dominantBaseline='middle'
                      textAnchor='middle'
                      fontSize='12'
                      fill={colors.selected}
                    >
                      {formatTotal(row[chartkey])}
                    </text>
                    <circle
                      cx={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                      cy={viewBox.height / 2}
                      r={6}
                      fill={'white'}
                      stroke={colors.selected}
                      strokeWidth={2}
                    />
                  </g>
                )
            )}
        {/* Active Asset: */}
        {!!xScale &&
          oData
            ?.filter(asset => asset.assetId === activeAsset)
            .map(row => {
              if (assetHighlighted === row.assetId) {
                return (
                  <>
                    <text
                      x={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                      y={4}
                      dominantBaseline='middle'
                      textAnchor='middle'
                      fontSize='12'
                      fontWeight='bold'
                      fill={colors.selected}
                    >
                      {formatTotal(row[chartkey])}
                    </text>
                    <circle
                      cx={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                      cy={viewBox.height / 2}
                      r={6}
                      fill={colors.selected}
                      stroke={'white'}
                    />
                  </>
                );
              }
              return (
                <circle
                  cx={dataTransformer ? xScale(dataTransformer(row[chartkey])) : xScale(row[chartkey])}
                  cy={viewBox.height / 2}
                  r={6}
                  fill={colors.selected}
                  stroke={'white'}
                  onMouseOver={() => {
                    setHighlight(row.assetId);
                  }}
                  key={row.assetId}
                />
              );
            })}
      </svg>
    </div>
  );
};

OdrBrakingChart.propTypes = {
  odrBrakingData: PropTypes.any.isRequired,
  chartkey: PropTypes.string.isRequired,
  activeAsset: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  xAxisMetric: PropTypes.string,
  formatTotal: PropTypes.func,
  averages: PropTypes.any,
  dataTransformer: PropTypes.func,
  switchTrailer: PropTypes.func,
  getDeviceName: PropTypes.func
};

OdrBrakingChart.defaultProps = {
  width: 830,
  height: 40
};

export default OdrBrakingChart;
