import React from 'react';
import { Provider } from 'react-redux';
import '../scss/App.scss';
import configureStore from './configureStore';
import DataLoader from './dataLoader';
import { Auth0Provider } from '@auth0/auth0-react';
import Auth0AppLogin from './Auth0AppLogin';
import { ENV_CONFIG } from './helpers/env-configs';

const store = configureStore;

const Auth0App = () => {
  return (
    <Auth0Provider
      domain={ENV_CONFIG.REACT_APP_AUTH_DOMAIN}
      clientId={ENV_CONFIG.REACT_APP_ODYSSEY_AUTH_CLIENTID}
      redirectUri={window.location.origin + '/callback'}
      audience={'PL'}
      scope=''
      useRefreshTokens={true}
    >
      <Provider store={store}>
        <DataLoader>
          <Auth0AppLogin />
        </DataLoader>
      </Provider>
    </Auth0Provider>
  );
};
export default Auth0App;
