import { defineMessages } from 'react-intl';

export const sections = defineMessages({
  historySpeedLoad: {
    id: 'sections.historySpeedLoad',
    defaultMessage: 'History - Speed and Load',
    description: ''
  },
  historyTPMSPressure: {
    id: 'sections.historyTPMSPressure',
    defaultMessage: 'History - TPMS Pressure',
    description: 'TPMS - tire pressure monitoring system'
  },
  historyTPMSTemperature: {
    id: 'sections.historyTPMSTemperature',
    defaultMessage: 'History - TPMS Temperature',
    description: 'TPMS - tire pressure monitoring system'
  },
  historyFCF: {
    id: 'sections.historyFCF',
    defaultMessage: 'FCF History',
    description: 'FCF is a name do not translate'
  },
  trailerDetails: {
    id: 'sections.trailerDetails',
    defaultMessage: 'General Information',
    description: ''
  },
  lastPosition: {
    id: 'sections.lastPosition',
    defaultMessage: 'Last position',
    description: ''
  },
  wheelLayout: {
    id: 'sections.wheelLayout',
    defaultMessage: 'Wheel Layout',
    description: ''
  },
  lightMonitoring: {
    id: 'sections.lightMonitoring',
    defaultMessage: 'Light Monitoring Details',
    description: ''
  }
});
