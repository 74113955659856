import React from 'react';
import { NoValueSpan, UniqueCategories } from './columnHelper/columnHelperFunctions';
import * as strings from '../../../../../../helpers/defaultStrings';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { metaDataTrailerModelSort } from '../../../../../../helpers/sorters/column-sorters';
import { Tooltip } from 'antd';

const Cell = ({ rowData }) => {
  if (rowData.metaDataTrailerModel) {
    return <p>{rowData.metaDataTrailerModel}</p>;
  }
  return <NoValueSpan />;
};

const TrailerModelColumn = ({ trailers, formatMessage }) => {
  const filterFunction = checkedCategories => {
    trailers.filter('metaDataTrailerModel', t => {
      if (!t.metaDataTrailerMake) {
        return checkedCategories['unknown'];
      }
      return checkedCategories[t.metaDataTrailerModel];
    });
  };

  return {
    title: (
      <Tooltip title={formatMessage(strings.columns.trailerModel)}>
        {formatMessage(strings.columns.trailerModel)}
      </Tooltip>
    ),
    key: 'metaDataTrailerModel',
    dataIndex: 'metaDataTrailerModel',
    searchKey: formatMessage(strings.columns.trailerModel),
    selectionSection: selectionHeaders.generalInfo.id,
    render: rowData => <Cell rowData={rowData} />,
    sorter: () => trailers.sort('metaDataTrailerModel', metaDataTrailerModelSort),
    categories: UniqueCategories(
      trailers.unfilteredData.map(t => t.metaDataTrailerModel),
      true
    ),
    onFilter: filterFunction,
    removeFilter: () => trailers.removeFilter('metaDataTrailerModel'),
    width: 150
  };
};

const TrailerModelHeader = formatMessage => formatMessage(strings.columns.trailerModel);
const TrailerModelCSVData = trailer => trailer.metaDataTrailerModel;
const TrailerModelCSVColumn = {
  header: TrailerModelHeader,
  data: TrailerModelCSVData
};

export default { table: TrailerModelColumn, csv: TrailerModelCSVColumn };
