import actionTypes from '../actions/actionTypes';

export const initialColumnState = {
  groups: null,
  processing: false
};

export default function userReducer(state = initialColumnState, action) {
  switch (action.type) {
    case actionTypes.GET_GROUPS_REQUEST:
      return {
        ...state,
        processing: true
      };
    case actionTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
        processing: false
      };
    case actionTypes.GET_GROUPS_FAILURE:
      return {
        ...state,
        processing: false
      };
    default:
      return state;
  }
}
