import React from 'react';
import * as strings from '../../../../../../helpers/defaultStrings';
import { NoValueSpan } from './columnHelper/columnHelperFunctions';
import { selectionHeaders } from '../../../../../../helpers/constants';
import { Tooltip } from 'antd';

function convertDistance(value = 0, isMetric) {
  const computedValue = isMetric ? value : value * 0.621371;
  return computedValue.toFixed(1);
}

const Cell = ({ rowData, formatMessage, isMetric }) => {
  if (rowData.sinceDays == null && rowData.sinceKm == null) {
    return <NoValueSpan />;
  }
  return (
    <div>
      {rowData.sinceDays === 0 && formatMessage(strings.short.today)}
      {rowData.sinceDays === 1 && formatMessage(strings.short.yesterday)}
      {rowData.sinceDays > 1 &&
        formatMessage(strings.short.numDaysVal, { value: rowData.sinceDays != null ? rowData.sinceDays : '-' })}
      {rowData.sinceDays !== null && rowData.sinceDays >= 0 && rowData.sinceKm !== null && <span>{' / '}</span>}
      {rowData.sinceKm && (
        <span>
          {rowData.sinceKm < 1 ? 0 : convertDistance(rowData.sinceKm, isMetric)}{' '}
          {formatMessage(isMetric.distance ? strings.abbrev.kilometer : strings.abbrev.mile)}
        </span>
      )}
    </div>
  );
};

const SinceColumn = ({ trailers, isMetric, formatMessage }) => {
  const sorter = (a, b) => {
    if (a.sinceDays == null) {
      return -1;
    }
    if (a.sinceDays === b.sinceDays) {
      return a.sinceKm - b.sinceKm;
    }
    return a.sinceDays - b.sinceDays;
  };

  return {
    title: <Tooltip title={formatMessage(strings.columns.since)}>{formatMessage(strings.columns.since)}</Tooltip>,
    key: 'since',
    dataIndex: 'since',
    searchKey: formatMessage(strings.columns.since),
    selectionSection: selectionHeaders.trailerSystems.id,
    render: rowData => <Cell rowData={rowData} formatMessage={formatMessage} isMetric={isMetric} />,
    sorter: () => trailers.sort('since', sorter),
    width: 150
  };
};

export default { table: SinceColumn, csv: null };
