import React from 'react';

const IconABS = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.51392 9.58789C6.51392 9.53555 6.49897 9.47573 6.46906 9.40844L4.95494 6.22879C4.91008 6.1428 4.84652 6.07551 4.76427 6.02691C4.68203 5.97831 4.5923 5.95401 4.4951 5.95401C4.40163 5.95401 4.31378 5.97831 4.23153 6.02691C4.14928 6.07177 4.0876 6.13906 4.04647 6.22879L2.53235 9.40844C2.50244 9.47573 2.48749 9.53555 2.48749 9.58789C2.48749 9.69257 2.53048 9.78229 2.61647 9.85706C2.70246 9.9281 2.79966 9.96361 2.90808 9.96361C2.98285 9.96361 3.05014 9.94492 3.10996 9.90753C3.17352 9.86641 3.22212 9.80659 3.25576 9.72808L3.55298 9.06636H5.45404L5.75126 9.72808C5.7849 9.80659 5.8335 9.86641 5.89706 9.90753C5.96061 9.94492 6.02791 9.96361 6.09894 9.96361C6.20736 9.96361 6.30269 9.9281 6.38494 9.85706C6.47093 9.78229 6.51392 9.69257 6.51392 9.58789ZM5.15682 8.40463H3.8502L4.50631 6.94659L5.15682 8.40463Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.9514 8.24761C9.82803 8.07938 9.65231 7.96161 9.42426 7.89432C9.61867 7.82329 9.77008 7.71113 9.8785 7.55785C9.99065 7.40083 10.0467 7.21951 10.0467 7.01389C10.0467 6.69611 9.92523 6.44563 9.68222 6.26244C9.44295 6.07925 9.1177 5.98765 8.70646 5.98765H7.29328C7.16243 5.98765 7.06149 6.02317 6.99046 6.0942C6.91942 6.16523 6.88391 6.26617 6.88391 6.39702V9.53181C6.88391 9.66266 6.91942 9.7636 6.99046 9.83463C7.06149 9.90567 7.16243 9.94118 7.29328 9.94118H8.76254C9.19247 9.94118 9.52894 9.84398 9.77195 9.64957C10.0187 9.45517 10.1421 9.1916 10.1421 8.85887C10.1421 8.61586 10.0785 8.41211 9.9514 8.24761ZM8.56065 7.5971H7.7363V6.65499H8.56065C8.78497 6.65499 8.94946 6.69424 9.05414 6.77275C9.16256 6.84752 9.21677 6.96716 9.21677 7.13165C9.21677 7.28867 9.16256 7.40644 9.05414 7.48495C8.94946 7.55972 8.78497 7.5971 8.56065 7.5971ZM9.1663 9.15608C9.06162 9.23459 8.89339 9.27385 8.66159 9.27385H7.7363V8.26444H8.66159C8.89339 8.26444 9.06162 8.30556 9.1663 8.38781C9.27098 8.47006 9.32332 8.59904 9.32332 8.77475C9.32332 8.95046 9.27098 9.07757 9.1663 9.15608Z'
    />
    <path d='M11.2381 9.89071C11.4924 9.958 11.7671 9.99165 12.0625 9.99165C12.3803 9.99165 12.6588 9.94118 12.8981 9.84024C13.1411 9.7393 13.328 9.59723 13.4588 9.41404C13.5934 9.23085 13.6607 9.01963 13.6607 8.78036C13.6607 8.4738 13.5486 8.23266 13.3243 8.05695C13.1037 7.88123 12.7392 7.7373 12.2307 7.62514C12.0139 7.57654 11.8457 7.52981 11.726 7.48495C11.6101 7.44008 11.526 7.38961 11.4737 7.33353C11.4251 7.27372 11.4008 7.19895 11.4008 7.10922C11.4008 6.95594 11.4662 6.83631 11.597 6.75032C11.7279 6.66059 11.9073 6.61573 12.1354 6.61573C12.3335 6.61573 12.4962 6.64003 12.6233 6.68863C12.7541 6.73723 12.8962 6.81014 13.0495 6.90734C13.113 6.94472 13.1616 6.96902 13.1953 6.98024C13.2327 6.99146 13.2719 6.99706 13.313 6.99706C13.3878 6.99706 13.4532 6.96155 13.5093 6.89051C13.5691 6.81948 13.599 6.73723 13.599 6.64377C13.599 6.57648 13.586 6.5204 13.5598 6.47553C13.5374 6.42693 13.4981 6.3802 13.442 6.33534C13.0981 6.0699 12.6607 5.93718 12.1298 5.93718C11.827 5.93718 11.554 5.99139 11.311 6.09981C11.0718 6.20449 10.883 6.35216 10.7446 6.54283C10.6101 6.72976 10.5428 6.94099 10.5428 7.17651C10.5428 7.38961 10.5895 7.57093 10.683 7.72048C10.7802 7.87002 10.9316 7.99526 11.1372 8.0962C11.3466 8.19714 11.6251 8.28313 11.9728 8.35416C12.2831 8.41772 12.498 8.48501 12.6177 8.55604C12.741 8.62708 12.8027 8.72054 12.8027 8.83644C12.8027 8.98972 12.7373 9.10748 12.6064 9.18973C12.4756 9.27198 12.2868 9.3131 12.0401 9.3131C11.8419 9.3131 11.6606 9.29067 11.4961 9.24581C11.3316 9.19721 11.169 9.12244 11.0082 9.0215C10.9521 8.98785 10.9035 8.96542 10.8624 8.9542C10.825 8.93925 10.7858 8.93177 10.7446 8.93177C10.6699 8.93177 10.6026 8.96729 10.5428 9.03832C10.4867 9.10935 10.4586 9.1916 10.4586 9.28506C10.4586 9.3561 10.4699 9.41591 10.4923 9.46452C10.5185 9.50938 10.5596 9.55237 10.6157 9.5935C10.7764 9.72061 10.9839 9.81968 11.2381 9.89071Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.3333 8.00001C15.3333 12.0501 12.0501 15.3333 8 15.3333C3.94991 15.3333 0.666667 12.0501 0.666667 8.00001C0.666667 3.94992 3.94991 0.666672 8 0.666672C12.0501 0.666672 15.3333 3.94992 15.3333 8.00001ZM14.2549 8.00001C14.2549 11.4545 11.4545 14.2549 8 14.2549C4.54551 14.2549 1.7451 11.4545 1.7451 8.00001C1.7451 4.54552 4.54551 1.7451 8 1.7451C11.4545 1.7451 14.2549 4.54552 14.2549 8.00001Z'
    />
  </svg>
);

export default IconABS;
