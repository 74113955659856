import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import './HistogramChart.scss';

export const AxisX = ({ height, axisMetric, label, ticks }) => {
  return (
    <foreignObject width='100%' y={height + 5} height='40' fontSize='10'>
      <div className='hist-x-container'>
        <div className='hist-x-axis'>
          {ticks.map((tick, index) => {
            return <span key={index}>{`${tick}${axisMetric ? axisMetric : ''}`}</span>;
          })}
        </div>
        <div className='hist-x-label'>
          <span>{label}</span>
        </div>
      </div>
    </foreignObject>
  );
};

export const AxisY = ({ ticks, height, axisMetric, label, isRelative }) => {
  return (
    <foreignObject height={height} x='-100' y={5} width='100' fontSize='8'>
      <div className='hist-y-container'>
        <div
          className='hist-y-label'
          style={{ width: isRelative ? '40px' : '20px', justifyContent: isRelative ? 'flex-end' : 'space-around' }}
        >
          <span className='hist-y-label-text' style={{ width: isRelative ? '170px' : 'max-content' }}>
            {label}
          </span>
        </div>
        <div className='hist-y-axis'>
          {ticks.map((tick, index) => (
            <span
              style={{
                margin: '0 5px'
              }}
              key={index}
            >
              {`${tick}${axisMetric ? axisMetric : ''}`}
            </span>
          ))}
        </div>
      </div>
    </foreignObject>
  );
};

export const PathTooltip = ({ title, x, y, children }) => (
  <Tooltip
    overlayStyle={{
      '--real-left': `${x - 65}px`,
      '--real-top': `${y - 45}px`,
      '--real-position': 'fixed'
    }}
    overlayClassName='histogram-tooltip'
    title={title}
  >
    {children}
  </Tooltip>
);

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = ev => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};
