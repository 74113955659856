import React from 'react';
import { Row, Col } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import LocaleString from '../Utils/LocaleString';
import { mobileOnly } from '../../helpers/responsive';
import { getMediumDateShortTime } from '../../helpers/functions';
import './Installation.scss';

const { Description } = DescriptionList;
const getDescription = details => {
  var date = getMediumDateShortTime(details.lastUpdated, true);
  return (
    <div>
      {!!details.report && (
        <DescriptionList title='' size='small' col={1}>
          <Description term={<LocaleString type='short' id='installationDate' />}>{date}</Description>
          {details.report.trailerType && (
            <Description term={<LocaleString type='short' id='trailerType' />}>
              {details.report.trailerType}
            </Description>
          )}
          {details.report.trailerBrand && (
            <Description term={<LocaleString type='short' id='trailerBrand' />}>
              {details.report.trailerBrand}
            </Description>
          )}
          {details.report.axleModel && (
            <Description term={<LocaleString type='short' id='axlesModel' />}>{details.report.axleModel}</Description>
          )}
          {details.report.axleBrand && (
            <Description term={<LocaleString type='short' id='axlesBrand' />}>{details.report.axleBrand}</Description>
          )}
          {details.report.tyreModel && (
            <Description term={<LocaleString type='short' id='tyresModel' />}>{details.report.tyreModel}</Description>
          )}
          {details.report.tyreBrand && (
            <Description term={<LocaleString type='short' id='tyresBrand' />}>{details.report.tyreBrand}</Description>
          )}
        </DescriptionList>
      )}
    </div>
  );
};

const Installation = ({ installationDetails }) => {
  const isMobile = mobileOnly.matches;
  return (
    <div>
      <Row className='installation-details'>
        <Col span={isMobile ? 24 : 10}>
          <h2>
            <LocaleString type='short' id='installation' />
          </h2>
          {installationDetails.map((details, index) => (
            <div key={index} className='installation'>
              {getDescription(details)}
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

Installation.propTypes = {};

export default Installation;
