import { createSelector } from '@reduxjs/toolkit';

const selectRoot = state => state?.ebpmsTrailerPerformance;

export const selectPerformanceMap = createSelector(selectRoot, state => state?.performanceMap ?? {});
export const selectFinishedLoading = createSelector(selectRoot, state => state?.finishedLoading ?? false);
export const selectError = createSelector(selectRoot, state => state?.error || null);
export const isPerformanceMapReady = createSelector([selectFinishedLoading, selectError], (finishedLoading, error) => {
  return finishedLoading === true && error === null;
});
