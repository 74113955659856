import { defineMessages } from 'react-intl';

export const month = defineMessages({
  january: {
    id: 'month.january',
    defaultMessage: 'January',
    description: 'month of the year'
  },
  february: {
    id: 'month.february',
    defaultMessage: 'February',
    description: 'month of the year'
  },
  march: {
    id: 'month.march',
    defaultMessage: 'March',
    description: 'month of the year'
  },
  april: {
    id: 'month.april',
    defaultMessage: 'April',
    description: 'month of the year'
  },
  may: {
    id: 'month.may',
    defaultMessage: 'May',
    description: 'month of the year'
  },
  june: {
    id: 'month.june',
    defaultMessage: 'June',
    description: 'month of the year'
  },
  july: {
    id: 'month.july',
    defaultMessage: 'July',
    description: 'month of the year'
  },
  august: {
    id: 'month.august',
    defaultMessage: 'August',
    description: 'month of the year'
  },
  september: {
    id: 'month.september',
    defaultMessage: 'September',
    description: 'month of the year'
  },
  october: {
    id: 'month.october',
    defaultMessage: 'October',
    description: 'month of the year'
  },
  november: {
    id: 'month.november',
    defaultMessage: 'November',
    description: 'month of the year'
  },
  december: {
    id: 'month.december',
    defaultMessage: 'December',
    description: 'month of the year'
  },
  abbreviatedJanuary: {
    id: 'month.abbreviatedJanuary',
    defaultMessage: 'Jan',
    description: 'abbreviation of month January'
  },
  abbreviatedFebruary: {
    id: 'month.abbreviatedFebruary',
    defaultMessage: 'Feb',
    description: 'abbreviation of month February'
  },
  abbreviatedMarch: {
    id: 'month.abbreviatedMarch',
    defaultMessage: 'Mar',
    description: 'abbreviation of month March'
  },
  abbreviatedApril: {
    id: 'month.abbreviatedApril',
    defaultMessage: 'Apr',
    description: 'abbreviation of month April'
  },
  abbreviatedMay: {
    id: 'month.abbreviatedMay',
    defaultMessage: 'May',
    description: 'abbreviation of month May'
  },
  abbreviatedJune: {
    id: 'month.abbreviatedJune',
    defaultMessage: 'Jun',
    description: 'abbreviation of month June'
  },
  abbreviatedJuly: {
    id: 'month.abbreviatedJuly',
    defaultMessage: 'Jul',
    description: 'abbreviation of month July'
  },
  abbreviatedAugust: {
    id: 'month.abbreviatedAugust',
    defaultMessage: 'Aug',
    description: 'abbreviation of month August'
  },
  abbreviatedSeptember: {
    id: 'month.abbreviatedSeptember',
    defaultMessage: 'Sep',
    description: 'abbreviation of month September'
  },
  abbreviatedOctober: {
    id: 'month.abbreviatedOctober',
    defaultMessage: 'Oct',
    description: 'abbreviation of month October'
  },
  abbreviatedNovember: {
    id: 'month.abbreviatedNovember',
    defaultMessage: 'Nov',
    description: 'abbreviation of month November'
  },
  abbreviatedDecember: {
    id: 'month.abbreviatedDecember',
    defaultMessage: 'Dec',
    description: 'abbreviation of month December'
  }
});
