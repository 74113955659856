import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VizSensor from 'react-visibility-sensor';
import AlertTimeline from './AlertTimeline';
import { isUsaCustomer, getTimeFormatLocale } from '../../../helpers/functions';
import { selectProductFeatures, selectRegion } from '../../../selectors';

class AlertTimelineComponent extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.chartRef = React.createRef();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
      isVisible: true
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentDidUpdate(prevProps) {
    const { chart, isVisible } = this.state;
    const { dataHash, alerts } = this.props;
    if (
      !document.hidden &&
      isVisible &&
      chart &&
      ((dataHash && dataHash !== prevProps.dataHash) || JSON.stringify(alerts) !== JSON.stringify(prevProps.alerts))
    ) {
      chart.update(this.props);
    }
  }

  updateIfVisible(isVisible) {
    const { chart } = this.state;
    if (isVisible) {
      chart.update(this.props);
    }
    this.setState({ isVisible });
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = this.chartRef.current;
    const { chart } = this.state;
    const {
      region,
      productFeatures,
      isHideTimeline,
      intl: { formatMessage },
      placesTimeline
    } = this.props;
    if (chart) {
      chart.destroy();
    }
    const margin = {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20
    };
    const { height, width } = this.props;
    const elWidth = width || el.offsetWidth;
    const elHeight = height || 150;
    const isUS = isUsaCustomer(region);

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      region,
      productFeatures,
      isHideTimeline,
      isUS,
      timeFormatLocale: getTimeFormatLocale(formatMessage, isUS),
      placesTimeline: placesTimeline
    };
    props.width = !isHideTimeline && props.width < 440 ? 440 : props.width;

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new AlertTimeline(el, props)
      },
      function callback() {
        this.state.chart.create(this.props);
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return (
      <VizSensor
        partialVisibility={true}
        onChange={isVisible => {
          this.updateIfVisible(isVisible);
        }}
      >
        <div className='alert-timeline__chart' ref={this.chartRef} />
      </VizSensor>
    );
  }
}
AlertTimelineComponent.propTypes = {};

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    region,
    productFeatures: selectProductFeatures(store)
  };
}

export default injectIntl(connect(mapStateToProps)(AlertTimelineComponent));
