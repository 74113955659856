import { arrayToCSV } from '../../../../../helpers/arrayToCSVDownload';
import { CsvColumns } from '../Columns/AllColumns';
import { getGroupColumnsCSV } from '../Columns/GroupColumns/getGroupColumns';

const prepTrailerRow = (trailer, csvColumnKeys, availableColumns, formatMessage, isMetric, isUS) => {
  return csvColumnKeys
    .filter(col => col in availableColumns)
    .reduce((acc, cur) => {
      acc.push(availableColumns[cur].data(trailer, formatMessage, isMetric, isUS));
      return acc;
    }, []);
};

const prepareCSVData = (trailers, formatMessage, isMetric, isUS, cols, groups) => {
  const groupsCsvColumns = getGroupColumnsCSV(trailers, groups);
  const formattedColumns = cols
    .filter(col => CsvColumns[col])
    .reduce((acc, cur) => {
      acc[cur] = CsvColumns[cur];
      return acc;
    }, {});

  const availableColumns = { ...formattedColumns, ...groupsCsvColumns };
  const csvHeaders = cols.filter(col => availableColumns[col]).map(col => availableColumns[col].header(formatMessage));
  const csvData = trailers.map(trailer =>
    prepTrailerRow(trailer, cols, availableColumns, formatMessage, isMetric, isUS)
  );
  return [csvHeaders].concat(csvData);
};

const downloadCsv = (
  trailers,
  formatMessage,
  isMetric,
  isUS,
  columns,
  groups,
  { date = new Date().toISOString() } = {}
) => {
  const data = prepareCSVData(trailers, formatMessage, isMetric, isUS, columns, groups);
  const filename = `trailers_export_${date}.csv`;
  arrayToCSV(data, filename);
};

export default downloadCsv;
