import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LocaleString from '../../Utils/LocaleString';
import TrailerWheelsChart from './TrailerWheelsChart';

import { isUsaCustomer, getTimeFormatLocale } from '../../../helpers/functions';
import { getUnitString, unitType } from '../../../helpers/unitConverter';

import './TrailerWheelsChart.scss';
import NoDataOrLoading from '../NoDataOrLoading';
import { selectProductFeatures, selectRegion } from '../../../selectors';

const TPMS_DISPLAY_NAME = {
  temperature: 'historyTPMSTemperature',
  pressure: 'historyTPMSPressure'
};

class TrailerWheelsChartComponent extends Component {
  chartEl = null;

  state = {
    chart: null,
    chartData: {
      keys: [],
      series: [],
      min: 0,
      max: 0,
      hasData: false
    }
  };

  constructor(props) {
    super(props);

    this.createChart = this.createChart.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.createChart.bind(this)());
    this.getChartData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataHash, resizeChart, startDate, endDate, pos, selectedWheels } = nextProps;

    if (resizeChart !== this.props.resizeChart) {
      this.createChart(this.state.chartData);
    } else if (
      (dataHash && dataHash !== this.props.dataHash) ||
      this.props.startDate !== startDate ||
      this.props.endDate !== endDate ||
      JSON.stringify(selectedWheels) !== JSON.stringify(this.props.selectedWheels) ||
      JSON.stringify(pos) !== JSON.stringify(this.props.pos)
    ) {
      this.getChartData(nextProps);
    }
  }

  toggleWheel = key => {
    this.props.onToggleWheel(key);
  };

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  getValueStringTranslated(value) {
    const {
      isMetric,
      view: componentViewName,
      intl: { formatMessage }
    } = this.props;

    const unityTypeValue = componentViewName === TPMS_DISPLAY_NAME.temperature ? unitType.temp : unitType.pressure;

    return formatMessage(getUnitString(unityTypeValue, isMetric), { value: value });
  }

  createChart(newChartData = false) {
    const { chart } = this.state;
    const {
      selectedTrailer,
      region,
      productFeatures,
      intl: { formatMessage }
    } = this.props;
    if (chart) {
      chart.destroy();
    }

    const chartData = newChartData || this.state.chartData;

    if (!chartData.hasData) {
      return;
    }

    const margin = {
      top: 20 + Math.floor(chartData.keys.length / 6) * 18,
      right: 40,
      bottom: 20,
      left: 40
    };
    const { height, width } = this.props;
    const elWidth = width || (this.chartEl && this.chartEl.offsetWidth);
    const elHeight = (height || 150) + Math.floor(chartData.keys.length / 6) * 18;
    const isUS = isUsaCustomer(region);

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      assetId: selectedTrailer ? selectedTrailer.assetId : null,
      chartData,
      getValueStringTranslated: this.getValueStringTranslated.bind(this),
      onToggleWheel: this.toggleWheel.bind(this),
      isUS,
      timeFormatLocale: getTimeFormatLocale(formatMessage, isUS),
      productFeatures
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new TrailerWheelsChart(this.chartEl, props)
      },
      function callback() {
        this.state.chart.create({
          ...this.props,
          chartData
        });
        this.state.chart.preventTransitions();
      }
    );
  }

  getChartData(props) {
    const { view: componentViewName, startDate, endDate, processing } = props;
    const { tpms: tpmsHistory } = this.props.trailerHistory.current;

    let chartData = this.state.chartData;

    if (!processing) {
      const wheelsData = {};
      let max = -Infinity;
      let min = +Infinity;

      const valueKey = componentViewName === TPMS_DISPLAY_NAME.temperature ? 'temperature' : 'pressure';

      (tpmsHistory || [])
        .filter(item => item.time >= startDate && item.time <= endDate)
        .forEach(({ wheels, time }) => {
          Object.keys(wheels).forEach(wheelKey => {
            const wheel = wheels[wheelKey];
            if (wheel) {
              let currentValue = {
                time: time * 1000,
                label: wheelKey,
                value: wheel[valueKey] ? parseFloat(wheel[valueKey].toString().replace(',', '.')) : 0
              };

              // do not add 0 values
              if (!currentValue.value) {
                return;
              }

              min = Math.min(min, currentValue.value);
              max = Math.max(max, currentValue.value);

              if (!wheelsData[currentValue.label]) {
                wheelsData[currentValue.label] = [];
              }

              wheelsData[currentValue.label].push(currentValue);
            }
          });
        });

      const keys = Object.keys(wheelsData).sort();
      const series = keys.map(key => ({ key, data: wheelsData[key] }));

      chartData = {
        keys,
        series,
        min,
        max,
        hasData: series.length > 0
      };

      this.setState({ chartData });
    }

    if (chartData.hasData) {
      this.state.chart
        ? this.state.chart.update(
            { ...props, chartData },
            this.props.selectedTrailer ? this.props.selectedTrailer.assetId : null
          )
        : this.createChart(chartData);
    } else if (!processing && this.state.chart) {
      this.state.chart.destroy();
      this.setState({
        chart: null
      });
    }

    return chartData;
  }

  render() {
    return (
      <>
        {this.props.showTitle && (
          <h2>
            <LocaleString type='sections' id={this.props.view} />
          </h2>
        )}
        <div ref={n => (this.chartEl = n)}>
          {!this.state.chartData.hasData && (
            <NoDataOrLoading
              processing={this.props.processing}
              setPickerOpen={this.props.setPickerOpen}
              onChangeEndDateTime={this.props.onChangeEndDateTime}
            />
          )}
        </div>
      </>
    );
  }
}

TrailerWheelsChartComponent.propTypes = {};

function mapStateToProps(store) {
  const region = selectRegion(store);

  return {
    region,
    isMetric: store.auth.isMetric,
    productFeatures: selectProductFeatures(store)
  };
}

export default injectIntl(connect(mapStateToProps)(TrailerWheelsChartComponent));
