import React, { useState, useEffect } from 'react';
// import 'babel-polyfill';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';
import numeral from 'numeral';
import 'numeral/min/locales.min';
import AppLayout from '../Components/Layout/Layout';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/ar';
import '@formatjs/intl-pluralrules/dist/locale-data/cs';
import '@formatjs/intl-pluralrules/dist/locale-data/da';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/dist/locale-data/it';
import '@formatjs/intl-pluralrules/dist/locale-data/nl';
import '@formatjs/intl-pluralrules/dist/locale-data/nb';
import '@formatjs/intl-pluralrules/dist/locale-data/pl';
import '@formatjs/intl-pluralrules/dist/locale-data/pt';
import '@formatjs/intl-pluralrules/dist/locale-data/ro';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/sk';
import '@formatjs/intl-pluralrules/dist/locale-data/sl';
import '@formatjs/intl-pluralrules/dist/locale-data/sv';
import '@formatjs/intl-pluralrules/dist/locale-data/tr';
import * as d3 from 'd3';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';

import localeData from '../assets/i18n/locales/data';
import actions from '../actions';
import TempAccess from '../Components/TempAccess/TempAccess';
import Banner from '../Components/Utils/SupportBanner';
import { history } from './configureStore';
import { pageView } from '../helpers/googleAnalytics';
import { defaultIsMetric, defaultNotMetric } from '../helpers/constants';
import { isScalar, isUsaCustomer } from '../helpers/functions';
import { ConfigProvider } from 'antd';
import { getAntLocale } from '../helpers/locale';
import { selectRegion, selectUser } from '../selectors';
import { ENV_CONFIG } from './helpers/env-configs';
import { parseJson } from '../helpers/parse-json';

const { REACT_APP_GOOGLE_ANALYTICS_CODE } = ENV_CONFIG;

if (REACT_APP_GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_CODE);
}

if (REACT_APP_GOOGLE_ANALYTICS_CODE) {
  if (history && history.location && history.location.pathname) {
    pageView(history.location.pathname);
  }

  history.listen(() => {
    pageView(history.location.pathname);
  });
}

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
let Language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = Language.toLowerCase().split(/[_-]+/)[0];
let twoLetterLangCode = Language.length === 2 ? Language : languageWithoutRegionCode;

// If browser doesn't support Intl
// and lang is no or nn (Norwegian Nynorsk)
// we need to switch language to nb: Norwegian Bokmål and messages to no
if (!window.intl && (twoLetterLangCode === 'no' || twoLetterLangCode === 'nn')) {
  Language = Language.replace(twoLetterLangCode, 'nb');
  twoLetterLangCode = 'no';
} else if (window.intl && (twoLetterLangCode === 'nb' || twoLetterLangCode === 'nn')) {
  // If browser does support Intl
  // and lang is nb (Norwegian Bokmål) or nn (Norwegian Nynorsk)
  // we switch language and messages to std Norwegian (no)
  Language = Language.replace(twoLetterLangCode, 'no');
  twoLetterLangCode = 'no';
}

let momentLocale = Language.toLowerCase();
momentLocale = momentLocale === 'en' ? 'en-gb' : momentLocale === 'en-us' ? 'en' : momentLocale;

moment.locale(momentLocale);
// Try full locale, fallback to locale without region code, fallback to en
const Messages = localeData[twoLetterLangCode] || localeData.en;

// store them for use in modals and other components that are not children of this component
localStorage.setItem(
  'locale',
  JSON.stringify({
    lang: Language,
    messages: Messages
  })
);

const LocaleContainer = () => {
  const user = useSelector(selectUser);
  const chosenLanguage = useSelector(store => store.language.language);
  const isMetric = useSelector(store => store.auth.isMetric);
  const region = useSelector(selectRegion);
  const groups = useSelector(store => store.groups.groups);
  const groupsProcessing = useSelector(store => store.groups.processing);

  const [language, setLanguage] = useState(Language);
  const [messages, setMessages] = useState(Messages);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (!groups && !groupsProcessing && !isScalar()) {
        dispatch(actions.groups.getGroups());
      }
      localStorage.setItem('transicsLanguage', user.language);
      setD3Locale();
      checkUnitSystem(false);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user) {
      setLocales();
    }
    // eslint-disable-next-line
  }, [chosenLanguage, user]);

  useEffect(() => {
    if (user) {
      checkUnitSystem(true);
    }
    // eslint-disable-next-line
  }, [isMetric]);

  const setLocales = () => {
    if (!user) return;
    const transicsLanguage = user.language;
    const trailerFitLanguage = localStorage.getItem('chosenLanguage');
    let userLanguage = transicsLanguage;
    let userLanguageChanged = false;

    if (localStorage.getItem('transicsLanguage') && localStorage.getItem('transicsLanguage') !== user.language) {
      userLanguageChanged = true;
    }

    if (!chosenLanguage && !userLanguageChanged && trailerFitLanguage) {
      userLanguage = trailerFitLanguage;
      dispatch(actions.language.setLanguage(userLanguage));
    } else if (chosenLanguage) {
      userLanguage = chosenLanguage;
      localStorage.setItem('chosenLanguage', userLanguage);
    } else {
      userLanguage = isUsaEnglish(transicsLanguage) ? 'en-us' : transicsLanguage;
      dispatch(actions.language.setLanguage(userLanguage));
    }

    if (language !== userLanguage && localeData[userLanguage]) {
      try {
        if (isUsaCustomer(region)) {
          moment.locale('en');
        } else {
          if (userLanguage) {
            if (userLanguage === 'cn') {
              moment.locale('zh-CN');
            } else if (userLanguage === 'jp') {
              moment.locale('ja');
            } else if (userLanguage === 'br') {
              moment.locale('pt-br');
            } else if (userLanguage === 'en') {
              moment.locale('en-gb');
            } else {
              moment.locale(userLanguage);
            }
          } else {
            moment.locale(user.locale);
          }
        }
      } catch (e) {
        console.warn('No locale for moment.js for ' + userLanguage + ' so setting it to English');
        moment.locale('en');
      }

      try {
        if (userLanguage) {
          if (userLanguage === 'cn') {
            numeral.locale('zh-CN');
          } else if (userLanguage === 'jp') {
            numeral.locale('ja');
          } else if (userLanguage === 'br') {
            numeral.locale('pt-br');
          } else {
            if (userLanguage === 'en' && isUsaCustomer(region)) {
              numeral.locale('en-us');
            } else {
              numeral.locale(userLanguage);
            }
          }
        } else {
          numeral.locale(user.locale);
        }
        // test so we can throw error if above didnt work (required for numeral locale)
        numeral('5000').format('0.0a');
      } catch (e) {
        console.warn('No locale for numeral.js for ' + userLanguage + ' so setting it to English');
        numeral.locale('en');
      }

      localStorage.setItem(
        'locale',
        JSON.stringify({
          lang: userLanguage,
          messages: localeData[userLanguage]
        })
      );

      setTimeout(() => {
        setLanguage(userLanguage);
        setMessages(localeData[userLanguage]);
      }, 0);
    }
  };

  const setD3Locale = () => {
    d3.timeFormatDefaultLocale({
      dateTime: '%a %e %b %-H:%M:%S %p %Y',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['', ''],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    });
  };

  const checkUnitSystem = isUpdated => {
    const localIsMetric = parseJson(localStorage.getItem('chosenIsMetric'));
    const isLocalValue = localIsMetric && localIsMetric !== 'null';

    // setting default here
    const defaultMetric = isUsaCustomer(region) ? defaultNotMetric : defaultIsMetric;
    if (!isLocalValue && !isMetric) {
      localStorage.setItem('chosenIsMetric', JSON.stringify(defaultMetric));
      dispatch(actions.auth.setIsMetric(defaultMetric));
    } else if (!isLocalValue || isUpdated) {
      localStorage.setItem('chosenIsMetric', JSON.stringify(isMetric));
    } else if (localIsMetric !== isMetric) {
      dispatch(actions.auth.setIsMetric(localIsMetric));
    }
  };

  const isUsaEnglish = language => isUsaCustomer(region) && language === 'en';

  return (
    <IntlProvider
      locale={language}
      messages={messages}
      key={`${language}_${isMetric && isMetric.base}_${isMetric && isMetric.pressure}`}
    >
      <ConfigProvider locale={getAntLocale(language)}>
        <Banner />
        <ConnectedRouter history={history}>
          <Switch>
            <Route path='/temp/:token' component={TempAccess} />
            <Route component={AppLayout} />
          </Switch>
        </ConnectedRouter>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default LocaleContainer;
