import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  messages: {}
};

// eslint-disable-next-line no-unused-vars
// https://immerjs.github.io/immer/return/
export const reset = state => {
  state.messages = initialState.messages;
};
export const queueMessage = (state, action) => {
  Object.entries(action.payload).forEach(([assetId, performanceMessages]) => {
    if (!(assetId in state.messages)) {
      state.messages[assetId] = [];
    }

    state.messages[assetId] = performanceMessages;
  });
};

export const sliceOptions = {
  name: 'mqtt-queued-messages',
  initialState,
  reducers: {
    reset,
    queueMessage
  }
};

export const mqttQueuedMessagesSlice = createSlice(sliceOptions);

export default {
  mqttQueuedMessages: mqttQueuedMessagesSlice.reducer
};
