/**
 * Based on https://github.com/visgl/deck.gl/tree/master/examples/website/icon
 * --> https://github.com/visgl/deck.gl/blob/master/examples/website/icon/icon-cluster-layer.js
 */
import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';

function getIconName(size) {
  if (size === 0) {
    return '';
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 100) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return 'marker-100';
}

function getIconSize(size) {
  return Math.min(100, size) / 100 + 1;
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 8, radius: props.sizeScale });
      const items = props.data.filter(
        d => d && ((d.gnss?.longitude && d.gnss?.latitude) || (d.address?.longitude && d.address?.latitude))
      );
      const points = items.map(d => ({
        geometry: { coordinates: props.getPosition(d) },
        properties: d
      }));

      index.load(points);
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });

      // listener to push the list of clusters / visible trailers to whoever is using this component (used to get the visible trailers)
      if (props.onClusterChange) {
        props.onClusterChange(this.state.index.getClusters([-180, -85, 180, 85], z));
      }
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== 'hover') {
        info.objects = this.state.index.getLeaves(pickedObject.cluster_id, 25).map(f => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { iconAtlas, iconMapping, sizeScale, getAngle, getIcon, getColor, autoHighlight } = this.props;
    const { data } = this.state;

    return new IconLayer(
      this.getSubLayerProps({
        id: 'icon',
        data,
        iconAtlas,
        iconMapping,
        sizeScale,
        getPosition: d => d.geometry.coordinates,
        getAngle,
        getColor,
        style: {
          background: '#00'
        },
        autoHighlight,
        getIcon: getIcon || (d => (d.properties.cluster ? getIconName(d.properties.point_count) : 'marker')),
        getSize: d => (d.properties.cluster ? getIconSize(d.properties.point_count) : 1)
      })
    );
  }
}
