import React from 'react';
import { Tooltip } from 'antd';
import './DashboardIcon.scss';

const DashboardIcon = ({ value, tooltipText, active, onClick, children, className }) => (
  <li className={className ? 'tf_dashboard__buttons_li ' + className : 'tf_dashboard__buttons_li'}>
    <Tooltip title={tooltipText} placement={'bottom'}>
      <button className={active ? 'active' : ''} onClick={onClick}>
        <span className='tf_icon'>{children}</span>
        <span>{value}</span>
      </button>
    </Tooltip>
  </li>
);
export default DashboardIcon;
