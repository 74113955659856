import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../actions';

const LoadingEmail = () => (
  <div className='li-email-loading-container'>
    <Spin />
  </div>
);
// given subscripton, return html preview for it
const EmailPreview = ({ emailKey }) => {
  const emailTemplate = useSelector(store => store.emailTemplates.templateHtml);
  const dispatch = useDispatch();
  useEffect(() => {
    if (emailKey && !emailTemplate[emailKey]) {
      dispatch(actions.emailTemplates.getEmailTemplate(emailKey));
    }
  }, [dispatch, emailKey, emailTemplate]);

  const emailHtml = emailTemplate[emailKey]?.item;
  if (emailHtml) {
    return <div dangerouslySetInnerHTML={{ __html: emailHtml }} />;
  }
  return <LoadingEmail />;
};

export default EmailPreview;
