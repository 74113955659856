import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ColorLegend from './ColorLegend';

function getChartState(props) {
  return {
    color: props.color,
    minValue: props.minValue,
    maxValue: props.maxValue,
    value: props.value,
    reverse: props.reverse
  };
}

class ColorLegendComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = getChartState(nextProps);
    if (
      nextProps.minValue !== null &&
      nextProps.maxValue !== null &&
      (this.props.minValue !== nextProps.minValue || this.props.maxValue !== nextProps.maxValue) &&
      chart
    ) {
      chart.update(currentState);
    }

    if (nextProps.value !== this.props.value && chart) {
      chart.updateValue(nextProps.value);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state;
    if (chart) {
      chart.destroy();
    }

    const margin = {
      top: 5,
      right: 10,
      bottom: 5,
      left: 10
    };

    const elWidth = el.offsetWidth;
    const elHeight = 30;
    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom
    };

    // Initialise the chart, then render it without transitions.
    this.setState(
      {
        chart: new ColorLegend(el, props)
      },
      function callback() {
        this.state.chart.create();
        this.state.chart.update(getChartState(this.props));
        this.state.chart.preventTransitions();
      }
    );
  }

  render() {
    return <div ref='chart' />;
  }
}

export default ColorLegendComponent;
