import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { mobileOnly } from '../../helpers/responsive';
import { useSelector } from 'react-redux';
import { injectIntl, useIntl } from 'react-intl';
import './LightMonitoringLayout.scss';
import {
  formatLampStatusString,
  groteLightState,
  groteSeverityColourMap,
  groteStatusSeverityMap
} from '../../helpers/grote';
import GroteGuardianLightStatus from '../GroteGuardianLightStatus/GroteGuardianLightStatus';
import * as strings from '../../helpers/defaultStrings';
import moment from 'moment';

const formatAnomalyString = (formatMessage, statuses) =>
  statuses
    .reduce((acc, curr) => {
      const lampStatusString = formatLampStatusString(curr.colour, groteLightState[curr.code], formatMessage);
      acc.push(lampStatusString);
      return acc;
    }, [])
    .join(' / ');

const TrailerLightMonitoringStatusInfo = ({ trailer, formatMessage }) => {
  const { lightMonitoring = {} } = trailer;
  const { type, severity, time, anomalies, outages } = lightMonitoring;

  if (!type) {
    return formatMessage(strings.lightMonitoringStatus.noInfo);
  }

  return (
    <React.Fragment>
      <table className={'description-table'}>
        <tbody>
          <tr>
            <td className={'header-cell'}>{formatMessage(strings.short.status)}</td>
            <td>{formatMessage(strings.lightMonitoringStatus[groteStatusSeverityMap[severity]])}</td>
          </tr>
          <tr>
            <td className={'header-cell'}>{formatMessage(strings.lightMonitoringStatus.alertColour)}</td>
            <td>{formatMessage(strings.alertColours[groteSeverityColourMap[severity]])}</td>
          </tr>
          {outages?.length > 0 && (
            <tr>
              <td className={'header-cell'}>{formatMessage(strings.lightMonitoringStatus.outages)}</td>
              <td>{formatAnomalyString(formatMessage, outages)}</td>
            </tr>
          )}
          {anomalies?.length > 0 && (
            <tr>
              <td className={'header-cell'}>{formatMessage(strings.lightMonitoringStatus.anomalies)}</td>
              <td>{formatAnomalyString(formatMessage, anomalies)}</td>
            </tr>
          )}
          <tr>
            <td className={'header-cell'}>{formatMessage(strings.lightMonitoringStatus.lastUpdated)}</td>
            <td>
              {time
                ? moment
                    .utc(time * 1000)
                    .local()
                    .fromNow()
                : formatMessage(strings.short.unknown)}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

const TrailerIdCardContentGeneralInfo = ({ trailer, selectedTrailer }) => {
  const isMobile = mobileOnly.matches;
  const processing = useSelector(store => store.trailerDetails.trailer.processing);
  const { formatMessage } = useIntl();

  if (processing) {
    return <LoadingOutlined className='large-margin-left' />;
  }

  return (
    (trailer || selectedTrailer) && (
      <Row type='flex' id={'light-monitoring-description'}>
        <Col span={isMobile ? 24 : 16}>
          <h2>{formatMessage(strings.sections.lightMonitoring)}</h2>
          <div className={'info-container'}>
            <TrailerLightMonitoringStatusInfo trailer={selectedTrailer} formatMessage={formatMessage} />
          </div>
        </Col>
        {selectedTrailer?.lightMonitoring?.groteValues.length > 0 && (
          <Col span={isMobile ? 24 : 8}>
            <div className={'grote-visualisation-wrapper'}>
              <GroteGuardianLightStatus
                grote={selectedTrailer?.lightMonitoring?.groteValues}
                anomalies={selectedTrailer?.lightMonitoring?.anomalies}
                outages={selectedTrailer?.lightMonitoring?.outages}
                formatMessage={formatMessage}
              />
            </div>
          </Col>
        )}
      </Row>
    )
  );
};

export default injectIntl(TrailerIdCardContentGeneralInfo);
