import axios from 'axios';
import actionTypes from './actionTypes';
import placesAPISample from '../assets/data/placesAPISample.json';
import { hexToRgb } from '../helpers/placesLayers';
import { isIntegration, isScalar } from '../helpers/functions';
import { ENV_CONFIG } from '../app/helpers/env-configs';

function fetch(url, method, token, options = {}) {
  return axios({
    baseURL: ENV_CONFIG.REACT_APP_GEO_GATEWAY,
    ...options,
    url,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(options.headers || {})
    }
  }).catch(e => {
    console.warn(`${url}-QueryFailure`, `callBFF - failed with error:`);
    throw e;
  });
}

function getPlaces(scalarToken) {
  return dispatch => {
    dispatch(getPlacesRequest());
    if (!isScalar() && isIntegration()) {
      // for testing places in integration
      dispatch(getPlacesSuccess(placesAPISample.items));
      return;
    }
    fetch(`v1/places?fields=definition`, 'get', scalarToken)
      .then(res => {
        if (res.data.items) {
          dispatch(getPlacesSuccess(res.data.items));
        } else {
          dispatch(getPlacesFailure(res.err || 'error'));
        }
      })
      .catch(err => dispatch(getPlacesFailure(err)));
  };
}

function getPlacesRequest() {
  return {
    type: actionTypes.GET_PLACES_REQUEST
  };
}

function getPlacesSuccess(data) {
  return {
    type: actionTypes.GET_PLACES_SUCCESS,
    data
  };
}

function getPlacesFailure(data) {
  return {
    type: actionTypes.GET_PLACES_FAILURE,
    data
  };
}

function createPlaceRequest() {
  return {
    type: actionTypes.CREATE_PLACE_REQUEST
  };
}
function createPlaceSuccess(data) {
  return {
    type: actionTypes.CREATE_PLACE_SUCCESS,
    data
  };
}
function createPlaceEditSuccess(data) {
  return {
    type: actionTypes.CREATE_PLACE_EDIT_SUCCESS,
    data
  };
}

function createPlace(rawPlace, cb = () => {}) {
  return dispatch => {
    dispatch(createPlaceRequest());

    const place = convertRawPlaceToAPIPlace(rawPlace);

    if (rawPlace.id) {
      dispatch(createPlaceEditSuccess(place));
    } else {
      dispatch(createPlaceSuccess(place));
    }

    cb(place);
  };
}

function convertRawPlaceToAPIPlace(rawPlace) {
  const id = Math.ceil(Math.random() * 1000000);
  return {
    placeId: rawPlace?.id || id,
    index: rawPlace?.id || id,
    placeName: rawPlace.name,
    placeCategory: {
      type: rawPlace.category,
      name: rawPlace.category
    },
    placeColor: {
      rgb: hexToRgb(rawPlace.color),
      hex: rawPlace.color
    },
    placeDescription: rawPlace.description,
    placeLocation: rawPlace.location,
    addressInfo: {
      address: rawPlace.address,
      city: rawPlace.city,
      postalCode: rawPlace.postalcode,
      country: rawPlace.country
    },
    geofence: {
      shape: rawPlace.shape,
      radius: rawPlace.radius,
      definition: rawPlace.geoJson
    },
    contact: {
      prefix: null,
      phoneNumber: null,
      email: null,
      website: null,
      contactPersons: null
    },
    notes: '',
    anyoneHasAccess: true,
    alarms: rawPlace.alarms
  };
}

function deletePlaceRequest() {
  return {
    type: actionTypes.DELETE_PLACE_REQUEST
  };
}
function deletePlace(place, cb = () => {}) {
  return dispatch => {
    dispatch(deletePlaceRequest());
    dispatch(deletePlaceSuccess(place));
    cb();
  };
}
function deletePlaceSuccess(data) {
  return {
    type: actionTypes.DELETE_PLACE_SUCCESS,
    data
  };
}

export default {
  getPlaces,
  createPlace,
  deletePlace
};
