import { useState, useLayoutEffect } from 'react';

export default function useSize(ref) {
  const [size, setSize] = useState({});

  useLayoutEffect(() => {
    if (ref.current == null) return;
    const observer = new ResizeObserver(([entry]) => setSize(entry.contentRect));
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return size;
}
