import { createAsyncThunk } from '@reduxjs/toolkit';
import { worker } from '../../app/web-workers/aws-web-worker';
import { API_NAMES, LAST_VALID_EBPMS_EVENT } from '../../helpers/constants';

// +info https://redux-toolkit.js.org/api/createAsyncThunk#type
export const lastValidEbpmsEventsThunk = async (nextPage, thunkApi) => {
  const queryString = nextPage ? `?pageKey=${nextPage}` : '';
  const endpoint = `trailers/lastValidBrakings${queryString}`;
  const result = await worker.Api({ method: 'get', api: API_NAMES.EBPMS, endpoint });
  if (result.metadata?.nextPage) {
    const { dispatch } = thunkApi;
    setTimeout(() => dispatch(fetchLastValidEbpmsMessage(result.metadata?.nextPage)), 0);
  }
  // return values will update the reducer state accordingly on action creator '*/fulfilled '
  return result.brakingEvents ?? [];
};

export const fetchLastValidEbpmsMessage = createAsyncThunk(LAST_VALID_EBPMS_EVENT, lastValidEbpmsEventsThunk);
