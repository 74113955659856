import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as strings from '../../helpers/defaultStrings';
import actions from '../../actions';
import { languages } from './SettingsLanguages';
import { injectIntl } from 'react-intl';
import { settingsConstants, unitSystems, defaultIsMetric, unitSystemOptions } from '../../helpers/constants';
import './Settings.scss';
import { Checkbox, Modal, Table, Radio, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import SettingsOptionCell from './SettingsOptionCell';
import { filterTrailers as filterTrailersThunk } from '../../actions/thunks/filter-trailers';
import { ENV_CONFIG } from '../../app/helpers/env-configs';
import { APP_VERSION } from '../../app/helpers/app-version';
import { selectDisplayTrailers, selectProductFeatures, selectDisplayTrailersEBPMS } from '../../selectors';

const { REACT_APP_ENV } = ENV_CONFIG;

class ApplicationSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: null,
      unit: null,
      optionalSettings: {},
      isCustomChecked: false,
      isMetricSystem: {},
      mapStyleURL: null,
      mapAccessToken: null
    };
  }

  componentDidMount() {
    const { settings, getSettings, isMetric } = this.props;
    if (!settings) {
      getSettings();
    }
    if (!Object.values(isMetric).every(v => v === Object.values(isMetric)[0])) {
      this.setState({ isCustomChecked: true });
    }
    this.setState({
      isMetricSystem: isMetric,
      optionalSettings: {
        ...this.state.optionalSettings,
        mapStyleURL: settings?.mapStyleURL,
        mapAccessToken: settings?.mapAccessToken,
        displayTrailers: settings?.items?.displayTrailers,
        displayTrailersEBPMS: settings?.items?.displayTrailersEBPMS,
        windowSize: settings?.items?.windowSize,
        maxErrorMargin: settings?.items?.maxErrorMargin
      }
    });
  }

  goBack = () => {
    const { match, goTo } = this.props;
    const url = match.url.split('/')[1];
    goTo(url);
  };

  onClickCancel() {
    const { onCancel, isMetric, language } = this.props;
    onCancel();
    this.setState({ lang: language, isMetricSystem: isMetric, optionalSettings: {} });
  }

  onClickOk() {
    const {
      changeLanguage,
      setIsMetric,
      isMetric,
      language,
      transicsLanguage,
      onOk,
      displayTrailers,
      displayTrailersEBPMS,
      saveSettingGroup,
      filterTrailers,
      filterEBPMSTrailers
    } = this.props;
    const { lang, isMetricSystem, optionalSettings } = this.state;
    const currentLanguage = language?.lang || transicsLanguage?.lang;
    if (lang?.lang && currentLanguage !== lang?.lang) {
      changeLanguage(lang.lang);
    }
    if (isMetricSystem !== isMetric) {
      setIsMetric(isMetricSystem);
    }
    saveSettingGroup(optionalSettings);

    if (optionalSettings.displayTrailers && optionalSettings.displayTrailers !== displayTrailers) {
      filterTrailers();
    }
    if (optionalSettings.displayTrailersEBPMS && optionalSettings.displayTrailersEBPMS !== displayTrailersEBPMS) {
      filterEBPMSTrailers(optionalSettings);
    }
    onOk();
  }

  updateLang = lang => {
    this.setState({ lang });
  };

  updateMetricSetting = (type, value) => {
    const { isMetricSystem } = this.state;
    const nextSetting = { ...isMetricSystem, [type]: value };
    this.setState({ isMetricSystem: nextSetting });
  };

  updateAllSetting = value => {
    let nextSetting = {};
    Object.keys(defaultIsMetric).forEach(key => {
      nextSetting[key] = value;
    });
    this.setState({ isMetricSystem: nextSetting });
  };

  getUnitColumns() {
    const { isMetricSystem, isCustomChecked } = this.state;
    const {
      intl: { formatMessage }
    } = this.props;
    const isBaseMetric = !!isMetricSystem.base;
    return [
      {
        key: 'key',
        dataIndex: 'key',
        width: 150,
        render: key => <div key={key}>{formatMessage(strings.short[key])}</div>
      },
      {
        key: 'unitOptions',
        dataIndex: 'unitOptions',
        width: 150,
        render: option => {
          const isCustomising = option.key === 'pressure' && isCustomChecked;
          const props = isCustomising
            ? { value: isMetricSystem[option.key] ? option.metric : option.imperial }
            : { value: isBaseMetric ? option.metric : option.imperial };
          return (
            <div key={option.metric}>
              <Radio.Group
                onChange={item => this.updateMetricSetting(option.key, item.target.value === option.metric)}
                buttonStyle='solid'
                {...props}
              >
                <Radio.Button
                  value={option.metric}
                  key={option.metric}
                  disabled={isCustomising ? false : !isBaseMetric}
                >
                  {formatMessage(strings.abbrev[option.metric])}
                </Radio.Button>
                <Radio.Button
                  value={option.imperial}
                  key={option.imperial}
                  disabled={isCustomising ? false : isBaseMetric}
                >
                  {formatMessage(strings.abbrev[option.imperial])}
                </Radio.Button>
              </Radio.Group>
            </div>
          );
        }
      }
    ];
  }

  updateSetting = (type, value) => {
    const { optionalSettings } = this.state;
    const nextSetting = { ...optionalSettings, [type]: value };
    this.setState({ optionalSettings: nextSetting });
  };

  updateIsMetric = additionalIsMetric => {
    const { isMetric } = this.props;
    this.setState({ isMetricSystem: { ...isMetric, ...additionalIsMetric } });
  };

  getColumns() {
    const {
      intl: { formatMessage }
    } = this.props;
    const { lang } = this.state;
    const language = languages.find(language => language.lang === this.props.language);
    const transicsLanguage = languages.find(language => language.lang === this.props.transicsLanguage);

    return [
      {
        key: 'settingType',
        dataIndex: 'settingType',
        width: '30%',
        render: type => formatMessage(strings.settings[type])
      },
      {
        key: 'options',
        dataIndex: 'options',
        width: '70%',
        render: (options, record) => {
          const { settings, productFeatures } = this.props;
          const { optionalSettings, isMetricSystem } = this.state;

          const args = {
            options,
            lang,
            language,
            formatMessage,
            transicsLanguage,
            settings,
            optionalSettings,
            updateLang: this.updateLang,
            isMetricSystem,
            updateAllSetting: this.updateAllSetting,
            updateSetting: this.updateSetting,
            showEBPMS: productFeatures.showEBPMS
          };

          return <SettingsOptionCell options={options} warning={record.warning} args={args} />;
        }
      }
    ];
  }

  getDataSource(transicsLanguage) {
    const { productFeatures } = this.props;
    const settingsArr = [
      {
        key: 'language',
        settingType: 'language',
        options: { type: 'language', data: languages },
        warning: {
          emailNotificationString: strings.settings.emailNotifications,
          defaultWarningString: productFeatures.showEBPMS
            ? strings.settings.defaultLanguageNotificationFleetConnect
            : strings.settings.defaultLanguageNotification,
          defaultOptionString: transicsLanguage.name
        }
      },
      {
        key: 'displayTrailers',
        settingType: 'displayTrailers',
        options: { type: 'displayTrailers' },
        warning: [
          {
            extraInformation: strings.settings.displayTrailersInfoText
          },
          { extraInformation: strings.settings.hideShowEBPMSData }
        ]
      }
    ];

    if (productFeatures.showUnitSettings) {
      settingsArr.push({
        key: 'unitSystem',
        settingType: 'unitSystem',
        options: { type: 'unitSystem', data: unitSystems },
        warning: {
          emailNotificationString: strings.settings.emailNotificationsUnitSystem,
          defaultWarningString: strings.settings.defaultUnitSystem,
          defaultOptionString: strings.settings[unitSystems.metric]
        }
      });
    }

    if (REACT_APP_ENV.toString().trim().toLowerCase() === 'integration') {
      settingsArr.push({
        key: 'mapStyle',
        settingType: 'mapStyle',
        options: { type: 'mapStyle' }
      });
    }

    if (productFeatures.showEBPMS) {
      settingsArr.push(
        {
          key: 'maxErrorMargin',
          settingType: 'maxErrorMargin',
          options: { type: 'maxErrorMargin', data: settingsConstants.maxErrorMargin },
          warning: null
        },
        {
          key: 'windowSize',
          settingType: 'windowSize',
          options: { type: 'windowSize', data: settingsConstants.windowSize },
          warning: null
        },
        {
          key: 'maxValidDays',
          settingType: 'maxValidDays',
          options: { type: 'maxValidDays', data: settingsConstants.maxValidDays },
          warning: {
            extraInformation: strings.settings.maxValidDaysExtraInfo
          }
        }
      );
    }

    return settingsArr;
  }
  onCustomChange() {
    const { isCustomChecked, isMetricSystem } = this.state;
    if (isCustomChecked) {
      this.updateAllSetting(isMetricSystem.base);
    }
    this.setState({ isCustomChecked: !isCustomChecked });
  }

  render() {
    const {
      open: isOpen,
      intl: { formatMessage }
    } = this.props;
    const { isCustomChecked } = this.state;
    const transicsLanguage = languages.find(language => language.lang === this.props.transicsLanguage);

    return (
      <Modal
        title={formatMessage(strings.pageTitle.settings)}
        width='60%'
        open={isOpen}
        onOk={() => this.onClickOk()}
        onCancel={() => this.onClickCancel()}
        className='application-settings'
      >
        <div className='application-settings-div'>
          <div className='application-version'>
            <div className='version-title'>Version</div>
            <div className='version-number'>{APP_VERSION}</div>
          </div>
          {transicsLanguage && (
            <Table
              columns={this.getColumns()}
              dataSource={this.getDataSource(transicsLanguage)}
              pagination={{
                hideOnSinglePage: true
              }}
              showHeader={false}
              rowClassName={record =>
                record.key !== 'unitSystem' && record.key !== 'mapStyle' ? 'not-expandable' : ''
              }
              expandedRowRender={record => (
                <>
                  {record.key === 'unitSystem' && (
                    <div>
                      <Checkbox
                        checked={isCustomChecked}
                        onClick={() => {
                          this.onCustomChange();
                        }}
                      >
                        {formatMessage(strings.short.customise)}
                      </Checkbox>
                      <Table
                        columns={this.getUnitColumns()}
                        dataSource={unitSystemOptions}
                        pagination={{
                          hideOnSinglePage: true
                        }}
                        showHeader={false}
                      />
                    </div>
                  )}
                  {record.key === 'mapStyle' && (
                    <Form layout='vertical'>
                      <Form.Item label={formatMessage(strings.short.mapStyleURL)}>
                        <Input
                          placeholder={formatMessage(strings.short.mapStyleURL)}
                          value={this.state.optionalSettings.mapStyleURL}
                          onChange={evt => this.updateSetting('mapStyleURL', evt.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label={formatMessage(strings.short.mapAccessToken)}>
                        <Input
                          placeholder={formatMessage(strings.short.mapAccessToken)}
                          value={this.state.optionalSettings.mapAccessToken}
                          onChange={evt => this.updateSetting('mapAccessToken', evt.target.value)}
                        />
                      </Form.Item>
                    </Form>
                  )}
                </>
              )}
            />
          )}
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    displayTrailers: selectDisplayTrailers(state),
    displayTrailersEBPMS: selectDisplayTrailersEBPMS(state),
    productFeatures: selectProductFeatures(state),
    processing: state.trailerDetails.trailer.processing,
    error: state.trailerDetails.trailer.error,
    selectedTrailer: state.trailerDetails.trailer.item,
    selectedHealthTrailer: state.appState.selectedHealthTrailer,
    isMetric: state.auth.isMetric,
    language: state.language.language,
    transicsLanguage: state.auth.user ? state.auth.user.language : undefined
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: language => dispatch(actions.language.setLanguage(language)),
    setIsMetric: isMetric => dispatch(actions.auth.setIsMetric(isMetric)),
    getSettings: () => dispatch(actions.settings.getSettings()),
    saveSettingGroup: value => dispatch(actions.settings.saveSettingGroup(value)),
    filterEBPMSTrailers: () => dispatch(actions.ebpmsTrailers.filterTrailers()),
    goTo: url => dispatch(push(`/${url}`)),
    filterTrailers: () => dispatch(filterTrailersThunk())
  };
}

ApplicationSettings.propTypes = {};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings));
